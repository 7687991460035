import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { action, useStrict } from 'mobx'
import { layerPopup } from "../../../../../libs/common";
import SohoApi from "../../../../../api/SohoApi";
import { toast } from "../../../../../libs/common";
import * as LocalStore from "../../../../../store/LocalStore";

//통화연결음 설정 페이지

const OpenTimeCallRing = observer(({history,location}) => {

  const { userInfo, userData,deviceInfo } = store;

    const [openTimeRing, setOpenTimeRing] = useState(null);
    const [eachCheckInput, setEachCheckInput] = useState("");
    const [checked, setChecked] = useState(true);
    const [comTextValue, setComTextValue] = useState([]);
    const [ttsPrePlayInfo, setTtsPrePlayInfo] = useState(null);

    const centrexIdCheck = () => {
      const centrexId = LocalStore.getStoreInetNo();
      const tempCentrexPass = LocalStore.getTempInetPass();
      return [centrexId ,tempCentrexPass]
    }
    
    const [resultNum, resultPass] = centrexIdCheck();

	useEffect(() => {
		setEachCheckInput("0")
    const subscription = NativeBridge.eventObservable.subscribe(event => {

      switch (event.type) {                    
  
          case 'CALL_BACKPRESS':
                  if(document.getElementById("popup_prelisten").style.display === "block"){
                      return layerPopup.hide('#popup_prelisten')
                  }
                  if(document.getElementById("make_call_before").style.display === "block"){
                    return layerPopup.hide('#make_call_before')
                  }
                  if(((location.pathname === "/shop/call/callset/callring/opentimecallring" && document.getElementById("popup_prelisten").style.display === "none") ||
                  (location.pathname === "/shop/call/callset/callring/opentimecallring" && document.getElementById("popup_prelisten").style.display === ""))){
                      goBackPage();
                  }
              
          break;
  
      default:
          break;
      }
});

        if($('#wrap').hasClass('gnb_bottom')) {
          $('#wrap').removeClass('gnb_bottom')
          $('#wrap').addClass('fixed_bottom');
        }     
        return () => {
          subscription.unsubscribe();
          if($('#wrap').hasClass('fixed_bottom')) {
            $('#wrap').removeClass('fixed_bottom');
            $('#wrap').addClass('gnb_bottom');
          }
        }
}, [])

  useEffect(()=> {
    document.getElementsByName("openTimeCallText")[0].onblur = () => {
      if(comTextValue.length < 1){
        console.log("11",comTextValue);
      document.getElementsByName("callLabel7")[0].style.display = "block";
      }
  }
  },[comTextValue])

  //저장 버튼
const saveOnHandleClick = () => {
    NativeBridge.sendUserActionLog('menu', 'D094');
  NativeBridge.sendUserActionLog('menu', 'H015');
  if((comTextValue.trim().length) < 5 || (comTextValue.trim().length) > 200){
    layerPopup.showCommon(
        '#popup_onetitlemsgbutton', 
        null, 
        '내용 글자 수 알림', 
        '5자 이상 200자 이하로 입력해주세요.',
        [
            function() {
                layerPopup.hide('#popup_onetitlemsgbutton');//닫기
            },
        ]
    )
  }else {
    //기본 통화연결음 조회
    ringToneInfo();
  }
}

//기본통화연결음 조회
const ringToneInfo = async () => {
  const getRingTone = await SohoApi.getRingToneInfo({
    //userId: LocalStore.getUserId(),
    lpzEntrNo : resultNum, //매장 인터넷 가입 번호	
    ownerUserId : LocalStore.getOwnerId()
  });

  let rng1 = "";
  let rng2 = "";
  let rng3 = "";
  let rng4 = "";
  let rng5 = "";

  const getRingToneData = getRingTone.data;
  
  //원래 가지고 있던 기본 통화연결음이 있는지 조회 있으면 변수에 담아서 보내줘야함
  if(getRingToneData?.rngTnTyp1 !== ""){
      rng1 = getRingToneData.rngTnTyp1;
  }
  if(getRingToneData?.rngTnTyp2 !== ""){
      rng2 = getRingToneData.rngTnTyp2;
      delMohList(rng2);
  }
  if(getRingToneData?.rngTnTyp3 !== ""){
      rng3 = getRingToneData.rngTnTyp3;
  }
  if(getRingToneData?.rngTnTyp4 !== ""){
      rng4 = getRingToneData.rngTnTyp4;
  }
  if(getRingToneData?.rngTnTyp5 !== ""){
      rng5 = getRingToneData.rngTnTyp5;
  }

  if(getRingTone.retCode === "0000"){
    makeTtsMoh(comTextValue,
      eachCheckInput, 
      userInfo.dataset.defaultGrt.ringEngName,
      rng1,
      rng2,
      rng3,
      rng4,
      rng5
     )
  }else {
    layerPopup.showCommon(
      '#popup_onetitlemsgbutton', 
      null, 
      '통화연결음 등록 실패', 
      '통화연결음 등록에 실패하였습니다. 다시 시도해주세요.',
      [
          function() {
              layerPopup.hide('#popup_onetitlemsgbutton');//닫기
          }
      ]
    );
  }
}

 //영업 중 통화연결음 생성
const makeTtsMoh = async (com, speaker, bgRing, ring1,ring2,ring3,ring4,ring5) => {
  if(com === "" || com === []){
    com = "안녕하세요. 고객님! 우리가게 역삼점입니다. 잠시만 기다려 주시면 곧 통화가 연결됩니다."
}
  NativeBridge.getLoadingStart();
  const makeTtsMohData = await CentrexApi.getMakettsMoh({
      newservice : "com", // 전체:com, 시간:work, 요일별:week
      titlename : "영업 중", //음원 명 2자 이상 20자 이하
      speaker : speaker, // 0 : 남성, 1 : 여성, 2 : 아이
      bgfile : bgRing, // "(빈값)" : 배경사용안함 //봄,여름,가을,겨울
      comtext : com.trim() // 전체음원멘트 최대 200자(한글기준, 공백포함)
  })

  if(makeTtsMohData.data.SVC_RT === "0000"){
    NativeBridge.getLodingEnd();
    userData.setBgCheck(true);
    ringToneRegi(makeTtsMohData.data.DATAS.CLASS, ring1,ring2,ring3,ring4,ring5);
  }else {
    NativeBridge.getLodingEnd();
    layerPopup.showCommon(
      '#popup_onetitlemsgbutton', 
      null, 
      '통화연결음 등록 실패', 
      '통화연결음 등록에 실패하였습니다. 다시 시도해주세요.',
      [
          function() {
              layerPopup.hide('#popup_onetitlemsgbutton');//닫기
          }
      ]
    );
  }
}

//소호 통화연결음 저장 
const ringToneRegi = async (ringClass, call1,call2,call3,call4,call5) => {
  const ringToneRegi = await SohoApi.getRingToneRegi({
    ownerUserId : LocalStore.getOwnerId(),
    lpzEntrNo : resultNum, //인터넷 가입번호
    rngTnTyp1 : call1,
    rngTnTyp2 : ringClass, 
    rngTnTyp3 : call3,
    rngTnTyp4 : call4,
    rngTnTyp5 : call5,
  })

  if(ringToneRegi.retCode === "0000"){
    callRingChange(ringClass);
    NativeBridge.getLodingEnd();
    setComTextValue("") //comValue 초기화
    setChecked(true); //음원멘트 default 초기화

    userInfo.setDefalutGreeting({
      ringName : "비발디 사계 봄",
      ringEngName : "bg_01",
    })

    toast("통화연결음을 등록했습니다.")
    history.goBack();
  }else{
    NativeBridge.getLodingEnd();
   //소호 통화연결음 저장 안될 시 센트릭스 통화연결음 목록에서 삭제
  //  if(ringToneRegi.retCode === "9005" || ringToneRegi.retCode === "9999" || ringToneRegi.retCode === "9006"){
    delMohList(ringClass);
    layerPopup.showCommon(
      '#popup_onetitlemsgbutton', 
      null, 
      '통화연결음 등록 실패', 
      '통화연결음 등록에 실패하였습니다. 다시 시도해주세요.',
      [
          function() {
              layerPopup.hide('#popup_onetitlemsgbutton');//닫기
          }
      ]
    );
  }
}

//통화연결음 변경설정API
const callRingChange = async (callClass) => {
  const mohInfoData = await CentrexApi.getSetMohInfo({
    class: callClass,
    peer_exten: LocalStore.getExtenInetNo()
  })
}

//센트릭스 삭제
const delMohList = async (callClass) => {
  const delMohListData = await CentrexApi.getDelMohList({
    class: callClass
  })
   const getDelMohListData = delMohListData.data;
  if(getDelMohListData.status === 200){
    console.log("삭제 성공")
  }
}

//연결음 미리듣기 API
const ttsPrePlay = async (speaker, text, bgRing) => {
  if(text === ""){
    text = "안녕하세요. 고객님! 우리가게 역삼점입니다. 잠시만 기다려 주시면 곧 통화가 연결됩니다."                       
  }
  const ttsPrePlayData = await NativeBridge.getRingMusicPlay(speaker, bgRing, text)
  NativeBridge.sendUserActionLog('detail', 'D093', '', '영업중');
  setTtsPrePlayInfo(ttsPrePlayData);
}

//연결음 미리듣기 click
const ttsPrePlayOnClick = () => {
  ttsPrePlay(eachCheckInput, 
             comTextValue,
             userInfo.dataset.defaultGrt.ringEngName
            );
}

      // comText input관리
const comTextOnChange = (e) => {
  setComTextValue(e.target.value);   
}
    
    
     //여성, 남성, 아이 구분 각각 0,1,2
const speakerHandelClick = (e) => {
  //defalut값(여성 = 1) 유지하면서 다른 input 체크
  if(e.target.value === "1" || e.target.value ==="2"){
    setChecked(false)
  }else{
    setChecked(true)
  }
  setEachCheckInput(e.target.value);
}
  
const goBackPage = () => {
    NativeBridge.sendUserActionLog('detail', 'D092', '', '영업중');
  layerPopup.showCommon(
    '#make_call_before', 
    null, 
    '이전 화면으로 이동', 
    '통화연결음을 만들지 않고 이전화면으로 <br /> 이동하시겠어요?',
    [
         function() {
            layerPopup.hide('#make_call_before');//닫기
         },
         async function() { // 확인 버튼
            await userInfo.setPopupCheck("1");
            userData.setBgCheck(true);
            layerPopup.hide('#make_call_before',() => {
              history.goBack();
            });
         }
      ]
    )
}
const textAreaLabelControl = (e) => {
  if(e.target.id === "abc"){
    document.getElementsByName("callLabel7")[0].style.display = "none";
    document.getElementsByName("openTimeCallText")[0].focus();
  }
}
    
const saveButtonNone = () => {
  if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "none";
}
const saveButtonBlock = () => {
  if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "block";
}
const popupScrollControll = () => {
	if(userInfo.dataset.defaultGrt.ringEngName === "bg_01"){
		userData.setBgCheck(true);
	}
	layerPopup.show('#popup_prelisten');
  }

  return (
    <>
			<div className="content store" onBlur={saveButtonBlock}>
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=>goBackPage()}>뒤로가기</button>
						<h2>간편 통화연결음_영업 중 인사말</h2>
					</div>
				</div>
				<div className="ringback_greeting">
				    <div className="greet_sec">
				        <span className="title">배경음</span>
                <button type="button" className="sel_sound" value={userInfo.dataset.defaultGrt.ringEngName} onClick={()=>popupScrollControll()}>{userInfo.dataset.defaultGrt.ringName}<i className="btn_dark_down"></i></button>
				    </div>
				    <div className="greet_sec">
				        <span className="title">인사말</span>
                        <div className="tab_style4_radio tab3">
                           <input type="radio" id="sourceWoman" name="soundSource" value="0" checked={checked} onClick={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceWoman">여성</label>
                            <input type="radio" id="sourceMan" name="soundSource" value="1" onClick={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceMan">남성</label>
                            <input type="radio" id="sourceChild" name="soundSource" value="2" onClick={(e) => speakerHandelClick(e)} />
                                <label htmlFor="sourceChild">아이</label>
                        </div>
                        <div className="textarea_wrap textarea_greet">
                        <textarea onFocus={saveButtonNone} id="openTimeCall" name="openTimeCallText" value={comTextValue}    onChange={(e) => comTextOnChange(e)}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel7" style={{display : ""}} onClick={(e)=>textAreaLabelControl(e)} >
                                      예) 안녕하세요. 고객님! 우리가게 역삼점입니다.<br />
                                      잠시만 기다려 주시면 곧 통화가 연결됩니다.
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                        </div>
				    </div>
				</div>
				<div className="btn_box fixed" name="save_button">
                    <div className="inner">
                        <button type="button" className="btn_half7 btn_point usetap" onClick={()=>ttsPrePlayOnClick()} >미리듣기</button>
                        <button type="button" className="btn_half3 btn_line2 usetap" onClick={()=>saveOnHandleClick()} >저장</button>
                    </div>
                </div>
			</div>
            
      
    </>
  );
});
export default OpenTimeCallRing;
