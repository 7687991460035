import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { floatUI } from '../../../../libs/floating'
import { tabUi } from '../../../../libs/tab'
import SohoApi from "../../../../api/SohoApi";
import * as LocalStore from '../../../../store/LocalStore';
import { layerPopup } from "../../../../libs/common";
import { getAddressInfo, getAddressInfoForBlack } from "../../../../common/common";

//전화 설정 페이지
const CallSet = observer(({ history, match }) => {
  

  const { userInfo, userData } = store;
  const userList = userInfo.getList();
  // console.log(userList);
  
  const [mohInfo, setMohInfo] = useState(null);
  const [blackListInfo, setBlackListInfo] = useState(null);
  const [callFowardingData, setCallFowardingData] = useState(null);
  const [addressNumData, setAddressNumData] = useState([]);
  const [addressNameData, setAddressNameData] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [myServiceDetailList, setMyServiceDetailList] = useState(null);
  const [myTtsInfo, setMyTtsInfo] = useState(null); //본인의 tts 청약권 조회

  const [ttsOneTime, setTtsOneTime] = useState(false);        //1회권
  const [ttsRegular, setTtsRegular] = useState(false);        //정액형

  useEffect(() => {
    console.log("CallSet default useEffect..");
    if($('#wrap').hasClass('gnb_bottom')) {
      $('#wrap').removeClass('gnb_bottom')
      $('#wrap').addClass('fixed_bottom');
    }  
    
    getForwardType(); // 착신 전환 조회
    getMoh(); // 현재 설정된 통화연결음 조회
    getBlackList(); //수신 차단 조회
    addressList(); //주소록 조회
    if(LocalStore.getIsTempUser() === false){
      myServiceDetail(); //통화연결음 가입여부 조회
    }
    getTtsInfo(); //본인의 tts 청약권 조회

    return () => {
      if($('#wrap').hasClass('fixed_bottom')) {
        $('#wrap').removeClass('fixed_bottom');
        $('#wrap').addClass('gnb_bottom');
      }
  }
  }, []);

  useEffect(() => {
    forwarNumAddressNumCompare(callFowardingData,addressData)
  },[callFowardingData,addressData])

  const centrexIdCheck = () => {
    //const centrexId = LocalStore.getStoreInetNo();
    const centrexId = userData.dataset.currstoreInfo.lpzNo;
    //const tempCentrexId = LocalStore.getTempInetNo();
    const tempCentrexPass = LocalStore.getTempInetPass();
    return [centrexId ,tempCentrexPass]
  }
  
  const [resultNum, resultPass] = centrexIdCheck();


//통화연결음 가입 여부 조회
const myServiceDetail = async () => {
	const getMyServiceDetail = await SohoApi.getMyServiceDetailList({
		userId: LocalStore.getUserId(),
		ownerUserId : LocalStore.getOwnerId(), //사장님 고유키(계정 선택시 전달 받은 값)
		storId : LocalStore.getStoreId(), //매장 아이디
	});

	if ( getMyServiceDetail.retCode === '0000'){
		const datas = getMyServiceDetail.data.lpzList;

		if ( datas !== null && datas.length > 0){
			setTtsOneTime(
				datas.filter((item) => {
					if ( item.svcCd === '070_ADD_SVC_2' && item.svcNm.indexOf('1회') > 0 && item.useYn === 'Y') return item;
				}).length > 0
			);

			setTtsRegular(
				datas.filter((item) => {
					if ( item.svcCd === '070_ADD_SVC_2' && item.svcNm.indexOf('정액') > 0  && item.useYn === 'Y') return item;
				}).length > 0
			);
		};
		
	}

	const myServiceDetailData = getMyServiceDetail.data;
	setMyServiceDetailList(myServiceDetailData);
}

//본인의 TTS 청약된 권한 조회API
const getTtsInfo = async () => {
  const getTtsInfoData = await CentrexApi.getTtsInfoList({})
  setMyTtsInfo(getTtsInfoData);
}

//주소록 조회
const addressList = async () => {
  const getAddressList = await CentrexApi.getAddressListInfo({
    num_per_page : "99", // 옵션
    page : "1",
    sort : 1 // Type -> number 
  })
  const address = getAddressList.data;
  setAddressData(address);
}


  //착신전환 조회 API
const getForwardType = async () => {
  const getForwardTypeData = await CentrexApi.getForwardType({})
  const forwardData = getForwardTypeData?.data.DATAS
  userData.setCallFowarding(forwardData.FORWARD_USE);
  setCallFowardingData(forwardData);
}

 //수신 차단 목록 조회
  const getBlackList = async () => {
    const blackList = await CentrexApi.getBlackListInfo({})
    const blackListData = blackList.data
    setBlackListInfo(blackListData);
    userData.setReceiveBlock(blackListData.MODEINFO?.MODE);
  }

  //현재 설정된 통화연결음 조회
  const getMoh = async () => {
    const getMohData = await CentrexApi.getMohInfo({
      peer_exten: LocalStore.getExtenInetNo() // 값 : 조회할 내선번호, (고객관리자만 사용가능(대상 내선번호) 일반사용자는 빈값, 본인의 정보 내선 3자리일 경우 0XXX 형식)
    }).catch((e) => {
    //   layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(인터넷 전화)", [
    //     function() {
    //         layerPopup.hide("#popup_onetitlemsgbutton");
    //     }
    // ]);     
  })
    const mohData = getMohData.data
    setMohInfo(mohData);
  }

  // 주소록과 착신전환 번호 비교해서 주소록에 번호가 있다면 이름 출력 아니면 미지정
const forwarNumAddressNumCompare = (callForwarding, address) => {
  let callForwardingNum = [];
  let addressNum = [];
  let addressName = [];
  let test = getAddressInfoForBlack(callForwarding?.FORWARD_DATA);

  addressNum[0] = callForwarding?.FORWARD_DATA;

  if(test.bookType === "nodata"){
    addressName[0] = "미저장"
  }else{
    addressName[0] = test.name;
  }
  
  setAddressNumData(addressNum);
  setAddressNameData(addressName);

}

const goCallFowrarding = () => {
  NativeBridge.sendUserActionLog('menu', 'H013');
  history.push('/shop/call/callset/callfowarding');
}

const NativeCall = (e,msg) => {
  if(e.target.value === "(부가)통화연결음"){
    userData.setMyPageCallPopupData("(부가)통화연결음")
  }

  let menuCode = '';

  switch (msg) {
    case '1800-8000':
      layerPopup.show('#popup_ringregi');
      menuCode = 'P014'
      break;
    default:
      break;
  }
}

return (
  <>
        <div className="call_content">
            <div className="call_setting">
                <div className="set_box">
                  <button type="button" onClick={()=> goCallFowrarding()}>
                    <span className="set_title">착신 전환</span>
                        {
                          callFowardingData === null 
                          ?
                          <></>
                          :
                          <span className="set_status on">{callFowardingData?.FORWARD_USE === 'F' ? '설정함' : '해제'}<i className="ico_dark_more"></i></span>
                        }
                        <span className="user_info">
                        {
                          callFowardingData === null 
                          ? 
                          <></>
                          :
                          callFowardingData?.FORWARD_USE === 'F' 
                            ?
                            <>
                              <span className="i_name">{addressNameData.length < 1 ?  "미저장" : addressNameData }</span> 
                              <span className="i_num">{(callFowardingData?.FORWARD_DATA)?.toString().replace(/(\d{3})(\d{4})(\d{4})/g,'$1-$2-$3')}</span>
                            </>
                            : 
                            <span className="i_name">번호 설정 안됨</span>  
                        }
                        </span>
                  </button>
                </div>
                
                {/* 통화연결음 서비스는 가입 가입된 경우 상품 아이콘 표시 */}
                {
                  (userInfo.dataset.myservice.callRing === true || userInfo.dataset.isTempUser === true) 
                    ?
                    <>
                      <div className="set_box">
                        <button type="button" onClick={()=>history.push('/shop/call/callset/callring')}>
                          <span className="set_title">통화연결음 
								{/* {myTtsInfo?.data.DATAS.TTS_1 === 'Y' && <span className="i_tag">1회용 가입</span>}
								{myTtsInfo?.data.DATAS.TTS_Z === 'Y' && <span className="i_tag">정액형</span>} */}
								{!userInfo.dataset.isTempUser && ttsOneTime && <span className="i_tag">1회용</span>}
                                {!userInfo.dataset.isTempUser && ttsRegular && <span className="i_tag">정액형</span>}
                          </span>
                          {
                            mohInfo === null 
                            ?
                            <></>
                            :
                            <span className="set_status on">{mohInfo?.DATAS.MOHCLASS === "" && mohInfo?.SVC_RT !== "1103" ? '해제' : '설정함'}<i className="ico_dark_more"></i></span>
                          }
                        </button>
                      </div>
                    </>
                    :
                    <>
                    <div className="set_box">
                          <button type="button">
                            <span className="set_title">통화연결음</span>										
                          </button>
                          <div className="btn_round_wrap">
                            <button type="button" className="btn_round purple usetap" value={"(부가)통화연결음"}  onClick={(e)=>NativeCall(e,'1800-8000')}>
                              가입필요
                            </button>
                          </div>
                    </div>
                    </>
                }
                <div className="set_box_2depth">
                    <button type="button" onClick={()=>{history.push('/shop/call/callset/receiveblockset'); NativeBridge.sendUserActionLog('detail', 'D034');}}>
                      <span className="set_title" >수신 차단 </span>
                      <i className="ico_dark_more"></i>
                    </button>
                    <ul>
                        <li>
                          <span className="depth2_title">차단 설정한 번호만 차단</span>
                          {
                            blackListInfo === null 
                            ? 
                            <></>
                            : 
                            <span className="set_status on">{blackListInfo?.MODEINFO?.MODE === '2' ? '설정함' : '해제'}</span>
                          }
                       </li>
                       <li>
                          <span className="depth2_title">차단 설정한 번호, 저장 안된 번호 차단</span>
                          {
                            blackListInfo === null 
                            ? 
                            <></>
                            : 
                            <span className="set_status on">{blackListInfo?.MODEINFO?.MODE === '6' ? '설정함' : '해제'}</span>
                          }
                       </li>
                       <li>
                          <span className="depth2_title">모든 전화 차단</span>
                          {
                            blackListInfo === null 
                            ? 
                            <></>
                            : 
                            <span className="set_status on">{blackListInfo?.MODEINFO?.MODE === '3' ? '설정함' : '해제'}</span>
                          }
                      </li>
                   </ul>
                </div>
            </div>
        </div>
  </>
);
});
export default CallSet;
