import React from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as NativeBridge from '../../../utils/NativeBridge'


const Albabanner = observer(() => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);

    const goPageOnClick = (urls=null) => {
        let menuCode = '';
        if (urls !== null && urls.indexOf('sohoshop.uplus.co.kr') > -1) {
            menuCode = 'H010'
        } else if (urls !== null && urls.indexOf('m.alba.co.kr') > -1) {
            menuCode = 'H011'
        } 

        if (menuCode !== '') {
            NativeBridge.sendUserActionLog('menu', menuCode);
        }
        NativeBridge.externalCall(`${urls}`)
    }
    
    return (
        <>
            <div className="content_box">
                <div className="sec_wrap bn_wrap">
                    <img src='/res/images/img-banner3@3x.png' onClick={()=>goPageOnClick('https://sohoshop.uplus.co.kr/soho/v1/ev/ceo/squareDetail?detailType=10&cetiNum=lgusoho')}></img>
                </div>
            </div>
        </>
    )
    
});
export default Albabanner;