import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";
import { tabUi } from "../../libs/tab"

const GroupContactAddPopup = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);


    const [checkState, setCheckState] = useState([]);

    useEffect(() => {
        tabUi.init3DepthTab(document.getElementById('tab_style4'));
        store.userData.setPhonebookCnt($('#popup_groupcontact .checkbox_green').length);
        tabUi.init3DepthTab(document.getElementById('memo_cate'));
    }, [])

    useEffect(() => {
        //for wake up ( show ) change value = sendMsgGroupPopupToggle
        console.log("change userData.dataset.sendMsgGroupPopupToggle =>", userData.dataset.sendMsgGroupPopupToggle);
       
    }, [userData.dataset.sendMsgGroupPopupToggle]);

    

    const SelectAllClick = (e) => {
        const checkboxes = document.getElementsByName('contactAdd');

        checkboxes.forEach((checkbox) => {
          checkbox.checked = e.checked
        })

        const query = 'input[name="contactAdd"]:checked';
        const selectedElements = document.querySelectorAll(query);
        const selectedElementsCnt = selectedElements.length;
        document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
    }

    const CheckedClick = () => {
        const checkboxes = document.querySelectorAll('input[name="contactAdd"]');
        //선택된 목록 가져오기
        const checked = document.querySelectorAll('input[name="contactAdd"]:checked');
        const selectAll = document.querySelector('input[name="contactAll"]');
        
        if(checkboxes.length === checked.length)  {
          checkboxes.checked = true;
        } else {
            checkboxes.checked = false;
        }
        
        const query = 'input[name="contactAdd"]:checked';
        const selectedElements = document.querySelectorAll(query);
        // 선택된 목록의 갯수 세기
        const selectedElementsCnt = selectedElements.length;
        // 출력
        document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
    }
    
   

    return (
        <>
            <div className="modal fadeInUp" id="popup_groupcontact">
                <div className="dim" onClick={()=>layerPopup.hide('#popup_groupcontact')}></div>
                <div className="modal_inner modal_up max_h">
                    <div className="modal_group_add contact">
                        <span className="modal_title">그룹추가</span>
                        <div className="chk_wrap">
                            <input type="checkbox" id="contactGroupAll" name="contactAll" onClick={(e)=>SelectAllClick(e.currentTarget)} />
                            <label htmlFor="contactGroupAll" className="btn_all_sel">모두선택</label>
                        </div>
                        <span className="info_chk_sel"></span>
                        <div className="input_box style1">
                            <input type="text" placeholder="이름, 전화번호를 검색해보세요." />
                            <span className="ip_clear hidden usetap">초기화</span>
                            <span className="ico_srh"></span>
                        </div>
            {/* <!-- 210616 추가 --> */}
			<div className="contact_cate row">
				<div className="btn_cate_wrap2" id="memo_cate">
					<button type="button" className="tab_item active">인터넷 전화 주소록</button>
					<button type="button" className="tab_item">휴대폰 주소록</button>
				</div>
				{/* <button type="button" className="btn_refresh_txt usetap"></button> */}
			</div>
                        {/*  */}
                        <div className="top_info">
                            <span className="total_num"><b><span id="chk_sel_num">0</span>명 선택</b> / 총 {store.userData.dataset.phonebookCnt}명</span>
                        </div>
                        <div className="contact_list_wrap">
                            <span className="devide_bar row">ㄱ</span>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd1" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd1" className="usetap">
                                    <span className="i_name">강가람</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd2" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd2" className="usetap">
                                    <span className="i_name">건우림</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd3" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd3" className="usetap">
                                    <span className="i_name">김겨루</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd4" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd4" className="usetap">
                                    <span className="i_name">김훈김훈김훈</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                            <span className="devide_bar row">ㄴ</span>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd5" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd5" className="usetap">
                                    <span className="i_name">나연호</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd6" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd6" className="usetap">
                                    <span className="i_name">나은준</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroupAdd7" name="contactAdd" onClick={()=> CheckedClick()} />
                                <label htmlFor="contactGroupAdd7" className="usetap">
                                    <span className="i_name">나은하</span>
                                    <span className="i_num">010-1234-5678</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn_box fixed">
                    <div className="inner">
                        <button type="button" className="btn_half btn_line2 usetap" onClick={()=>layerPopup.hide('#popup_groupcontact')}>취소</button>
                        <button type="button" className="btn_half btn_point usetap">추가</button>
                    </div>
                </div>
            </div>
        </>
    );
    });
    export default GroupContactAddPopup;