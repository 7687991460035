import React from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import { Link } from 'react-router-dom';

const OrderListUnSub = observer(() => {
    const { userData } = store;

    const goSsisicga = () => {
        userData.setSisicMainTabList('order');
        userData.setOrderTabList('neworder');
    }
    
    return (
        <> 
            <div className="content_box">         
                <Link to="/ssisicga/order/neworder" className="sec_link usetap" onClick={()=> goSsisicga()}><i className="ico_dark_more"></i>더보기</Link> 
                <span className="cont_title">씨식가</span>
                <div className="sec_wrap unsubs_img">
                    <img src="res/images/img-home-ssisik-card-join@3x.png" alt='홈 씨식가 미가입 이미지1' />
                    <Link to="/ssisicga/order/neworder"><button type="button" onClick={()=>goSsisicga()}><img src="res/images/btn-home-cashnote-card-join@3x.png" alt="씨식가 시작하기" /></button></Link>
                </div>
            </div>
        </>
    )
});

export default OrderListUnSub;