import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store';
import $ from 'jquery';
import SohoApi from '../../../../api/SohoApi';

const MyPolicyContent = observer(({ history, match }) => {

    const { userInfo, userData } = store;
    const [termsagree, setTermsagree] = useState(null)
    const [termsagreedetail, setTermsagreedetail] = useState(null)

    useEffect(() => {
        termsAgreeDetailInfo();
        var obj = document.getElementById("wrap");
        obj.setAttribute("id", "wrap_soho");

        if($('#wrap').hasClass('fixed_bottom')) {
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').removeClass('gnb_bottom')
        }          


        return () => {
            var obj = document.getElementById("wrap_soho");
            obj.setAttribute("id", "wrap");
            if($('#wrap').hasClass('fixed_bottom')) {
                $('#wrap').removeClass('fixed_bottom')
                $('#wrap').addClass('gnb_bottom');
            }
            // $('#wrap').addClass('gnb_bottom');

        }
    }, [])

    const termsAgreeDetailInfo = async() => {
        const termsAgreeDetailData = await SohoApi.getTermsAgreeDetailList({ "prvsId": `${userData.dataset.policyAccessDetail}` });
        setTermsagreedetail(termsAgreeDetailData.data);
        console.log('setTermsagreedetail', termsAgreeDetailData.data);
    }

    return (
        <>
            <div className="page_title_wrap">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> history.goBack()}>뒤로가기</button>
                    <h2>약관 상세 보기</h2>
                </div>
            </div>
            { termsagreedetail !== "" &&
                <div className="terms_content2" dangerouslySetInnerHTML={{__html: termsagreedetail?.cntn}}></div>
            }
            <div className="btn_box fixed">
                <div className="inner">
                    <button type="button" className="btn_lg btn_point usetap" onClick={()=> history.goBack()}>확인</button>
                </div>
            </div>
        </>
    )
    
});
export default MyPolicyContent;