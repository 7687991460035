import React, { useEffect, useState } from 'react'
import moment from 'moment'
import SohoApi from '../../../api/SohoApi';
import * as CONSTS from '../../../const/index'
import { isDataCheck } from '../../../common/common';
import { observer } from 'mobx-react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import userData from '../../../store/userData';

Chart.register(ArcElement);
Chart.register(ChartDataLabels);

const Analyze = observer(() => {

    const [onlineBoardInfo, setOnlineBoardInfo] = useState([]);
    const [storeBoardInfo, setStoreBoardInfo] = useState([]);
    const [likeProductList, setLikeProductList] = useState([]);
    const [bestProductList, setBestProductList] = useState([]);
    const [onlineInflowPath, setOnlineInflowPath] = useState([]);
    const [boardMsg, setBoardMsg] = useState([]);
    const [onlineCnt, setOnlineCnt] = useState(0);
    const [bfOnlineCnt, setBfOnlineCnt] = useState(0);
    const [likeCnt, setLikeCnt] = useState(0);
    const [bfLikeCnt, setBfLikeCnt] = useState(0);
    const [shareCnt, setShareCnt] = useState(0);
    const [bfShareCnt, setBfShareCnt] = useState(0);
    const [onlineInflowTop, setOnlineInflowTop] = useState('');

    let upMsg = [];
    let colorArr = [];

    useEffect(() => {
        async function fetchBoardInfo() {
            await getBoardInfo()
        }
        async function fetchBestProductList() {
            await getBestProductList()
        }
        async function fetchOnlineInflowPath() {
            await getOnlineInflowPath()
        }
        if(userData.dataset.sissicgaRegiYn === 'Y') {
            fetchBoardInfo();
            fetchBestProductList();
            fetchOnlineInflowPath();
        }
    }, [userData.dataset.currstoreInfo]);


    useEffect(() => {
        if(isDataCheck(onlineBoardInfo)) {
            setOnlineCnt(parseInt(onlineBoardInfo.weekOnlineCnt));
            setBfOnlineCnt(parseInt(onlineBoardInfo.bfWeekOnlineCnt));
            setLikeCnt(parseInt(onlineBoardInfo.weekLikeCnt));
            setBfLikeCnt(parseInt(onlineBoardInfo.bfWeekLikeCnt));
            setShareCnt(parseInt(onlineBoardInfo.weekShareCnt));
            setBfShareCnt(parseInt(onlineBoardInfo.bfWeekShareCnt));
        }
    }, [onlineBoardInfo])

    useEffect(() => {
        if(onlineCnt - bfOnlineCnt > 0) {
            upMsg.push('방문');
        }

        if(likeCnt - bfLikeCnt > 0) {
            upMsg.push('좋아요');
        }

        if(shareCnt - bfShareCnt > 0) {
            upMsg.push('공유');
        }
        setBoardMsg(upMsg);
    }, [bfShareCnt])

    useEffect(() => {
        if(isDataCheck(onlineInflowPath)) {
            const inflowMaxValue = onlineInflowPath.reduce((prev, current) => (parseInt(prev.cnt) > parseInt(current.cnt)) ? prev : current);
            setOnlineInflowTop(inflowMaxValue.flowName);
        }   
    }, [onlineInflowPath])
    
    const getBoardInfo = async() => {
        try {
            await SohoApi.getBoardInfo({
                storeId: userData.dataset.storeId
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setOnlineBoardInfo(rst.data.onlineBoard)
                    setStoreBoardInfo(rst.data.storeBoard)
                }
            }).catch((e) => {
                console.log(e);
            })
        } catch (e) {
            console.log(e);
        }
    }

    const getBestProductList = async() => {
        try {
            await SohoApi.getBestProductList({
                storeId: userData.dataset.storeId 
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setLikeProductList(rst.data.likeProductList);
                    setBestProductList(rst.data.bestProductList);
                }
            }).catch((e) => {
                console.log(e);
            })
        } catch (e) {
            console.log(e);
        }
    }

    const getOnlineInflowPath = async() => {
        try {
            await SohoApi.getOnlineInflowPath({
                storeId: userData.dataset.storeId
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    if(isDataCheck(rst.data.onlineInflowList)) {
                        // color 값 삽입
                        rst.data.onlineInflowList.map((item, idx) => {
                            if(item.flowType === 'I') {
                                rst.data.onlineInflowList[idx].color = '#8253e1';
                            } else if(item.flowType === 'N') {
                                rst.data.onlineInflowList[idx].color = '#5ac4e6';
                            } else if(item.flowType === 'K') {
                                rst.data.onlineInflowList[idx].color = '#eeedf1';
                            } else {
                                rst.data.onlineInflowList[idx].color = '#6f82fe';
                            }
                        })

                        // color 수량 대소 비교
                        rst.data.onlineInflowList.sort(function(a, b) {
                            return parseInt(b.cnt) - parseInt(a.cnt);
                        });    
                        setOnlineInflowPath(rst.data.onlineInflowList);
                    }
                }
            }).catch((e) => {
                console.log(e);     
            })
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="fix_container food_analysis">
            { (isDataCheck(storeBoardInfo) || isDataCheck(onlineBoardInfo)) &&
                <div className="sec_wrap">
                    <div className="order_stats">
                        <span className="title">씨식가 주문 현황</span>
                        <span className="text_highlight">
                            <span className="i_text">지난 주 보다 주문이 <b>{Math.abs(parseInt(storeBoardInfo.weekCnt) - parseInt(storeBoardInfo.bfWeekCnt))}건</b> 
                                { parseInt(storeBoardInfo.weekCnt) - parseInt(storeBoardInfo.bfWeekCnt) > 0 ? ' 더 들어왔어요.' : ' 줄었어요.' }
                            </span>
                        </span>
                        <div className="today_stats">
                            <span className="i_title">오늘 주문 건 수</span>
                            <span className="num_wrap">
                                <em className="num_stats">{storeBoardInfo.todayCnt}건</em>
                            </span>
                        </div>
                        <div className="week_stats">
                            <span className="i_title">7일 간 주문 건 수</span>
                            <span className="num_wrap">
                                <em className="num_stats">{storeBoardInfo.weekCnt}건</em>
                                <span className={"ico_stats" + (parseInt(storeBoardInfo.weekCnt) - parseInt(storeBoardInfo.bfWeekCnt) > 0 ? ' up' : parseInt(storeBoardInfo.weekCnt) - parseInt(storeBoardInfo.bfWeekCnt) < 0 ? ' down' : ' same')}>
                                    {Math.abs(parseInt(storeBoardInfo.weekCnt) - parseInt(storeBoardInfo.bfWeekCnt)) === 0 ? '' : `${Math.abs(parseInt(storeBoardInfo.weekCnt) - parseInt(storeBoardInfo.bfWeekCnt))}건`}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="week_status">
                        <span className="title">주간 온라인 현황</span>
                        <span className="text_highlight">
                            <span className="i_text">지난 주 보다 <b>{boardMsg.join(', ')}</b>{boardMsg.toString() === '방문' ? '이' : "가"} 활성화되었네요.</span>
                        </span>
                        <div className="box_wrap">
                            <span className="status_box i_online">
                                <span className="ico_img"></span>
                                <span className="i_title">온라인 방문 수</span>
                                <em className="num_stats">{onlineCnt}건</em>
                                <span className={"ico_stats" + (onlineCnt - bfOnlineCnt > 0 ? ' up' : onlineCnt - bfOnlineCnt < 0 ? ' down' : ' same')} value='방문'>
                                    {Math.abs(onlineCnt - bfOnlineCnt) === 0 ? '' : `${Math.abs(onlineCnt - bfOnlineCnt)}건`}
                                </span>
                            </span>
                            <span className="status_box i_favorite">
                                <span className="ico_img"></span>
                                <span className="i_title">전체 좋아요 수</span>
                                <em className="num_stats">{likeCnt}건</em>
                                <span className={"ico_stats" + (likeCnt - bfLikeCnt > 0 ? ' up' : likeCnt - bfLikeCnt < 0 ? ' down' : ' same')} value='좋아요'>
                                    {Math.abs(likeCnt - bfLikeCnt) === 0 ? '' : `${Math.abs(likeCnt - bfLikeCnt)}건`}
                                </span>
                            </span>
                            <span className="status_box i_share">
                                <span className="ico_img"></span>
                                <span className="i_title">공유 수</span>
                                <em className="num_stats">{shareCnt}건</em>
                                <span className={"ico_stats" + (shareCnt - bfShareCnt > 0 ? ' up' : shareCnt - bfShareCnt < 0 ? ' down' : ' same')} value='공유'>
                                    {Math.abs(shareCnt - bfShareCnt) === 0 ? '' : `${Math.abs(shareCnt - bfShareCnt)}건`}
                                </span>
                            </span>
                        </div>
                        <p className="i_info"><i className="ico_info"></i>{`${moment().format('YYYY.MM.DD HH:mm')} 기준, 7일 간 정보입니다.`}</p>
                    </div>
                </div>
            }
            <div className="sec_wrap">
                <span className="title">우리 가게 인기 메뉴</span>
                { isDataCheck(bestProductList) &&
                    <span className="text_highlight">
                        <span className="i_text">이번 주 우리 가게 인기 메뉴는 <b>{bestProductList[0].prdtNm}</b>이에요.</span>
                    </span>
                }
                <div className="rank_wrap">
                    { isDataCheck(bestProductList) ?
                        <div className="rank_box order_rank">
                            <span className="rank_title">주문 순위</span>
                            <p>씨식가에서 판매량이 높은 메뉴에요.</p>
                            <ul>
                                {/* TODO: 이미지 경로 및 변동 순위 알려주는 구분자 필요 */}
                                { bestProductList.map((item, index) => (
                                    <li key={item.prdtId}>
                                        { !isDataCheck(item.mediaPath) ?
                                            <span className="food_thumb"></span>
                                        :
                                            <span className="food_thumb" style={{backgroundImage: `url('${item.mediaPath}')`}}></span>
                                        }
                                        <span className="rank_num">
                                            <em className="num_stats">{index + 1}위</em>
                                            <span className={"ico_stats" + (parseInt(item.ranking) - parseInt(item.bfRanking) < 0 ? ' up' : parseInt(item.ranking) - parseInt(item.bfRanking) > 0 ? ' down' : ' same')}>
                                                { item.ranking - item.bfRanking === 0 ? '' : Math.abs(item.ranking - item.bfRanking) }
                                            </span>
                                        </span>
                                        <span className="food_name">{item.prdtNm}</span>
                                    </li>
                                ))

                                }
                            </ul>
                        </div>
                    :
                        <></>
                    }
                    { isDataCheck(likeProductList) ?
                        <div className="rank_box favorite_rank">
                            <span className="rank_title">관심도 순위</span>
                            <p>메뉴 별 ‘좋아요’수와 ‘체류 시간’이 긴 메뉴에요.</p>
                            <ul>
                                {/* TODO: 이미지 경로 및 변동 순위 알려주는 구분자 필요 */}
                                { likeProductList.map((item, index) => (
                                    <li key={item.prdtId}>
                                        { !isDataCheck(item.mediaPath) ?
                                            <span className="food_thumb"></span>
                                        :
                                            <span className="food_thumb" style={{backgroundImage: `url('${item.mediaPath}')`}}></span>
                                        }
                                        <span className="rank_num">
                                            <em className="num_stats">{index + 1}위</em>
                                            <span className={"ico_stats" + (parseInt(item.ranking) - parseInt(item.bfRanking) < 0 ? ' up' : parseInt(item.ranking) - parseInt(item.bfRanking) > 0 ? ' down' : ' same')}>
                                                {item.ranking - item.bfRanking === 0 ? '' : Math.abs(item.ranking - item.bfRanking)}
                                            </span>
                                        </span>
                                        <span className="food_name">{item.prdtNm}</span>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                    :
                        <></>
                    }
                </div>
            </div>
            { isDataCheck(onlineInflowPath) &&
                <>
                    <div className="sec_wrap">
                        <span className="title">씨식가 방문 경로</span>
                        <span className="text_highlight">
                            <span className="i_text"><b>{onlineInflowTop}</b>에서 가장 많이 들어왔네요!</span>
                        </span>
                        <div className="route_visit">
                            <div className="chart_wrap">
                                <Doughnut 
                                    data={{
                                        datasets: [{
                                            data: onlineInflowPath.map((item) => (item.cnt)),
                                            backgroundColor: onlineInflowPath.map((item) => (item.color)),
                                            borderWidth: 3,
                                            options: {
                                                tooltip: {
                                                    enabled: false
                                                },
                                                hover: {
                                                    mode: null
                                                }
                                            }
                                        }]
                                    }}
                                    plugins={ChartDataLabels}
                                    options={{
                                        plugins: {
                                            tooltip: {
                                                enabled: false
                                            },
                                            datalabels:{
                                                formatter: function (value, context) {
                                                    let datasets = context.chart.data.datasets[0].data;
                                                    if(value !== 0) {
                                                        let sum =0;
                                                        datasets.map(data => {
                                                            sum += parseInt(data);
                                                        })
                                                        let percentage = Math.round((value * 100 / sum)) + "%"
                                                        return percentage;
                                                    } else {
                                                        let percentage = '';
                                                        return percentage;
                                                    }
                                                },
                                                font: function (context) {
                                                    var avgSize = Math.round((context.chart.height + context.chart.height) / 2);
                                                    var size = Math.round(avgSize / 12);
                                                    return {
                                                        family: 'Roboto',
                                                        size: size,
                                                        weight: 'bold'
                                                    };
                                                },
                                                color:'#2e2e30',
                                                backgroundColor: '#ffffff',
                                                borderRadius: 40,                
                                                padding: {
                                                    top: 6,
                                                    right: 2,
                                                    bottom: 6,
                                                    left: 2,
                                                },                
                                            },
                                        }
                                    }}
                                    />                                 
                            </div>
                            <ul className="route_desc">
                                { onlineInflowPath.map((item, index) => (
                                    <li className={ 
                                        item.flowType === 'I' ? "data_insta" : 
                                        item.flowType === 'N' ? "data_naver" :
                                        item.flowType === 'K' ? "data_kakao" :
                                        item.flowType === 'S' ? "data_share" :
                                        <></>}>
                                        <span className="i_name">
                                            {item.flowName}
                                        </span>
                                        <span className="i_num">{ parseInt(item.cnt) > 999 ? '999+' : item.cnt }회</span>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                    </div>
                </>
            }
            <div className="sec_wrap">
                <span className="title">같은 업종에서 인기 메뉴</span>
                <span className="text_highlight">
                    <span className="i_text"><b>일식</b>에서 ‘좋아요’를 가장 많이 받은 메뉴에요!</span>
                </span>
                <ul className="popuplar_list">
                    <li>
                        <span className="food_thumb" style={{ backgroundImage: "url('/res/images/best_product_1.jpg')"}}></span>
                        <div className="rank_wrap">
                            <span className="i_rank">1위</span>
                        </div>
                    </li>
                    <li>
                        <span className="food_thumb" style={{ backgroundImage: "url('/res/images/best_product_2.jpg')"}}></span>
                        <div className="rank_wrap">
                            <span className="i_rank">2위</span>
                        </div>
                    </li>
                    <li>
                        <span className="food_thumb" style={{ backgroundImage: "url('/res/images/best_product_3.jpg')"}}></span>
                        <div className="rank_wrap">
                            <span className="i_rank">3위</span>
                        </div>
                    </li>
                    <li>
                        <span className="food_thumb" style={{ backgroundImage: "url('/res/images/best_product_4.jpg')"}}></span>
                        <div className="rank_wrap">
                            <span className="i_rank">4위</span>
                        </div>
                    </li>
                    <li>
                        <span className="food_thumb" style={{ backgroundImage: "url('/res/images/best_product_5.jpg')"}}></span>
                        <div className="rank_wrap">
                            <span className="i_rank">5위</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
})

export default Analyze