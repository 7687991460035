import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import { layerPopup } from "../../libs/common";
import { msgUI } from "../../libs/msg";
import {setCctvToken, setTempCctvToken} from '../../api/CctvApi';
import {setCenterexUserIdPass, setCentrexSsoKey} from '../../api/CentrexApi';
import SohoApi from "../../api/SohoApi";
import CentrexApi from "../../api/CentrexApi";

const SohoCentrexAuthErrorPopup = observer(({ history }) => {

    const { userInfo, userData } = store;
    const goNext = () => {
        console.log("SohoCentrexAuthErrorPopup CAll goNext");
        layerPopup.hide("#popup_soho_centrex_auth_error");
        SohoApi.getCentrexAuth({storeInetNo: LocalStore.getStoreInetNo()});
    };

    return (
        <>
            <div className="modal fadeIn" id="popup_soho_centrex_auth_error">
                <div className="dim"></div>
                <div className="modal_inner">
                    <div className="modal_content">
                        <div className="modal_hd">알림</div>
                        <div className="modal_bd">
                            <p>인터넷전화 로그인에 실패하였습니다.</p>
                        </div>
                        <div className="modal_ft">
                            <button type="button" className="btn usetap" onClick={goNext}><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    );
});
export default SohoCentrexAuthErrorPopup;