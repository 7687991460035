import { observer } from 'mobx-react'
import React, { useEffect, useState } from "react";
import { layerPopup } from '../../libs/common';
import store from "../../store";
import CentrexApi from '../../api/CentrexApi';

const MessagePreviewPopup = observer(() => {

    return (
    <div className="modal fadeInUp" id="popup_msgpreview">
        <div className="dim" onClick={()=> layerPopup.hide('#popup_msgpreview')}></div>
        <div className="modal_inner modal_up max_h full_h">
            <span className="modal_title">메시지 미리보기</span>
            <div className="modal_msg_preview">
                <p className="preview_ip">영업시간 안내</p>
                <span className="preview_img" style={{backgroundImage:"url('res/images/img_dummy.jpg')"}}></span>
                <p className="preview_textarea pre_line"></p>
                <div className="btn_box bottom_box">
                    <button type="button" className="btn_lg btn_point usetap">확인</button>
                </div>
            </div>
        </div>
    </div>
    )
});

export default MessagePreviewPopup
