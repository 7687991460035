import { observer } from 'mobx-react'
import React, {useEffect, useState} from 'react'
import SohoApi from '../../../../api/SohoApi'
import { layerPopup, toast } from '../../../../libs/common'
import store from '../../../../store'
import { isNullData, numberWithCommas } from '../../../../common/common'
import { getSimpleDate } from '../../../../common/common'
import userData from '../../../../store/userData'
import * as CONSTS from '../../../../const/index'

const OrderNew = observer(({ history }) => {

    const [orderList, setOrderList] = useState([]);

    useEffect(() => {
        async function fetchOrderList() {
            await getOrderListApi();
        }
        fetchOrderList();
    },[userData.dataset.currstoreInfo])

    const getOrderListApi = async () => {
        try {
            await SohoApi.getOrderList({
                "storeId": userData.dataset.storeId,
                "ordDate": getSimpleDate()
                // "ordDate": "20220330"
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setOrderList(rst.data.orderList);
                    userData.setNewOrderLength(rst.data.orderList.length);
                }
            }).catch((e) => {
                toast('주문 목록을 가져오는데 실패했습니다. 다시 시도해 주세요');
            })
        } catch(e) {
            toast('주문 목록을 가져오는데 실패했습니다. 다시 시도해 주세요');
        }
    }

    const orderConfirm = async (e) => {
        const orderId = e.target.id;

        try {
            await SohoApi.getOrderStatus({
                "storeId" : userData.dataset.storeId,
                "ordId" : orderId
            }).then(async(rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    await getOrderListApi()
                    toast("해당 주문은 처리완료 되었습니다.");
                }
            }).catch((e) => {
                console.log(e);
                toast("주문 완료 처리에 실패했습니다. 다시 시도해 주세요");
            })
        } catch {
            toast("주문 완료 처리에 실패했습니다. 다시 시도해 주세요");
        }
    }

    const refresh = async() => {
        await getOrderListApi();
    }

    return (
        <>
            <div className="top_info">
                <span className="total_num">총 {orderList.length === 0 ? '0' : orderList.length}개</span>
                {/* <!-- 신규주문 시 --> */}
                <button type="button" className="btn_refresh_txt usetap" onClick={() => refresh()}><i className="ico_refresh"></i>새로고침</button>
            </div>
            <div className="order_list">
                { orderList?.length > 0 && !isNullData(orderList) ?
                orderList?.map((items, idx) => (
                    items.stus === 'N' &&
                    <div className="order_box" key={items.ordId}>
                        <span className="order_time">주문시간 {items.sysOrdDttm}</span>
                        <span className="tbl_num"><span className="i_num"># {items.tblNum}번</span> 테이블</span>
                        {items?.orderProductList?.map((item, idx) => (
                            <>
                                <span className="food_name">{item.prdtNm} {item.prdtCnt}개</span>
                                {item?.options?.options?.map((option) => (
                                    <span className="food_opt">{option?.optionDesc}</span>
                                ))}
                             </>
                        ))}
                        <div className="order_sum">
                            <span className="price_sum">{numberWithCommas(items.totalPrice)}원</span>
                            <button type="button" id={items.ordId} onClick={(e) => orderConfirm(e)} className="btn_round2 purple usetap">주문확인</button>
                        </div>
                    </div>
                ))
                    :
                    <div className="data_none">
                        <div className="none_wrap">
                            <span className="none_img"></span>
                            <p>씨식가에 아직 주문이 <br/>들어오지 않았어요.</p>
                        </div>
                    </div>
                }
            </div>
        </>
    )
})

export default OrderNew