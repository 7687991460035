import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";
import * as NativeBridge from '../../utils/NativeBridge';
import { ReactSortable } from 'react-sortablejs'; 

const HomeCardEditPopups = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    
    console.log(userList);

    const [homecardlist, setHomecardlist] = useState([]);

    useEffect(() => {
        setHomecardlist(userData.dataset.homecard)
    }, [userData.dataset.homecard])

    const ClickClosePopup = () => {
        //추가 - 12.08
        NativeBridge.sendUserActionLog('menu', 'H030');

        layerPopup.hide('#popup_homecardedit');
    };

    const HomeCardEditClick = () => {
        let homeCard = []
        let currHomeCard = []
        
        const card = $('ul.modal_edit_card').children('li');

        $.each(card, function (index, item) {
            const hposImge = homecardlist[index].hposImge
            const cardId = $(item).attr('data-id');
            const cardName = $(item).children('span').text();
            const svcCd = homecardlist[index].svcCd
            const svcRegiYn = homecardlist[index].svcRegiYn
            const urlConn = homecardlist[index].urlConn
            const urlConnImge = homecardlist[index].urlConnImge
            homeCard.push({ hposImge: hposImge, svcCardId: cardId, svcCardNm: cardName, 
                            svcRegiYn: svcRegiYn, svcCd: svcCd, urlConn: urlConn,
                            urlConnImge: urlConnImge  });
        });
        currHomeCard.push({ storeId: LocalStore.getStoreId(), homecardList: homeCard })
        console.log('현재 홈카드', currHomeCard);
        setHomecardlist(homeCard);
        userData.setHomeList(homeCard, true)

        userData.setCallPageReset(true);

        layerPopup.hide('#popup_homecardedit');
        
        //홈화면 위치 정렬 변경 플래그
        userData.setHomeChFlag(true);
        userData.setHomeWorkerChFlag(true);
        userData.setHomeWeatherChFlag(true);

        window.scrollTo(0, 0);

        //추가 - 12.08
        NativeBridge.sendUserActionLog('menu', 'H029', '', homeCard.map(item=>item.svcCd).join("-"));

    }

  return (
    <>
        <div className="modal fadeInUp" id="popup_homecardedit">
            <div className="dim" onClick={()=> layerPopup.hide('#popup_homecardedit')}></div>
            <div className="modal_inner modal_up">
                <button type="button" className="pop_close usetap blind" onClick={()=>ClickClosePopup()}>닫기</button>
                <span className="modal_title">홈 화면 순서 편집</span>
                    <ReactSortable
                        tag="ul"
                        id="edit_card_sec"
                        list={homecardlist}
                        setList={setHomecardlist}
                        className="modal_edit_card"
                        animation={200}>
                    {   homecardlist?.length &&
                        homecardlist?.map((item) => (
                            <li className="usetap" key={item.svcCardId}>
                                <span className="title" >{item.svcCardNm}</span>
                            </li>
                        ))
                    }
                    </ReactSortable>
                <div className="btn_box bottom_box">
                    <button type="button" className="btn_lg btn_point usetap" onClick={()=> HomeCardEditClick()}>완료</button>
                </div>
            </div>
        </div>
    </>
  );
});
export default HomeCardEditPopups;