import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import { layerPopup } from '../../libs/common';
import $ from 'jquery';
import SohoApi from '../../api/SohoApi'
import * as NativeBridge from '../../utils/NativeBridge';

const MyTemporaryAccount = observer(({ history }) => {

    const { deviceInfo } = store;
    const [appversion, setAppversion] = useState("")

    useEffect(() => {       
        getAppVersionList();

        // 클래스 추가 제거
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').addClass('bg_gray');
        }          
        return () => {
            if($('#wrap').hasClass('bg_gray')) {
                $('#wrap').addClass('gnb_bottom')
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').removeClass('bg_gray');
            }
        };
    }, []);
    
    const getAppVersionList = async () => {
        const appversionData = await SohoApi.getAppVersionList(`${deviceInfo.dataset.os_type}`)
            setAppversion(appversionData.data.appVersion);
            console.log("getAppVersionList",appversionData.data.appVersion);
    }

    const UpdateClick = (currVersion, updateVersion) => {
        if(currVersion < updateVersion) {
            layerPopup.showCommon(
                '#version_store_popup',
                null,
                '업데이트 알림',
                '새로운 버전이 나왔습니다.<br /> 지금 업데이트하고 더 안정적인<br /> 서비스를 이용하시겠어요?',
                [
                    function() {
                        layerPopup.hide("#version_store_popup")
                    },
                    function() {
                        layerPopup.hide("#version_store_popup", () => {
                            if(deviceInfo.dataset.os_type === 'android') {
                                NativeBridge.externalCall('https://play.google.com/store/apps/details?id=com.lguplus.sohoapp')
                            } else {
                                NativeBridge.externalCall('https://itunes.apple.com/app/id1571096278')
                            } 
                        })
                    },
                ]
            )
        }
    }

    const SettingTabPinCodeClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D054')
        history.push('/pincode/checkreg')
    }

    const SettingBioClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D055')
        history.push("/my/mysetting/biosetting")
    }

    const SettingNoticeClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D060')
        history.push("/my/mysetting/cs")
    }

    return (
        <>
			<div className="content my">
				<div className="my_set_top">
				    <h1>임시 사용 계정</h1>
                    <p className="account_desc">현재 임시 계정으로 사용중입니다.<br />*앱 삭제 시, 사용하신 임시 계정 정보는 삭제됩니다.</p>
				</div>
				<div className="my_setting">
                    <div className="sec_wrap">
					    {/* <!-- 터치 위치 --> */}
                            <div className="set_wrap usetap" onClick={()=> SettingTabPinCodeClick()}> 
                                <span className="set_title">비밀번호 재설정</span>
                                <span className="i_more"></span>
                            </div>
                            <div className="set_wrap is_desc usetap" onClick={()=> SettingBioClick()}>
                                <span className="set_title">지문 또는 얼굴인식 로그인 설정</span>
                                <span className="desc">비밀번호 대신 지문 또는 얼굴을 인식해 로그인할 수 있습니다.</span>
                                <span className="i_more"></span>
                            </div>
                    </div>
                    <div className="sec_wrap">
                        <div className="set_wrap usetap" onClick={()=> SettingNoticeClick()}>
                            <span className="set_title">공지보기/계정삭제</span>
                            <span className="i_more"></span>
                        </div>
                    </div>
                    <div className="sec_wrap">
                        <div className="set_wrap app_vision usetap">
                            <span className="set_title">앱 버전</span>
                            <span className="vision_txt">버전 V.{deviceInfo.dataset.version}</span>
                            <span className={"vision_round" + (deviceInfo.dataset.version < appversion ? " update" : " latest")} onClick={()=>UpdateClick(deviceInfo.dataset.version, appversion)}> 
                                {deviceInfo.dataset.version < appversion ? "업데이트" : "최신버전"}
                            </span>
                        </div>
                    </div>
				</div>
			</div>
        </>
    )
    
});
export default MyTemporaryAccount;