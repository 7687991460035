import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as LocalStore from '../../../store/LocalStore'
import $ from 'jquery';
import { observer } from 'mobx-react';
import store from '../../../store';
import 'moment/locale/ko';
import { layerPopup } from '../../../libs/common';
import * as NativeBridge from '../../../utils/NativeBridge';
import { getSimpleDate } from '../../../common/common';
import SohoApi from '../../../api/SohoApi';

const HomePopup = observer(({history}) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    const [popDataList, setPopDataList] = useState(null);
    const [popupStack, setPopupStack] = useState(null);
    const [showTempConnectPopup, setShowTempConnectPopup] = useState(false);
    //(userList);
    // let homePopupsDate = LocalStore.getHomePopupDate();

    useEffect( async ()=>{
        await PopupList();
    }, []);

    useEffect(()=>{
        if(Array.isArray(popDataList)) {
            if(popDataList.length) {
                let stackList = [];
                for(let i=0; i < popDataList.length; i++) {
                    stackList.push(i+1);
                    //-- 0.5초마다 한개씩 생성
                    // setTimeout(()=>{
                    //     layerPopup.show("#popup_onetitle_twomsgbutton_homepopup" + (i+1));
                    // }, 550*(i+1));            
                }
                setPopupStack(stackList);
            } else {
                setPopupStack([]);
            }
        }
    }, [popDataList]);

    useEffect(()=>{
        if(popupStack !== null) {
            if(Array.isArray(popupStack)) {
                if(popupStack.length > 0) {

                    const currPopUpId = "#popup_onetitle_twomsgbutton_homepopup" + popupStack[0];
                    // setPopupStack(popupStack => popupStack.shift());

                    // 임시사용자가 아닌데 푸시 알람 설정이 안되어 있는경우 잠시 후 호출한다.(푸시알람이 먼저 뜬다.)
                    if(LocalStore.getPushAlram() === null && LocalStore.getIsTempUser() !== true) {
                        setTimeout(()=>{
                            layerPopup.show(currPopUpId);
                        }, 550);                    
                    } else {
                        layerPopup.show(currPopUpId);
                    }

                } else {             
                    setShowTempConnectPopup(true);
                }
            }
        }
    }, [popupStack]);

    useEffect(()=>{
        if(showTempConnectPopup === true) {
            if(LocalStore.getIsTempUser() === true){
                layerPopup.show("#temploginpopup");
            }
        }
    }, [showTempConnectPopup, userData.dataset.storeList]);

    const PopupList = async() => {
        const PopupListData = await SohoApi.getPopupList({});
        //setPopDataList(PopupListData.data.list);

        let showPopupList = [];
        const localPopupData = LocalStore.getHomePopupDate();
        if(localPopupData) {
            
            for(let i=0; i < PopupListData.data.list.length; i++) {
                const showItem = localPopupData.find(item => item.linkType === PopupListData.data.list[i].linkType && 
                    ((item.linkType==="N" && item.targetId===PopupListData.data.list[i].linkData.notiId) || (item.linkType==="B" && item.targetId===PopupListData.data.list[i].linkData.beneId)) && 
                    item.currDate >= getSimpleDate());
                if(typeof(showItem)==="undefined") {
                    showPopupList.push(PopupListData.data.list[i]);
                }
            }

            //setPopDataList(showPopupList);

        } else {
            // setPopDataList(PopupListData.data.list);
            showPopupList = PopupListData.data.list;
        }

        //최신이 위로 오도록 역순 정렬
        const reverseShowPopupList = showPopupList.reverse();
        setPopDataList(reverseShowPopupList);

        return reverseShowPopupList.length;


        // for(let i=0; i < PopupListData.data.list.length; i++) {
        //     setTimeout(()=>{
        //         layerPopup.show("#popup_onetitle_twomsgbutton_homepopup" + (i+1));
        //     }, 500*i);            
        // }

        //userData.setHomePopupData(PopupListData.data.list)
        //console.log("팝업 조회",userData.dataset.homepopupData)

        /*
        if(userData.dataset.homepopupData !== null) {
            if(LocalStore.getHomePopupDate() == undefined) {
                for(let i=0; i < userData.dataset.homepopupData.length; i++) {
                    PopupCloseData.push({ currIndex: i + 1, currDate: "" })
                }
                //LocalStore.setHomePopupDate(PopupCloseData)
            } else {
            }
        }
        */
    }

    const ClosePopup = (currIndex, linkType, targetId) => {
        let homePopupsDate = LocalStore.getHomePopupDate();
        let selectHomeDate = null;
        if(homePopupsDate) {
            for(let i = 0; i < homePopupsDate.length; i++) {
                if(homePopupsDate[i].linkType===linkType && homePopupsDate[i].targetId === targetId) {
                    selectHomeDate = homePopupsDate.splice(i,1);
                }
            }
            //let selectHomeDate = homePopupsDate.filter((item) => item.currIndex === currIndex)
        } else {
            homePopupsDate = [];
        }

        if($(`input:checkbox[id='popupHideToday${currIndex}']`).prop("checked") === true) {
                // LocalStore.setHomePopupDate(currIndex, getSimpleDate());
                homePopupsDate.push({
                    linkType: linkType,
                    targetId: targetId,
                    currDate: getSimpleDate()
                });

        } else {
        }
        LocalStore.setHomePopupDate(homePopupsDate);

        layerPopup.hide(`#popup_onetitle_twomsgbutton_homepopup${currIndex}`, ()=> {
            setPopupStack(popupStack.splice(1));
        });
    }


    const goPopupNoticePage = (notiId, isTempUser, familyYn, popupId) => {

        console.log('*****goPopupNoticePage=>', notiId);

        //추가 - 12.08
        NativeBridge.sendUserActionLog('menu', 'H031', '', popupId);

        layerPopup.hide('#'+popupId, ()=>{

            let goLink = '';
            if(isTempUser === false) {
                goLink = `/my/mysetting/cs/notice/${notiId}`;
            } else if(isTempUser === true || familyYn === 'Y') {
                goLink = `/my/mysetting/cs/familynotice/${notiId}`;
            } else {
                console.log("N/A =>", notiId);
            }
            if(goLink) {
                history.push(goLink);
            }           

        });

    }
    const goPopupBenePage = (catgId, beneId, popupId) => {
        //추가 - 12.08
        NativeBridge.sendUserActionLog('menu', 'H031', '', popupId);

        layerPopup.hide('#'+popupId, ()=>{
            userData.setBnfCateId(catgId);
            userData.setBnfId(beneId);
            history.push("/ceocommunity");
        });
    }

    const TempLoginClick = () => {
        layerPopup.hide('#temploginpopup', () => {
            history.push('/shop/interwork');
        })
    }

    return (
        <>
            <div className="modal fadeInUp" id="temploginpopup"> 
                <div className="dim" onClick={()=>layerPopup.hide('#temploginpopup')}></div>
                <div className="modal_inner modal_up">
                    <span className="modal_title">서비스 연동 안내</span>
                    <div className="temp_login">
                        <span className="img_box">
                            <span className="blind">로그인 창</span>
                        </span>
                        <p>
                            우리가게매니저를 통해 관리할 <br/> 
                            지능형 CCTV와 인터넷전화를 앱에 <br/>
                            연동해 보세요. 서비스를 더 편리하게 <br/>
                            이용할 수 있습니다.
                        </p>
                    </div>
                    <div className="btn_box bottom_box">
                        <div className="inner">
                            <button type="button" onClick={() => TempLoginClick()} className="btn_lg btn_point usetap">서비스 등록하기</button>
                        </div>
                    </div>
                </div>
            </div>       

            { popDataList !== null &&
                popDataList.map((item, index) =>(
                <div className="modal fadeIn" key={`homepopup${index}`} id={`popup_onetitle_twomsgbutton_homepopup${index + 1}`}>
                    <div className="dim"></div>
                    <div className="modal_inner">
                        <div className="modal_content modal_notice">
                            { item.linkType === 'N' &&
                                <div onClick={() => goPopupNoticePage(item.linkData.notiId, userInfo.dataset.isTempUser, userInfo.dataset.familyYn, `popup_onetitle_twomsgbutton_homepopup${index + 1}`)}>
                                    <div className="modal_hd">{item.tit}</div>
                                    <div className="modal_bd">
                                        {item.cntnImge !== '' && 
                                            <div className="modal_img">
                                                <img src={`${item.cntnImge}`} alt="공지사항 이미지"/>
                                            </div>
                                        }
                                        {item.cntn !== '' && 
                                            <p dangerouslySetInnerHTML= { {__html: item.cntn.length > 88 ?
                                                `${item.cntn.substr(0, 88) + '...'}` :
                                                `${item.cntn}`} } />
                                        }
                                    </div>
                                </div>
                            }
                            { item.linkType === 'B' &&
                                <div onClick={() => goPopupBenePage(item.linkData.catgId, item.linkData.beneId, `popup_onetitle_twomsgbutton_homepopup${index + 1}`)}>
                                    <div className="modal_hd">{item.tit}</div>
                                    <div className="modal_bd">
                                        {item.cntnImge !== '' && 
                                            <div className="modal_img">
                                                <img src={`${item.cntnImge}`} alt="공지사항 이미지"/>
                                            </div>
                                        }
                                        {item.cntn !== '' && 
                                            <p dangerouslySetInnerHTML= { {__html: item.cntn.length > 88 ?
                                                `${item.cntn.substr(0, 88) + '...'}` :
                                                `${item.cntn}`} } />
                                        }
                                    </div>
                                </div>
                            }                            
                            <div className="checkbox_green">
                                <input type="checkbox" id={`popupHideToday${index + 1}`} />
                                <label htmlFor={`popupHideToday${index + 1}`} className="usetap">
                                    오늘 하루 보지 않기
                                </label>
                            </div>
                            <div className="modal_ft">
                                <button type="button" className="btn_half usetap" onClick={()=> ClosePopup(index + 1, item.linkType, item.linkType === 'N' ? item.linkData.notiId : item.linkData.beneId)}><span>닫기</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                ))
            }   

        </>
    )
});

export default HomePopup;