import * as Crypt from '../utils/Crypt';

const getObjectLocalStoreData = (keyName) => {
    const data = window.sessionStorage.getItem(keyName);
    if(data !== null && data !== '') {
        let retData = null;
        try {
            retData = JSON.parse(data);
        } catch(e) {
            retData = null;
        }
        return retData;
    } else {
        return null;
    }
}
const setObjectLocalStoreData = (keyName, value = '') => {
    if(value !== null && value !== '') {
        window.sessionStorage.setItem(keyName, JSON.stringify(value));
    } else {
        window.sessionStorage.setItem(keyName, '');
    }
}

export const setLoginSession = (userid) => {
    if(userid) {
        window.sessionStorage.setItem("userid", Crypt.aesEncrypt(userid));
    } else {
        window.sessionStorage.setItem("userid", '');
    }
}
export const getLoginSession = () => {
    const userId = window.sessionStorage.getItem("userid");
    if(userId) {
        return Crypt.aesDecrypt(userId);
    } else {
        return null;
    }
}
export const expireLoginSession = () => {
    if(window.sessionStorage.getItem("userid")) {
        window.sessionStorage.removeItem("userid"); 
    }
    return true;
}

export const clear = () => {
    window.sessionStorage.clear();
}

export const setCctvToken = (cctvToken) => {
    if(cctvToken) {
        window.sessionStorage.setItem("cctvtoken", Crypt.aesEncrypt(cctvToken));
    } else {
        window.sessionStorage.setItem("cctvtoken", '');
    }
}
export const getCctvToken = () => {
    const cctvToken = window.sessionStorage.getItem("cctvtoken");
    if(cctvToken) {
        return Crypt.aesDecrypt(cctvToken);
    } else {
        return null;
    }
}
export const removeCctvToken = () => {
    window.sessionStorage.removeItem("cctvtoken");
}

export const setCentrexKey = (keyObj) => {
    setObjectLocalStoreData("centrexkey", keyObj);
}
export const getCentrexKey = () => {
    return getObjectLocalStoreData("centrexkey");
}
export const removeCentrexKey = () => {
    window.sessionStorage.removeItem("centrexkey");
}

export const setSohoToken = (sohoToken) => {
    if(sohoToken) {
        window.sessionStorage.setItem("sohotoken", Crypt.aesEncrypt(sohoToken));
    } else {
        window.sessionStorage.setItem("sohotoken", '');
    }
}
export const getSohoToken = () => {
    const sohoToken = window.sessionStorage.getItem("sohotoken");
    if(sohoToken) {
        return Crypt.aesDecrypt(sohoToken);
    } else {
        return null;
    }
}

export const setStoreMappingJob = () => {
    setObjectLocalStoreData("storemappingjob", true);
}
export const getStoreMappingJob = () => {
    return getObjectLocalStoreData("storemappingjob");
}
export const removeStoreMappingJob = () => {
    window.sessionStorage.removeItem("storemappingjob");
}

export const setInviceFamilyCheck = () => {
    setObjectLocalStoreData("invitefamilycheck", true);
}
export const getInviceFamilyCheck = () => {
    return getObjectLocalStoreData("invitefamilycheck");
}
export const setAgreeCheck = () => {
    setObjectLocalStoreData("agreecheck", true);
}
export const getAgreeCheck = () => {
    return getObjectLocalStoreData("agreecheck");
}