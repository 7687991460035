import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../utils/NativeBridge";
import CentrexApi from "../../../api/CentrexApi";
import SohoApi from "../../../api/SohoApi";
import Shop from "../../shop/Shop";
import * as LocalStore from "../../../store/LocalStore"
import { layerPopup, toast } from '../../../libs/common';
import CustomerManage from './CustomerManage';
import CustomerManageError from "./CustomerManageError";

const CustomerManageUnSub = observer(({ svcCardNm, hposImge, urlConn, urlConnImge }) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  const CsClick = () => {
      //추가 - 12.08
      NativeBridge.sendUserActionLog('menu', 'H026');

      if(userData.dataset.currCallPage !== 'calllog') {
        userData.setCallTabList('calllog');
        userData.setShopMainTabList('call');
      } else {
        userData.setShopMainTabList('call')
      }
  }

  const goPageOnClick2 = (urls) => {
      //추가 - 12.08
      NativeBridge.sendUserActionLog('menu', 'H023');

      NativeBridge.externalCall(`${urls}`)
  }
    

    return (
        <>
          { 
            !userInfo.dataset.myservice.call && (userData.dataset.storeInetNo === null || userData.dataset.storeInetNo === "") ?
              <div className="content_box">
                  <Link to={`/shop/call/calllog`} className="sec_link usetap" onClick={()=> CsClick()}><i className="ico_dark_more"></i>더보기</Link>
                  <span className="cont_title">{svcCardNm}</span> 
                  <div className="sec_wrap unsubs_img">
                      <img src={`${hposImge}`} alt="인터넷전화 예시" />
                      <button type="button" onClick={()=>goPageOnClick2(urlConn)}><img src={`${urlConnImge}`} /></button>
                  </div>
              </div>
            : 
            <CustomerManage svcCardNm={svcCardNm}/>
        }
      </>
    )
  });
export default CustomerManageUnSub;