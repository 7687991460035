import React,{useEffect} from 'react'
import $ from 'jquery';
import { Route } from "react-router";
import store from '../../store/index';
import BizApi from '../../api/BizApi';
import * as LocalStore from '../../store/LocalStore';
import * as NativeBridge from '../../utils/NativeBridge'
import { tabUi } from '../../libs/tab';
import { layerPopup } from '../../libs/common';
import Order from './order/Order';
import Analyze from './analyze/Analyze';
import OrderManage from './ordermanage/OrderManage';
import OrderListUnSub from '../home/order/OrderListUnSub';
import OrderUnSub from './OrderUnSub';


const OrderMain = ({ history, match }) => {
    const { userInfo, userData } = store;

    let storeListData = LocalStore.getStoreList();

    useEffect(() => {
        let menuIdx = getMenuIdx(userData.dataset.sisicMainTab);
        setTimeout(() => {
            if(userData.dataset.sissicgaRegiYn === 'Y') {
                tabUi.initSubTab(document.getElementById('tab_1depth'), menuIdx);
            }
        }, 50);
      }, [userData.dataset.sisicMainTab, userData.dataset.currstoreInfo]);

    useEffect(() => {
        if(match.params.mainmenu) {
            const submenu = match.params.mainmenu;
            userData.setSisicMainTabList(submenu);
        }
      }, [match.params.mainmenu]);

    const getMenuIdx = (menuName) => {
        let menuIdx = 0;
        switch (menuName)
        {
            case "order" : 
                menuIdx = 0;
                break;
            case "analyze" : 
                menuIdx = 1;
                break;
            case "ordermanage" : 
                menuIdx = 2;
                break;
            default:
                menuIdx = 0;
                break;
        }
        return menuIdx;
      }

    const showStoreListPopup = (e) => {
        if(storeListData.length > 1) {
          if(LocalStore.getStoreId() === userData.dataset.currstoreInfo.storeId) {
            $(`input:radio[id=storeIdx_${userData.dataset.currstoreInfo.storeId}]`).prop("checked", true);
          } else if(LocalStore.getStoreId() !== userData.dataset.currstoreInfo.storeId) {
            $(`input:radio[id=storeIdx_${userData.dataset.currstoreInfo.storeId}]`).prop("checked", false);
          }
          layerPopup.show('#popup_store_id_select');
        } else {
            return;
        }
    }

    const selectStore = () => {
        layerPopup.hide('#popup_store_id_select', async()=> {
          const storeId = $("#popup_store_id_select ul li input[name='shopSelect']:checked").attr("storeid");
          //TODO : 스토어 변경시 필요한 프로세스 진행
          await BizApi.procChgStore(storeId);
          userData.setSisicMainTabList('order');

          if(userData.dataset.sisicMainTab === 'order') {
            userData.setOrderTabList('neworder');
            history.push('/ssisicga/order/neworder');
          } 
      });
    }

    const CallMenuClick = (currMenu=null) => {
        userData.setSisicMainTabList(currMenu);
        userData.setOrderTabList('neworder');
        history.replace(`/ssisicga/${currMenu}/${userData.dataset.orderTap}`)    
      }

    const MenuClick = (currMenu=null) => {
        userData.setSisicMainTabList(currMenu); 
        history.replace(`/ssisicga/${currMenu}`);
    }

    return (
        <>
        <div className="content food">
            { userData.dataset.sissicgaRegiYn === 'Y' ?
                <div className='hd_fix_wrap'>
                    <div className="hd_top2">
                        <div className="hd_top_inner">
                            { storeListData.length === 1 ?
                                <button type="button" className="hd_sel_lounge usetap active" onClick={(e)=>showStoreListPopup(e)}>{userData.dataset.currstoreInfo?.storeNm}</button>
                            :
                                <button type="button" className="hd_sel_shop usetap active" onClick={(e)=>showStoreListPopup(e)}>{userData.dataset.currstoreInfo?.storeNm}</button>
                            }
                        </div>
                    </div>
                    <div className="tab_1depth_wrap">
                        <div className="tab_1depth" id="tab_1depth">
                        <ul className="tab_link">
                                <li className={"tab_item" + (userData.dataset.sisicMainTab === 'order' ? " active" : "")} 
                                    onClick={()=>CallMenuClick('order')}><span>주문내역</span>
                                </li>
                                <li className={"tab_item" + (userData.dataset.sisicMainTab === 'analyze' ? " active" : "")} 
                                    onClick={()=>MenuClick('analyze')}><span>분석</span>
                                </li>
                                <li className={"tab_item" + (userData.dataset.sisicMainTab === 'ordermanage' ? " active" : "")} 
                                    onClick={()=>MenuClick('ordermanage')}><span>메뉴판 관리</span>
                                </li>
                            </ul>
                            <div className="active_bar"></div>
                        </div>
                    </div>
                </div>
            :
                <div className='hd_fix_wrap none_tab'>
                    <div className="hd_top2">
                        <div className="hd_top_inner">
                            { storeListData.length === 1 ?
                                <button type="button" className="hd_sel_lounge usetap active" onClick={(e)=>showStoreListPopup(e)}>{userData.dataset.currstoreInfo?.storeNm}</button>
                            :
                                <button type="button" className="hd_sel_shop usetap active" onClick={(e)=>showStoreListPopup(e)}>{userData.dataset.currstoreInfo?.storeNm}</button>
                            }
                        </div>
                    </div>
                </div>
            }

            { userData.dataset.sissicgaRegiYn === 'Y' ?
                ( userData.dataset.sisicMainTab === 'order' ?
                    <Route path="/ssisicga/order" component={Order} />
                : userData.dataset.sisicMainTab === 'analyze' ?
                    <Route exact path="/ssisicga/analyze" component={Analyze} />
                : userData.dataset.sisicMainTab === 'ordermanage' ?
                    <Route exact path="/ssisicga/ordermanage" component={OrderManage} />                
                :
                    <></>
                )
            :
                <Route path="/ssisicga" component={OrderUnSub} />
            }
        </div>

        {/* 매장 선택 팝업 */}
        <div className="modal fadeInUp" id="popup_store_id_select">
            <div className="dim" onClick={()=>layerPopup.hide('#popup_store_id_select')}></div>
            <div className="modal_inner modal_up">
                <span className="modal_title">가게 선택</span>
                <ul className="modal_radio_list">
                    {storeListData.map(item => (
                        <li key={item.storeId}>
                            <div className="radio_box green">
                                <input type="radio" id={`storeIdx_${item.storeId}`} name="shopSelect" {...{storeid: item.storeId, storenm: item.storeNm}} />
                                <label htmlFor={`storeIdx_${item.storeId}`}  className="usetap">{item.storeNm}</label>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="btn_box bottom_box">
                    <button type="button" className="btn_lg btn_point usetap" onClick={selectStore}>완료</button>
                </div>
            </div>
        </div>
    </>
  )
}

export default OrderMain