import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import store from '../../../store';
import $ from "jquery";
import { tabUi } from '../../../libs/tab';
import { layerPopup } from '../../../libs/common';
import { getAddressListByGroup, getAddressInfo, getAddressListForPhoneByGroup, sortHanFirstObjectList } from '../../../common/common';
import CentrexApi from '../../../api/CentrexApi';


const MsgGroup = observer(() => {

    const { userInfo, userData } = store;
    const [addressGroupList, setAddressGroupList] = useState([]); 
    const [addressOriGroupList, setAddressOriGroupList] = useState([]); 
    const [popSearchWords, setPopSearchWords] = useState(""); 


    useEffect(() => {
        tabUi.init3DepthTab(document.getElementById('tab_style4'));
        //store.userData.setGroupCnt($('#popup_msgcontactgroup .group_list').length);
        //$(".input_box.style1 input[type=text]").trigger("focus");
        //$("#tab_phonebook").trigger("click");
        
    }, [])

    useEffect(() => {
        console.log('change popSearchWords=>', popSearchWords);

        const filterData = sortHanFirstObjectList(
            addressOriGroupList.filter((targetItem) => { 
                if ( targetItem.GROUP_CODE !== 0) return targetItem;
            }), 'GROUP_NAME', 'ASC');

        setAddressGroupList(filterPbGrpData( filterData,popSearchWords) );
    }, [popSearchWords])

    useEffect(() => {
        console.log('change addressOriGroupList=>', addressOriGroupList);

        const filterData = sortHanFirstObjectList(
            addressOriGroupList.filter((targetItem) => { 
                if ( targetItem.GROUP_CODE !== 0) return targetItem;
            }), 'GROUP_NAME', 'ASC');

        setAddressGroupList(filterData);

    }, [addressOriGroupList]);


    const getAddressGroupList = async () => {
        const result = await CentrexApi.getAddressGroupList(); 
        if(result.data.SVC_MSG === "OK"){
            
            if ( result.data.DATAS?.length > 0 ){
                const filterData = sortHanFirstObjectList(
                    result.data.DATAS.filter((targetItem) => { 
                        if ( targetItem.GROUP_CODE !== 0) return targetItem;
                    }), 'GROUP_NAME', 'ASC');
    
                setAddressOriGroupList(filterPbGrpData(filterData, popSearchWords));
            }else{
                setAddressOriGroupList([]);
            }
            
		}
	};

    

    useEffect(() => {
        //for wake up ( show ) change value = sendMsgGroupPopupToggle
        console.log("change userData.dataset.sendMsgGroupPopupToggle =>", userData.dataset.sendMsgGroupPopupToggle);
        if ( userData.dataset.sendMsgGroupPopupToggle ){
            getAddressGroupList();
        }
        //userData.setSendMsgAddrPopupToggle(false)
    }, [userData.dataset.sendMsgGroupPopupToggle]);

    useEffect(() => {
        setTimeout( () => {
            $('input[name="contactGroupAll"]').attr('checked', false);
        }, 800);
        
    }, [addressGroupList]);

    const SelectAllClick = (e) => {
        const checkboxes = document.getElementsByName('msgGroupAdd');

        checkboxes.forEach((checkbox) => {
          checkbox.checked = e.checked
        })

        const query = 'input[name="msgGroupAdd"]:checked';
        const selectedElements = document.querySelectorAll(query);
        const selectedElementsCnt = selectedElements.length;
        document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
    }

    const CheckedClick = (e,_item) => {
        // const checkboxes = document.querySelectorAll('input[name="msgGroupAdd"]');
        // const checked = document.querySelectorAll('input[name="msgGroupAdd"]:checked');
        // const selectAll = document.querySelector('input[name="contactGroupAll"]');
        
        // if(checkboxes.length === checked.length)  {
        //   selectAll.checked = true;
        // } else {
        //   selectAll.checked = false;
        // }

        console.log("CheckedClick by group =>", _item );
        if ( _item.ADDRESS_CNT === '0'){
            //메시지 내용을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "연락처가 없는 그룹입니다.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);

            e.target.checked = false;

            return false;
        }

        /*
        if ( $(e.target).is(":checked") === true ){
            console.log('isChecked');
            userData.setPopSelectedSendMsgGroup(Array.from(userData.dataset.popSelectedSendMsgGroup).concat([
                {
                    type : 'G', // 'G' : 그룹  | 'N' 번호 or Phonebook
                    name : _item.GROUP_NAME ,
                    items : getAddressListForPhoneByGroup(_item.GROUP_CODE) //TODO : 그룹별 전화번호 목록 가져오기 ( 유틸생성 )
                }
            ]));
        }else{
            console.log('inot sChecked');
            userData.setPopSelectedSendMsgGroup( 
                (userData.dataset.popSelectedSendMsgGroup != null && userData.dataset.popSelectedSendMsgGroup.length > 0 ?
                    userData.dataset.popSelectedSendMsgGroup.filter ( ( targetItem ) => {
                        if ( targetItem.type === 'G' && targetItem.name === _item.GROUP_NAME)
                            console.log('not ');
                        else
                            return targetItem;
                    })
                    : []
            ));
        }
        */

        const query = 'input[name="msgGroupAdd"]:checked';
        const selectedElements = document.querySelectorAll(query);
        // 선택된 목록의 갯수 세기
        const selectedElementsCnt = selectedElements.length;
        // 출력
        document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
    }

    const PhonebookGroupTabClick = (subMenu= null) => {
        store.userData.setMsgPhonebookGroupList(subMenu);
        userData.setSendMsgAddrPopupToggle(true);
    }

    const ChangeSearchWords = (e) => {
		setPopSearchWords(e.target.value);
	}

    const ClickPopupReset = (e) => {
        $('#idx_input_name_number').val('');
        setPopSearchWords('');
    }

    const filterPbGrpData = (_list, _words) => {
        console.log("filterPbGrpData=>",_words);
		return _list.filter((targetItem) => {
			if ( _words === '' || (targetItem.GROUP_NAME)?.includes(_words) ){
				return targetItem;
			}	
		});
	}

    const ClickRegContMsg = async (e) => {

        //개인정보전달하기
        const checkedPhone = document.querySelectorAll('input[name="msgContactAdd"]:checked');
        if ( !Array.isArray(checkedPhone) && Array.from(checkedPhone).length > 0 ){
            let tmplist = [];
            Array.from(checkedPhone).map((_roofItem, _idx) => {
                const _addrInfo = getAddressInfo($(_roofItem).attr('phone'), $(_roofItem).attr('no'));
                tmplist.push({
                    type : 'P'
                    ,idx : ( 'P' + _addrInfo.no + '_' + _addrInfo.phone )
                    ,name : _addrInfo.name
                    ,items : [
                        _addrInfo.phone
                    ]
                });
            });

            //개인정보전달하기
            //userData.dataset.popSelectedSendMsgPhone
            userData.setPopSelectedSendMsgPhone(tmplist);
            //userData.setPopSelectAddressAndPhone(false);
        }

        //그룹전화번호 전달하기
        //userData.dataset.popSelectedSendMsgGroup
        const checkedGroup = document.querySelectorAll('input[name="msgGroupAdd"]:checked');
        if ( checkedPhone !== undefined && checkedPhone !== null && Array.from(checkedGroup).length > 0 ){

            let tmplist = [];
            Array.from(checkedGroup).map((_roofItem, _idx) => {
                let _groupCode = $(_roofItem).attr('groupcode');
                let _groupName = $(_roofItem).attr('groupname');
                let _getAddrList = getAddressListForPhoneByGroup(_groupCode);

                tmplist.push({
                    type : 'G'
                    ,idx : ( 'G' + _groupCode )
                    ,name : _groupName
                    ,items : _getAddrList
                });

                userData.setPopSelectedSendMsgGroup(tmplist);
            });

        }

        //데이터 전송 flag
        userData.setSendMsgAddrPopupToggle(false);      //다음팝업 열리게 하기 위해
        layerPopup.hide('#popup_msgcontactgroup');
    };

    const ClickCancel = (e) => {
        userData.setSendMsgAddrPopupToggle(false);      //다음팝업 열리게 하기 위해
        layerPopup.hide('#popup_msgcontactgroup')
    }

    return (
        <>
        <div className="modal_inner modal_up max_h">
            <div className="modal_group_add">
                { /* 컨텐츠 */ }

                <div className="chk_wrap">
                    <input type="checkbox" id="contactGroupAll" name="contactGroupAll" onClick={(e)=>SelectAllClick(e.target)} />
                    <label htmlFor="contactGroupAll" className="btn_all_sel">모두선택</label>
                </div>
                <span className="info_chk_sel"><span id="chk_sel_num">0</span>개 선택</span>
                <div id="tab_style4" className="tab_style4 tab2">
                    <button className="tab_item phonebook" id="tab_phonebook" onClick={()=> PhonebookGroupTabClick('phonebook')}>연락처</button>
                    <button className="tab_item group active" id="tab_group" onClick={()=> PhonebookGroupTabClick('group')}>그룹</button>
                </div>
                <div className="input_box style1">
                    <input type="text" id="idx_input_name_number" placeholder="그룹명을 검색해보세요." onKeyUp={(e)=> ChangeSearchWords(e)}/>
                    <span className="ip_clear hidden usetap" onClick={(e) => ClickPopupReset(e)}>초기화</span>
                    <span className="ico_srh"></span>
                </div>
                <div className="top_info">
                    <span className="top_title">그룹</span>
                    <span className="total_num">{addressGroupList.length}개</span>
                </div>
                { 
                    (addressGroupList !== undefined && addressGroupList !== null && addressGroupList.length > 0) ?
                    <>
                        <ul className="msg_group_list">
                            { 
                            addressGroupList.map((groupItem, index) => (
                                <>
                                    <li key={groupItem.GROUP_CODE} className="group_list">
                                        <input type="checkbox" id={"msgGroupAdd_" + index + "_" + groupItem.GROUP_CODE} name="msgGroupAdd" 
                                            {...{groupcode: groupItem.GROUP_CODE
                                                ,groupname: groupItem.GROUP_NAME
                                            }}
                                            onClick={(e)=> CheckedClick(e, groupItem)} />
                                        <label htmlFor={"msgGroupAdd_" + index + "_" + groupItem.GROUP_CODE} className="usetap">
                                            <span className="group_name">{groupItem.GROUP_NAME}</span>
                                            <span className="group_num">{groupItem.ADDRESS_CNT}명</span>
                                        </label>
                                    </li>
                                </>
                            ))
                            }
                        </ul>
                    </>
                    :
                    <>
                        <div className="data_none">
                        <div className="none_wrap">
                            <span className="none_img"></span>
                            <p>검색 결과가 없습니다.</p>
                        </div>
                    </div> 
                    </>
                }
                { /* 컨텐츠 */ }
            </div>
        </div>
        <div className="btn_box fixed">
            <div className="inner">
                <button type="button" className="btn_half btn_line2 usetap" onClick={(e)=>ClickCancel(e)}>취소</button>
                <button type="button" className="btn_half btn_point usetap" onClick={(e)=>ClickRegContMsg(e)}>추가</button>
            </div>
        </div>
        </>
    );
});

export default MsgGroup;
