// import * as URLs from '../const';
import axios from "axios";
import { layerPopup } from '../libs/common';
import * as LocalStore from '../store/LocalStore'
import * as SessionStore from '../store/SessionStore'
import userData from "../store/userData";
import SohoApi from './SohoApi';
import * as CONSTS from '../const';
import CentrexApi from "./CentrexApi";
import * as NativeBridge from '../utils/NativeBridge';

let SESSID = "";
let SSOHASH = "";
let USERID = "";
let USERPASS = "";
let centrexAuthRetryCnt = 0;
let centrexAuthRetryCnt_TEMPUSER = 0;
//_YYYYMMDDhhmmssss

function getCurrentDate()
{
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + month + day + hour + minites + seconds + "00";
}

const instance = axios.create({
  baseURL: `https://${process.env.REACT_APP_CENTREX_API_URL}`,
  timeout: 10000,
  headers: { 'AgentID': process.env.REACT_APP_CENTREX_AGENT_ID + "_" +  + getCurrentDate() }
});

export const initCentrexAuthRetryCntEnv = () => {
  centrexAuthRetryCnt = 0;
  centrexAuthRetryCnt_TEMPUSER = 0;
}

instance.interceptors.request.use(
  function (config) {
    console.log("-------------------------------\n centrix [REQ]=>\n", config);
    console.log("\n[REQ PARAM]=>", printFormData(config.data));
    //userData.setByDayTimeCheck("")
    NativeBridge.getLoadingStart();
    return config;
  },
  function (error) {
    NativeBridge.getLodingEnd();
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {

    NativeBridge.getLodingEnd();

    console.log("-------------------------------\n centrix [RES]=>\n", response);
    /*
    if(response.data.SVC_RT === '1900' || response.data.SVC_RT === '1901' || response.data.SVC_RT === '1902' || response.data.SVC_RT === '1908' || response.data.SVC_RT === '1900') 
    {
      if(LocalStore.getIsTempUser() === false){
        layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요", [
            function() {
                SohoApi.getCentrexAuth({storeInetNo: LocalStore.getStoreInetNo()}).then(centrexAuthInfo => {
                  // if(centrexAuthInfo.retCode === "0000") {
                  //   NativeBridge.setCentrexSsoKeyToNative(centrexAuthInfo.data.sessId, centrexAuthInfo.data.ssoHash, LocalStore.getStoreInetNo());
                  // }                  
                  layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                });
            }
        ]);      
        return response;
      }
      // else{
      //   //임시사용자일 경우 서버 오류시에는 센트릭스 사용자 정보 요청
      //   if(userData.dataset.centrexTempAuthFlag2 === "0"){
      //       layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요", [
      //         function() {
      //               CentrexApi.getUserInfo({id: LocalStore.getStoreInetNo(), pass : LocalStore.getTempInetPass()}).then(centrexAuthInfo => {              
      //               userData.setCentrexTempAuthFlag2("1")
      //               layerPopup.hide("#popup_onetitlemsgbutton_api_error");
      //             });
      //         }
      //     ]);      
      //       return response;
      //   }
      // }
    } else {
      return response;
    }
    */
   
    try {
      const errCodeData = CONSTS.CENTREXAPI_AUTH_ERROR.find((item)=>item.code===response.data.SVC_RT);
      if(typeof(errCodeData) !== "undefined") {
        if(LocalStore.getIsTempUser() !== true) {
          if(centrexAuthRetryCnt === 0) {
            centrexAuthRetryCnt++;
            layerPopup.showCommon("#popup_centrex_auth_error", null, errCodeData.title, errCodeData.msg, [null]);
          }
        } else {
          if(centrexAuthRetryCnt_TEMPUSER === 0) {
            centrexAuthRetryCnt_TEMPUSER++;
            layerPopup.showCommon("#popup_centrex_auth_error", null, errCodeData.title, errCodeData.msg, [null]);
          }
        }
      } else if (response.data.SVC_RT === CONSTS.CENTRIX_SVC_RT_SUCCESS) {
        initCentrexAuthRetryCntEnv();
      }
      return response;
    } catch(e) {
      return response;
    }

  },
  async function (error) {

    NativeBridge.getLodingEnd();

    // timeout error
    // if(error.code == 'ECONNABORTED'){
    //   console.log('timeout error')
    //   layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(인터넷 전화)", [
    //       function() {
    //           layerPopup.hide("#popup_onetitlemsgbutton");
    //       }
    //   ]);
    // }

    return Promise.reject(error);
  }
);

const printFormData = (_formData) => {

  let keyArr = [];
  for (var key of _formData.keys()) {
      keyArr.push(key);
  }
    
  let valArr = [];
  for (var value of _formData.values()) {
      valArr.push(value);
  }

  return "\n[KEYS]=>" + keyArr.join('|') + "]\n" + "[Value]=>" + valArr.join('|') + "]";

}

const makeFormData = (jsonData) => {
  let form = new FormData();
  for(let key in jsonData) {
    form.append(key, jsonData[key]);
  }

  if(SESSID !== '' && SSOHASH !== '') {
      form.append("id", LocalStore.getStoreInetNo())
      form.append("SESSID", SESSID);
      form.append("SSOHASH", SSOHASH);
  }else{
    //센트릭스 070번호 정상 조회 되면 주석 처리 후 아래 거 주석 풀기 임시 테스트용
    // else{
    //   form.append("id", "07075999917" );
    //   form.append("pass", "67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf" );
    //   form.append("peer_exten", "07975999917".substr(7,4));
    // }
    //임시사용자일 때

    if(LocalStore.getIsTempUser()===true) {
      if( USERID !== "" && USERPASS !== ""){
        //임시사용자 로그인 후
        form.append("id", USERID )
        form.append("pass", USERPASS)
        // form.append("peer_exten",LocalStore.getExtenInetNo());
      }else{
        //TODO : 세션에서 가져오는 경우 케이스 정리 필요\
        form.append("id", LocalStore.getStoreInetNo())
        form.append("pass", LocalStore.getTempInetPass())
        // form.append("peer_exten",LocalStore.getExtenInetNo());
      }
    }
  }
  
  return form;
}

const makeFormData2 = (jsonData) => {
  let form = new FormData();
  for(let key in jsonData) {
    form.append(key, jsonData[key]);
  }
  // if(USERID !== '' && USERPASS !== '') {
  //   form.append("USERID", USERID);
  //   form.append("USERPASS", USERPASS);
  // }
  return form;
}

/*
{SVC_RT: "1901", SVC_MSG: "SSO TIMEOUT AUTH_ERR", DATAS: " 07075999925 SSO TIMEOUT FAIL"}
DATAS: " 07075999925 SSO TIMEOUT  FAIL"
SVC_MSG: "SSO TIMEOUT AUTH_ERR"
SVC_RT: "1901"
*/

/*
  {
    SESSID: "",
    SSOHASH : ""
  }
*/
export const setCentrexSsoKey = (sessId, ssoHash) => {
  SESSID = sessId;
  SSOHASH = ssoHash;
  LocalStore.setCentrexKey({ "SESSID": SESSID,  "SSOHASH": SSOHASH});
  SessionStore.setCentrexKey({ "SESSID": SESSID,  "SSOHASH": SSOHASH});
  NativeBridge.setCentrexSsoKeyToNative(SESSID, SSOHASH, LocalStore.getStoreInetNo());
}
export const setCenterexUserIdPass = (userId, userPass) => {
  USERID = userId;
  USERPASS = userPass;
  if(LocalStore.getIsTempUser() === true) {
    NativeBridge.setCentrexSsoKeyToNative('', '', USERID, USERPASS);
  }
}

export default {
  isEmpty(result){
    if ( result.SVC_RT === '4004'){
      return true;
    }else{
      return false;
    }
  },
  getMyInfo() {
    return instance({
      url: "/res/json/sample2.json",
      method: "get",
    });
  },
  //통화이력
  getCallHistoryInfo(params) {
    return instance({
      url: "/RestApi/callhistory",
      method: "post",
      data: makeFormData(params),
    });
  },
  //문자수발신이력
  getAllSmsList(params) {
    return instance({
      url: "/RestApi/getallsmslist",
      method: "post",
      data: makeFormData(params),
    });
  },
  //문자빌송
  sendSms(params) {
    return instance({
      url: "/RestApi/smssend",
      method: "post",
      data: makeFormData(params),
    });
  },
  //우리매장 알림 조회
  getMMSInfo() {
    return instance({
      url: "/RestApi/getmmsinfo",
      method: "post",
      data : makeFormData({})
    });
  },
  //우리매장 알림 삭제
  removeMMSInfo() {
    return instance({
      url: "/RestApi/delmmsinfo",
      method: "post",
      data : makeFormData({})
    });
  },
  //우리매장 알림 등록
  setMMSInfo(params) {
    return instance({
      url: "/RestApi/setmmsinfo",
      method: "post",
      data : makeFormData(params)
    });
  },
  //우리매장 알림 발송 상태 변경
  setChgMMSInfo(params) {
    return instance({
      url:"/RestApi/chgmmsinfo",
      method: "post",
      data: makeFormData(params)
    })
  },

  //녹취목록조회
  getRecordList(params) {
    return instance({
      url: "/RestApi/recordlist",
      method: "post",
      data: makeFormData(params),
    });
  },
  //녹취파일삭제
  removeRecordFile(params) {
    return instance({
      url: "/RestApi/recordremove",
      method: "post",
      data: makeFormData(params),
    });
  },

  //통화그룹목록 조회
  getAddressGroupList(params) {
    return instance({
      url: "/RestApi/getaddrgrouplist",
      method: "post",
      data: makeFormData(params),
    });
  },
  //통화그룹삭제
  removeAddressGroup(params) {
    return instance({
      url: "/RestApi/deladdrgroup",
      method: "post",
      data: makeFormData(params),
    });
  },
  //통화그룹추가
  addAddressGroup(params) {
    return instance({
      url: "/RestApi/addaddrgroup",
      method: "post",
      data: makeFormData(params),
    });
  },
  //통화그룹수정
  modifyAddressGroup(params) {
    return instance({
      url: "/RestApi/chgaddrgroup",
      method: "post",
      data: makeFormData(params),
    });
  },
  //전화번호추가
  getPhoneBookList(params){
    return instance({
      url : "/RestApi/getaddresslist",
      method: "post",
      data: makeFormData(params),
    })
  },
  //전화번호추가
  AddPhonebookInfo(params){
    return instance({
      url : "/RestApi/addaddresslist",
      method: "post",
      data: makeFormData(params),
    })
  },
  //전화번호수정
  ModifyPhonebookInfo(params){
    return instance({
      url : "/RestApi/chgaddresslist",
      method: "post",
      data: makeFormData(params),
    })
  },
  //전화번호삭제
  RemovePhonebookInfo(params){
    return instance({
      url : "/RestApi/deladdresslist",
      method: "post",
      data: makeFormData(params),
    })
  },
  //다중연락처그룹이동
  mvaddresslist(params) {
    return instance({
      url: "/RestApi/mvaddresslist",
      method: "post",
      data: makeFormData(params),
    });
  },

  //착신전환설정 (착신 번호 삭제 요청)
  getSetForwardInfo(params) {
    return instance({
      url: "/RestApi/setforward",
      method: "post",
      data: makeFormData(params),
    });
  },
  //통화연결음 목록 API
  getMohListInfo(params) {
    return instance({
      url: "/RestApi/getmohlist",
      method: "post",
      data: makeFormData(params),
    });
  },

  //착신 전화 조회
  getForwardType(params) {
    return instance({
      url: "/RestApi/getforwardtype",
      method: "post",
      data: makeFormData(params),
    });
  },
  //착신 전화 삭제 테스트
  getForwardTypeTest(params) {
    return instance({
      url : "/RestApi/getforwardtypetetest",
      method: "post",
      data: makeFormData(params)
    });
  },
  //착신 전화 삭제
  getStopForward(params) {
    return instance({
      url:"/RestApi/stopforward",
      method:"post",
      data: makeFormData(params),
    })
  },
  //착신 전화 페이지에서 요청
  getSetForwardTypeInfo(params) {
    return instance({
      url: "/RestApi/setforwardtype",
      method: "post",
      data:makeFormData(params)
    })
  },
  //현재 설정된 통화 연결음 조회
  getMohInfo(params) {
    return instance({
      url: "/RestApi/getmohinfo",
      method: "post",
      data: makeFormData(params)
    });
  },
  //번호의 통화 연결음 변경설정 API
  getSetMohInfo(params) {
    return instance({
      url: "/RestApi/setmohinfo",
      method: "post",
      data: makeFormData(params),
    });
  },
  //수신 차단 목록 API
  getBlackListInfo(params) {
    return instance({
      url: "/RestApi/getblacklistinfo",
      method: "post",
      data: makeFormData(params),
    });
  },
  //블랙리스트 추가
  getAddBlackListInfo(params) {
    return instance({
      url:"/RestApi/addblacklist",
      method: "post",
      data : makeFormData(params)
    })
  },
  getBlackListMode(params) {
    return instance({
      url:"/RestApi/setblacklistmode",
      method:"post",
      data:makeFormData(params)
    })
  },
  //수신 차단 번호 삭제
  getDelBlackListInfo(params) {
    return instance({
      url:"/RestApi/delblacklist",
      method:"post",
      data:makeFormData(params)
    })
  },
  //착신전환설정
  getSetForward(params) {
    return instance({
      url: "/RestApi/setforward",
      method: "post",
      data: makeFormData(params)
    })
  },
  //주소록
  getAddressListInfo(params) {
    return instance({
      url: "/RestApi/getaddresslist",
      method: "post",
      data : makeFormData(params)
    })
  },
  //통화 연결음 음원 생성 
  getMakettsMoh(params) {
    return instance({
      url: "/RestApi/makettsmoh",
      method: "post",
      data: makeFormData(params)
    })
  },
  //통화 연결음 삭제
  getDelMohList(params) {
    return instance({
      url: "/RestApi/delmohlist",
      method: "post",
      data:makeFormData(params)
    })
  },
  //통화 연결음 미리듣기
  getTtsPrePlay(params) {
    return instance({
      url: "/RestApi/ttspreplay",
      method:"post",
      data:makeFormData(params)
    })
  },
  //본인의 TTS 청약권 조회
  getTtsInfoList(params) {
    return instance({
      url : "/RestApi/getttsinfo",
      method:"post",
      data:makeFormData(params)
    })
  },
  //사용자 정보조회 (로그인)
  getUserInfo(params, check) {
    if(check){
      return instance({
        url : "/RestApi/userinfo",
        method:"post",
        data: LocalStore.getIsTempUser() ? makeFormData2(params) : makeFormData(params)
      }).then((result) => {
        if(result.data.SVC_RT === "0000"){
          const exten = result.data.DATAS.EXTEN;
          LocalStore.setExtenInetNo(exten);
        }
        //else처리 필요 
      })
    } else {
      return instance({
        url : "/RestApi/userinfo",
        method:"post",
        data: LocalStore.getIsTempUser() ? makeFormData2(params) : makeFormData(params)
      })
    }
  },
  //비밀번호 변경 인증번호 요청 API
  getAuthCode(params) {
    return instance({
      url : "/RestApi/getauthcode",
      method:"post",
      data:makeFormData2(params)
    })
  },
  //바말번호 변경 API
  getAuthChange(params) {
    return instance({
      url: "RestApi/setauthchange",
      method:"post",
      data:makeFormData2(params)
    })
  }

};
