import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../store';
import InfiniteScroll from 'react-infinite-scroll-component';
import $ from 'jquery';
import * as NativeBridge from '../../../../../utils/NativeBridge';
import SohoApi from "../../../../../api/SohoApi"

const InviteAccountNotice = observer(({ match, history }) => {

    const { userInfo, userData } = store;
    const [notice, setNotice] = useState([])
    const [noticedetail, setNoticedetail] = useState(null);
    const [hasMore, setHasMore] = useState(true)
    const [nextPage, setNextPage] = useState(2)

    useEffect(() => {
        getNotice();
        $("html, body").removeClass("noscroll");
        $(window).on('scroll', function() {
            const scrollTop = $(window).scrollTop()
            if(scrollTop <= 0) {
                setHasMore(false)
            } else {
                setHasMore(true);
            }
        })

        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').addClass('bg_gray');
        }          
        
        return () => {
            if($('#wrap').hasClass('fixed_bottom')) {
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').addClass('gnb_bottom');
                $('#wrap').removeClass('bg_gray');
            }
            $(window).off('scroll');
        }
    }, []);

    useEffect(async() => {    
        if(match.params.noticeIdx) {
            await getNoticeDetail(match.params.noticeIdx)

            setTimeout(() => {
                $(`#list_a-${match.params.noticeIdx}`).addClass('on');
                $(`#notice-${match.params.noticeIdx}`).addClass('current');
                const offsetTop = $(`#notice-${match.params.noticeIdx}`).offset().top
                $('html, body').animate({ scrollTop: offsetTop }, 500)
            }, 200);
        }
    }, [match.params.noticeIdx]);

    const getNotice = async () => {
        const noticeData = await SohoApi.getNotice({"pagePerRows": '20', "bookmark": '1'})
            setNotice(noticeData.data.list);
            console.log("getNotice:",noticeData.data.list); 
    }
    const getNextNotice = async () => {
        const noticeNextData = await SohoApi.getNotice({"pagePerRows": '20', "bookmark": `${nextPage}`})
            setNotice(notice => Array.from(notice).concat(noticeNextData.data.list))
            setNextPage(nextPage + 1)
            console.log('setNextNotice:', noticeNextData.data)
    }

    const getNoticeDetail = async (noticeId) => {
        const noticedetailData = await SohoApi.getNoticeDetail({"noticeId": `${noticeId}`})
            setNoticedetail(noticedetailData.data);
            console.log("getNoticeDetail:",noticedetailData.data);
    }

    const noticedetailClick = async(noticeId) => {
        await getNoticeDetail(noticeId)   

        if($(`#list_a-${noticeId}`).parent(`#notice-${noticeId}`).hasClass('current')) {
            $(`#list_a-${noticeId}`).removeClass('on');
            $(`#notice-${noticeId}`).removeClass('current');
            setHasMore(false)
            const offsetTop = $(`.content.home`).offset().top
            $('html, body').animate({ scrollTop: offsetTop }, 500)

        } else {
            $(`.list_a`).removeClass('on');
            $(`.list_a`).parent().removeClass('current');
            $(`#list_a-${noticeId}`).addClass('on');
            $(`#notice-${noticeId}`).addClass('current');
            const offsetTop = $(`#notice-${noticeId}`).offset().top
            $('html, body').animate({ scrollTop: offsetTop }, 500)
        }
    }

    const getYYYYMMDD = (noticeDate) => {
        let ret = noticeDate;
        try {
            ret = noticeDate.substr(0,4) + "-" + noticeDate.substr(4,2) + "-" + noticeDate.substr(6,2) + noticeDate.substr(8,3) + ":" + noticeDate.substr(12,2);
        } catch(e) {
            ret = noticeDate;
        }
        return ret;
    }

    const goBackPage = () => {
        history.goBack();
    }

    const goPageOnClick = (urls=null) => {
        NativeBridge.externalCall(`${urls}`)
    }

    return (
        <>
            <div className="content home" >
                <div className="page_title_wrap nofixed">
                    <div className="inner">
                        <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                        <h2>공지보기</h2>
                    </div>
                </div>
                <div className="change_history">
                    <div className="inner">
                        <div className="notice_list row">
                            { notice !== null &&
                                <InfiniteScroll
                                    dataLength={notice.length}
                                    next={getNextNotice}
                                    hasMore={hasMore}
                                    scrollThreshold={'2px'}>
                                    { notice.map((noticeitem, index) => (
                                        <div id={`notice-${noticeitem.noticeId}`} className="aco_box usetap">
                                            <div id={`list_q-${noticeitem.noticeId}`} className="list_q" onClick={()=> noticedetailClick(noticeitem.noticeId)}>
                                                <span className="subject" dangerouslySetInnerHTML={ {__html: noticeitem.tit} }></span>
                                                <span className="i_date">{getYYYYMMDD(noticeitem.regScdStrtDttm)}</span>
                                                <span className="btn_dark_drop"></span>
                                            </div>
                                        { (noticedetail !== undefined && noticedetail !== null ) ?
                                            <div id={`list_a-${noticeitem.noticeId}`} className="list_a">
                                                <p className="content">
                                                    { noticedetail.cntnImge !== '' && 
                                                        <p><img src={`${noticedetail.cntnImge}`} /></p>
                                                    }
                                                    { noticedetail.urlConnImge !== '' && noticedetail.urlConnLnk !== '' ? 
                                                        <p><img src={`${noticedetail.urlConnImge}`} onClick={()=> goPageOnClick(`${noticedetail.urlConnLnk}`)} /></p>
                                                    :
                                                        noticedetail.urlConnImge !== '' ?
                                                        <p><img src={`${noticedetail.urlConnImge}`} /></p>
                                                    : 
                                                        <></> 
                                                    }
                                                    {noticedetail.cntn !== '' && 
                                                        <p dangerouslySetInnerHTML={ {__html: noticedetail.cntn} }></p>
                                                    }
                                                </p>
                                            </div>
                                        :
                                            <div id={`list_a-${noticeitem.noticeId}`} className="list_a">

                                            </div>
                                        }
                                        </div>
                                    ))
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default InviteAccountNotice;