import React from 'react'
import { observer } from "mobx-react";
import * as NativeBridge from '../../../utils/NativeBridge'


const WorkerManageUnSub = observer(() => {

    const goPageOnClick = () => {
		
        NativeBridge.externalCall("https://sohoshop.uplus.co.kr/soho/v1/cctv")
    }

    return (
		<>
			<div className="fix_container">
				<div className="unsubs_wrap unsubs_staff">
					<div className="intro_sec">
						<span className="title">출입문관리기</span>
						<p className="desc1">출입보안부터 근태관리까지<br/>한번에 해결하세요.</p>
						<p className="desc2">매장 출입에 관한 모든 것을 관리</p>
						<span className="intro_ico"></span>
					</div>
					<div className="major_service">
						<span className="title">주요 서비스</span>
						<ul>
							<li className="service1">
								<span className="box_title">출입문 원격제어</span>
								<p>지능형 CCTV 앱에서<br/>출입문 열림/잠금 원격 제어</p>
								<span className="service_ico"></span>
							</li>
							<li className="service2">
								<span className="box_title">직원 근태관리</span>
								<p>인원 별 출입 기록 / <br/>영상 조회</p>
								<span className="service_ico"></span>
							</li>
							<li className="service3">
								<span className="box_title">에스원 자동 출동</span>
								<p>이상 상황 감지,<br/>출입문 관리기 훼손 시 출동</p>
								<span className="service_ico"></span>
							</li>
							<li className="service4">
								<span className="box_title">지능형 이상상황 감지</span>
								<p>배회/유기/불꽃/연기 감지 시 <br/>실시간 알림</p>
								<span className="service_ico"></span>
							</li>
						</ul>
					</div>
					<div className="user_review">
						<span className="title">먼저 사용 중인 사장님들 리뷰</span>
						<div className="review_box">
							<div className="review_wrap right">
								<div className="msg bg1">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">호랑이</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
									</div>
									<p>CCTV 앱으로 영상을 보면서 출입문을 관리하니 편해요.</p>
								</div>
							</div>
							<div className="review_wrap left">
								<div className="msg bg2">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">김사장</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
										<span className="ico_star"></span>
									</div>
									<p>출동되는 상품도 함께 이용할 수 있어 <br/>안심이에요~</p>
								</div>
							</div>
							<div className="review_wrap right">
								<div className="msg bg3">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">다람쥐</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
									</div>
									<p>이상 감지 서비스를 <br/>무료로 이용할 수 있어 좋아요!</p>
								</div>
							</div>
						</div>
					</div>
					<div className="info_payment">
						<span className="title">요금 안내</span>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">출입문관리기</span>
							</div>
							<div className="charge_box">
								<span className="i_charge"><span className="ft_roboto">33,000</span>원</span>
							</div>
						</div>
						<ul className="desc_list">
							<li>3년 약정, 월 이용요금, 부가세 포함 기준</li>
							<li>영상분석 부가서비스 (배회/유기/연기/불꽃)무료</li>
						</ul>
					</div>
					<div className="btn_box fixed is_gnb">
						<div className="inner">
							<button type="button" onClick={()=> goPageOnClick()} className="btn_lg btn_point usetap">가입하기</button>
						</div>
					</div>
				</div>
			</div>
		{/* // : <WorkerManage />
		// } */}
		</>
    );
});

export default WorkerManageUnSub