import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";
import { tabUi } from "../../libs/tab";
import MsgPhonebook from './components/MsgPhonebook';
import MsgGroup from './components/MsgGroup';

const MakeCallRingPopup = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //console.log(userList);

    //console.log(store.userData.dataset.phonebookCnt);
    //console.log(store.userData.dataset.groupCnt);

   
    return (
        <>
            <div className="modal fadeIn" id="popup_callring_make">
                <div className="dim"onClick={()=> layerPopup.hide("#popup_callring_make")}></div>
                <div className="modal_inner">
                    <div className="modal_content">
                    <button type="button" className="pop_close2 blind" onClick={()=> layerPopup.hide("#popup_callring_make")}>닫기</button>
                        <div className="modal_hd">통화연결음 설정 안내</div>
                        <div className="modal_bd">
                            <p>
                            통화연결음을 먼저 만든 다음 설정할 수 있<br/>습니다. 우리가게만의 통화연결음을 만들어<br/>보세요.
                            </p>
                        </div>
                        <div className="modal_ft">
                            <Link to="/shop/call/callset/callring"><button type="button" className="btn usetap" onClick={()=> layerPopup.hide('#popup_callring_make')}><span>통화연결음 만들기</span></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MakeCallRingPopup;