import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";
import { getAddressListForPhoneByGroup, getAddressInfo, getGroup } from "../../common/common";
import { tabUi } from "../../libs/tab";
import MsgPhonebook from './components/MsgPhonebook';
import MsgGroup from './components/MsgGroup';

const MessageContactGroupAddPopup = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    useEffect(()=>{
        //$(".input_box.style1 input[type=text]").trigger("focus");
    }, []);

    useEffect(() => {
        console.log("MessageConractGroupAddPopup =>", userData.dataset.sendMsgAddrPopupToggle);

        //$(".input_box.style1 input[type=text]").trigger("focus");

        if ( userData.dataset.sendMsgAddrPopupToggle ){
            store.userData.setMsgPhonebookGroupList('phonebook');
        }

    }, [userData.dataset.sendMsgAddrPopupToggle])

    const ClickCancel = (e) => {
        userData.setSendMsgAddrPopupToggle(false);      //다음팝업 열리게 하기 위해
        layerPopup.hide('#popup_msgcontactgroup')
    }
    
    return (
        <>
            <div className="modal fadeInUp" id="popup_msgcontactgroup">
                <div className="dim" onClick={(e)=>ClickCancel(e)}></div>
                { store.userData.dataset.msgPhoneBookGroupTap === "phonebook" &&
                    <MsgPhonebook />
                }
                { store.userData.dataset.msgPhoneBookGroupTap === "group" &&
                    <MsgGroup />
                }
                
            </div>
        </>
    );
    });
    export default MessageContactGroupAddPopup;