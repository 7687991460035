import React, { useEffect, useState, useCallback } from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import { tabUi } from '../../../../libs/tab'
import { getPrevMonth, getAMPM, getTimes, getDateMontAllType, prettyMsgLog, getAddressInfo } from "../../../../common/common";
import { layerPopup } from "../../../../libs/common";
import { btnStUi } from "../../../../libs/btn_st";
import CentrexApi from '../../../../api/CentrexApi';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'moment/locale/ko';
import moment from "moment";

//전화 설정 페이지
const MsgLog = observer(({ history, match }) => {
   
    // useEffect(() => {
    //   store.userData.setCallTabList(`${calltap}`);
    // }, [store.userData.dataset.currPage])

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    
    const [currentPage, setPage] = useState(1);
    const [msgloglist, setMsgLogList] = useState([]);
    const [srchDestNumber, setSrcsDestNumber] = useState('');
    const [srchFromDate, setSrchFromData] = useState('');

    const [totalCnt, setTotalCount] = useState(0);
    const [hasMore, setHasMore] = useState(true);


    useEffect(() => {
		tabUi.init3DepthTab(document.getElementById('tab_style4'));
        btnStUi.msgScroll(document.getElementById('btn_floating_msg'))

	}, []);

    useEffect(() => {
        console.log("MsgLog default useEffect..");

        //초기화
        //setSrchFromData(getPrevMonth()); 

        GetAllSmsList( currentPage, getPrevMonth(), null);

        $(window).on('scroll', function() {
            const scrollTop = $(window).scrollTop()
            if(scrollTop <= 0) {
                setHasMore(false)
            } else {
                setHasMore(true);
            }
        })

    }, []);

    /*
    useEffect(()=>{
    },[msgloglist])
    */
    
    useEffect(() => {
        if(userData.dataset.msgCheck === "1"){
            setTimeout(() => {
                GetAllSmsList();
            },500)
        }
        userData.setMsgCheck("0");
    },[userData.dataset.msgCheck])

    // useEffect(() => {
    //     GetAllSmsList();
    // },[msgloglist[0].MESSAGE])

  const GetAllSmsList = async (page = 1, fromdate, destnumber = '') => {
    setPage(page);

    if ( destnumber !== null && destnumber !== ''){
        setSrcsDestNumber(destnumber);
    }

    if ( fromdate !== null && fromdate !== ''){
        setSrchFromData(fromdate);
    }

    var _param = {
        page : page
        ,fromdate : moment().subtract(1, 'month').format('YYYY-MM-DD HH-mm-ss') //YYYY-MM-DD HH:00:00
        ,viewtype : 'first'
        ,num_per_page : 200
    };

    if ( destnumber !== null && destnumber !== '' ){
        _param.destnumber = destnumber;
    }

    const result = await CentrexApi.getAllSmsList(_param);

    if( result.data.SVC_RT === "0000" ){

        const msgLoglistForPage = result.data.DATAS;
        if ( msgLoglistForPage !== null && msgLoglistForPage.length > 0 ){
            if ( page === 1) {
                setMsgLogList(msgLoglistForPage);
                setTotalCount(msgLoglistForPage.length);
            } else {
                setMsgLogList(msglog => Array.from(msglog).concat(msgLoglistForPage));
                setTotalCount(totalCnt => totalCnt + msgLoglistForPage.length);
            }
        }else{
            setMsgLogList(msgLoglistForPage);
            setTotalCount(msgLoglistForPage.length);
        }
    }
  }

  const GetAllSmsListNext = (page) => {
    GetAllSmsList(currentPage + 1, srchFromDate, srchDestNumber);
  }


  const MsgLogClick = (e, _item) => {
    history.push(
        {
            pathname : '/shop/msg/msglog/detail'
            ,state : {
                destnumber : _item.CTN
                ,item : _item 
            }
        }
    );
  }

  const ShowSendMessage = (e) => {
    userData.setMessagePopupCellNum({});
    userData.setMessagePopupConts("");
    layerPopup.show('#popup_msgwrite');
  }   

//   console.log(msgloglist[0].MESSAGE)

  return (
    <>
        <ul>
            { msgloglist !== null &&
                <InfiniteScroll
                    dataLength={totalCnt}
                    next={GetAllSmsListNext}
                    hasMore={hasMore}
                    scrollThreshold={'2px'}>
                    { 
                        msgloglist.map((msglog, index) => (
                            msglog.SRC !== msglog.DST ?
                            <>
                            <li key={'li_${msglog.NO}'} className="msg_log_list" onClick={(e)=> MsgLogClick(e,msglog)}>
                                <span className="i_name">{getAddressInfo(msglog.TYPE === 'SEND' ? msglog.DST : msglog.SRC).name}</span>
                                <p className="comment pre_line">{msglog.MESSAGE}</p>
                                <span className="time_wrap">
                                    <span className="time_num">{moment(msglog.TIME,'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD')}</span>
                                    <span className="time_sort">{moment(msglog.TIME,'YYYY-MM-DD hh:mm:ss').format('A')}</span>
                                    <span className="time_num">{moment(msglog.TIME,'YYYY-MM-DD hh:mm:ss').format('hh:mm')}</span>
                                </span>
                                {/* <button type="button" className="btn_small_x usetap blind">삭제</button> */}
                            </li>
                            </>
                            :
                            <>
                            </>
                        )) 
                    }
                </InfiniteScroll>
            }
        </ul>
        <div className="info_month_wrap">
            <span className="info_month">한 달 이내의 문자만 보여집니다.</span>
        </div>
        
            
            <button type="button" id="btn_floating_msg" className="btn_floating_msg blind usetap down" onClick={(e)=> ShowSendMessage(e)}>메시지 작성</button>
        
    </>
  );
});
export default MsgLog;