import React from 'react'
import * as NativeBridge from '../../../utils/NativeBridge'
import userData from '../../../store/userData';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from '../../../libs/common';
import { observer } from 'mobx-react';

const OrderManage = observer(() => {

    const InMenuPreview = () => {
        NativeBridge.externalCall(`${process.env.REACT_APP_SSG_URL}/live/in/${userData.dataset.storeId}`);
    }

    const OutMenuPreview = () => {
        NativeBridge.externalCall(`${process.env.REACT_APP_SSG_URL}/live/out/${userData.dataset.storeId}`);
    }
    
    const MenuEditClick = () => {
        NativeBridge.externalCall(`${process.env.REACT_APP_SOHO_KAKAO_URL}`);
    }

    const CopyComplete = () => {
        toast('URL이 복사되었습니다.');
    }

    return (
        <div className="fix_container manage_board">      
            <div className="sec_wrap">
                <span className="title">메뉴판 미리보기</span>
                <p className="desc">우리 가게 메뉴판이 손님들에게<br /> 어떻게 보이는지 확인해보세요.</p>
                <div className="preview_box i_internal" onClick={() => InMenuPreview()}>
                    <div className="middle_inner">
                        <span className="box_title">가게 방문용<br />미리보기</span>
                    </div>
                </div>
                <div className="preview_box i_external" onClick={() => OutMenuPreview()}>
                    <div className="middle_inner">
                        <span className="box_title">외부 홍보용<br />미리보기</span>
                    </div>
                </div>
            </div>
            <div className="sec_wrap">
                <span className="title">메뉴판 수정하기</span>
                <p className="desc">변동사항이 있다면 메뉴판에 반영해주세요.</p>
                <div className="btn_box">
                    <button type="button" className="btn_lg btn_point usetap" onClick={() => MenuEditClick()}>수정하기</button>
                </div>
            </div>
            <div className="sec_wrap">
                <span className="title">홍보하기</span>
                <p className="desc">외부 홍보용 메뉴판 URL을 복사하여<br /> 홍보하고 싶은 곳에 올려보세요.</p>
                <div className="btn_box">
                    <CopyToClipboard text={`${process.env.REACT_APP_SOHO_FOOD_URL}/live/out/${userData.dataset.storeId}`} onCopy={() => CopyComplete()}>
                        <button type="button" className="btn_lg btn_point usetap">URL 복사하기</button>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    )
});

export default OrderManage