import React from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';

const CustomerManageError = observer(() => {
    
    const { userInfo, userData } = store;

    const reSetClick = () => {
        userData.setCallPageReset(true);
    }
    return (

        <>
            <div className="sec_wrap"> 
                <strong className="st_msg">일시 오류, 새로고침으로 <br />정보를 불러주세요!</strong>
                <div className="ex_img row">
                    <img src="res/images/img-home-customer-card-error@3x.png" alt="전화 관리 예시" />
                    <button type="button" className="btn_refresh usetap blind" onClick={() => reSetClick()}>새로고침</button>
                </div>
            </div>       
        </>
    )
});

export default CustomerManageError;