import React, { useEffect }  from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../utils/NativeBridge";
import * as LocalStore from '../../../store/LocalStore';
import CallLogMain from "./calllog/CallLogMain";
import PhoneBookMain from "./phonebook/PhoneBookMain";
import CallLogEdit from "./callrec/CallLogEdit";
import CallSet from "./callsetting/CallSet";
import * as CONSTS from '../../../const';


//전화 설정 페이지
const ShopCall = observer(({ history, match }) => {

  const IsTempUser = LocalStore.getIsTempUser()

  const { userData } = store;

  useEffect(() => {
    console.log("shopcall==>", match);
  }, []); 

  const CallTapClick = (currMenu=null) => {
    userData.setCallTabList(currMenu);
    
    if(currMenu === 'calllog') {
      NativeBridge.sendUserActionLog('detail', 'D019');
      history.replace(`/shop/call/${currMenu}`);
    }
    else if(currMenu === 'phonebook') {
      userData.setPhoneMainTab(CONSTS.ADDRESS_TYPE_CENTRIX);  //메뉴 초기화
      NativeBridge.sendUserActionLog('detail', 'D020');
      history.replace(`/shop/call/${currMenu}/${userData.dataset.phoneBookTap}`);
    } 
    else if(currMenu === 'callrec' || currMenu === 'callset') {
      let detailCode = 'D021';
      if (currMenu === 'callset') {
        detailCode = 'D022';
      }
      NativeBridge.sendUserActionLog('detail', detailCode);
      history.replace(`/shop/call/${currMenu}`);
    } 
  }  

  return (
    <>
		<div className="fix_container contact_scroll_area" id="contact_scroll_area">
			<div className="store_call">
        <div className="tab_btn_wrap">
          <div id="tab_style4" className="tab_style4 tab4">
            <button className={"tab_item" + (userData.dataset.currCallPage === 'calllog' ? " active" : "")} onClick={()=> CallTapClick('calllog')}>통화기록</button>
            <button className={"tab_item" + (userData.dataset.currCallPage === 'phonebook' ? " active" : "")} onClick={()=> CallTapClick('phonebook',`${userData.dataset.phoneBookTap}`)}>연락처</button>
            <button className={"tab_item" + (userData.dataset.currCallPage === 'callrec' ? " active" : "")} onClick={()=> CallTapClick('callrec')}>녹취목록</button>
            <button className={"tab_item" + (userData.dataset.currCallPage === 'callset' ? " active" : "")} onClick={()=> CallTapClick('callset')}>전화설정</button>
          </div>
        </div>
                { (userData.dataset.currCallPage === 'calllog') &&
                    <Route path="/shop/call/calllog" component={CallLogMain} />
                }
                { (userData.dataset.currCallPage === 'phonebook') &&
                    <Route path="/shop/call/phonebook" component={PhoneBookMain} />
                }
                { (userData.dataset.currCallPage === 'callrec') &&
                    <Route path="/shop/call/callrec" component={CallLogEdit} />
                }
                { (userData.dataset.currCallPage === 'callset') &&
                    <Route path="/shop/call/callset" component={CallSet} />
                }
			</div>
		</div>
    </>
  );
});
export default ShopCall;