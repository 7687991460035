import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { layerPopup, toast } from "../../../../../libs/common";
import { getAddressInfo, checkIMEEnabledType, defaultString} from "../../../../../common/common";
import BizApi from "../../../../../api/BizApi";

//전화 설정 페이지
const PhoneBookDetailEdit = observer(({ history, location }) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  const [addressInfo, setAddressInfo] = useState({});   
  const [addressGroupList, setAddressGroupList] = useState({});       //주소록 그룹
  //입력항목
  const [name, setAddressName] = useState("");        //이름
  const [phone, setAddressPhone] = useState("");      //PHONE
  const [phone2, setAddressPhone2] = useState("");    //PHONE2
  const [etc, setAddressEtc] = useState("");          //etc
  const [no, setAddressNo] = useState("");            //NO(Centrix No)
  //const [groupNames, setGroupNames] = useState("");   //그룹명목록
  //const [groupIds, setGroupIds] = useState("");       //그룹ID목록

  const [groupName, setGroupName] = useState("");   //그룹명
  const [groupId, setGroupId] = useState("");       //그룹ID

  const [isSaveAction, setIsSaveAction] = useState(false);    //저장여부

  const destNumber = location.state?.destnumber;
  const addrNo = location.state.no;

	useEffect(() => {

    console.log("destNumber:" + destNumber);
    setAddressInfo(getAddressInfo(destNumber, addrNo));

	}, []);

  useEffect(() => {

    if ( isSaveAction ) {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>event userData.dataset.addressSyncFlag');
      toast("연락처가 수정되었습니다.");
      //setIsSaveAction(true);

      let _name = $('#name').val();
      let _phone = $('#phone').val();
      let _phone2 = $('#phone2').val();

      //네이티브 전달
      NativeBridge.modifyAddressInfo({
        group_code : groupId
        ,group_name : groupName
        ,name : _name
        ,phone : _phone + "," + _phone2
        ,etc : addressInfo.etc
        ,peer_no : addressInfo.no
      });

      //연락처 현행화
      //TODO : 과도하면 1페이지만 업데이트 하는 로직 구현 필요
      history.replace(
        {
            pathname : `/shop/call/phonebook/detail`
            ,state : {
                destnumber : _phone
                ,no : addressInfo.no
                ,item : null
            }
        }
      );
    }

	}, [userData.dataset.addressSyncFlag,isSaveAction]);

  useEffect(() => {
    /*
    setInputForm({
      name : addressInfo.name
      ,phone : addressInfo.phone
      ,phone2 : addressInfo.phone2
      ,etc : addressInfo.etc
      ,groupName : addressInfo.groupName
      ,groupCode : addressInfo.groupCode
      ,no : addressInfo.no
    });
    */

    //정보 설정
    setAddressName(addressInfo.name); //이름
    setAddressPhone(addressInfo.phone); //PHONE
    setAddressPhone2(addressInfo.phone2); //PHONE2
    setAddressEtc(addressInfo.etc); //etc
    setAddressNo(addressInfo.no); //NO(Centrix No)

    //setGroupNames(addressInfo.groupName); //그룹명
    //setGroupIds(addressInfo.groupCode); //그룹ID
    setGroupName(addressInfo.groupName); //그룹명
    setGroupId(addressInfo.groupCode); //그룹ID

	}, [addressInfo]);

  const goBackPage = () => {
      //history.goBack();
      history.replace(
        {
            pathname : `/shop/call/phonebook/detail`
            ,state : {
                destnumber : $('#phone').val()
                ,no : addressInfo.no
                ,item : null
            }
        }
      );
    }

  useEffect(() => {
    console.log("zxczxc", $('#name').val())
  },[$('#name').val()])

  const ClickSaveAction = async (e) => {
    let _name = defaultString($('#name').val(), "");
    let _phone = defaultString($('#phone').val(),"");
    let _phone2 = defaultString($('#phone2').val(),"");

    if($('#name').val().length < 1 || $('#name').val().length > 10){
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "글자 수 초과", "10자 이내로 입력해주세요.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);
      return false;
    }
    
    if ( _name === '' || _phone === '' ){
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "이름,전화번호를 입력했는지 확인해주세요.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);

      return false;
    }

    if ( !checkIMEEnabledType(_name) ){
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "특수 문자 입력 제한", "특수문자는 입력할 수 없습니다.<br/>영문, 숫자, 한글만 입력해 주세요.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);

      return false;
    }

    if ( groupId === addressInfo.groupCode && _name === addressInfo.name 
          && _phone === addressInfo.phone &&  defaultString(_phone2, '') === defaultString(addressInfo.phone2, '') ){

          layerPopup.showCommon("#popup_onetitlemsgbutton", null,"알림",  "변경된 내용이 없습니다.", [
            function() {
                layerPopup.hide("#popup_onetitlemsgbutton");
            }
          ]);
    
          return false;
    }

    if(_phone.length < 5 || _phone.length > 14){
      layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'연락처 등록 실패', 
				'연락처는 5~14자리로 입력해주세요.'
			);
			return;
    }

    if(_phone2.length > 0 ) {
      if(_phone2.length < 5 || _phone2.length > 14){
        layerPopup.showCommon(
          '#popup_onetitlemsgbutton', 
          null, 
          '연락처 등록 실패', 
          '연락처는 5~14자리로 입력해주세요.'
        );
        return;
      }
    }

    var reqParam = {
        group_code : groupId
        ,name : _name
        ,phone1 : _phone
        ,phone2 : _phone2
        ,etc : addressInfo.etc
        ,peer_no : addressInfo.no
    };

    console.log("reqParam=>", reqParam);
    console.log("addressInfo=>", addressInfo);

    const ret = await CentrexApi.ModifyPhonebookInfo(reqParam);

    if ( ret.data.SVC_RT === '0000'){
      setIsSaveAction(true);
      BizApi.makeCentrixPhonebookList();
    //   history.goBack( {
       
    //     state : {
    //         destnumber : _name
    //         ,no : _phone
    //         ,item : null
    //     }
    // });
     
    }else{
      // toast("연락처 수정 중 오류가 발생되었습니다.");
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "연락처 수정 실패", "연락처 수정이 실패했습니다. 다시 시도해주세요.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);
    }
  }

  const ClickShowPop = async (e) => {
    console.log('ClickShowPop');
    const result = await CentrexApi.getAddressGroupList(); 

		console.log("그룹목록조회 ===>", result);
    if(result.data.SVC_RT === "0000"){
      setAddressGroupList(result.data.DATAS);

      //초기화
      //$('input[type=checkbox][name=contactGroup1]').attr('checked', false);
      $('input[type=checkbox][name=contactGroup1]:checked').each(function(idx, item) {
          console.log('11111');
          $(this).prop('checked', false);
      });
      //팝업 모달 노출
      layerPopup.show('#popup_phongbook_group_select');
    }else{
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "선택할 그룹이 없습니다.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);
    }

  }

  const PopSelectGroup = (e, _item = null) => {

    /*
    let arrSelectedGroupNames = [];
    let arrSelectedGroupIds = [];

    $('input[type=checkbox][name=contactGroup1]:checked').each(function(idx, item) {
      console.log("groupid:",$(this).attr('groupid'), "groupname:",$(this).attr('groupname'));

      if ( $(this).attr('groupid') !== undefined ){
        arrSelectedGroupNames.push($(this).attr('groupname'));
        arrSelectedGroupIds.push($(this).attr('groupid'));
      }
    });

    console.log("!!!!!" + arrSelectedGroupNames.join(","));
    setGroupIds(arrSelectedGroupIds.join(","));
    setGroupNames(arrSelectedGroupNames.join(","));
    */

    if ( _item !== null ){
      setGroupName(_item.GROUP_NAME);
      setGroupId(_item.GROUP_CODE);
    }else{
      setGroupName($('input[type=radio][name=contactGroup1]:checked').attr('groupname'));
      setGroupId($('input[type=radio][name=contactGroup1]:checked').attr('groupid'))
    }

    

    layerPopup.hide("#popup_phongbook_group_select");
    
  }



  const ChangeInputText = (e) => {
    if ( e.target.id === 'name') setAddressName(e.target.value);
    else if ( e.target.id === 'phone') setAddressPhone(e.target.value);
    else if ( e.target.id === 'phone2') setAddressPhone2(e.target.value);
    else if ( e.target.id === 'etc') setAddressEtc(e.target.value);
    /*
    TODO : REF.https://www.pluralsight.com/guides/how-to-use-react-to-set-the-value-of-an-input
    setInputForm({
      [e.target.id] : e.target.value
    });
    */
  }

  const ClickDelGroup = (e) => {
    setGroupName("");
    setGroupId("");
  }
  useEffect(() => {
		//console.log("ㅁㅁ",groupNamed)
		if(name !== ""){
		document.getElementById('input_name').innerText = ""
		}
		else{
			document.getElementById('input_name').innerText = "이름을 입력해주세요."
      $("#input_name").show();
		}
	},[name])
  useEffect(() => {
		//console.log("ㅁㅁ",groupNamed)
		if(phone !== ""){
		document.getElementById('input_phone').innerText = ""
		}
		else{
			document.getElementById('input_phone').innerText = "번호를 입력해주세요."
      $("#input_phone").show();
		}
	},[phone])

  useEffect(() => {
		//console.log("ㅁㅁ",groupNamed)
		if(phone2 !== ""){
		document.getElementById('input_phone2').innerText = ""
    console.log("확인")
		}
		else{
			document.getElementById('input_phone2').innerText = "번호를 입력해주세요."
      console.log("확인2")
      $("#input_phone2").show();
		}
	},[phone2])


  const textClearOnClick = (e) => {
  
    setAddressName("")
	}
  const textClearOnClick2 = (e) => {
  
    setAddressPhone("")
	}
  
  const textClearOnClick3 = (e) => {
    
    setAddressPhone2("")
	}

  const focusCheck = (e) => {
    let name = e.target.id;

    //setLabelHide("1") 

    if(name === "input_name") 
    {
      $("#input_name").hide();
      $("name").show();
    }
    if(name === "input_phone") 
    {
      $("#input_phone").hide();
      $("phone").show();
    }
    if(name === "input_phone2") 
    {
      $("#input_phone2").hide();
      $("phone2").show();
    }
   
    //$("#input_name").hide();
    //console.log("aa")
  }
  

  

  return (
    <>
        <div className="content store">
            <div className="page_title_wrap3">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                    <h2>연락처 수정</h2>
                    <button type="button" className="btn_complete" onClick={(e) => ClickSaveAction(e)}>완료</button>
                </div>
            </div>
            <div className="contact_info edit">
                <div className="name_wrap">
                <span className="title">이름</span>
                    <div className="input_box style3">
                        <input type="text" id="name" value={name||''} onChange={(e) => ChangeInputText(e)} />
                        <label htmlFor="name" id="input_name" onClick={(e)=> focusCheck(e)} className="label_placeholder">이름을 입력해주세요.</label>
                        <span className="ip_gradient"></span>
                        <span className="ip_clear hidden"  name="name" onClick={(e)=>textClearOnClick(e)}>초기화</span>
                    </div>
                </div>
                <div className="tel_wrap">
                    <span className="title">전화번호</span>
                    <div className="input_box style3">
                        <input type="tel" id="phone" value={phone||''} onChange={(e) => ChangeInputText(e)}/>
                        <label htmlFor="phone" id="input_phone" onClick={(e)=> focusCheck(e)} className="label_placeholder">번호를 입력해주세요.</label>
                        <span className="ip_gradient"></span>
                        <span className="ip_clear hidden" name="phone" onClick={(e)=>textClearOnClick2(e)}>초기화</span>
                    </div>
                    <div className="input_box style3">
                        <input type="tel" id="phone2" value={phone2||''} onChange={(e) => ChangeInputText(e)}/>
                        <label htmlFor="phone2" id="input_phone2" onClick={(e)=> focusCheck(e)} className="label_placeholder">번호를 입력해주세요.</label>
                        <span className="ip_gradient"></span>
                        <span className="ip_clear hidden" name ="phone2" onClick={(e)=>textClearOnClick3(e)}>초기화</span>
                    </div>
                </div>
            </div>
            <ul className="contact_detail_list">
                <li className="detail_group_list">
                    <span className="title">그룹</span>
                    {
                      ( groupId !== undefined && groupId !== '' && groupId !== '0') ?
                      <>
                        <button type="button" className="gp_del usetap" onClick={(e) => ClickDelGroup(e)} >{groupName}</button>
                      </>
                      :
                      <>
                        <button className="btn_round purple usetap" onClick={(e) => ClickShowPop(e)}>추가</button>
                      </>
                    }
                </li>
            </ul>
        </div>

        <div className="modal fadeInUp" id="popup_phongbook_group_select">
            <div className="dim" onClick={()=>layerPopup.hide("#popup_phongbook_group_select")}></div>
            <div className="modal_inner modal_up">
                <button type="button" className="pop_close_txt" onClick={()=>layerPopup.hide("#popup_phongbook_group_select")}>취소</button>
                <span className="modal_title">그룹선택</span>
                <ul className="modal_radio_list">
                  { 
                    addressGroupList !== null && addressGroupList.length > 0 ?
                      addressGroupList.map((item, idx) => 
                        <>
                          <li key={idx}>
                            <div className="checkbox_green">
                              <input type="checkbox" id={"contactGroup_" + idx} name="contactGroup1" {...{groupid: item.GROUP_CODE, groupname: item.GROUP_NAME}} onClick={(e) => PopSelectGroup(e, item)} />
                              <label htmlFor={"contactGroup_" + idx} className="usetap">{item.GROUP_NAME}</label>
                            </div>
                            {/*
                            <div className="radio_box">
                              <input type="radio" id={"contactGroup_" + idx} name="contactGroup1" {...{groupid: item.GROUP_CODE, groupname: item.GROUP_NAME}}   />
                              <label htmlFor={"contactGroup_" + idx}>{item.GROUP_NAME}</label>
                            </div>
                          */}
                          </li>
                        </>
                      )
                    : <></>
                  }
                </ul>
                <div className="btn_box bottom_box">
                    <button type="button" className="btn_lg btn_point usetap" onClick={(e)=>PopSelectGroup(e)}>완료</button>
                </div>
            </div>
        </div>
    </>
  );
});
export default PhoneBookDetailEdit;