import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { tabUi } from '../../libs/tab'
import { webUI } from "../../libs/common";
import ShopCall from './call/ShopCall';
import { Route } from "react-router";
import CCTV from "./cctv/CCTV";
import MessageUnSub from "../shop/msg/MessageUnSub";
import WorkerManage from "./workermanage/WorkerManage";
import ShopManage from "./shopmanage/ShopManage";
import MsgMain from "./msg/MsgMain";
import CallLogUnSub from "../shop/call/CallLogUnSub";
import CCTVUnSub from "./cctv/CCTVUnSub";
import WorkerManageUnSub from "../shop/workermanage/WorkerManageUnSub";
import ShopManageUnSub from "../shop/shopmanage/ShopManageUnSub";
import { layerPopup } from '../../libs/common';
import { getGroup } from '../../common/common';
import BizApi from '../../api/BizApi';
import * as CONSTS from '../../const';
import MessageError from "../shop/msg/MessageError";
import CallLogError from "../shop/call/CallLogError" 
import ShopAnalyzeUnSub from "./shopanalyze/ShopAnalyzeUnSub";

const Shop = observer(({history, match}) => {
  const { userInfo, userData } = store;

  let id = LocalStore.getStoreInetNo();
  let pass = LocalStore.getTempInetPass();

  let centrexKey = LocalStore.getCentrexKey();
  let storeListData = LocalStore.getStoreList();

  useEffect(() => {
    $("html, body").removeClass("noscroll");

    if($("#wrap").hasClass('bg_gray')) {
			$("#wrap").removeClass('bg_gray');
		}

    // //연락처 정보 요청
    // CallAddressData();

    //연락처 정보 조회 
    //bridge 리턴
		const subscription = NativeBridge.eventObservable.subscribe(event => {
			console.log("----------------- from Native Event : Shop.js ------------------");
			console.log(event);
			switch (event.type) {                    
	
				case 'GET_ADDRESS_LIST':
					const result = event.param;
					console.log(" result: " , result);
					const _phonebookList = result.address;
          let phonebookType = "";
					const _phonebookListAfter = [];
					if ( _phonebookList !== undefined &&_phonebookList !== null && _phonebookList.length > 0 ){
            phonebookType = ( _phonebookList[0].no === undefined ) ? CONSTS.ADDRESS_TYPE_DEVICE : CONSTS.ADDRESS_TYPE_CENTRIX

						_phonebookList.map((item, idx) => (
							_phonebookListAfter.push(
								{ 
									etc : (item.etc === undefined || item.etc === null || item.etc === '' ) ? '' : item.etc 
									,groupCode: item.groupCode
									,groupName: item.groupName
									,name: item.name
									,no: item.no
									,contactId : item.contactId
									,phone : (item.phone.replace(/-/gi, "").indexOf(',') > 0) ? item.phone.replace(/-/gi, "").split(',')[0] : item.phone.replace(/-/gi, "")
									,phone2 : (item.phone.replace(/-/gi, "").indexOf(',') > 0) ? item.phone.replace(/-/gi, "").split(',')[1] : ''
									,drawgroup : getGroup(item.name)
									,bookType : CONSTS.ADDRESS_TYPE_DEVICE
								}
							)
						));
					}

					//userData에 저장 ( 이후 사용을 위해 )
          userData.setDevicePhoneBookList(_phonebookListAfter);

				break;
	
			default:
				break;
			}
		});

		return () => {
      subscription.unsubscribe();
		};
  }, []);

  useEffect(() => {
    const submenu = match.params.mainmenu;
    userData.setShopMainTabList(submenu);
  }, [match.params.mainmenu]);

  useEffect(() => {
    let menuIdx = getMenuIdx(userData.dataset.shopMainTab);
    let container = document.getElementById('tab_1depth');
    tabUi.initSubTab(document.getElementById('tab_1depth'), menuIdx);
    webUI.animatedScrollTo(document.getElementById('tab_1depth'),(container.querySelectorAll('li')[menuIdx].offsetLeft + 
    container.querySelectorAll('li')[menuIdx].clientWidth * 0.5) - (container.clientWidth * 0.5), 300);
  }, [userData.dataset.shopMainTab, userData.dataset.currstoreInfo]);

  const showStoreListPopup = (e) => {
    if(storeListData.length > 1) {
      if(LocalStore.getStoreId() === userData.dataset.currstoreInfo.storeId) {
        $(`input:radio[id=storeIdx_${userData.dataset.currstoreInfo.storeId}]`).prop("checked", true);
      } else if(LocalStore.getStoreId() !== userData.dataset.currstoreInfo.storeId) {
        $(`input:radio[id=storeIdx_${userData.dataset.currstoreInfo.storeId}]`).prop("checked", false);
      }
      layerPopup.show('#popup_store_id_select');
    } else {
      return;
    }
  }

  const selectStore = () => {
      layerPopup.hide('#popup_store_id_select', async()=> {
        const storeId = $("#popup_store_id_select ul li input[name='shopSelect']:checked").attr("storeid");
        const storeNm = $("#popup_store_id_select ul li input[name='shopSelect']:checked").attr("storenm");
        userData.setMsgLogTabList('msgset')

        if(userData.dataset.shopMainTab === 'call') {
          userData.setCallTabList('calllog');
          history.push('/shop/call/calllog');
        } 
        
        if(userData.dataset.shopMainTab === 'msg') {
          userData.setMsgLogTabList('msgset');
          history.push('/shop/msg/msgset');
        }

        //TODO : 스토어 변경시 필요한 프로세스 진행
        await BizApi.procChgStore(storeId);
    });
  }

  const getMenuIdx = (menuName) => {
    let menuIdx = 0;
    switch (menuName)
    {
      case "cctv" : 
        menuIdx = 0;
        break;
      case "call" : 
        menuIdx = 1;
        break;
      case "msg" : 
        menuIdx = 2;
        break;
      case "workermanage" : 
        menuIdx = 3;
        break;
      case "shopanalyze" : 
        menuIdx = 4;
        break;
      case "shopmanage" : 
        menuIdx = 5;
        break;
      default:
        menuIdx = 0;
        break;
    }
    return menuIdx;
  }

  const MenuClick = (currMenu=null) => {
    userData.setShopMainTabList(currMenu); 
    let menuCode = '';
    switch (currMenu)
    {
      case "cctv" : 
        menuCode = 'S004'
        break;
      case "workermanage" : 
        menuCode = 'S003';
        break;
      case "shopanalyze" :
        menuCode = 'S006';
        break;
      case "shopmanage" : 
        menuCode = 'S007';
        break;
      default:
        menuCode = '';
    }

    if (menuCode !== '') {
      NativeBridge.sendUserActionLog('menu', menuCode);
    }
    history.replace(`/shop/${currMenu}`)
  }

  const CallMenuClick = (currMenu=null) => {
    userData.setShopMainTabList(currMenu);
    userData.setCallTabList('calllog')

    NativeBridge.sendUserActionLog('menu', 'S001');

    if( userData.dataset.storeInetNo !== '') {
      if(userData.dataset.currCallPage === 'calllog' || userData.dataset.currCallPage === 'callrec' ||
          userData.dataset.currCallPage === 'callset') {
        history.replace(`/shop/${currMenu}/${userData.dataset.currCallPage}`);
      } 
      else if(userData.dataset.currCallPage === 'phonebook' && 
              (userData.dataset.phoneBookTap === 'phonebook' || 
                userData.dataset.phoneBookTap === 'group' || userData.dataset.phoneBookTap === 'callmemo')) {
        history.replace(`/shop/${currMenu}/${userData.dataset.currCallPage}/${userData.dataset.phoneBookTap}`);
      } else {
        return;
      }
    } 
    else {
      userData.setShopMainTabList('calllog');
      history.replace(`/shop/${currMenu}`)
    }
  }

  const MsgMenuClick = (currMenu= null) => {
    store.userData.setShopMainTabList(currMenu);
    
    if(LocalStore.getIsTempUser() === false){
    userData.setMsgLogTabList('msgset')
    }
    else{
      userData.setMsgLogTabList('msglog')
    }
    NativeBridge.sendUserActionLog('menu', 'S002');
    if(userData.dataset.storeInetNo !== '') {
      history.replace(`/shop/${currMenu}/${userData.dataset.shopMsgLogTab}`);
    } else {
      history.replace(`/shop/${currMenu}`)
    }
  }

  //주소록 정보 요청 및 저장
  const CallAddressData = async () => {
      console.log("==================================================[ 연락처 정보 요청 ]================");
      //get address
      BizApi.makeCentrixPhonebookList("__SHOP__");
      NativeBridge.getAddressList(CONSTS.ADDRESS_TYPE_DEVICE, "");	//휴대전화 연락처 조회
  }
  
  return (
    <>
      <div className="content store">
        <div className="hd_fix_wrap">
          <div className="hd_top2">
            <div className="hd_top_inner">
              { storeListData.length === 1 ?
                <button type="button" className="hd_sel_lounge usetap active" onClick={(e)=>showStoreListPopup(e)}>{userData.dataset.currstoreInfo.storeNm}</button>
              :
                <button type="button" className="hd_sel_shop usetap active" onClick={(e)=>showStoreListPopup(e)}>{userData.dataset.currstoreInfo.storeNm}</button>
              }
            </div>
          </div>
          <div className="tab_1depth_wrap">
            <div className="tab_1depth" id="tab_1depth">
              <ul className="tab_link">
                <li className={"tab_item" + (userData.dataset.shopMainTab === 'cctv' ? " active" : "")} 
                    onClick={()=>MenuClick('cctv')}><span>지능형CCTV</span>
                </li>
                <li className={"tab_item" + (userData.dataset.shopMainTab === 'call' ? " active" : "")} 
                    onClick={()=>CallMenuClick('call')}><span>인터넷 전화</span>
                </li>
                <li className={"tab_item" + (userData.dataset.shopMainTab === 'msg' ? " active" : "")} 
                    onClick={()=>MsgMenuClick('msg')}><span>메시지</span>
                </li>
                <li className={"tab_item" + (userData.dataset.shopMainTab === 'workermanage' ? " active" : "")} 
                    onClick={()=>MenuClick('workermanage')}><span>직원관리</span>
                </li>
                <li className={"tab_item" + (userData.dataset.shopMainTab === 'shopanalyze' ? " active" : "")} 
                    onClick={()=>MenuClick('shopanalyze')}><span>상권분석</span>
                </li>
                <li className={"tab_item" + (userData.dataset.shopMainTab === 'shopmanage' ? " active" : "")} 
                    onClick={()=>MenuClick('shopmanage')}><span>머스타드</span>
                </li>
              </ul>
              <div className="active_bar"></div>
            </div>
          </div>  
        </div>
        {   
          <>
            {/* 우리가게 CCTV */}
            { (userData.dataset.shopMainTab === "cctv" && userInfo.dataset.myservice.cctv) &&
                <Route exact path="/shop/cctv" component={CCTV} />
            }
            { (userData.dataset.shopMainTab === "cctv" && !userInfo.dataset.myservice.cctv) &&
                <Route exact path="/shop/cctv" component={CCTVUnSub} />
            }
            
            {/* 우리가게 전화 */}
            { 
              (
                (
                  /* 일반사용자 */
                  userData.dataset.shopMainTab === "call" && userInfo.dataset.myservice.call && userInfo.dataset.isTempUser === false && 
                  ( centrexKey !== undefined && centrexKey !== null && centrexKey !== "" ) 
                )    
                ||
                (
                  /*임시사용자 */
                  userData.dataset.shopMainTab === "call" && userInfo.dataset.myservice.call &&  userInfo.dataset.isTempUser === true && 
                  ( id !== "" &&  pass !== "" && pass !== null)
                )
              )
              &&
              ( !userData.dataset.shopMainTabCallError )
              &&
              <Route path="/shop/call" component={ShopCall} />
            }
            { (userData.dataset.shopMainTab === "call" && !userInfo.dataset.myservice.call && !userData.dataset.shopMainTabCallError) &&
              <Route path="/shop/call" component={CallLogUnSub} />
            }
            { 
              (
                  (
                    /* 일반사용자 */
                    userData.dataset.shopMainTab === "call" && userInfo.dataset.myservice.call && userInfo.dataset.isTempUser === false && 
                    (centrexKey === "" || centrexKey === null || centrexKey === undefined) 
                  )    
                  ||  
                  (
                    /*임시사용자 */
                    userData.dataset.shopMainTab === "call" && userInfo.dataset.myservice.call &&  userInfo.dataset.isTempUser === true && 
                    ( id === "" ||  pass === "" || pass === null)
                  )
                  ||
                  (userData.dataset.shopMainTabCallError)
              )
              &&    
              <Route path="/shop/call" component={CallLogError} />
            }

            {/* 우리가게 메시지 */}
            { 
                (
                  (
                    /* 일반사용자 */
                    userData.dataset.shopMainTab === "msg" && userInfo.dataset.myservice.call && userInfo.dataset.isTempUser === false &&
                    ( centrexKey !== "" && centrexKey !== null && centrexKey !== undefined )
                  )
                  ||
                  (
                    /* 임시사용자 */
                    userData.dataset.shopMainTab === "msg" && userInfo.dataset.myservice.call && userInfo.dataset.isTempUser === true  &&
                    ( id !== "" &&  pass !== "" && pass !== null)
                  )
                ) &&
                (!userData.dataset.shopMainTabMsgError) &&
                <Route path="/shop/msg" component={MsgMain} />
            }
            { (userData.dataset.shopMainTab === "msg" && !userInfo.dataset.myservice.call && !userData.dataset.shopMainTabMsgError )  &&
                <Route path="/shop/msg" component={MessageUnSub} />
            }
            { 
                (
                  (
                    userData.dataset.shopMainTab === "msg" && userInfo.dataset.myservice.call && userInfo.dataset.isTempUser === false && 
                    (centrexKey === undefined || centrexKey === null || centrexKey === '') 
                  )
                  ||
                  ( 
                    userData.dataset.shopMainTab === "msg" && userInfo.dataset.myservice.call && userInfo.dataset.isTempUser === true && 
                    ( id === "" || id === null   || pass === "" || pass === null )
                  )
                  ||
                  (userData.dataset.shopMainTabMsgError)
                ) &&
                <Route path="/shop/msg" component={MessageError} />
            }
            {/* 우리가게 직원관리 */}
            { (userData.dataset.shopMainTab === "workermanage" && userInfo.dataset.myservice.staffMng) &&
                <Route exact path="/shop/workermanage" component={WorkerManage} />
            }
            { (userData.dataset.shopMainTab === "workermanage" && !userInfo.dataset.myservice.staffMng) &&
                <Route exact path="/shop/workermanage" component={WorkerManageUnSub} />
            }

            {/* 우리가게 상권분석 */}
            { (userData.dataset.shopMainTab === "shopanalyze") &&
                <Route exact path="/shop/shopanalyze" component={ShopAnalyzeUnSub} />
            }         

            {/* 우리가게 머스타드 */}
            { (userData.dataset.shopMainTab === "shopmanage" && userInfo.dataset.myservice.promota) &&
                <Route exact path="/shop/shopmanage" component={ShopManage} />
            }
            { (userData.dataset.shopMainTab === "shopmanage" && !userInfo.dataset.myservice.promota) &&
                <Route exact path="/shop/shopmanage" component={ShopManageUnSub} />
            }
          </>
        }         
      </div>

      <div className="modal fadeInUp" id="popup_store_id_select">
          <div className="dim" onClick={()=>layerPopup.hide('#popup_store_id_select')}></div>
          <div className="modal_inner modal_up">
              <span className="modal_title">가게 선택</span>
              <ul className="modal_radio_list">
                  {storeListData.map(item => (
                      <li key={item.storeId}>
                          <div className="radio_box green">
                              <input type="radio" id={`storeIdx_${item.storeId}`} name="shopSelect" {...{storeid: item.storeId, storenm: item.storeNm}} />
                              <label htmlFor={`storeIdx_${item.storeId}`}  className="usetap">{item.storeNm}</label>
                          </div>
                      </li>
                  ))}
              </ul>
              <div className="btn_box bottom_box">
                  <button type="button" className="btn_lg btn_point usetap" onClick={selectStore}>완료</button>
              </div>
          </div>
      </div>
    </>
  );
});

export default Shop;
