import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import { tabUi } from '../../../../libs/tab'
import { layerPopup } from "../../../../libs/common";
import { chattingUi } from "../../../../libs/chatting";
import 'moment/locale/ko';
import moment from "moment";
import CentrexApi from '../../../../api/CentrexApi';
import { getPrevMonth, prettyMsgDetailLog, getDateMont, getAddressInfo } from "../../../../common/common";
import InfiniteScroll from 'react-infinite-scroll-component';
import SohoApi from "../../../../api/SohoApi";
import * as LocalStore from "../../../../store/LocalStore"

//전화 설정 페이지
const MsgLogDetail = observer(({ history, match, location }) => {
    const scrollRef = useRef();

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const targetNumber = location.state.destnumber;

    const [srchFromDate, setSrchFromData] = useState('');

    const [msgloglist, setMsgLogList] = useState([]);
    const [msglogDrawlist, setDrawDataMsgLogList] = useState([]);

    const [title, setTitle] = useState('');
    const [currentPage, setPage] = useState(1);
    const [totalCnt, setTotalCount] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    let tmplst = [];
    const maxRetryCnt =  100;
    let currRetryCnt = 0;
    
  

    useEffect(() => {
        //  $('#chreerScroll').scrollTop($('#chreerScroll')[0].scrollHeight);
        // const ele = document.querySelector("div");
        // ele.scrollTop = ele.scrollHeight;
        
        // var objDiv = document.getElementById("chat_wrap"); 
        // objDiv.scrollTop = objDiv.scrollHeight;

        //$('html, body').scrollTop(10000);

        console.log("MsgLogDetail useEffect");

        console.log('match.params', match.params); 
        console.log('location', location); 

        setTitle(_title => getAddressInfo(targetNumber).name);
        //초기화
        GetAllSmsList( 1, getPrevMonth(), targetNumber);

        $(window).on('scroll', function() {
            const scrollTop = $(window).scrollTop()
            if(scrollTop <= 0) {
                setHasMore(false)
            } else {
                setHasMore(true);
            }
        });

        //chattingUi.chatlayout(document.getElementById('origin'));
        //document.body.scrollTop = document.body.scrollHeight;
        
    }, []);

    

    useEffect(() => {
        //$('html, body').scrollTop(0);
        console.log("change msgloglist >>>>>>>>>> ");
        console.log(msgloglist);

        if ( msgloglist !== null && msgloglist.length > 0  ){

            var drawData = msgloglist.reduce((src, dest ) => {

                (src[getDateMont(dest.TIME)] = src[getDateMont(dest.TIME)] || []).push(dest);
                return src;
            }, {});
       
            var drawlstData = [];
            console.log("data", drawData)
            Object.keys(drawData).map((_key) => {
                if ( drawlstData[_key] === null ){
                    drawlstData[_key] = [];
                }
                
                drawlstData.push({
                    title : _key
                    ,items : drawData[_key]
                });
            });

            drawlstData.map((data) => {
                data.items.reverse()
            })
            

            console.log(">>>>final =>", drawlstData);
            setDrawDataMsgLogList(drawlstData.reverse());
        }else{
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>");
        }
        
        
        /*
        var menuHeight = document.querySelector(".scroll_bottom").offsetHeight;
        console.log(">>>>menuHeight:" , menuHeight);
        var location = document.querySelector("#move").offsetTop;
        console.log(">>>>location:" , location);
        window.scrollTo({top:location - menuHeight, behavior:'smooth'});
        */

        if ( msgloglist !== null && msgloglist.length > 0  ){
            scrollEnd();
        }

        // console.log('currentPage=>', currentPage);
        // if ( currentPage === 1 ){
        //     setTimeout(()=>{
        //         console.log('change msgloglist ==>scrolling');
        //         window.scrollTo(0, 2000);
        //         $('div.infinite-scroll-component').scrollTop($('div.infinite-scroll-component').prop('scrollHeight'));
        //     }, 50);  
        // }
        
    }, [msgloglist]);

    const getoffSet = (e) => {
        console.log(e, e.natiiveEvent.target.childNodes[0].offsetTop)
    }

    const scrollEnd = () => {
        console.log("currRetryCnt : ", currRetryCnt);
        if(currRetryCnt > maxRetryCnt) {
            currRetryCnt = 0;
            return;
        }
        if($(".msg_wrap").length > 0) {
            setTimeout(()=>{
                const docHeight = $(".infinite-scroll-component").height();
                const winHeight = $("#chreerScroll").height();
                const scrollSize = docHeight-winHeight;
                $("#chreerScroll").animate({ scrollTop: scrollSize }, "fast");
            });
            currRetryCnt = 0;
        } else {
            setTimeout(()=>{
                currRetryCnt++;
                scrollEnd();
            }, 50);        
        }
    }

    const GetAllSmsList = async (_page = 1, _fromdate, _destnumber = '') => {

        setPage(_page);
    
        var _param = {
            page : _page
            ,fromdate : moment().subtract(1, 'month').format('YYYY-MM-DD HH-mm-ss') //YYYY-MM-DD HH:00:00
            ,num_per_page : 200
            //,viewtype : 'first'
        };
    
        if ( _destnumber !== null && _destnumber !== '' ){
            _param.destnumber = _destnumber;
        }
    
        const result = await CentrexApi.getAllSmsList(_param);
    
        if( result.data.SVC_RT === "0000" ){
            const msgLoglistForPage = result.data.DATAS;
            setTotalCount(result.data.LISTINFO.total);
            
            if ( msgLoglistForPage !== null && msgLoglistForPage.length > 0 ){
                
                if ( _page === 1 ){
                    setMsgLogList(msgLoglistForPage);
                }else{
                    setMsgLogList(msgloglist => Array.from(msgloglist).concat(msgLoglistForPage));
                }
                
                //setTotalCount(totalCnt => totalCnt + msgLoglistForPage.length);
            }
        }
    }

    const GetAllSmsListNext = (page) => { 
        GetAllSmsList(currentPage + 1, srchFromDate, targetNumber);
    }

    const ClickSendSMS = async (e) => {
        const _message = $("#origin").val();

        if ( _message.length < 1){
            //메시지 내용을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "보내실 메시지 내용을 입력하세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
            $("html, body").removeClass("noscroll");
            return;
        }

        const result = await CentrexApi.sendSms({
            destnumber : targetNumber
            ,smsmsg : _message
        }).catch((e) => {

            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(인터넷 전화)", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);  
            $("html, body").removeClass("noscroll"); 
        })

        if ( result.data.SVC_RT === '0000'){

            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", '문자 발송이 완료되었습니다.', [
                function() {
                    $("#origin").val('');
                    //초기화
                    layerPopup.hide("#popup_onetitlemsgbutton");
                    setTimeout(() => {
                        GetAllSmsList( 1, getPrevMonth(), targetNumber);
                    }, 2000);
                }
            ]);
            $("html, body").removeClass("noscroll");
            //scrollToBottom();

        }else{
            //resultMsg = '실패하였습니다.(사유:' + result.data.SVC_MSG + ')' ;

            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", '발송이 실패하였습니다.', [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
            $("html, body").removeClass("noscroll");
        }
        
    }

    const ClickPhonebookDetail = (e) => {
		history.push(
            {
                pathname : `/shop/call/phonebook/detail`
                ,state : {
                    destnumber : targetNumber
                    ,no : ''
                    ,item : null 
                }
            }
        );
	}

  return (
    <>
    <div id="wrap" className="chatting_wrap" >
        <div id="container">
        <div className="content store chatting" >
            <div className="page_title_wrap">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> history.goBack()}>뒤로가기</button>
                    <h2>{title}</h2>
                    <button type="button" className="btn_info usetap blind" onClick={(e)=> ClickPhonebookDetail(e)}>연락처 상세 화면</button>
                </div>
            </div>
            <div className="content_scroll" >
                <div className="chat_wrap" id="chat_wrap">
                    <div className="chat_inner" id="chreerScroll" style={{paddingBottom: "62px"}}>
                        <div className="scroll_bottom">
                        {
                            Array.isArray(msglogDrawlist) &&
                            <InfiniteScroll
                                dataLength={totalCnt}
                                next={GetAllSmsListNext}
                                hasMore={hasMore}
                                scrollThreshold={'2px'}
                                style={{ overflowX: 'hidden' }}>
                                { 
                                    msglogDrawlist.map((msgGrouploglst, gidx) => (
                                        <>
                                            <div className="msg_wrap">
                                                {/*
                                                <span className="msg_date">msgGrouploglst.title</span>
                                                */}
                                                <span className="msg_date">
                                                    {moment(msgGrouploglst.title, 'YYYY-MM-DD').format('MM월 DD일 (ddd)')}   
                                                </span>
                                            </div>
                                            {
                                                (msgGrouploglst.items !== null && msgGrouploglst.items.length > 0 ) ?
                                                msgGrouploglst.items.map((msglog, index) => (
                                                    <>
                                                        <div key={msglog.NO} className={ msglog.DST === targetNumber ? "msg_wrap right":"msg_wrap left"}>
                                                            <div className="msg">
                                                                <p className="comment pre_line" style={{textAlign:"left"}}>{msglog.MESSAGE}</p>
                                                                <span className="msg_time">{prettyMsgDetailLog(msglog.TIME)}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )) : 
                                                <></>
                                            }
                                        </>
                                    )) 
                                }
                            </InfiniteScroll>
                        }
                        </div>
                    </div>
                    <div className="chat_btm">
                        <div className="chat_input">
                            <div className="textarea_wrap">
                                <textarea rows="9" id="origin" className="textarea" placeholder="메시지를 입력하세요."></textarea>
                                <textarea id="dummy" className="dummy" disabled></textarea>
                            </div>
                        </div>
                        <span className="ic_chat_send usetap" onClick={(e)=> ClickSendSMS(e)}></span>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    </>
  );
});
export default MsgLogDetail;