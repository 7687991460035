import React, { useEffect, useState, useCallback } from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { floatUI } from '../../../../libs/floating'
import { tabUi } from '../../../../libs/tab'
import Group from "./group/Group";
import CallMemo from "./callmemo/CallMemo";
import PhoneBook from './phonebook/PhoneBook';

//전화 설정 페이지
    const PhoneBookMain = observer(({ history, match, }) => {
    // const [forwardType, setFowardType] = useEffect([]);
    // const [mohInfo, setMohInfo] = useEffect([]);
    const [callFowarding, SetCallFowarding] = useState(false);
    const [callLink, SetCallLink] = useState(false);
    const [callFowardingToggle, SetCallFowardingToggle] = useState(true);
    const [callLinkToggle, SetCallLinkToggle] = useState(true);
    const [phonebooktap, setPhonebooktap] = useState("phonebook");

    useEffect(() => {
        tabUi.init3DepthTab(document.getElementById('tab_style4'));
        tabUi.init3DepthTab(document.getElementById('floating_tab2'));
    }, []);



    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //console.log(userList);

    const callFowardSet = userInfo.dataset.callFoward;
    const callLinkSet = userInfo.dataset.callLink;

    const blackList = userInfo.dataset.blackList;
    const blackAndAnonymous = userInfo.dataset.blackAndAnonymous;
    const allBlock = userInfo.dataset.allBlock;


    //착신 전화 조회
    // useEffect(() => {
    //   CentrexApi.getForwardTypeInfo({
    //     id: "",
    //     pass: "",
    //   })
    //     .then((result) => {
    //       setFowardType(result.datas);
    //       console.log("getSetFowardType ===>", result.datas);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // });

    //현재 설정된 통화 연결음 조회 API
    // useEffect(() => {
    //   CentrexApi.getMohInfo({
    //     id: "",
    //     pass: "",
    //     peer_exten: "", //조회할 내선번호 -> 고객관리자만 변경가능하며, 자신의 내선번호 // 필수아님 옵션
    //   })
    //     .then((result) => {
    //       setMohInfo(result.datas);
    //       console.log("getMohInfo ===>", result.datas);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // });
    
    // ON/OFF 시 상태관리 함수
    const callFowardingToggleOnHandleClick = () => {
        SetCallFowardingToggle(!callFowardingToggle);
        //console.log(callFowardingToggle);
    }
    const callLinkToggleOnHandleClick = () => {
        SetCallLinkToggle(!callLinkToggle);
        //console.log(callLinkToggle);
    }

    //버튼식 시 상태관리 함수
    const callFowardingHandleClick = useCallback(() => { 
    // e.preventDefault()
    SetCallFowarding(prevState => !prevState);//함수형 업데이트
    //console.log(callFowarding)
    })
    const callLinkHandleClick = useCallback(() => { 
    // e.preventDefault()
    SetCallLink(prevState => !prevState);//함수형 업데이트
   // console.log(callLink)
    })

    // 여러개의 체크박스 중 하나만 관리
    // const inputChangeHandler = (checked, id) => {
    // if(checked) {
    //   setCheckedInputs([id]);
    // }else{
    //   // 체크 해제
    //   setCheckedInputs(checkedInputs.filter((el)=> el !== id));
    // }
    // };
    const PhoneBookClick = (currMenu=null) => {
        store.userData.setPhoneBookTabList(currMenu);
        history.replace(`${currMenu}`);
    }


    return (
        <>  
            <div className="call_content">
                { store.userData.dataset.phoneBookTap === 'phonebook' &&
                    <Route path="/shop/call/phonebook/phonebook" component={PhoneBook} />
                }
                { store.userData.dataset.phoneBookTap === 'group' &&
                    <Route path="/shop/call/phonebook/group" component={Group} />
                }
                { store.userData.dataset.phoneBookTap === 'callmemo' &&
                    <Route path="/shop/call/phonebook/callmemo" component={CallMemo}  />
                }
            </div>
            <div className="floating_tab_wrap">
                <ul id="floating_tab2">
                    <li><button type="button" className={"tab_item" + (store.userData.dataset.phoneBookTap === 'phonebook' ? " active" : "")} onClick={()=> PhoneBookClick('phonebook')}>연락처</button></li>
                    <li><button type="button" className={"tab_item" + (store.userData.dataset.phoneBookTap === 'group' ? " active" : "")} onClick={()=> PhoneBookClick('group')}>그룹</button></li>
                    <li><button type="button" className={"tab_item" + (store.userData.dataset.phoneBookTap === 'callmemo' ? " active" : "")} onClick={()=> PhoneBookClick('callmemo')}>통화메모</button></li>
                </ul>
            </div>
        </>
    );
    });
export default PhoneBookMain;