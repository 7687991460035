import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";

const RingRegiPopup = observer((props) => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //console.log(userList);

    const [name, setName] = useState("")

    useEffect(()=>{
        if(userData.dataset.myPageCall === "(부가)통화연결음") {
            setName("(부가)통화연결음")
        }
        if(userData.dataset.myPageCall === "(부가)우리매장알림메시지") {
            setName("(부가)우리매장알림메시지")
        }
        if(userData.dataset.myPageCall === "(부가)TTS 음원제작(정액형)") {
            setName("(부가)TTS 음원제작(정액형)")
        }
        if(userData.dataset.myPageCall === "(부가)TTS 음원제작(1회용)") {
            setName("(부가)TTS 음원제작(1회용)")
        }
        if(userData.dataset.myPageCall === "가입/문의") {
            setName("가입/문의")
        }
        if(userData.dataset.myPageCall ==="(부가)출입관리기") {
            setName("(부가)출입관리기")
        }
        if(userData.dataset.myPageCall ==="(부가)위생인증 CCTV"){
            setName("(부가)위생인증 CCTV")
        }        
        
        //console.log("aaaa",userData.dataset.myPageCall);
    },[userData.dataset.myPageCall])
 
    const click = () => {
        layerPopup.hide('#popup_ringregi', ()=> {
            NativeBridge.callPhone('1800-8000')
        })
    }


    return (
    <>            
        <div className="modal fadeIn" id="popup_ringregi">
            <div className="dim" onClick={()=>layerPopup.hide('#popup_ringregi')}></div>
            <div className="modal_inner">
                <div className="modal_content modal_ring_regi">
                    <div className="modal_bd">
                    <p>{name} 가입 안내</p>
                    <span className="i_num">1800-8000</span>
                    {/* <a href="tel:1522-3232" className="btn_ring_tel usetap">전화 걸기</a> */}
                    {/* <!-- a 태그의 tel 기능이 필요 없을 경우 버튼으로 대체 
                    <button type="button" className="btn_ring_tel usetap">전화 걸기</button>
                    --> */}
                    </div>
                    <div className="modal_ft">
                        <button  type="button" className="btn_half usetap" onClick={()=>layerPopup.hide('#popup_ringregi')}><span>닫기</span></button>
                        <button  type="button" className="btn_half usetap"  onClick={(e)=>click(e)}><span>전화걸기</span></button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
});
export default RingRegiPopup;