import React from "react";
import * as NativeBridge from "../../../utils/NativeBridge";


//전화 설정 페이지
function CallLogUnSub() {

  	const goPageOnClick = () => {
    	NativeBridge.externalCall("https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000060")
	}

	return (
		<>  
			<div className="fix_container">
				<div className="unsubs_wrap unsubs_tel">
					<div className="intro_sec">
						<span className="title">인터넷 전화</span>
						<p className="desc1">통화량에 맞는 요금제로<br/>실속있게 이용해보세요!</p>
						<p className="desc2">쓰던 번호 그대로, 다양한 부가 서비스까지</p>
						<span className="intro_ico"></span>
					</div>
					<div className="major_service">
						<span className="title">주요 서비스</span>
						<ul>
							<li className="service1">
								<span className="box_title">통화료 절감</span>
								<p>일반 유선전화보다 <br/>저렴한 기본료와 통화료</p>
								<span className="service_ico"></span>
							</li>
							<li className="service2">
								<span className="box_title">다양한 부가 서비스</span>
								<p>통화연결음, 착신전환, 녹취 등 <br/>효율적인 매장 관리 기능</p>
								<span className="service_ico"></span>
							</li>
							<li className="service3">
								<span className="box_title">안정적인 통화 환경</span>
								<p>서비스 안정성은 물론, 통화연결에 <br/>문제가 생겨도 지정된 번호로 연결 가능</p>
								<span className="service_ico"></span>
							</li>
						</ul>
					</div>
					<div className="user_review">
						<span className="title">먼저 사용 중인 사장님들 리뷰</span>
						<div className="review_box">
							<div className="review_wrap right">
								<div className="msg bg1">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">호랑이</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
									</div>
									<p>잠깐 자리를 비울 때 <br/>착신전환 해둘 수 있어서 좋아요!</p>
								</div>
							</div>
							<div className="review_wrap left">
								<div className="msg bg2">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">김사장</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
										<span className="ico_star"></span>
									</div>
									<p>앱에서 통화연결음 제작까지 쉽게 할 수 <br/>있어요~</p>
								</div>
							</div>
							<div className="review_wrap right">
								<div className="msg bg3">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">다람쥐</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
									</div>
									<p>단골 고객을 메모해두면 누군지 <br/>알 수 있어서 고객 관리하기 편해요!</p>
								</div>
							</div>
						</div>
					</div>
					<div className="info_payment">
						<span className="title">요금 안내</span>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">종량제</span>
								<p>통화료 단말기 요금 별도</p>
							</div>
							<div className="charge_box">
								{/* <!--<span className="i_discount">25%</span>--> */}
								<span className="i_charge"><span className="ft_roboto">3,300</span>원</span>
								<span className="i_unit">기본료</span>
							</div>
						</div>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">매장 안심형</span>
								<p>통화료 부가 서비스 일부 포함</p>
							</div>
							<div className="charge_box">
								{/* <!--<span className="i_discount">25%</span>--> */}
								<span className="i_charge"><span className="ft_roboto">5,500</span>원</span>
								<span className="i_unit">기본료</span>
							</div>
						</div>
						<ul className="desc_list">
							<li>3년 약정, 월 이용요금, 부가세 포함 기준</li>
						</ul>
					</div>
					<div className="btn_box fixed is_gnb">
						<div className="inner">
							<button type="button" onClick={goPageOnClick} className="btn_lg btn_point usetap">가입하기</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CallLogUnSub;
