import React from "react";
import { observer } from "mobx-react";

//통화기록, 연락처, 녹취목록 데이터 없을 때 화면
const ContactDataNone = observer(() => {
  return (
    <>  
      <div className="call_content">
        {/* <!-- 210912 ‘메뉴명’ 이/가 없습니다. --> */}
        <div className="data_none">
                    <div className="none_wrap">
                        <span className="none_img"></span>
                <p>통화 이력이 없습니다.</p>
            </div>
        </div>
      </div>
			{/* <!-- // 210912 ‘메뉴명’ 이/가 없습니다. --></div> */}
    </>
  );
});

export default ContactDataNone;
