import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import CentrexApi, {setCenterexUserIdPass} from '../../api/CentrexApi';
import CctvApi, {setCctvToken} from '../../api/CctvApi';
import BizApi from '../../api/BizApi';
// import {setCentrexSsoKey} from '../../api/CentrexApi';
import { layerPopup, toast } from '../../libs/common';
import * as common from '../../common/common';
import { setUserDataStoreList, checkAgreeRegInfo, checkFamilyInviteInfo, clearLocalData } from './DefaultSet'
import * as CONSTS from '../../const';

const BioCheck = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    useEffect(() => {       
        NativeBridge.sendUserActionLog('first', 'FL');
        const subscription = NativeBridge.eventObservable.subscribe(event => {

            switch (event.type) {                    

                case 'CALL_BIOMETRICS':
                    const data = event.param;
                    console.log("CALL_DEVICE_EVENT :: kmccallback :: event.param.detail : ", event.param);
                    if(data.status === "0") {
                        console.log("성공");
                        userInfo.setBioLoginType(data.type);
                        
                        // 토큰 발급
                        const deviceInfo = LocalStore.getDeviceInfo();
                        SohoApi.getAuthToken({
                            //"userId" : LocalStore.getOwnerId() ? LocalStore.getOwnerId() : LocalStore.getUserId()
                            "clientIp" : deviceInfo.ip_addr
                            ,"devInfo" : "PHONE"				
                            ,"osInfo" : deviceInfo.os_type ? deviceInfo.os_type : "unknown_os"
                            ,"nwInfo" : deviceInfo.network				
                            ,"devModel" : deviceInfo.dev_model ? deviceInfo.dev_model : "unknown_dev"
                            ,"carrierType": deviceInfo.carrier_type
                        }).then((sohoAuthInfo)=> {
                            //const sohoToken = sohoAuthInfo.data.token;
                            // setSohoToken(sohoToken);
                            //LocalStore.setSohoToken(sohoToken);

                            if(sohoAuthInfo.retCode !== '0000') {
                                const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===sohoAuthInfo.retCode);
                                if(typeof(errCodeData) === "undefined") {
                                    layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "인증실패", [
                                        function() {
                                            layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                                        }
                                    ]);
                                }
                                return;
                            }		

                            SessionStore.setLoginSession(LocalStore.getUserId());
                            userInfo.setLogin();


                            
                            goNextStep();

                        }).catch((e) => {
                            //timeout 처리
                            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "인증실패하였습니다.<br />재시도해주세요."
                                , [
                                function() {
                                    clearLocalData();
                                    layerPopup.hide("#popup_onetitlemsgbutton", ()=>{
                                        history.replace("/");
                                    });
                                }
                            ]);
                        });


                        // LocalStore.setBioLoginType(event.param.detail.type);

                        // // 정보 동의 체크
                        // const termsAgreeInfo = LocalStore.getTermsAgree();

                        // //-- check.11  동의 필수값 체크
                        // SohoApi.getTermsAgreeList({userId: LocalStore.getUserId()}).then((result) => {
                        //     const agreeList = result.data.list;
                        //     console.log("agreeList", agreeList);
                        //     if(agreeList.length) {
                        //         let invalidList = agreeList.reduce((prev, cur) => {
                        //             if(cur.estlYn === "Y" && cur.prvsAgreeYn !== "Y") {
                        //                 prev.push(cur);
                        //             }
                        //             return prev;
                        //         }, []);
                        //         if(invalidList.length > 0 ) {
                        //             history.replace("/agree/reg");
                        //             return;
                        //         }
                        //     }

                        //     // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
                        //     const ownerId = LocalStore.getOwnerId();
                        //     if(common.isNullData(ownerId)) {
                        //         history.replace("/account/owner/select");
                        //         return;
                        //     }

                        //     history.replace("/home");

                        // });


                    // // 실패, 취소
                    // } else if(data.status === "1") {
                    //     history.replace("/pincode/check");
                    //     return;

                    // 미등록, 미지원인경우 기존 등록된 바이오세팅을 제거한다.
                    } else if(data.status === "2" || data.status === "9" || data.status === "3") {

                        if(data.status === "2") {
                            toast("기기에서 지문을 등록해주세요.");
                        } else if(data.status === "9") {
                            toast("생체인증 미지원단말입니다.");
                        } else if(data.status === "3") {
                            toast("생체인증 정보가 변경되었습니다.");
                        }

                        // LocalStore.removeBioLoginType();
                        userInfo.setBioLoginType(null);
                        history.replace("/pincode/check");
                        return;

                    } else {

                        toast("인증에 실패했습니다.");
                        history.replace("/pincode/check");
                        return;
                        
                        // const bioType = LocalStore.getBioLoginType();
                        // NativeBridge.checkBiometrics(bioType);
               
                    }

                break;

            default:
                break;
            }
        });



        let bioType = LocalStore.getBioLoginType();
        if(bioType === null || bioType === "") {
            bioType = 0;
        }

        NativeBridge.checkBiometrics(bioType);

        return () => {
            subscription.unsubscribe();
          };

    }, []);

    const goNextStep = async () => {

        // -- 임시 사용자
        if(LocalStore.getIsTempUser()===true) {

            // 로컬스토리지에 id,pw 가 세팅되어 있는지 확인한다.
            const cctvId = LocalStore.getCctvTempUserId();
            const cctvPw = LocalStore.getCctvTempUserPwd();
            if(cctvId && cctvPw) {
                userInfo.setCctvTempUserId(cctvId);
                const cctvAuthInfo = await CctvApi.getAuth({
                    userId : cctvId,
                    userPwd : cctvPw
                }).catch((e)=>{
                    console.log(e);
                });
                console.log('cctvAuthInfo', cctvAuthInfo)
                if(typeof(cctvAuthInfo)==="undefined" || cctvAuthInfo.data.resCode !== CONSTS.CCTV_RES_CODE_SUCCESS) {
                    layerPopup.showCommon("#popup_cctv_auth_error", null, "알림", "CCTV 로그인에 실패하였습니다.<br /> U+지능형 CCTV에 등록된 휴대폰번호를 업데이트하세요.", [null]);                        
                }                
            }

            // 임시사용자는 로컬스토리지의 id/pw로 센트릭스 로그인
            if(LocalStore.getStoreInetNo() && LocalStore.getTempInetPass()) {

                userData.setStoreInetNo(LocalStore.getStoreInetNo());
                setCenterexUserIdPass(LocalStore.getStoreInetNo() , LocalStore.getTempInetPass());                    

                const retCentrixUserInfo = await CentrexApi.getUserInfo({
                    id: LocalStore.getStoreInetNo(),
                    pass: LocalStore.getTempInetPass()
                }, false).catch(e=>{
                    console.log(e);
                });
                if ( typeof(retCentrixUserInfo) === "undefined" || typeof(retCentrixUserInfo.data) === "undefined" || retCentrixUserInfo.data.SVC_RT !== CONSTS.CENTRIX_SVC_RT_SUCCESS ){
                    layerPopup.showCommon("#popup_centrex_auth_error", null, '', '', [null]);
                }
            }

            await BizApi.makeMyService();
            history.replace("/home");
            return;
        }  //--- 임시사용자는 여기까지

        // -- 이하 일반 사용자
        /* setUserDataStoreList 내부에서 하므로 주석
        try {
            const cctvAuthInfo = await SohoApi.getCctvAuth();
            // if(cctvAuthInfo.data.resCode !== "0000") {
            //     layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "CCTV 인증실패", [
            //         function() {
            //             layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=>{
            //                 if(userData.dataset.cctvauthFlag === false) {
            //                     await SohoApi.getCctvAuth();
            //                 }
            //                 userData.setCctvAuthFlag(true)
            //             });
            //         }
            //     ]);
            //     return;
            // }
        } catch(e) {
            console.log(e);
        }
        */
        
        // 센트릭스 인증키 발급
        /*
        try {
            if(LocalStore.getStoreInetNo()) {
                const centrexAuthInfo = await SohoApi.getCentrexAuth({"storeInetNo":LocalStore.getStoreInetNo()});
            }
        } catch(e) {
            console.log(e);
        }
        */

        // 약관동의 확인
        if(await checkAgreeRegInfo()===true) {
            history.replace("/agree/reg");
            return;
        }        
        
        // 초대계정 확인
        if(await checkFamilyInviteInfo()===true) {
            history.replace("/account/family/reg");
            return;
        }        

        if(LocalStore.getOwnerId()===null) {
            history.replace("/account/owner/select");
            return;
        }
        if(LocalStore.getStoreId()===null || LocalStore.getStoreList().length === 0) {
            history.replace("/account/store/reg");
            return;
        }

        await setUserDataStoreList();
        history.replace("/home");         

    }

    return (
        <>
            
        </>
    )
    
});
export default BioCheck;