import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";

const SelectCallRing = observer(({history}) => {


	

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);

    // const MoreClick = (e) => {
        
    //     document.getElementById('ft_opt_more').classList.toggle('show');
    //     $('.btn_contact_more.usetap').toggleClass('on');
    // }
	useEffect(()=>{
		console.log("디폴트체크.", userInfo.dataset.defaultCheck)
		
	},[userInfo.dataset.defaultCheck])
    
	const callRingPopupOnClick = (event) => {
        NativeBridge.sendUserActionLog('detail', 'D036');
		layerPopup.hide("#popup_selectcall", ()=>{
			layerPopup.show('#popup_callring')
		});
		event.preventDefault();
		//layerPopup.hide('#popup_selectcall')
		//document.getElementById("popup_selectcall").style.display = "none";

	}

	const pageMove = (e) => {
		if(e.currentTarget.name === "1"){
			layerPopup.hide('#popup_selectcall', () => {
				history.push("/shop/call/callset/callring/alwayscall")
			})
			NativeBridge.sendUserActionLog('detail', 'D037');
		}
		if(e.currentTarget.name === "2"){
			layerPopup.hide('#popup_selectcall', () => {
				history.push("/shop/call/callset/callring/bydaycall")
			})
			NativeBridge.sendUserActionLog('detail', 'D038');
		}
		if(e.currentTarget.name === "3"){
			layerPopup.hide('#popup_selectcall', () => {
				history.push("/shop/call/callset/callring/timecall")
			})
			NativeBridge.sendUserActionLog('detail', 'D039');
		}
		
		e.preventDefault()

	}

    return (
        <>
	<div className="modal fadeInUp" id="popup_selectcall">
    <div className="dim" onClick={()=>layerPopup.hide('#popup_selectcall')}></div>
    <div className="modal_inner modal_up">
        <div className="modal_ringback_sel2">
            <span className="modal_title">통화연결음 선택</span>
            <p>통화연결음 종류를 선택해주세요.</p>
			{userInfo.dataset.defaultCheck === '1' && LocalStore.getIsTempUser() === false
			? 
			<>
				<a href="#" className="btn_ringback usetap" onClick={(e)=>callRingPopupOnClick(e)}>
					<span className="title">간편 통화연결음</span>
					<p className="desc">쉬는시간/영업종료 등 상황에 맞게 설정할 수 있어요.</p>
				</a>
			
			</> 
			: 
			<></>  
			}
          		<a href="#" className="btn_ringback usetap"  name="1"  onClick={(e) => {pageMove(e)}}>
					<span className="title">기본 통화연결음</span>
					<p className="desc">24시간 같은 통화연결음을 설정할 수 있어요.</p>
				</a>
				<a href="#" className="btn_ringback usetap" name="2"  onClick={(e) => {pageMove(e)}}>
					<span className="title">요일별 통화연결음</span>
				    <p className="desc">요일별로 다르게 설정할 수 있어요.</p>
				</a>
				<a href="#" className="btn_ringback usetap" name="3"  onClick={(e) => {pageMove(e)}}>
					<span className="title">시간대별 통화연결음</span>
				    <p className="desc">시간대별로 다르게 설정할 수 있어요.</p>
				</a>
        </div>
    </div>
</div>


{/* 
<div className="modal fadeIn" id="popup_selectcall"> 
	<div className="dim"></div>
	<div className="modal_inner">
		<div className="modal_content modal_ringback_sel">
			<div className="modal_hd">통화연결음 선택</div>
			<div className="modal_bd">
				<p>통화연결음 종류를 선택해주세요</p>
				
				{userInfo.dataset.defaultCheck !== '1' && LocalStore.getIsTempUser() === false
				? 
				<Link to="#" className="btn_ringback usetap" onClick={()=>callRingPopupOnClick()}>
					<span className="title">간편 통화연결음</span>
				<p className="desc">쉬는시간, 영업종료 등 상황에 맞게 설정할 수 있어요.</p>
				</Link> 
				: 
				<></>  }
				
				<Link to="/shop/call/callset/callring/alwayscall" className="btn_ringback usetap"  onClick={()=>layerPopup.hide('#popup_selectcall')}>
				<span className="title">기본 통화연결음</span>
				    <p className="desc">24시간 같은 통화연결음을 설정할 수 있어요.</p>

				</Link>
				<Link to="/shop/call/callset/callring/bydaycall" className="btn_ringback usetap"  onClick={()=>layerPopup.hide('#popup_selectcall')}>
				<span className="title">요일별 통화연결음</span>
				    <p className="desc">요일별로 다르게 설정할 수 있어요.</p>
				</Link>
				<Link to="/shop/call/callset/callring/timecall" className="btn_ringback usetap"  onClick={()=>layerPopup.hide('#popup_selectcall')}>
				<span className="title">시간대별 통화연결음</span>
				    <p className="desc">시간대 별로 다르게 설정할 수 있어요.</p>

				</Link>
			</div>
			<div className="modal_ft">
				<button type="button" className="btn usetap" onClick={()=>layerPopup.hide('#popup_selectcall')}><span>닫기</span></button>
			</div>
		</div>
	</div>
</div> */}



        </>
    );
});
export default SelectCallRing;
