import React from 'react'
import * as NativeBridge from '../../../utils/NativeBridge'

function CCTVUnSub() {

    const goPageOnClick = () => {
        NativeBridge.externalCall("https://sohoshop.uplus.co.kr/soho/v1/cctv")
    }

    return (
    	<>
			<div className="fix_container">
				<div className="unsubs_wrap unsubs_cctv">
					<div className="intro_sec">
						<span className="title">지능형 CCTV</span>
						<p className="desc1">언제 어디서나 <br/>매장을 안전하게 지켜보세요!</p>
						<p className="desc2">고화질 카메라부터 이상 상황 자동 알림까지</p>
						<span className="intro_ico"></span>
					</div>
					<div className="major_service">
						<span className="title">주요 서비스</span>
						<ul>
							<li className="service1">
								<span className="box_title">간편한 영상 모니터링 프로그램</span>
								<p>최대 147개 영상<br/>동시 모니터링 제공</p>
								<span className="service_ico"></span>
							</li>
							<li className="service2">
								<span className="box_title">안전한 매장 위한 영상 분석 기능</span>
								<p>침입, 카메라 훼손 감지 시 <br/>앱에서 자동 알림</p>
								<span className="service_ico"></span>
							</li>
							<li className="service3">
								<span className="box_title">특수 카메라 기능</span>
								<p>360도 상하좌우 화각 조절 <br/>광학 4 - 5배 줌 가능</p>
								<span className="service_ico"></span>
							</li>
							<li className="service4">
								<span className="box_title">매장 안전을 위한 출동 서비스</span>
								<p>침입, 카메라 훼손 감지 시 <br/>에스원 무료 출동 월 2회</p>
								<span className="service_ico"></span>
							</li>
						</ul>
					</div>
					<div className="user_review">
						<span className="title">먼저 사용 중인 사장님들 리뷰</span>
						<div className="review_box">
							<div className="review_wrap right">
								<div className="msg bg1">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">호랑이</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
									</div>
									<p>출동보안까지 저렴하게 이용하고 있어요</p>
								</div>
							</div>
							<div className="review_wrap left">
								<div className="msg bg2">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">김사장</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
										<span className="ico_star"></span>
									</div>
									<p>내가 보고싶은 곳을 언제 어디서나<br/>확인할 수 있어요!</p>
								</div>
							</div>
							<div className="review_wrap right">
								<div className="msg bg3">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">다람쥐</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
									</div>
									<p>여러 지점을 동시에 확인 할 수 있어서, <br/>여러 매장 관리가 너무 편해요!</p>
								</div>
							</div>
						</div>
					</div>
					<div className="info_payment">
						<span className="title">요금 안내</span>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">일반형</span>
							</div>
							<div className="charge_box">
								<span className="i_discount">25%</span>
								<span className="i_charge"><span className="ft_roboto">11,000</span>원</span>
								<span className="i_unit">1대당</span>
							</div>
						</div>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">매장 안심형</span>
							</div>
							<div className="charge_box">
								<span className="i_charge"><span className="ft_roboto">13,200</span>원</span>
								<span className="i_unit">1대당</span>
							</div>
						</div>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">제휴형</span>
							</div>
							<div className="charge_box">
								<ul>
									<li>
										<span className="i_charge"><span className="ft_roboto">24,200</span>원</span>
										<span className="i_unit">1대</span>
									</li>
									<li>
										<span className="i_discount">25%</span>
										<span className="i_charge"><span className="ft_roboto">36,300</span>원</span>
										<span className="i_unit">2대</span>
									</li>
									<li>
										<span className="i_discount">25%</span>
										<span className="i_charge"><span className="ft_roboto">47,300</span>원</span>
										<span className="i_unit">3대</span>
									</li>
								</ul>
							</div>
						</div>
						<ul className="desc_list">
							<li>3년 약정, 월 이용요금, 부가세 포함 기준</li>
							<li>매장안심형은 PTZ/가변초점 카메라 요금이며, <br/>일반카메라 추가 시 일반형 요금제로 합산됩니다.</li>
						</ul>
					</div>
					<div className="btn_box fixed is_gnb">
						<div className="inner">
							<button type="button"onClick={()=>goPageOnClick()} className="btn_lg btn_point usetap">가입하기</button>
						</div>
					</div>
				</div>
			</div>
    	</>
    );
};

export default CCTVUnSub