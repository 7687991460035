import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import store from '../../../store';
import $ from "jquery";
import { tabUi } from '../../../libs/tab';
import { layerPopup } from "../../../libs/common";
import { getAddressListForPhoneByGroup, getAddressInfo, getGroup , transformAddressDrawData } from "../../../common/common";


const MsgPhonebook = observer(({ match }) => {

    const { userInfo, userData } = store;
    //popup 관련
	const [popSearchWords, setPopSearchWords] = useState(""); 
	const [popPbList, setPopPbList] = useState([]);
	const [popSrchDrawData, setPopSrchDrawData] = useState([]); 
    const [popDrawGroupData, setPopDrawGroupData] = useState([]); 
	//const [popPbType, setPopPbType] = useState(CONSTS.ADDRESS_TYPE_CENTRIX);
	const [popAddPbList, setPopAddPbList] = useState([]);

    useEffect(() => {
        tabUi.init3DepthTab(document.getElementById('tab_style4'));
        //$("#tab_phonebook").trigger("focus");

    }, [])

    useEffect(() => {

        console.log("change MsgPhonebook.js userData.dataset.sendMsgAddrPopupToggle =>", userData.dataset.sendMsgAddrPopupToggle);
        setPopSearchWords('');
        $('#idx_input_name_number2').val('');
        if ( userData.dataset.sendMsgAddrPopupToggle){
            $('input[name="msgContactAdd"]').prop('checked', false);
            document.getElementById('chk_sel_num').innerText = 0;

            //주소정보 조회
            GetAddressList();
            
        }

        /*
        setTimeout(() => {
            $("#idx_input_name_number").trigger("click");
        }, 1000);
        */

    }, [userData.dataset.sendMsgAddrPopupToggle])

    const SelectAllClick = (e) => {
        const checkboxes = document.getElementsByName('msgContactAdd');

        checkboxes.forEach((checkbox) => {
          checkbox.checked = e.checked
        })

        const query = 'input[name="msgContactAdd"]:checked';
        const selectedElements = document.querySelectorAll(query);
        const selectedElementsCnt = selectedElements.length;
        document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
    }

    const PhonebookGroupTabClick = (subMenu= null) => {
        userData.setSendMsgAddrPopupToggle(true);
        store.userData.setMsgPhonebookGroupList(subMenu);
        if ( subMenu === 'phonebook') {
            GetAddressList();
        }else if ( subMenu === 'group') {
            userData.setSendMsgGroupPopupToogle(true);
        }
    }

    //---------------------------------------[ Phonebook ] --------------------------
    const GetAddressList = () => {
		//setPopSrchDrawData("");		//검색어 초기화
        console.log('saved userData.dataset.pblistForCentrix=>' , userData.dataset.pblistForCentrix );
        setPopPbList(userData.dataset.pblistForCentrix);
	}

    const ChangeSearchWords = (e) => {
		setPopSearchWords(e.target.value);
	}

    useEffect(() => {
		console.log("change popPbList, popSearchWords", popPbList);
		if ( popPbList !== null && popPbList.length > 0 ){
			setPopSrchDrawData(filterPbData(popPbList, popSearchWords));
            //setPopDrawGroupData(transformAddressDrawData(filterPbData(popPbList, popSearchWords)))
        }

	}, [popPbList, popSearchWords]);

    useEffect(() => {
		console.log("change popSrchDrawData", popSrchDrawData);
		if ( popSrchDrawData !== null && popSrchDrawData.length > 0 ){
			setPopDrawGroupData(transformAddressDrawData(popSrchDrawData));
		}else{
            setPopDrawGroupData([]);
        }

        setTimeout( () => {
            $('input[name="msgContactAdd"]').prop('checked', false);
            RefreshClickedCount();
        }, 800);

	}, [popSrchDrawData]);

    const filterPbData = (_list, _words) => {
		return popPbList.filter((targetItem) => {
			if ( _words === '' || (targetItem.name)?.includes(_words) || (targetItem.phone)?.includes(_words) ){
				return targetItem;
			}	
		});
	}

    const ClickItemSelect = (e, _item) => {
		const checkboxes = document.querySelectorAll('input[name="msgContactAdd"]');
		const checked = document.querySelectorAll('input[name="msgContactAdd"]:checked');

		RefreshClickedCount();
	}

    useEffect(() => {
        //userData lamda Array.from.concat 확인필요
		console.log("change popAddPbList", popAddPbList);
		userData.setPopSelectedSendMsgPhone(popAddPbList);

	}, [popAddPbList]);

    const RefreshClickedCount = () => {
		const query = 'input[name="msgContactAdd"]:checked';
        const selectedElements = document.querySelectorAll(query);
        const checkboxes = document.querySelectorAll('input[name="msgContactAdd"]');
        // 선택된 목록의 갯수 세기
        const selectedElementsCnt = selectedElements.length;
        // 출력
        document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
        document.getElementById('totalCntPb').innerText = checkboxes.length + " 명";
	}

    const ClickPopupReset2 = (e) => {
        console.log(">>>ClickPopupReset2");
        $('#idx_input_name_number2').val('');
        setPopSearchWords('');
    }

    const ClickRegContMsg = async (e) => {

        //개인정보전달하기
        const checkedPhone = document.querySelectorAll('input[name="msgContactAdd"]:checked');
        if ( !Array.isArray(checkedPhone) && Array.from(checkedPhone).length > 0 ){
            let tmplist = [];
            Array.from(checkedPhone).map((_roofItem, _idx) => {
                const _addrInfo = getAddressInfo($(_roofItem).attr('phone'), $(_roofItem).attr('no'));
                tmplist.push({
                    type : 'P'
                    ,idx : ( 'P' + _addrInfo.no + '_' + _addrInfo.phone )
                    ,name : _addrInfo.name
                    ,items : [
                        _addrInfo.phone
                    ]
                });
            });

            //개인정보전달하기
            //userData.dataset.popSelectedSendMsgPhone
            userData.setPopSelectedSendMsgPhone(tmplist);
            //userData.setPopSelectAddressAndPhone(false);
        }

        //그룹전화번호 전달하기
        //userData.dataset.popSelectedSendMsgGroup
        const checkedGroup = document.querySelectorAll('input[name="msgGroupAdd"]:checked');
        if ( checkedPhone !== undefined && checkedPhone !== null && Array.from(checkedGroup).length > 0 ){

            let tmplist = [];
            Array.from(checkedGroup).map((_roofItem, _idx) => {
                let _groupCode = $(_roofItem).attr('groupcode');
                let _groupName = $(_roofItem).attr('groupname');
                let _getAddrList = getAddressListForPhoneByGroup(_groupCode);

                tmplist.push({
                    type : 'G'
                    ,idx : ( 'G' + _groupCode )
                    ,name : _groupName
                    ,items : _getAddrList
                });

                userData.setPopSelectedSendMsgGroup(tmplist);
            });

        }

        //데이터 전송 flag
        userData.setSendMsgAddrPopupToggle(false);      //다음팝업 열리게 하기 위해
        layerPopup.hide('#popup_msgcontactgroup');
    };

    const ClickCancel = (e) => {
        userData.setSendMsgAddrPopupToggle(false);      //다음팝업 열리게 하기 위해
        layerPopup.hide('#popup_msgcontactgroup')
    }

    return (
        <>
        <div className="modal_inner modal_up max_h">
            <div className="modal_group_add">
                { /* 컨텐츠 */ }
                <div className="chk_wrap">
                    <input type="checkbox" id="contactGroupAll2" name="contactGroupAll" onClick={(e)=>SelectAllClick(e.currentTarget)} />
                    <label htmlFor="contactGroupAll2" className="btn_all_sel">모두선택</label>
                </div>
                <span className="info_chk_sel"><span id="chk_sel_num" className="checknum">0</span>개 선택</span>
                    <div id="tab_style4" className="tab_style4 tab2">
                        <button className="tab_item phonebook active" id="tab_phonebook" onClick={()=> PhonebookGroupTabClick('phonebook')}>연락처</button>
                        <button className="tab_item group" id="tab_group" onClick={()=> PhonebookGroupTabClick('group')}>그룹</button>
                    </div>
                <div className="input_box style1">
                    <input type="text" id="idx_input_name_number2" placeholder="이름, 전화번호를 검색해보세요." onKeyUp={(e)=> ChangeSearchWords(e)}/>
                    <span className="ip_clear hidden usetap test2" onClick={(e)=>ClickPopupReset2(e)}>초기화</span>
                    <span className="ico_srh"></span>
                </div>
                <div className="top_info">
                    <span className="top_title">주소록</span>
                    <span className="total_num" id="totalCntPb">{userData.dataset.pblistForCentrix.length}명</span>
                </div>
                <div className="contact_list_wrap">
                    { 
                        popDrawGroupData !== null && popDrawGroupData.length > 0 ?
                        popDrawGroupData.map((groupItem, gidx) => (
                            <>
                                <span className="devide_bar row">{groupItem.title}</span>
                                {
                                    (groupItem.items.length !== null || groupItem.items !== undefined ) ?
                                    groupItem.items.map((addressItem, index) => (
                                        <>
                                            <div id={gidx * 1000 +index} className="checkbox_green">
                                                <input type="checkbox" id={"contactGroupAdd_" + (gidx * 1000 + index)} name="msgContactAdd" 
                                                        {...{booktype: addressItem.bookType
                                                            ,drawgroup: addressItem.drawgroup
                                                            ,etc: addressItem.etc
                                                            ,groupcode: addressItem.groupCode
                                                            ,groupname: addressItem.groupName
                                                            ,addressname: addressItem.name
                                                            ,no: addressItem.no
                                                            ,phone: addressItem.phone
                                                            ,phone2: addressItem.phone2
                                                        }}
                                                    onClick={(e)=>ClickItemSelect(e, addressItem)} />
                                                <label htmlFor={"contactGroupAdd_" + (gidx * 1000 + index)} className="usetap">
                                                    {addressItem.name}
                                                </label>
                                            </div>
                                        </>
                                    )) : 
                                    <></>
                                }
                            </>
                        )) :
                        (
                        <>
                            <div className="data_none">
                                <div className="none_wrap">
                                    <span className="none_img"></span>
                                    <p>검색 결과가 없습니다.</p>
                                </div>
                            </div> 
                        </>
                        )
                    }
                </div>
                { /* 컨텐츠 */ }
            </div>
        </div>
        <div className="btn_box fixed">
            <div className="inner">
                <button type="button" className="btn_half btn_line2 usetap" onClick={(e)=>ClickCancel(e)}>취소</button>
                <button type="button" className="btn_half btn_point usetap" onClick={(e)=>ClickRegContMsg(e)}>추가</button>
            </div>
        </div>
        </>
    )
});

export default MsgPhonebook;
