import 'moment/locale/ko';
import moment from "moment";
import store from "../store";
import { toast } from '../libs/common';
// import * as SessionStore from '../store/SessionStore'
import * as NativeBridge from '../utils/NativeBridge';
import * as CONSTS from '../const';
import { trim } from 'jquery';

const { userData } = store;

export const getOSType = () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) === null ? 'Android': 'iOS';
};

export const isIOS = () => {
    return getOSType() === 'iOS';
}

export const isAndroid = () => {
    return getOSType() !== 'iOS';
}

export function isNullData(data){
	if(data === "" || data === null || data === undefined || data === 'null' || data === 'undefined' ||(typeof data === 'object' && Object.keys(data).length === 0)) return true;
	return false;
}

export function isDataCheck(data){
	if(data === "" || data === null || data === undefined || data === 'null' || data === 'undefined' || data.length === 0) return false;
	return true;
}

export const isPC = () => {
    var pcDevice = "win16|win32|win64|mac|macintel";
    if ( navigator.platform ) {
        if ( pcDevice.indexOf(navigator.platform.toLowerCase()) < 0 ) {
            return false;
        } else {
            return true;
        }
    }
}

export const parsePathname = (pathStr) => {
    console.log("--------parsePathname------ pathStr : ", pathStr);
    if(pathStr.indexOf('=') < 0)
        return null;;
    let newPath = pathStr.replace(/^\?/,'');
    let newPathArray = newPath.split('&');
    let resultObj = {};
    for(let i = 0; i < newPathArray.length; i++ ) {
        let currData = newPathArray[i].split('=');
        resultObj[currData[0]] = currData[1];
    }
    return resultObj;
};

export const isLogin = (userId, userPinCode) => {
    if(isNullData(userId) || isNullData(userPinCode)) {
        return false;
    } else {
        return true;
    }
}

export const sortObjectList = (objectList, sortKey, sortType="ASC") => {
    const sortedList = objectList.sort(function (a, b) {
        let x = a[sortKey];
        let y = b[sortKey];
        if(sortType === "ASC") {
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
        } else {
            if (x < y) {
                return 1;
            }
            if (x > y) {
                return -1;
            }
        }
        return 0;
    });
    return sortedList;
}

export const sortHanFirstObjectList  =  (objectList, sortKey, sortType="ASC") => {

    /**
     * ㄱㄴㄷ : 한글
      #: 한글자음 > 영어 > 숫자 >기타(특수문자) 입니다.
      
     * @param {*} s
     * @returns 
     */
    if(sortType === "ASC"){
        objectList = sortObjectList(objectList, sortKey, sortType='ASC')
    }
    else{
        objectList = sortObjectList(objectList, sortKey, sortType='DESC')
    }   

      
    const addOrderPrefix = (s) => {
        var code = s.toLowerCase().charCodeAt(0);
        var prefix;
    
        // 한글 AC00—D7AF
        if (0xac00 <= code && code <= 0xd7af) prefix = 1000000;
        // 한글 자모 3130—318F
        else if (0x3130 <= code && code <= 0x318f) prefix = 2000000;
        // 영어 소문자 0061-007A
        else if (0x61 <= code && code <= 0x7a) prefix = 3000000;
        // 숫자
        else if (48 <= code && code <= 57) prefix = 4000000;
        // 그외
        else prefix = 9000000;
    
        return prefix + code;
    }

    const sortedList = objectList.sort(function (a, b) {
        let x = addOrderPrefix(a[sortKey]);
        let y = addOrderPrefix(b[sortKey]);

        if(sortType === "ASC") {
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
        } else {
            if (x < y) {
                return 1;
            }
            if (x > y) {
                return -1;
            }
        }
        return 0;
    });
  
    return sortedList;
}

/**
 * ㄱㄴㄷ : 한글
     #: 한글자음 > 영어 > 숫자 >기타(특수문자) 입니다.
    
* @param {*} s
* @returns 
*/
export const sortHanWordsList  =  (objectList, sortKey, sortType="ASC") => {
      
    const addOrderPrefix2 = (s, n) => {
        //var code = s.toLowerCase().charCodeAt(n);
        var code = s.charCodeAt(n);
        var prefix;
        
        // 한글 AC00—D7AF
        if (0xac00 <= code && code <= 0xd7af) prefix = 1000000;
        // 한글 자모 3130—318F
        else if (0x3130 <= code && code <= 0x318f) prefix = 2000000;
        // 영어 소문자 0061-007A
        else if (0x61 <= code && code <= 0x7a) prefix = 3000000;
        // 숫자
        else if (48 <= code && code <= 57) prefix = 4000000;
        // 그외
        else prefix = 9000000;
        
        return prefix + code;
    
    }

    const sortedList = objectList.sort(function (_a, _b) {
        let changeFlag = false;
        let firstvalue = -2;
        let len = 0;
        let tmpCompareValue = 0;
        let tmpA = '';
        let tmpB = '';
        let targetA = _a[sortKey];
        let targetB = _b[sortKey];

        //console.log('sortHanWordsList [A:' + targetA + ']', '[B:' + targetB + ']');

        if ( tmpCompareValue === 0 ){
           
            do {

                tmpA = addOrderPrefix2(targetA, len);
                tmpB = addOrderPrefix2(targetB, len);

                //console.log('sortHanWordsList _a=> ' + len + ']', tmpA);
                //console.log('sortHanWordsList _b=> ' + len + ']', tmpB);

                //--------------------------------------------------------
                //compare
                //--------------------------------------------------------
                if ( tmpA > tmpB ){
                    tmpCompareValue = 1;
                } else if ( tmpB > tmpA ) {
                    tmpCompareValue = -1;
                } else { 
                    tmpCompareValue = 0;
                }

                if ( len === 0 ) firstvalue = tmpCompareValue;

                if ( firstvalue !== tmpCompareValue ) {
                    changeFlag = true;

                    if ( firstvalue !== 0 ){
                        tmpCompareValue = firstvalue;
                    }
                    //console.log('==>compare break =>', tmpCompareValue);

                    break;
                }

                //--------------------------------------------------------
                //길이체크
                //--------------------------------------------------------
                /*
                if ( _a.length <= ( len - 1 ) && _a.length < _b.length ){
                    tmpCompareValue = 1;
                }else if ( _b.length <= ( len - 1 )  && _a.length < _b.length ){
                    tmpCompareValue = -1;
                } else if ( _a.length === _b.length && _a.length === ( len - 1 )  ){
                    tmpCompareValue = 0;
                }

                firstvalue = tmpCompareValue;
                

                if ( firstvalue !== tmpCompareValue ){
                    changeFlag = true;

                    console.log('==>compare break');
                    break;
                }
                */
                if ( targetA.length <= ( len + 1 ) || targetB.length <= ( len + 1 ) || targetA.length === 1 || targetB.length === 1 ) {
                    //console.log('==> chceck length :', tmpCompareValue);
                    if ( tmpCompareValue === 0 ) {
                        if ( targetA.length > targetB.length ){
                            tmpCompareValue = 1;
                        }else if ( targetA.length < targetB.length ){
                            tmpCompareValue = -1;
                        }
                    }
                    changeFlag = true;
                    break;
                }

                len++;

                //console.log('==> roof  :', changeFlag);

            } while ( !changeFlag );
        }
        

        if ( sortType === 'DESC' )  tmpCompareValue = tmpCompareValue * -1;

        //console.log('==> break end :', tmpCompareValue);

        return tmpCompareValue;
        
    });
  
    return sortedList;
}

export const addHyphenPhoneNo = (phoneNum) => {
    return phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
}

/* 매장명 한글,알파벳,숫자,공백 */
export const checkStoreName = (storeNm) => {
    const reg_hanengnum = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]+$/;
    if (!reg_hanengnum.test(storeNm)) {
        return false;
    }
    return true;
}
export const checkStoreDetailNumber = (numberVal) => {
    const reg_num = /^[0-9|,]+$/;
    if (!reg_num.test(numberVal)) {
        return false;
    }
    return true;
}


/**
 * 한글, 영문, 숫자만 가능
 * @param {*} _text 
 * @returns 
 */
export const checkIMEEnabledType = (_text) => {
    const reg_hanengnum = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|\s]+$/;

    if ( _text === ''){
        return true;
    }else if (!reg_hanengnum.test(_text)) {
        return false;
    }
    return true;
}

/* true : AM / false : PM , dateStr형태 : yyyy-mm-dd HH:mi:ss*/
export const getAMPM = (dateStr) => {
    var hour = dateStr.substr(11,2);
    if (parseInt(hour) > 11){
        return false;
    }
    return true;
}

export const getTimes = (dateStr) => {
    if ( dateStr.length > 18){
        return dateStr.substr(11,8);
    }else{
        return dateStr;
    }
    
}

export const getDate = (dateStr) => {
    if ( dateStr.length > 10){
        return dateStr.substr(0,10);
    }else{
        return dateStr;
    }
}

const defaultFormatYMD = 'YYYY-MM-DD';
// const defaultFormatYMDHIS = 'YYYY-MM-DD hh:mm:ss';

const PRETTY_DATETYPE_MSGLOG = "MSGLOG";
const PRETTY_DATETYPE_MSGDETAILLOG = "MSGDETAILLOG";
/**
 * 메시지 수발신용 날짜표기
 * @param {*} _srcDate 
 * @param {*} _srcFormat 
 * @returns 
 */
export const prettyMsgLog = ( _srcDate, _srcFormat ) => {
    return getPrettyDate(PRETTY_DATETYPE_MSGLOG, _srcDate, _srcFormat)
}

export const prettyMsgDetailLog = ( _srcDate, _srcFormat ) => {
    return getPrettyDate(PRETTY_DATETYPE_MSGLOG, _srcDate, _srcFormat)
}

/**
 * 서비스내 날짜 표기법 정의
 * @param {*} _type 
 * @param {*} _srcDate 
 * @param {*} _srcFormat 
 */
export const getPrettyDate = (_type, _srcDate, _srcFormat) => {
    let srcData = moment(_srcDate, _srcFormat);
    //console.log("222", srcData);
    if ( _type === PRETTY_DATETYPE_MSGLOG) {
        //if ( srcData.isSame(new Date(), "day")){
        if ( moment().diff(srcData) > 0 ){
            return srcData.format('LT');
        }else{
            return srcData.format("YYYY-MM-DD HH:mm:ss");
        }
    } else if ( _type === PRETTY_DATETYPE_MSGDETAILLOG){
        return srcData.format('LT');
    } else {
        return moment(_srcDate, _srcFormat);
    }
}

export const getDateMont = (_date) => {
    return getDateMontAllType(defaultFormatYMD, _date, defaultFormatYMD);
}

export const getDateMontTwoWay = (_date, _format) => {
    return getDateMontAllType(defaultFormatYMD, _date, _format);
}

export const getDateMontAllType = (_srcFormat, _date, _destFormat) => {

    if ( _destFormat === null ){
        _destFormat = defaultFormatYMD;
    }

    if ( _srcFormat === null || _srcFormat === '' ){
        _srcFormat = defaultFormatYMD
    }

    if ( _date === null ){
        return moment().format(_destFormat);
    }else{
        return moment(_date, _srcFormat).format(_destFormat);
    }
}

export const getPrevMonth = () => {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = date.getMonth();
    
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + "-" + month + "-" + day + " " + hour + ":" + minites + ":" + seconds;
}

export const getCurrentDate = () => {
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + month + day + hour + minites + seconds + "00";
}

export const getSimpleDate = () => {
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    return year + month + day;
}

/*
    < 연락처 구조 >
    etc : item.etc
    ,groupCode: item.groupCode
    ,groupName: item.groupName
    ,name: item.name
    ,no: item.no
    ,phone : item.phone
    ,drawgroup : getGroup(item.name)
    ,bookType : phonebookType
*/
export const getAddressInfo = (_number, _no = "") => {
    //연락처를 검색해서 있는 경우 연락처 정보 리턴 없는 경우 없는 케이스 리턴
    //let allPhonebookLst = Array.from(userData.dataset.pblistForDevice).concat(userData.dataset.pblistForCentrix);
    let allPhonebookLst = userData.dataset.pblistForCentrix;

    //console.log("주소록개수 : ", allPhonebookLst.length);
    //console.log("#####주소조회요청 : ", "전화번호:" + _number + "/No:" + _no);
    const phoneItem = allPhonebookLst.filter((el) => {
            if ( _no === '' || _no === undefined ){
                if ( (el.phone === _number) ){
                    return el;
                }
            }else if ( _no === el.no ) {
                return el;
            }
        }
    );

    //console.log("find result ==>", phoneItem);
    if ( phoneItem === null || phoneItem.length === 0  ){
        let rtnObj = {
                etc : ''
                ,groupCode: ''
                ,groupName: ''
                ,name: _number
                ,no: -1
                ,contactId : -1
                ,phone : _number
                ,phone2 : ''
                ,drawgroup : ''
                ,bookType : 'nodata'
        };
        return rtnObj;

    }else{
        console.log('==========match phonebook===============');
        if ( phoneItem.length > 0 ) {
            console.log('=match check:',phoneItem[0]);
            return phoneItem[0];
        }else{
            console.log('no arrary=?', phoneItem);
        }
        return phoneItem;
    }
}

export const getAddressInfoForBlack = (_number, _no = "") => {
    //연락처를 검색해서 있는 경우 연락처 정보 리턴 없는 경우 없는 케이스 리턴
    //let allPhonebookLst = Array.from(userData.dataset.pblistForDevice).concat(userData.dataset.pblistForCentrix);
    let allPhonebookLst = userData.dataset.pblistForCentrix;

    //console.log("주소록개수 : ", allPhonebookLst.length);
    //console.log("#####주소조회요청 : ", "전화번호:" + _number + "/No:" + _no);
    const phoneItem = allPhonebookLst.filter((el) => {
            if ( _no === '' || _no === undefined ){
                if ( (el.phone === _number) ){
                    return el;
                }
            } else if ( _no === el.no ) {
                return el;
            }
        }
    );

    const phoneItemForDevice = userData.dataset.pblistForDevice.filter((el) => {
            if ( _no === '' || _no === undefined ){
                if ( (el.phone === _number) ){
                    return el;
                }
            }else if ( _no === el.contactId ) {
                return el;
            }
        }
    );
    

    //console.log("find result1 ==>", phoneItem);
    //console.log("find result2 ==>", phoneItemForDevice);
    if ( ( phoneItem === null || phoneItem.length === 0 ) && ( phoneItemForDevice === null || phoneItemForDevice.length === 0 ) ){
        let rtnObj = {
                etc : ''
                ,groupCode: ''
                ,groupName: ''
                ,name: _number
                ,no: -1
                ,contactId : -1
                ,phone : _number
                ,phone2 : ''
                ,drawgroup : ''
                ,bookType : 'nodata'
        };
        return rtnObj;

    } else if ( phoneItem !== null && phoneItem.length > 0 ) {
        if ( phoneItem.length > 0 ) {
            //console.log('=match centrix check:',phoneItem[0]);
            return phoneItem[0];
        }else{
            //console.log('=match centrix check:',phoneItem);
            return phoneItem;
        }

    } else if ( phoneItemForDevice !== null && phoneItemForDevice.length > 0 ) {
        if ( phoneItemForDevice.length > 0 ) {
            //console.log('=match phone check:',phoneItemForDevice[0]);
            return phoneItemForDevice[0];
        }else{
            //console.log('=match phone check:',phoneItemForDevice);
            return phoneItemForDevice;
        }
    }else{
        console.log('==========match else phonebook===============');
        if ( phoneItem.length > 0 ) {
            //console.log('=match check:',phoneItem[0]);
            //console.log('=match phone check:',phoneItem[0]);
            return phoneItem[0];
        }
        //console.log('=match phone check:',phoneItem);
        return phoneItem;
    }

}


export const getGroup = (dataStr) => {
    var result = "";

    //console.log("getGroup target=>",dataStr);
    var code = dataStr.charCodeAt(0)-44032;
    if(code>-1 && code<11172) result = CONSTS.DEST_CHO[Math.floor(code/588)];
    else result = '#';  //dataStr.charAt(0);

    return result;
}

export const comma = (str) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
}


export const transformAddressDrawData = (_list) => {
    //console.log("=======transformAddressDrawData=======");
    //console.log(_list);

    if ( _list !== null && _list.length > 0 ){
        var drawData = _list.reduce((src, dest ) => {
            //console.log(dest.drawgroup);
            (src[dest.drawgroup] = src[dest.drawgroup] || []).push(dest);
            return src;
        }, {});
    
        //console.log("=======process first=======");
        //console.log(drawData);
    
        var drawlstData = [];
        Object.keys(drawData).map((_key) => {
            if ( drawlstData[_key] === null ){
                drawlstData[_key] = [];
            }
            drawlstData.push({
                title : _key
                //,items : sortHanFirstObjectList(drawData[_key], 'name', 'ASC')
                ,items : sortHanWordsList(drawData[_key], 'name', 'ASC')
                /*
                ,items : drawData[_key].sort( (_src, _dest) => {
                    if ( _src.name > _dest.name) return 1;
                    if ( _src.name === _dest.name) return 0;
                    if ( _src.name < _dest.name) return -1;
                  })
                  */
            });
        });
    
        //console.log("=======result => drawData=======");
        //console.log(drawlstData.sort());

        return drawlstData.sort( (_src, _dest) => {
            if ( CONSTS.DEST_CHO.indexOf(_src.title) > CONSTS.DEST_CHO.indexOf(_dest.title) ) return 1;
            if ( CONSTS.DEST_CHO.indexOf(_src.title) === CONSTS.DEST_CHO.indexOf(_dest.title) ) return 0;
            if ( CONSTS.DEST_CHO.indexOf(_src.title) < CONSTS.DEST_CHO.indexOf(_dest.title) ) return -1;
          });
    }else {
        return [];
    }
    
}


export const getBlackList = (_srchWord) => {
    
}

export const getCurrentDateTimeString = () =>
{
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + month + day + hour + minites + seconds;
}
let backKeyPressedTime = 0;
export const closeBack = (msg) => {
    if(isIOS()) return;
    const currentTimestamp = new Date().getTime();
    if (currentTimestamp > backKeyPressedTime + 2000) {
        backKeyPressedTime = new Date().getTime();
        toast('\'뒤로\' 버튼을 한번 더 누르시면 종료됩니다');
    } else {
        NativeBridge.shutdownApp();
    }
}
export const getMaskName = (nameStr) => {
    return nameStr.replace(/^(.)./, '$1*');
}

export const addHyphenMaskPhoneNo = (phoneNum) => {
    const phoneNoString = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
    const phoneNoArray = phoneNoString.split("-");
    return phoneNoArray[0] + "-" + phoneNoArray[1].substr(0,2) + "**-" + phoneNoArray[2].substr(0,2) + "**";
}

export const getByteLength = (targetStr)  => {

    if ( targetStr !== undefined && targetStr !== null && targetStr !== '') targetStr = targetStr.trim();
    else targetStr = '';

    var intByteLength   = 0;
    var chrData         = null;

    for(var i=0; i< targetStr.length; i++) {

        chrData = escape(targetStr.charAt(i));
        if(chrData.length === 1) {
            intByteLength ++;
        } else if (chrData.indexOf("%u") !== -1) {
            intByteLength += 2;
        } else if (chrData.indexOf("%") !== -1) {
            intByteLength += chrData.length/3;
        }
    }

    return intByteLength;
}

/**
 * 공백여부
 * @param  _target 
 * @returns 
 */
export const isCheck = (_target) => {
    if ( _target === undefined || _target === null || _target === '' || trim(_target) === '' ){
        return false;
    }else{
        return true;
    }
}

/**
 * 
 * @param {*} _target 
 * @param {*} _item 
 * @returns 
 */
export const defaultString = (_target, _item = '') => {
    if ( _target === undefined || _target === null || _target === '' || trim(_target) === '' ){
        return _item;
    }else{
        return trim(_target);
    }
}

export const getAddressListByGroup = (_groupCode) => {
    return Array.isArray(userData.dataset.pblistForCentrix) 
        && userData.dataset.pblistForCentrix.filter((targetItem) => {
            if ( targetItem.groupCode === _groupCode) return targetItem;
        });
}

export const getAddressListForPhoneByGroup = (_groupCode) => {

    const selectedAddressList = [];

    if ( Array.isArray(userData.dataset.pblistForCentrix) ){
        userData.dataset.pblistForCentrix.map((targetItem, idx) => {
            if ( targetItem.groupCode === _groupCode) selectedAddressList.push(targetItem.phone);
        });
    }

    return selectedAddressList;
}

//3자리 마다 , 넣기 우리나라 원표시
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const Base64 = {
  btoa: (input = '')  => {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }

      block = block << 8 | charCode;
    }

    return output;
  },

  atob: (input = '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i = 0;
      buffer = str.charAt(i++);

      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};