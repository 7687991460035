import React, {useEffect, useState} from 'react';
import { Route, Redirect, Link, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import 'moment/locale/ko';
import * as NativeBridge from '../../../utils/NativeBridge';
import ShopManage from './ShopManage';
import * as LocalStore from '../../../store/LocalStore';

const ShopManageUnSub = observer(({ svcCardNm, hposImge, urlConn, urlConnImge }) => {
    const { userInfo, userData } = store;

    const ShopCCTV = () => {
        NativeBridge.sendUserActionLog('menu', 'H005');

        userData.setShopMainTabList('cctv')
    }

    const ExternalPage = (urls) => {
        NativeBridge.sendUserActionLog('menu', 'H006');
        NativeBridge.externalCall(`${urls}`)
    }

    return (
        <> 
            { (!userInfo.dataset.myservice.cctv && LocalStore.getIsTempUser()) ||  !LocalStore.getIsTempUser() ? 
                    <div className="content_box">
                        <Link to={`/shop/cctv`} className="sec_link usetap" onClick={()=> ShopCCTV()}><i className="ico_dark_more"></i>더보기</Link>
                        <span className="cont_title">{svcCardNm}</span>
                        <div className="sec_wrap unsubs_img">
                            <img src={`${hposImge}`} />
                            <button type="button" onClick={()=>ExternalPage(urlConn)}><img src={`${urlConnImge}`} /></button>
                        </div>
                    </div>
                :
                <>
                    <ShopManage svcCardNm={svcCardNm} />
                </>
            }

        </>
    )
});

export default ShopManageUnSub;