import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import { layerPopup, toast } from '../../libs/common';
import { isIOS, isPC } from '../../common/common';

const BioReg = observer(({ history, location }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    useEffect(() => {    
        NativeBridge.sendUserActionLog('first', 'FS');   
        console.log("Bio default useEffect..");
        layerPopup.show('#popup_bio');

       //native event set
       const subscription = NativeBridge.eventObservable.subscribe(event => {

        console.log("------- From Native -------");
        console.log(event);
            
        switch (event.type) {                    

            case 'CALL_BIOMETRICS':

                const result = event.param;                
                console.log("bio reg result: ", result);

                console.log(result.type === "0" ? "지문" : "안면인식");
        
                // 성공
                if(result.status === "0") {
                    console.log("등록 성공");
                    userInfo.setBioLoginType(result.type);

                    // mypage 에서 왔으면 my 로 이동  
                    if(location.pathname === '/bio/myreg') {
                        history.replace("/my/mysetting/biosetting");
                        return;
                    }

                    // - 임시사용자는 약관동의, 초대계정 체크 없이 그 다음으로 바로 이동
                    const isTempUser = LocalStore.getIsTempUser();
                    if(isTempUser === true) {
                        //history.replace("/home");
                        history.replace("/account/owner/select");
                        return;
                    }

                    goTemsAgreePage();

                // 실패
                } else if(result.status === "1") {

                    toast("등록에 실패했습니다. 다시 한번 시도해 주세요.");
                    //LocalStore.removeBioLoginType();
                    userInfo.setBioLoginType(null);
                    layerPopup.show('#popup_bio');

                // 미등록 
                } else if(result.status === "2") {
                    const deviceInfo = LocalStore.getDeviceInfo();
                    if ( deviceInfo.os_type === 'ios'){
                        toast("생체인식 권한을 허용하거나 등록 후 다시 시도해주세요.");
                    }else{
                        toast("기기에서 지문 등록 후 다시 시도해주세요.");
                    }
                    
                    //LocalStore.removeBioLoginType();
                    userInfo.setBioLoginType(null);

                    // mypage 에서 왔으면 my 로 이동  
                    if(location.pathname === '/bio/myreg') {
                        history.replace("/my/mysetting/biosetting");
                        return;
                    }

                    goTemsAgreePage();

                // 미지원단말 
                } else if(result.status === "9") {

                    //LocalStore.removeBioLoginType();
                    toast("생체인증 미지원단말입니다.");
                    userInfo.setBioLoginType(null);

                    // mypage 에서 왔으면 my 로 이동  
                    if(location.pathname === '/bio/myreg') {
                        history.replace("/my/mysetting/biosetting");
                        return;
                    }

                    goTemsAgreePage();

                } else {
                    toast("등록에 실패했습니다.");
                    //LocalStore.removeBioLoginType();
                    userInfo.setBioLoginType(null);
                    goTemsAgreePage();
                }

            break;

            default:
                break;
        }

    });

    return () => {
        subscription.unsubscribe();
      };

}, []);


   // 다음에 다시하기
   const notNow = (event) => {
        event.preventDefault();

        LocalStore.removeBioLoginType();
        layerPopup.hide('#popup_bio', () => {
            if(location.pathname === '/bio/myreg') {
                history.replace("/my/mysetting/biosetting");
                return;
            }

            // - 임시사용자는 약관동의, 초대계정 체크 없이 그 다음으로 바로 이동
            const isTempUser = LocalStore.getIsTempUser();
            if(isTempUser === true) {
                history.replace("/account/owner/select");
                //history.replace("/home");
                return;
            }

            goTemsAgreePage();
        });
    }

    // 등록하기
    const regBio = (event) => {
        // LocalStore.setBioLoginStatus(false);
        // history.push("/home");
        event.preventDefault();        

        layerPopup.hide('#popup_bio');
        const ret = NativeBridge.regBiometrics();

        // if(isPC()) {
        //     setTimeout(()=> {
        //         const userData = {
        //             type:"CALL_BIOMETRICS",
        //             param:{
        //             status:"0",
        //             type: "1"                }
        //         };
        //         window.NativeInvoker(userData);
        //     }, 500);
        // }

        // if(location.pathname === '/bio/myreg') {
        //     history.replace("/my/mysetting/biosetting");
        //     return;
        // }

    }

    const goTemsAgreePage = () => {
        history.replace("/agree/reg");
    }

    return (
        <>
            <div className="all_ready">
                <p>모든 준비가 끝났어요.</p>
                <p>함께 쉽게 가게를 통합 관리해보아요!</p>
            </div>

            <div className="modal fadeInUp" id="popup_bio">
                <div className="dim"></div>
                <div className="modal_inner modal_up">
                    <span className="modal_title">간편 인증을 설정해보세요.</span>
                    <a href="#none" className="bio_regi_box usetap" onClick={(e)=> regBio(e)}> 
                        <span className="title">지문 또는 얼굴 등록하기</span>
                        <p className="desc">비밀번호 대신<br/> 지문 또는 얼굴로 로그인할 수 있어요.</p>
                        {/* <i className="ico_dark_more"></i> */}
                    </a>
                    <div className="link_theme_wrap">
                        <a href="#none" className="link_theme" onClick={(e)=> notNow(e)}>다음에 등록하기</a>
                    </div>
                </div>
            </div>

        </>
    )
    
});
export default BioReg;