import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import { layerPopup } from '../../libs/common';
import { setCenterexUserIdPass } from '../../api/CentrexApi';
import CentrexApi from '../../api/CentrexApi';
import BizApi from '../../api/BizApi';

const InterworkLoginPopup = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);


    const [idValue, setIdValue] = useState("");
    const [password, setPassWord] = useState("");
    const [userInfoData, setUserInfoData] = useState([]);


    useEffect(() => {
        if(userData.dataset.callLoginFlag === true){
            초기화("all");
        }
        userData.setCallLoginPopupFlag(false);
    },[userData.dataset.callLoginFlag])
    
    //아이디 관리
    const idValueOnChange = (e) => {
        let id = e.target.value;
        setIdValue(id);
    }
    //패스워드 관리
    const passwordValueOnChange = (e) => {
        let pass = e.target.value;
        setPassWord(pass);
    }

    //로그인 api
    const uPlusLoginInfo = async () => {

        let cryptPassword = Crypt.centrexPwEncrypt(password)

        const userInfo = await CentrexApi.getUserInfo({
            id:idValue,
            //pass:cryptPassword// 암호화
            //pass:"67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf"
              pass: cryptPassword
        },false)

        console.log("사용자 정보조회 ===>", userInfo);
        setUserInfoData(userInfo);

        if(userInfo.data.SVC_RT === "0000"){
            const exten = userInfo?.data.DATAS.EXTEN;
            //창 닫고 연동하기 없앰.
            console.log("연동성공")
            userData.setInterworkCheck("1");
            LocalStore.setStoreInetNo(idValue);
            userData.setStoreInetNo(idValue);
            LocalStore.setTempInetPass(cryptPassword);
            LocalStore.setExtenInetNo(exten);
            setCenterexUserIdPass(idValue , cryptPassword);
            setIdValue("");
            setPassWord("");
            layerPopup.hide('#popup_interworklogin')

            BizApi.makeMyService();
            
        }
        else{
            //오류 팝업
            setIdValue("");
            setPassWord("");
            layerPopup.hide('#popup_interworklogin', ()=>{
                console.log("연동실패");
                layerPopup.showCommon(
                    '#popup_temploginerror', 
                    
                    null, 
                    '아이디 또는 비밀번호 오류', 
                    '아이디 또는 비밀번호를 잘못</br>입력했습니다.<br/>확인 후 다시 입력해주세요.',
                    
                    [
                        function() {
                            setIdValue("");
                            setPassWord("");
                            layerPopup.hide('#popup_temploginerror', ()=>{
                                layerPopup.show('#popup_interwork_pw_find');
                            })
                            // document.getElementById("popup_temploginerror").style.display = "none";
                            //layerPopup.hide('#popup_temploginerror');//닫기
                        },
                    
                    ]
                )
            });


            
        }

    }
    const 초기화 = (data) => {
        if(data === "all"){
            setIdValue("");
            setPassWord("");
            layerPopup.hide('#popup_interworklogin')
        }
        if(data === "id"){
            setIdValue("");
        }
        if(data === "pass"){
            setPassWord("");
        }
    }
    

    
    

    return (
        <>
            <div className="modal fadeIn" id="popup_interworklogin">
                <div className="dim" onClick={()=>초기화("all")}></div>
                <div className="modal_inner">
                    <div className="modal_content modal_interwork_login">
                    <button type="button" className="pop_close2 blind" onClick={()=>초기화("all")}>닫기</button>
                        <div className="modal_hd">U+인터넷전화 연동하기</div>
                        <div className="modal_bd">
                            {/* <p>CCTV 기능을 앱에서 편리하게 <br />사용할 수 있습니다.</p> */}
                            <div className="input_box style2">
                                <input type="text" placeholder="아이디 입력" value={idValue}  onChange={(e) => idValueOnChange(e)} />
                                <span className="ip_clear hidden usetap" onClick={() => {초기화("id")}}>초기화</span>
                            </div>
                            <div className="input_box style2">
                                <input type="password" placeholder="비밀번호 입력" value={password} onChange={(e) => passwordValueOnChange(e) } />
                                <span className="ip_clear hidden usetap" onClick={() => {초기화("pass")}}>초기화</span>
                            </div>
                        </div>
                        <div className="modal_ft">
                            <button  type="button" className="btn_half usetap" onClick={()=>uPlusLoginInfo()} ><span>로그인</span></button>
                            <button  type="button" className="btn_half usetap" onClick={()=>layerPopup.show('#popup_interwork_pw_find')}><span>비밀번호 재설정</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
});
export default InterworkLoginPopup;