import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import { layerPopup, toast } from '../../libs/common';
import CentrexApi from '../../api/CentrexApi';

const InterworkPwFindPopup = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    const [idValue, setIdValue] = useState("");
    const [password, setPassWord] = useState(""); 
    const [password2, setPassWord2] = useState("");
    const [passCheck, setPassCheck] = useState(false);
    const [passCheck2, setPassCheck2] = useState(false);
    const [passCheck3, setPassCheck3] = useState(false);//연속 3번 문자 입력할 시 문구에 사용
    const [passCheck4, setPassCheck4] = useState(false);//이전에 사용한 비밀번호 일시 문구에 사용
    const [authNum, setAuthNum] = useState("");
    const [timerCheck, setTimerCheck] = useState(false);
    const [sessionId1, setSessionId1] = useState("");
   // const [sessionId, setSessionId] = useState("");
   const [check, setCheck] = useState(null);
    let [time, setTime] = useState(300);
    const  [timeId, setTimeId] = useState(null);
    const [time2,setTime2] = useState(null);
    const [stop1, setStop1] = useState(null);
   //let time = 300; //기준시간 작성
   let min = ""; // 분
   let sec = ""; // 초
  // let check = "on";
   //let timeId = null;


   let timer = () => {
   
     console.log("타임시작")
    // console.log("ㅍㅍㅍ",timerCheck);
    //parseInt() : 정수를 반환
    min = parseInt(time/60); //몫을 계산
    
    sec = time%60; //나머지를 계산

    if(sec < 10){
        sec = "0" + time % 60
    }
    console.log("111", min, sec);
    
    document.getElementById("demo").innerHTML = min + ":" + sec ;
    time--;
    let test = time;
    //setTimeId(time => --time);
    setTime2(test);
    console.log("1121", min, sec);
    
    // if(timerCheck === false){
    //     setTimerCheck(true);
    //     console.log("dd")

    // }else{
    //     console.log("dd2")
    // }
    //타임아웃 시
    
    //setTimerCheck(true);
    // console.log("ㅍㅍㅍ",timerCheck);
    console.log("zxck00", time, test);
    // if(test < 0){
    //     stopTimer(true);
    //     console.log("zxck~~~", test)
    //     //setTimerCheck(false);   
    //}
    // if(timerCheck === true){
    //     console.log("타는지확인")
    //     clearInterval(x);
    //     clearInterval(x);
        
    //     setTimerCheck(false); 
    //     return;
    // }
    
}
useEffect(() => {
    if(time2 < 0){
        stopTimer(true);
        console.log("zxck~~~", time2)
    }
},[time2])

useEffect(() => {
    if(stop1 !== null){
        stopTimer(false);
        setStop1(null);
    }
},[stop1])

//HW back key 시 모든 값 초기화 
useEffect(() => {
    if(userData.dataset.passFindPopupFlag === true){
        clear();
    }
    userData.setPassFindPopupFlag(false);
},[userData.dataset.passFindPopupFlag])

useEffect(() => {
    $('#auth_num').prop('disabled', true);
},[])

const startTimer = async () => {
    console.log("zxck",timeId);
    console.log("zxck@",check)
    
    $('#auth_num').attr('disabled', false);

    if(timeId !== null || check !== null){
        console.log("zxck11",timeId);
        await stopTimer(false)
        //timeId =  setInterval(timer, 1000)
        setTimeId(setInterval(timer, 1000));
        //time = 300;
        setTime(300);
        authCodeOnClick()
    }else{
        console.log("zxck22",timeId);
         //stopTimer()
         //timer()
         //timeId =  setInterval(timer, 1000)
         setTimeId(setInterval(timer, 1000));
         authCodeOnClick()
     }
}

const stopTimer = (data) => {
    console.log("zxck33",timeId);
    if(timeId !== null && data === false  ){
        console.log("11")
        console.log("zxck44",timeId);
        clearInterval(timeId);
        document.getElementById("demo").innerHTML = "5:00"
        //timeId = null
        //time = 300;
       
    }
    if(data === true){
        console.log("zxck55",timeId);
        clearInterval(timeId);
    }
    //time = 300;
}
   const clear = () => {
    //timer()
    $('#auth_num').prop('disabled', true);
    stopTimer(false);
    setIdValue("")
    setPassWord("")
    setPassWord2("")
    setPassCheck(true)
    setPassCheck2(false)
    setPassCheck3(false)
    setPassCheck4(false)
    setAuthNum("")
    setTimerCheck(false)
    setSessionId1("")
    layerPopup.hide('#popup_interwork_pw_find')
}
     //아이디 관리
     const idValueOnChange = (e) => {
        let id = e.target.value;
        setIdValue(id);
    }
    //패스워드 관리
    const passwordValueOnChange = (e) => {
        let pass = e.target.value;
        setPassWord(pass);
    }

    const authValueOnChange = (e) => {
        let authCode = e.target.value;
        setAuthNum(authCode);
    }

    //패스워드 일치 확인
    const passwordValueOnChange2 = (e) => {
        let pass2 = e.target.value;
        setPassWord2(pass2);
    }
    useEffect(() => {       
        console.log("Bio default useEffect..");
        console.log("aa",passCheck2)
    }, []);
    useEffect(()=> {
        if(password === password2){
            setPassCheck(true);
        }
        else{
            setPassCheck(false);
        }
    },[password2])

  
//비밀번호 유효성 검사
    const checkPassword = (e) => {
        let pw = e.target.value;

        setPassWord(pw);
        let num = pw.search(/[0-9]/g);
        let eng = pw.search(/[a-z]/ig);
        let spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
       
        
        console.log("pw",pw)
        if((/(\w)\1\1\1/gi).test(pw)){
            console.log("같은 문자를 연속적으로 사용할 수 없습니다.")
            setPassCheck3(true);
            return false;
        }
        else{
            setPassCheck3(false);
        }
        if(pw.length !== 0 && pw.length < 8 || pw.length !== 0 &&pw.length > 10){
       
            console.log("영문,숫자 특수문자를 모두 사용해 8-10자로 입력해주세요.")
            setPassCheck2(true);
         return false;
       
        }
        
       
        if(pw.search(/₩s/) != -1){
            
            console.log("영문,숫자 특수문자를 모두 사용해 8-10자로 입력해주세요.")
            setPassCheck2(true);
       
         return false;
       
        }
       
        if(pw.length !== 0 && num < 0 || pw.length !== 0 && eng < 0 || pw.length !== 0 && spe < 0 ){
       
            console.log("영문,숫자 특수문자를 모두 사용해 8-10자로 입력해주세요.")
            setPassCheck2(true);
            return false;
       
        }
        if(pw.length < 1){
            setPassCheck2(false);
            return false;
        }

        console.log("확인")
        setPassCheck2(false);
        setPassCheck3(false);
        return true;
       
}
//인증코드 API
const getAuthCodeApi = async (check) => {

    let sessionId = ""

    //timeId = "default";
    console.log("zxck66",timeId);
    if(check === true){
        const authCode = await CentrexApi.getAuthCode({
            id: idValue,
        })

        console.log("인증번호 요청", authCode)

        
        
        if(authCode.data.DATAS.TRYCNT === "20" || authCode.data.SVC_RT === "1201" ){
            console.log("zxck99",timeId);
            //stopTimer(false);
            setStop1(true);
             layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                
                null, 
                '인증번호 요청 횟수 초과', 
                '인증번호 1일 최대 20회만 <br />발송가능합니다. <br />24시간 후에 다시 요청해 주세요.',
                
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                        //stopTimer(false);
                        
                    },
                  
                ]
            )
        }
        else if(authCode.data.SVC_MSG === "OK"){
            let code = authCode.data.DATAS.SESSIONID
            sessionId = code;
            setSessionId1(sessionId);
            // if(data === false){
            //      timer();   
            // }
            //timeId = "default";
            setCheck(timeId);
            console.log("zxck77", timeId);
            return;
        }else {
            setStop1(true);
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '알림', 
                '인증번호 발송에 실패하였습니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                    },
                  
                ]
            );
        }
        return;
    }
    else{
        getAuthChangeApi(sessionId)
    }
    console.log("zxck88", timeId);
}
//비밀번호 변경 API
const getAuthChangeApi = async (key) => {

    if(authNum.length !== 6){
        return layerPopup.showCommon(
            '#popup_onetitlebutton_threemsg', 
            
            null, 
            '알림', 
            '인증코드를 다시 확인해주세요.</br>(6자리 숫자)',
            
            [
                function() {
                    layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                },
              
            ]
        )
    }

    if(document.getElementById("demo").innerHTML === "0:00" ){
        return toast("인증번호를 다시 요청해 주세요.")
    }  

    const authChange = await CentrexApi.getAuthChange({
        id: idValue,
        sessionid : sessionId1,
        newpass: password,
        newpass2: password2,
        authcode : authNum,
    });
    //이전 3회에 사용한 비밀번호
    if ( authChange.data.SVC_RT === '3001') {
        return layerPopup.showCommon(
            '#popup_onetitlebutton_threemsg', 
            null, 
            '알림', 
            '이전에 사용한 비밀번호입니다. 다른 비밀번호를 입력해 주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                },
              
            ]
        );
        
    }else if( authChange.data.SVC_RT === "1204" || authChange.data.SVC_RT === "1203"){
        setPassCheck4(true);
        toast("인증번호 확인 후 다시 입력해주세요.");
    }else if( authChange.data.SVC_RT === "1201" || authChange.data.SVC_RT === "1202"){
        setPassCheck4(true);
        toast("인증번호 요청 횟수 초과.");
    }else if( authChange.data.SVC_RT === "0000" ){
        setPassCheck4(true);
        toast("비밀번호가 변경되었습니다. 변경된 비밀번호로 로그인 해 주세요.")
        clear(false);
    } else  {
        setPassCheck4(true);
        layerPopup.showCommon(
            '#popup_onetitlebutton_threemsg', 
            null, 
            '알림', 
            '비밀변호 변경 실패하였습니다.\n다시 설정해주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                },
              
            ]
        );
    }

}
//console.log("zxcvv",document.getElementById("demo").innerHTML)

//최초 인증번호 요청 클릭
const authCodeOnClick = (data) => {
    const check = true;
    getAuthCodeApi(check);
    console.log("data",data);
    
//   if(data === false){
//     timer();   
//     }
//     else{
//         console.log("datadata222")
//         timer(); 
//     }
    // if(data == true){
        
    //     timer();
    // }

    //timer();
    
}

//비밀번호 재설정 클릭
const authChangeOnClick = () => {
    const check = false;
    getAuthCodeApi(check);
}

useEffect(() => {

    

},[timerCheck])





// 타이머 
// const timer = (data) => {
    
    
//     let time = 300; //기준시간 작성
//     let min = ""; // 분
//     let sec = ""; // 초
//     let check = "on";
  
    
//     let x = setInterval(function() {
//         // console.log("타임시작")
//         // console.log("ㅍㅍㅍ",timerCheck);
//         //parseInt() : 정수를 반환
//         min = parseInt(time/60); //몫을 계산
        
//         sec = time%60; //나머지를 계산

//         if(sec < 10){
//             sec = "0" + time % 60
//         }
        
//         document.getElementById("demo").innerHTML = min + ":" + sec ;
//         time--;
//         // if(timerCheck === false){
//         //     setTimerCheck(true);
//         //     console.log("dd")

//         // }else{
//         //     console.log("dd2")
//         // }
//         //타임아웃 시
        
//         //setTimerCheck(true);
//         // console.log("ㅍㅍㅍ",timerCheck);
//         if(time < 0){
//             clearInterval(x);
//             setTimerCheck(false);   
//             return;
//         }
//         if(timerCheck === true){
//             console.log("타는지확인")
//             clearInterval(x);
//             clearInterval(x);
            
//             setTimerCheck(false); 
//             return;
//         }
        
//     }, 1000);
//     // if(timerCheck === true){
//     //     clearInterval(x);
//     //     setTimerCheck(false);
//     //     setInterval(x);
//     // }
//     //clearInterval(x);
//      console.log("dzdz", timerCheck);
   


//     setTimerCheck(true);
// }
// let timer2 = () =>  {
//     setInterval(() => {
       
//         let time = 300; //기준시간 작성
//         let min = ""; // 분
//         let sec = ""; // 초
//         let check = "on";
      
//         // console.log("타임시작")
//         // console.log("ㅍㅍㅍ",timerCheck);
//         //parseInt() : 정수를 반환
//         min = parseInt(time/60); //몫을 계산
        
//         sec = time%60; //나머지를 계산
    
//         if(sec < 10){
//             sec = "0" + time % 60
//         }
    
//         document.getElementById("demo").innerHTML = min + ":" + sec ;
//         time--;
//         // if(timerCheck === false){
//         //     setTimerCheck(true);
//         //     console.log("dd")
    
//         // }else{
//         //     console.log("dd2")
//         // }
//         //타임아웃 시
        
//         //setTimerCheck(true);
//         // console.log("ㅍㅍㅍ",timerCheck);
//         if(time < 0){
//             clearInterval(timer2());
//             setTimerCheck(false);   
//             return;
//         }
//         if(timerCheck === true){
//             console.log("타는지확인")
//             clearInterval(timer2());
            
//             setTimerCheck(false); 
//             return;
//         }
        
//     }, 1000);
// } 

// const timer = (data) => {
    
    
   
    
    
//     // if(timerCheck === true){
//     //     clearInterval(x);
//     //     setTimerCheck(false);
//     //     setInterval(x);
//     // }
//     //clearInterval(x);
//      console.log("dzdz", timerCheck);
   


//    // setTimerCheck(true);
// }




    return (
        <>
            <div className="modal fadeInUp" id="popup_interwork_pw_find">
                <div className="dim" onClick={()=>{clear()}}></div>
                <div className="modal_inner modal_up max_h">
                    <button type="button" className="pop_close usetap blind" onClick={()=>{clear()}} >닫기</button>
                    <span className="modal_title">비밀번호 재설정</span>
                    <div className="modal_interwork_pw">
                        <div className="pw_sec1">
                            <span className="title">아이디를 입력하고 비밀번호를 영문, 숫자, 특수문자 조합으로 8~10자리로 설정해주세요</span>
                            <div className="input_box style2">
                                <input type="tel" placeholder="예: 07012340000" style={{marginBottom: '1rem'}} value={idValue} onChange={(e) => idValueOnChange(e)} />
                                <span className="ip_clear hidden usetap">초기화</span>
                                <span className="desc_msg">아이디는 가입한 U+인터넷전화 전화번호 중 뒷자리가 가장 낮은 숫자를 가진 번호입니다.</span>
                            </div>
                            <div className="input_box style2" style={{marginBottom: '1rem'}}>
                                <input type="password" placeholder="비밀번호 입력" style={{margin: '1rem 0'}} value={password} onChange={(e) => checkPassword(e)} />
                                <span className="ip_clear hidden usetap">초기화</span>
                                <span className="desc_msg">비밀번호는 영문, 숫자, 특수문자를 모두 사용하여 8~10자리로 설정해주세요.</span>
                            </div>
                            {passCheck2 === true ? <p className="err_msg">영문, 숫자 특수문자를 모두 사용해 8~10자로 입력해주세요.</p> : <></>}
                            {passCheck3 === true ?<p className="err_msg">같은 문자를 연속적으로 4번 이상 사용할 수 없습니다.</p> : <></> }
                            <div className="input_box style2">
                                <input type="password" placeholder="비밀번호 재입력" value={password2} onChange={(e) => passwordValueOnChange2(e)} />
                                <span className="ip_clear hidden usetap">초기화</span>
                            </div>
                            {passCheck !== true ? <p className="err_msg">비밀번호가 일치하지 않습니다.</p> : <></>}
                            
                        </div>
                        <div className="pw_sec2">
                            <span className="title">[인증번호 받기]를 누르고 가입한 인터넷전화에서 인증번호를 확인하세요</span>
                            <div className="input_wrap">
                                <div className="input_box style2">
                                    <input type="tel" id="auth_num" placeholder="인증번호 입력" value={authNum} onChange={(e)=> authValueOnChange(e)} />
                                    <span id= "demo"className="auth_num">5:00</span>
                                </div>
                                <button type="button" className="btn_point2 usetap" disabled={passCheck === false || idValue === "" || password === "" || password2 === "" ? true : false}  onClick={()=>startTimer()} >인증번호 받기</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn_box fixed">
                    <div className="inner">
                        <button type="button" className="btn_lg btn_point usetap" disabled={passCheck === false || (idValue === "" || idValue.length !== 11) || authNum === "" ? true : false}  onClick={()=>authChangeOnClick()} >재설정하기</button>
                    </div>
                </div>
            </div>
        </>
    )
});
export default InterworkPwFindPopup;