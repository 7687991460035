import React, { useEffect, useState, useCallback } from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../store";
import * as LocalStore from '../../../store/LocalStore'
import $ from "jquery";
import { tabUi } from "../../../libs/tab";
import { layerPopup } from "../../../libs/common";
import * as NativeBridge from "../../../utils/NativeBridge";


//전화 설정 페이지
const Interwork = observer(({ history, match, location }) => {

	const { userInfo, userData,deviceInfo } = store;
	// if(document.getElementById("popup_callring").style.display === "block"){
	// 	popupClose();
	// 	  return layerPopup.hide('#popup_prelisten')
	// 	  //return document.getElementById("popup_prelisten").style.display = "none"
	// 	  //document.getElementById("make_call_before").style.display = "none"
	// 	  console.log("타는지 확인@@@22")
	// 	  //setRender("1");
	//   }
	//   else{
	// 	  console.log("타는지 확인@@@")
	// 	  goBackPage();
	// 	  //setRender("0");
	//   }

	const cctvKey = LocalStore.getCctvToken();
  
	useEffect(() => {
		$('#wrap').removeClass('bg_gray')
		//userData.setStoreInetNo(LocalStore.getStoreInetNo());
		const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : Main.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'CALL_BACKPRESS':
						//아이디 패스워드 팝업과 비밀번호 재설정 팝업 같이 띄워졌을 때 비밀번호 재설정 팝업만 닫음
						if(document.getElementById("popup_interwork_pw_find").style.display === "block" && 
						   document.getElementById("popup_interworklogin").style.display === "block" &&
						   location.pathname === "/shop/interwork")
						  {
								userData.setPassFindPopupFlag(true);  
								return layerPopup.hide("#popup_interwork_pw_find");  
						  }
						if(document.getElementById("popup_interwork_pw_find").style.display === "none" && 
						   document.getElementById("popup_interworklogin").style.display === "block" &&
						   location.pathname === "/shop/interwork")
						   {
								userData.setCallLoginPopupFlag(true);   
								return layerPopup.hide("#popup_interworklogin")	   
						   }
						if(document.getElementById("popup_interwork_pw_find").style.display === "" && 
						   document.getElementById("popup_interworklogin").style.display === "block" &&
						   location.pathname === "/shop/interwork")
						   {
								userData.setCallLoginPopupFlag(true);  
								return layerPopup.hide("#popup_interworklogin")	
						   }
						if(document.getElementById("popup_interworktemplogin").style.display === "block" &&
						location.pathname === "/shop/interwork")
						   {
								return layerPopup.hide("#popup_interworktemplogin")
						   }

                        if(((document.getElementById("popup_interwork_pw_find").style.display === "none" && location.pathname === "/shop/interwork") ||
						(document.getElementById("popup_interwork_pw_find").style.display === "" && location.pathname === "/shop/interwork")))
						   {
								goBackPage();
						   }
						
                    
                break;

            default:
                break;
            }

        });
		return(() => {
			subscription.unsubscribe();
		})
	
	
	}, [])

	const goBackPage = () => {
        history.goBack();
    }

	//캐시노트 외부연동
	const goPageOnClick = (e) => {
		e.preventDefault();
		//history.replace("/shop/interwork")
		NativeBridge.sendUserActionLog('detail', 'D004');
		layerPopup.showCommon(
			'#interwork_cashnote_popup', 
			null, 
			'캐시노트 바로가기', 
			'캐시노트 홈페이지로 이동할까요?',
			
			[
				function() {
					layerPopup.hide('#interwork_cashnote_popup');//닫기
				},
				function() { // 확인 버튼
				  	layerPopup.hide('#interwork_cashnote_popup');
				  	setTimeout(() => {
						NativeBridge.goPage("https://lg-uplus-soho.cashnote.kr/?registration_number=6111303767")
				  	}, 600);
				  	//history.goBack();
				}
			]
		)
    }

	//프로토타 앱 연동
	const externalCallOnClick = (e) => {
		e.preventDefault();
		
		console.log("안드로이드", deviceInfo.dataset.os_type)
		NativeBridge.sendUserActionLog('detail', 'D003');
		if(deviceInfo.dataset.os_type === "android"){
		NativeBridge.externalCall("com.lge.promota","1")
		}
		else{
			NativeBridge.externalCall("com.lgsvl.showit","1")
		}
		//console.log("url", NativeBridge.externalCall("com.lge.promota"))
	}
	//알바천국 임시
	const externalCallOnClick2 = () => {
		NativeBridge.externalCall("https://m.alba.co.kr/serviceguide/GoodsSelect.asp");
	}

	const externalCallOnClick3 = (e) => {
		e.preventDefault();
		NativeBridge.sendUserActionLog('detail', 'D005');
		layerPopup.showCommon(
			'#interwork_alba_popup', 	
			null, 
			'알바천국 바로가기', 
			'알바천국 홈페이지로 이동할까요?',
			[
				function() {
					layerPopup.hide('#interwork_alba_popup');//닫기
				},
				function() { // 확인 버튼
				  layerPopup.hide('#interwork_alba_popup');
				  setTimeout(() => {
						NativeBridge.externalCall("https://m.alba.co.kr/serviceguide/GoodsSelect.asp")
				  }, 600);
				}
			]
		)
	}

  	return (
		<>
			<div className="content store">
					<div className="page_title_wrap">
						<div className="inner">
							<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
							<h2>연동 서비스 관리</h2>
						</div>
					</div>
				<div className="fix_container">
					<ul className="interwork_list">
						<>
							<li className="inter_call">
								<span className="title">U+인터넷전화</span>
								{/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!일반사용자는 무조건 연동하기 미노출임!!!!!!!!!!!!!!! */}
								{ (userInfo.dataset.isTempUser === false || (userInfo.dataset.isTempUser === true && (userData.dataset.storeInetNo !== "" && userData.dataset.storeInetNo !== null))) ?
									<></>
								:
								<span className="btn_round purple usetap" onClick={()=>{layerPopup.show('#popup_interworklogin'); NativeBridge.sendUserActionLog('detail', 'D001'); }}>연동하기</span>
								}
							</li>
						</>
						<>
							<li className="inter_cctv">
								<span className="title">지능형 CCTV</span>
								{ ((userInfo.dataset.isTempUser === false) || (userInfo.dataset.isTempUser === true && userInfo.dataset.cctvTempUserId !== '')) ?
									<></>
								:
								<span className="btn_round purple usetap" onClick={()=>{layerPopup.show('#popup_interworktemplogin'); NativeBridge.sendUserActionLog('detail', 'D002');}}>연동하기</span>
								}
							</li>
						</>
						<li className="inter_promota">
							<span className="title">머스타드</span>
							<div className="btn_round purple usetap" onClick={(e)=> externalCallOnClick(e)}>바로가기</div>
						</li>
						<li className="inter_cashnote">
							<span className="title">캐시노트</span>
							<div onClick={(e)=>goPageOnClick(e)} className="btn_round purple usetap">바로가기</div>
						</li>
						<li className="inter_alba">
							<span className="title">알바천국</span>
							<div  className="btn_round purple usetap" onClick={(e)=>externalCallOnClick3(e)}>바로가기</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
});

export default Interwork;


