import { btnStUi,btnStUi2 } from "./btn_st";

export const floatUI = (function() {
	let pTop;
	let before_st;
    return {
        "init": function(prevTop) {
			before_st = 0;
			if (prevTop !== undefined){
				pTop = prevTop.offsetHeight;
			} else {
				pTop = 0;
			}
			let last_st = 0;
			let ticking = false;
			window.addEventListener('scroll', function(e) {
			  last_st = window.scrollY;

			  if (!ticking) {
				window.requestAnimationFrame(function() {
				  floatUI.onScroll(last_st);
				  ticking = false;
				});

				ticking = true;
			  }
			});
			window.addEventListener('resize', function(e) {
				if (prevTop !== undefined){
					pTop = prevTop.offsetHeight;
				} else {
					pTop = 0;
				}
				floatUI.onScroll(last_st);
			});
        },
		"onScroll": function(st) {
			const elaTop = document.querySelector('.hd_top');
			const ela = document.querySelectorAll('.fix');
			const btnFloatMsg = document.getElementById('btn_floating_msg');
			const btnFloatCs = document.getElementById('floating_cs')

			if (btnFloatMsg != null){
				btnStUi.msgScroll();
			}

			if (btnFloatCs != null){
				btnStUi2.csScroll();
			}

			
			if (ela.length){
				ela.forEach(function (el) {
					if (pTop > el.getBoundingClientRect().top) {
						el.children[0].style.position = 'fixed';
						el.children[0].style.top = pTop + 'px';
						el.children[0].style.zIndex = '10';
						if (elaTop != null){
							if (st > before_st){
								elaTop.classList.remove('on');
								elaTop.classList.add('off');
							} else {
								elaTop.classList.add('on');
								elaTop.classList.remove('off');
							}
						}
					} else {
						el.children[0].style.position = '';
						el.children[0].style.top = '';
						el.children[0].style.zIndex = '';
						if (elaTop != null){
							elaTop.classList.remove('on');
							elaTop.classList.remove('off');
						}
					}
					before_st = st;
				});		
			}
		}
    }
})();
