/* server api url */

export const version = "0.0.1";
// export const sohoApiUrl = "http://localhost:3000";
// export const centrexApiUrl = "http://localhost:3000";
// export const cctvApiUrl = "http://localhost:3000";

export const sohoApiUrl_PRD = "";
export const sohoApiUrl_DEV = "";
export const sohoApiUrl_LOC = "";

export const centrexApiUrl_PRD = "";
export const centrexApiUrl_DEV = "";
export const centrexApiUrl_LOC = "";

export const cctvApiUrl_PRD = "";
export const cctvApiUrl_DEV = "";
export const cctvApiUrl_LOC = "";

export const weatherApiUrl = "";
export const noticeApiUrl = "";

export const SOHOAPI_RETCODE_SUCCESS = "0000";
export const SOHOAPI_AUTH_LOGOUT_ERROR = [
    {code: "9007", title: "알림", msg: "인증에 오류가 발생했습니다."},
    {code: "9003", title: "알림", msg: "인증에 실패하였습니다."},
    {code: "1501", title: "알림", msg: "탈퇴한 사용자 입니다."},
    {code: "1708", title: "알림", msg: "패밀리 사용자가 아닙니다."}
];
export const CENTREXAPI_AUTH_ERROR = [
    {code: "1900", title: "알림", msg: "인터넷전화 로그인에 실패하였습니다."},
    {code: "1901", title: "알림", msg: "인터넷전화 로그인에 실패하였습니다."},
    {code: "1902", title: "알림", msg: "인터넷전화 로그인에 실패하였습니다."},
    {code: "1908", title: "알림", msg: "인터넷전화 로그인에 실패하였습니다."},
    {code: "1909", title: "알림", msg: "인터넷전화 로그인에 실패하였습니다."}
];
export const CENTRIX_SVC_RT_SUCCESS = "0000";

export const CCTV_RES_CODE_SUCCESS = 0;
export const CCTV_RES_AUTH_ERROR_CODE = 401;

export const ADDRESS_TYPE_CENTRIX = 'centrix';	//인터넷전화번호
export const ADDRESS_TYPE_DEVICE = 'device';
export const ADDRESS_TYPE_NODATA = 'nodata';	

    
export const MESSAGE_TEMPLATE_01 = '(광고) 제목을 입력합니다. \n\n안녕하세요. 가게 명 입니다.\n\n특별 이벤트로 행사 명 행사가 진행\n되오니, 많은 관심 부탁드립니다.\n\n- 행사기간 :\n- 영업시간 :\n- 라스트 오더 :\n- 주소 :\n- 위치 :\n- 주차 :\n\n감사합니다. 행복하세요 ^^';
export const MESSAGE_TEMPLATE_02 = '[예약완료 안내] \n\n안녕하세요. 가게 명 에 예약이 확정 \n되었습니다.\n\n- 예약일자 :\n- 시간 :\n- 예약 문의 :\n- 주소 :\n- 위치 :\n- 주차 :';

export const SOHO_SRVC_CALL = 'call';                        //전화&메시지
export const SOHO_SRVC_CCTV = 'cctv';                       //지능형CCTV
export const SOHO_SRVC_PROMOTA = 'promota';                 //프로모타 ( 가입유무 체크 가능로직 없음 )
export const SOHO_SRVC_CASHNOTE = 'cashnote';               //캐시노트 ( 가입유무 없음 - 판단은 캐시노트에서 진행함. iframe으로 제공)
export const SOHO_SRVC_ALBA = 'alba';                       //알바천국 ( 가입유무 체크 가능로직 없음  )
export const SOHO_SRVC_CALLRING = 'callRing';               //통화연결음
export const SOHO_SRVC_CALLMSG = 'callMsg';                 //우리매장알림서비스
export const SOHO_SRVC_STAFFMNG = 'staffMng';               //근태서비스(매장관리)
export const SOHO_SRVC_CCTV_OPENURL = 'cctvOpenUrl';        //위생인증서비스
export const SOHO_SRVC_CCTV_REQUEST = 'cctvReq';            //출동요청

export const DEST_CHO = ["ㄱ","ㄲ","ㄴ","ㄷ","ㄸ","ㄹ","ㅁ","ㅂ","ㅃ","ㅅ","ㅆ","ㅇ","ㅈ","ㅉ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ", "#"];
