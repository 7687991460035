
import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router";
import store from '../../store';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as LocalStore from '../../store/LocalStore';
import HeadTop from './header/HeadTop';
import Weather from './weather/Weather';
import Sales from './sales/Sales'
import ShopManage from './shopmanage/ShopManage';
import WorkerManage from './workermanage/WorkerManage'
import CustomerManage from './customermanage/CustomerManage';
import OneDepthTap from './header/OneDepthTap';
import HomeCardEdit from './HomeCardEdit';
import ShopAnalyze from './banner/ShopAnalyze';
import AlbaBanner from './banner/AlbaBanner';
import HomeCardEditPopups from '../popup/HomeCardEditPopup';
import FloatBanner from './banner/FloatBanner';
import { layerPopup } from '../../libs/common';
import CustomerManageUnSub from './customermanage/CustomerManageUnSub';
import ShopManageUnSub from './shopmanage/ShopManageUnSub';
import WorkerManageUnSub from './workermanage/WorkerManageUnSub';
import WeatherUnSub from './weather/WeatherUnSub';
import HomePopup from './popup/HomePopup';
import ShopAnalyzeUnSub from './banner/ShopAnalyzeUnSub';
import AlbaBannerUnSub from './banner/AlbaBannerUnSub';
import BizApi from '../../api/BizApi';
import OrderList from './order/OrderList';
import OrderListUnSub from './order/OrderListUnSub';

const Home = observer(({ location }) => {

    const { userData, userInfo } = store;

    useEffect(() => {
        async function fetchHomeInto() {
            await BizApi.homeInto(LocalStore.getStoreId());
        }
        fetchHomeInto();
    }, []);
    
    useEffect(() => {
        $("html, body").removeClass("noscroll");
        $('#wrap').addClass('bg_home');

        NativeBridge.sendUserActionLog('second', 'H');

        //통화메모 디폴트값 정의 (off)
        if( LocalStore.getCallMemo() === undefined || LocalStore.getCallMemo() === null ){
            userData.setCallMemo("1");
            LocalStore.setCallMemo("1")
        }

        const subscription = NativeBridge.eventObservable.subscribe(event => {
			console.log("----------------- from Native Event Home.js ------------------ action=>", event);
			switch (event.type) {                    
				case 'GET_ALARM':
					const result = event.param;
					console.log(" result: " , result);

                    if(result.status === '1') {
                        layerPopup.showCommon(
                            '#popup_pushalram_home',
                            null, 
                            '혜택 알림 설정', 
                            '이벤트, 가게 알림 사항을 앱 푸시 메시지로 받으시겠어요?',
                            [
                                function() {
                                    console.log("받지않기")
                                    pushAlramCheckApi("delete");
                                    userData.setAlram("1");
                                    LocalStore.setPushAlram("1");
                                    layerPopup.hide("#popup_pushalram_home");
                                },
                                function() { // 확인 버튼
                                    console.log("알림받기")
                                    pushAlramCheckApi("add")
                                    userData.setAlram("0");
                                    LocalStore.setPushAlram("0");
                                    layerPopup.hide("#popup_pushalram_home");
                                }
                            ]
                        )
                    } 
				break;
			default:
				break;
			}
		});

        //LocalStore에 있는 PushAlarm은 데이터값을 확인하였을때 1 또는 0을 설정한다.
        //그러므로 최초 로그인시 알람설정 팝업 노출을 위해 undefined이거나 null 일 경우를 체크하여 노출한다
        //단 임시사용자나 패밀리유저인 경우에는 제외한다.
        if( ( LocalStore.getPushAlram() === undefined || LocalStore.getPushAlram() === null) &&
            LocalStore.getIsTempUser() === false && LocalStore.getFamilyYn() === 'N') {
                // 홈화면 최초 진입 시 알람설정 팝업
                NativeBridge.getAlarm();
        }

        return () => {
            $('#wrap').removeClass('bg_home');  
            subscription.unsubscribe();
        }
    }, [])

    //푸쉬 알람 체크 API
    const pushAlramCheckApi = (checked) => {
        const pushData = NativeBridge.setAlarm(checked)
        console.log("네이티브 알람체크",pushData);
    }

    const ExternalPage = (urls=null) => {
        NativeBridge.externalCall(`${urls}`)
    }

    return (
        <>  
            <div className="content home">
                <div className="hd_fix_wrap">
                    <HeadTop />
                    <OneDepthTap />
                </div>
                <div className="fix_container">
                    <div className="home_wrapper">
                        { userData.dataset.sissicgaRegiYn === 'Y' ?
                            <OrderList />
                        : userData.dataset.sissicgaRegiYn === 'N' ?
                            <OrderListUnSub />
                        :
                            <></>
                        }
                        { (Array.isArray(userData.dataset.homecard) && userData.dataset.homecard.length > 0 && Array.isArray(userData.dataset.storeList) && userData.dataset.storeList.length > 0) ?
                            userData.dataset.homecard.map((item, index) => (
                                <>
                                    {
                                        (item.svcCd === "000001") ?
                                            <Sales key={item.svcCd} svcCardNm={item.svcCardNm} />
                                        :
                                        (item.svcCd === "000002" && item.svcRegiYn === 'Y') ? 
                                            <CustomerManage key={item.svcCd} svcCardNm={item.svcCardNm} />
                                        :
                                        (item.svcCd === "000002" && item.svcRegiYn === 'N') ? 
                                            <CustomerManageUnSub
                                                key={`UnSub_${item.svcCd}`}
                                                svcCardNm={item.svcCardNm} hposImge={item.hposImge} urlConn={item.urlConn} urlConnImge={item.urlConnImge} 
                                            />
                                        :
                                        (item.svcCd === "000003" && item.svcRegiYn === 'Y') ? 
                                            <ShopManage key={item.svcCd} svcCardNm={item.svcCardNm} />
                                        :
                                        (item.svcCd === "000003" && item.svcRegiYn === 'N') ? 
                                            <ShopManageUnSub
                                                key={`UnSub_${item.svcCd}`}
                                                svcCardNm={item.svcCardNm} hposImge={item.hposImge} urlConn={item.urlConn} urlConnImge={item.urlConnImge} 
                                            />
                                        :
                                        (item.svcCd === "000004" && item.svcRegiYn === 'Y') ?
                                            <WorkerManage key={item.svcCd} svcCardNm={item.svcCardNm} />
                                        :
                                        (item.svcCd === "000004" && item.svcRegiYn === 'N') ?
                                            <WorkerManageUnSub
                                                key={`UnSub_${item.svcCd}`}
                                                svcCardNm={item.svcCardNm} hposImge={item.hposImge} urlConn={item.urlConn} urlConnImge={item.urlConnImge} 
                                            />
                                        :
                                        (item.svcCd === "000005" && item.svcRegiYn === 'Y') ?
                                            <Weather key={item.svcCd} svcCardNm={item.svcCardNm} />
                                        :
                                        (item.svcCd === "000005" && item.svcRegiYn === 'N') ?
                                            <WeatherUnSub
                                                key={`UnSub_${item.svcCd}`}
                                                svcCardNm={item.svcCardNm} hposImge={item.hposImge} urlConn={item.urlConn} urlConnImge={item.urlConnImge}
                                            />
                                        :
                                        (item.svcCd === "000006" && item.svcRegiYn === 'Y') ?
                                            <AlbaBanner key={item.svcCd} />
                                        :
                                        (item.svcCd === "000006" && item.svcRegiYn === 'N') ?
                                            <AlbaBannerUnSub
                                                key={`UnSub_${item.svcCd}`}
                                                hposImge={item.hposImge} urlConn={item.urlConn} urlConnImge={item.urlConnImge}
                                            />
                                        :
                                        (item.svcCd === "000007" && item.svcRegiYn === 'Y') ?
                                            <ShopAnalyze key={item.svcCd} />
                                        :
                                        (item.svcCd === "000007" && item.svcRegiYn === 'N') ?
                                            <ShopAnalyzeUnSub
                                                key={`UnSub_${item.svcCd}`}
                                                hposImge={item.hposImge} urlConn={item.urlConn} urlConnImge={item.urlConnImge}
                                            />
                                        :
                                        <>
                                            <div className="content_box" key={`test${index}`}>
                                                <span className="cont_title">{item.svcCardNm}</span>
                                                <div className="sec_wrap unsubs_img">
                                                    <img src={`${item.hposImge}`} />
                                                    <button type="button" onClick={()=>ExternalPage(item.urlConn)}><img src={`${item.urlConnImge}`} /></button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            ))
                            :
                            <></>   
                        }
                        <HomeCardEdit />
                        <HomeCardEditPopups />
                        <Route component={FloatBanner} key="FloatBanner" />
                        <Route component={HomePopup} key="HomePopup" />
                    </div>
                </div>
            </div>
        </>
    )
});

export default Home;