import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';



const Login = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    useEffect(() => {       
        console.log("Login deviceInfo.dataset.ctn useEffect..");
        console.log(deviceInfo.dataset);
        // $(".main").addClass("nofoot");
        // $(".head").addClass("nohead");

        // return () => {
        //     $(".main").removeClass("nofoot");
        //     $(".head").removeClass("nohead");
        //   };
 
    }, [deviceInfo.dataset]);

    useEffect(() => {       
        const deviceInfo = LocalStore.getDeviceInfo();
        if(deviceInfo === null) {
            NativeBridge.getDeviceInfo();
        }        
        const userId = LocalStore.getUserId();
        if(userId) {
            const userPinCode = LocalStore.getPinCode();
            if(userPinCode) {
                history.replace("/pincode/check");
            } else {
                history.replace("/pincode/reg");
            }
        }
        $("html, body").removeClass("noscroll");
    }, []);
    

    async function goLogin() {
        NativeBridge.sendUserActionLog('first', 'L');
        // kmc pass
        // const userId = "user123";
        // LocalStore.setUserId(userId);
        // userInfo.setUserId(userId);
        // history.push("/pincode");

        userInfo.setIsTempUser(false);

        history.replace("/callkmc");

    }

    const tempUser = () => {
        NativeBridge.sendUserActionLog('first', 'T');
        const userId = process.env.REACT_APP_SOHO_API_TMP_USER_ID;
        const userNm = process.env.REACT_APP_SOHO_API_TMP_USER_NM;

        userInfo.setUserId(userId);
        userInfo.setOwnerId(userId);
        userInfo.setOwnerNm(userNm);
        userInfo.setIsTempUser();
        userInfo.setFamilyYn("N");

        /*
        const storeList = [
            {
                storeId : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_ID,
                storeNm : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_NM,
                lpzEntrNo:"",
                lpzNo:"",
                lzpEntrNo:"",
                camId: "",
                cam_device_sn_id:[],
                cams:[],
                
                
            }
        ];
        userData.setStoreList(storeList);
        userData.setStoreId(process.env.REACT_APP_SOHO_API_TMP_USER_STORE_ID);
        //userData.setCurrStoreInfo(storeList[0]);
        */                
        
        history.replace("/pincode/reg");

    }





    const joinUser = () => {
        NativeBridge.sendUserActionLog('first', 'N');
        console.log("join... .open..");
        const ret = NativeBridge.externalCall(process.env.REACT_APP_EXT_USER_REG_URL);
        // window.SohoInterface.sendEvent("{\"type\":\"GO_PAGE\",\"param\":{\"type\":\"O\", \"url\":\"https://www.google.com\"}}");
        // const ret = NativeBridge.goPage(process.env.REACT_APP_EXT_USER_REG_URL, "O");
    }

    return (
        <>
            <div id="wrap" className="intro_login">
                <div className = "intro_landing">
                    <div className="landing_wrap">
                        <div className="middle_box">
                            <h1>U+와 함께하는<br />모든 소상공인을<br />응원합니다!</h1>
                            <p>사장님 힘내세요!</p>
                            <span className="landing_bg blind">우리가게 서비스</span>
                            <div className="btn_box">
                                <div className="inner">
                                    <button type="button" className="btn_lg btn_point usetap" onClick={()=> goLogin()}>시작하기</button>
                                    <button type="button" className="btn_lg btn_line1 usetap" onClick={()=> tempUser()}>임시 사용</button>
                                    {/* 21.11.11 박지영선임 요청 
                                    <a href="#none" className="join_mb" onClick={joinUser}>U<sup>+</sup> 서비스가입</a>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
});
export default Login;