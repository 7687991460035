import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import { layerPopup, toast } from "../../libs/common";
import { getAddressInfo, getCurrentDateTimeString, getByteLength } from "../../common/common";
import { msgUI } from "../../libs/msg";
import $, { trim } from "jquery";
import SohoApi from "../../api/SohoApi";
import CentrexApi from "../../api/CentrexApi";
import { _allowStateChangesInsideComputed } from "mobx";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from "../../store/LocalStore";
import {checkStoreName} from "../../common/common"
import { transformAddressDrawData } from "../../common/common";
    
const MessageWritePopup = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //console.log(userList);

    const [state, setState] = useState([]);
    const [destNumAddrInfo, setDestNumAddrInfo] =  useState({});
    const [receiveList, setReceiveList] =  useState([]);

    const [srchWords, setSrchWords] =  useState("");
    const [defaultMessageTemplage, setDefaultMessageTemplage] =  useState("");
    const [srchPhonebookList , setSrchPhonebookList] = useState([]);
    const [check, setCheck] = useState("1"); //백키 감지 메시지작성 초기화를 위함
    const [phoneNum, setPhoneNum] = useState([]);
    const [msgTitle, setMsgTitle] = useState("");
    const [check2, setCheck2] = useState("0");
    const [searchResultData, setSearchResultData] = useState([]);

    useEffect(() => {
        //setSrchPhonebookList(userData.dataset.pblistForCentrix.reverse())
        setCheck("1")
        //setSrchPhonebookList(transformAddressDrawData(userData.dataset.pblistForCentrix))
        console.log("MessageWritePopup useEffect");
        FocusBlur()
    }, [])
    useEffect(()=>{
        
        if(check === "3"){
            initUI();
        }

    },[check])

   

    useEffect(() => {
        console.log("변화하감지");
        if(receiveList.length < 1){
            $(".label_text").attr('class','label_text');
        }else{
            $(".label_text").attr('class','label_text hidden');
        }
    },[receiveList])

    useEffect(() => {
        setSearchResultData(userData.dataset.pblistForCentrix)
    },[userData.dataset.pblistForCentrix])

    useEffect(() => {
        setSrchPhonebookList(transformAddressDrawData(searchResultData))
    },[searchResultData])
    /**
     * CASE1) 팝업 요청시 전화번호 선택 후 전달된 경우(userData.dataset.messagePopupCellNum) , 
     * CASE2) 메시지내용 설정 후 전달된 경우(userData.dataset.messagePopupConts)
     */
    useEffect(() => {
        setCheck("2")
        //팝업 요청시 전화번호 전달한 경우 호출
        console.log("change userData.dataset.messagePopupConts useEffect", userData.dataset.messagePopupConts);
        if ( userData.dataset.messagePopupConts !== null && userData.dataset.messagePopupConts !== ''){
            $('#conts_txt').val(userData.dataset.messagePopupConts);
        } else {
            $('#conts_txt').val("");
        }

        //팝업 요청시 전화번호 전달한 경우 호출
        console.log("change userData.dataset.messagePopupCellNum useEffect => ", userData.dataset.messagePopupCellNum);
        let passPopData = userData.dataset.messagePopupCellNum;
        if ( passPopData !== null && passPopData.phone !== undefined && passPopData.phone !== null){
            //setDestNumber(passPopData.phone );
            //setDestNumAddrInfo(getAddressInfo(destNumber));

            let _destNumAddrInfo = getAddressInfo(passPopData.phone );
            setReceiveList([
                { 
                    type : 'N' // 'G' : 그룹  | 'P' 번호 o r Phonebook
                    ,idx : ( 'N' + _destNumAddrInfo.no + '_' + _destNumAddrInfo.phone )
                    ,name : _destNumAddrInfo.name
                    ,items : [ 
                        _destNumAddrInfo.phone
                    ]
                }
            ]);

        }else{
            setReceiveList([]);
        }
        
    }, [userData.dataset.messagePopupConts, userData.dataset.messagePopupCellNum]);
    

    useEffect(() => {
        //전화번호 검색, 그룹검색처리 ( setSendMsgAddrPopupToogle )
        console.log("change sendMsgAddrPopupToggle useEffect", userData.dataset.sendMsgAddrPopupToggle);

        let addReceiveList = [];

        if ( !userData.dataset.sendMsgAddrPopupToggle ){
            if ( Array.isArray(userData.dataset.popSelectedSendMsgPhone) && userData.dataset.popSelectedSendMsgPhone.length > 0  ){
                addReceiveList = userData.dataset.popSelectedSendMsgPhone;
            }
    
            console.log("change sendMsgAddrPopupToggle popSelectedSendMsgGroup useEffect", userData.dataset.popSelectedSendMsgGroup);
            if ( Array.isArray(userData.dataset.popSelectedSendMsgGroup) && userData.dataset.popSelectedSendMsgGroup.length > 0 ){
                if ( Array.isArray(addReceiveList) && addReceiveList.length > 0  ){
                    addReceiveList = Array.from(addReceiveList).concat(userData.dataset.popSelectedSendMsgGroup);
                }else{
                    addReceiveList = userData.dataset.popSelectedSendMsgGroup;
                }
            }
    
            if ( receiveList !== null && receiveList.length > 0 ){
                setReceiveList(receiveList => Array.from(receiveList).concat(addReceiveList));
            }else {
                setReceiveList(addReceiveList);
            }
        }

    }, [ userData.dataset.sendMsgAddrPopupToggle ]);

    /**
     * 화면초기화
     */
    const initUI = () => {
        userData.setMessagePopupConts("");
        userData.setMessagePopupCellNum({});
        setReceiveList([]);
        $("#conts_txt").val('');
        $("#ipSrh").val('');
    }


    const FocusBlur = () => {
        const msgContactSrh = document.getElementById('msg_contact_srh');
        const textWidth = document.getElementById('textWidth');
        const ipSrh = document.getElementById('ipSrh');
        
        msgContactSrh.addEventListener("focus", () => msgContactSrh.classList.add('focused'), true);
        msgContactSrh.addEventListener("blur", () => msgContactSrh.classList.remove('focused'), true);
        ipSrh.addEventListener('input', function resize() {
            textWidth.textContent = ipSrh.value;
            ipSrh.style.width = textWidth.offsetWidth / 10 + 0.1 + "rem"
        });
    }

    const ClickRemoveReceiveData = (e, _item) => {
        // "event.stopPropagation()"
        console.log("ClickRemoveReceiveData:", _item);
        //userData.setPopSelectedSendMsgPhone();
        setReceiveList( receiveList => receiveList.filter( (targetItem ) => {
             console.log(_item, targetItem);
            if ( targetItem.type === _item.type && targetItem.name === _item.name ){
                console.log("remind=>1", targetItem);
            }else{
                console.log("remind=>2", targetItem);
                return targetItem;
            }
        }));
         //userData.setPopSelectedSendMsgPhone(receiveList);
         console.log("조회..", userData.dataset.popSelectedSendMsgPhone)
    }
    console.log("조회2", receiveList);

    const ClickClosePopup = (e) => {
        //초기화처리 ( 닫을때)
        initializeUI();
        userData.setPopFlag(getCurrentDateTimeString());
        layerPopup.hide("#popup_msgwrite");
    }

    const initializeUI = () => {
        setReceiveList([]);
        $("#conts_txt").val('');
        $("#ipSrh").val('');
        if ( !$('ul.recipient_list').hasClass('hidden') ) $('ul.recipient_list').addClass('hidden');
    }

    const ClickPopupAddress = (e) => {

        userData.setPopSelectedSendMsgGroup([]);
        userData.setPopSelectedSendMsgPhone([]);
        //userData.dataset.sendMsgAddrPopupToggle
        userData.setSendMsgAddrPopupToggle(true)
        //userData.setSendMsgAddrPopupToogle(true);       //팝업 오픈 처리
        layerPopup.show("#popup_msgcontactgroup");      //MessageContactGroupAddPopup.js
        e.preventDefault();
        setTimeout(()=>{
            $("#idx_input_name_number").trigger("focus");
        }, 500);

    }

    /*
    useEffect(() => {
        //최종 연락처 및 그룹 선택 후 데이터 저장처리
        console.log('change userData.dataset.sendMsgAddrPopupToogle =>', userData.dataset.sendMsgAddrPopupToogle);
        //화면에 노출
        console.log("타는지 확인", userData.dataset.popSelectedSendMsgPhone)
        //화면닫히는 경우에 추가되는 프로세스
        if ( !userData.dataset.sendMsgAddrPopupToogle ) {
            console.log("타는지 확인22", userData.dataset.popSelectedSendMsgPhone)
            let tmpSelctedItem = [];
            if ( userData.dataset.popSelectedSendMsgGroup !== null && userData.dataset.popSelectedSendMsgGroup.length > 0 ){
                tmpSelctedItem = userData.dataset.popSelectedSendMsgGroup;
            }

            if ( userData.dataset.popSelectedSendMsgPhone !== null && userData.dataset.popSelectedSendMsgPhone.length > 0 ){
                tmpSelctedItem = Array.from(tmpSelctedItem).concat(userData.dataset.popSelectedSendMsgPhone);
            }

            console.log("merge=>", tmpSelctedItem);
            if ( tmpSelctedItem !== null && tmpSelctedItem.length > 0 ){
                setReceiveList(receiveList => Array.from(receiveList).concat(tmpSelctedItem));
            }
        }
        
    // }, [userData.dataset.sendMsgAddrPopupToggle]);
    }, [userData.dataset.popSelectedSendMsgPhone]);
    */


    const KeyPressSearch = (e) => {
        let keyworkds = e.target.value;
		console.log('KeyPressSearch =>' + keyworkds);
		setSrchWords(keyworkds);
    }
    
    const FocusSrchInput = (e) => {
        setCheck2("2");
        $('ul.recipient_list').removeClass('hidden');
        
        if(check2 !== "2"){
        setSrchPhonebookList(
            (transformAddressDrawData(userData.dataset.pblistForCentrix)).filter((targetItem) => {
                if ( srchWords === '' || (targetItem.name)?.includes(srchWords) || (targetItem.phone)?.includes(srchWords) ){
                    return targetItem;
                }	
            }
        ));
        }else{
            setSrchPhonebookList(
                (transformAddressDrawData(userData.dataset.pblistForCentrix)).filter((targetItem) => {
                    if ( srchWords === '' || (targetItem.name)?.includes(srchWords) || (targetItem.phone)?.includes(srchWords) ){
                        return targetItem;
                    }	
                }
            ));
        }
    }

    const BlurSrchInput = (e) => {
        $('ul.recipient_list').addClass('hidden');
    }

    const ClickSelectItemOnPage = (e, _item) => {
        console.log('ClickSelectItemOnPage=>', _item);
        setReceiveList(receiveList => Array.from(receiveList).concat([
            { 
                type : 'N', // 'G' : 그룹  | 'N' 번호 or Phonebook
                name : _item.name ,
                items : [  _item.phone ]
            }
        ]));
        /*
        if ( receiveList !== null && receiveList.length > 0 ){
            setReceiveList(receiveList => Array.from(receiveList).concat([{phone : _item.phone, name : _item.name, no : _item.no||'' }]));
        }
        */
        setSrchWords("");   //초기화
        $('#ipSrh').val('');
        setTimeout(() => {
            //딜레이없이 실행시 안닫히는 효과 발생
            $('ul.recipient_list').addClass('hidden');
        }, 100);
        
    }

   // console.log("transData", transData);
    useEffect(() => {
        console.log('change srchWords =>', srchWords);

        
        //전화번호 검색
        if ( userData.dataset.pblistForCentrix !== null && userData.dataset.pblistForCentrix.length > 0 ){
            setSearchResultData(
                (userData.dataset.pblistForCentrix).filter((targetItem) => {
                    if ( srchWords === '' || (targetItem.name)?.includes(srchWords) || (targetItem.phone)?.includes(srchWords) ){
                        return targetItem;
                    }	
                }
            ));
        }
    }, [srchWords])

    // console.log("asd", transformAddressDrawData(userData.dataset.pblistForCentrix).map((srchItem) => (srchItem.items)).filter((targetItem) => {
    //     return targetItem;
    // }))

    /*
    useEffect(() => {
        console.log('change srchPhonebookList =>', srchPhonebookList);
        //화면에 노출
        if( srchPhonebookList != null && srchPhonebookList.length > 0 ){

        }
    }, [srchPhonebookList]);
    */

    const FocusTextArea = async (e) => {
        $('ul.recipient_list').addClass('hidden');
    };

    const ClickRegContMsg = async (e) => {
        let outString = $('#conts_txt').val().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        let outString2 = outString.replace(/\n/gi,'');
        NativeBridge.sendUserActionLog('message', 'UM', '', outString2);
        if  ( ValidTemlate() ) {
            $('#template_title').val('');
            layerPopup.show("#popup_twobutton_input");
        }
    };

    const ClickTemplatAction = async(e) => {

        if ( ValidTemlate(2) ) {

            const ret = await SohoApi.saveContMsg({
                storeId : userData.dataset.currstoreInfo.storeId
                ,msgTit : $('#template_title').val()    
                ,msgCntn : $('#conts_txt').val()
            });
    
            if ( ret.retCode === '0000'){
                let outString = $('#conts_txt').val().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                let outString2 = outString.replace(/\n/gi,'');
                userData.setPopFlag(getCurrentDateTimeString());
                NativeBridge.sendUserActionLog('detail', 'D078', '', `제목 : ${$('#template_title').val()}-내용 : ${outString2}`);
                toast("자주 쓰는 문자 메시지로 저장하였습니다.");
                layerPopup.hide("#popup_twobutton_input");
            }else if(ret.retCode === '2203'){
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "최대 등록 개수 초과", "쵀대 20개까지 등록할 수 있습니다.", [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton");
                    }
                ]);
            }else {
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "등록이 실패하였습니다.", [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton");
                    }
                ]);
    
            }

        }
    };

    const ValidTemlate = (type = 1) => {

        const _message = $('#conts_txt').val();
        console.log('ClickTempateAction=>', _message);
        if ( type === 1) {

            if ( _message.length === 0){
                //메시지 내용을 입력하세요.
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "자주 쓰는 문자메시지로 저장 할 내용을 입력해 주세요.", [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton");
                    }
                ]);

                return false;
            }
            
            if ( getByteLength(_message) > 720 ){
                
                //메시지 내용을 입력하세요.
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "메시지 내용은 720byte를 초과 할 수 없습니다.", [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton");
                    }
                ]);

                return false;
            }
            

       
        } else {

            if ( $('#template_title').val() === ''){
                //자주쓰는 메시지 저장열기
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "자주쓰는메시지 제목을 입력하세요.", [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton");
                    }
                ]);

                return false;
            }

            if($('#template_title').val().length > 20){
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "글자 수 초과", "20자 이내로 입력해주세요.", [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton");
                    }
                ]);
                return false
            }
    
            /*
            const checkName = checkStoreName($('#template_title').val());
            if(checkName !== true){
                layerPopup.showCommon(
                    '#popup_onetitlemsgbutton', 
                    null, 
                    '특수 문자 입력 제한', 
                    '특수 문자는 입력할 수 없습니다.<br />영문, 숫자, 한글만 입력해주세요.'
                );
                return false;
            }
            */
        }

        return true;

    }

    const chkPhoneLength = (_str) => {
        if ( _str !== undefined &&  _str !== null && _str !== '' ) {
            let _dest = _str.trim().replace("-","");
            if ( _dest.length < 9 || _dest.length > 11 ){
                return false;
            }
        }else {
            return false;
        }
        return true;
    }

    const ClickSendSMS = async (e) => {
        userData.setMsgCheck("1")
        $('ul.recipient_list').addClass('hidden');
        console.log("zcz",$(".msg_tag").val())
        const _title = $("#ipSrh").val();
        const _message = $("#conts_txt").val();
        const _phoneNum = [];

        let chkValFlag = true;
        for(let i = 0; i< receiveList.length; i++){
            if ( receiveList[i].type === 'G'){
                receiveList[i].items.map((targetItem, idx) => {
                    _phoneNum.push(targetItem);
                    if ( !chkPhoneLength(targetItem) ) {
                        chkValFlag = false;
                    }
                });
            }else{
                _phoneNum.push(receiveList[i].items.join());
                if ( !chkPhoneLength(receiveList[i].items.join()) ) {
                    chkValFlag = false;
                }
            }
        }

        if ( !chkValFlag ) {
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "연락처는 9~11자리로 입력해주세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);

            return false;
        }

        if ( _phoneNum.length > 10 ){
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "최대 10명까지 발송가능합니다.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);

            return false;
        }

        if(_phoneNum.length < 1 ){
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "입력 정보 확인", "받는 사람이 지정되지 않았습니다.<br/>문자를 받으실 분의 연락처나 그룹을 등록하세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
            return false;
        }
        
        if ( _message.length < 1){
            //메시지 내용을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "보내실 메시지 내용을 입력하세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);

            return false;
        }
        
        //TODO : UI규격서 문구 확인필요
        //LMS 720 byte
        console.log("확인",getByteLength(_message));
        if ( getByteLength(_message) > 720 ){
            
            //메시지 내용을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "메시지 내용은 720byte를 초과 할 수 없습니다.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);

            return false;
        }

        var sendSMSParam = {
            destnumber : _phoneNum.join(',')
            ,smsmsg : _message
        };

        const result = await CentrexApi.sendSms(sendSMSParam)
        .catch((e) => {

            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(인터넷 전화)", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);   
        })
        var msgTitle = "알림";
        var msgTitle2 = '문자 발송 실패'
        var resultMsg = '발송이 완료되었습니다.';
        //console.log("ㅋㅋ",result);
        
        if (result.data?.SVC_RT === '0000'){
            //메시지 내용을 입력하세요.
            let outString = _message.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            let outString2 = outString.replace(/\n/gi,'');
            NativeBridge.sendUserActionLog('detail', 'D080', "", outString2);
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, msgTitle, "발송이 완료되었습니다.", [
                function() {

                    userData.setMsgCheck("1")
                    $("textarea[name=message]").text('');
            
                    //데이터 초기화
                    $("#conts_txt").val('');
                    setReceiveList([]);
                    $("#ipSrh").val('');
                    setSrchWords('');

                    layerPopup.hide("#popup_onetitlemsgbutton",  layerPopup.hide("#popup_msgwrite") );

                }
            ]); 
        } else {
            //메시지 내용을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, msgTitle2, "문자발송이 실패하였습니다.<br/>다시 시도해주세요.<br/>(이모티콘 발송 불가)", [
                function() {
                    //userData.setMsgCheck("1")
                    layerPopup.hide("#popup_onetitlemsgbutton");

                }
            ]); 
        }

    };

    const GoBack = (e) => {
        NativeBridge.sendUserActionLog('detail', 'D081');
        setCheck("3");
        userData.setPopFlag(getCurrentDateTimeString());
        $('ul.recipient_list').addClass('hidden');
        layerPopup.hide("#popup_msgwrite");
    }

    //  특수문자 입력제한
    const msgTitleManage = (e) => {

       setMsgTitle(e.target.value);
        
    }

   //console.log("z1z",srchPhonebookList)
   const clear = () => {
    NativeBridge.sendUserActionLog('detail', 'D079');
    setMsgTitle("")
    layerPopup.hide("#popup_twobutton_input")
   }

   const ClickBlur = (e) => {
       if ( e.target.id !== 'ipSrh') {
           setTimeout(() => {
                //딜레이없이 실행시 안닫히는 효과 발생
                $('ul.recipient_list').addClass('hidden');
            }, 100);
       }
   }

   const ClickPopupReset = (e) => {
        $('#template_title').val('');
    }
    console.log("ㅋㅌㅊㅋㅌㅊ", srchPhonebookList)
  return (
    <>
	<div className="modal fadeInUp" id="popup_msgwrite">
            <div className="dim" onClick={() => clear()}></div>
            <div className="modal_inner modal_up full_h">
                <button type="button" className="pop_close usetap blind active" onClick={(e)=> GoBack(e)}>닫기</button>
                <span className="modal_title">메시지 작성</span>
                <div className="modal_msg_write modal_fixed_bottom" >
                    <div className="recipient_wrap" >
                        <label htmlFor="ipSrh" id="msg_contact_srh" className="msg_contact_srh">
                            {
                                receiveList !== null && receiveList.length > 0 ? 
                                receiveList.map((receiveItem, idx) => (
                                    <>
                                        <span className="msg_tag">{receiveItem.name}<button type="button" className="btn_group_del blind" onClick={(e) => ClickRemoveReceiveData(e, receiveItem)}>삭제</button></span>
                                    </>
                                ))
                                : <></>
                            }
                            <span id="msg_label" className="label_text">받는사람 / 보낼번호 / 그룹</span> {/* !-- msg_tag가 0 이상일 때 hidden 클래스 추가 --> */}
                            <span id="textWidth" className="text_width"></span>
                            <input id="ipSrh" type="text"  className="ip_contact_srh" onKeyUp={(e) => KeyPressSearch(e)} style={{ width: '0.1rem' }} onClick={(e) => FocusSrchInput(e)}  />
                            <span className="ip_recipient usetap" onClick={(e)=> ClickPopupAddress(e)}>받는사람/그룹 추가</span>
                            <ul className="recipient_list hidden">
                                {
                                    srchPhonebookList !== null && srchPhonebookList.length > 0 ?
                                    srchPhonebookList.map((srchItem, idx) => (
                                        <>
                                        {
                                            srchItem?.items?.map((addressItem, index) => (
                                                <>
                                                    <li key={"srchItem" + idx} className={(addressItem.name.search(srchWords) || addressItem.phone.search(srchWords))  !== -1 ? 'usetab' : 'usetab active'} onClick={(e) => ClickSelectItemOnPage(e, addressItem)}>
                                                        <span className="i_name">{addressItem.name}{ /* 고<span className="i_match">길동</span> */}</span>
                                                        <span className="i_num">{addressItem.phone}</span>
                                                    </li>
                                                </>
                                            ))
                                        }
                                    </>
                                )) :
                                <></>
                                }
                            </ul>
                        </label>
                        {/* srchItem.name.includes(srchWords) */}
                    </div>
                    <div className="textarea_wrap">
                        <textarea placeholder="내용을 입력하세요." id="conts_txt" onFocus={(e) => FocusTextArea(e)}></textarea>
                    </div>
                </div>
            </div>
            <div className="btn_box fixed">
                <div className="inner">
                    {userInfo.dataset.isTempUser === false ?
                    <>
                    <button type="button" className="btn_half7 btn_point usetap" onClick={(e) => ClickRegContMsg(e)}>자주 쓰는 문자메시지로 저장</button>
                    <button type="button" className="btn_half3 btn_line2 usetap" onClick={(e) => ClickSendSMS(e)}>보내기</button>
                    </>
                    :  
                    <button type="button" className="btn_lg btn_line2 usetap" onClick={(e) => ClickSendSMS(e)}>보내기</button>
                    // <button type="button" className="btn_half3 btn_point usetap" >보내기</button>
                    }
                </div>
            </div>
        </div>

        <div className="modal fadeIn" id="popup_twobutton_input">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content modal_input">
                    <div className="modal_hd">자주 쓰는 문자메시지로 저장</div>
                    <div className="modal_bd">
                    <div className="input_box style2">
                        <input type="text" id="template_title" value={msgTitle} onChange={(e)=>msgTitleManage(e)} placeholder="문자메시지 제목 입력"/>
                        <span className="ip_clear hidden usetap" onClick={(e) => ClickPopupReset()}>초기화</span>
                    </div>
                    </div>
                    <div className="modal_ft">
                    <button type="button" className="btn_half usetap" onClick={(e) => clear()}><span>취소</span></button>
                    <button type="button" className="btn_half usetap" onClick={(e) => ClickTemplatAction(e)}><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
});
export default MessageWritePopup;