import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as NativeBridge from '../../../utils/NativeBridge';
import WorkerManage from './WorkerManage';
import * as LocalStore from '../../../store/LocalStore';


const WorkerManageUnSub = observer(({ svcCardNm, hposImge, urlConn, urlConnImge }) => {

    const { userInfo, userData } = store;

    const ShopWorkerManage = () => {
        NativeBridge.sendUserActionLog('menu', 'H008');
		userData.setShopMainTabList('workermanage');
	}

    const ExternalPage = (urls) => {
        NativeBridge.sendUserActionLog('menu', 'H009');
        NativeBridge.externalCall(`${urls}`)
    }


    return (
        <>
        { (!userInfo.dataset.myservice.staffMng && LocalStore.getIsTempUser()) ||  !LocalStore.getIsTempUser() ?
            <div className="content_box">
                <Link to={`/shop/workermanage`} className="sec_link usetap" onClick={()=> ShopWorkerManage()}>
                    <i className="ico_dark_more"></i>더보기
                </Link>
                <span className="cont_title">{svcCardNm}</span>
                <div className="sec_wrap unsubs_img">
                    <img src={`${hposImge}`} />
                    <button type="button" onClick={()=>ExternalPage(urlConn)}><img src={`${urlConnImge}`} /></button>
                </div>
            </div>
        :
            <WorkerManage svcCardNm={svcCardNm} />
        }
        </>
    )
});

export default WorkerManageUnSub;