import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { action, useStrict } from 'mobx'
import { toast } from "../../../../libs/common";
import * as LocalStore from "../../../../store/LocalStore";
import { Link } from "react-router-dom";

const CallForwarding = observer(({history}) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();
  console.log(userList);

  const [stopForward, setStopForward] = useState(null);
  const [callFowarding, setCallFowarding] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [callFowardingData, setCallFowardingData] = useState(null);
  const [saveControl, setSaveControl] = useState (true);
  //const [check, setCheck] = useState(userData.dataset.fowardingData === "F" ? true : false );

useEffect(() => {
    console.log("CallForwarding default useEffect..");
    getForwardType();
    if($('#wrap').hasClass('gnb_bottom')) {
      $('#wrap').removeClass('gnb_bottom')
      $('#wrap').addClass('fixed_bottom');
    }     
    return () => {
      if($('#wrap').hasClass('fixed_bottom')) {
        $('#wrap').removeClass('fixed_bottom');
        $('#wrap').addClass('gnb_bottom');
      }
    }
}, []);

useEffect(() => {
    if(callFowardingData?.FORWARD_USE === "F")
    {
      $(".ip_clear").show();
      $("#shopName").attr('readOnly',true);
    }
    else{
      $(".ip_clear").hide();
    }
},[callFowardingData])

const centrexIdCheck = () => {
    const centrexId = LocalStore.getStoreInetNo();
    const tempCentrexPass = LocalStore.getTempInetPass();
    return [centrexId ,tempCentrexPass]
}
  
const [resultNum, resultPass] = centrexIdCheck();

//착신전환 설정
const callFowardingHandleClick = () => { 
    if(inputValue.length === 13 ){
      let destnumber = inputValue.replace(/\-/g,'');
      setCallFowarding(true);  
      getSetForward(destnumber); 
    } else{
      toast("착신받을 번호를 등록 해주세요.");
    }
}

//착신전환 번호 삭제
const removeBtnHandleClick = () => {
    if(callFowardingData?.FORWARD_USE === "F"){
        setInputValue([]);
        setCallFowarding(false);
        getStopForward();
    }
    else{
      setInputValue([])
      setSaveControl(true);
    }
}
  
const saveBtnHandleClick = () => {
  if(inputValue.length === 11){
    $(".ip_clear").show();
    setInputValue(inputValue.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    setSaveControl(false);
    NativeBridge.sendUserActionLog('detail', 'D035', '', inputValue.replace(/\-/g,''));
  } else{
    toast("휴대폰 번호를 확인해주세요.");
  }
}

//착신전환 설정 API
const getSetForward = async (num) => {
  NativeBridge.getLoadingStart();
  const setForward = await CentrexApi.getSetForward({
    destnumber : num
  });
  if(setForward.data.SVC_RT === "0000"){
    NativeBridge.getLodingEnd();
    userData.setCallFowarding("F");
    getForwardType();
    //toast("휴대폰 번호를 확인해주세요.");
    toast("등록");
    NativeBridge.sendUserActionLog('detail', 'D044', '', 'SET');
  }else {
    NativeBridge.getLodingEnd();
    toast("착신전환 등록이 실패했습니다.")
  }
  
}

//착신전환 삭제 API
const getStopForward = async ()=> {
  NativeBridge.getLoadingStart();
  const stoppForward = await CentrexApi.getStopForward({});
  setStopForward(stoppForward);

  if(stoppForward.data.SVC_RT === "0000"){
    NativeBridge.getLodingEnd();
    userData.setCallFowarding("N");
    setInputValue([]);
    setCallFowarding(false);
    getForwardType();
    setSaveControl(true);
    $(".ip_clear").hide();
    $("#shopName").removeAttr('readOnly');

    toast("착신 번호를 삭제해 착신전환이 해제되었습니다.");
    NativeBridge.sendUserActionLog('detail', 'D044', '', 'UNSET');
  }else {
    NativeBridge.getLodingEnd();
    toast("착신전환 해제가 실패했습니다.")
  }
}

//착신전환 조회 API
const getForwardType = async () => {
  const getForwardTypeData = await CentrexApi.getForwardType({})
  const forwardData = getForwardTypeData?.data.DATAS
  setCallFowardingData(forwardData);
}

  //핸드폰 유효성 검사
const checkPhoneNum = (e) => {
  let regExp4 =  /^[0-9]*$/g;

    if(regExp4.test(e.target.value)){
      if(e.target.value.length > 11){
        toast("최대 11자리까지 입력 가능합니다.")
      }
      setInputValue(e.target.value)
    }
    else{
      toast("번호를 잘못 입력했습니다. -없이 숫자만 입력해 주세요.")
    }
}

const goBackPage = () => {
  history.goBack();
}

  return (
    <>
			<div className="content store">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>착신설정</h2>
					</div>
				</div>
				<div className="sec_wrap">
					<div className="inner">
						<div className="chk_setting">
							<div className="checkbox style3">
								<span className="chk_title">착신전환</span>
								<div className="check_wrap">
                  {
                    userData.dataset.fowardingData === "F"   
                    ? <input className="checkbox" type="checkbox" name="call_fowarding" checked={true} onChange={()=>removeBtnHandleClick()} />
                    : <input className="checkbox" type="checkbox" name="call_fowarding"  checked={callFowarding} onChange={()=>callFowardingHandleClick()} />
                  }
                    <div className="check_bg">
                      <div className="check_bg_theme"></div>
                    </div>
									<div className="check_dot"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sec_wrap">
					<div className="inner">
						<span className="title">착신번호</span>
						<div className="input_box style2">
              {callFowardingData === null 
                ? <></>
                : callFowardingData?.FORWARD_USE === 'F' 
                    ? <input type="text"  id="shopName" maxLength="" placeholder="휴대폰 번호를 입력해주세요." onChange={(e)=>checkPhoneNum(e)} value={(callFowardingData?.FORWARD_DATA).toString().replace(/(\d{3})(\d{4})(\d{4})/g,'$1-$2-$3')} /> 
                    : <input type="text"  id="shopName"  maxLength="" placeholder="휴대폰 번호를 입력해주세요." onChange={(e)=>checkPhoneNum(e)} value={inputValue || ""} />
              }
              <span className="ip_clear hidden usetap" onClick={()=>removeBtnHandleClick()} >삭제</span>
						</div>
					</div>
				</div>
				<div className="btn_box fixed">
					<div className="inner">
            {callFowardingData === null 
              ? <></>
              : callFowardingData?.FORWARD_USE === 'F' || saveControl === false 
                  ?  <></> 
                  : <button type="button" className="btn_lg btn_point usetap"  onClick={()=>saveBtnHandleClick()} >등록</button>
            }
					</div>
				</div>
			</div>
    </>
);
});
export default CallForwarding;