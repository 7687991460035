   import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import { observer } from 'mobx-react';
import store from '../../../store';
import { Swiper, SwiperSlide } from 'swiper/react';
import SohoApi from '../../../api/SohoApi';
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";
import { bnPopup } from '../../../libs/common';
import * as CONSTS from '../../../const/index'
import * as NativeBridge from '../../../utils/NativeBridge';

SwiperCore.use([ Pagination, Scrollbar ]);

const FloatBanner = observer(({ history }) => {
    const { userInfo, userData } = store;

    const [bannerListData, setBannerListData] = useState([])

    useEffect( () => {
        async function fetchBannerList() {
            await BannerList();
        }
        fetchBannerList()
    }, [])

    useEffect(() => {
        if(typeof(bannerListData) !== 'undefined' && bannerListData !== null && bannerListData?.length > 0 && 
        userData.dataset.homeFloatBannerDown === false) {
            bnPopup.show('#modal_banner');
        }

        if(bannerListData.length > 0) {
            if(bannerListData.length <= 1) {
                $('#modal_banner .banner_wrap .i_pagination').addClass('i_disabled');	
            } else {
                $('#modal_banner .banner_wrap .i_pagination').removeClass('i_disabled');
            }
        }
    }, [bannerListData])

    const BannerList = async() => {
        try {
            await SohoApi.getHomeBannerList({}).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setBannerListData(rst.data.bannerList);
                    console.log('배너 조회', rst.data.bannerList)
                }
            }).catch((e) => {
                console.log(e)
            })
        } catch (e) {
            console.log(e)
        }

    }

    const homeFloatDown = () => {
        userData.setFloatPopupDown(true);
        bnPopup.hide('#modal_banner');
    }

    const goPopupNoticePage = (notiId, isTempUser, familyYn) => {
        bnPopup.hide('#modal_banner');
        NativeBridge.sendUserActionLog('menu', 'H020', '', notiId);

        setTimeout(()=>{
            let goLink = '';
            if(isTempUser === false) {
                goLink = `/my/mysetting/cs/notice/${notiId}`;
            } else if(isTempUser === true || familyYn ==='Y') {
                goLink = `/my/mysetting/cs/familynotice/${notiId}`;
            } else {
                console.log("N/A");
            }
            if(goLink) {
                history.push(goLink);
            }           
        }, 500);           
    }

    const goPopupBenePage = (catgId, beneId) => {
        bnPopup.hide('#modal_banner');
        NativeBridge.sendUserActionLog('menu', 'H020', '', beneId);

        setTimeout(() => {
            userData.setBnfCateId(catgId);
            userData.setBnfId(beneId);
            history.push("/ceocommunity");
        }, 500);
    }    


    return (
        <>
            <div className="modal_banner" id="modal_banner">
                <div className="banner_wrap">
                    <Swiper
                        autoHeight={true}
                        className="bn_rolling"
                        pagination={{
                            el: '.i_pagination'
                        }}
                        slidesPerView="auto"
                        observeParents
                        observer
                        loop={false}>
                            { (typeof(bannerListData) !== 'undefined' && bannerListData !== null && bannerListData.length > 0 ) ?
                                bannerListData.map((item, index) => (
                                <SwiperSlide key={`float_${index}`}>
                                    <button type="button" className="pop_close btn_small_x usetap blind" onClick={()=> homeFloatDown()}>닫기</button>
                                    {item.linkType==='N' ?
                                        <span className="bn_cont" onClick={()=>goPopupNoticePage(item.linkData.notiId, userInfo.dataset.isTempUser, userInfo.dataset.familyYn)}>{item.tit}</span>
                                    : item.linkType==='B' ?
                                        <span className="bn_cont" onClick={()=>goPopupBenePage(item.linkData.catgId, item.linkData.beneId)}>{item.tit}</span>
                                    : 
                                        <span className="bn_cont">{item.tit}</span>
                                    }
                                </SwiperSlide>
                                ))
                            :
                                <></>
                            }
                        <div className="i_pagination"></div>
                    </Swiper>
                </div>
            </div>
        </>
    )
});

export default FloatBanner;