import CryptoJS from 'crypto-js';

export const aesEncrypt = (data) =>  {
    // console.log("process.env.REACT_APP_CRYPT_KEY: " , process.env.REACT_APP_CRYPT_KEY);
    const key = process.env.REACT_APP_CRYPT_KEY;
    const cipher = CryptoJS.AES.encrypt(data, key, {
        iv: CryptoJS.SHA256(key).toString().substring(0,31),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    return cipher.toString();
};
export const aesDecrypt = (data) =>  {
    const key = process.env.REACT_APP_CRYPT_KEY;
    const cipher = CryptoJS.AES.decrypt(data, key, {
        iv: CryptoJS.SHA256(key).toString().substring(0,31),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    return cipher.toString(CryptoJS.enc.Utf8);
};
export const cctvPwEncrypt = (pwd) => {
    let hash = CryptoJS.SHA512(pwd).toString();
    hash = CryptoJS.enc.Hex.parse(hash);
    hash = CryptoJS.enc.Base64.stringify(hash);
    return hash;
}
export const centrexPwEncrypt = (pwd) => {
    return CryptoJS.SHA512(pwd).toString();
}