import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../utils/NativeBridge";
import CentrexApi from "../../../api/CentrexApi";
import SohoApi from "../../../api/SohoApi";
import * as LocalStore from "../../../store/LocalStore"
import { layerPopup, toast } from '../../../libs/common';
import CustomerManageError from "./CustomerManageError";

const CustomerManage = observer(({ svcCardNm }) => {

  const [getMohInfo, setGetMohInfo] = useState(null);//현재설정된 통화연결음 조회
  const [getRingTonelData, setGetRingTonelData] = useState([]);//기본통화연결음 조회 (sohoAPI)
  const [sameClass, setSameClass] = useState("");//현재설정된 통화연결음과 기본 통화연결음 비교해서 같은 클래스 담음
  const [currentCheck, setCurrentCheck] = useState("");//기본 통화연결음 5개중 1나를 체크하기 위함
  const [mohListInfo, setMohListInfo] = useState(null);//통화연결음 목록 조회
  const [callUnSet, setCallUnSet] = useState(false); 
  const [shy, setShy] = useState ("");
  const [basicCallBoxCheck, setBasicCallBoxCheck] = useState([]); // 기본통화연결음 배열
  const [number,setNumber] = useState(LocalStore.getStoreInetNo())
  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  let centrexKey = ""
  let inetNo = ""

  const centrexIdCheck = () => {
      const centrexId = LocalStore.getStoreInetNo();
      //const tempCentrexId = LocalStore.getTempInetNo();
      const tempCentrexPass = LocalStore.getTempInetPass();
    return [centrexId ,tempCentrexPass]
  }
  
  const [resultNum, resultPass] = centrexIdCheck();

  //간편통화연결음은 없는데 통화연결음 목록에 나왔을 시
const nonDefalutRingRemove = (ring, ringList) => {
  //ring : 통화연결음 목록에서 간편통화연결음 모두 가져온 배열
  //ringList : 소호 통화연결음 조회
  let nonData = [];
  //중복값 제거 배열
  let nonData2 =[];

  if(ring === undefined || ring === null || ring.length === 0){
    userInfo.setDefalutCheck("1")
    return ringToneRegi();
  }
  if(ringList.length === 0 || ring === undefined){
    userInfo.setDefalutCheck("1")
    ringToneRegi();
    return mohList()
  }else{
      for(let i =0; i < ringList.length; i++ ){
          for(let j = 0; j < ring.length; j++){
            if(ringList[i] !== ring[j].CLASS){
                nonData.push(ring[j].CLASS);
            }
          }
        }
  }

  nonData2 = nonData.filter((item, idx) => (
    nonData.indexOf(item) === idx
  ));

  for(let i = 0; i < ringList.length; i++){
    if(ringList[i] !== ""){
      for(let j = 0; j < nonData2.length; j++){
        if(ringList[i] === nonData2[j]){
          nonData2.splice(j, 1);
        }
      }
    }
  }

  if(nonData2.length > 0){
      nonData2.map((item) => {
        delMohList2(item)
      })
  }
}

 //기본 통화연결음 삭제 API
 const delMohList2 = async (callClass) => {
  const delMohListData = await CentrexApi.getDelMohList({
    class: callClass
  })
  if(delMohListData.data.SVC_MSG === "OK"){
    return mohList()
  }
}
  
  useEffect(() => {
    userData.setShopMainTabList('customermanage');
      if(userData.dataset.procChgStoreComplete === true){
        centrexKey =LocalStore.getCentrexKey();
        inetNo = LocalStore.getStoreInetNo();
        if(LocalStore.getIsTempUser() === true){
          userData.setStoreInetNo(inetNo);
        }

        if(userInfo.dataset.myservice.callRing !== true) return;
        if(centrexKey === null) return;
        if(inetNo === '') return;
        if(LocalStore.getIsTempUser() === true) return;

         mohList(); // 통화연결음 목록 조회

      }
},[userData.dataset.procChgStoreComplete, userInfo.dataset.myservice.callRing])

  useEffect(()=> {
    centrexIdCheck()
},[userData.dataset.currstoreInfo])

useEffect(() => {
  if(userInfo.dataset.myservice.call && LocalStore.getIsTempUser() === true){
    mohList();
  }
},[userInfo.dataset.myservice.call])

//새로고침 클릭 시 api 재호출
const reset = () => {
  mohList();
}

useEffect(() => {
  //화면 진입 시 호출 방지 (값 받아 올 때만 호출)
  if(userData.dataset.callPageReset === true){
    reset();
    //userData.setCallPageReset(false);
  }
  userData.setCallPageReset(false);
},[userData.dataset.callPageReset])

//간편 통화연결음이 현재 설정된 통화연결음에 있는지 확인
  const sameClassHandler = (sameClassList, mohInfoList) => {
    let sameClass ="";
    let sameClassNum ="";
    let noData = "0";
      for(let i = 0; i < 5; i++){
          if(sameClassList[i]?.includes(mohInfoList?.DATAS.MOHCLASS) && mohInfoList?.DATAS.MOHCLASS !== "" ){
            sameClass = sameClassList[i] ;
            sameClassNum = i;
            noData = "1";
            setCurrentCheck(sameClassNum);
            setCallUnSet(false);
            setShy(null);
            const currId = `#homeRingBack${sameClassNum+1}`;
            $(currId).prop("checked",true);
          }
      }
      if(noData !== "1" ) {
        setCallUnSet(true);
      }
  } 
  
  //통화 연결음 목록 조회 API
  const mohList = async () => {
    try {
      const mohListData = await CentrexApi.getMohListInfo({})
      const getMohList = mohListData.data
      if(mohListData.data.SVC_RT === "0000" || mohListData.data.SVC_RT === '4004'){
          // setBasicCallBoxCheck(basicCallArray)//간편 통화연결음 담기
          setMohListInfo(getMohList);
          getMohData(getMohList)
      } 
    } catch(e) {
        console.log(e);
    }
  }

function isEmptyObj(obj)  {
  if(obj.constructor === Object
     && Object.keys(obj).length === 0)  {
       console.log("빈객체");
       //setRemoveDelete("1");
    return true;
  }
}
  
  //기본 통화연결음 조회 (소호 통화연결음 조회)
  const ringToneInfo = async (setClass, getMohListData) => {
    const getRingTone = await SohoApi.getRingToneInfo({
      lpzEntrNo : resultNum //매장 인터넷 가입 번호
      ,ownerUserId : LocalStore.getOwnerId()
    })
    let valueArray = [];
    const getRingToneData = getRingTone.data;
    let classArray2 =  getMohListData?.DATAS?.map((item) => item.CLASS );
      
      if(getRingToneData === null || getRingToneData === undefined  ){
        valueArray = [];
      }
      else {
        valueArray = Object?.values(getRingToneData);
          userInfo.setCallRingPopupCheck({
            rngTnTyp1:valueArray[0], //기본인사말
            rngTnTyp2:valueArray[2], // 영업중
            rngTnTyp3:valueArray[1], // 쉬는 시간
            rngTnTyp4:valueArray[4], // 영업종료
            rngTnTyp5:valueArray[3], // 휴무일
        });
      } 
      
      if(isEmptyObj(getRingToneData) !== true){
        if(classArray2?.find((items) => items === valueArray[0]) === undefined){
          if(valueArray[0] !== ""){
            await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : "", rngTnTyp2 : valueArray[2], rngTnTyp3 :valueArray[1], rngTnTyp4 : valueArray[4], rngTnTyp5 : valueArray[3]}) 
            valueArray[0] = ""
            userInfo.setCallRingPopupCheck({
              rngTnTyp1: "", //기본인사말
              rngTnTyp2:valueArray[2], // 영업중
              rngTnTyp3:valueArray[1], // 쉬는 시간
              rngTnTyp4:valueArray[4], // 영업종료
              rngTnTyp5:valueArray[3], // 휴무일
          });
          }
        }
        if(classArray2?.find((items) => items === valueArray[2]) === undefined){
          if( valueArray[2] !== ""){
            await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : "", rngTnTyp3 :valueArray[1], rngTnTyp4 : valueArray[4], rngTnTyp5 : valueArray[3]}) 
            valueArray[2] = ""
            userInfo.setCallRingPopupCheck({
              rngTnTyp1:valueArray[0], //기본인사말
              rngTnTyp2:"", // 영업중
              rngTnTyp3:valueArray[1], // 쉬는 시간
              rngTnTyp4:valueArray[4], // 영업종료
              rngTnTyp5:valueArray[3], // 휴무일
          });
          }
        }
        if(classArray2?.find((items) => items === valueArray[1]) === undefined){
          if( valueArray[1] !== ""){
            await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : valueArray[2], rngTnTyp3 : "", rngTnTyp4 : valueArray[4], rngTnTyp5 : valueArray[3]}) 
            valueArray[1] = ""
            userInfo.setCallRingPopupCheck({
              rngTnTyp1:valueArray[0], //기본인사말
              rngTnTyp2:valueArray[2], // 영업중
              rngTnTyp3:"", // 쉬는 시간
              rngTnTyp4:valueArray[4], // 영업종료
              rngTnTyp5:valueArray[3], // 휴무일
          });
          }
        }
        if(classArray2?.find((items) => items === valueArray[4]) === undefined){
          if( valueArray[4] !== ""){
            await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : valueArray[2], rngTnTyp3 : valueArray[1], rngTnTyp4 : "", rngTnTyp5 : valueArray[3]}) 
            valueArray[4] = ""
            userInfo.setCallRingPopupCheck({
              rngTnTyp1:valueArray[0], //기본인사말
              rngTnTyp2:valueArray[2], // 영업중
              rngTnTyp3:valueArray[1], // 쉬는 시간
              rngTnTyp4:"", // 영업종료
              rngTnTyp5:valueArray[3], // 휴무일
          });
          }
        }
        if(classArray2?.find((items) => items === valueArray[3]) === undefined){
          if( valueArray[3] !== ""){
            await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : valueArray[2], rngTnTyp3 : valueArray[1], rngTnTyp4 : valueArray[4], rngTnTyp5 : ""}) 
            valueArray[3] = ""
            userInfo.setCallRingPopupCheck({
              rngTnTyp1:valueArray[0], //기본인사말
              rngTnTyp2:valueArray[2], // 영업중
              rngTnTyp3:valueArray[1], // 쉬는 시간
              rngTnTyp4:valueArray[4], // 영업종료
              rngTnTyp5:"", // 휴무일
          });
          }
        }
      }
      setGetRingTonelData(getRingToneData);
      setSameClass(prev => prev = valueArray);
      sameClassHandler(valueArray,setClass,getMohListData)
}
  //현재 설정된 통화연결음 조회
  const getMohData = async (getMohListData) => {
    const mohData = await CentrexApi.getMohInfo({
      peer_exten: LocalStore.getExtenInetNo()
    }).catch((e) => {
      // layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(인터넷 전화)", [
      //   function() {
      //       layerPopup.hide("#popup_onetitlemsgbutton");
      //     }
      //  ]);
    })

    // if(Change === true){ 
    //   return //console.log("변경했을 때 진입 확인===>", mohData);;
    // }
      const mohCallData = mohData.data;
      setGetMohInfo(mohCallData);
      //소호 기본통화연결음 조회
      ringToneInfo(mohCallData, getMohListData)
}

  //번호의 통화 연결음 변경설정 API
  const callRingChange = async (callClass, ) => {
    const setMohInfoData = await CentrexApi.getSetMohInfo({
      class: callClass, // getmohlist에서 조회된 CLASS명 미설정시 : "" OR N 
      peer_exten:LocalStore.getExtenInetNo() // 뒷자리 4개 3자리일 경우 0XXX형식
    })

    if(setMohInfoData.data.SVC_MSG === "OK"){
      toast("통화연결음 설정을 변경했습니다.")
      getMohData(mohListInfo,basicCallBoxCheck);
    }
} 

  //통화연결음 변경 
 const callRingChangeHandler = async (e) => {
  const changeClass = e.target.value
  let ChanngeCall = true;

  if(changeClass === ""){
    //추가 - 12.08
    NativeBridge.sendUserActionLog('menu', 'H021');

    setCallUnSet(true);
  }
  if(changeClass === "1" ||
  changeClass === "2" ||
  changeClass === "3" ||
  changeClass === "4" ||
  changeClass === "5" )
  {
    layerPopup.showCommon(
          '#home_callring_popup', 
          null, 
          '통화연결음 설정 안내', 
          '통화연결음을 먼저 만든 다음 설정할 수 있<br/>습니다. 우리가게만의 통화연결음을 만들어<br/>보세요.',
          [
              function() {
                  layerPopup.hide('#home_callring_popup');//닫기
                  //getMohData()  
              },
          ]
      )
  }else{
    await callRingChange(changeClass,ChanngeCall);
  }
  getMohData(mohListInfo,basicCallBoxCheck)
 
  let menuCode = '';
      switch (changeClass) {
        case getRingTonelData.rngTnTyp1:
          menuCode = 'H014';
          break;
        case getRingTonelData.rngTnTyp2:
          menuCode = 'H015';
          break;  
        case getRingTonelData.rngTnTyp3:
          menuCode = 'H016';
          break;  
        case getRingTonelData.rngTnTyp4:
          menuCode = 'H017';
          break;  
        case getRingTonelData.rngTnTyp5:
          menuCode = 'H018';
          break;  
        default:
          break;
      }
      if (menuCode !== '') {
        NativeBridge.sendUserActionLog('menu', menuCode);
      }
 }

  const ringToneRegi = async () => {
    const ringToneRegi = await SohoApi.getRingToneRegi({
      ownerUserId : LocalStore.getOwnerId(),
      lpzEntrNo : resultNum, //인터넷 가입번호
      rngTnTyp1 : "",
      rngTnTyp2 : "",
      rngTnTyp3 : "",
      rngTnTyp4 : "",
      rngTnTyp5 : "",
    })
  }
  
  const CsClick = () => {
    //추가 - 12.08
    NativeBridge.sendUserActionLog('menu', 'H026');
    
    /*
    if(userData.dataset.currCallPage !== 'calllog') {
      userData.setCallTabList('calllog');
      userData.setShopMainTabList('call');
    } 
    else {
      userData.setShopMainTabList('call')
    }
    */
    userData.setShopMainTabList('call');
    userData.setCallTabList('calllog');
    
  }

  const NativeCall = (e,msg) => {
    userData.setMyPageCallPopupData("(부가)통화연결음")
    let menuCode = '';

    switch (msg) {
      case '1800-8000':
        layerPopup.show('#popup_ringregi');
        menuCode = 'P014'
        break;
      default:
        break;
    }
  }

  return (
        <>
          { 
           userInfo.dataset.isTempUser !== true ?
            (getMohInfo?.SVC_RT === "0000" && userInfo.dataset.myservice.callRing === true) ?
                <div className="content_box">
                    <Link to={`/shop/call/calllog`} className="sec_link usetap" onClick={()=> CsClick()}><i className="ico_dark_more"></i>더보기</Link>
                    <span className="cont_title">{svcCardNm}</span> 
                        <div className="sec_wrap sec_callsound">
                            <Link to={`/shop/call/calllog`} className="desc_link" title="우리가게 > 전화 탭" onClick={()=> CsClick()}>
                                <span className="info_msg">가게 전화를 관리해 보세요!</span>
                                <strong className="st_msg" >통화연결음을 설정해주세요.</strong>
                            </Link>
                                <div className="home_call_manage">
                                    <div className="home_call_box">
                                        <span className="box_title">착신전환</span>
                                        <p className="box_desc">가게로 걸려온 전화를 다른 전화기로 대신 받을 수<br /> 있어요.</p>
                                    </div>
                                        {/* case 1-1 : 서비스 가입, 기본 인사말 설정 */}
                                        { getMohInfo?.DATAS.MOHCLASS !== "" && callUnSet === false ?
                                            <div className="home_call_box">
                                                <span className="box_title">통화연결음</span>
                                                <p className="box_desc">가게로 전화를 건 고객에게 인사말 또는 홍보 메<br />시지를전달할 수 있어요.</p>
                                                  <ul className="radio_list">
                                                      <li>
                                                          <input type="radio" id="homeRingBack0" name="homeRingback" value=""  onClick={(e)=>callRingChangeHandler(e)} />
                                                          <label htmlFor="homeRingBack0" >
                                                              <span className="chk_title">선택 안함</span>
                                                          </label>
                                                    </li>
                                                      <li>
                                                          <input id="homeRingBack1" className="checkbox" value={userInfo.dataset.callRingPopupCheck.rngTnTyp1 !== "" ? userInfo.dataset.callRingPopupCheck.rngTnTyp1 : "1"} name="homeRingback" type="radio" onClick={(e)=>callRingChangeHandler(e)}   />
                                                          <label htmlFor="homeRingBack1" >
                                                              <span className="chk_title">기본 인사말</span>
                                                          </label>
                                                      </li>
                                                      <li>
                                                          <input id="homeRingBack3" className="checkbox" value={userInfo.dataset.callRingPopupCheck.rngTnTyp2 !== "" ? userInfo.dataset.callRingPopupCheck.rngTnTyp2 : "2"} name="homeRingback" type="radio" onClick={(e)=>callRingChangeHandler(e)}  />
                                                          <label htmlFor="homeRingBack3" >
                                                              <span className="chk_title">영업 중</span>
                                                          </label>
                                                      </li>
                                                      <li>
                                                          <input id="homeRingBack5" className="checkbox" value={userInfo.dataset.callRingPopupCheck.rngTnTyp4 !== "" ? userInfo.dataset.callRingPopupCheck.rngTnTyp4 : "4"} name="homeRingback" type="radio" onClick={(e)=>callRingChangeHandler(e)}  />
                                                          <label htmlFor="homeRingBack5">
                                                              <span className="chk_title">영업 종료</span>
                                                          </label>
                                                      </li>
                                                      <li>
                                                          <input id="homeRingBack2" className="checkbox" value={userInfo.dataset.callRingPopupCheck.rngTnTyp3 !== "" ? userInfo.dataset.callRingPopupCheck.rngTnTyp3 : "3"} name="homeRingback" type="radio" onClick={(e)=>callRingChangeHandler(e)}   />
                                                          <label htmlFor="homeRingBack2">
                                                              <span className="chk_title">쉬는 시간</span>
                                                          </label>
                                                      </li>
                                                      <li>
                                                          <input id="homeRingBack4" className="checkbox" value={userInfo.dataset.callRingPopupCheck.rngTnTyp5 !== "" ? userInfo.dataset.callRingPopupCheck.rngTnTyp5: "5" } name="homeRingback" type="radio" onClick={(e)=>callRingChangeHandler(e)}  />
                                                          <label htmlFor="homeRingBack4">
                                                              <span className="chk_title">휴무일</span>
                                                          </label>
                                                      </li>
                                                </ul>        
                                          </div>
                                        /* <!-- Case 1_2 서비스 가입, 통화연결음 미 설정 --> */
                                        : getMohInfo?.DATAS.MOHCLASS === "" && callUnSet === true ?
                                            <div className="home_call_box">
                                                <span className="box_title">통화연결음</span>
                                                <p className="box_desc">통화연결음을 만들어 홈화면에서 간편하게 <br />설정해 보세요.</p>
                                                  <div className="btn_box btn_callsound_box">
                                                      <button type="button" className="btn_sm btn_line1 usetap"onClick={()=> layerPopup.show("#popup_callring_make")} >통화연결음 만들기</button>
                                                </div>
                                            </div>
                                        /* <!-- Case 1_3 요일별/간편 통화연결음 설정 시 --> */
                                        : 
                                          <div className="home_call_box">
                                                <span className="box_title">통화연결음</span>
                                                <p className="box_desc">홈화면에서 바로 설정할 수 있는 간편통화연결음을 만들어보세요.</p>
                                                  <div className="btn_box btn_callsound_box">
                                                      <button type="button" className="btn_sm btn_line1 usetap" onClick={()=> layerPopup.show("#popup_ByDayCallRing")}>통화연결음 만들기</button>
                                                </div>
                                            </div>                  
                                        }
                                </div>
                          </div>
                    </div>
            :
            userInfo.dataset.myservice.callRing === false ?
              <div className="content_box">
                <Link to={`/shop/call/calllog`} className="sec_link usetap" onClick={()=> CsClick()}><i className="ico_dark_more"></i>더보기</Link>
                <span className="cont_title">{svcCardNm}</span> 
                    <div className="sec_wrap sec_callsound">
                        <Link to={`/shop/call/calllog`} className="desc_link" title="우리가게 > 전화 탭" onClick={()=> CsClick()}>
                            <span className="info_msg">가게 전화를 관리해 보세요!</span>
                            <strong className="st_msg" >통화연결음을 설정해주세요.</strong>
                        </Link>
                            <div className="home_call_manage">
                                <div className="home_call_box">
                                    <span className="box_title">착신전환</span>
                                    <p className="box_desc">가게로 걸려온 전화를 다른 전화기로 <br />대신 받을 수 있어요.</p>
                                </div>
                                <div className="home_call_box">
                                    {/* <!-- 210913 Case : 인터넷 가입O, 통화연결음 가입 x --> */}
                                    <div className="home_call_box">
                                        <a href="#none" className="ringback_subs" value="(부가)통화연결음"  onClick={(e)=>NativeCall(e,'1800-8000')}>
                                          <img src="res/images/img-banner-call-service@3x.png" alt="통화연결음(부가서비스) 가입하기" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
              </div>
            :
              <div className="content_box">
                  <Link to={`/shop/call/calllog`} className="sec_link usetap" onClick={()=> CsClick()}><i className="ico_dark_more"></i>더보기</Link>
                  <span className="cont_title">{svcCardNm}</span> 
                  <CustomerManageError />
              </div>
          
          :
          getMohInfo?.SVC_RT === "0000" || getMohInfo?.SVC_RT === "1103" ?
            <div className="content_box"> 
                <Link to={`/shop/call/calllog`} className="sec_link usetap" onClick={()=> CsClick()}><i className="ico_dark_more"></i>더보기</Link>
                <span className="cont_title">{svcCardNm}</span>  
                <div className="sec_wrap sec_callsound">
                      <Link to={`/shop/call/calllog`} className="desc_link" title="우리가게 > 전화 탭" onClick={()=> CsClick()}>
                          <span className="info_msg">가게 전화를 관리해 보세요!</span>
                      </Link>
                      <div className="home_call_manage">
                          <div className="home_call_box">
                                <span className="box_title">착신전환</span>
                                <p className="box_desc">가게로 걸려온 전화를 다른 전화기로 <br />대신 받을 수 있어요.</p>
                          </div>
                      </div>
                </div>
            </div>
          :
            <div className="content_box">
                <Link to={`/shop/call/calllog`} className="sec_link usetap" onClick={()=> CsClick()}><i className="ico_dark_more"></i>더보기</Link>
                <span className="cont_title">{svcCardNm}</span> 
                  <CustomerManageError />
            </div>
          }
    </>
  );
});
export default CustomerManage;
