import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import { layerPopup } from '../../libs/common';
import CctvApi from '../../api/CctvApi';
import SohoApi from '../../api/SohoApi';
import { setCctvToken } from '../../api/CctvApi';
import { deleteCctvAuthKey } from '../../store/LocalStore';
import { removeCctvToken } from '../../store/SessionStore';
import * as CONSTS from '../../const';
import BizApi from '../../api/BizApi';

const InterworkLoginTempPopup = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const [idValue, setIdValue] = useState("");
    const [password, setPassWord] = useState("");
    const [cameras, setCameras] = useState(null);
    const [dispatchService, setDispatchService] = useState(null)
    const [iotType, setIotType] = useState(null)

    useEffect(() => {       
        setDispatchService(userInfo.dataset.myservice.cctvReq);
        setIotType(userInfo.dataset.myservice.staffMng);
    }, []);

     //아이디 관리
     const idValueOnChange = (e) => {
        let id = e.target.value;
        setIdValue(id);
    }
    //패스워드 관리
    const passwordValueOnChange = (e) => {
        let pass = e.target.value;
        setPassWord(pass);
    }

   const camerasData = async () => {
        //    const cameras = await CctvApi.getCameras()
        let cameras = {};
        try {
            cameras = await CctvApi.getCameras();
            // console.log('cameras12', cameras)
            // if(cameras.data.resCode == 401) {
            //     layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요.(CCTV)", [
            //         function() {
            //             layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=> {
            //                 if(userData.dataset.cctvauthFlag === false) {
            //                     await CctvApi.getAuth({
            //                         userId : LocalStore.getCctvTempUserId(),
            //                         userPwd : LocalStore.getCctvTempUserPwd()
            //                     });
            //                 }
            //                 userData.setCctvAuthFlag(true)
            //             });
            //         }
            //     ]);
            // }
        } catch(e) {
            cameras = {
                data: {
                    list : []
                }
            };
        }        

       console.log("cameras@@@@@@@", cameras.data.list);

       let cammerasData = cameras.data.list

       let storeMappingList = []

       let camIds = [];
       let cams = [];
       let cam_device_sn_id = [];
       let addLzpList = [];
       
       cammerasData?.map((camera) => {
           
        const cam = {};
            camIds.push(camera.camId);
            cam.camId = camera.camId;
            cam.camName = camera.camName;
            cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
            cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
                            if (camera.device_sn_id.length > 0) {
                                cam_device_sn_id.push(camera.device_sn_id)
                            }
                            cams.push(cam);
       
                            //console.log("sdkfhsdkfhsdkfh",cam);

                        
        })
       //userData.setStoreList([0], cam);

       storeMappingList.push({
        storeId: LocalStore.getStoreList()[0].storeId ,
        storeNm: LocalStore.getStoreList()[0].storeNm ,
        lpzEntrNo: LocalStore.getStoreList()[0].lpzEntrNo,
        camId: camIds.join(","), 
        cams: cams,
        cam_device_sn_id : cam_device_sn_id,
        lpzNo: LocalStore.getStoreList()[0].lpzNo,
        lzpEntrNo: addLzpList.join(",")
    });

    console.log("0454", storeMappingList[0]);

    
    userData.setStoreList(storeMappingList);
    userData.setCurrStoreInfo(storeMappingList[0]);
   }
   
    
    
    //로그인 헤더에 authkey값 보내면 안됨
    const userInfoAPI = async () => {

        let cryptPassword = Crypt.cctvPwEncrypt(password);

    
        const userAuth = await CctvApi.getAuth({
            userId : idValue,
            userPwd : cryptPassword
        })

        console.log("userAuth...", userAuth)

        if(userAuth && userAuth.data.resCode === CONSTS.CCTV_RES_CODE_SUCCESS){
            //연동하기 노출
            console.log("연동성공");
            console.log("authkey===>",userAuth.data.authKey);
            console.log("11111")
            

            userData.setInterworkCheck2("1");
            userInfo.setCctvTempUserId(idValue);
            setIdValue("");
            setPassWord("");
            // document.getElementById("popup_interworktemplogin").style.display = "none";
            layerPopup.hide('#popup_interworktemplogin');

            if(userAuth.data.authKey){
                //console.log("222222")
                await camerasData()
            }    

            BizApi.makeMyService();

        }
        else{
            clear("all");
            //오류팝업
            // document.getElementById("popup_interworktemplogin").style.display = "none";
            layerPopup.hide('#popup_interworktemplogin', ()=> {
                layerPopup.showCommon(
                    '#popup_tempcctvloginerror', 
                    
                    null, 
                    '아이디 또는 비밀번호 오류', 
                    '아이디 또는 비밀번호를 잘못<br/>입력했습니다. 확인 후 다시 입력해주세요.',
                    
                    [
                        function() {
                            layerPopup.hide('#popup_tempcctvloginerror',()=>{
                                console.log("오류")
                                setIdValue("");
                                setPassWord("");
                                externalCallApp();//아이디 찾기
                            })
                        },
                         function() { // 확인 버튼
                            layerPopup.hide('#popup_tempcctvloginerror',()=>{
                                console.log("오류")
                                setIdValue("");
                                setPassWord("");
                                externalCallApp(); //비밀번호 찾기
                            })                        
                        }
                    ]
                )
            });



            // console.log("오류")
            // setIdValue("");
            // setPassWord("");
            //  layerPopup.hide('#popup_interworktemplogin');
        }

}
    //로그아웃 헤더에 authkey값 보냄
    const userInfoDeleteAPI = async () => {
        const userInfoDelete = await CctvApi.getDeleteAuth({
        })

        console.log("userInfoDelete...",userInfoDelete);

        if(userInfoDelete.data.resMsg === "성공"){
            setCctvToken("")
            userInfoAPI();
        }

    }

    //로그인 로그아웃
    const cctvLoginFlow = () => {
        
        if(LocalStore.getCctvToken() === "" || LocalStore.getCctvToken() === null){
            const loginCheck = true;
            userInfoAPI(loginCheck)
        }
        //cctvtoken 존재한다면 기존사용자 강제 로그아웃
        else{
            deleteCctvAuthKey();
            removeCctvToken();
            userInfoDeleteAPI()
        }
    }

     //네이티브 외부 앱 호출
    const externalCallApp = () => {
        NativeBridge.externalCall(`Soho://main.launch?authKey=&dispatchService=${dispatchService ? '1' : '0'}&iotType=${iotType ? '1' : '0'}&type=0`, "3")
    }

    const cctvPwMissing = () => {
        layerPopup.hide('#popup_interworktemplogin',()=>{
            NativeBridge.externalCall(`Soho://main.launch?authKey=&dispatchService=${dispatchService ? '1' : '0'}&iotType=${iotType ? '1' : '0'}&type=0`, "3")
        })
    }

   const clear = (data) => {
       if(data === "all"){
        setIdValue("");
        setPassWord("");   
        layerPopup.hide('#popup_interworktemplogin')
       }
       if(data === "id"){
        setIdValue("");
       }
       if(data === "pass"){
        setPassWord("");   
       }

   }

    return (
        <>
            <div className="modal fadeIn" id="popup_interworktemplogin">
                <div className="dim" onClick={()=>clear("all")}></div>
                <div className="modal_inner">
                    <div className="modal_content modal_interwork_login">
                        <button type="button" className="pop_close2 blind" onClick={()=>clear("all")}>닫기</button>
                        <div className="modal_hd">U+지능형 CCTV 연동하기</div>
                        <div className="modal_bd">
                            <p>U+지능형 CCTV 앱에서 사용하는<br />아이디와 비밀번호를 입력해 주세요.</p>
                            <div className="input_box style2">
                                <input type="text" placeholder="아이디 입력"  value={idValue}  onChange={(e) => idValueOnChange(e)} />
                                <span className="ip_clear hidden usetap" onClick={() => clear("id")}>초기화</span>
                            </div>
                            <div className="input_box style2">
                                <input type="password" placeholder="비밀번호 입력" value={password} onChange={(e) => passwordValueOnChange(e) } />
                                <span className="ip_clear hidden usetap" onClick={() => clear("pass")}>초기화</span>
                            </div>
                        </div>
                        <div className="modal_ft">
                            <button  type="button" className="btn_half usetap" onClick={()=>cctvLoginFlow()}><span>로그인</span></button>
                            <button  type="button" className="btn_half usetap" onClick={()=>cctvPwMissing()}><span>비밀번호 찾기</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
});
export default InterworkLoginTempPopup;