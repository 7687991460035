import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { action, useStrict } from 'mobx'
import { layerPopup } from "../../../../../libs/common";
import Picker from "pickerjs";
import { toast } from "../../../../../libs/common";
import * as LocalStore from "../../../../../store/LocalStore";
import { TimeoutError } from "rxjs";
import SohoApi from "../../../../../api/SohoApi";


//시간설정 체크, radio 버튼 체크 


const TimeCallRing = observer(({history, location}) => {
  
 
	const { userInfo, userData,deviceInfo } = store;


    const [timeCallRing, setTimeCallRing] = useState(null); //시간별 통화연결음 성공조회

	const [titleValue, setTitleValue] = useState("");//타이틀

	const [eachCheckInput, setEachCheckInput] = useState("");//speaker
	const [checked, setChecked] = useState(true);//speaker check management

    const [workInValue, setWorkInValue] = useState(""); //영업중 멘트
    const [workOutValue, setWorkOutValue] = useState(""); //영업 종료멘트

    const [workSaInValue, setWorkSaInValue] = useState("");//토요일 영업 중 멘트
    const [workSaOutValue, setWorkSaOutValue] = useState("");//토요일 영업 종료 멘트

    const [workSunInValue,  setWorkSunInValue] = useState(""); //일요일 영업 중 멘트
    const [workSunOutValue,  setWorkSunOutValue] = useState(""); //일요일 영업 중 멘트
   
    const [workBreakValue, setWorkBreakValue] = useState(""); //쉬는 시간 멘트
    const [workOffValue, setWorkOffValue] = useState(""); //휴일 멘트
    const [breakFTime, setBreakFTime] = useState("");// 쉬는 시간 설정 Picker3
    const [dayCheck, setDayCheck] = useState([]); // 현재 체크된 요일 


    const [timeCheck, setTimeCheck] = useState("");


    const [pickCheck, setPickCheck] = useState(false);
    const [test2,setTest2] = useState("");

    const [time, setTime] = useState({
        pickerTime1: "",
        pickerTime2: "",
        pickerTime3: "",
        pickerTime4: "",
        pickerTime5: "",
        pickerTime6: "",
        pickerTime7: "",
        pickerTime8: "",
    });
  
    const [change,setChange] = useState(true);

   
    const timeCheckHandler = () => {  

        //("1111111111111111111")
        
        let eventControl = true;
        let startClick = true;
        let endClick = true;


        makeTtsMoh(eventControl)
        //setTimeCheck("");
        //console.log("테스트");
    }


    useEffect(() => {
      const subscription = NativeBridge.eventObservable.subscribe(event => {
            
			
        //console.log("----------------- from Native Event : Main.js ------------------");
        //console.log("타는지 확인@@@2233",event);
        switch (event.type) {                    
    
            case 'CALL_BACKPRESS':
                //console.log("Go Back!");
                //const bBack = checkHistoryBack();
                //console.log("bBack: ", bBack);
                
                    if(document.getElementById("popup_prelisten").style.display === "block"){
                        return layerPopup.hide('#popup_prelisten')
                        //return document.getElementById("popup_prelisten").style.display = "none"
                        //document.getElementById("make_call_before").style.display = "none"
                        console.log("타는지 확인@@@22")
                        //setRender("1");
                    }
                    if(document.getElementById("make_call_before").style.display === "block"){
                      return layerPopup.hide('#make_call_before')
                    }
                    if(((location.pathname === "/shop/call/callset/callring/timecall" && document.getElementById("popup_prelisten").style.display === "none") ||
                  (location.pathname === "/shop/call/callset/callring/timecall" && document.getElementById("popup_prelisten").style.display === ""))){
                      console.log("타는지 확인@@@")
                      goBackPage();
                      //setRender("0");
                  }
                
                
            break;
    
         
    
        default:
            break;
        }
    
    });
  //subscription.unsubscribe();
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
          }          
          // 화면이 렌더링 되었을 때 speker(여성) default 값 체크
          setEachCheckInput("0")

        new Picker(document.querySelector('.time_picker'), {
            format: 'HH:mm',
            rows: 1,
            //value:1,
            text: {
                title: '영업 시간 설정',
                confirm: '완료',
            },
            
        });
        new Picker(document.querySelector('.time_picker2'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '영업 시간 설정',
                confirm: '완료',
            }
        });
        new Picker(document.querySelector('.time_picker3'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '영업 시간 설정',
                confirm: '완료',
            },
            
        });
        new Picker(document.querySelector('.time_picker4'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '쉬는 시간 설정',
                confirm: '완료',
            }
        });

        new Picker(document.querySelector('.time_picker5'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '영업 시간 설정',
                confirm: '완료',
            }
        });
        new Picker(document.querySelector('.time_picker6'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '영업 시간 설정',
                confirm: '완료',
            }
        });
        new Picker(document.querySelector('.time_picker7'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '쉬는 시간 설정',
                confirm: '완료',
            }
        });
        new Picker(document.querySelector('.time_picker8'), {
            format: 'HH:mm',
            rows: 1,
            text: {
                title: '쉬는 시간 설정',
                confirm: '완료',
            }
        });
    
        $('.time_picker').on("click", () => {
            return false;
        }
            
        )
        $('.picker-confirm').on("click", () => (timeCheckHandler()))
        
        
        return () => {
          subscription.unsubscribe();  
          if($('#wrap').hasClass('fixed_bottom')) {
              
              $('#wrap').removeClass('fixed_bottom');
              $('#wrap').addClass('gnb_bottom');
            }
        }

    }, [])

    useEffect(() => {
      if(timeCheck){
        makeTtsMoh(true);
        setTimeCheck(false);
      }
    },[timeCheck])

    useEffect(()=> {
        document.getElementsByName("openMent1")[0].onblur = () => {
          if(workInValue.length < 1){
            //("11",workInValue);
          document.getElementsByName("callLabel8")[0].style.display = "block";
          }
      }
      },[workInValue])
      useEffect(()=> {
        document.getElementsByName("closeMent1")[0].onblur = () => {
          if(workOutValue.length < 1){
            //console.log("11",workOutValue);
          document.getElementsByName("callLabel9")[0].style.display = "block";
          }
      }
      },[workOutValue])
      useEffect(()=> {
        document.getElementsByName("saOpenMent1")[0].onblur = () => {
          if(workSaInValue.length < 1){
           // console.log("11",workSaInValue);
          document.getElementsByName("callLabel10")[0].style.display = "block";
          }
      }
      },[workSaInValue])
      useEffect(()=> {
        document.getElementsByName("saCloseMent1")[0].onblur = () => {
          if(workSaOutValue.length < 1){
            //console.log("11",workSaOutValue);
          document.getElementsByName("callLabel11")[0].style.display = "block";
          }
      }
      },[workSaOutValue])
      useEffect(()=> {
        document.getElementsByName("sunOpenMent1")[0].onblur = () => {
          if(workSunInValue.length < 1){
            //console.log("11",workSunInValue);
          document.getElementsByName("callLabel12")[0].style.display = "block";
          }
      }
      },[workSunInValue])
      useEffect(()=> {
        document.getElementsByName("sunCloseMent1")[0].onblur = () => {
          if(workSunOutValue.length < 1){
            //console.log("11",workSunOutValue);
          document.getElementsByName("callLabel13")[0].style.display = "block";
          }
      }
      },[workSunOutValue])
      useEffect(()=> {
        document.getElementsByName("breakMent1")[0].onblur = () => {
          if(workBreakValue.length < 1){
            //console.log("11",workBreakValue);
          document.getElementsByName("callLabel14")[0].style.display = "block";
          }
      }
      },[workBreakValue])


  useEffect(()=> {
    if(
      titleValue === "" ||
    workInValue === "" ||
    workOutValue === "" ||
    workSaInValue === "" ||
    workSaOutValue === "" ||
    workSunInValue === "" ||
    workSunOutValue === "" ||
    workBreakValue === "" ||
    time.pickerTime1 === "" || 
    time.pickerTime2 === "" ||
    time.pickerTime3 === "" ||
    time.pickerTime4 === "" ||
    time.pickerTime5 === "" ||
    time.pickerTime6 === "" ||
    time.pickerTime7 === "" ||
    time.pickerTime8 === "" 
    ){
      $('#timeCallRing').attr('disabled', true);
    }
    else{
      $('#timeCallRing').attr('disabled', false);
    }
  
  },[titleValue,workInValue,workOutValue,workSaInValue,workSaOutValue,workSunInValue,workSunOutValue,workBreakValue,time])

  useEffect(() => {

    
    
    if(time.pickerTime1 !== "" && time.pickerTime2 !== ""){
        let picker1 = time.pickerTime1.replace(/\:/g,'');
      let picker1Our = Number(picker1.slice(0,2));
      let picker1mm = Number(picker1.slice(3, 5));
      let picker2 = time.pickerTime2.replace(/\:/g,'');
      let picker2Our = Number(picker2.slice(0,2));
      let picker2mm = Number(picker2.slice(3, 5));
        startTimeSetPopup(picker1Our, picker2Our, picker1mm, picker2mm, 1 )
    }
    

  },[time.pickerTime1])
  // Number(time.pickerTime1.replace(/\:/g,'').slice(0,2))
  
  useEffect(()=>{
   

    if(time.pickerTime1 !== "" && time.pickerTime2 !== ""){
      let picker1 = time.pickerTime1.replace(/\:/g,'');
      let picker1Our = Number(picker1.slice(0,2));
      let picker1mm = Number(picker1.slice(3, 5));
      let picker2 = time.pickerTime2.replace(/\:/g,'');
      let picker2Our = Number(picker2.slice(0,2));
      let picker2mm = Number(picker2.slice(3, 5))
      endTimeSetPopup(picker1Our, picker2Our, picker1mm, picker2mm, 1 )
    }
   
  },[time.pickerTime2])

  useEffect(() => {

    
    
    if(time.pickerTime3 !== "" && time.pickerTime4 !== ""){
      let picker3 = time.pickerTime3.replace(/\:/g,'');
    let picker3Our = Number(picker3.slice(0,2));
    let picker3mm = Number(picker3.slice(3, 5));
    let picker4 = time.pickerTime4.replace(/\:/g,'');
    let picker4Our = Number(picker4.slice(0,2));
    let picker4mm = Number(picker4.slice(3, 5));
      startTimeSetPopup(picker3Our, picker4Our, picker3mm, picker4mm, 2 )
    }
    

  },[time.pickerTime3])

  useEffect(()=>{
    
    
    if(time.pickerTime3 !== "" && time.pickerTime4 !== ""){
      let picker3 = time.pickerTime3.replace(/\:/g,'');
    let picker3Our = Number(picker3.slice(0,2));
    let picker3mm = Number(picker3.slice(3, 5));
    let picker4 = time.pickerTime4.replace(/\:/g,'');
    let picker4Our = Number(picker4.slice(0,2));
    let picker4mm = Number(picker4.slice(3, 5));
      endTimeSetPopup(picker3Our, picker4Our, picker3mm, picker4mm, 2 )
    }
   
  },[time.pickerTime4])

  useEffect(() => {

   
    
    if(time.pickerTime5 !== "" && time.pickerTime6 !== ""){
      let picker5 = time.pickerTime5.replace(/\:/g,'');
      let picker5Our = Number(picker5.slice(0,2));
      let picker5mm = Number(picker5.slice(3, 5));
      let picker6 = time.pickerTime6.replace(/\:/g,'');
      let picker6Our = Number(picker6.slice(0,2));
      let picker6mm = Number(picker6.slice(3, 5));
      startTimeSetPopup(picker5Our, picker6Our, picker5mm, picker6mm, 3 )
    }
    

  },[time.pickerTime5])

  useEffect(()=>{
    
    
    if(time.pickerTime5 !== "" && time.pickerTime6 !== ""){
      let picker5 = time.pickerTime5.replace(/\:/g,'');
    let picker5Our = Number(picker5.slice(0,2));
    let picker5mm = Number(picker5.slice(3, 5));
    let picker6 = time.pickerTime6.replace(/\:/g,'');
    let picker6Our = Number(picker6.slice(0,2));
    let picker6mm = Number(picker6.slice(3, 5));
      endTimeSetPopup(picker5Our, picker6Our, picker5mm, picker6mm, 3 )
    }
   
  },[time.pickerTime6])

  useEffect(() => {

   
    
    if(time.pickerTime7 !== "" && time.pickerTime8 !== ""){
      let picker7 = time.pickerTime7.replace(/\:/g,'');
      let picker7Our = Number(picker7.slice(0,2));
      let picker7mm = Number(picker7.slice(3, 5));
      let picker8 = time.pickerTime8.replace(/\:/g,'');
      let picker8Our = Number(picker8.slice(0,2));
      let picker8mm = Number(picker8.slice(3, 5));
      startTimeSetPopup(picker7Our, picker8Our, picker7mm, picker8mm, 4 )
    }
    

  },[time.pickerTime7])

  useEffect(()=>{
    
    if(time.pickerTime7 !== "" && time.pickerTime8 !== ""){
      let picker7 = time.pickerTime7.replace(/\:/g,'');
    let picker7Our = Number(picker7.slice(0,2));
    let picker7mm = Number(picker7.slice(3, 5));
    let picker8 = time.pickerTime8.replace(/\:/g,'');
    let picker8Our = Number(picker8.slice(0,2));
    let picker8mm = Number(picker8.slice(3, 5));
    
    endTimeSetPopup(picker7Our, picker8Our, picker7mm, picker8mm, 4 )
    }
   
  },[time.pickerTime8])


  const startTimeSetPopup = (ourTime1, ourTime2, mmTime1, mmTime2, type) => {
    if(ourTime1 > ourTime2 || (ourTime1 === ourTime2 && mmTime1 > mmTime2) || (ourTime1 === ourTime2 && mmTime1 === mmTime2) )
    
    {
      console.log("aa@@1", ourTime1 )
      console.log("aa@@2",  $('.time_picker').val())
      console.log("aa@@3",time);
      console.log("aa@@4", Number(time.pickerTime1.replace(/\:/g,'').slice(0,2)))
      console.log("aa@@5", Number(time.pickerTime3.replace(/\:/g,'').slice(0,2)))
      console.log("aa@@6",  Number(time.pickerTime1.replace(/\:/g,'').slice(3, 5)))
      console.log("aa@@7",  Number(time.pickerTime3.replace(/\:/g,'').slice(3, 5)))
      //console.log("aa@@3", ourTime1 )
    //setChange(true);
    layerPopup.showCommon(
                          '#popup_onetitlemsgbutton', 
                          
                          null, 
                          '시작 시간 재설정', 
                          '시작 시간을 종료 시간 보다 늦은 시간으로<br />설정했습니다. 다시 설정해 주세요.',
                          
                          [
                              function() {
                                  layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                                 if(((type === 1 && ourTime1 ===  Number(time.pickerTime1.replace(/\:/g,'').slice(0,2))) ||
                                    (type === 1 && mmTime1 === Number(time.pickerTime1.replace(/\:/g,'').slice(3, 5)))) ){
                                       console.log("aa@@1111")
                                  $('.time_picker').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime1 : ""
                                  }))
                                 
                                 }
                                 if(((type === 2 && ourTime1 ===  Number(time.pickerTime3.replace(/\:/g,'').slice(0,2))) ||
                                 (type === 2 &&  mmTime1 === Number(time.pickerTime3.replace(/\:/g,'').slice(3, 5))))){
                                      console.log("aa@@2222")
                                  $('.time_picker3').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime3 : ""
                                  }))
                                 
                                 }
                                 if(((type === 3 && ourTime1 ===  Number(time.pickerTime5.replace(/\:/g,'').slice(0,2))) ||
                                 (type === 3 && mmTime1 === Number(time.pickerTime5.replace(/\:/g,'').slice(3, 5))))){
                                      console.log("aa@@3333")
                                  $('.time_picker5').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime5 : ""
                                  }))
                             
                                 }
                                 if(((type === 4 && ourTime1 ===  Number(time.pickerTime7.replace(/\:/g,'').slice(0,2))) ||
                                 (type === 4 &&   mmTime1 === Number(time.pickerTime7.replace(/\:/g,'').slice(3, 5))))){
                                      console.log("aa@@4444")
                                  $('.time_picker7').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime7 : ""
                                  }))
                                 
                                 }
                                 
                                  
                              },
                              // function() { // 확인 버튼
                                  
                              //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                              
              
                              // }
                          ]
                      )
  }
  
  
}

  const endTimeSetPopup = (ourTime1, ourTime2, mmTime1, mmTime2, type) => {
   
    if(ourTime1 > ourTime2 || (ourTime1 === ourTime2 && mmTime1 > mmTime2) || (ourTime1 === ourTime2 && mmTime1 === mmTime2))
   
    {
      console.log("aa@@0", ourTime1)
      console.log("aa@@1", ourTime2 )
      console.log("aa@@2",  $('.time_picker2').val())
      console.log("aa@@3",time);
      console.log("aa@@4", Number(time.pickerTime2.replace(/\:/g,'').slice(0,2)))
      console.log("aa@@5", Number(time.pickerTime4.replace(/\:/g,'').slice(0,2)))
      console.log("aa@@6",  Number(time.pickerTime2.replace(/\:/g,'').slice(3, 5)))
      console.log("aa@@7",  Number(time.pickerTime4.replace(/\:/g,'').slice(3, 5)))
      console.log("aa@@1212",type);
       
        layerPopup.showCommon(
          '#popup_onetitlemsgbutton', 
          
          null, 
          '종료 시간 재설정', 
          '종료 시간을 시작 시간 보다 빠른 시간으로<br />설정했습니다. 다시 설정해 주세요.',
          
          [
              function() {
                  layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                                 if(((type === 1 && ourTime2 ===  Number(time.pickerTime2.replace(/\:/g,'').slice(0,2))) ||
                                (type === 1 && mmTime2 === Number(time.pickerTime2.replace(/\:/g,'').slice(3, 5))))){
                                  console.log("aa@@1111")
                                  $('.time_picker2').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime2 : ""
                                  }))

                                  console.log("aa@@8", time);
                                 
                                 }
                                 if(((type === 2 && ourTime2 ===  Number(time.pickerTime4.replace(/\:/g,'').slice(0,2))) ||
                                 (type === 2 && mmTime2 === Number(time.pickerTime4.replace(/\:/g,'').slice(3, 5))))){
                                  console.log("aa@@2222")
                                  $('.time_picker4').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime4 : ""
                                  }))
                                 
                                 }
                                 if(((type === 3 && ourTime2 ===  Number(time.pickerTime6.replace(/\:/g,'').slice(0,2))) ||
                                 (type === 3 &&  mmTime2 === Number(time.pickerTime6.replace(/\:/g,'').slice(3, 5))))){
                                  console.log("aa@@3333")
                                  $('.time_picker6').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime6 : ""
                                  }))
                             
                                 }
                                 if(((type === 4 && ourTime2 ===  Number(time.pickerTime8.replace(/\:/g,'').slice(0,2))) ||
                                 (type === 4 &&  mmTime2 === Number(time.pickerTime8.replace(/\:/g,'').slice(3, 5))))){
                                  console.log("aa@@4444")
                                  $('.time_picker8').val("")
                                   setTime((prevState) => ({
                                    ...prevState,
                                    pickerTime8 : ""
                                  }))
                                 
                                 }
              },
              // function() { // 확인 버튼
                  
              //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
              

              // }
          ]
      )
  }
}

//시간별 음원 생성
const makeTtsMoh = async (data) => {

    if(data === false){
      NativeBridge.getLoadingStart();
       const makeTtsMohData = await CentrexApi.getMakettsMoh({
        newservice : "work", // 전체:com, 시간:work, 요일별:week
        titlename : titleValue.trim(), //음원 명 2자 이상 20자 이하
        speaker : eachCheckInput, // 0 : 남성, 1 : 여성, 2 : 아이
        bgfile : userInfo.dataset.defaultGrt?.ringEngName, // "(빈값)" : 배경사용안함 //봄,여름,가을,겨울
        ftime : time.pickerTime1, // 평일 근무 시작 시간 HH_MM (시간_분)
        ttime : time.pickerTime2, // 평일 근무 종료 시간 ''
        workintext : workInValue.trim(), // 평일 근무시간 멘트 최대 200자 한글기준 공백함 영업중
        workouttext : workOutValue.trim(), // 평일 근무 외 시간멘트 200자 영업종료
        safttime : time.pickerTime3, // 토요일 근무 시작시간
        sattime : time.pickerTime4, // 토요일 근무 종료 시간
        worksaintext : workSaInValue.trim(), //토요일 근무시간 멘트 -- 조건부
        worksaouttext : workOutValue.trim(), //토요일 근무 외 시간 멘트 -- 필수
        sunftime : time.pickerTime5, // 일요일 근무 시작시간 
        sunttime : time.pickerTime6, //일요일 근무 종료 시간
        worksunintext : workSunInValue.trim(), //일요일 근무 시간멘트 -- 조건부 200자
        worksunouttext : workSunOutValue.trim(), //일요일 근무 외 시간멘트 200자
        breakftime : time.pickerTime7, //점심시간 시작시간 
        breakttime : time.pickerTime8, //점심시간 종료시간
        workbreaktext : workBreakValue.trim(), // 점심시간 멘트 --조건부
        workofftext : "휴일입니다.", //휴일멘트 
        //점심시간, 토/일요일 근무시간을 설정시 멘트는 반드시 입력 필수
         }).catch((e) => {
        	//console.log(e);
          userInfo.setDefalutGreeting({
                ringName : "비발디 사계 봄",
                ringEngName : "bg_01",
                })
          NativeBridge.getLodingEnd();
          history.goBack();
          toast("통화연결음을 등록했습니다")
        //    console.log(e)
        //   //  NativeBridge.getLoadingStart();
        //    setTimeout((function() {
        //      console.log("test")
        //      setTitleValue(""); // 저장 버튼 눌렀을 때 inputValue값 초기화
        //      setWorkInValue("")
        //      setWorkOutValue("")
        //      setWorkSaInValue("")
        //      setWorkSaOutValue("")
        //      setWorkSunInValue("")
        //      setWorkSunOutValue("")
        //      setWorkBreakValue("")
     
        //  userInfo.setDefalutGreeting({
        //      ringName : "비발디 사계 봄",
        //      ringEngName : "bg_01",
        //    })
           
        //    //console.log("시간별 통화연결음 데이타 확인 ===>", makeTtsMohData);
     
        //  setChecked(true); //음원멘트 default 초기화
        //      toast("통화연결음이 등록되었습니다.")
        //      NativeBridge.getLodingEnd();
        //      history.goBack();
        //    }), 4000)
         })
         if(makeTtsMohData.data.SVC_MSG === "OK"){
            NativeBridge.sendUserActionLog('detail', 'D094');
            userInfo.setDefalutGreeting({
                ringName : "비발디 사계 봄",
                ringEngName : "bg_01",
            })
            userData.setBgCheck(true);
            NativeBridge.getLodingEnd();
            history.goBack();
            toast("통화연결음을 등록했습니다.")
          }else {
            NativeBridge.getLodingEnd();
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '통화연결음 등록 실패', 
                '통화연결음 등록에 실패하였습니다. 다시 시도해주세요.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                    }
                ]
            )
            
        }

        

        }
        
        else{
          // const getTtsInfoData = await SohoApi.getFamilyAccountList({
          // });
          
            //const getTtsInfoData = await CentrexApi.getTtsInfoList({
                //id : centrexIdCheck(),
                //pass : "67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf",
              //})    
      
              //console.log("이벤트발생", getTtsInfoData);
            //setTimeCheck(getTtsInfoData)
            //console.log("3333333",$('.time_picker').val());
            //console.log("3333333",$('.time_picker2').val());

               //특수문자 제거
        let picker1 = $('.time_picker').val()?.replace(/\:/g,'');
        let picker2 = $('.time_picker2').val()?.replace(/\:/g,'');
        let picker3 = $('.time_picker3').val()?.replace(/\:/g,'');
        let picker4 = $('.time_picker4').val()?.replace(/\:/g,'');
        let picker5 = $('.time_picker5').val()?.replace(/\:/g,'');
        let picker6 = $('.time_picker6').val()?.replace(/\:/g,'');
        let picker7 = $('.time_picker7').val()?.replace(/\:/g,'');
        let picker8 = $('.time_picker8').val()?.replace(/\:/g,'');
    
        //시간,분 나눠서 num타입으로
        let picker1Our = Number(picker1.slice(0,2));
        let picker1mm = Number(picker1.slice(2, 4));
        let picker2Our = Number(picker2.slice(0,2));
        let picker2mm = Number(picker2.slice(2, 4));
        let picker3Our = Number(picker3.slice(0,2));
        let picker3mm = Number(picker3.slice(2, 4));
        let picker4Our = Number(picker4.slice(0,2));
        let picker4mm = Number(picker4.slice(2, 4));
        let picker5Our = Number(picker5.slice(0,2));
        let picker5mm = Number(picker5.slice(2, 4));
        let picker6Our = Number(picker6.slice(0,2));
        let picker6mm = Number(picker6.slice(2, 4));
        let picker7Our = Number(picker7.slice(0,2));
        let picker7mm = Number(picker7.slice(2, 4));
        let picker8Our = Number(picker8.slice(0,2));
        let picker8mm = Number(picker8.slice(2, 4));
        //console.log("picker1Our",picker1Our);
        //console.log("최종 확인",picker1.replace(/(\d{2})(\d{2})/, '$1_$2'))
    
        //시간 비교 
        $(".time_picker").attr('name');
        $(".time_picker2").attr('name');
        $(".time_picker3").attr('name');
        $(".time_picker4").attr('name');
        $(".time_picker5").attr('name');
        $(".time_picker6").attr('name');
        $(".time_picker7").attr('name');
        $(".time_picker8").attr('name');
        // if(picker1Our || picker3Our || picker5Our || picker7Our)
        //시작 시간 눌렀을 시

        //setChange(false);

        setTime({
          pickerTime1: picker1.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime2: picker2.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime3: picker3.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime4: picker4.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime5: picker5.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime6: picker6.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime7: picker7.replace(/(\d{2})(\d{2})/, '$1_$2'),
          pickerTime8: picker8.replace(/(\d{2})(\d{2})/, '$1_$2'),
      })
      setTimeCheck(true);

    //     if(picker1Our || picker3Our || picker5Our || picker7Our){ 
    //         //11:00  : 09:00 시작 시간 클릭 시   
    //         console.log("시작시간진입")
    //                 if(picker1Our > picker2Our && picker2Our !== 0 ||
    //                     picker3Our > picker4Our && picker4Our !== 0 ||
    //                     picker5Our > picker6Our && picker6Our !== 0 ||
    //                     picker7Our > picker8Our && picker8Our !== 0  ){

    //                 return layerPopup.showCommon(
    //                     '#popup_onetitlemsgbutton', 
                        
    //                     null, 
    //                     '시작 시간 재설정', 
    //                     '시작 시간을 종료 시간 보다 늦은 시간으로<br />설정했습니다. 다시 설정해 주세요.',
                        
    //                     [
    //                         function() {
    //                             layerPopup.hide('#popup_onetitlemsgbutton');//닫기
    //                         },
    //                         // function() { // 확인 버튼
                                
    //                         //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                            
            
    //                         // }
    //                     ]
    //                 )
    //                  }
    //                 else if(picker1Our === picker2Our && picker2Our !== 0 ||
    //                     picker3Our === picker4Our && picker4Our !== 0 ||
    //                     picker5Our === picker6Our && picker6Our !== 0 ||
    //                     picker7Our === picker8Our && picker8Our !== 0){
                        
    //                         console.log("시간 같을 때 진입111111")
                            
    //                         if(picker1mm > picker2mm && picker2mm !== 0 ||
    //                             picker3mm > picker4mm && picker4mm !== 0 ||
    //                             picker5mm > picker6mm && picker6mm !== 0 ||
    //                             picker7mm > picker8mm && picker8mm !== 0){
    //                                 console.log("분 같은 때 진입1111111")
    //                         return layerPopup.showCommon(
    //                             '#popup_onetitlemsgbutton', 
                                
    //                             null, 
    //                             '시작 시간 재설정', 
    //                             '시작 시간을 종료 시간 보다 늦은 시간으로<br />설정했습니다. 다시 설정해 주세요.',
                                
    //                             [
    //                                 function() {
    //                                     layerPopup.hide('#popup_onetitlemsgbutton');//닫기
    //                                 },
    //                                 // function() { // 확인 버튼
                                        
    //                                 //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                                    
                    
    //                                 // }
    //                             ]
    //                         )
                            
    //                  }
    //                  else{
    //                     setTime({
    //                         pickerTime1: picker1.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime2: picker2.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime3: picker3.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime4: picker4.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime5: picker5.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime6: picker6.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime7: picker7.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime8: picker8.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                     })

    //                  }
    //                 }
    //                 else{
    //                     setTime({
    //                         pickerTime1: picker1.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime2: picker2.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime3: picker3.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime4: picker4.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime5: picker5.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime6: picker6.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime7: picker7.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                         pickerTime8: picker8.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //                     })
    //                    } 
    //             }
    // // 종료 시간 눌렀을 시
    //     else if(picker2Our || picker4Our || picker6Our || picker8Our){
    //         console.log(" 종료시간 진입")

    //             if(picker1Our > picker2Our && picker2Our !== 0 ||
    //                 picker3Our > picker4Our && picker4Our !== 0 ||
    //                 picker5Our > picker6Our && picker6Our !== 0 ||
    //                 picker7Our > picker8Our && picker8Our !== 0  ){

    //                     return layerPopup.showCommon(
    //                 '#popup_onetitlemsgbutton', 
                    
    //                 null, 
    //                 '종료 시간 재설정', 
    //                 '종료 시간을 시작 시간 보다 빠른 시간으로<br />설정했습니다. 다시 설정해 주세요.',
                    
    //                 [
    //                     function() {
    //                         layerPopup.hide('#popup_onetitlemsgbutton');//닫기
    //                     },
    //                     // function() { // 확인 버튼
                            
    //                     //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                        
        
    //                     // }
    //                 ]
    //             )
    //         }
    //         else if(picker1Our === picker2Our ||
    //             picker3Our === picker4Our ||
    //             picker5Our === picker6Our ||
    //             picker7Our === picker8Our){
                
    //                 console.log("시간 같을 때 진입222222")
                    
    //                 if(picker1mm > picker2mm && picker2mm !== 0 ||
    //                     picker3mm > picker4mm && picker4mm !== 0 ||
    //                     picker5mm > picker6mm && picker6mm !== 0 ||
    //                     picker7mm > picker8mm && picker8mm !== 0){

    //                         return layerPopup.showCommon(
    //                     '#popup_onetitlemsgbutton', 
                        
    //                     null, 
    //                     '종료 시간 재설정', 
    //                     '종료 시간을 시작 시간 보다 늦은 시간으로<br />설정했습니다. 다시 설정해 주세요.',
                        
    //                     [
    //                         function() {
    //                             layerPopup.hide('#popup_onetitlemsgbutton');//닫기
    //                         },
    //                         // function() { // 확인 버튼
                                
    //                         //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                            
            
    //                         // }
    //                     ]
    //                 )
                    
    //          }
    //       }
    //       else{
    //         setTime({
    //             pickerTime1: picker1.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime2: picker2.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime3: picker3.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime4: picker4.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime5: picker5.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime6: picker6.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime7: picker7.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //             pickerTime8: picker8.replace(/(\d{2})(\d{2})/, '$1_$2'),
    //         })
    //        }  
    //    }
       //console.log()
    }
}


const familyAccountList = async () => {
  await SohoApi.getFamilyAccountList({
    
  });

  //const getFamilyAccount = familyAccountData.data;

  //
}    
            
    
//연결음 미리듣기 API
const ttsPrePlay = async (speaker, text, bgRing) => {
  if(text === "" || text === []){
    text = "안녕하세요. 고객님 잠시만 기다리시면 매장으로 연결됩니다."
  }
  //   const ttsPrePlayData = await CentrexApi.getTtsPrePlay({
  //     //id:centrexIdCheck(),
  //   //   id:"07075999917",
  //     //pass : "67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf",
  //     speaker:speaker ,//화자
  //     bgfile : bgRing,//배경음악
  //     text : text, //음원멘트
  // })

  const ttsPrePlayData = await NativeBridge.getRingMusicPlay(speaker, bgRing, text)
  NativeBridge.sendUserActionLog('detail', 'D093', '', '시간대별');
  
  //setTtsPrePlayInfo(ttsPrePlayData);

  //console.log("통화연결음 미리듣기", ttsPrePlayData);
  //console.log("스피커, 텍스트", speaker, text);
}


 //title input관리
const tittleOnChange = (e) => {
  console.log(e.target.value.length);
    setTitleValue(e.target.value);
}


//------------월~금 영업 시간 별 설정 --> 영업시간 및 영업 중 멘트, 영업 종료 멘트------------
//영업 중 멘트
const workInTextOnChange = (e) => {
    setWorkInValue(e.target.value);
    //console.log("영업 중 멘트 ===>", e.target.value);
}
//영업 종료 멘트
const workOutTextOnChange = (e) => {
    setWorkOutValue(e.target.value);
    //console.log("영업 종료 멘트 ===>", e.target.value);
}

//영업 중 멘트 미리듣기
const workInTextPrePlay = () => {
    ttsPrePlay(eachCheckInput, 
               workInValue,
               userInfo.dataset.defaultGrt.ringEngName
              );
    //console.log("영업 중 멘트 미리듣기 실행")
  }
//영업 종료 멘트 미리듣기
const workOutTextPrePlay = () => {
    ttsPrePlay(eachCheckInput, 
               workOutValue,
               userInfo.dataset.defaultGrt.ringEngName
              );
    //console.log("영업 종료 멘트 미리듣기 실행")
  }

  //------------토 영업 시간 별 설정 --> 영업시간 및 영업 중 멘트, 영업 종료 멘트------------
  
  //토요일 영업 중 멘트
const workSaInTextOnChange = (e) => {
    setWorkSaInValue(e.target.value);
    //console.log("토툐일 영업 중 멘트 ===>", e.target.value);
}
//토요일 영업 종료 멘트
const workSaOutTextOnChange = (e) => {
  setWorkSaOutValue(e.target.value);
  //console.log("토요일 영업 중 멘트 ===>", e.target.value);
} 

//토요일 영업 중 멘트 미리듣기
const workSaInTextPrePlay = () => {
  ttsPrePlay(eachCheckInput, 
             workSaInValue,
             userInfo.dataset.defaultGrt.ringEngName
            );
  //console.log("토요일 영업 중 멘트 미리듣기 실행")
}
//토요일 영업 종료 멘트 미리듣기
const workSaOutTextPrePlay = () => {
  ttsPrePlay(eachCheckInput, 
             workSaOutValue,
             userInfo.dataset.defaultGrt.ringEngName
            );
  //console.log("토요일 영업 종료 멘트 미리듣기 실행")
}

//------------일 영업 시간 별 설정 --> 영업시간 및 영업 중 멘트, 영업 종료 멘트------------
  
 //일요일 영업 중 멘트
 const workSunInTextOnChange = (e) => {
    setWorkSunInValue(e.target.value);
    //console.log("일요일 영업 중 멘트 ===>", e.target.value);
}
//일요일 영업 종료 멘트
const workSunOutTextOnChange = (e) => {
  setWorkSunOutValue(e.target.value);
  //console.log("일요일 영업 중 멘트 ===>", e.target.value);
} 

//일요일 영업 중 멘트 미리듣기
const workSunInTextPrePlay = () => {
  ttsPrePlay(eachCheckInput, 
             workSunInValue,
             userInfo.dataset.defaultGrt.ringEngName
            );
  //console.log("일요일 영업 중 멘트 미리듣기 실행")
}
//일요일 영업 종료 멘트 미리듣기
const workSunOutTextPrePlay = () => {
  ttsPrePlay(eachCheckInput, 
             workSunOutValue,
             userInfo.dataset.defaultGrt.ringEngName
            );
  //console.log("일요일 영업 종료 멘트 미리듣기 실행")
}


//------------쉬는 시간 별 설정 --> 영업시간 및 영업 중 멘트, 영업 종료 멘트------------

//쉬는시간멘트
const workBreakTextOnChange = (e) => {
  setWorkBreakValue(e.target.value);
  //console.log("1",e.target.value.length);
  //console.log("2",e.maxLength);
  // if(e.target.value.length > 200){ 
  //   layerPopup.showCommon(
  //     '#popup_onetitlemsgbutton', 
      
  //     null, 
  //     '글자 수 초과', 
  //     '200자 이내로 입력해 주세요',
      
  //     [
  //         function() {
  //             layerPopup.hide('#popup_onetitlemsgbutton');//닫기
  //         },
  //         // function() { // 확인 버튼
              
  //         //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
          

  //         // }
  //     ]
  // )
  // }else{
  //   setWorkBreakValue(e.target.value);
  //   //console.log("쉬는시간 멘트 ===>", e.target.value.length);
  // }
}

//쉬는시간 미리듣기
const workBreakTextPrePlay = () => {
    ttsPrePlay(eachCheckInput, 
               workBreakValue,
               userInfo.dataset.defaultGrt.ringEngName
              );
    //console.log("쉬는시간  멘트 미리듣기 실행")
  }



//------------------------------------------------------------------------




//여성, 남성, 아이 구분 각각 0,1,2
const speakerHandelClick = (e) => {

    //defalut값(여성 = 1) 유지하면서 다른 input 체크
    if(e.target.value === "1" || e.target.value ==="2")
     {
        setChecked(false)
    }
    else(
        setChecked(true)
    )
    setEachCheckInput(e.target.value);
}
const byteCheck = (text) => {
  
  let len = 0;
  for (var i = 0; i < text.length ; i++) {
      if (escape(text.charAt(i)).length === 6) {
          len++;
      }
      len++;
  }
  console.log("zzzzzdd",len);
  return len;
}
//저장 버튼
const saveOnHandleClick = () => {

    let eventControl = false;

    // console.log("제목확인", titleValue);
    // console.log("스피커 체크", eachCheckInput);
    // console.log("time 확인", time);
    // console.log("배경음 확인",  userInfo.dataset.defaultGrt.ringEngName);
    // console.log("평일 영업중 멘트 확인", workInValue);
    // console.log("평일 영업종료 멘트 확인", workOutValue);
    // console.log("토툐일 영업중 멘트 확인", workSaInValue);
    // console.log("토요일 영업종료 멘트 확인", workSaOutValue);
    // console.log("일요일 영업중 멘트 확인", workSunInValue);
    // console.log("일요일 영업종료 멘트 확인", workSunOutValue);
    // console.log("점심시간 멘트", workBreakValue);
    // 
    if(titleValue.trim().length < 2 || titleValue.trim().length > 20){
        layerPopup.showCommon(
			'#popup_onetitlebutton_threemsg', 
			
			null, 
			'제목 글자 수 알림', 
			'2자 이상 20자 이하로 입력해주세요.',
			
			[
				function() {
					layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
				},
				// function() { // 확인 버튼
				//   layerPopup.hide('#popup_onetitlebutton_threemsg');
				// }
			]
		)
        return;
    }
 

    if(((workInValue.trim().length) < 5 || (workInValue.trim().length) > 200) ||
    ((workOutValue.trim().length) < 5 || (workOutValue.trim().length) > 200) ||
    ((workSaInValue.trim().length) < 5 || (workSaInValue.trim().length) > 200) ||
    ((workSaOutValue.trim().length) < 5 || (workSaOutValue.trim().length) > 200) ||
    ((workSunInValue.trim().length) < 5 || (workSunInValue.trim().length) > 200) ||
    ((workSunOutValue.trim().length) < 5 || (workSunOutValue.trim().length) > 200) ||
    ((workBreakValue.trim().length) < 5 || (workBreakValue.trim().length) > 200)
      ){
        layerPopup.showCommon(
          '#popup_onetitlemsgbutton', 
          
          null, 
          '내용 글자 수 알림', 
          '5자 이상 200자 이하로 입력해주세요.',
          
          [
              function() {
                  layerPopup.hide('#popup_onetitlemsgbutton');//닫기
              },
              // function() { // 확인 버튼
                  
              //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
              
    
              // }
          ]
      )
      return;
    }
    if(workInValue === "" || workOutValue === "" ||
    workSaInValue === "" || workSaOutValue === "" ||
    workSunInValue === "" || workSunOutValue === "" ||
    workBreakValue === "" || time.pickerTime1 === "" ||
    time.pickerTime2 === "" ||  time.pickerTime3 === "" ||
    time.pickerTime4 === "" ||  time.pickerTime5 === "" ||
    time.pickerTime6 === "" ||  time.pickerTime7 === "" ||
    time.pickerTime8 === "" 
    ){
        layerPopup.showCommon(
			'#popup_onetitlebutton_threemsg', 
			
			null, 
			'입력 정보 확인', 
			'설정하지 않은 정보가 있습니다. <br/> 인사말, 영업 요일 등 빠진 항목이 <br/>없는지 확인해주세요.',
			
			[
				function() {
					layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
				},
				// function() { // 확인 버튼
				//   layerPopup.hide('#popup_onetitlebutton_threemsg');
				// }
			]
		)
        return;
    }else{
        makeTtsMoh(eventControl)//통화연결음 생성 API
    
    }

     
}


  
const goBackPage = () => {
    NativeBridge.sendUserActionLog('detail', 'D092', '', '시간대별');
    layerPopup.showCommon(
      '#make_call_before', 
      
      null, 
      '이전 화면으로 이동', 
      '통화연결음을 만들지 않고 이전화면으로 <br /> 이동하시겠어요?',
      
      [
          function() {
              layerPopup.hide('#make_call_before');//닫기
          },
          function() { // 확인 버튼
            userData.setBgCheck(true);
            layerPopup.hide('#make_call_before',() => {
              history.goBack();
          });
          }
      ]
  )
    //history.goBack();
  }
  
const funcDropdown = (x, e) => {
    document.getElementById(x).classList.toggle("hidden");
    e.classList.toggle('on')
}

const clickHandler = () => {
    //console.log("클릭");
}

const textAreaLabelControl = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel8")[0].style.display = "none";
     document.getElementsByName("openMent1")[0].focus();
    }
  }

  const textAreaLabelControl2 = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel9")[0].style.display = "none";
     document.getElementsByName("closeMent1")[0].focus();
    }
  }

  const textAreaLabelControl3 = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel10")[0].style.display = "none";
     document.getElementsByName("saOpenMent1")[0].focus();
    }
  }

  const textAreaLabelControl4 = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel11")[0].style.display = "none";
     document.getElementsByName("saCloseMent1")[0].focus();
    }
  }

  const textAreaLabelControl5 = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel12")[0].style.display = "none";
     document.getElementsByName("sunOpenMent1")[0].focus();
    }
  }

  const textAreaLabelControl6 = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel13")[0].style.display = "none";
     document.getElementsByName("sunCloseMent1")[0].focus();
    }
  }

  const textAreaLabelControl7 = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel14")[0].style.display = "none";
     document.getElementsByName("breakMent1")[0].focus();
    }
  }
  const inputClear = () => {
    setTitleValue("")
}

const saveButtonNone = () => {
  if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "none";
}
const saveButtonBlock = () => {
  if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "block";
}
const popupScrollControll = () => {
	if(userInfo.dataset.defaultGrt.ringEngName === "bg_01"){
		userData.setBgCheck(true);
	}
	layerPopup.show('#popup_prelisten');
  }

  console.log("pp", time);

  return (
    <>
			<div className="content store" onBlur={saveButtonBlock}>
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>시간대별 통화연결음</h2>
					</div>
				</div>
				<div className="ringback_greeting">
				    <div className="greet_sec">
				        <span className="title">통화연결음 이름</span>
				        <div className="input_box style2">
                            <input type="text"  placeholder="사용자 연결음" value={titleValue} onChange={(e) => tittleOnChange(e)} onFocus={saveButtonNone}/>
                            <span className="ip_clear hidden usetap" onClick={() =>{inputClear()}}>초기화</span>
                        </div>
				    </div>
                    <div className="greet_sec">
                        <span className="title">인사말</span>
                        <div className="tab_style4_radio tab3">
                           <input type="radio" id="sourceWoman" name="soundSource" value="0" checked={checked} onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceWoman">여성</label>
                            <input type="radio" id="sourceMan" name="soundSource" value="1" onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceMan">남성</label>
                            <input type="radio" id="sourceChild" name="soundSource" value="2" onChange={(e) => speakerHandelClick(e)} />
                                <label htmlFor="sourceChild">아이</label>
                        </div>
                    </div>
                    <div className="greet_sec">
                        <span className="title">배경음</span>
                        <button type="button" className="sel_sound"  value={userInfo.dataset.defaultGrt.ringEngName} onClick={()=>popupScrollControll()}>{userInfo.dataset.defaultGrt.ringName}<i className="btn_dark_down"></i></button>
                    </div>
				    {/* <!-- (월-금) 영업 시간 별 설정(필수) --> */}
                    {/* <button type="button" className="btn_drop_content on" onClick="this.classList.toggle('on');funcDropdown('timeContent1')">영업 시간 별 설정(필수)</button> */}
                    <button type="button" className="btn_drop_content on" onClick={(e)=>funcDropdown('timeContent1', e.currentTarget) }>영업 시간 별 설정&#x00028;필수&#x00029;</button>
                    <div className="drop_content" id="timeContent1">
                        <div className="greet_sec">
                            <span className="title">영업 시간 설정</span>
                            <div className="time_picker_wrap" onClick={()=>clickHandler()}>
                                <input type="text" className="time_picker" value={$('.time_picker').val() || ''} name="picker1" onClick={()=>clickHandler()} />
                                <span className="dash">~</span>
                                <input type="text" className="time_picker2" value={$('.time_picker2').val() || ''} name="picker"/>
                            </div>
                        </div>
                        <div className="greet_sec">

                            <span className="title">영업 중 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workInTextPrePlay()} >미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="openMent" name="openMent1" value={workInValue}    onChange={(e) => workInTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel8" style={{display : ""}} onClick={(e)=>textAreaLabelControl(e)}  >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                              </div>
                                  {/* <textarea id="alwaysCall" ></textarea> */}
                            
                        </div>
                        
                        <div className="greet_sec">
                            <span className="title">영업 종료 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workOutTextPrePlay()} >미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="closeMent" name="closeMent1" value={workOutValue}    onChange={(e) => workOutTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel9" style={{display : ""}} onClick={(e)=>textAreaLabelControl2(e)} >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                                 
                            </div>
                        </div>
                    </div>
                        {/* <!-- (토) 영업 시간 별 설정(필수) --> */}
				    <button type="button" className="btn_drop_content" onClick={(e)=>funcDropdown('timeContent2', e.currentTarget) }>(토) 영업 시간 별 설정(필수)</button>
				    <div className="drop_content hidden" id="timeContent2">
                        <div className="greet_sec">
                            <span className="title">영업 시간 설정</span>
                            <div className="time_picker_wrap">
                                <input type="text" className="time_picker3"  value={$('.time_picker3').val()}  name="picker1" />
                                <span className="dash">~</span>
                                <input type="text" className="time_picker4"  value={$('.time_picker4').val()} name="picker" />
                            </div>
                        </div>
                        <div className="greet_sec">
                            <span className="title">영업 중 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workSaInTextPrePlay()} >미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="saOpenMent" name="saOpenMent1" value={workSaInValue}    onChange={(e) => workSaInTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel10" style={{display : ""}} onClick={(e)=>textAreaLabelControl3(e)} >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                            </div>
                        </div>
                        <div className="greet_sec">
                            <span className="title">영업 종료 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workSaOutTextPrePlay()} >미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="saCloseMent" name="saCloseMent1" value={workSaOutValue}    onChange={(e) => workSaOutTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel11" style={{display : ""}} onClick={(e)=>textAreaLabelControl4(e)} >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                            </div>
                        </div>
                    </div>
                    {/* <!-- (일) 영업 시간 별 설정(필수) --> */}
				    <button type="button" className="btn_drop_content" onClick={(e)=>funcDropdown('timeContent3', e.currentTarget) }>(일) 영업 시간 별 설정(필수)</button>
				    <div className="drop_content hidden" id="timeContent3">
                        <div className="greet_sec">
                            <span className="title">영업 시간 설정</span>
                            <div className="time_picker_wrap">
                                <input type="text" className="time_picker5" value={$('.time_picker5').val()} name="picker1"/>
                                <span className="dash">~</span>
                                <input type="text" className="time_picker6" value={$('.time_picker6').val()} name="picker" />
                            </div>
                        </div>
                        <div className="greet_sec">
                            <span className="title">영업 중 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workSunInTextPrePlay()}>미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="sunOpenMent" name="sunOpenMent1" value={workSunInValue}    onChange={(e) => workSunInTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel12" style={{display : ""}} onClick={(e)=>textAreaLabelControl5(e)} >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                            </div>
                        </div>
                        <div className="greet_sec">
                            <span className="title">영업 종료 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workSunOutTextPrePlay()} >미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="sunCloseMent" name="sunCloseMent1" value={workSunOutValue}    onChange={(e) => workSunOutTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel13" style={{display : ""}} onClick={(e)=>textAreaLabelControl6(e)} >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 쉬는 시간 설정(필수) --> */}
				    <button type="button" className="btn_drop_content" onClick={(e)=>funcDropdown('timeContent4', e.currentTarget) }>쉬는 시간 설정(필수)</button>
				    <div className="drop_content hidden" id="timeContent4">
                        <div className="greet_sec">
                            <span className="title">쉬는 시간 설정</span>
                            <div className="time_picker_wrap">
                                <input type="text" className="time_picker7" value={$('.time_picker7').val()}name="picker1"/>
                                <span className="dash">~</span>
                                <input type="text" className="time_picker8" value={$('.time_picker8').val()} name="picker" />
                            </div>
                        </div>
                        <div className="greet_sec">
                            <span className="title">쉬는 시간 멘트</span>
                            <button type="button" className="ico_prelisten usetap blind" onClick={()=>workBreakTextPrePlay()} >미리듣기</button>
                            <div className="textarea_wrap textarea_greet">
                            <textarea  id="breakMent" name="breakMent1" value={workBreakValue}    onChange={(e) => workBreakTextOnChange(e)} onFocus={saveButtonNone}></textarea>
                              <label htmlFor="" className="textarea_label" id="abc" name="callLabel14" style={{display : ""}} onClick={(e)=>textAreaLabelControl7(e)} >
                                     예) 안녕하세요. 고객님 <br />
                                    잠시만 기다리시면 매장으로 연결됩니다.
                                      
                                      <span className="txt_limit">200자 이내</span>
                              </label>
                            </div>
                        </div>
                        
                    </div>
				</div>
        <div className="btn_box fixed" name="save_button">
                    <div className="inner">
                        <button type="button"  id="timeCallRing" className="btn_lg btn_point usetap" disabled="" onClick={()=>saveOnHandleClick()}>저장</button>
                    </div>
                </div>
				{/* <div className="btn_box fixed">
                    <div className="inner">
                        
                        <button type="button" id="timeCallRing" className="btn_half3 btn_line2 usetap" disabled="" onClick={()=>saveOnHandleClick()} >저장</button>
                    </div>
                </div> */}
			</div>
    </>
  );
});

export default TimeCallRing;