import React from "react";
import { observer } from "mobx-react";
import { layerPopup } from "../../libs/common";
import * as NativeBridge from '../../utils/NativeBridge';

const HomeCardEdit = observer(() => {

  const ClickHomeCardEditBtn = () => {
    //추가 - 12.08
    NativeBridge.sendUserActionLog('menu', 'H028');

    layerPopup.show('#popup_homecardedit');
  }

  return (
    <>
      <div className="edit_sec_card">
        <span className="title">홈 카드 편집</span>
        <p>홈 화면 메뉴 순서를 마음대로 편집해 보세요</p>
        <button type="button" className="btn_edit usetap blind" onClick={()=>ClickHomeCardEditBtn()}>홈 카드 편집</button>
      </div>
    </>
  );
});

export default HomeCardEdit;