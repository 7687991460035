import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store';
import $ from 'jquery';

const MyAccess = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    useEffect(() => {       
        console.log("Today default useEffect..");
		
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('bg_gray');
        }        

        return () => {
            if($('#wrap').hasClass('bg_gray')) {
                $('#wrap').removeClass('bg_gray');
                $('#wrap').addClass('gnb_bottom');
            }
        };
    }, []);

    const goBackPage = () => {
        history.goBack();
    }

    return (
        <>
			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>접근권한 안내</h2>
					</div>
				</div>
	            <div className="access_rights">
	                <p className="access_info">
	                    원활한 우리가게 서비스 이용을 위해 <br />
	                    아래 접근 권한 허용이 필요합니다.
	                </p>
	                <div className="sec_rights">
	                    <span className="title">위치(선택)</span>
	                    <p className="desc">
	                        가게 미등록 상태에서 날씨 정보 안내를 위해 <br />
	                        접근 허용이 필요합니다.
	                    </p>
	                </div>
	                <div className="sec_rights">
	                    <span className="title">주소록(선택)</span>
	                    <p className="desc">
	                        전화 서비스에서 모바일 연락처 동기화를 통한 <br />
	                        서비스 제공을 위해 접근 허용이 필요합니다.
	                    </p>
	                </div>
	            </div>
			</div>
        </>
    )
});

export default MyAccess;