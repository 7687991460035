import React, { useEffect, useState, useCallback } from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../utils/NativeBridge";
import { tabUi } from '../../../libs/tab'
import MsgLog from "./msglog/MsgLog";
import MsgSet from "./msgset/MsgSet";
import MessageSetPopup from '../../../components/popup/MessageSetPopup';
import { floatUI } from "../../../libs/floating";

//전화 설정 페이지
const MsgLogMain = observer(({ history, match }) => {
  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  const [callFowarding, SetCallFowarding] = useState(false);
  const [callLink, SetCallLink] = useState(false);
  const [callFowardingToggle, SetCallFowardingToggle] = useState(true);
  const [callLinkToggle, SetCallLinkToggle] = useState(true);

	useEffect(() => {
		tabUi.init3DepthTab(document.getElementById('tab_style4'));
    floatUI.init();
    if(userInfo.dataset.isTempUser === true){
      //MsgTapClick('msg','msglog');
    }
	}, []);

  const MsgTapClick = (currMenu="msg", subMenu='msglog') => {
    store.userData.setMsgLogTabList(subMenu);
    history.push(`/shop/${currMenu}/${subMenu}`);
    if(subMenu === 'msgset'){
        NativeBridge.sendUserActionLog('detail', 'D070');
    }else {
        NativeBridge.sendUserActionLog('detail', 'D071');
    }
    
  }

  return (
    <>
      <div className="fix_container msg_log" id="msg_log">
        <div className="tab_btn_wrap">
          
        {userInfo.dataset.isTempUser === false
            ?
          <div id="tab_style4" className="tab_style4 tab2">
           
            <>
            <button className={"tab_item" + (store.userData.dataset.shopMsgLogTab === 'msgset' ? " active" : "")} onClick={()=> MsgTapClick('msg','msgset')}>메시지 보내기</button>
            <button className={"tab_item" + (store.userData.dataset.shopMsgLogTab === 'msglog' ? " active" : "")} onClick={()=> MsgTapClick('msg','msglog')}>수발신 이력</button>
            </>
       
          </div>
          :
          <>
          </>
        }
        </div>
          { userInfo.dataset.isTempUser === false && store.userData.dataset.shopMsgLogTab === 'msgset' &&
              <Route component={MsgSet} />
          }
          { store.userData.dataset.shopMsgLogTab === 'msglog' &&
              <Route component={MsgLog} />
          }
      </div>

      <Route component={MessageSetPopup} />
    </>
  );
});

export default MsgLogMain;