import React from "react";
import { observer } from "mobx-react";
import store from "../../store";
import * as LocalStore from '../../store/LocalStore';
import { layerPopup } from "../../libs/common";
import {setCenterexUserIdPass} from '../../api/CentrexApi';
import SohoApi from "../../api/SohoApi";
import CentrexApi from "../../api/CentrexApi";
import * as CONSTS from "../../const";

const CentrexAuthErrorPopup = observer(({ history }) => {

    const { userInfo, userData } = store;
    const goNext = async () => {
        console.log("CentrexAuthErrorPopup CAll goNext");
        layerPopup.hide("#popup_centrex_auth_error", async()=> {
            if(LocalStore.getIsTempUser() !== true) {            
                SohoApi.getCentrexAuth({storeInetNo: LocalStore.getStoreInetNo()});
            } else {
                if(LocalStore.getStoreInetNo() && LocalStore.getTempInetPass()) {
                    const retCentrixUserInfo = await CentrexApi.getUserInfo({
                        id: LocalStore.getStoreInetNo(),
                        pass: LocalStore.getTempInetPass()
                    }, false);
                    if ( retCentrixUserInfo && retCentrixUserInfo.data.SVC_RT === CONSTS.CENTRIX_SVC_RT_SUCCESS ){
                        userData.setStoreInetNo(LocalStore.getStoreInetNo());
                        setCenterexUserIdPass(LocalStore.getStoreInetNo() , LocalStore.getTempInetPass());                    
                    }            
                } else {
                    console.log("임시사용자 로그인 정보 부족");
                }
            }    
        });
    };

    return (
        <>
            <div className="modal fadeIn" id="popup_centrex_auth_error">
                <div className="dim"></div>
                <div className="modal_inner">
                    <div className="modal_content">
                        <div className="modal_hd">알림</div>
                        <div className="modal_bd">
                            <p>인터넷전화 로그인에 실패하였습니다.</p>
                        </div>
                        <div className="modal_ft">
                            <button type="button" className="btn usetap" onClick={goNext}><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    );
});
export default CentrexAuthErrorPopup;