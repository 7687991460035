import React, {useEffect, useState} from 'react';
import { Route, Redirect, Link, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../../store';
import { layerPopup } from '../../../../libs/common';
import 'moment/locale/ko';
import moment from 'moment';
import $ from 'jquery';
import * as NativeBridge from '../../../../utils/NativeBridge';
import { tabUi } from '../../../../libs/tab';
import { floatUI } from '../../../../libs/floating';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast } from '../../../../libs/common';
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";
import SohoApi from '../../../../api/SohoApi';
import CryptoJS from 'crypto-js';
import * as LocalStore from '../../../../store/LocalStore';

SwiperCore.use([ Pagination, Scrollbar ]);



const MyFamilySet = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);

	const [familyAccountListInfo, setFamilyAccoutListInfo] = useState([]);
   
    useEffect(() => {       
		familyAccountList(); //패밀리 계정 조회
		if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('bg_gray');
          }          
          return () => {
            if($('#wrap').hasClass('bg_gray')) {
              $('#wrap').removeClass('bg_gray');
              $('#wrap').addClass('gnb_bottom');
            }
          };
    }, []);

	//패밀리 계정조회
	const familyAccountList = async () => {
		const familyAccountData = await SohoApi.getFamilyAccountList({
			
		});

		const getFamilyAccount = familyAccountData.data;

		setFamilyAccoutListInfo(getFamilyAccount);
		console.log("패밀리 계정 조회 ===>", familyAccountData);

		// if(getFamilyAccount.list.length < 1){
		// 	console.log("@@@ㅇㅇ");
		// 	$('content.my .my_family_account p').removeAttr( 'border-top' );
		// }

	}

	//패밀리 계정등록 --> 초대 재발송을 위함
	const familyAccountRegi = async (num) => {
		const familyAccountRegiData = await SohoApi.getFamilyAccountRegi({
			mblTlno : num // 사용자 전화번호
		})

		const getFamilyAccountRegi = familyAccountRegiData;
		console.log("패밀리 계정 등록 확인 ===>", getFamilyAccountRegi);
	}

	//패밀리 계정삭제 
	const familyAccountDelete = async (num, id) => {
		const familyAccountDeleteData = await SohoApi.getFamilyAccountDelete({
			famId : id,// familyID 암호화 No --> 복호화 해야함
			mblTlno : num//사용자 전화번호
		})

		const getFamilyAccountDelete = familyAccountDeleteData;
		console.log("패밀리 계정 삭제 확인 ===>", getFamilyAccountDelete);

		if(getFamilyAccountDelete.retCode === "0000"){
			familyAccountList();
		}
	}

	//초대 재발송 함수
	const familyReIviteClick = (e) => {
		const reInveteNum = e.target.value;

		if(reInveteNum){
			layerPopup.showCommon(
				'#popup_onetitle_twomsgbutton', 
				
				null, 
				'알림', 
				'초대 문자메시지를 다시 보내시겠어요?',
				
				[
					function() {
						layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
					},
					function() { // 확인 버튼
						familyAccountRegi(reInveteNum);
						toast("초대 문자메시지를 다시 보냈습니다.")
					  	layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
					  	console.log("삭제완료");
					}
				]
			)
		}
	}

	//패밀리 계정 삭제 함수
	const familyAccountRemove = (e) => {
		console.log("삭제할 번호 ===>", e.target.value);
		console.log("삭제할 familId ===>", e.target.name);
        NativeBridge.sendUserActionLog('detail', 'D063')

		const removeId = e.target.name;
		const removeNum = e.target.value;

		if(removeId || removeNum){
			layerPopup.showCommon(
				'#image_remove_popup', 
				null, 
				'초대 계정 삭제하기', 
				'선택한 계정을 삭제하시겠어요?<br />삭제한 계정은 우리 가게를 관리할 수<br /> 없습니다.',
				[
					function() {
                        NativeBridge.sendUserActionLog('detail', 'D065');
						layerPopup.hide('#image_remove_popup');//닫기
					},
					function() { // 확인 버튼
                        NativeBridge.sendUserActionLog('detail', 'D064');
						familyAccountDelete(removeNum, removeId);
						// familyAccountList();
					  	layerPopup.hide('#image_remove_popup');//닫기
					  	console.log("삭제완료");
					}
				]
			)
		}
	}

    const goPhoneNumAdd = () => {
        NativeBridge.sendUserActionLog('detail', 'D062')
		//패밀리 게정 5개 초과 일 때 팝업창 띄움
		if(familyAccountListInfo?.list.length === 5)
		{
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				
				null, 
				'초대 계정 수 초과', 
				'최대 5명까지 초대할 수 있습니다.',
				
				[
					function() {
						layerPopup.hide('#popup_onetitlemsgbutton');//닫기
					},
				]
			)
		} else {
        	history.push('/my/mysetting/familysetting/add')
		}
	}

	const goBackPage = () => {
        history.goBack();
    }

    return (
        <>
 			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>초대 계정 관리</h2>
					</div>
				</div>
	            <div className="my_family_account">
	                <span className="title">초대 계정</span>
	                <button type="button" className="btn_round purple usetap" onClick={()=> goPhoneNumAdd()}>초대하기</button>
	               
					<ul>
						{familyAccountListInfo?.list?.map((item, idx) => (
							<li key={idx}>
								{item.reqStus === "1" &&
								  <>
								    <span className="i_num">{item.mblTlno?.toString().replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**')}</span>
									<span className="i_state">초대 완료</span>
									<button type="button" name={item.famId} value={item.mblTlno} className="btn_small_x usetap blind" onClick={(e)=>familyAccountRemove(e)} >삭제</button>
								  </>
								}
								{item.reqStus === "3" &&
								  <>
									<span className="i_num">{item.mblTlno?.toString().replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**')}</span>
									<span className="i_state">초대 기간 만료<span className="expire_msg">삭제 후 다시 초대 해주세요</span></span>
									{/* <button type="button" value={item.famctn} className="btn_round2 green usetap" onClick={(e)=>familyReIviteClick(e)} >다시 초대</button> */}
									<button type="button" name={item.famId} value={item.mblTlno} className="btn_small_x usetap blind" onClick={(e)=>familyAccountRemove(e)}>삭제</button>
								  </>
								}
								{item.reqStus === "0" &&
								  <>
									<span className="i_num">{item.mblTlno?.toString().replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**')}</span>
	                       			<span className="i_state">초대 응답 대기중</span>
                            		{/* <button type="button" className="btn_round2 red usetap">초대 취소</button> */}
                            		<button type="button" name={item.famId} value={item.mblTlno} className="btn_small_x usetap blind" onClick={(e)=>familyAccountRemove(e)}>삭제</button>
								  </>
								
								}
								{item.reqStus === "2" &&
								  <>
									<span className="i_num">{item.mblTlno?.toString().replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**')}</span>
	                        		<span className="i_state">초대 거절</span>
                            		<button type="button" name={item.famId} value={item.mblTlno} className="btn_small_x usetap blind" onClick={(e)=>familyAccountRemove(e)} >삭제</button>
								  </>
								}
							</li>
							
						))}
	                </ul>
					{familyAccountListInfo.list?.length > 0 ?
						<p >
							가족, 직원을 초대 계정으로 등록하면<br />
							U+우리가게 서비스를 통해 우리 가게를 함께 관리할 수 있습니다.
						</p>
					:
					<div  className = "family_manage">
	                    가족, 직원을 초대 계정으로 등록하면<br />
	                    U+우리가게 서비스를 통해 우리 가게를 함께 관리할 수 있습니다.
	                </div>
					}
	            </div>
			</div>
        </>
    ) 
});

export default MyFamilySet;