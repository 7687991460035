import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store';
import $ from 'jquery';

const MyPolicySet = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);
   
    useEffect(() => {       
        console.log("Today default useEffect..");
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').addClass('bg_gray');
          }          
          return () => {
            if($('#wrap').hasClass('bg_gray')) {
              $('#wrap').removeClass('bg_gray');
              $('#wrap').addClass('gnb_bottom');
            }
          };
    }, []);

    const goBackPage = () => {
        history.goBack();
    }

    const TermsAccessClick = (e, subMenu=null) => {
        // userData.setTermsTabList(subMenu);
        history.push(`/my/mysetting/policyaccess/${subMenu}`)
    }

    return (
        <>
			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>약관 자세히 보기</h2>
					</div>
				</div>
				<div className="my_setting">
                    <div className="sec_wrap">
                        <div className="set_wrap usetap" onClick={(e)=> TermsAccessClick(e.target, 'termslist')}>
                            <span className="set_title" >약관 상세 보기</span>
                            <span className="i_more"></span>
                        </div>
                        {/* <div className="set_wrap usetap" onClick={(e)=> TermsAccessClick(e.target, 'accesslist')}>
                            <span className="set_title" >접근 권한 안내</span>
                            <span className="i_more"></span>
                        </div> */}
                    </div>
				</div>
			</div>
        </>
    )
    
});
export default MyPolicySet;