import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as LocalStore from '../../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../../utils/NativeBridge';
import { tabUi } from '../../../libs/tab'
import { webUI } from '../../../libs/common';
import SohoApi from '../../../api/SohoApi';
import BizApi from '../../../api/BizApi'

const OneDepthTap = observer(() => {

    const { userInfo, userData } = store;
	var StoreNmIndex = 0

    useEffect(() => {
		initTabMenu();
    }, []);

	const initTabMenu = () => {
		if($('#tab_1depth ul li').length > 0 ) {
			let container = document.getElementById('tab_1depth');
			StoreNmIndex = LocalStore.getStoreList().map((item, index) => item.storeId).indexOf(LocalStore.getStoreId());
			tabUi.initSubTab(container, StoreNmIndex);
			webUI.animatedScrollTo(document.getElementById('tab_1depth'),(container.querySelectorAll('li')[StoreNmIndex].offsetLeft + 
			container.querySelectorAll('li')[StoreNmIndex].clientWidth * 0.5) - (container.clientWidth * 0.5), 300);
		}
	}


	const ShopClick = async(storeId= null, storeNm= null) => {
        // userData.setThumbnail([]);
        // userData.setSplitThumbnail([]);

		// userData.setStoreId(storeId);
		// userData.setStoreNm(storeNm);
        // StoreNmIndex = LocalStore.getStoreList().map((item, index) => item.storeId).indexOf(userData.dataset.storeId);
		// currstoreInfo = LocalStore.getStoreList();
		// userData.setStoreNmIndex(StoreNmIndex)
		// userData.setCurrStoreInfo(currstoreInfo[StoreNmIndex]);

		// let currHomeCard = LocalStore.getHomeCard(storeId)

		// if(currHomeCard === null || (Array.isArray(currHomeCard) && currHomeCard.length === 0)) {
		// 	const TapClickStoreList = await SohoApi.setHomeCardInfo({
		// 		'ownerUserId': LocalStore.getOwnerId(),
		// 		'storeId' : LocalStore.getStoreId()
		// 	});
		// 	userData.setHomeList(TapClickStoreList.data.list, false)
		// } else {
		// 	userData.setHomeList(currHomeCard)
		// }

		NativeBridge.sendUserActionLog('menu', 'H002');

		//TODO : 스토어 변경시 필요한 프로세스 진행s
		await BizApi.procChgStore(storeId);
	}

    return (
        <>
			{/* 설정 아이콘이 붙을 시 set 클래스 추가 */}
			<div className="tab_1depth_wrap fix">
				<div className="tab_1depth" id="tab_1depth">
					<ul className="tab_link">
						{ userData.dataset.storeList?.length > 0 &&
							userData.dataset.storeList?.map((item, index) => (
								index < 15 &&
								<li className={"tab_item" + (item.storeId === LocalStore.getStoreId() ? " active" : "")} key={item.storeId} id={item.storeId} onClick={()=> ShopClick(item.storeId, item.storeNm)} >
									<span>{item.storeNm}</span>
								</li>
							))
						}
					</ul>
					<div className="active_bar"></div>
				</div>
			</div>
        </>
    )
});

export default OneDepthTap;