import { observer } from 'mobx-react'
import React from 'react'
import * as NativeBridge from '../../utils/NativeBridge'

const OrderUnSub = observer(({ history, match }) => {

    const MenuEditClick = () => {
        NativeBridge.externalCall('https://pf.kakao.com/_xjPxaWb');
    }

    return (
        <>
            <div className="fix_container">
                <div className="flow_wrap">
                    <h1>메뉴판 사진만 찍으면<br /> 영상 메뉴판을 뚝딱 만들어 드려요</h1>
                    <div className="step_box">
                        <span className="step_num">STEP 1</span>
                        <p className="step_desc">아래 ‘메뉴판 만들기’ 진입 후, <br /><b>카카오톡 채널 “씨식가”를 추가</b>해주세요.</p>
                        <img src="/res/images/img-unuser-1@3x.png" alt='미가입 이미지1' />
                    </div>
                    <div className="step_box">
                        <span className="step_num">STEP 2</span>
                        <p className="step_desc"><b>‘신규 메뉴판 생성’</b>을 선택해주세요.</p>
                        <img src="/res/images/img-unuser-2@3x.png" alt='미가입 이미지2' />
                    </div>
                    <div className="step_box">
                        <span className="step_num">STEP 3</span>
                        <p className="step_desc">안내 문구를 따라 <br /><b>메뉴판 사진을 업로드</b> 해주세요.</p>
                        <img src="/res/images/img-unuser-3@3x.png" alt='미가입 이미지3' />
                    </div>
                    <div className="btn_box fixed is_gnb">
                        <div className="inner">
                            <button type="button" className="btn_lg btn_point usetap" onClick={() => MenuEditClick()}>메뉴판 만들기</button>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
});

export default OrderUnSub