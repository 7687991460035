import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import { tabUi } from '../../libs/tab';
import $ from 'jquery';
import SohoApi from "../../api/SohoApi"
import * as NativeBridge from '../../utils/NativeBridge'
import InfiniteScroll from 'react-infinite-scroll-component';

let bnfCatePage = 1;
const CeoCommunity = observer(() => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const [category, setCategory] = useState(null);
    const [bnfcategory, setBnfCategory] = useState([]);
    const [bnfcategorydetail, setBnfCategoryDetail] = useState([]);
    const [cateIdx, setCatgIdx] = useState("");
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        bnfCatePage = 1;
        categoryData();
        bnfcategoryData("");
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').addClass('bg_gray');
        }      
        $("html, body").removeClass("noscroll");
        $(window).on('scroll', function() {
            const scrollTop = $(window).scrollTop()
            if(scrollTop <= 0) {
                setHasMore(false)
            } else {
                setHasMore(true);
            }
        });
        tabUi.initSubTab(document.getElementById('tab_1depth'), 0);

        return () => {
            if($('#wrap').hasClass('gnb_bottom')) {
                $('#wrap').removeClass('bg_gray');
            }
            bnfCatePage = 1;
            $(window).off('scroll');
        }
    }, []);


    useEffect(()=>{

        console.log("userData.dataset.bnf useeffect");

        //-- 초기화
        if(category && userData.dataset.bnfCateId !== '' && userData.dataset.bnfId !== '') {

            console.log("userData.dataset.bnfCateId: ", userData.dataset.bnfCateId);
            console.log("userData.dataset.bnfId: ", userData.dataset.bnfId);

            //bnfcategoryData(userData.dataset.bnfCateId);
            //tabUi.activeBar(2, document.getElementById('tab_1depth'));

            const bnfId = userData.dataset.bnfId;
            const bnfCateId = userData.dataset.bnfCateId;
            try {
                $("ul.tab_link li#"+bnfCateId).trigger("click");
                 bnfcategoryData(bnfCateId);

                if(bnfId) {
                    setTimeout(()=>{
                        try {
                            const offset = $("li#"+bnfId).offset(); 
                            const headerHeight = $(".hd_fix_wrap") ? $(".hd_fix_wrap").height() : 0; 
                            $('html').scrollTop(offset.top-headerHeight);
                            //$('html').animate({scrollTop : offset.top-headerHeight}, 400);
                        } catch(e) {
                            console.log(e);
                        }
                    }, 50);
                }
            } catch(e) {
                console.log(e);
            }
            userData.setBnfCateId('');
            userData.setBnfId('');
        }

    }, [userData.dataset.bnfCateId, userData.dataset.bnfId, category]);

    /*
    useEffect(() => {

        if ( bnfcategory !== null && bnfcategory.length > 0 ){
            bnfcategory.map((item, idx) => {
                bnfcategorydetailData(item.bfnId);
            });
        }
        
    }, [bnfcategory]);
    */

    const categoryData = async () => {
        const result = await SohoApi.getCategoryList();
        if ( result.retCode === "0000"){
            setCategory(result.data);
            console.log("categoryData", result.data);
            const categoryList = result.data;

            if ( categoryList !== null && categoryList.length > 0 ){
                bnfcategoryData(categoryList[0].catgId);
            }

        }
    }

    const bnfcategoryData = async (id, page=1) => {
        console.log("id3",id)
        const bnfcategoryList = await SohoApi.getBnfCategoryList(
        { 
            catgId: id === "" ? id : id, 
            catgType: id === "" ? 'A' : 'M', 
            pagePerRows: '20', 
            bookmark: page 
        });
        let list = bnfcategoryList.data.list;
        if(!Array.isArray(list)) {
            list = [];
        }
        setBnfCategory(bnfCategory=>bnfCategory.concat(list));
        //console.log("bnfcategoryData", bnfcategoryList.data);
    } 
    const bnfcategorydetailData = async (_bnfId) => {
        const bnfcategorydetailList = await SohoApi.getBnfDetailList({ bnfId: _bnfId});
        setBnfCategoryDetail(bnfcategorydetailList.data);
        console.log('bnfcategorydetailData', bnfcategorydetailList.data);
    } 

    const tapClickHandler = (e) => {
        console.log("onClick")
        let id = e.currentTarget.getAttribute('value');

        bnfCatePage = 1;
        setBnfCategory([]);
       
        console.log("id1",id)
        if(id === "no"){
            bnfcategoryData("");
        }else{
            console.log("id2",id)
            bnfcategoryData(id);
        }
    }

    const goPageOnClick = (urls=null, bnfId='') => {
        NativeBridge.sendUserActionLog('detail', 'D050', '', `${bnfId}`);
        NativeBridge.externalCall(`${urls}`)
    }

    const getNextBnf = () => {

        let bnfId = '';
        if($(".content.ceo ul li.tab_item.active")) {
            bnfId = $(".content.ceo ul li.tab_item.active").attr("id");
        }
        bnfCatePage++;
        bnfcategoryData(bnfId, bnfCatePage);
    }

    return (
        <>
        <div className="content ceo">
            <div className="hd_fix_wrap">
                <div className="hd_top2">
                    <div className="hd_top_inner">
                        <button type="button" className="hd_sel_lounge usetap active">사장님광장</button>
                    </div>
                </div>
                <div className="tab_1depth_wrap">
                    <div className="tab_1depth" id="tab_1depth">
                        <ul className="tab_link">
                            <li className="tab_item active" id="" value="no" onClick={(e)=>tapClickHandler(e)}><span value="no"name="test">전체</span></li>
                            { category !== null &&
                                category?.list.map((item, index)=>(
                                    <li id={item.catgId} className="tab_item" value={item.catgId} name="test" ><span value={item.catgId} onClick={(e)=>tapClickHandler(e)}>{item.catgNm}</span></li>
                                ))
                            }
                        </ul>
                        <div className="active_bar" style={{ left: '21px', width: '34px' }}></div>
                    </div>
                </div>
            </div>
            <div className="fix_container">
                    <ul className="ceo_community">

                        { Array.isArray(bnfcategory) && bnfcategory.length ?

                            <InfiniteScroll
                                dataLength={bnfcategory.length}  
                                next={getNextBnf}
                                hasMore={hasMore}
                                scrollThreshold={'2px'}>

                                { bnfcategory?.map((item, index) => (     
                                    <li key={item.bnfId} id={item.bnfId}>
                                        <span className="title">{item.tit}</span>
                                        <div className="cont">
                                            <span className="thumb" style={{backgroundImage:`url(${item.cntnImge})`}} onClick={()=> goPageOnClick(`${item.urlConnLnk}`, item.bnfId)}></span>
                                            <p className="desc_wrap" dangerouslySetInnerHTML={ {__html: item?.cntn} }></p>
                                        </div>
                                    </li>
                                ))
                            }
                            </InfiniteScroll>
                            :
                            <></>
                        }
                    </ul>
                </div>
            </div>
        </>
    )
    
});
export default CeoCommunity;