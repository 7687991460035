// import * as URLs from '../const';
 import { layerPopup } from '../libs/common';
import { getGroup, sortHanWordsList } from '../common/common';
import store from "../store";
import SohoApi from './SohoApi';
import CentrexApi from './CentrexApi';
import { setCenterexUserIdPass } from './CentrexApi';
import * as CONSTS from '../const';
import * as LocalStore from '../store/LocalStore';
import * as SessionStore from '../store/SessionStore';
import CctvApi from './CctvApi';
import { setUserDataStoreListByOwnerId } from '../components/login/DefaultSet';

let cctvErrorCnt = 0;
const { userData, userInfo } = store;

    // eslint-disable-next-line import/no-anonymous-default-export
    export default {

        async homeInto(toStoreId) {
            console.log("procChgStore call");

            userData.setProcChgStoreComplete(false);
            console.log("userData.dataset.procChgStoreComplete : biz : false");
            // userData.setStoreId(toStoreId);
            

            const _isTempUserYN = LocalStore.getIsTempUser() === null ? false : LocalStore.getIsTempUser();
            
            if ( !_isTempUserYN ) {
                //console.log("call procChgStore:" + toStoreId);
                let storeList = userData.dataset.storeList;

                // let selectedStoreInfo = (query) => {
                //     return storeList.filter((_item) => _item.storeId.equals(toStoreId))
                // }
                let selectedStoreInfo = storeList.find((item) => {
                    return item.storeId === toStoreId;
                });

                console.log("[BIZAPI.PROCCHGSTORE] SELECTED STORE INFO=>", selectedStoreInfo);

                if (selectedStoreInfo !== undefined && selectedStoreInfo !== null) {

                    var _storeInetNo = selectedStoreInfo.lpzNo;
                    var _cctvNo = selectedStoreInfo.lzpEntrNo;

                    //===============================================================
                    //센트릭스 SSO 요청
                    //===============================================================
                    if ( _storeInetNo !== '' && _storeInetNo !== null){
                        // await SohoApi.getCentrexAuth({"storeInetNo":_storeInetNo});

                        userData.setStoreInetNo(_storeInetNo);
                        //전화번호 만들기
                        // this.makeCentrixPhonebookList();
                    }else {
                        //센트릭스 미설정 매장인 경우 token 초기화 필요
                        SessionStore.removeCentrexKey();
                        LocalStore.removeCentrexKey();
                        LocalStore.removeExtenInetNo();
                        userData.setStoreInetNo(null);                
                    }

                    //===============================================================
                    //CCTV SSO 요청
                    //===============================================================
                    if ( _cctvNo !== undefined && _cctvNo !== '' && _cctvNo !== null){
                        //CCTV는 인증요청 안해도 됨. 인증기간이 김.
                        console.log("[BIZAPI.PROCCHGSTORE] _cctvNo=>", _cctvNo)
                        // const retCctv = await SohoApi.getCctvAuth();

                    } else {
                        //매장에 cctv가 설정되지 않은 경우 token 초기화 필요
                        LocalStore.setCctvToken(null);
                        SessionStore.setCctvToken(null);   
                    }
                } else {
                    // -- 로직 모두 끝났다는 신호
                    userData.setProcChgStoreComplete(true);
                    //-- 잠시 후 원래대로 원복 
                    setTimeout(()=>{
                        userData.setProcChgStoreComplete(false);
                    }, 500);

                    console.log("userData.dataset.procChgStoreComplete : biz : true1");
                    return false;
                }
                // userData.setCurrStoreInfo(selectedStoreInfo);
                // userData.setStoreId(selectedStoreInfo.storeId);
                // userData.setStoreNm(selectedStoreInfo.storeNm);
            } else {
                let _cams = [];
                let camIds = [];
                let _cam_device_sn_id = [];
                if ( LocalStore.getCctvToken() !== undefined && LocalStore.getCctvToken() !== null ){
                    let cameras = {};
                    try {
                        cameras = await CctvApi.getCameras();
                    } catch(e) {
                        cameras = {
                            data: {
                                list : []
                            }
                        };
                    }  

                    let cammerasData = [];
                    try {
                        cammerasData = cameras.data.list;
                    } catch (e) {
                        cammerasData = [];
                    }                    
                    cammerasData?.map((camera) => {
                        const cam = {};
                        cam.camId = camera.camId;
                        cam.camName = camera.camName;
                        cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
                        cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
                        cam.camStatus = camera.camStatus;
                        _cams.push(cam);

                        if (camera.device_sn_id.length > 0) {
                            _cam_device_sn_id.push(camera.device_sn_id)
                        }

                        camIds.push(camera.camId);
                    });
                }

                const centrixId = LocalStore.getStoreInetNo();
                if ( centrixId !== undefined && centrixId !== null && centrixId !== '' ) {
                    //전화번호 만들기
                    this.makeCentrixPhonebookList();
                }

                let storeList = [
                    {
                        storeId : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_ID,
                        storeNm : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_NM,
                        lpzEntrNo: centrixId,
                        lpzNo : centrixId,
                        lzpEntrNo : "",
                        camId: camIds?camIds.join(","):"",
                        cam_device_sn_id: _cam_device_sn_id,
                        cams : _cams,
                        storeAddr: "",
                        rntSpc: "",
                        storeAddrDetl: "",                    
                        storeRnt: "",
                        storeInetNo: ""                    
                    }
                ];
                userData.setStoreList(storeList);
                userData.setCurrStoreInfo(storeList[0]);
                userData.setStoreId(storeList[0].storeId);
                userData.setStoreNm(storeList[0].storeNm);
            }

            await this.makeSsgJoinInfo(toStoreId);
            await this.makeMyService();
            await this.InitSvcCard();

            //에러코드 복귀
            userData.setShopMainTabCallError(false);
            userData.setShopMainTabMsgError(false);

            // -- 로직 모두 끝났다는 신호
            userData.setProcChgStoreComplete(true);
            //-- 잠시 후 원래대로 원복 
            setTimeout(()=>{
                userData.setProcChgStoreComplete(false);
            }, 500);

            console.log("userData.dataset.procChgStoreComplete : biz : true2");
            return true;
        },

        // 매장변경
        async procChgStore(toStoreId) {

            console.log("procChgStore call");

            userData.setProcChgStoreComplete(false);
            console.log("userData.dataset.procChgStoreComplete : biz : false");
            // userData.setStoreId(toStoreId);
            

            const _isTempUserYN = LocalStore.getIsTempUser() === null ? false : LocalStore.getIsTempUser();
            
            if ( !_isTempUserYN ) {
                //console.log("call procChgStore:" + toStoreId);
                let storeList = userData.dataset.storeList;

                // let selectedStoreInfo = (query) => {
                //     return storeList.filter((_item) => _item.storeId.equals(toStoreId))
                // }
                let selectedStoreInfo = storeList.find((item) => {
                    return item.storeId === toStoreId;
                });

                console.log("[BIZAPI.PROCCHGSTORE] SELECTED STORE INFO=>", selectedStoreInfo);

                if ( selectedStoreInfo !== undefined && selectedStoreInfo !== null){

                    var _storeInetNo = selectedStoreInfo.lpzNo;
                    var _cctvNo = selectedStoreInfo.lzpEntrNo;

                    //===============================================================
                    //센트릭스 SSO 요청
                    //===============================================================
                    if ( _storeInetNo !== '' && _storeInetNo !== null){

                        console.log("[BIZAPI.PROCCHGSTORE] storeInetNo=>", _storeInetNo);
                        
                        const centrexAuthInfo = await SohoApi.getCentrexAuth({"storeInetNo":_storeInetNo});

                        /* : 오류팝업 처리는 SohoApi.getCentrexAuth 내에서 일괄 처리 하고 있어 주석처리함. (20211022)
                        if ( centrexAuthInfo.retCode !== CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                            //console.log(centrexAuthInfo.retMsg);
                            //오류일때 처리방안.
                            //메시지 표기

                            layerPopup.showCommon(
                                '#popup_onetitlesentence_onebutton_cctv',
                                null,
                                '안내',
                                "인터넷 전화 로그인이 실패하였습니다.\n다시 시도해주세요.",
                                [
                                    function() {
                                        layerPopup.hide('#popup_onetitlesentence_onebutton_cctv', ()=> {
                                            // SohoApi.getCentrexAuth({"storeInetNo":_storeInetNo});
                                        })
                                    }
                                ]
                            );

                            //return false;
                        }
                        */

                        userData.setStoreInetNo(_storeInetNo);
                        // if(location.pathname === "/shop/call/phonebook/phonebook") {
                            //전화번호 만들기
                            this.makeCentrixPhonebookList();
                        // }

                        
                    }else{
                        //센트릭스 미설정 매장인 경우 token 초기화 필요
                        SessionStore.removeCentrexKey();
                        LocalStore.removeCentrexKey();
                        LocalStore.removeExtenInetNo();
                        userData.setStoreInetNo(null);                
                    }

                    //===============================================================
                    //CCTV SSO 요청
                    //===============================================================
                    if ( _cctvNo !== undefined && _cctvNo !== '' && _cctvNo !== null){
                        //CCTV는 인증요청 안해도 됨. 인증기간이 김.
                        console.log("[BIZAPI.PROCCHGSTORE] _cctvNo=>", _cctvNo);
                        await SohoApi.getCctvAuth();

                    } else {
                        //매장에 cctv가 설정되지 않은 경우 token 초기화 필요
                        LocalStore.setCctvToken(null);
                        SessionStore.setCctvToken(null);   
                    }

                    userData.setStoreId(selectedStoreInfo.storeId);
                    userData.setStoreNm(selectedStoreInfo.storeNm);
                    await this.makeSsgJoinInfo(toStoreId);
                    await this.makeMyService();
                    await this.InitSvcCard();
                    userData.setCurrStoreInfo(selectedStoreInfo);
                    
                } else {
                    // -- 로직 모두 끝났다는 신호
                    userData.setProcChgStoreComplete(true);
                    //-- 잠시 후 원래대로 원복 
                    setTimeout(()=>{
                        userData.setProcChgStoreComplete(false);
                    }, 500);

                    console.log("userData.dataset.procChgStoreComplete : biz : true1");
                    return false;
                }
            } else {

                let _cams = [];
                let camIds = [];
                let _cam_device_sn_id = [];
                if ( LocalStore.getCctvToken() !== undefined && LocalStore.getCctvToken() !== null ){
                    let cameras = {};
                    try {
                        cameras = await CctvApi.getCameras();
                    } catch(e) {
                        cameras = {
                            data: {
                                list : []
                            }
                        };
                    }  

                    let cammerasData = [];
                    try {
                        cammerasData = cameras.data.list;;
                    } catch (e) {
                        cammerasData = [];
                    }                    
                    cammerasData?.map((camera) => {
                        const cam = {};
                        cam.camId = camera.camId;
                        cam.camName = camera.camName;
                        cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
                        cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
                        _cams.push(cam);

                        if (camera.device_sn_id.length > 0) {
                            _cam_device_sn_id.push(camera.device_sn_id)
                        }

                        camIds.push(camera.camId);
                    });
                }

                const centrixId = LocalStore.getStoreInetNo();
                if ( centrixId !== undefined && centrixId !== null && centrixId !== '' ) {
                    //전화번호 만들기
                    this.makeCentrixPhonebookList();
                }

                let storeList = [
                    {
                        storeId : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_ID,
                        storeNm : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_NM,
                        lpzEntrNo: centrixId,
                        lpzNo : centrixId,
                        lzpEntrNo : "",
                        camId: camIds?camIds.join(","):"",
                        cam_device_sn_id: _cam_device_sn_id,
                        cams : _cams,
                        storeAddr: "",
                        rntSpc: "",
                        storeAddrDetl: "",                    
                        storeRnt: "",
                        storeInetNo: ""                    
                    }
                ];

                userData.setStoreList(storeList);
                userData.setStoreId(storeList[0].storeId);
                await this.makeSsgJoinInfo(toStoreId);
                await this.makeMyService();
                await this.InitSvcCard();
                userData.setCurrStoreInfo(storeList[0]);
                userData.setStoreNm(storeList[0].storeNm);
            }

            //에러코드 복귀
            userData.setShopMainTabCallError(false);
            userData.setShopMainTabMsgError(false);

            // -- 로직 모두 끝났다는 신호
            userData.setProcChgStoreComplete(true);
            //-- 잠시 후 원래대로 원복 
            setTimeout(()=>{
                userData.setProcChgStoreComplete(false);
            }, 500);

            console.log("userData.dataset.procChgStoreComplete : biz : true2");

            return true;            
        }
        ,async makeSsgJoinInfo(_storeId) {
            try {
                await SohoApi.getSsgJoinInfo({
                    storeId : _storeId
                }).then((rst) => {
                    if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                        userData.setSissicgaRegiYn(rst.data.ssgJoinYn);
                    }
                }).catch((e) => {
                    console.log(e);
                    userData.setSissicgaRegiYn('N');
                })
            } catch (e) {
                console.log(e);
                userData.setSissicgaRegiYn('N');
            }
        }
        ,async makeCentrixPhonebookList(_flag = "") {

            let centrixPhoneBookData = [];
            let afterData = [];

            if ( userInfo.dataset.myservice.call ){
                
                let errorCnt = 0;
                let _page = 1;

                do {
                    let ret = await CentrexApi.getPhoneBookList({
                        page : _page + ""
                        ,num_per_page : 100
                    });
                    //console.log("makeCentrixPhonebookList>>>", ret);
                    if ( ret.data.SVC_RT === '0000'){
                        centrixPhoneBookData = centrixPhoneBookData.concat(ret.data.DATAS);
                        if ( ret.data.DATAS === undefined || ret.data.DATAS === null || ret.data.DATAS.length === 0 ){
                            break;
                        }
                        _page++;
                    }else{
                        errorCnt++;

                    }
                } while ( errorCnt === 0);
                
                //console.log("start process=>", centrixPhoneBookData);
                if ( centrixPhoneBookData !== null && centrixPhoneBookData.length > 0){
                    afterData = this.procAfterPhonebook(centrixPhoneBookData);
                    //console.log("afterprocess=>", afterData);
                    userData.setCentrixPhoneBookList(afterData);
                }else{
                    userData.setCentrixPhoneBookList([]);
                    //console.log("afterprocess=>", 'nodata');
                }
            }

            userData.setAddressSyncFlag(_flag);
            
            return afterData;
            
        }
        /**
         * 데이터 원하는 형태로 가공처리 ( bookType, divid group 처리)
         */
        ,procAfterPhonebook(_targetlist) {

            const _phonebookListAfter = [];

            if( _targetlist !== null && _targetlist.length > 0 ){
                let phonebookType = ""; phonebookType = ( _targetlist[0].NO === undefined ) ? CONSTS.ADDRESS_TYPE_DEVICE : CONSTS.ADDRESS_TYPE_CENTRIX
        
                _targetlist.map((item, idx) => (
                    _phonebookListAfter.push(
                        { 
                            etc : (item.ETC === undefined || item.ETC === null || item.ETC === '' ) ? '' : item.ETC 
                            ,groupCode: item.GROUP_CODE
                            ,groupName: item.GROUP_NAME
                            ,name: item.NAME
                            ,no: item.NO
                            ,contactId :-1
                            ,phone : item.PHONE1
                            ,phone2 : item.PHONE2
                            ,drawgroup : getGroup(item.NAME)
                            ,bookType : phonebookType
                        }
                    )
                ));
            }
    
            return _phonebookListAfter;
        }
        ,async GetCentrixPhonebookListByGroup(_groupId = "", isConvert = true) {
            //console.log(">>>>>>>>>>call GetCentrixPhonebookListByGroup =>", _groupId);
            let centrixPhoneBookData = [];
            let errorCnt = 0;
            let _page = 1;

            do {
                let ret = await CentrexApi.getPhoneBookList({
                    page : _page + ""
                    ,num_per_page : 100
                });
                //console.log("GetCentrixPhonebookListByGroup>>>", ret);
                if ( ret.data.SVC_RT === '0000'){
                    centrixPhoneBookData = centrixPhoneBookData.concat(ret.data.DATAS);
                    if ( ret.data.DATAS === undefined || ret.data.DATAS === null || ret.data.DATAS.length === 0 || ret.data.DATAS.length < 100 ){
                        break;
                    }
                    _page++;
                }else{
                    errorCnt++;
                }
            } while ( errorCnt === 0);

            let filterData = [];
            if ( isConvert ) {
            
                centrixPhoneBookData.map( (_item, idx) => {
                    console.log(_item.GROUP_CODE + "_" + _groupId , (_item.GROUP_CODE === _groupId));
                    if ( _item.GROUP_CODE === _groupId) {
                        filterData.push(
                        {
                            etc : _item.ETC
                            ,groupCode: _item.GROUP_CODE
                            ,groupName: _item.GROUP_NAME
                            ,name: _item.NAME
                            ,no: _item.NO
                            ,contactId : -1
                            ,phone : _item.PHONE1
                            ,phone2 : _item.PHONE2
                            ,drawgroup : getGroup(_item.NAME)
                            ,bookType : ( _item.NO === undefined || _item.NO === null || _item.NO === '' ) ? CONSTS.ADDRESS_TYPE_DEVICE : CONSTS.ADDRESS_TYPE_CENTRIX
                        });
                    }
                });

                console.log('isConvert filter data =>', filterData);

            }else{
                filterData = filterData.filter( (_targetItem) => {
                    if ( _targetItem.GROUP_CODE === _groupId ){
                        return _targetItem;
                    }
                });

            }

            /*
            return filterData.sort( (_src, _dst) => {
                if ( _src.name > _dst.name ) return 1;
                else if ( _src.name < _dst.name ) return -1;
                else return 0;
            });
            */
           return sortHanWordsList(filterData, "name", "ASC");
            
        }
        ,async GetSavedCentrixPhonebookListByGroup(_groupId = "") {

            console.log(">>>>>>>>>>call GetSavedCentrixPhonebookListByGroup =>", _groupId);
            const addrlst = userData.dataset.centrixPhoneBookData;
            return addrlst.filter( (_targetItem) => {
                if ( _targetItem.GROUP_CODE === _groupId ){
                    return _targetItem;
                }
            });
        }
        ,async makeMyService(_storeId = "") {

            if ( _storeId === "" ){
                _storeId = LocalStore.getStoreId();        //현재 설정된 StoreId 
            }

            const _isTempUserYN = LocalStore.getIsTempUser() === null ? false : LocalStore.getIsTempUser();
            
            if ( !_isTempUserYN ) {
                const sohoMyService =  {
                    call : false,           //전화&메시지
                    cctv : false,           //지능형CCTV
                    promota : false,        //프로모타 ( 가입유무 체크 가능로직 없음 )
                    cashnote : false,       //캐시노트 ( 가입유무 없음 - 판단은 캐시노트에서 진행함. iframe으로 제공)
                    alba : false,           //알바천국 ( 가입유무 체크 가능로직 없음  )
                    callRing : false,       //통화연결음
                    callRingEnReg : false,  //통화연결음 음원 생성
                    callMsg : false,        //우리매장알림서비스
                    staffMng : false,       //근태서비스(매장관리)
                    cctvOpenUrl : false,    //위생인증서비스
                    cctvReq : false         //출동요청
                };
    
                const ret = await SohoApi.getMyServiceDetailList({
                    'ownerUserId': LocalStore.getOwnerId(), 
			        'storId': _storeId
                });

                if( ret.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS ) {
                    if ( ret.data !== null && ret.data.lpzList !== null && ret.data.lpzList.length > 0 ){

                        ret.data.lzpList.map( (targetItem, _index) => {
                            if ( (targetItem.svcCd === 'CCTV_SVC_1' || targetItem.svcCd === 'CCTV_SVC_2' || targetItem.svcCd === 'CCTV_SVC_3' || targetItem.svcCd === 'CCTV_SVC_4' || targetItem.svcCd === 'CCTV_SVC_5') && targetItem.useYn === 'Y' ){
                                console.log('cctv =>', targetItem);
                                sohoMyService.cctv = true;
                            }
                            
                            if ( (targetItem.svcCd === 'CCTV_SVC_1' || targetItem.svcCd === 'CCTV_SVC_4' || targetItem.svcCd === 'CCTV_ADD_SVC_3' ) && targetItem.useYn === 'Y' ) {
                                console.log('cctvReq =>', targetItem);
                                sohoMyService.cctvReq = true;
                            }
                            
                            if ( targetItem.svcCd === 'CCTV_ADD_SVC_3' && targetItem.useYn === 'Y' ) {
                                sohoMyService.staffMng = true;
                            }
                            
                            if ( targetItem.svcCd === 'CCTV_ADD_SVC_1' && targetItem.useYn === 'Y' ) {
                                sohoMyService.cctvOpenUrl = true;
                            }
                        });

                        ret.data.lpzList.map( (targetItem, _index) => {
                            if ( targetItem.svcCd === '070_SVC_1' && targetItem.useYn === 'Y' ){
                                sohoMyService.call = true;
                            }
                            
                            if ( targetItem.svcCd === '070_ADD_SVC_1' && targetItem.useYn === 'Y' ) {
                                sohoMyService.callRing = true;
                            }
                            
                            if ( targetItem.svcCd === '070_ADD_SVC_2' && targetItem.useYn === 'Y' ) {
                                sohoMyService.callRingEnReg = true;
                            }
                            
                            if ( targetItem.svcCd === '070_ADD_SVC_3' && targetItem.useYn === 'Y' ) {
                                sohoMyService.callMsg = true;
                            }
                        });
                        
                    }
                }

                console.log("Change MyService...", sohoMyService);

                userInfo.setMyService(sohoMyService);
            } else {
                const sohoMyService =  {
                    call : false,           //전화&메시지
                    cctv : false,           //지능형CCTV
                    promota : false,        //프로모타 ( 가입유무 체크 가능로직 없음 )
                    cashnote : false,       //캐시노트 ( 가입유무 없음 - 판단은 캐시노트에서 진행함. iframe으로 제공)
                    alba : false,           //알바천국 ( 가입유무 체크 가능로직 없음  )
                    callRing : false,       //통화연결음
                    callRingEnReg : false,  //통화연결음 음원 생성
                    callMsg : false,        //우리매장알림서비스
                    staffMng : false,       //근태서비스(매장관리)
                    cctvOpenUrl : false,    //위생인증서비스
                    cctvReq : false         //출동요청
                };

                //센트릭스 로그인 유무
                const centrixId = LocalStore.getStoreInetNo();
                if ( centrixId !== undefined && centrixId !== null && centrixId !== '' ) {
                    sohoMyService.call = true;


                        const retCentrixUserInfo = await CentrexApi.getUserInfo({
                            id: LocalStore.getStoreInetNo(),
                            pass: LocalStore.getTempInetPass()
                        }, false);

                    console.log(">>>>result:", retCentrixUserInfo);

                    if ( retCentrixUserInfo.data.SVC_RT === CONSTS.CENTRIX_SVC_RT_SUCCESS ){

                        const isCentrix = retCentrixUserInfo.data.NUMBER070;
                        if ( isCentrix !== undefined && isCentrix !== null && isCentrix !== '' ){
                            sohoMyService.call = true;
                        }
                        setCenterexUserIdPass(LocalStore.getStoreInetNo() , LocalStore.getTempInetPass());
                        
                    }
                    // else{
                    //     //재진입 시 auth에러일 경우 최초 한번만 띄움
                    //     if(userData.dataset.centrexTempAuthFlag === "0"){
                    //         layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "인터넷 전화 인증실패", [
                    //             function() {
                    //                 const retCentrixUserInfo2 = CentrexApi.getUserInfo({
                    //                     id: LocalStore.getStoreInetNo(),
                    //                     pass: LocalStore.getTempInetPass()
                    //                 });

                    //                 if(retCentrixUserInfo2.data.SVC_RT === CONSTS.CENTRIX_SVC_RT_SUCCESS){
                    //                     const isCentrix = retCentrixUserInfo.data.NUMBER070;
                    //                     if ( isCentrix !== undefined && isCentrix !== null && isCentrix !== '' ){
                    //                         sohoMyService.call = true;
                    //                     }
                    //                     setCenterexUserIdPass(LocalStore.getStoreInetNo() , LocalStore.getTempInetPass());
                    //                 }else{
                    //                     userData.setCentrexTempAuthFlag3("1");
                    //                 }
                    //                 userData.setCentrexTempAuthFlag("1")
                    //                 layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                                    
                    //             }
                    //         ]);    
                    //     }
                    // }
                    
                }

                //CCTV
                const cctvInfo = LocalStore.getCctvTempUserData();
                if ( cctvInfo !== null && cctvInfo !== '' ){
                    
                    //CCTV가입자
                    sohoMyService.cctv = true;

                    console.log("cctvInfo=>", cctvInfo);

                    if ( cctvInfo.dispatchService === 1 ) {
                        sohoMyService.cctvReq = true;
                    } 

                    if ( cctvInfo.iotType === 1 ) {
                        sohoMyService.staffMng = true;
                    } 

                    const camresRet = await CctvApi.getCameras();
                    if ( camresRet && camresRet.data.resCode === CONSTS.CCTV_RES_CODE_SUCCESS ) {
                        camresRet.cctvOpenUrl = ( camresRet.data.list !== null && camresRet.data.list.length > 0 ) ? 
                            camresRet.data.list.filter( (targetItem) => {
                                if ( targetItem.cctvOpenUrl !== undefined && targetItem.cctvOpenUrl !== null && targetItem.cctvOpenUrl !== '') return targetItem;
                            }).length > 0 
                        :
                            false;
                    }
                }

                console.log("Change MyService [ temp user ]...", sohoMyService);

                userInfo.setMyService(sohoMyService);

            }

        }
        /**
         * SOHO My Service  조회
         * - 화면갱신형으로 state 사용을 위할때는 userInfo.dataset을 사용해야 함.
         */
        ,async getMyService(_serviceCode) {

            const sohoMyService = userInfo.dataset.myservice;

            if ( sohoMyService === null || sohoMyService === undefined) {
                console.log("getMyService is null =>req : ", _serviceCode );
                return false;
            }

            if ( CONSTS.SOHO_SRVC_CALL === _serviceCode) {
                return sohoMyService.call;
            } else if ( CONSTS.SOHO_SRVC_CCTV === _serviceCode) {
                return sohoMyService.cctv;
            } else if ( CONSTS.SOHO_SRVC_PROMOTA === _serviceCode) {
                return sohoMyService.promota;
            } else if ( CONSTS.SOHO_SRVC_CASHNOTE === _serviceCode) {
                return sohoMyService.cashnote;
            } else if ( CONSTS.SOHO_SRVC_ALBA === _serviceCode) {
                return sohoMyService.alba;
            } else if ( CONSTS.SOHO_SRVC_CALLRING === _serviceCode) {
                return sohoMyService.callRing;
            } else if ( CONSTS.SOHO_SRVC_CALLMSG === _serviceCode) {
                return sohoMyService.callMsg;
            } else if ( CONSTS.SOHO_SRVC_STAFFMNG === _serviceCode) {
                return sohoMyService.staffMng;
            } else if ( CONSTS.SOHO_SRVC_CCTV_OPENURL === _serviceCode) {
                return sohoMyService.cctvOpenUrl;
            } else if ( CONSTS.SOHO_SRVC_CCTV_REQUEST === _serviceCode) {
                return sohoMyService.cctvReq;
            } else {
                return false;
            }

        },
        /**
         * 간편로그인처리
         * 01. 로그인 토큰 발급요청
         *      01.01.예외처리) 로그인 중 삭제시/패밀리계정 해제시 홈화면으로 이동
         * 02. 로그인 성공 후 로컬Store 설정
         * 03. 매장정보 조회
         * 04. 서비스 로그인 ( 센트릭스 or CCTV )
         * 05. 매장부가정보 설정 ( 카메라정보 / 기타 )
         */
        async simpleAuthentification(ownerId, familyYn = 'N'){

            const deviceInfo = LocalStore.getDeviceInfo();
			const sohoAuthInfo = await SohoApi.getAuthToken({
				//"userId" : ownerId 	
				"clientIp" : deviceInfo.ip_addr ? deviceInfo.ip_addr : "0.0.0.0"
				,"devInfo" : "PHONE"				
				,"osInfo" : deviceInfo.os_type ? deviceInfo.os_type : "unknown_os"
				,"nwInfo" : deviceInfo.network ? deviceInfo.network : "4G"			
				,"devModel" : deviceInfo.dev_model ? deviceInfo.dev_model : "unknown_dev"
				,"carrierType": deviceInfo.carrier_type ? deviceInfo.carrier_type : "unknown_ct"
			});
			if(sohoAuthInfo.retCode !== '0000') {
                const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===sohoAuthInfo.retCode);
                if(typeof(errCodeData) === "undefined") {
                    layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요", [
                        function() {
                            layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                        }
                    ]);
                }
				return;
			}
			userInfo.setFamilyYn(familyYn);
            userInfo.setIsTempUser(false);
			userInfo.setOwnerId(ownerId);
			//userInfo.setOwnerNm(ownerNm);

			// 다음단계(마이 -> 설정)로 이동
			//toast('계정 변경이 완료 되었습니다.')
			LocalStore.deleteHomecard();
			// setUserDataStoreListByOwnerId(ownerId)
            console.log('storeId', userData.dataset.storeId);
            await setUserDataStoreListByOwnerId(ownerId);
			// BizApi.procChgStore(LocalStore.getStoreId());

            // 로그인 세션을 제거한다.
			SessionStore.clear();

        },
        async InitSvcCard(toStoreId='') {
            console.log("InitSvcCard Call");

            if(toStoreId === '') {
                toStoreId = LocalStore.getStoreId();
            }
            
            var ServerHomeCardList = [];
            
            ServerHomeCardList = await SohoApi.setHomeCardInfo({ 
                'ownerUserId': LocalStore.getOwnerId(),
                'storeId': toStoreId
            });
            if( ServerHomeCardList.retCode !== CONSTS.SOHOAPI_RETCODE_SUCCESS ) return;
            // console.log('서버 홈카드',ServerHomeCardList)
            const serverhomecard = ServerHomeCardList.data.list;
    
    
            if(LocalStore.getHomeCard(toStoreId) === null/* || LocalStore.getIsTempUser() === true*/) {
                //LocalStore.setHomeCard(LocalStore.getStoreId(),serverhomecard)
                userData.setHomeList(serverhomecard, false);
            } else {
    
                const LocalHomeCardList = LocalStore.getHomeCard(toStoreId);
    
                let matchCardList = [];
                let newCardList = [];
                serverhomecard.map(item => {
                    const matchItem = LocalHomeCardList.find((element) => element.svcCd === item.svcCd);
                    if(typeof(matchItem)==="undefined") {
                        newCardList.push(item);
                    } else {
                        matchCardList.push(item);
                    }
                });
    
                let matchCardListOrdered = [];
                LocalHomeCardList.map(item=>{
                    const matchItem = matchCardList.find((element) => element.svcCd === item.svcCd);
                    if(typeof(matchItem)!=="undefined") {
                        matchCardListOrdered.push(matchItem);
                    } 
                })
                const sumCardList = matchCardListOrdered.concat(newCardList);
                userData.setHomeList(sumCardList, true);
            }
    
        },
        // async TempCamerasError() {
        //     const cctvInfo = LocalStore.getCctvTempUserData();
        //     const camresRet = await CctvApi.getCameras();

        //     if(cctvInfo == undefined) {
        //         layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요.(CCTV)", [
        //             function() {
        //                 layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=> {
        //                     if(userData.dataset.tempcctvauthFlag === false) {
        //                         userData.setTempCctvAuthFlag(true)
        //                         await CctvApi.getAuth({
        //                             userId : LocalStore.getCctvTempUserId(),
        //                             userPwd : LocalStore.getCctvTempUserPwd()
        //                         });
        //                         // 매핑
        //                         if(camresRet.data.resCode === 0) {
        //                             let cameras = {};
        //                             try {
        //                                 cameras = await CctvApi.getCameras();

        //                             } catch(e) {
        //                                 cameras = {
        //                                     data: {
        //                                         list : []
        //                                     }
        //                                 };
        //                             }        
                            
        //                         console.log("cameras@@@@@@@", cameras.data.list);
                            
        //                         let cammerasData = cameras.data.list
                            
        //                         let storeMappingList = []
                            
        //                         let camIds = [];
        //                         let cams = [];
        //                         let cam_device_sn_id = [];
        //                         let addLzpList = [];
                                
        //                         cammerasData?.map((camera) => {
                                    
        //                             const cam = {};
        //                                 camIds.push(camera.camId);
        //                                 cam.camId = camera.camId;
        //                                 cam.camName = camera.camName;
        //                                 cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
        //                                 cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
        //                                                 if (camera.device_sn_id.length > 0) {
        //                                                     cam_device_sn_id.push(camera.device_sn_id)
        //                                                 }
        //                                                 cams.push(cam);
        //                             })
        //                         //userData.setStoreList([0], cam);
                            
        //                         storeMappingList.push({
        //                             storeId: LocalStore.getStoreList()[0].storeId ,
        //                             storeNm: LocalStore.getStoreList()[0].storeNm ,
        //                             lpzEntrNo: LocalStore.getStoreList()[0].lpzEntrNo,
        //                             camId: camIds.join(","), 
        //                             cams: cams,
        //                             cam_device_sn_id : cam_device_sn_id,
        //                             lpzNo: LocalStore.getStoreList()[0].lpzNo,
        //                             lzpEntrNo: addLzpList.join(",")
        //                         });
                            
        //                         console.log("0454", storeMappingList[0]);
                            
                                
        //                         userData.setStoreList(storeMappingList);
        //                         userData.setCurrStoreInfo(storeMappingList[0]);
        //                         } else {
        //                             return
        //                         }
        //                     } else {
        //                         return;
        //                     }
        //                 });
        //             }
        //         ]);
        //     }
            
        //     if(cctvInfo !== undefined && camresRet.data.resCode === 401) {
        //         layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요.(CCTV)", [
        //             function() {
        //                 layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=> {
        //                     if(userData.dataset.tempcctvauthFlag === false) {
        //                         userData.setTempCctvAuthFlag(true)
        //                         await CctvApi.getAuth({
        //                             userId : LocalStore.getCctvTempUserId(),
        //                             userPwd : LocalStore.getCctvTempUserPwd()
        //                         });
        //                     } else {
        //                         return;
        //                     }
        //                 });
        //             }
        //         ]);
        //     } else {
        //         return
        //     }
        // }   
}