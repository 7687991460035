import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import * as LocalStore from '../../../../../store/LocalStore';
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import BizApi from "../../../../../api/BizApi";
import {getGroup, transformAddressDrawData} from "../../../../../common/common";
import { tabUi } from '../../../../../libs/tab'
import sampleData from "../test.json";		//테스트데이터
import * as CONSTS from '../../../../../const';
import PhoneBookNone from '../phonebook/PhoneBookNone';

//우리가게 > 전화 페이지
const PhoneBook = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const [phonebooklist, setPhoneBookList] = useState([]);
	const [searchResultData, setSearchResultData] = useState([]); 
	const [phonebookGrouplist, setPhoneBookDrawGroupList] = useState([]); 
	const [searchWords, setSrchWords] = useState("");
	const [addressType, setAddressType] = useState("");

	const [phonebookType, setPhonebookType] = useState("centrix"); 

	useEffect(() => {
		tabUi.init3DepthTab(document.getElementById('tab_style4'));
		tabUi.init3DepthTab(document.getElementById('floating_tab2'));
    	tabUi.init3DepthTab(document.getElementById('memo_cate')); // 0617 수정사항 반영

		//userData.setPhoneMainTab('centrix');

		//bridge 리턴
		const subscription = NativeBridge.eventObservable.subscribe(event => {
            
			console.log("----------------- from Native Event : PhoneBook.js -----------------");
			console.log(event);
			switch (event.type) {                    
	
				case 'GET_ADDRESS_LIST':
					const result = event.param;
					console.log(" result: " , result);
					const _phonebookList = result.address;

					const _phonebookListAfter = [];
					if ( _phonebookList !== null && _phonebookList.length > 0 ){
						_phonebookList.map((item, idx) => (
							_phonebookListAfter.push(
								{ 
									etc : (item.etc === undefined || item.etc === null || item.etc === '' ) ? '' : item.etc 
									,groupCode: item.groupCode
									,groupName: item.groupName
									,name: item.name
									,no: item.no
									,contactId : item.contactId
									,phone : (item.phone.replace(/-/gi, "").indexOf(',') > 0) ? item.phone.replace(/-/gi, "").split(',')[0] : item.phone.replace(/-/gi, "")
									,phone2 : (item.phone.replace(/-/gi, "").indexOf(',') > 0) ? item.phone.replace(/-/gi, "").split(',')[1] : ''
									,drawgroup : getGroup(item.name)
									,bookType : CONSTS.ADDRESS_TYPE_DEVICE
								}
							)
						));
					}

					//userData에 저장 ( 이후 사용을 위해 )
					/*
					if ( phonebookType === CONSTS.ADDRESS_TYPE_CENTRIX ){
						userData.setCentrixPhoneBookList(_phonebookListAfter);
					}else{
						userData.setDevicePhoneBookList(_phonebookListAfter);
					}
					*/
					//console.log("zz",_phonebookListAfter)
					userData.setDevicePhoneBookList(_phonebookListAfter);

					console.log(">>>From NativeBridge=>", addressType);

					if ( userData.dataset.flagGetAddress === '__PHONEBOOK__'){
						setPhoneBookList(_phonebookListAfter);
						userData.setFlagGetAddress('');
					}
				break;
	
			default:
				
				break;
			}
	
		});


		return () => {
            subscription.unsubscribe();
		};


	}, []);

	useEffect(() => {
		//Initialize
		// getAddressList(CONSTS.ADDRESS_TYPE_CENTRIX);
	}, [userData.dataset.currstoreInfo]);


	useEffect(()=>{
		if(userData.dataset.phoneMainTab !== CONSTS.ADDRESS_TYPE_CENTRIX && userData.dataset.phoneMainTab !== CONSTS.ADDRESS_TYPE_DEVICE) {
			userData.setPhoneMainTab(CONSTS.ADDRESS_TYPE_CENTRIX);
		} else {
			getAddressList(userData.dataset.phoneMainTab);	
		}
	}, [userData.dataset.phoneMainTab]);

	useEffect(() => {

		//console.log("change addressSyncFlag=>", userData.dataset.pblistForCentrix);
		//Initialize
		if ( userData.dataset.addressSyncFlag === '__PHONEBOOK__' ){
			setPhoneBookList(userData.dataset.pblistForCentrix);
			userData.setAddressSyncFlag('');
		}
		
	}, [userData.dataset.addressSyncFlag]);

	//데이터 가공처리
	useEffect(() => {
		//console.log("=======convert drawData=======", phonebooklist);
		setSearchResultData(phonebooklist);		//pass
	}, [phonebooklist]);

	useEffect(() => {
		//console.log("=======convert searchResultData=======", searchResultData);
		setPhoneBookDrawGroupList(transformAddressDrawData(searchResultData));
	}, [searchResultData]);


	useEffect(() => {
		//검색어 입력시
		const deviceInfo = LocalStore.getDeviceInfo();
		//검색어 입력시 연락처//그룹// 통화메모 숨김
		$('.content.store .store_call .call_srh_box input').on("focusin", function(){
			//if ( deviceInfo.os_type === 'ios'){
				$('.floating_tab_wrap').hide();
			//}
		});
		$('.content.store .store_call .call_srh_box input').on("focusout", function(){
			//if ( deviceInfo.os_type === 'ios'){
				setTimeout(() => {
					$('.floating_tab_wrap').show();
					$('.floating_tab_wrap').css('bottom', '8.6rem');
				}, 500);
			//}
			
		});
		$('.content.store .store_call .call_srh_box input').on("blur", function(){
			//if ( deviceInfo.os_type === 'ios'){
				setTimeout(() => {
					$('.floating_tab_wrap').show();
					$('.floating_tab_wrap').css('bottom', '8.6rem');
				}, 500);
			//}
		});
		
		console.log("change srchWord:" + searchWords);

		setSearchResultData(phonebooklist?phonebooklist.filter((targetItem) => {
			if ( searchWords === '' || (targetItem.name)?.includes(searchWords) || (targetItem.phone)?.includes(searchWords)){
				return targetItem;
			}	
		}):[]);

	}, [searchWords]);

    const getAddressList = async (type = CONSTS.ADDRESS_TYPE_CENTRIX ) => {
		console.log("getAddressList=>", type);
		setAddressType(type);
        //get address
		setPhoneBookList([]);
		if ( type === CONSTS.ADDRESS_TYPE_CENTRIX ){
			BizApi.makeCentrixPhonebookList("__PHONEBOOK__");
			//setPhoneBookList(userData.dataset.pblistForCentrix);
		}else{
			userData.setFlagGetAddress('__PHONEBOOK__');
			NativeBridge.getAddressList(CONSTS.ADDRESS_TYPE_DEVICE, "");	//그룹별 조회 없음
		}
        
	}

  	const CallLogDetailClick = (e, _item) => {
		console.log("CallLogDetailClick:", _item);
		history.push(
            {
                pathname : '/shop/call/phonebook/detail'
                ,state : {
                    destnumber : _item.phone
					,no : _item.no
					,contactId : _item.contactId
                }
            }
        );
	}

	const ChangeTab = (e,  type=CONSTS.ADDRESS_TYPE_CENTRIX) => {
		
		$('input[type=text]').val("");
		setSrchWords("");
		
		$('div.btn_cate_wrap2 > button').removeClass('active');
		$(e.target).addClass('active');
		
		console.log("ChangeTab=>", type);
		getAddressList(type);

		userData.setPhoneMainTab(type);
	}

	const ClickRefresh = (e) => {
		console.log("새로고침 호출 =>", addressType);
		getAddressList(addressType);
	}

	const KeyPressSearch = (e) => {
		let keyworkds = e.target.value;
		console.log('KeyPressSearch =>' + keyworkds);
		setSrchWords(keyworkds);
	}

	const GoPhoneRegi = async (e) => {
		NativeBridge.sendUserActionLog('detail', 'D023');
		history.push(
			{
				pathname : '/shop/call/phonebook/reg'
				,state : {
					destnumber : ''
					,item : null 
				}
			}
		);
	}

	const ClickReset = (e) => {
		$('input[type=text]').val("");
		setSrchWords("");
	}

  	return (
    <>
{/* <div className="fix_container contact_scroll_area"> */}
		<div className="call_srh_box">
			<div className="input_box style1">
				<input type="text" placeholder="이름, 전화번호를 검색해보세요." maxLength="15" onKeyUp={(e) => KeyPressSearch(e)}/>
				<span className="ip_clear hidden usetap" onClick={(e)=>ClickReset(e)}>초기화</span>
				<span className="ico_srh"></span>
			</div>
			{/* <button id="btn_edit2" className="btn_edit2 usetap" onClick={()=>EditClick()}>편집</button> */}
		</div>
			{/* <!-- 210616 추가 --> */}
		<div className="contact_cate">
			<div className="btn_cate_wrap2" id="memo_cate">
				<button type="button" className={userData.dataset.phoneMainTab === CONSTS.ADDRESS_TYPE_CENTRIX ? "tabItem active":"tabItem"} onClick={(e)=>ChangeTab(e, CONSTS.ADDRESS_TYPE_CENTRIX)}>인터넷 전화 주소록</button>
				<button type="button" className={userData.dataset.phoneMainTab === CONSTS.ADDRESS_TYPE_DEVICE ? "tabItem active":"tabItem"} onClick={(e)=>ChangeTab(e, CONSTS.ADDRESS_TYPE_DEVICE)}>휴대폰 주소록</button>
			</div>
			{/*
			<button type="button" className="btn_refresh_txt usetap" onClick={(e)=>ClickRefresh(e)}><i className="ico_refresh" ></i>새로고침</button>
			*/}
			<div className="btn_wrap">
				<button type="button" className="btn_refresh_round blind usetap" onClick={(e)=>ClickRefresh(e)}>새로고침</button>
				<button type="button" className="btn_add blind usetap" onClick={(e)=>GoPhoneRegi(e)}>연락처 추가</button>
			</div>
		</div>
		{phonebookGrouplist.length < 1 
		? 
		<>
			<div className="data_none">
				<div className="none_wrap">
					<span className="none_img"></span>
					{
						searchWords !== null && searchWords.length > 0 
						?
						<p>검색결과가 없습니다.</p>
						:
						<p>등록된 연락처가 없습니다.</p>
					}
				</div>
			</div>
		</>
		: 
		<div className="contact_list_wrap floating_bottom">
		{ 
			phonebookGrouplist.map((phonebookGroupItem, gidx) => (
				<>
					<span className="devide_bar">{phonebookGroupItem.title}</span>
					{
						(phonebookGroupItem.items.length !== null || phonebookGroupItem.items !== undefined ) ?
						phonebookGroupItem.items.map((addressItem, index) => (
							<>
								<button type="button" className="contact_list" onClick={(e)=> CallLogDetailClick(e, addressItem)}>
									<span className="i_name">{addressItem.name}</span>
								</button>
							</>
						)) : 
						<></>
					}
				</>
			)) 
		}
		</div>
	}
		{ /*
		<ol className="contact_short">
			<li>ㄱ</li>
			<li>ㄴ</li>
			<li>ㄷ</li>
			<li>ㄹ</li>
			<li>ㅁ</li>
			<li>ㅂ</li>
			<li>ㅅ</li>
			<li>ㅇ</li>
			<li>ㅈ</li>
			<li>ㅊ</li>
			<li>ㅋ</li>
			<li>ㅌ</li>
			<li>ㅍ</li>
			<li>ㅎ</li>
			<li>#</li>
		</ol>
		} */}
		{/*
		<span className="cate_short">ㄱ</span>
		*/}
    </>
	
  );
});
export default PhoneBook;