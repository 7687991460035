import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { floatUI } from '../../../../libs/floating'
import { tabUi } from '../../../../libs/tab'
import { textChgEdit, layerPopup, toast } from "../../../../libs/common";
import { getAddressInfo } from "../../../../common/common";

//SAMPLE DN LIST
import sampleData from "./recordDnList.json";		//테스트데이터

import 'moment/locale/ko';
import moment from "moment";

import ContactDataNone2 from "../ContactDataNone2";
import deviceInfo from "../../../../store/deviceInfo";

//전화 설정 페이지
const CallLogEdit = observer(({ history }) => {

	const { userInfo, userData } = store;
	const userList = userInfo.getList();

	const [currentPage, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
	const [orderByType, setOrderByType] = useState(1);  //1:최신순 , 2:오래된 순

	const [recordList, setRecordList] = useState([]);
	const [downloadedFiles, setDownloadedFiles] = useState([]);

	const [searchWords, setSearchWords] = useState("");

	useEffect(() => {
		console.log("ShopMapping default useEffect..");
		//userData.setRecordFileCnt($('.recordfile_list').length);
		tabUi.init3DepthTab(document.getElementById('memo_cate'));

		getRecordList();

		//bridge 리턴
		const subscription = NativeBridge.eventObservable.subscribe(event => {
            
			console.log("----------------- from Native Event : CallLogEdit.js ------------------");
			console.log(event);
			switch (event.type) {                    
	
				case 'GET_RECODE_DN_LIST':		//GET_RECODE_DN_LIST
					var result = event.param;
					console.log("GET_RECODE_DN_LIST result: " , result);
					const _downloadedFiles = result.files;

					if ( _downloadedFiles !== null && _downloadedFiles.length > 0 ){
						setDownloadedFiles(_downloadedFiles);
					}

					break;

				case 'CALL_RECODE_DN':		//CALL_RECODE_DN
					var result = event.param;
					console.log("CALL_RECODE_DN result: " , result);

					//TODO : 다운로드 요청 한번에 여러개 받는경우에 대해서 고려하지 않음
					if ( result.files !== null && result.files.length > 0 ){
						let _status = result.files[0].status;
						var _message = "";
						//var _title = "저장실패[" + _status + "]";
						var _title = "저장실패";
						if ( _status === '1') {
							_message = '파일다운로드 저장 실패';
							//_message = '휴대폰에 저장공간이 부족합니다.\n다른 파일을 삭제해 저장공간을 확보한 후 다시 시도해주세요.'
						}else if ( _status === '2') {
							_message = '휴대폰에 저장공간이 부족합니다.\n다른 파일을 삭제해 저장공간을 확보한 후 다시 시도해주세요.'
						}else if ( _status === '3') {
							_title = '알림';
							_message = '재생 가능한 플레이어가 없습니다.'
						}else{
							_title = '알림';
							_message = '휴대폰에 저장했습니다.';
							/*
							if ( deviceInfo.dataset.os_type === 'ios') {
								_message = '휴대폰에 저장했습니다.';
							} else {
								_message = '디렉토리가 Android 11이상에서는 보이지 않아, 별도의 브라우저 혹은 앱 설치가 필요합니다.';
							}
							*/
						}

						if ( _status === '0'){
							toast(_message);
						}else{
							//그룹명을 입력하세요.
							layerPopup.showCommon("#popup_onetitlemsgbutton", null, _title, _message, [
								function() {
									layerPopup.hide("#popup_onetitlemsgbutton");
								}
							]);
						}

						getRecordList();
						
					}

					break;
					
				case 'CALL_PLAY_RECODE_FILE':	//CALL_PLAY_RECODE_FILE
					var result = event.param;
					if ( result.status === "9"){
						//그룹명을 입력하세요.
						layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "재생가능한 플레이어가 없습니다.", [
							function() {
								layerPopup.hide("#popup_onetitlemsgbutton");
							}
						]);
					}

					break;

				default:
					break;
			}
	
		});

		return () => {
            subscription.unsubscribe();
		};

  	}, []);

	useEffect(() => {

		console.log("CallLogEdit change searchWords useEffect..");
		
  	}, [searchWords]);

	  /*
	useEffect(() => {

		console.log("get RecordList useEffect..");
		

  	}, [recordList]);
	  */


	useEffect(() => {

		console.log("CallLogEdit change downloadedFiles useEffect..", downloadedFiles);
		let tmpArray = [];
		if ( downloadedFiles !== null && downloadedFiles.length > 0) {
			recordList.map((item, idx) => {
				item.ADR_NAME = getAddressInfo(item.DST)?.NAME;
				item.ISDN = ( downloadedFiles?.filter( (_targetFileNm)=>{
					console.log(">>>>" + _targetFileNm);
					if ( _targetFileNm.filename === item.FILE_NAME && _targetFileNm.status === "0"){
						return _targetFileNm
					}
				})).length > 0 ? true : false;

				tmpArray.push(item)
			});

			if ( orderByType === "2"){
				tmpArray.sort((a, b) => (
					a.CALLDATE > b.CALLDATE
				) ? 1 : -1);
			}else{
				tmpArray.sort((a, b) => (
					a.CALLDATE < b.CALLDATE
					) ? 1 : -1);
			}

			setRecordList(tmpArray);
		}
		
  	}, [downloadedFiles]);


	useEffect(() => {
		getRecordList();
  	}, [userData.dataset.currstoreInfo]);

  	const getRecordList = async (_page=1) => {

		setPage(_page);
		const result = await CentrexApi.getRecordList({
			page: _page
			,num_per_page : 100
		}); 

		console.log("녹취목록조회 ===>", result);

		if(result.data.SVC_RT === "0000"){
			console.log("녹취목록조회");
			const _recordListByPage = result.data.DATAS;
			console.log(_recordListByPage);

			//let tmpData = [];
			if ( _recordListByPage !== null && _recordListByPage.length > 0 ){
				//tmpData = _recordListByPage;
				/*
				if ( recordList !== null && recordList.length > 0 ){
					tmpData = Array.from(recordList).concat(_recordListByPage);
					//setRecordList(recordList => Array.from(recordList).concat(_recordListByPage));
					//setTotalCount(totalCnt => totalCnt + _recordListByPage.length);
				}else{
					//setRecordList(_recordListByPage);
					//setTotalCount(_recordListByPage.length);
					tmpData = _recordListByPage;
				}
				*/
				
				if ( orderByType === "2"){
					_recordListByPage.sort((a, b) => (
						a.CALLDATE > b.CALLDATE
					) ? 1 : -1);
				}else{
					_recordListByPage.sort((a, b) => (
						a.CALLDATE < b.CALLDATE
						) ? 1 : -1);
				}

				//다운로드 파일목록요청(Native)
				//const _files = _recordListByPage.map(e => e.FILE_NAME).join(",");
				getDownloadedFiles( _recordListByPage.map(e => e.FILE_NAME).join(","));
				
				setRecordList(_recordListByPage);

			}
		}
  	}

	const getRecordListNext = () => {
		setPage(page => page++);
		getRecordList(currentPage)
	}
  
  	const clickOrderBy = (e, _orderByType) => {

		console.log("clickOrderBy:" + _orderByType);
		if ( _orderByType === "2"){
			recordList.sort((a, b) => (
				a.CALLDATE > b.CALLDATE
			) ? 1 : -1);
		}else{
			recordList.sort((a, b) => (
				a.CALLDATE < b.CALLDATE
				) ? 1 : -1);
		}

		setRecordList(recordList);
		setOrderByType(_orderByType);

		$('#memo_cate > button.tab_item').removeClass('active');
		var thisObj = e.target;
		$(thisObj).addClass('active');
	
  	}

	const removeRecordFile = async (e, recordFileItem) => {

		layerPopup.showCommon(
            '#image_remove_popup', 
            null, 
            '녹음 파일 삭제', 
            '통화 파일을 삭제하시겠어요?<br/>휴대폰에 저장된 파일은 함께 삭제되지 않습니다.', 
            [
                function() {
                    layerPopup.hide('#image_remove_popup');
                },
                function() {

                    console.log('selected Item removeRecordFile =>' + recordFileItem);
					processRemoveRecodeFile(recordFileItem);
					layerPopup.hide('#image_remove_popup');
					
                }
            ]
        );
		
	}

	const processRemoveRecodeFile = async (recordFileItem) =>{
		const result = await CentrexApi.removeRecordFile({
			filename : recordFileItem.FILE_NAME
		});

		if ( result.data.SVC_MSG === 'OK'){
			toast('녹취 파일을 삭제했습니다.');
		}else{
			toast('녹취 파일 삭제시 오류가 발생하였습니다.');
		}

		getRecordList();

		layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
	}

	const downloadRecordFile = (e, recordFileItem) => {
		NativeBridge.sendUserActionLog('detail', 'D033');
		console.log('selected Item downloadRecordFile =>' + recordFileItem);
		NativeBridge.requestDownloadRecord(recordFileItem.FILE_NAME, "");
	}

	const ClickPlayRecordFile = async (e, playFile) => {
		console.log('selected Item downloadRecordFile =>' + playFile.FILE_NAME);
		NativeBridge.playRecordFile(playFile.FILE_NAME);
	}

	const getDownloadedFiles = async (param) => {
		NativeBridge.getDownloadedFileForRecord(param);
	}

	const KeyPressSearch = (e) => {
		NativeBridge.sendUserActionLog('detail', 'D032');
		let keywords = e.target.value;
		console.log('KeyPressSearch =>' + keywords);
		if ( keywords !== null && keywords !== ''){
			$('span.ip_clear').removeClass('hidden');
		}
		setSearchWords(keywords);
	}

	const click_sample = (e) => {

		var result = sampleData.param;
		console.log("GET_RECODE_DN_LIST result: " , result);
		const _downloadedFiles = result.files;

		if ( _downloadedFiles !== null && _downloadedFiles.length > 0 ){
			setDownloadedFiles(_downloadedFiles);
		}
	}

	const ClickInitBtn = (e) => {
		//$(e.currentTarget).parent('input[type=text]').val('');

		$('input[type=text][id=searchWords]').val('');
		setSearchWords('');
	}
console.log("ㅁㄴㅇㄹㄴㅇㄹ", recordList.filter((targetItem) => {
	if ( searchWords === '' || (targetItem.DST)?.includes(searchWords) ){
		return targetItem;
	}	
}).length)
  return (
    <>

	{recordList.length < 1 
	?
	<ContactDataNone2 />
	:
		<div className="call_content">
		{/* <!-- 210712 검색박스 추가 --> */}
				<div className="call_srh_box">
					<div className="input_box style1">
						<input type="text" id="searchWords" placeholder="파일명을 검색해보세요." maxlnegth="15" onKeyUp={(e) => KeyPressSearch(e)} />
						<span className="ip_clear hidden usetap" onClick={(e) => ClickInitBtn(e)}>초기화</span>
						<span className="ico_srh"></span>
					</div>
				</div>
			<div className="call_memo_wrap floating_bottom">
				<span className="total_num" >총 {recordList.filter((targetItem) => {
							/*
							if ( searchWords === '' || (targetItem.DST)?.includes(searchWords) ){
								return targetItem;
							}
							*/
							if ( searchWords === '' || (targetItem.FILE_NAME)?.includes(searchWords) ) {
								return targetItem;
							}	
							}).length}개</span> {/* 사용유무 확인 필요 : userData.dataset.recordFileCnt */}
							{console.log("zxczxc..", orderByType)}
				<div className="btn_cate_wrap" id="memo_cate">
					<button type="button" className="tab_item active" onClick={(e)=> clickOrderBy(e, "1")}>최신순</button>
					{/* <button type="button" className={"tab_item " + orderByType==="2" && "active"} onClick={(e)=> clickOrderBy(e, "2")}>오래된순</button> */}
					<button type="button" className={orderByType==="2" ? "tab_item active" : "tab_item" } onClick={(e)=> clickOrderBy(e, "2")}>오래된순</button>
				</div>
				<ul className="record_list">
				{
					recordList.filter((targetItem) => {
						/*
						if ( searchWords === '' || (targetItem.DST)?.includes(searchWords) ){
							return targetItem;
						}
						*/
						if ( searchWords === '' || (targetItem.FILE_NAME)?.includes(searchWords) ) {
							return targetItem;
						}	
					}).length  === 0 && <div className="data_none">
					<div className="none_wrap">
						<span className="none_img"></span>
						<p>검색 결과가 없습니다.</p>
					</div>
				</div> 
				}
					{ (recordList !== null && recordList.length > 0  ) ?
                        recordList.filter((targetItem) => {
							/*
							if ( searchWords === '' || (targetItem.DST)?.includes(searchWords) ){
								return targetItem;
							}	
							*/
							if ( searchWords === '' || (targetItem.FILE_NAME)?.includes(searchWords) ) {
								return targetItem;
							}
						}).map((recordItem, index) => (	
						<>
						<li key={"recordfile_list" + index} className="recordfile_list">
							<span className="record_name">{recordItem.FILE_NAME}</span>
							<span className="record_date">{moment(recordItem.CALLDATE, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</span>
							<span className="btn_wrap">
								{
									( recordItem.ISDN === undefined ||  recordItem.ISDN === null || !recordItem.ISDN ) ?
									<>
										<button type="button" className="btn_dark_download usetap blind" onClick={(e)=> downloadRecordFile(e, recordItem)}>다운로드</button>
									</>
									:
									<>
										<button type="button" className="btn_dark_play usetap blind" onClick={(e)=> ClickPlayRecordFile(e, recordItem)}>재생</button>
									</>
								}
								<button type="button" className="btn_dark_del usetap blind" onClick={(e)=> removeRecordFile(e, recordItem)}>휴지통</button>
							</span>
						</li>
						</>
						))
						:
						<>
								 <div className="data_none">
									<div className="none_wrap">
										<span className="none_img"></span>
										<p>'메뉴명' 이/가 없습니다.</p>
									</div>
								</div> 
						</>
						
					}
					
				</ul>
			</div>
		</div>
		}
		
	
    </>
  );
});
export default CallLogEdit;