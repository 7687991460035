import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import CctvApi from '../../api/CctvApi';
import SohoApi from '../../api/SohoApi';
import store from '../../store';
import * as CONSTS from '../../const';
import { setCenterexUserIdPass, setCentrexSsoKey, initCentrexAuthRetryCntEnv } from '../../api/CentrexApi';
import { setCctvToken, setTempCctvToken, initCctvAuthRetryEnv } from '../../api/CctvApi';
import { initSohoCentrexAuthRetryEnv, initSohoCctvAuthRetryEnv } from '../../api/SohoApi';
import * as NativeBridge from '../../utils/NativeBridge';

const { userData, userInfo } = store;
export const setUserDataStoreListByOwnerId = async (ownerId) => {
    const storeDetailList = await SohoApi.storeDetailList({ownerUserId : ownerId});
    const storeMappingList = await setUserData(storeDetailList);

    let initStoreId = '';
    let initStoreInetNo = '';
    if(storeMappingList.length) {
        initStoreId = storeMappingList[0].storeId ? storeMappingList[0].storeId  : '';
        initStoreInetNo = storeMappingList[0].lpzNo ? storeMappingList[0].lpzNo : '';
    }

    userData.setStoreList(storeMappingList);
    userData.setStoreId(initStoreId);
    userData.setStoreInetNo(initStoreInetNo);
}

export const setUserDataStoreList = async (storeDetailList) => {
    const storeMappingList = await setUserData(storeDetailList);
    console.log("setUserDataStoreList", storeMappingList);
    setInitData(storeMappingList);
}

const setInitData = async(storeMappingList) => {
    let initStoreId = '';
    let initStoreInetNo = '';
    

    if(storeMappingList.length) {
        initStoreId = LocalStore.getStoreId();

        if(!initStoreId) {
            initStoreId = storeMappingList[0].storeId;
        } else {
            const storeIdDelYN = storeMappingList.filter((el) => {
                if ( el.storeId === initStoreId ){
                    return el;
                }
            }).length > 0 ? true : false ;

            if ( !storeIdDelYN ) {
                initStoreId = storeMappingList[0].storeId;
            }
        }
        initStoreInetNo = LocalStore.getStoreInetNo();

        if(!initStoreInetNo) {
            initStoreInetNo = storeMappingList[0].lpzNo;
        }
        userData.setStoreList(storeMappingList);
        userData.setStoreId(initStoreId);
        userData.setStoreInetNo(initStoreInetNo);
        let selectedStoreInfo = storeMappingList.find((item) => {
            return item.storeId === LocalStore.getStoreId();
        });
        console.log('매핑 목록', selectedStoreInfo)
        userData.setCurrStoreInfo(selectedStoreInfo);
    }

    try {
        if(initStoreInetNo) {
            await SohoApi.getCentrexAuth({
                storeInetNo: initStoreInetNo
            });
        }
    } catch(e) {
        console.log(e);
    }
}

const setUserData = async (storeDetailList) => {
    if (storeDetailList === undefined) {
        storeDetailList = await SohoApi.storeDetailList({ownerUserId : LocalStore.getOwnerId()});
    }

    if(!Array.isArray(storeDetailList.data.storeList) || !storeDetailList.data.storeList.length) {
        return [];
    }
    //서비스 가입유무를 통한 CCTV 가입유무 확인 ( CCTV는 가입하였으나, 매장설정하지 않은 경우)
    //const ownerServiceList = await SohoApi.serviceContractList();

    //매장정보에서 CCTV가입유무 확인
    const isJoinCCTV = storeDetailList.data.storeList.filter( (targetItem) => {
        if ( targetItem.lzpList.length > 0) return targetItem;
    }).length > 0 ? true : false;

    let cameras = {
        data: {
            list : []
        }
    };

    if ( isJoinCCTV ){
        try {
            //카메라 인증키 조회 후 카메라 목록 생성
            const cctvAuthRet = await SohoApi.getCctvAuth();
            if(cctvAuthRet && cctvAuthRet.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                //무조건 카메라 정보를 조회하면 안됨.
                 cameras = await CctvApi.getCameras();
            }
        } catch(e) {
        }    
    }

    const storeMappingList = [];
    if(storeDetailList.data.storeList.length > 0) {
        storeDetailList.data.storeList.map((item, index) => {

            const storeMapping = {
                storeId: item.storeId,
                storeNm: item.storeNm,
                lpzEntrNo: '',
                camId: '', 
                cams: [],
                cam_device_sn_id : [],
                lpzNo: item.storeInetNo,
                lzpEntrNo: item.lzpList.length > 0 ? item.lzpList[0].lzpEntrNo : '',
            };

            if(cameras) {
            
                if(item.lzpList.length > 0) {
                    const camIds = [];
                    const cams = [];
                    const cam_device_sn_id = [];
                    item.lzpList.map((lzp) => {
                        const lzpEntrNo = lzp.lzpEntrNo;
                        if(item.lzpList.length > 0 && cameras.data.list !== undefined) {
                            cameras.data.list.map(camera => {
                                const cam = {};
                                if (lzpEntrNo.indexOf(camera.info[0].subsNo) > -1) {
                                    camIds.push(camera.camId);
                                    cam.camId = camera.camId;
                                    cam.camName = camera.camName;
                                    console.log('camera.camOpenUrl', camera.camOpenUrl);
                                    cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
                                    cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
                                    if (camera.device_sn_id.length > 0) {
                                        cam_device_sn_id.push(camera.device_sn_id)
                                    }
                                    cams.push(cam);
                                }
                            });
                        }
                    })
                    storeMapping.camId = camIds.join(',');
                    storeMapping.cam_device_sn_id = cam_device_sn_id;
                    storeMapping.cams = cams;
                }

            }

            console.log('storeMapping', storeMapping);
            storeMappingList.push(storeMapping);
        });
    }
    return storeMappingList;
}

/* 체크해야 할 필수 동의 항목이 존재하면 true, 아니면 false */
export const checkAgreeRegInfo = async () => {
    SessionStore.setAgreeCheck();
    const result = await SohoApi.getTermsAgreeList();
    if(Array.isArray(result.data.list)) {
        const termsAgreeList = result.data.list.filter((agree) => {
            if (agree.prvsAgreeYn !== 'Y' && agree.estlYn === 'Y') {
                return agree;
            }
        })
        if(termsAgreeList.length > 0 ) {
            return true;
        }
    }
    return false;
}
/* 초대 목록이 존재하면 true, 아니면 false */
export const checkFamilyInviteInfo = async () => {
    SessionStore.setInviceFamilyCheck();
    const familyInviteList = await SohoApi.getFamilyInviteList();
    if(Array.isArray(familyInviteList.data.list)) {
        const vaildInviteList = familyInviteList.data.list.filter((invite) => {
            if(invite.reqStus === "0" || invite.reqStus === "3") {
                return invite;
            }
        });
        if(vaildInviteList.length > 0) {
            return true;
        }
    }
    return false;

/*
    let vaildInviteList = [];
    if(inviteList.length) {
        vaildInviteList = inviteList.reduce((prev, cur) => {                            
            // 0: 초대요청, 1:초대수락, 2:초대거부, 3: 초대만료
            if(cur.reqStus === "0" || cur.reqStus === "3") {
                prev.push(cur);
            }
            return prev;
        }, []);
    }
    //--- 등록할 패밀리계정이 있으면 등록화면으로 이동
    if(vaildInviteList.length > 0) {
        return true;
    }
    return false;
*/

}

/* 로그아웃 */
export const clearLocalData = () => {
    try {
        setCentrexSsoKey('','');
        setCenterexUserIdPass('','');
        setCctvToken('');
        setTempCctvToken('');
        LocalStore.clear();
        SessionStore.clear();
        userInfo.initialize();
        userData.initialize();
        NativeBridge.setAlarm('delete');
        NativeBridge.setCallMemoPopup('off');      
        
        clearApiRetryCnt();

    } catch(e){
        console.log(e);
    }
}
export const clearApiRetryCnt = () => {
    initSohoCentrexAuthRetryEnv();
    initSohoCctvAuthRetryEnv();
    initCctvAuthRetryEnv();
    initCentrexAuthRetryCntEnv();
}