import React from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store'
import OrderComplete from './completeorder/OrderComplete';
import OrderNew from './neworder/OrderNew';

const Order = observer(({ history }) => {

    const { userData } = store;

    const OrderTapClick = (currMenu=null) => {
        userData.setOrderTabList(currMenu);

        history.replace(`/ssisicga/order/${currMenu}`);
    }

    return (
        <div className="fix_container">
            {/* <!-- 씨식가 주문내역 --> */}
            <div className="food_order_list">
                <div className="tab_btn_wrap">
                    <div id="tab_style4" className="tab_style4 tab2">
                    {/* <!-- 220322 주문 미확인, 신규 주문수 추가 --> */}
                        <button className={"tab_item" + (userData.dataset.orderTap === 'neworder' ? " active" : "")} onClick={() => OrderTapClick('neworder')}>신규주문<span className="order_num">{userData.dataset.newOrderLength === '0' ? '0' : userData.dataset.newOrderLength}</span></button>
                        <button className={"tab_item" + (userData.dataset.orderTap === 'completeorder' ? " active" : "")} onClick={() => OrderTapClick('completeorder')}>처리완료</button>
                    </div>
                </div>
                { (userData.dataset.orderTap === 'neworder') &&
                    <Route path="/ssisicga/order/neworder" component={OrderNew} />
                }
                { (userData.dataset.orderTap === 'completeorder') &&
                    <Route path="/ssisicga/order/completeorder" component={OrderComplete} />
                }
            </div>
        </div>
    );
})

export default Order