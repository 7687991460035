import {webUI} from '../libs/common';

export const tabUi = (function() {
	let subtab;
    return {
		"initSubTab": function(container, menuIdx) {
			let subtab = container;
			tabUi.activeBar(menuIdx,subtab);
			webUI.addDelegate(subtab, "click", ".tab_item", function(e) {
				e.preventDefault();
				if (!this.classList.contains('active')) {
					let indexNum = webUI.getChildIndex(this);
					subtab.querySelector('.active').classList.remove('active');
					this.classList.add('active');
					tabUi.activeBar(indexNum,subtab);
					webUI.animatedScrollTo(subtab, (subtab.querySelectorAll('li')[indexNum].offsetLeft + subtab.querySelectorAll('li')[indexNum].clientWidth * 0.5) - (subtab.clientWidth * 0.5), 200);
				}
			});
			window.addEventListener('resize', function(e) {
				let indexNum = webUI.getChildIndex(subtab.querySelector('.active'));
				tabUi.activeBar(indexNum,subtab);					
			});
			webUI.animatedScrollTo(subtab, (subtab.querySelectorAll('li')[menuIdx].offsetLeft + subtab.querySelectorAll('li')[menuIdx].clientWidth * 0.5) - (subtab.clientWidth * 0.5), 200);
        },
		"activeBar" : function(index, container) {
			let subtab = container;
			let bar = subtab.querySelector('.active_bar');
			let initPos = container.querySelectorAll('li')[index].lastElementChild;
			bar.style.left = initPos.offsetLeft + 'px';
			bar.style.width = initPos.offsetWidth + 'px';
		},
		"init3DepthTab": function(container) {
			let subtab = container;
			webUI.addDelegate(subtab, "click", ".tab_item", function(e) {
				e.preventDefault();
				if (!this.classList.contains('active')) {
					let indexNum = webUI.getChildIndex(this);
					subtab.querySelector('.active').classList.remove('active');
					this.classList.add('active');
					//webUI.animatedScrollTo(subtab, (subtab.querySelectorAll('li')[indexNum].offsetLeft + subtab.querySelectorAll('li')[indexNum].clientWidth * 0.5) - (subtab.clientWidth * 0.5), 300);
				}
			});
        }
    }
})();