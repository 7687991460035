import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { action, set, useStrict } from 'mobx'
import { layerPopup } from "../../../../../libs/common";
import { toast } from "../../../../../libs/common";
import * as LocalStore from "../../../../../store/LocalStore";



const AlwaysCallRing = observer(({history, location}) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    
    const [alwaysRing, setAlwaysRing] = useState(null);
    const [titleValue, setTitleValue] = useState("");
    const [saveTitleValue, setSaveTitleValue] = useState("");
    const [saveComTextValue, setSaveComTextValue] = useState("");
    const [eachCheckInput, setEachCheckInput] = useState("");
    const [comTextValue, setComTextValue] = useState("");
    const [checked, setChecked] = useState(true);
    const [ttsPrePlayInfo, setTtsPrePlayInfo] = useState(null);

    console.log("배경음 value", userInfo.dataset.defaultGrt.ringEngName);

// 화면이 렌더링 되었을 때 speker(여성) default 값 체크
useEffect(() => {
setEachCheckInput("0")
const subscription = NativeBridge.eventObservable.subscribe(event => {


    // $('.ringback_greeting textarea, .ringback_greeting input[type="text"]').on("focusin", function(){
    //     $('.btn_box.fixed').addClass('btn_static');
    //     $('#wrap').removeClass('fixed_bottom');
    // });
    // $('.ringback_greeting textarea, .ringback_greeting input[type="text"]').on("focusout", function(){
    //     $('.btn_box.fixed').removeClass('btn_static');
    //     $('#wrap').addClass('fixed_bottom');
    // });
    // $('.ringback_greeting textarea, .ringback_greeting input[type="text"]').on("blur", function(){
    //     $('.btn_box.fixed').removeClass('btn_static');
    //     $('#wrap').addClass('fixed_bottom');
    // });

    // $('ringback_greeting textarea, .ringback_greeting input[type="text"]')., 

    // if ((navigator.userAgent.indexOf('iPhone') != -1) || (navigator.userAgent.indexOf('iPod') != -1) || (navigator.userAgent.indexOf('iPad') != -1)) { // IOS 감지
	// 	// 통화연결음 textarea, input 입력 시 fixed 버튼 감추기
	// 	$('.ringback_greeting textarea, input').on("focusin", function(){
	// 		$('.btn_box.fixed').hide();
	// 		$('#wrap').removeClass('fixed_bottom');
	// 	});
	// 	$('.ringback_greeting textarea, input').on("focusout", function(){
	// 		$('.btn_box.fixed').show();
	// 		$('#wrap').addClass('fixed_bottom');
	// 	});
	// 	$('.ringback_greeting textarea, input').on("blur", function(){
	// 		$('.btn_box.fixed').show();
	// 		$('#wrap').addClass('fixed_bottom');
	// 	});
	// }
    
    switch (event.type) {                   
        case 'CALL_BACKPRESS':
                if(document.getElementById("popup_prelisten").style.display === "block"){
                    return layerPopup.hide('#popup_prelisten')
                }
                if(document.getElementById("make_call_before").style.display === "block"){
                    return layerPopup.hide('#make_call_before')
                }
                if(((location.pathname === "/shop/call/callset/callring/alwayscall" && document.getElementById("popup_prelisten").style.display === "none") ||
                (location.pathname === "/shop/call/callset/callring/alwayscall" && document.getElementById("popup_prelisten").style.display === ""))){
                    goBackPage();
                }
        break;
    default:
        break;
    }

});

if($('#wrap').hasClass('gnb_bottom')) {
    $('#wrap').removeClass('gnb_bottom')
    $('#wrap').addClass('fixed_bottom');
  }        
return () => {
    subscription.unsubscribe();
    if($('#wrap').hasClass('fixed_bottom')) {
        
      $('#wrap').removeClass('fixed_bottom');
      $('#wrap').addClass('gnb_bottom');
    }
}
}, [])


useEffect(()=> {
    document.getElementsByName("alwaysCallText")[0].onblur = () => {
      if(comTextValue.length < 1){
      document.getElementsByName("callLabel2")[0].style.display = "block";
      }
  }
},[comTextValue])

useEffect(() => {
    if(comTextValue === "" || titleValue === ""){
        $('#always_callring').attr('disabled', true);
    }
    else{
        $('#always_callring').attr('disabled', false);
    }
},[titleValue, comTextValue]);

const centrexIdCheck = () => {
    const centrexId = LocalStore.getStoreInetNo();
    const tempCentrexPass = LocalStore.getTempInetPass();
    return [centrexId ,tempCentrexPass]
}
  
const [resultNum, resultPass] = centrexIdCheck();

//통화연결음 생성
const makeTtsMoh = async (title, com, speaker,bgRing) => {
    if(com === "" || com === []){
        com = "안녕하세요. 고객님! 우리가게 역삼점입니다. 잠시만 기다려 주시면 곧 통화가 연결됩니다."
    }
    NativeBridge.getLoadingStart();
    const makeTtsMohData = await CentrexApi.getMakettsMoh({
        newservice : "com", // 전체:com, 시간:work, 요일별:week
        titlename : title.trim(), //음원 명 2자 이상 20자 이하
        speaker : speaker, // 0 : 남성, 1 : 여성, 2 : 아이
        bgfile : bgRing, // "(빈값)" : 배경사용안함 //봄,여름,가을,겨울
        comtext : com.trim() // 전체음원멘트 최대 200자(한글기준, 공백포함)
    }).catch((e) => {
        console.log(e);
		userInfo.setDefalutGreeting({
					ringName : "비발디 사계 봄",
					ringEngName : "bg_01",
				  })
		NativeBridge.getLodingEnd();
		history.goBack();
		toast("통화연결음을 등록했습니다.")
    })

    if(makeTtsMohData.data.SVC_MSG === "OK"){
        NativeBridge.sendUserActionLog('detail', 'D094');
        setAlwaysRing(makeTtsMohData.data);
        setTitleValue(""); // 저장 버튼 눌렀을 때 inputValue값 초기화
        setComTextValue("") //comValue 초기화
        setChecked(true); //음원멘트 default 초기화
        userData.setBgCheck(true);
        userInfo.setDefalutGreeting({
            ringName : "비발디 사계 봄",
            ringEngName : "bg_01",
        });   
        NativeBridge.getLodingEnd();
        toast("통화연결음을 등록했습니다.")
        history.goBack();
    }else{
        layerPopup.showCommon(
            '#popup_onetitlemsgbutton', 
            null, 
            '통화연결음 등록 실패', 
            '통화연결음 등록에 실패하였습니다. 다시 시도해주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                }
            ]
        )   
    }
}

//연결음 미리듣기 API
const ttsPrePlay = async (speaker, bgRing,text ) => {
    if(text === ""){
        text = "안녕하세요. 고객님! 우리가게 역삼점입니다. 잠시만 기다려 주시면 곧 통화가 연결됩니다."                       
    }
    const ttsPrePlayData = await NativeBridge.getRingMusicPlay(speaker, bgRing, text)
    NativeBridge.sendUserActionLog('detail', 'D093', '', '기본통화연결음');
    setTtsPrePlayInfo(ttsPrePlayData);
}

//연결음 미리듣기 click
const ttsPrePlayOnClick = () => {
    ttsPrePlay(eachCheckInput,
               userInfo.dataset.defaultGrt.ringEngName,       
               comTextValue, 
              );
}

//저장 버튼 에외처리 해야됨
const saveOnHandleClick = () => {
    if(titleValue.trim().length < 2 || titleValue.trim().length > 20){
        layerPopup.showCommon(
            '#popup_onetitlemsgbutton', 
            null, 
            '제목 글자 수 알림', 
            '2자 이상 20자 이하로 입력해주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                },
            ]
        )
    }
    else if(comTextValue.trim().length < 5 || comTextValue.trim().length > 200){
        layerPopup.showCommon(
            '#popup_onetitlemsgbutton', 
            null, 
            '내용 글자 수 알림', 
            '5자 이상 200자 이하로 입력해주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlemsgbutton');
                },
            ]
        )
    }
    else{
    makeTtsMoh(titleValue, 
               comTextValue, 
               eachCheckInput, 
               userInfo.dataset.defaultGrt.ringEngName
              );//통화연결음 생성 API
    }
}
  

 //title input관리
 const tittleOnChange = (e) => {
    setTitleValue(e.target.value);
}

// comText input관리
const comTextOnChange = (e) => {
    setComTextValue(e.target.value);
}

//여성, 남성, 아이 구분 각각 0,1,2
const speakerHandelClick = (e) => {
    if(e.target.value === "1" || e.target.value ==="2")
     {
        setChecked(false)
    }
    else(
        setChecked(true)
    )
    setEachCheckInput(e.target.value);
 }

 const goBackPage = () => {
    NativeBridge.sendUserActionLog('detail', 'D092', "", "기본통화연결음");
    layerPopup.showCommon(
      '#make_call_before', 
      null, 
      '이전 화면으로 이동', 
      '통화연결음을 만들지 않고 이전화면으로 <br /> 이동하시겠어요?',
      [
          function() {
              layerPopup.hide('#make_call_before');
          },
          function() {
            userData.setBgCheck(true);
            layerPopup.hide('#make_call_before',() => {
                history.goBack();
            });
          }
      ]
  )
}
const textAreaLabelControl = (e) => {
    if(e.target.id === "abc"){
     document.getElementsByName("callLabel2")[0].style.display = "none";
     document.getElementsByName("alwaysCallText")[0].focus();
    }
}

  const inputClear = () => {
      setTitleValue("")
  }
  
  const saveButtonNone = () => {
    if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "none";
  }
  const saveButtonBlock = () => {
    if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "block";
  }
  const popupScrollControll = () => {
      if(userInfo.dataset.defaultGrt.ringEngName === "bg_01"){
          userData.setBgCheck(true);
      }
      layerPopup.show('#popup_prelisten');
  }

  return (
    <>
			<div className="content store" onBlur={saveButtonBlock}>
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>기본 통화연결음</h2>
					</div>
				</div>
				<div className="ringback_greeting">
				    <div className="greet_sec">
				        <span className="title" >통화연결음 이름</span>
				        <div className="input_box style2">
                            <input type="text" onFocus={saveButtonNone}  placeholder="사용자 연결음" value={titleValue} onChange={(e) => tittleOnChange(e)} />
                            <span className="ip_clear hidden usetap" onClick={() =>{inputClear()}}>초기화</span>
                        </div>
				    </div>
				    <div className="greet_sec">
				        <span className="title">배경음</span>
				        {/* <button type="button" className="sel_sound" onClick={()=>layerPopup.show('#popup_prelisten')}>비발디 사계 봄<i className="btn_dark_down"></i></button> */}
                        <button type="button" className="sel_sound" value={userInfo.dataset.defaultGrt.ringEngName} onClick={()=>popupScrollControll()}>{userInfo.dataset.defaultGrt.ringName}<i className="btn_dark_down"></i></button>
                    </div>
				    <div className="greet_sec">
				        <span className="title">인사말</span>
                        <div className="tab_style4_radio tab3">
                            <input type="radio" id="sourceWoman"  name="soundSource" value="0" checked={checked} onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceWoman" >여성</label>

                            <input type="radio" id="sourceMan"  name="soundSource"  value="1"   onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceMan">남성</label>

                            <input type="radio" id="sourceChild"  name="soundSource" value="2"   onChange={(e) => speakerHandelClick(e)} /> 
                            <label htmlFor="sourceChild">아이</label>
                        </div>
                        <div className="textarea_wrap textarea_greet">

                        <div className="textarea_wrap box1">
                        <textarea  id="alwaysCall" name="alwaysCallText" onFocus={saveButtonNone}  value={comTextValue} onChange={(e) => comTextOnChange(e)}> </textarea>
                            <label htmlFor="" className="textarea_label" id="abc" name="callLabel2" style={{display : ""}} onClick={(e)=>textAreaLabelControl(e)}>
                            예) 안녕하세요. 고객님! 우리가게 역삼점입니다.<br />
                                    잠시만 기다려 주시면 곧 통화가 연결됩니다.
                            <span className="txt_limit">200자 이내</span>    
                            </label>
                            </div>
                        </div>
				    </div>
				</div>
				<div className="btn_box fixed" name="save_button">
                    <div className="inner">
                        <button type="button" className="btn_half7 btn_point usetap" onClick={()=>ttsPrePlayOnClick()} >미리듣기</button>
                        <button type="button" id="always_callring" className="btn_half3 btn_line2 usetap" onClick={() => saveOnHandleClick()}>저장</button>
                    </div>
                </div>
			</div> 
    </>
  );
});
export default AlwaysCallRing;
