// import * as URLs from '../const';
import axios from 'axios'
import { layerPopup } from '../libs/common';
import CentrexApi, { setCentrexSsoKey } from './CentrexApi';
import { setCctvToken } from './CctvApi';
import * as LocalStore from '../store/LocalStore';
import * as SessionStore from '../store/SessionStore';
import * as CONSTS from '../const';
import * as NativeBridge from '../utils/NativeBridge';

// let sohoToken = "";
let SohoCentrexAuthRetryCnt = 0;
let SohoCentrexAuthRetryInetNo = "";
let SohoCctvAuthRetryCnt = 0;

const instance = axios.create({
    baseURL: `//${process.env.REACT_APP_SOHO_500_API_URL}`,
    timeout: 5000,
    headers: {'Accept': '*/*', 'Content-Type': 'application/json' }
    // headers: {'X-Custom-Header': 'foobar'}
});
instance.interceptors.request.use(
    function (config) {
        if(config.url.indexOf('orderList') < 0) {
            NativeBridge.getLoadingStart();
        }
        return config;
    }, 
    function (error) {
        NativeBridge.getLodingEnd();
        return Promise.reject(error);
    }
);
instance.interceptors.response.use((response) => 
    {
        NativeBridge.getLodingEnd();
        if(response.data.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
            return response.data;
        } else {

            // 인증관련 오류시 팝업 처리 (팝업 확인 버튼 누를시 로그아웃 처리)
            const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===response.data.retCode);
            if(typeof(errCodeData) !== "undefined") {
                layerPopup.showSohoAuthError(errCodeData.title, errCodeData.msg);
                initSohoCentrexAuthRetryEnv();
            }
            return response.data;
        }
    },
    async (error) => 
    {
        NativeBridge.getLodingEnd();
        // timeout error
        // if(error.code == 'ECONNABORTED'){
        //     console.log('timeout error')
        //     layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(SOHO)", [
        //         function() {
        //             layerPopup.hide("#popup_onetitlemsgbutton");
        //         }
        //     ]);
        // }
        // 에러 처리
        // soho/admin/errLog (API 서버로 오류코드 전송)
        return Promise.reject(error);
    }
);

// const resErrorHandler = (retData) => {

//     const retCode = retData.retCode;
//     const retMsg = retData.retMsg;

//     if(retCode === "9003") {
//         layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", retMsg, [
//             function() {
//                 layerPopup.hide("#popup_onetitlemsgbutton");
//             }
//         ]);
//     }

//     console.log("response error : code => ", retCode);
// }

export const setSohoToken = (token) => {
    console.log(">>>>setSohoToke:" + token);
    instance.defaults.headers.common['Authorization'] = token;
    LocalStore.setSohoToken(token);
    SessionStore.setSohoToken(token);
}

export const initSohoCentrexAuthRetryEnv = () => {
    SohoCentrexAuthRetryCnt = 0;
    SohoCentrexAuthRetryInetNo = "";    
}
export const initSohoCctvAuthRetryEnv = () => {
    SohoCctvAuthRetryCnt = 0;
}

const SohoCentrexAuthErrorPopupCall = (retCode, params) => {
    // 센트릭스 Auth 에러인경우 팝업 노출 (SOHOAPI_AUTH_LOGOUT_ERROR 가 아닌경우)
    const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===retCode);
    if(retCode === null || typeof(errCodeData) === "undefined") {
        if(SohoCentrexAuthRetryInetNo !== params.storeInetNo) {
            SohoCentrexAuthRetryCnt = 0;
            SohoCentrexAuthRetryInetNo = params.storeInetNo;
        }
        console.log("getCentrexAuth : " , SohoCentrexAuthRetryInetNo, params.storeInetNo, SohoCentrexAuthRetryCnt);
        if(SohoCentrexAuthRetryCnt === 0) {                    
            SohoCentrexAuthRetryCnt++;
            layerPopup.show("#popup_soho_centrex_auth_error");
        }
    }
}
const SohoCctvAuthErrorPopupCall = (retCode) => {
    const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===retCode);
    if(retCode === null || typeof(errCodeData) === "undefined") {
        console.log("SohoCctvAuthRetryCnt : " , SohoCctvAuthRetryCnt);
        if(SohoCctvAuthRetryCnt === 0) {                    
            SohoCctvAuthRetryCnt++;
            layerPopup.show("#popup_soho_cctv_auth_error");
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // 공지사항 조회
    getNotice(params) {
        return instance({
            url: '/sohosvc/api/notiList',
            method: 'post',
            data: params
        })
    },
    // 공지사항 상세조회
    getNoticeDetail(params) {
        return instance({
            url: '/sohosvc/api/notiDetail',
            method: 'post',
            data: params
        })
    },


    /* kmc url 암호화
     * param = {kmcUrl:""}
    */
    getKmcUrlEnc() {
        return instance({
            url: '/sohosvc/api/kmcUrlEnc',
            method: 'post',
            data: {urlCode: process.env.REACT_APP_URL_CODE}
        })
    },


    /* user reg
     * param  = {
         encData : ""  //kmc 로부터 받은 결과값 그대로
     }
    */
    regUser(params) {
        return instance({
            url: '/sohosvc/api/userRegi',
            method: 'post',
            data: params
        })
    },
    

    /* authtoken 발급받기
     * param = {
        "userId" : "210614SOHOUS000004" 	
        ,"clientIp" : "127.0.0.1"			
        ,"devInfo" : "PHONE"				
        ,"osInfo" : "android"			
        ,"nwInfo" : "WIFI"				
        ,"devModel" : "TEST001"			
        ,"carrierType":"S"		
     }
    */
    async getAuthToken(params) {
        params = {
            ...params,
            userId: typeof(params.userId)==="undefined" ? LocalStore.getUserId() : params.userId,
            ownerUserId: typeof(params.ownerUserId)==="undefined" ? (LocalStore.getOwnerId() ? LocalStore.getOwnerId() : LocalStore.getUserId()) : params.ownerUserId
        }
        const rst = await instance({
            url: '/sohosvc/api/authToken',
            method: 'post',
            data: params
        })
        if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
            setSohoToken(rst.data.authToken);
        }        
        return rst;
    },

    // 하단 띠 배너 조회
    getHomeBannerList(params={}) {
        return instance({
            url: '/sohosvc/api/bannerList',
            method: 'post',
            data : params
        })
    },
    // 사장님 광장 카테고리 조회
    getCategoryList(params={}) {
        return instance({
            url: '/sohosvc/api/categoryList',
            method: 'post',
            data : params
        })
    },
    getBnfCategoryList(params={}) {
        return instance({
            url: '/sohosvc/api/bnfCategoryList',
            method: 'post',
            data : params
        })
    },
    getBnfDetailList(params={}) {
        return instance({
            url: '/sohosvc/api/bnfDetail',
            method: 'post',
            data : params
        })
    },
    // 홈 메인 팝업 데이터 조회
    getPopupList(params={}) {
        return instance({
            url: '/sohosvc/api/popupList',
            method: 'post',
            data : params
        })
    },

    /* 약관동의 목록 */
    getTermsAgreeList() {
        return instance({
            url: '/sohosvc/api/termsAgreeList',
            method: 'post',
            data : {}
        })
    },
    // 약관동의 상세목록 조회
    getTermsAgreeDetailList(params) {
        return instance({
            url: '/sohosvc/api/termsDetail',
            method: 'post',
            data: params
        })
    },        

    
    /* 
     * 약관동의 저장
     param = {
        prvsInfoList: [
          {
             prvsId : "",
             prvsAgreeYn : ""
          }
        ]
      
    }
    */
    // 사용자 탈퇴
    getUserQuit(params) {
        return instance({
            url: '/sohosvc/api/userWidr',
            method: 'post',
            data: params
        })
    },
    regTermsAgreeInfo(params) {
        return instance({
            url: '/sohosvc/api/termsAgree',
            method: 'post',
            data: params
        })
    },



    // network error log
    // sendErrLog(params) {
    //     return instance({
    //         url: '/sohosvc/api/errLog',
    //         method: 'post',
    //         data: params
    //     })
    // },

    // 날씨 정보 조회 
    getWeatherInfo(params={}) {
        return instance({
            url: '/sohosvc/api/weatherInfo',
            method: 'post',
            data: params
        })
    },
    // 홈카드 조회
    setHomeCardInfo(params) {
        return instance({
            url: '/sohosvc/api/serviceCardList',
            method: 'post',
            data: params
        })
    },

    // 서비스 변경 알림 조회
    setServiceChgListInfo(params={}) {
        return instance({
            url: '/sohosvc/api/serviceChgList',
            method: 'post',
            data: params
        })
    },
    //통화 연결음 조회 soho/admin/ringToneInfo
    getRingToneInfo(params) {
        return instance({
            url: '/sohosvc/api/ringToneInfo',
            method: 'post',
            data: params
        })
    },
    //통화 연결음 저장
    getRingToneRegi(params) {
        return instance({
                url:'/sohosvc/api/ringToneRegi',
                method:'post',
                data: params,
         })
    },

    // 계정목록 조회
    getAccountList(params={}) {
        return instance({
            url: '/sohosvc/api/accountList',
            method: 'post',
            data: params
        })
    },

    //패밀리 계정 조회
    getFamilyAccountList(params) {
        return instance({
            url : '/sohosvc/api/familyAccountList',
            method:'post',
            data: params
        })
    },

    //패밀리 계정 등록
    getFamilyAccountRegi(params) {
        return instance({
            url : '/sohosvc/api/familyAccountRegi',
            method : 'post',
            data : params
        })
    },

    //패밀리 계정 삭제
    getFamilyAccountDelete(params) {
        return instance({
            url : '/sohosvc/api/familyAccountDelete',
            method : 'post',
            data : params
        })
    },

    // 패밀리 초대 목록 조회
    getFamilyInviteList(params={}) {
        return instance({
            url: '/sohosvc/api/familyInviteList',
            method: 'post',
            data: params
        })
    },

    // 패밀리 초대 수락
    /*
    {
        "ownerUserId" : "123"
    }
    */
    familyAccountConfirm(params) {
        return instance({
            url: '/sohosvc/api/familyAccountConfirm',
            method: 'post',
            data: params
        })
    },
    // 패밀리 초대 거절  (inviteType => A : 전체, M : 단건)
    /*
    {
        "ownerUserId" : "1234",
        "inviteType" : "M"
    }
    */
    familyAccountReject(params) {
        return instance({
            url: '/sohosvc/api/familyAccountReject',
            method: 'post',
            data: params
        })
    },

    /*
    {
        "ownerUserId" : "123"
    }
    */
    // 매장 목록 조회
    getStoreList(params) {
        return instance({
            url: '/sohosvc/api/storeList',
            method: 'post',
            data: params
        })
    },
    /*
    {
    "ownerUserId":"210609SOHOUS000044",
    "storeNm":"매장01"
    }
    */
    storeRegi(params) {
        return instance({
            url: '/sohosvc/api/storeRegi',
            method: 'post',
            data: params
        })
    }, 
        /*
    {
    "storeId":"asdfasdfasd"
    }
    */
    storeDelete(params) {
        return instance({
            url: '/sohosvc/api/storeDelete',
            method: 'post',
            data: params
        })
    }, 


    /*
    // 스토어 상세 목록 (cctv 매핑 데이터 있음)
    {
    "ownerUserId":"210609SOHOUS000044",
    }
    */
    storeDetailList(params) {
        return instance({
            url: '/sohosvc/api/storeDetail',
            method: 'post',
            data: params
        })
    }, 

    /*
    서비스계약목록 조회
    
    */
    serviceContractList(params={}) {
        return instance({
            url: '/sohosvc/api/serviceContractList',
            method: 'post',
            data: params
        })
    },


    /*
    매장 서비스 등록
    {
        "storeList":[
            {
                "storeId":"210618USSTIN000007",
                "lpzEntrNo":"623996973880",
                "lzpEntrNo":"623996974078,623996974091,623996974177"
            }
        ]
    }
    */
    storeServiceRegi(params) {
        return instance({
            url: '/sohosvc/api/storeServiceRegi',
            method: 'post',
            data: params
        })
    },
    
    /*
    매장 추가 정보 등록
    params = {
        storeId: "",
        storeAddr: "",
        storeAddrDetl: "",
        storeOwnKd: "",
        storeRnt: "",
        rntSpc: ""
    }
    */
    storeDetailRegi(params) {
        return instance({
            url: '/sohosvc/api/storeDetailRegi',
            method: 'post',
            data: params
        })
    },

    // 앱 버전 조회
    getAppVersionList(params) {
        return instance({
            url: '/sohosvc/api/appVersionInfo?osType=' + params,
            method: 'get',
        })
    },
    //매장 별 서비스 가입정보
    getMyServiceDetailList(params) {
        return instance({
            url: '/sohosvc/api/myServiceDetail',
            method : 'post',
            data: params
        })
    },


    // cctv 인증키 발급 (SohoCctvAuthRetryCnt)
    getCctvAuth() {
        if(SohoCctvAuthRetryCnt > 1) {
            console.log("SohoCctvAuthRetryCnt Over cnt : ", SohoCctvAuthRetryCnt);
            return Promise.resolve();
        }
        return instance({
            url: '/sohosvc/api/cctvAuth',
            method : 'post',
            data: {ownerUserId: LocalStore.getOwnerId() ? LocalStore.getOwnerId() : LocalStore.getUserId()}
        }).then(rst=>{
            if(rst && rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                initSohoCctvAuthRetryEnv();
                setCctvToken(rst.data.authKey);
            } else {
                SohoCctvAuthErrorPopupCall(rst.retCode);
            }
            return rst;
        }).catch(e=>{  
            console.log(e);
            SohoCctvAuthErrorPopupCall(null);
        });

    },

    // 실시간 문자 사용량 조회
    async getFreeSmsDetail(currentStoreId) {
        return instance({
            url: '/sohosvc/api/freeSmsDetail',
            method : 'post',
            data: {storeId: currentStoreId}
        });
    },

    // 센트릭스 인증키 발급
    /*
    param = {
        "storeInetNo":"07075999903"
    }
    */
    async getCentrexAuth(params) {
        if(SohoCentrexAuthRetryCnt > 1 && SohoCentrexAuthRetryInetNo === params.storeInetNo) {
            console.log("SohoCentrexAuthRetryCnt Over cnt : ", SohoCentrexAuthRetryCnt);
            return Promise.resolve();
        }
        try {
            const rst = await instance({
                url: '/sohosvc/api/centrexAuth',
                method: 'post',
                data: params
            });
            if (rst && rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                LocalStore.setStoreInetNo(params.storeInetNo);
                setCentrexSsoKey(rst.data.sessId, rst.data.ssoHash);
                initSohoCentrexAuthRetryEnv();
                try {
                    await CentrexApi.getUserInfo({}, true);
                } catch(e) {
                    // userData.setShopMainTabCallError(true);
                    // userData.setShopMainTabMsgError(true);
                    console.log(e);
                    return rst;
                }
            } else {
                // 센트릭스 Auth 에러인경우 팝업 노출 (SOHOAPI_AUTH_LOGOUT_ERROR 가 아닌경우)
                SohoCentrexAuthErrorPopupCall(rst.retCode, params);
            }
            return rst;
        } catch (e) {
            console.log(e);
            SohoCentrexAuthErrorPopupCall(null, params);
        }
    },

    // 이달의 요금 조회
    getMonthBillList(params) {
        return instance({
            url: '/sohosvc/api/monthBillDetail',
            method: 'post',
            data: params
        }) 
    },
    //상황별 메시지 저장
    saveContMsg(params) {
        return instance({
            url: '/sohosvc/api/contMsgRegi',
            method: 'post',
            data: params
        }) 
    },
    //상황별 메시지 조회
    getContMsg(params) {
        return instance({
            url: '/sohosvc/api/contMsgInfo',
            method: 'post',
            data: params
        }) 
    },
    //상황별 메시지 수정
    modifyContMsg(params) {
        return instance({
            url: '/sohosvc/api/contMsgUpdate',
            method: 'post',
            data: params
        }) 
    },
    //상황별 메시지 수정
    delContMsg(params) {
        return instance({
            url: '/sohosvc/api/contMsgDelete',
            method: 'post',
            data: params
        }) 
    },
    // 사용자 정보 조회
    getMyInfo(params) {
        return instance({
            url: 'sohosvc/api/myInfo',
            method: 'post',
            data: params
        })
    },

    // 500TF API
    getBoardInfo(params) {
        return instance({
            url: 'sohosvc/500tf/api/boardInfo',
            method: 'post',
            data: params
        })
    },
    getOrderList(params) {
        return instance({
            url: 'sohosvc/500tf/api/orderList',
            method: 'post',
            data: params
        })
    },
    getBestProductList(params) {
        return instance({
            url: 'sohosvc/500tf/api/bestProductList',
            method: 'post',
            data: params
        })
    },
    getOnlineInflowPath(params) {
        return instance({
            url: 'sohosvc/500tf/api/onlineInflowPath',
            method: 'post',
            data: params
        })
    },
    getSsgJoinInfo(params) {
        return instance({
            url: 'sohosvc/500tf/api/ssgJoinInfo',
            method: 'post',
            data: params
        })
    },

    getOrderStatus(param) {
        return instance({
            url: 'sohosvc/500tf/api/orderStatus',
            method: 'post',
            data :param
        })
    }
}