import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import $ from 'jquery';
import * as NativeBridge from '../../../utils/NativeBridge';
import SohoApi from '../../../api/SohoApi';
import * as SessionStore from '../../../store/SessionStore';
import * as LocalStore from '../../../store/LocalStore';
import { layerPopup, toast } from '../../../libs/common';
import { setUserDataStoreListByOwnerId, clearLocalData, clearApiRetryCnt } from '../../login/DefaultSet';
import * as CONSTS from '../../../const/index'

const MySetting = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const [appversion, setAppversion] = useState("")
    const [accountListData, setAccountListData] = useState([]);
    const [check, setCheck] = useState(LocalStore.getPushAlram() === "0" ? true : false);
    const [check2, setCheck2] = useState(LocalStore.getCallMemo() === "0" ? true : false);

    useEffect(() => {       
        getAppVersionList();
        pushAlramCheckLook();
        callMemoGet();

        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').addClass('bg_gray');
        }    

        if(LocalStore.getCallMemo() === "" || LocalStore.getCallMemo() === undefined || LocalStore.getCallMemo() === null){
            LocalStore.setCallMemo("1")
            userData.setCallMemo("1")
            $("input:checkbox[name='callMemoCheck']").prop("checked",false)
        }
        if(LocalStore.getCallMemo() === "1"){
            $("input:checkbox[name='callMemoCheck']").prop("checked",false)
        }
        if(LocalStore.getCallMemo() === "0"){
            $("input:checkbox[name='callMemoCheck']").prop("checked",true)
        }

        if(LocalStore.getPushAlram() === "" || LocalStore.getPushAlram() === undefined || LocalStore.getPushAlram() === null){
            console.log("푸쉬알림 확인");
            LocalStore.setPushAlram("0");
            userData.setAlram("0");
            $("input:checkbox[name='alramCheck']").prop("checked",true)
        }
        if(LocalStore.getPushAlram() === "1"){
            $("input:checkbox[name='alramCheck']").prop("checked",false)
        }
        if(LocalStore.getPushAlram() === "0"){
            $("input:checkbox[name='alramCheck']").prop("checked",true)
        }
        
        const subscription2 = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : MySetting.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'GET_ALARM':
                    const alramData = event.param;
                    console.log(" alramData: " , alramData);
                    console.log(" alramData222: " , check);

                    if(alramData.status === "0"){
                        console.log(" alramData333: " , check);
                        setCheck(true);
                    }else{
                        console.log(" alramData444: " , check);
                        setCheck(false);
                    }
                    
                    // if(alramData.status === "0"){
                    //     console.log("status 0번 확인")
                    //     setCheck(prev => prev = true);
                    //     //$("input:checkbox[name='alramCheck']").prop("checked",true)
                    // }
                    // else{  
                    //     console.log("status 1번 확인")                   
                    //     //$("input:checkbox[name='alramCheck']").prop("checked",false)
                    //     setCheck(false);
                    //}
                    //deviceInfo.setData(deviceData);
                break;

                case  'SET_CALL_MEMO_POPUP' :
                    const memoData = event.param;
                    console.log("memoData : ", memoData);

                    if(memoData.status === "0"){
                        console.log("성공")
                    }
                    else{
                        callMemoSetApi("off");
                        userData.setCallMemo("1");
                        LocalStore.setCallMemo("1")
                        console.log("off");
                        setCheck2(false);
                    }

                break;

                // -- 폰 설정에서 설정에서 "다른앱위에표시" 처리 한 경우 콜백
                case  'GET_CALL_MEMO_POPUP' :
                    const memoData2 = event.param;
                    console.log("memoData2 : ", memoData2);

                    if(memoData2.status === "0"){
                        userData.setCallMemo("0");
                        LocalStore.setCallMemo("0")
                        console.log("on");
                        setCheck2(true);
                    }
                    else if(memoData2.status === "1"){
                        userData.setCallMemo("1");
                        LocalStore.setCallMemo("1")
                        console.log("off");
                        setCheck2(false);
                    }

                break;                
            default:
                break;
            }

        });
         //subscription.unsubscribe();
        
      

          return () => {
            
            if($('#wrap').hasClass('fixed_bottom')) {
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').removeClass('bg_gray');
                $('#wrap').addClass('gnb_bottom');

            }
            subscription2.unsubscribe();
          };


    }, []);

    useEffect(() => {
        pushAlramCheckLook()
    },[check])

    const SettingTabClick = (e, setSubMenu=null) => {
        const obj = e.currentTarget;
        if (setSubMenu === 'policyaccess') {
            NativeBridge.sendUserActionLog('detail', 'D058')
        }
        if (setSubMenu === 'biosetting') {
            NativeBridge.sendUserActionLog('detail', 'D055')
        }
        if (setSubMenu === 'familysetting') {
            NativeBridge.sendUserActionLog('detail', 'D012');
        }
        if (setSubMenu === 'cs') {
            NativeBridge.sendUserActionLog('detail', 'D060');
        }
        history.push(`/my/mysetting/${setSubMenu}`)
    }

    const SettingTabPinCodeClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D054')
        history.push(`/callkmc/modpass`)
    }

    const selectAccount = async () => {
        const checkedObj = $('input[type="radio"]:checked');
        const ownerId = checkedObj.val();
        const familyYn = checkedObj.attr("familyyn");
        const ownerNm= checkedObj.attr("ownernm");
        console.log("selectedId: ", ownerId);
        console.log("selectedId: ", familyYn);
        console.log("selectedId: ", ownerNm);
        console.log(checkedObj.length);
        
        if(checkedObj.length === 1 && ownerId !== LocalStore.getOwnerId()) {
           
            userInfo.setIsTempUser(false);
            userInfo.setOwnerId(ownerId);
            userInfo.setOwnerNm(ownerNm);
            pushAlramCheckApi('delete');
            callMemoSetApi('off')

            // 다음단계(마이 -> 설정)로 이동
            LocalStore.deleteHomecard();
            console.log('storeId', userData.dataset.storeId);
            await setUserDataStoreListByOwnerId(ownerId);
            // toast('계정 변경이 완료 되었습니다.')

            // 로그인 세션을 제거한다.
            SessionStore.clear();

            clearApiRetryCnt();
            
            layerPopup.hide("#popup_accountselect", ()=> {
                if(LocalStore.getBioLoginType() === '0' || LocalStore.getBioLoginType() === '1') {
                    history.push('/bio/check');
                } else {
                    history.push('/pincode/check')
                }
            });
            userInfo.setFamilyYn(familyYn);


        } else {
            userInfo.setFamilyYn('N');
            if(ownerId == LocalStore.getOwnerId()) {
                toast("현재 선택된 계정입니다, 다른 계정을 선택해주세요.");
            } else {
                toast("계정을 선택해 주세요.");
            }
            return;
        }

    }

    const logout = () => {
        NativeBridge.sendUserActionLog('detail', 'D061')
        layerPopup.showCommon("#popup_onetitle_twomsgbutton_logout", null, "로그아웃", "로그아웃 하시겠어요?", [
            function() {
                layerPopup.hide("#popup_onetitle_twomsgbutton_logout");   
            },
            function() {
                layerPopup.hide("#popup_onetitle_twomsgbutton_logout", () => {
                    execLogout();                             
                });
            }
        ]);
    }

    const getAppVersionList = async () => {
        const appversionData = await SohoApi.getAppVersionList(`${deviceInfo.dataset.os_type}`)
            setAppversion(appversionData.data.appVersion);
            console.log("getAppVersionList",appversionData.data.appVersion);
    }
    const execLogout = () => {
        clearLocalData();
        // setTimeout(() => {
        history.replace("/");
        // }, 3000);
    }
    //알람 true,false 체크
    const pushAlramCheck = (e) => {
        console.log("알람 체크11", e.target.checked)
        
        let checked = e.target.checked
        if(checked === true) {
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '알림', 
                '사장님을 위한 혜택 <br/> 실시간 가게 알림 등 알찬 소식을 <br/> 받을 수 있습니다.',
                
                [
                    function() {
                        console.log("확인")
                        pushAlramCheckApi("add")
                        userData.setAlram("0");
                        LocalStore.setPushAlram("0");
                        setCheck(checked)
                        NativeBridge.sendUserActionLog('detail', 'D059', '', 'ON')
                        layerPopup.hide("#popup_onetitlebutton_threemsg");            
                    },
                     
                ]
            )
            // setCheck(checked)
            // LocalStore.setPushAlram("0");
            // return pushAlramCheckApi("add")
        }
        else {
            layerPopup.showCommon(
                '#popup_pushalram', 
                null, 
                '알림 수신 해제', 
                '알림 수신을 해제하면 사장님을 위한 <br/> 혜택, 가게 관리에 필요한 새소식 등을 <br/> 받을 수 없습니다. <br/> 그래도 해제하시겠어요?',
                
                [
                    function() {
                        console.log("취소")
                           layerPopup.hide("#popup_pushalram")
                        
                    },
                     function() { // 확인 버튼
                        console.log("수신해제")
                        pushAlramCheckApi("delete")
                        userData.setAlram("1");
                        LocalStore.setPushAlram("1");
                        setCheck(checked)
                        NativeBridge.sendUserActionLog('detail', 'D059', '', 'OFF')
                        layerPopup.hide("#popup_pushalram");
                    }
                ]
            )
            NativeBridge.sendUserActionLog('detail', 'D059', '', 'OFF')

        }

        
    }
    
    //푸쉬 알람 체크 API
    const pushAlramCheckApi = async (checked) => {
        const pushData = await NativeBridge.setAlarm(checked)
        await pushAlramCheckLook(checked)
        console.log("네이티브 알람체크",pushData);
    }
    //푸쉬 알람 조회 API
    const pushAlramCheckLook = async (data) => {
        const alram = await NativeBridge.getAlarm(data)
        //let data = alram.param.status
        console.log("네이티브 알람 조회", alram)
    }

    //통화 메모 설정 on/Off
    const callMemoSet = (e) => {
        console.log("통화메모체크===>",e.target.checked);
        let checked = e.target.checked;

        if(checked === true){
            toast("전화 수신 시 통화 메모가 표시됩니다.")
            callMemoSetApi("on");
            userData.setCallMemo("0");
            LocalStore.setCallMemo("0");
            NativeBridge.sendUserActionLog('detail', 'D057', '', 'ON');
            console.log("온")
            setCheck2(checked);
        } else {
            toast("전화 수신 시 통화 메모를 표시하지 않습니다.")
            callMemoSetApi("off");
            userData.setCallMemo("1");
            LocalStore.setCallMemo("1")
            NativeBridge.sendUserActionLog('detail', 'D057', '', 'OFF');
            console.log("off");
            setCheck2(checked);
        }
        
    }
    //통화 메모 설정
    const callMemoSetApi = async (data) => {
        const memo = await NativeBridge.setCallMemoPopup(data)
        console.log("메모설정확인",memo);
    }

    //통화 메모 조회
    const callMemoGet = async () => {
        const memo = await NativeBridge.getCallMemoPopup();
        console.log("메모설정조회",memo)
    }

    const UpdateClick = async(currVersion, updateVersion) => {
        if(currVersion < updateVersion) {
            layerPopup.showCommon(
                '#version_store_popup',
                null,
                '업데이트 알림',
                '새로운 버전이 나왔습니다.<br /> 지금 업데이트하고 더 안정적인<br /> 서비스를 이용하시겠어요?',
                [
                    function() {
                        layerPopup.hide("#version_store_popup")
                    },
                    function() {
                        layerPopup.hide("#version_store_popup", () => {
                            if(deviceInfo.dataset.os_type === 'android') {
                                NativeBridge.externalCall('https://play.google.com/store/apps/details?id=com.lguplus.sohoapp')
                            } else {
                                NativeBridge.externalCall('https://itunes.apple.com/app/id1571096278')
                            }    
                        })
                    },
                ]
            )
        }
    }

    const AccountChange = async() => {
        NativeBridge.sendUserActionLog('detail', 'D011');
        try {
            await SohoApi.getAccountList().then((accountList) => {
                if(accountList.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    console.log("전 계정목록",accountList.data.list);
                    setAccountListData(accountList.data.list);
                    if(accountList.data.list) {
                        const currAccountIdx = accountList.data.list.map((item) => ( item.ownerUserId )).indexOf(userInfo.dataset.ownerId);
                        $(`input:radio[id='accounts${currAccountIdx + 1}']`).prop("checked", true);
                        layerPopup.show('#popup_accountselect');
                    }
                }
            }).catch((e) => {
                console.log(e);
            })
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> history.goBack()}>뒤로가기</button>
						<h2>앱 설정</h2>
					</div>
				</div>
				<div className="my_setting">
                    <div className="sec_wrap">
                        <div className="set_wrap usetap" onClick={()=> AccountChange()}>
                            <span className="set_title">다른 계정으로 로그인</span>
                            <span className="i_more"></span>
                        </div>
                        <div className="set_wrap usetap" onClick={()=> SettingTabPinCodeClick()}>
                            <span className="set_title">비밀번호 재설정</span>
                            <span className="i_more"></span>
                        </div>
                        <div className="set_wrap is_desc usetap" onClick={(e)=> SettingTabClick(e, 'biosetting')}>
                            <span className="set_title">지문 또는 얼굴인식 로그인 설정</span>
                            <span className="desc">비밀번호 대신 지문 또는 얼굴을 인식해 <br/>로그인할 수 있습니다.</span>
                            <span className="i_more"></span>
                        </div>
                    </div>
                    <div className="sec_wrap">
                        <div className="set_wrap usetap" onClick={(e)=> SettingTabClick(e, 'familysetting')}>
                            <span className="set_title">초대 계정 관리</span>
                            <span className="i_more"></span>
                        </div>
                        {
                            deviceInfo.dataset.os_type === 'ios' ?
                            <>
                            <div className="set_wrap is_desc usetap">
                                <span className="set_title">통화 메모 설정</span>
                                <span className="desc">주소록에 저장된 고객에게 연락이 오면 <br/>해당 고객의 통화메모가 화면에 표시됩니다.</span>
                                <div className="check_wrap">
                                    <input className="checkbox" type="checkbox" checked={check2} name="callMemoCheck" onChange={(e)=>callMemoSet(e)} />
                                    <div className="check_bg">
                                        <div className="check_bg_theme"></div>
                                    </div>
                                    <div className="check_dot"></div>
                                </div>
                            </div>
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div className="sec_wrap">
                        <div className="set_wrap usetap" onClick={(e)=> SettingTabClick(e, 'policyaccess')}>
                            <span className="set_title">약관 자세히 보기</span>
                            <span className="i_more"></span>
                        </div>
                        <div className="set_wrap usetap">
                            <span className="set_title">알림 설정</span>
                            <div className="check_wrap">
                                <input className="checkbox" type="checkbox" name="alramCheck" checked={check}  onChange={(e)=>pushAlramCheck(e)}/>
                                <div className="check_bg">
                                    <div className="check_bg_theme"></div>
                                </div>
                                <div className="check_dot"></div>
                            </div>
                        </div>
                        <div className="set_wrap usetap" onClick={(e)=> SettingTabClick(e, 'cs')}>
                            <span className="set_title">알림 및 공지보기/계정삭제</span>
                            <span className="i_more"></span>
                        </div>
                    </div>  
                    <div className="sec_wrap">
                        <div className="set_wrap app_vision usetap">
                            <span className="set_title">앱 버전</span>
                            <span className="vision_txt">버전 V.{deviceInfo.dataset.version}</span>
                            <span className={"vision_round" + (deviceInfo.dataset.version < appversion ? " update" : " latest")} onClick={()=>UpdateClick(deviceInfo.dataset.version, appversion)}>
                                {deviceInfo.dataset.version < appversion ? "업데이트" : "최신버전"}
                            </span>
                        </div>

                        <div className="set_wrap" onClick={logout}>
                            <span className="set_title">로그아웃</span>
                        </div>
                    </div>
				</div>
			</div>
            <div className="modal fadeInUp" id="popup_accountselect">
                <div className="dim" onClick={()=> layerPopup.hide('#popup_accountselect')}></div>
                <div className="modal_inner modal_up">
                    <span className="modal_title">어느 가게를 관리하시겠어요?</span>
                    <ul className="account_sel_list modal_style">

                        { accountListData.length !== null &&
                            accountListData.map((item, index) => (
                                item.familyYn === 'N' ?
                                    <li>
                                        <input id={`accounts${index + 1}`} type="radio" name="sel_accounts" value={item.ownerUserId} {...{familyyn: item.familyYn, ownernm: item.ownerNm}} />
                                        <label htmlFor={`accounts${index + 1}`} className="usetap">
                                            <span className="account_img owner"></span>
                                            <span className="i_name">{item.ownerNm}</span>
                                            <span className="i_sort">소유 계정</span>
                                            <span className="i_sort">{userInfo.dataset.ownerId === item.ownerUserId && '현재 선택된 계정입니다.'}</span>
                                        </label>
                                    </li>
                                : item.familyYn === 'Y' ?
                                    <li>
                                        <input id={`accounts${index + 1}`} type="radio" name="sel_accounts" value={item.ownerUserId} {...{familyyn: item.familyYn, ownernm: item.ownerNm}} />
                                        <label htmlFor={`accounts${index + 1}`} className="usetap">
                                            <span className="account_img family"></span>
                                            <span className="i_name">{item.ownerNm}</span>
                                            <span className="i_sort">패밀리 계정</span>
                                            <span className="i_sort">{userInfo.dataset.ownerId === item.ownerUserId && '현재 선택된 계정입니다.'}</span>
                                        </label>
                                    </li>
                                : <></>
                            ))
                        }
                    </ul>
                    <div className="btn_box bottom_box">
                        <button type="button" className="btn_lg btn_point usetap" onClick={()=> selectAccount()}>다음</button>
                    </div>
                </div>
            </div>
        </>
    )
});

export default MySetting;