import React, {useEffect, useState} from 'react'
import { observer } from "mobx-react";
import store from '../../../store';
import $ from 'jquery'
import CctvApi from '../../../api/CctvApi';
import * as LocalStore from '../../../store/LocalStore'
import * as NativeBridge from '../../../utils/NativeBridge'
import 'moment/locale/ko';
import moment from 'moment';

const WorkerManage = observer(() => {

    const { userData } = store;

	const RandomNum = ['1', '2', '3']
    var dayArray = ['월', '화', '수', '목', '금', '토', '일']
    let cctvKey = LocalStore.getCctvToken();
	let currStoreInfo = userData.dataset.currstoreInfo
    var currDay = dayArray.indexOf(moment().format('ddd').toLowerCase()) + 1
	var WorkerData = []
	var calcWorkerData = []
	var imgArray = []

	const [imgIdx, setImgIdx] = useState([])
    const [workerList, setWorkerList] = useState([])
	const [workerCnt, setWorkerCnt] = useState(0)
    const [retCode, setRetCode] = useState(0)

    useEffect(() => {
        WorkerFilter();
    }, [userData.dataset.currstoreInfo])

    const WorkerFilter = async() => {
        await WorkerList();

        if(WorkerData.length > 0) {
			WorkerData.map((item) => (item.schedule_info.schedule_weekly?.indexOf(`${currDay}`) !== -1 && item.schedule_info.schedule_type === 0) && calcWorkerData.push(item))
			setWorkerList(calcWorkerData)
			setWorkerCnt(calcWorkerData.length)

            for(var j=0; j<calcWorkerData.length; j++) imgArray.push(...RandomNum[Math.floor(Math.random() * 3)])
			// console.log('wd', imgArray)
			setImgIdx(imgArray)
		}
    }

	const WorkerList = async() => {
		if((cctvKey !== null || cctvKey !== "") && (currStoreInfo.cam_device_sn_id !== undefined && currStoreInfo.cam_device_sn_id !== null 
          && currStoreInfo.cam_device_sn_id.length > 0)) {
			try {
				if(userData.dataset.currstoreInfo.cam_device_sn_id.length === 1) {
					const WorkerListData = await CctvApi.getWorkerList(
						userData.dataset.currstoreInfo?.cam_device_sn_id[0][0],
						{
							'fromDateTime' : `${moment().format('YYYYMMDD000000')}`,
							'toDateTime' : `${moment().format('YYYYMMDDHHmmss')}`
						})
						setRetCode(WorkerListData.data.resCode)
					if ( Array.isArray(WorkerListData.data.list) && WorkerListData.data.list.length > 0 ) {
						WorkerData.push(...WorkerListData.data.list)
					}
				} else {
					let serverWorkerList = []
					for(let i = 0; i < userData.dataset.currstoreInfo?.cam_device_sn_id.length; i++) {
						const WorkerListData = await CctvApi.getWorkerList(
							userData.dataset.currstoreInfo?.cam_device_sn_id[i][0],
							{
								'fromDateTime' : `${moment().format('YYYYMMDD000000')}`,
								'toDateTime' : `${moment().format('YYYYMMDDHHmmss')}`
							})
							setRetCode(WorkerListData.data.resCode)
						if ( Array.isArray(WorkerListData.data.list) && WorkerListData.data.list.length > 0 ) {
							serverWorkerList.push(...WorkerListData.data.list)
						}
					}
					WorkerData.push(...serverWorkerList);
				}
			} catch {
				setRetCode(null)
			}
		}
    }

    const ErrorRefresh = () => {
        WorkerFilter();
    }

    const funcTooltip = (e,msg) => {
        e.stopPropagation()
		e.currentTarget.parentNode.querySelector(msg).classList.toggle('on')
	}

    const MoreClick = (target) => {
        if(target.firstChild.innerText === "더보기") {
            target.firstChild.innerText = "닫기";
        } else {
            target.firstChild.innerText = "더보기";
        }
        document.getElementById('commute_more').classList.toggle('show');
        $('.btn_list_more.usetap').toggleClass('on');
    }

    const goPageOnClick = (urls=null) => {
        NativeBridge.externalCall(`${urls}`)
    }

    return (
        <>
        { ((cctvKey !== null || cctvKey !== "") && (typeof(userData.dataset.currstoreInfo.cam_device_sn_id) !== 'undefined' && 
            userData.dataset.currstoreInfo.cam_device_sn_id !== null && userData.dataset.currstoreInfo.cam_device_sn_id.length > 0) && 
            (retCode !== null && retCode === 0)) ?
                <div className="fix_container">
                    <div className="fix_container staff_manage">
                        { (workerList !== undefined && workerList !== null && workerList.length !== 0) ?
                            <div className="sec_wrap sec_staff">
                                <div className="title_wrap">
                                    <span className="title">직원 근무 현황</span>
                                    <span className="total_num">{workerCnt}명</span>
                                </div>
                                <ul className="commute_list">
                                    {(workerList !== undefined && workerList !== null && workerList.length > 0) &&
                                        workerList.map((item, index) => (
                                            ( index < 3 && 
                                                <li className="staff_list">
                                                    <span className={'staff_thumb' + (` hero${imgIdx[index]}`)}></span>
                                                    <span className="staff_name">{item.device_user_name.length > 10 ? 
                                                                                    `${item.device_user_name.substr(0, 10) + '...'}` : 
                                                                                    `${item.device_user_name}`}
                                                    </span>
                                                    <div className="work_plan">
                                                        <span className="plan_title">근무계획</span>
                                                        <span className="commute_time">{
                                                            currDay === 1 ? 
                                                                item.schedule_info.schedule_mon_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 2 ?
                                                                item.schedule_info.schedule_tue_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 3 ?
                                                                item.schedule_info.schedule_wed_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 4 ?
                                                                item.schedule_info.schedule_thu_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 5 ?
                                                                item.schedule_info.schedule_fri_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 6 ?
                                                                item.schedule_info.schedule_sat_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 7 ?
                                                                item.schedule_info.schedule_sun_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            <></>
                                                        }&nbsp;
                                                        -
                                                        &nbsp;
                                                        {   currDay === 1 ?
                                                                item.schedule_info.schedule_mon_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 2 ?
                                                                item.schedule_info.schedule_tue_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 3 ?
                                                                item.schedule_info.schedule_wed_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 4 ?
                                                                item.schedule_info.schedule_thu_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 5 ?
                                                                item.schedule_info.schedule_fri_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 6 ?
                                                                item.schedule_info.schedule_sat_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 7 ?
                                                                item.schedule_info.schedule_sun_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            <></>
                                                        }
                                                        </span>
                                                    </div>
                                                    { item.workStatus === null ?
                                                        <span className={`staff_status blind status6`}></span>
                                                    :
                                                        <span className={`staff_status blind status${item.workStatus}`}></span>
                                                    }
                                                    { item.workStatus === 5 &&
                                                        <>
                                                            <button type="button" className="ico_ques usetap blind" onClick={(e)=> funcTooltip(e,'.tooltip_box')}>도움말</button>
                                                            <div className="tooltip_box">
                                                                <span className="top_arrow"></span>
                                                                <p className="tip_content">
                                                                    출근 확인이 제대로 되지 않았습니다.<br/>한 번 더 태그해주세요.
                                                                </p>
                                                            </div>
                                                        </>
                                                    }
                                                </li>
                                            )
                                        ))
                                    }
                                    {/* 
                                        status1 : 정상 근무
                                        status2 : 지각
                                        status3 : 조퇴
                                        status4 : 출근
                                        status5 : 출퇴근 등록 미흡
                                        status6 : 결근
                                        status7 : 퇴근 
                                    */}
                                </ul>
                                <ul id="commute_more" className="commute_list">
                                    {(workerList !== null && workerList !== undefined) &&
                                            workerList.map((item, index) => (
                                                ( index > 2 &&
                                                <li className="staff_list">
                                                    <span className={'staff_thumb' + (` hero${RandomNum[Math.floor(Math.random() * RandomNum.length)]}`)}></span>
                                                    <span className="staff_name">{item.device_user_name.length > 10 ? 
                                                                                    `${item.device_user_name.substr(0, 10) + '...'}` : 
                                                                                    `${item.device_user_name}`}
                                                    </span>
                                                    <div className="work_plan">
                                                        <span className="plan_title">근무계획</span>
                                                        <span className="commute_time">{
                                                            currDay === 1 ?
                                                                item.schedule_info.schedule_mon_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 2 ?
                                                                item.schedule_info.schedule_tue_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 3 ?
                                                                item.schedule_info.schedule_wed_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 4 ?
                                                                item.schedule_info.schedule_thu_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 5 ?
                                                                item.schedule_info.schedule_fri_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 6 ?
                                                                item.schedule_info.schedule_sat_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 7 ?
                                                                item.schedule_info.schedule_sun_start?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            <></>
                                                        }&nbsp;
                                                        -
                                                        &nbsp;
                                                        {   
                                                            currDay === 1 ?
                                                                item.schedule_info.schedule_mon_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 2 ?
                                                                item.schedule_info.schedule_tue_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 3 ?
                                                                item.schedule_info.schedule_wed_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 4 ?
                                                                item.schedule_info.schedule_thu_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 5 ?
                                                                item.schedule_info.schedule_fri_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 6 ?
                                                                item.schedule_info.schedule_sat_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            currDay === 7 ?
                                                                item.schedule_info.schedule_sun_end?.replace(/(\d{2})(\d{2})/, '$1:$2') :
                                                            <></>
                                                        }
                                                        </span>
                                                    </div>
                                                    { item.workStatus === null ?
                                                        <span className={`staff_status blind status6`}></span>
                                                    :
                                                        <span className={`staff_status blind status${item.workStatus}`}></span>
                                                    }
                                                { item.workStatus === 5 &&
                                                        <>
                                                            <button type="button" className="ico_ques usetap blind" onClick={(e)=> funcTooltip(e,'.tooltip_box')}>도움말</button>
                                                            <div className="tooltip_box">
                                                                <span className="top_arrow"></span>
                                                                <p className="tip_content">
                                                                    출근 확인이 제대로 되지 않았습니다.<br/>한 번 더 태그해주세요.
                                                                </p>
                                                            </div>
                                                        </>
                                                }
                                            </li>
                                            )
                                        ))   
                                    }
                                </ul>
                                { (typeof(workerList) !== 'undefined' && workerList !== null && (workerList.length !== 0 && workerList.length > 3)) &&
                                    <button type="button" className="btn_list_more usetap" onClick={(e)=> MoreClick(e.currentTarget)}><span>더보기</span></button>
                                }
                            </div>
                        :
                            <div className="sec_wrap sec_staff">
                                <div className="title_wrap">
                                    <span className="title">
                                        직원 근무 현황&nbsp;
                                        <div className="tooltip_wrap">
                                            <button type="button" className="ico_ques usetap blind" onClick={(e)=> funcTooltip(e,'.tooltip_box')}>도움말</button>
                                            <div className="tooltip_box">
                                                <span className="top_arrow"></span>
                                                <p className="tip_content">
                                                    등록방법: PC &gt; 영상모니터링 프로그램 실행 &gt;<br />
                                                    출입관리 &gt; 근무스케줄 관리 &gt; 근무규칙 추가<br /><br />
                                                    (영상모니터링 프로그램 다운로드: cloudcctv.uplus.co.kr)
                                                </p>
                                            </div>
                                        </div>
                                    </span>
                                    <div className="data_none">
                                        <div className="none_wrap">
                                            <span className="none_img"></span>
                                            <p>직원관리 기록이 없습니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="sec_wrap sec_staff_manage">
                            <span className="title">직원 채용 관리<span className="logo_alba"><span className="blind">알바천국 로고</span></span></span>
                            <ul className="staff_manage_list">
                                <li className="manage_coupon">
                                    <span className="list_ico"></span>
                                    <div className="txt_wrap">
                                        <span className="txt1">무료 쿠폰 받아서</span>
                                        <span className="txt2">내 공고 가장 위로 올리기</span>
                                    </div>
                                    <button type="button" className="btn_round purple usetap" onClick={()=>goPageOnClick('https://sohoshop.uplus.co.kr/soho/v1/ev/ceo/squareDetail?detailType=10&cetiNum=lgusoho')}>무료쿠폰</button>
                                </li>
                                <li className="manage_announce">
                                    <span className="list_ico"></span>
                                    <div className="txt_wrap">
                                        <span className="txt1">직원 채용이 필요하다면</span>
                                        <span className="txt2">공고 올리기</span>
                                    </div>
                                    <button type="button" className="btn_round purple usetap" onClick={()=>goPageOnClick('https://m.alba.co.kr/serviceguide/GoodsSelect.asp')}>공고작성</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            :
                <div className="fix_container">
                    <div className="fix_container staff_manage">
                        <div className="temporay_error">
                            <p>일시 오류, 새로고침으로<br />정보를 불러주세요!</p>
                            <div className="ex_img">
                                <img src="/res/images/img-mystore-staff-error@3x.png" alt="직원관리 화면" />
                                <button type="button" className="btn_refresh usetap blind" onClick={()=> ErrorRefresh()}>새로고침</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
});

export default WorkerManage