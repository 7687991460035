import React, {useEffect, useState} from 'react';
import { Route, Redirect, Link, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../store';
import 'moment/locale/ko';
import moment from 'moment';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import {webUI, focusOutIpClear, fn_onlyNum, fn_NextFocus, fn_PreviousFouse, toast, layerPopup} from '../../libs/common';
import { closeBack } from '../../common/common';
// import LoginHead from '../head/LoginHead';
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import SohoApi from '../../api/SohoApi';
import CctvApi from '../../api/CctvApi';
import {setCctvToken, setTempCctvToken} from '../../api/CctvApi';
import CentrexApi, {setCenterexUserIdPass, setCentrexSsoKey} from '../../api/CentrexApi';
import { setUserDataStoreList, checkAgreeRegInfo, checkFamilyInviteInfo, clearLocalData } from './DefaultSet'
import BizApi from '../../api/BizApi';
import * as CONSTS from '../../const';
import TagManager from 'react-gtm-module'

// import {setCentrexSsoKey} from '../../api/CentrexApi';

let passInputStep = 1;
let userPassWd1 = "";
let userPassWd2 = "";
let accessType = "reg";
let gIncorrectCnt = 0;
const PinCode = observer(({match, history}) => {

    const { userInfo, userData,deviceInfo } = store;
    const userList = userInfo.getList();
    const [userPass, setUserPass] = useState("");
    const [userPass2, setUserPass2] = useState("");
    const [passStep, setPassStep] = useState(1);
    const [matchPass, setMatchPass] = useState(true);
    const [incorrectCnt, setIncorrectCnt] = useState(0);
    const [isReset, setIsReset] = useState(false);

    console.log(userList);
   
    useEffect(() => {       
        userInfo.setIsTempUser(LocalStore.getIsTempUser());

        if (match.params.type === 'reg') {
            NativeBridge.sendUserActionLog('first', 'PS');
        }
        
       //ios에서는 backkey 제거
       
        const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : PinCode.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'CALL_BACKPRESS':
                    console.log("Go Back!");
                    
                    //ios에서는 backkey 제거
                    if(deviceInfo.dataset.os_type === "android"  || 
                        // deviceInfo.dataset.os_type === "android" && passInputStep === 2 ||
                        (deviceInfo.dataset.os_type === "ios" && passInputStep === 2)){
                        btnGoBack(passInputStep);
                    }
                break;

                default:
                break;
            }

        });
    
        $(function(){
    
            let pinFinish = $('.pin_finish');
            
            pinFinish.hide();
        
            $('.pinCode').on("keyup blur", function() {  
                let rgEx = /^[0-9]*$/g;
                let chkEx = rgEx.exec($(this).val());
                if(!chkEx){
                    $(this).val( $(this).val().replace( /[^0-9]/gi, '' ));
                } 
                if ($(this).val()!="") {
                    $(this).next(pinFinish).show();
                } else {             
                    $(this).next(pinFinish).hide();
                }
                checkComplete();
            });
            
            $('.pin_finish, .pin_box').on("click", function() {
                pinFinish.hide();
                $('.pin_box input').val('');
                $('#pinCode1').trigger("focus");
            });

            $('#pinCode1').trigger("focus");
            
        });        

        return () => {
            $('.pinCode').off("keyup blur");
            passInputStep = 1;
            userPassWd1 = "";
            userPassWd2 = "";
            setUserPass("");
            setUserPass2("");
            setPassStep(1);
            subscription.unsubscribe();
        }

    }, []);


    useEffect(() => {       
        //--- 핀번호 5번이상 틀리면 초기화
        if(incorrectCnt > 4 && userInfo.dataset.isTempUser === true) {
            // LocalStore.removePinCode();
            // let pinFinish = $('.pin_finish');
            // pinFinish.hide();
            execTempLogout();
        }
    }, [incorrectCnt]);

    useEffect(() => {       
        console.log(match.params);
        accessType = match.params;

        if(accessType.type === "resetpin") {
            setIsReset(true);
        }

    }, [match.params]);

    const checkComplete = () => {

        let complete = true;
        let passString = "";
        $('.pinCode').each((idx, item) => {
            passString += $(item).val();
        });
        if(passInputStep === 1) {
            setUserPass(passString);
            userPassWd1 = passString;
        } else {
            setUserPass2(passString);
            userPassWd2 = passString;
            setMatchPass(true);
            if(userPassWd2.length === 6) {
                if(userPassWd1 !== userPassWd2) {
                    setMatchPass(false);
                    $('#pinCode6').trigger("focus");
                    return;
                }
            }
        }
    }

    const NumKeyEvent = (e) => {
        
        const obj = e.target;
        const objNum = parseInt(obj.id.replace(/^pinCode/i,''), 10);

        fn_onlyNum(e, obj, obj.value);

        console.log(obj.value);

        if(objNum !== 6) {
            const nextId = "pinCode" + ( objNum + 1 );
            fn_NextFocus(e, obj.id, nextId, 1);
        }

        if(objNum !== 1) {
            const prevId = "pinCode" + ( objNum - 1 );
            fn_PreviousFouse(e, obj.id, prevId);
        }

        if(objNum === 6) {
            $('.btn_box .inner button').trigger("focus");
        }
    };

    const callMemoSetApi = async (data) => {
        const memo = await NativeBridge.setCallMemoPopup(data)
        console.log("메모설정확인",memo);
    }


    const pushAlramCheckApi = async (checked) => {
        const pushData = await NativeBridge.setAlarm(checked)
        console.log("네이티브 알람체크",pushData);
    }

    const execTempLogout = async() => {

        clearLocalData();

        // SessionStore.clear();
        // LocalStore.clear();
        // LocalStore.removeStoreInetNo();
        // userInfo.initialize();
        // userData.initialize();
        // pushAlramCheckApi('delete');
        // callMemoSetApi('off')

        // setCentrexSsoKey('','');
        // setCenterexUserIdPass('','');
        // setCctvToken('');
        // setTempCctvToken('');

        history.replace("/");
        layerPopup.showCommon(
            '#popup_onetitlemsgbutton',
            null,
            '로그아웃',
            '임시사용자의 비밀번호를 5회 오입력하여 서비스 이용이 불가능합니다. 처음부터 로그인 해 주세요.',
            function() {
                layerPopup.hide('#popup_onetitlemsgbutton')
            }
        )
    }

    const checkAngGoNext = async (event,checkType) => {

        event.preventDefault();

        // if(incorrectCnt > 4) {
        //     // LocalStore.removePinCode();
        //     // let pinFinish = $('.pin_finish');
        //     // pinFinish.hide();
        //     // execTempLogout();
        // }

        if(userPass.length !== 6) {
            return;
        }

        console.log(userPass);
        console.log(LocalStore.getPinCode());

        if(userPass !== LocalStore.getPinCode()) {
            toast('비밀번호를 잘못 입력했습니다.');
            setIncorrectCnt(incorrectCnt => incorrectCnt + 1);
            return;
        }

        if(checkType === "checkreg") {
            passInputStep = 1;
            userPassWd1 = "";
            userPassWd2 = "";
            setUserPass("");
            setUserPass2("");
            setPassStep(1);
            setIncorrectCnt(0);

            let pinFinish = $('.pin_finish');
            pinFinish.hide();
            history.push("/pincode/reg");
            return;
        } else {

            if(LocalStore.getOwnerId() === null && LocalStore.getUserId() === null) {
                history.replace("/login");
                return;
            }

            // 토큰 발급
            const deviceInfo = LocalStore.getDeviceInfo();
            // const sohoAuthInfo = await 
            const sohoAuthInfo = await SohoApi.getAuthToken({
                //"userId" : LocalStore.getOwnerId() ? LocalStore.getOwnerId() : LocalStore.getUserId()
                "clientIp" : deviceInfo.ip_addr ? deviceInfo.ip_addr : "0.0.0.0"
                ,"devInfo" : "PHONE"				
                ,"osInfo" : deviceInfo.os_type ? deviceInfo.os_type : "unknown_os"
                ,"nwInfo" : deviceInfo.network ? deviceInfo.network : "4G"			
                ,"devModel" : deviceInfo.dev_model ? deviceInfo.dev_model : "unknown_dev"
                ,"carrierType": deviceInfo.carrier_type ? deviceInfo.carrier_type : "unknown_ct"
            }).catch((e) => {
                //timeout 처리
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "인증실패하였습니다.<br />재시도해주세요."
                    , [
                    function() {
                        clearLocalData();
                        layerPopup.hide("#popup_onetitlemsgbutton", ()=>{
                            history.replace("/");
                        });
                    }
                ]);
            });

            if(typeof(sohoAuthInfo)==="undefined") {
                return;
            }
            if(sohoAuthInfo.retCode !== '0000') {
                const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code === sohoAuthInfo.retCode);
                if(typeof(errCodeData) === "undefined") {
                    layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "로그인 중 오류가 발생하였습니다. 재시도해 주세요.", [
                        function() {
                            layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                        }
                    ]);
                }
                return;
            }		

            // const sohoToken = sohoAuthInfo.data.token;
            // // setSohoToken(sohoToken);
            // LocalStore.setSohoToken(sohoToken);
            SessionStore.setLoginSession(LocalStore.getUserId());
            userInfo.setLogin();

            //GA log TEST - 22.04.14
            saveGALog();


            // -- 임시 사용자
            if(LocalStore.getIsTempUser()===true) {
                // 임시사용자는 로컬스토리지의 id/pw 로 cctv 인증
                const cctvId = LocalStore.getCctvTempUserId();
                const cctvPw = LocalStore.getCctvTempUserPwd();
                if(cctvId && cctvPw) {
                    userInfo.setCctvTempUserId(cctvId);
                    const cctvAuthInfo = await CctvApi.getAuth({
                        userId : cctvId,
                        userPwd : cctvPw
                    }).catch((e)=>{
                        console.log(e);
                    });
                    if(typeof(cctvAuthInfo)==="undefined" || cctvAuthInfo.data.resCode !== CONSTS.CCTV_RES_CODE_SUCCESS) {
                        layerPopup.showCommon("#popup_cctv_auth_error", null, "알림", "CCTV 로그인에 실패하였습니다.<br /> U+지능형 CCTV에 등록된 휴대폰번호를 업데이트하세요.", [null]);                        
                    }    
                }

                // 임시사용자는 로컬스토리지의 id/pw로 센트릭스 로그인
                if(LocalStore.getStoreInetNo() && LocalStore.getTempInetPass()) {
                    userData.setStoreInetNo(LocalStore.getStoreInetNo());
                    setCenterexUserIdPass(LocalStore.getStoreInetNo() , LocalStore.getTempInetPass());                    

                    const retCentrixUserInfo = await CentrexApi.getUserInfo({
                        id: LocalStore.getStoreInetNo(),
                        pass: LocalStore.getTempInetPass()
                    }, false).catch(e=>{
                        console.log(e);
                    });
                    if ( typeof(retCentrixUserInfo) === "undefined" || typeof(retCentrixUserInfo.data) === "undefined" || retCentrixUserInfo.data.SVC_RT !== CONSTS.CENTRIX_SVC_RT_SUCCESS ){
                        layerPopup.showCommon("#popup_centrex_auth_error", null, '', '', [null]);
                    } 
                }
                BizApi.makeMyService();

                history.replace("/home");
                return;
            } //--- 임시사용자는 여기까지


            // -- 이하 일반 사용자 

            // 센트릭스 인증키 발급
            /*
            try {
                if(LocalStore.getStoreInetNo()) {
                    const centrexAuthInfo = await SohoApi.getCentrexAuth({"storeInetNo":LocalStore.getStoreInetNo()});
                }
            } catch(e) {
                console.log(e);
            }
            */


            // 약관동의 확인
            if(await checkAgreeRegInfo()===true) {
                history.replace("/agree/reg");
                return;
            }
            // 약관 테스트용 데이터
            // termsAgreeList.push({
            //     'estlYn': "Y",
            //     'prvsAgreeYn': "N",
            //     'prvsId': "210729CMPRIN000017",
            //     'prvsNm': "주소록"
            // });
            // termsAgreeList.push({
            //     'estlYn': "N",
            //     'prvsAgreeYn': "N",
            //     'prvsId': "210729CMPRIN000017",
            //     'prvsNm': "주소록"
            // })

            // 초대계정 확인
            if(await checkFamilyInviteInfo()===true) {
                history.replace("/account/family/reg");
                return;
            }

            if(LocalStore.getOwnerId() === null) {
                history.replace("/account/owner/select");
                return;
            }

            if(LocalStore.getStoreId()===null || LocalStore.getStoreList().length === 0) {
                history.replace("/account/store/reg");
                return;
            }

            await setUserDataStoreList();

            history.replace("/home");


            //     // 임시 사용자는 여기서 홈으로 바로 이동
            //     const isTempUser = LocalStore.getIsTempUser();
            //     if(isTempUser === true) {
            //         history.replace("/home");
            //         return;
            //     }
                
            //     // 동의 필수값 체크
            //     // const result = await 
            //     return SohoApi.getTermsAgreeList({userId: LocalStore.getUserId()});
        
            // }).then((result)=> {

            //     const agreeList = result.data.list;
            //     console.log("agreeList", agreeList);
            //     if(agreeList.length) {
            //         let invalidList = agreeList.reduce((prev, cur) => {
            //             if(cur.estlYn === "Y" && cur.prvsAgreeYn !== "Y") {
            //                 prev.push(cur);
            //             }
            //             return prev;
            //         }, []);
            //         if(invalidList.length > 0 ) {
            //             history.replace("/agree/reg");
            //         } else {
            //             history.push("/");
            //         }
            //     }

        }
    }

    const goNext = async () => {
        console.log(userPass);

        if(userPass.length !== 6) {
            toast('비밀번호 6자리를 입력해주세요.');
            return;
        }

        //--- 핀코드 1단계완료, 2단계로 진행
        if(passInputStep === 1 && userPass.length === 6) {
            // history.push("/home");
            console.log("input again");
            passInputStep = 2;
            setPassStep(2);
            clearInputPasswd();
            
        //--- 핀코드 2단계 완료, 다음 단계로 진행
        } else if(passInputStep === 2 && userPass2.length === 6) {
            
            console.log("pass1 : ", userPass, ", pass2 : " , userPass2);

            if(userPass !== userPass2) {
                setMatchPass(false);
                $('.pin_finish').hide();
                $('.pinCode').val("");
                $('#pinCode1').trigger("focus");
                toast('비밀번호가 일치하지 않습니다.');
                return;
            }

            // 토큰 발급
            const deviceInfo = LocalStore.getDeviceInfo();
            const sohoAuthInfo = await SohoApi.getAuthToken({
                //"userId" : LocalStore.getOwnerId() ? LocalStore.getOwnerId(): LocalStore.getUserId()
                "clientIp" : deviceInfo.ip_addr ? deviceInfo.ip_addr : "0.0.0.0"
                ,"devInfo" : "PHONE"				
                ,"osInfo" : deviceInfo.os_type ? deviceInfo.os_type : "unknown_os"
                ,"nwInfo" : deviceInfo.network ? deviceInfo.network : "4G"			
                ,"devModel" : deviceInfo.dev_model ? deviceInfo.dev_model : "unknown_dev"
                ,"carrierType": deviceInfo.carrier_type ? deviceInfo.carrier_type : "unknown_ct"

            }).catch((e) => {
                //timeout 처리
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "인증실패하였습니다.<br />재시도해주세요."
                    , [
                    function() {
                        clearLocalData();
                        layerPopup.hide("#popup_onetitlemsgbutton", ()=>{
                            history.replace("/");
                        });
                    }
                ]);
            });
            if(typeof(sohoAuthInfo)==="undefined") {
                return;
            }
            
            if(sohoAuthInfo.retCode !== '0000') {
                const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===sohoAuthInfo.retCode);
                if(typeof(errCodeData) === "undefined") {
                    layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "인증실패", [
                        function() {
                            layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                        }
                    ]);
                }
                return;
            }            
            // const sohoToken = sohoAuthInfo.data.token;
            // // setSohoToken(sohoToken);
            // LocalStore.setSohoToken(sohoToken);

            const sessionId = SessionStore.getLoginSession();
            
            console.log("goNextPage");
            LocalStore.setPinCode(userPass);

            saveGALog();
            

            if(sessionId === null) {
                SessionStore.setLoginSession(LocalStore.getUserId());
                userInfo.setLogin(true);
                NativeBridge.sendUserActionLog('first', 'PL');
                

                //임시 사용자는 id/pw 로 cctv 인증
                if(LocalStore.getIsTempUser()===true) {

                    // 로컬스토리지에 id,pw 가 세팅되어 있는지 확인한다.
                    const cctvId = LocalStore.getCctvTempUserId();
                    const cctvPw = LocalStore.getCctvTempUserPwd();
                    if(cctvId && cctvPw) {
                        const cctvAuthInfo = await CctvApi.getAuth({
                            userId : cctvId,
                            userPwd : cctvPw
                        }).catch((e)=>{
                            console.log(e);
                        });

                        // if(cctvAuthInfo.resCode !== 0) {
                        //     layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "CCTV 인증실패", [
                        //         function() {
                        //             layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=>{
                        //                 if(userData.dataset.tempcctvauthFlag === false) {
                        //                     await CctvApi.getAuth({
                        //                         userId : cctvId,
                        //                         userPwd : cctvPw
                        //                     });
                        //                 }
                        //                 userData.setTempCctvAuthFlag(true)
                        //             });
                        //         }
                        //     ]);
                        // }
                    }

                    // history.replace("/bio/reg");
                    // return;

                } else {    

                    /*
                    try {
                        const cctvAuthInfo = await SohoApi.getCctvAuth();
                        // if(cctvAuthInfo.retCode === "0000") {
                        //     const cctvToken = cctvAuthInfo.data.token;
                        //     // setCctvToken(cctvToken);
                        //     // LocalStore.setCctvToken(cctvToken);
                        // }
                        // if(cctvAuthInfo.data.resCode !== "0000") {
                        //     layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "CCTV 인증실패", [
                        //         function() {
                        //             layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=>{
                        //                 if(userData.dataset.cctvauthFlag === false) {
                        //                     await SohoApi.getCctvAuth();
                        //                 }
                        //                 userData.setCctvAuthFlag(true)
                        //             });
                        //         }
                        //     ]);
                        //     return;
                        // }
                    } catch(e) {
                        console.log(e);
                    }
                    */

                }

                // 센트릭스 인증키 발급 (현재 단계에서는 센트릭스 안받는것으로 하여 주석)
                // try {
                //     if(LocalStore.getStoreInetNo()) {
                //         const centrexAuthInfo = await SohoApi.getCentrexAuth({"storeInetNo":LocalStore.getStoreInetNo()});
                //         if(centrexAuthInfo.retCode === "0000") {
                //             setCentrexSsoKey(centrexAuthInfo.data.DATAS.SESSID, centrexAuthInfo.data.DATAS.SSOHASH); 
                //             NativeBridge.setCentrexSsoKeyToNative(centrexAuthInfo.data.DATAS);                   
                //         }
                //     }
                // } catch(e) {
                //     console.log(e);
                // }

                history.replace("/bio/reg");
                
            } else {
                SessionStore.setLoginSession();
                userInfo.setLogout();
                $('.pin_finish').hide();
                $('.pinCode').val("");
                $('#pinCode1').trigger("focus");

                toast('비밀번호가 변경되었습니다.');

                gIncorrectCnt = 0;
                passInputStep = 1;
                userPassWd1 = "";
                userPassWd2 = "";
                setUserPass("");
                setUserPass2("");
                setPassStep(1);
                setIncorrectCnt(0);
                setIsReset(false);

                history.replace("/pincode/check");
            }
            // 다음 바이오 인증 등록 페이지로 이동
        }
    };

    const clearInputPasswd = () => {
        $('.pin_finish').hide();
        $('.pinCode').val("");
        $('#pinCode1').trigger("focus");
        setMatchPass(true);
    }

    const resetPinCode = (event) => {

        event.preventDefault();

        // 임시 사용자 여부 체크
        const isTempUser = LocalStore.getIsTempUser();
        userInfo.setIsTempUser(isTempUser);

        gIncorrectCnt = 0;
        passInputStep = 1;
        userPassWd1 = "";
        userPassWd2 = "";
        setUserPass("");
        setUserPass2("");
        setPassStep(1);
        setIncorrectCnt(0);
        setIsReset(true);

        let pinFinish = $('.pin_finish');
        pinFinish.hide();        

        if(isTempUser === true) {
            history.replace("/pincode/reg");
            $('#pinCode1').trigger("focus");
            return;
        }
        history.replace("/callkmc/resetpin");
    }

    // console.log('pincode', userPass.length)

    const btnGoBack = (step) => {

        if(step === 2) {
            passInputStep = 1;
            userPassWd1 = "";
            userPassWd2 = "";
            setUserPass("");
            setUserPass2("");
            setPassStep(1);
            setIncorrectCnt(0);
            $('.pin_finish').hide();
            $('.pin_box input').val('');
            $('#pinCode1').trigger("focus");
        } else {
            console.log("history.length : ", history.length);
            if(SessionStore.getLoginSession() === null) {
                closeBack();
            } else {
                history.goBack();  
            }
        }
    }

    const saveGALog = () => {

        const tagManagerArgs = {
            dataLayer: {}
        };

        tagManagerArgs.dataLayer.event = "login";
        tagManagerArgs.dataLayer.user_id = LocalStore.getUserId();
        
        let mySvc = { ...userInfo.dataset.myservice }
        tagManagerArgs.dataLayer.myservice = {}
        tagManagerArgs.dataLayer.myservice.call = mySvc.call;
        tagManagerArgs.dataLayer.myservice.cctv = mySvc.cctv;


        console.log('GA TAG' , tagManagerArgs)
        TagManager.dataLayer(tagManagerArgs);
    }

    return (
        <div className="login_wrap">
            <div className="page_title_wrap">
                <div className="inner">
                    {
                        isReset !== true && match.params.type === 'check' ?
                        <>
                        </>
                        : 
                        (isReset !== true && (passStep === 1 && SessionStore.getLoginSession() === null)) && LocalStore.getDeviceInfo()?.os_type === 'ios' ?
                        <>
                        </>
                        :
                        <>
                        <button type="button" className="btn_back usetap blind" onClick={()=>btnGoBack(passStep)}>뒤로가기</button>
                        </>
                    }
                    {/* 페이지 별로 Header 내용 다름 */}
                    { (isReset !== true && (match.params.type === 'reg' && SessionStore.getLoginSession() === null)) &&
                        <h2>로그인</h2>
                    }
                    { (isReset === true || (match.params.type === 'reg' && SessionStore.getLoginSession() !== null)) &&
                        <h2>비밀번호 재설정</h2>
                    }
                    { (match.params.type === 'check' || match.params.type === 'checkreg') &&
                        <h2>로그인</h2>
                    }
                </div>
            </div>
            <div className="pin_wrap">
                <p className="title">
                    { passInputStep === 1 ?
                        <>간편 로그인 비밀번호 6자리를<br /> {match.params.type === 'reg' ? '설정' : '입력'}해 주세요.</>
                    : 
                        <>비밀번호를 다시 한번 입력해주세요.</>
                    }
                </p>
                <ul className="pin_box">
                    <li>
                        <input type="tel"  id="pinCode1" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="첫 번째 비밀번호 입력" />
                        <span className="pin_finish" style={{"display":"none"}}></span>
                    </li>
                    <li>
                        
                        <input type="tel" id="pinCode2" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent}   title="두 번째 비밀번호 입력" />
                        <span className="pin_finish" style={{"display":"none"}}></span>
                    </li>
                    <li>
                        
                        <input type="tel" id="pinCode3" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="세 번째 비밀번호 입력" />
                        <span className="pin_finish" style={{"display":"none"}}></span>
                    </li>
                    <li>
                        
                        <input type="tel" id="pinCode4" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="네 번째 비밀번호 입력" />
                        <span className="pin_finish" style={{"display":"none"}}></span>
                    </li>
                    <li>
                        
                        <input type="tel" id="pinCode5" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="다섯 번째 비밀번호 입력" />
                        <span className="pin_finish" style={{"display":"none"}}></span>
                    </li>
                    <li>
                        
                        <input type="tel" id="pinCode6" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="여섯 번째 비밀번호 입력" />
                        <span className="pin_finish" style={{"display":"none"}}></span>
                    </li>
                </ul>

                    {
                    incorrectCnt > 0 && 
                        <>
                            <span className="err_count">{incorrectCnt}/5</span>
                            <p className="pin_desc">
                                {
                                incorrectCnt < 5 ? 
                                    <>
                                        {incorrectCnt}회 잘못 입력했습니다.<br />
                                        5회 잘못 입력하면 비밀번호를 재설정해야 합니다.<br />
                                        비밀번호를 잊으셨다면<br />
                                        지금 비밀번호를 재설정 하세요.
                                    </>
                                : 
                                    <>
                                        5회 이상 입력 오류로 <br />
                                        비밀번호 재설정이 필요합니다.
                                    </>
                                }
                            </p>
                        </>
                    }
                    { userInfo.dataset.isTempUser === false && 
                        <div className="reset_pw">
                            { (match.params.type === "check"|| match.params.type === "checkreg") && incorrectCnt < 1 && 
                                <a href="#" className="link_theme" onClick={(e)=> resetPinCode(e)}>비밀번호를 잊으셨나요?</a>
                            }
                            
                            { (match.params.type === "check" || match.params.type === "checkreg") && incorrectCnt > 0 && incorrectCnt < 5 && 
                                <a href="#" className="link_theme" onClick={(e)=> resetPinCode(e)}>비밀번호 재설정</a>
                            }
                        </div>
                    }
            </div>
            <div className="btn_box fixed">
                <div className="inner">                    
                    { (match.params.type === "check" || match.params.type === "checkreg") &&  incorrectCnt < 5 && 
                        <button type="button" className="btn_lg btn_point usetap" disabled={(userPass.length === 6 ? false : true) } onClick={(e)=> checkAngGoNext(e,match.params.type)}>다음</button>
                    }

                    { (match.params.type === "check" || match.params.type === "checkreg") &&  incorrectCnt > 4 && 
                        <button type="button" className="btn_lg btn_point usetap" onClick={(e)=> resetPinCode(e)}>비밀번호 재설정</button>
                    }

                    { (match.params.type === "reg" || match.params.type === "resetpin" ) &&
                        <button type="button" className="btn_lg btn_point usetap" disabled={((passStep === 1 && userPass.length === 6) || (passStep === 2 && userPass2.length === 6)) ? false : true } onClick={()=> goNext()}>다음</button>
                    }
                </div>
            </div>
        </div>
    )
});

export default PinCode;