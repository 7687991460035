import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { textChgBlock, layerPopup, toast } from "../../../../../libs/common";
import * as common from "../../../../../common/common";
import BizApi from "../../../../../api/BizApi";

//전화  페이지
const PhoneBookDetail = observer(({ history, match, location }) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  const [addressInfo, setAddressInfo] = useState({});                 //주소록 정보
  const [addressGroupList, setAddressGroupList] = useState({});       //주소록 그룹
  const [blackList, setBlackList] = useState({});                     //수신차단목록 ( 기존 저장된 데이터 불러와야 함 )
  const [existYN, setExistYN] = useState("");                //수신차단 목록에 존재하는경우 on 아닌경우 empty ( 클래스 명으로도 사용)
  
  const [groupName, setGroupName] = useState("");                   //그룹목록
  const [groupId, setGroupId] = useState("");                       //그룹목록(Id)
  const [etcTxt, setEtcTxt] = useState("");                       

  const [check, setCheck] = useState(2);                         //백키 처리

  const destNumber = location.state?.destnumber.replace('-','');
  const addrNo = location.state?.no;
  const contactId = location.state?.contactId;

  useEffect(() => {
    setCheck(2)
    
    const subscription = NativeBridge.eventObservable.subscribe(event => {
            
      console.log("----------------- from Native Event : Main.js ------------------");
      console.log(event);
      
      switch (event.type) {                    
          case 'CALL_BACKPRESS':
              console.log("@@@aa",check);
              setCheck(3);
          break;
      default:
          break;
      }

    });
    
    
    GetBlackList();
    setAddressInfo(common.getAddressInfo(destNumber, addrNo));

    return () => {
      subscription.unsubscribe();
    };

  }, []);
  
  // useEffect(() => {
  //   if(userData.dataset.phoneBookEditFlag === true){
  //     setAddressInfo(common.getAddressInfo(destNumber, addrNo));
  //   }
  //   userData.setPhoneBookEditFlag(false);
  // },[userData.dataset.phoneBookEditFlag, ])

  useEffect(() => {
    
      setAddressInfo(common.getAddressInfo(destNumber, addrNo));
    
    
  },[userData.dataset.pblistForCentrix])

  useEffect(() => {
    if(check === 3) goBackPage();
  },[check])

  useEffect(() => {
    
    //---------------
    //그룹수정을 위해서 분리
    setGroupName(addressInfo.groupName);
    setGroupId(addressInfo.groupId);
    //---------------
    $('#memo').val(addressInfo.etc);

  }, [addressInfo]);

  useEffect(() => {

    if ( blackList != null && blackList.length > 0 ){
      const existObj = blackList.filter((el) => {
          if ( el.NUMBER === destNumber ){
              return el;
          }
        }
      );

      if ( existObj.length > 0 ){
        setExistYN(true);
      }else{
        setExistYN(false);
      }
    }else{
      setExistYN(false);
    }

  }, [blackList]);

  const goBackPage = async (e) => {
    setCheck(2);    //재호출초기화

    const _memo = $('#memo').val();
    const _savedMemo = addressInfo.etc === null || addressInfo.etc === undefined ? "" : addressInfo.etc.replace('\r','');
    console.log('save memo1=>', _memo);
    console.log('save memo2=>', _savedMemo);

    if ( addressInfo !== null && addressInfo.no > 0 && _memo !== _savedMemo && !( _savedMemo === '' &&  _memo === _savedMemo ) ){

      if ( _memo !== '' && _memo.length > 50 ){
        layerPopup.showCommon("#popup_onetitlemsgbutton", null, "글자 수 초과", "50자 이내로 입력해 주세요.", [
          function() {
              layerPopup.hide("#popup_onetitlemsgbutton");
          }
        ]);
        return false;
      }

      if ( !common.checkIMEEnabledType($('#memo').val()) ){
        layerPopup.showCommon("#popup_onetitlemsgbutton", null, "특수 문자 입력 제한", "특수문자는 입력할 수 없습니다.<br/>영문, 숫자, 한글만 입력해 주세요.", [
          function() {
              layerPopup.hide("#popup_onetitlemsgbutton");
          }
        ]);
        return false;
      }

      var reqParam = {
        group_code : addressInfo.groupCode
        ,name : addressInfo.name
        ,phone1 : addressInfo.phone
        ,phone2 : addressInfo.phone2
        ,etc : $('#memo').val()
        ,peer_no : addressInfo.no
      };

      const ret = await CentrexApi.ModifyPhonebookInfo(reqParam);

      if ( ret.data.SVC_RT === '0000'){
        //네이티브 전달
        NativeBridge.modifyAddressInfo({
            group_code : addressInfo.groupCode
            ,group_name : addressInfo.groupName
            ,name : addressInfo.name
            ,phone : addressInfo.phone + ( addressInfo.phone2 !== '' ? ',' + addressInfo.phone2 : '' )
            ,etc : $('#memo').val()
            ,peer_no : addressInfo.no
        });
        BizApi.makeCentrixPhonebookList('__PHONEBOOK_EDIT__');
        userData.setCallMemoFlag(true);
        toast('통화메모가 저장되었습니다.');
      } else {
        //TODO : 통화메모 저장 실패 표기 없음

      }
    }
    
    history.goBack();
    
  }

  const CallLogDetailEditClick = (e) => {
    NativeBridge.sendUserActionLog('detail', 'D024');
    history.replace(
      {
          pathname : `/shop/call/phonebook/detail/edit`
          ,state : {
              destnumber : destNumber
              ,no : addrNo
              ,item : null
          }
      }
    );
  }

  const CallLogDetailDelClick = (e) => {
    NativeBridge.sendUserActionLog('detail', 'D025');
    layerPopup.showCommon(
        '#image_remove_popup', 
        null, 
        '연락처 삭제', 
        '연락처를 삭제 하시겠어요?', 
        [
            function() {
                layerPopup.hide('#image_remove_popup');
            },
            function() {
                console.log('selected Item remove =>');
                processRemoveRecodeFile();
                layerPopup.hide('#image_remove_popup');
            }
        ]
    );
  }

  const processRemoveRecodeFile = async () => {
    const ret = await CentrexApi.RemovePhonebookInfo({
      name : addressInfo.name
      ,phone : addressInfo.phone
      ,peer_no : addressInfo.no
    });

    if ( ret.data.SVC_RT === '0000') {
      //데이터 현행화
      BizApi.makeCentrixPhonebookList();

      //삭제
      NativeBridge.removeAddressInfo({
        type : addressInfo.bookType
        ,no : addressInfo.no
      });

      toast("연락처를 삭제했습니다.");
      goBackPage();
    }else{
      toast("연락처 삭제가 실패되었습니다.(code" + ret.data.SVC_RT + ")" );
    }
  }

  const TextChangeClick = async (e) => {

    let isSuccessYN = false;

    let targetNum = common.defaultString(destNumber, "");
    if ( targetNum.length < 8 || targetNum.length > 14 ){
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "수신차단은 8~14자리 연락처만 가능합니다.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);

      return false;
    }

    if ( !existYN ){

      //해제처리
      const resultDel = await CentrexApi.getAddBlackListInfo({
        blacknumber : destNumber
      });

      if ( resultDel.data.SVC_RT === '0000'){
        NativeBridge.sendUserActionLog('detail', 'D040');
        isSuccessYN = true;
        toast('차단되었습니다.');
      } else {
        // toast('수신 차단 처리가 실패하였습니다.');
        layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "수신차단 설정이 실패하였습니다.", [
          function() {
              layerPopup.hide("#popup_onetitlemsgbutton");
          }
        ]);
      }
    }else{
      //차단처리
      const resultAdd = await CentrexApi.getDelBlackListInfo({
        blacknumber : destNumber
      });

      if ( resultAdd.data.SVC_RT === '0000'){
        isSuccessYN = true;
        toast('해제되었습니다.');
      } else {
        layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "수신 차단 해제처리가 실패하였습니다.", [
          function() {
              layerPopup.hide("#popup_onetitlemsgbutton");
          }
        ]);
      }
    }

    GetBlackList();
    
  }

  const ClickToCall = (e, destNumber) => {
    NativeBridge.callPhone(destNumber);
  }

  const SendToMessage = (e, destNumber) => {
    console.log("SendToMessage=>", destNumber);

    userData.setMessagePopupCellNum({phone:destNumber, no: addressInfo.no});

    userData.setMessagePopupConts("");
    layerPopup.show('#popup_msgwrite');
  }

  const GoPhoneRegi = async (e, destNumber) => {
    history.push(
      {
          pathname : '/shop/call/phonebook/reg'
          ,state : {
              destnumber : destNumber
              ,item : null 
          }
      }
    );
  }

  const ClickShowPop = async (e) => {
    const result = await CentrexApi.getAddressGroupList(); 

		console.log("그룹목록조회 ===>", result);
    if(result.data.SVC_RT === "0000"){
      setAddressGroupList(result.data.DATAS);

      //팝업 모달 노출
      layerPopup.show('#popup_phongbook_group_select');
    }else{
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "선택할 그룹이 없습니다.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);
    }
  }

  const GetBlackList = async (e) => {
    const result = await CentrexApi.getBlackListInfo({});
    if(result.data.SVC_RT === "0000"){
      setBlackList(result.data.DATAS);
      userData.setBlackList(result.data.DATAS);
    }else if(result.data.SVC_RT === "4004"){
      setBlackList([]);
      userData.setBlackList([]);
    }
  }

  const PopSelectGroup = () => {

    setGroupName($('input[type=radio][name=contactGroup1]:checked').attr('groupname'));
    setGroupId($('input[type=radio][name=contactGroup1]:checked').attr('groupid'))
    layerPopup.hide("#popup_phongbook_group_select");
  }


  const ChangeMemo = (e) => {
    //let keyworkds = e.target.value;
    //console.log('e.target=>', e.target.value);
		//console.log('KeyPressSearch =>' + keyworkds);

    // if ( e.target.value.length > 50 ){
    //   layerPopup.showCommon("#popup_onetitlemsgbutton", null, "글자 수 초과", "50자 이내로 입력해 주세요.", [
    //     function() {
    //         layerPopup.hide("#popup_onetitlemsgbutton");
    //     }
    //   ]);
    //   return false;
    // }

  }

  const EvntClear = (e) => {
    console.log("=====================EvntClear==========================");
    $('#memo').val('')
  }

  return (
    <>
        <div className="content store">
            <div className="page_title_wrap3">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                    <h2>연락처 상세</h2>
                    { 
                      addressInfo !== null && addressInfo.bookType === 'centrix' ?
                      <>
                        <button type="button" className="btn_light_edit usetap blind" onClick={(e)=> CallLogDetailEditClick(e)}>편집</button>
                        <button type="button" className="btn_light_del usetap blind" onClick={(e)=> CallLogDetailDelClick(e)}>삭제</button>
                      </>
                      :
                      <></>
                    }
                </div>
            </div>
            <div className="contact_info">
                {
                  //status : nodata
                  addressInfo === null || addressInfo.bookType === 'nodata' ?
                  <>
                    <div className="unregi_box">
                        <span className="i_num">{addressInfo.phone}</span>
                        <button type="button" className="btn_regi" onClick={(e)=> GoPhoneRegi(e, addressInfo.phone)}>연락처 추가</button>
                        <div className="btn_wrap">
                            <button type="button" className="btn_call usetap blind" onClick={(e)=> ClickToCall(e, addressInfo.phone)}>전화 발신</button>
                            <button type="button" className="btn_sms usetap blind" onClick={(e)=> SendToMessage(e, addressInfo.phone)}>메시지 작성 화면 이동</button>
                        </div>
                    </div>
                  </>
                  :
                  //status : centrix
                  <>
                    <span className="i_name">{addressInfo.name}</span>
                      <div className="ft_opt">
                          <span className="i_num">{addressInfo.phone}</span>
                          <div className="btn_wrap">
                              <button type="button" className="btn_call usetap blind" onClick={(e)=> ClickToCall(e, addressInfo.phone)}>전화 발신</button>
                              <button type="button" className="btn_sms usetap blind" onClick={(e)=> SendToMessage(e, addressInfo.phone)}>메시지 작성 화면 이동</button>
                          </div>
                      </div>
                      { 
                        ( addressInfo.phone2 !== '' ? 
                          <>  
                            <div className="ft_opt">
                                <span className="i_num">{addressInfo.phone2}</span>
                                <div className="btn_wrap">
                                    <button type="button" className="btn_call usetap blind" onClick={(e)=> ClickToCall(e, addressInfo.phone2)}>전화 발신</button>
                                    <button type="button" className="btn_sms usetap blind" onClick={(e)=> SendToMessage(e, addressInfo.phone2)}>메시지 작성 화면 이동</button>
                                </div>
                            </div>
                          </>
                          :
                          <></>
                        )
                      }
                  </>
                }
            </div>
            <ul className="contact_detail_list">
                {
                  //status : only centrix
                  addressInfo !== null && addressInfo.bookType === 'centrix' ?
                    <>
                    <li className="detail_group_list">
                        <span className="title">그룹</span>
                        {
                          ( addressInfo.groupName !== undefined && addressInfo.groupName !== '' && addressInfo.groupId !== '0') ? 
                          <>
                            <button type="button" className="btn_added_group" >{addressInfo.groupName}</button>
                          </>
                          :
                          <>
                          <button href="#none" className="btn_round purple usetap" onClick={(e)=> ClickShowPop(e)}>추가</button>
                          </>
                        }
                    </li>
                    </>
                    :
                    <></>
                }
                {
                    //status : centrix
                    addressInfo !== null && addressInfo.bookType === 'centrix' ?
                    <>
                      <li>
                          <span className="title">통화 메모</span>
                          <div className="input_box style2">
                              <input type="text" id="memo" placeholder="메모를 입력해주세요." onKeyUp={(e)=> ChangeMemo(e)}/>
                              <span className="ip_clear hidden usetap" onClick={(e)=> EvntClear(e)}>초기화</span>
                              <span className="ico_srh"></span>
                          </div>
                      </li>
                    </>
                    :
                    <></>
                }
                <li>
                    <span className="title">수신차단</span>
                    <button type="button" className={"btn_round red usetap " + (existYN ? "on": "")} onClick={(e)=>TextChangeClick(e.target)}>{existYN?"해제":"차단"}</button>
                </li>
            </ul>
        </div>

        <div className="modal fadeInUp" id="popup_phongbook_group_select">
            <div className="dim" onClick={()=>layerPopup.hide("#popup_phongbook_group_select")}></div>
            <div className="modal_inner modal_up">
                <button type="button" className="pop_close_txt" onClick={()=>layerPopup.hide("#popup_phongbook_group_select")}>취소</button>
                <span className="modal_title">그룹선택</span>
                <ul className="modal_radio_list2">
                  { 
                    addressGroupList !== null && addressGroupList.length > 0 ?
                      addressGroupList.map((item, idx) => 
                        <>
                          <li key={idx}>
                            {/*
                            <div className="checkbox_green">
                              <input type="checkbox" id={"contactGroup_" + idx} name="contactGroup1" {...{groupid: item.GROUP_CODE, groupname: item.GROUP_NAME}}  onClick={(e)=>ClickSelGroup(e)}/>
                              <label htmlFor={"contactGroup_" + idx} className="usetap">{item.GROUP_NAME}</label>
                            </div>
                            */}
                            <div className="radio_box">
                              <input type="radio" id={"contactGroup_" + idx} name="contactGroup1" {...{groupid: item.GROUP_CODE, groupname: item.GROUP_NAME}}   />
                              <label htmlFor={"contactGroup_" + idx}>{item.GROUP_NAME}</label>
                            </div>
                          </li>
                        </>
                      )
                    : <></>
                  }
                </ul>
                <div className="btn_box bottom_box">
                    <button type="button" className="btn_lg btn_point usetap" onClick={(e)=>PopSelectGroup(e)}>완료</button>
                </div>
            </div>
        </div>
    </>
  );
});
export default PhoneBookDetail;