import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import BizApi from "../../../../../api/BizApi";
import { floatUI } from '../../../../../libs/floating'
import { tabUi } from '../../../../../libs/tab'
import { layerPopup, toast } from "../../../../../libs/common";
import { getGroup, transformAddressDrawData, checkIMEEnabledType, defaultString, sortHanWordsList, sortHanFirstObjectList } from "../../../../../common/common";
import * as CONSTS from "../../../../../const";
import phoneSampleData from "../../phonebook/test.json"

//전화 설정 페이지
const GroupAdd = observer(({ history }) => {

    const CALLID_POPUP = "POPUP";

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);

    const [groupName, setGroupName] = useState(""); 
	const [groupAddrList, setGroupAddrList] = useState([]);
	const [groupAddrDrawList, setGroupAddrDrawList] = useState([]);
	const [delPbList, setPbDelList] = useState([]);
	const [callID, setCallID] = useState("");		//임시방안처리

    //popup 관련
	const [popSearchWords, setPopSearchWords] = useState(""); 
	const [popPbList, setPopPbList] = useState([]);
	const [popSrchDrawData, setPopSrchDrawData] = useState([]); 
	const [popDrawGroupData, setPopDrawGroupData] = useState([]); 
	const [popPbType, setPopPbType] = useState(CONSTS.ADDRESS_TYPE_CENTRIX);
	const [popAddPbList, setPopAddPbList] = useState([]);
	const [groupNamed, setGroupNamed] = useState("");

    let callsID = "";
    
    //temp save용
	const [groupAddrPopTmpList, setGroupAddrPopTmpList] = useState([]);

    useEffect(() => {

		tabUi.init3DepthTab(document.getElementById('tab_style4'));
		tabUi.init3DepthTab(document.getElementById('floating_tab2'));

        if ($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('bg_gray');
        }
      
        //bridge 리턴 ( 휴ㅐ전화)
		/*
		const subscription = NativeBridge.eventObservable.subscribe(event => {
            
			console.log("----------------- from Native Event : GroupDetailEdit.js ------------------");
			console.log(event);
			switch (event.type) {                    
	
				case 'GET_ADDRESS_LIST':
					const result = event.param;
					console.log("GET_ADDRESS_LIST result: " , result);
					const _phonebookList = result.address;

					const _phonebookListAfter = [];
					if ( _phonebookList !== null && _phonebookList.length > 0 ){
						_phonebookList.map((item, idx) => (
							_phonebookListAfter.push(
								{ 
									etc : item.etc
									,groupCode: item.groupCode
									,groupName: item.groupName
									,name: item.name
									,no: item.no
									,phone : (item.phone.indexOf(',') > 0) ? item.phone.split(',')[0] : item.phone
									,phone2 : (item.phone.indexOf(',') > 0) ? item.phone.split(',')[1] : ''
									,drawgroup : getGroup(item.name)
									,bookType : item.no === undefined ? CONSTS.ADDRESS_TYPE_CENTRIX : CONSTS.ADDRESS_TYPE_DEVICE
								}
							)
						));
					}

					setGroupAddrList(_phonebookListAfter);
					
				break;
			default:
				break;
			}
		});

		return () => {
            subscription.unsubscribe();

            if( $('#wrap').hasClass('bg_gray')) {
                $('#wrap').removeClass('bg_gray');
                $('#wrap').addClass('gnb_bottom');
            }
		};
		*/
	}, []);


    const goBackPage = () => {
        history.goBack();
    }

	const ClickDelPb = (e, _item) => {
		console.log(">>>>ClickDelPb", _item);
		setPbDelList(delPbList => Array.from(delPbList).concat(_item));
	}

	useEffect(() => {
		console.log("change delPbList", delPbList);
		//그룹내 연락처 정보 현행화(UI상)
		let tmplist = [];
		let tmpAddlist = [];

		popAddPbList.map((_item, _idx) => {

			var retCnt = delPbList.filter((targetItem) => {
				if ( targetItem.no === _item.no ){
					return targetItem;
				}	
			}).length;

			if ( retCnt === 0 ){
				tmpAddlist.push(_item);
			}
		});
		setPopAddPbList(tmpAddlist);


		groupAddrList.map((_item, _idx) => {

			var retCnt = delPbList.filter((targetItem) => {
				if ( targetItem.no === _item.no ){
					return targetItem;
				}	
			}).length;

			if ( retCnt === 0 ){
				tmplist.push(_item);
			}
		});
		setGroupAddrList(tmplist);

	}, [delPbList]);


    const groupAdd = async (e) => {

		const _groupName = defaultString($("#contact_name").val(), '');
        if ( _groupName === ''){
			//그룹명을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "그룹명을 입력하세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);

			return false;
		}
		if(_groupName.length < 2 || _groupName.length > 19){
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'그룹 등록 실패', 
				'2자 이상 19자 이내로 입력해주세요.'
			);
			return;
		}

		const checkName = checkIMEEnabledType(_groupName);
		if(checkName !== true) {
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'특수 문자 입력 제한', 
				'특수문자는 입력할 수 없습니다.<br />영문,숫자,한글만 입력해 주세요.'
			);
			return;
		}

        let addParam = {
            group_name : _groupName
            ,parent_code : 0
        }

        const result = await CentrexApi.addAddressGroup(addParam);

        layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
        if ( result.data.SVC_RT === '0000' ){

            const resultDatas = result.data.DATAS;
            console.log("결과정보=>", resultDatas);
            //추가된 그룹정보 
            const _matchlst = resultDatas.filter((targetItem) => {
                if ( targetItem.GROUP_NAME === addParam.group_name ){
                    return targetItem;
                }	
            });

            let _groupId = "";
            if ( _matchlst != null && _matchlst.length > 0 ){
                _groupId = _matchlst[0].GROUP_CODE;
            }
            
            //연락처 정보 변경
			let modifyRet = {};

			if ( groupAddrList !== null && groupAddrList.length > 0 ){
				const resultDelAddr = await CentrexApi.mvaddresslist({
					new_groupcode: _groupId	
					,peer_no_list : groupAddrList.map(e => e.no).join(",")
				});
			}

			//TODO : 네이티브 현행화 필요 ( 실패시 어떻게 할것인가 )
			BizApi.makeCentrixPhonebookList();

            toast('그룹을 추가했습니다.');
            history.replace('/shop/call/phonebook/group');

        } else if ( result.data.SVC_RT === '3001' ){
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'최대 등록 개수 초과', 
				'최대 50개까지 등록할 수 있습니다.<br/>새로 등록하시려면 불필요한 그룹을 정리해주세요.'
			);
			return;
        } else if ( result.data.SVC_RT === '9009' ){
			toast('이름이 같은 그룹이 있습니다. 다시 입력해 주세요.');
			return;
		}else{
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'그룹 등록 실패', 
				'그룹 등록이 실패했습니다.<br/>다시 시도해주세요.'
			);
			return;
        }
    }

	const checkAddressList = () => {
		setTimeout( () => {
			//팝업 재 오픈시 변화없는 주소록으로 인한 처리가 필요함.
			//init
			$('input[name="contactGroupAdd"]').prop('checked', false);
			//$('input[name="contactGroupAll"]').prop('checked', false);
			
			//console.log('************change popPbList popAddPbList=>', popAddPbList);
			groupAddrPopTmpList.map((_roofItem, _idx) => {
				console.log("selItem => ", $('input[name="contactGroupAdd"][value="NO_' + _roofItem.no + '"]'));
				$('input[name="contactGroupAdd"][value="NO_' + _roofItem.no + '"]').prop('checked',true);
			});

		}, 500);
	}

	const ClickOpenPopup = (e) => {
		layerPopup.show('#popup_groupcontact_inpage');
		callsID = CALLID_POPUP;

		console.log(">>ClickOpenPopup : ", groupAddrList);
		/*
		if ( groupAddrList !== null && groupAddrList.length > 0 ){
			setPopAddPbList(groupAddrList);
		}else{
			setPopAddPbList([]);
		}

		setPopPbList(userData.dataset.pblistForCentrix);
		*/
		//주소정보 화면 그리기 
		setPopPbList(userData.dataset.pblistForCentrix);

		//이미 추가한 목록 넣기
		if ( popAddPbList !== null && popAddPbList.length > 0 ){
			setGroupAddrPopTmpList(popAddPbList);
		}else{
			//이미 추가한 목록이 없거나, 처음인 경우
			setGroupAddrPopTmpList([]);
		}

	}

    //---------------------------[ POPUP Logic]---------------------------
	const ClickHidePopup = (e) => {
		layerPopup.hide('#popup_groupcontact_inpage');
		//setCallID("");

		$('#popSechNm').val('');
		setPopSearchWords('');		//검색어 초기화
		setPopPbList([]);			//주소록 초기화(팝업)
	}

	const ChangeSearchWords = (e) => {
		setPopSearchWords(e.target.value);
	}

	const filterPbData = (_list, _words) => {
		return popPbList.filter((targetItem) => {
			if ( _words === '' || (targetItem.name)?.includes(_words) || (targetItem.phone)?.includes(_words) ){
				return targetItem;
			}	
		});
	}
    
	useEffect(() => {
		//console.log("change popPbList, popSearchWords", popPbList);
		if ( popPbList !== null && popPbList.length > 0 ){
			setPopSrchDrawData(filterPbData(popPbList, popSearchWords));
		}else{
			setPopSrchDrawData([]);
		}

	}, [popPbList, popSearchWords]);

	useEffect(() => {
		console.log("change popSrchDrawData", popSrchDrawData);
		if ( popSrchDrawData !== null && popSrchDrawData.length > 0 ){
			setPopDrawGroupData(transformAddressDrawData(popSrchDrawData));
		}else{
			setPopDrawGroupData([]);
		}
	}, [popSrchDrawData]);

	useEffect(() => {

		//검색어가 바뀌거나, 주소가 변경되어 다시 설정이 되면 발생하는 이벤트
		if ( popDrawGroupData !== null && popDrawGroupData.length > 0 ){
			//연락처 데이터 화면 표기
			//선택된 정보 체크버튼 표기
			checkAddressList();
		}

	}, [popDrawGroupData]);

	useEffect(() => {
		//그룹 연락처 추가 발생시
		console.log("change popAddPbList", popAddPbList);
		//changeGroupAddrCurList();
		setGroupAddrList(popAddPbList);
		//setPopDrawGroupData(transformAddressDrawData(popSrchDrawData));
	}, [popAddPbList]);

	
	useEffect(() => {
		//그룹 연락처 추가 발생시
		console.log('change groupAddrPopTmpList=>', groupAddrPopTmpList.length);
		if ( groupAddrPopTmpList !== null && groupAddrPopTmpList.length > 0 ){
			$('#btnPopupAdd').attr('disabled', false);
		}else{
			$('#btnPopupAdd').attr('disabled', true);
		}
		
	}, [groupAddrPopTmpList]);


	const ClickItemSelect = (e, _item) => {
		console.log("ClickItemSelect=>", _item);

		const checkboxes = document.querySelectorAll('input[name="contactGroupAdd"]');
		const checked = document.querySelectorAll('input[name="contactGroupAdd"]:checked');
		const selectAll = document.querySelector('input[name=contactGroupAll]');
		
		console.log('totalCnt=>' , Array.from(checkboxes).length);
		console.log('checked=>' , Array.from(checked).length);

		if ( groupAddrPopTmpList.length > 20 ) {
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'알림', 
				'최대 20개까지 선택 가능합니다.'
			);

			$(e.target).prop('checked', false);
			return false;
		}

		if ( $(e.target).prop('checked') == true ){
			//항목추가
			let tmpArr = [];
			tmpArr.push(_item);

			if ( groupAddrPopTmpList !== null && groupAddrPopTmpList.length > 0 ){
				setGroupAddrPopTmpList(groupAddrPopTmpList => Array.from(groupAddrPopTmpList).concat(tmpArr));
			}else{
				setGroupAddrPopTmpList(tmpArr);
			}
			
			$(e.target).prop('checked', true);
		} else {
			//체크 해제시 항목삭제
			setGroupAddrPopTmpList( groupAddrPopTmpList => 
																groupAddrPopTmpList.filter( (targetItem) => {
																	if ( targetItem.no !== _item.no) return targetItem
																})
			);
		}

	}

	const ClickCheckAll = (e) => {
		const checkboxes = document.getElementsByName('contactGroupAdd');
  
		if ( Array.from(checkboxes).length > 20 ) {
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'알림', 
				'최대 20개까지 선택 가능합니다.'
			);

			$(e.target).prop('checked', false);
			return false;
		}

		checkboxes.forEach((checkbox) => {
			checkbox.checked = e.target.checked
		})

		RefreshClickedCount();
	}

	const RefreshClickedCount = () => {
		const query = 'input[name="contactGroupAdd"]:checked';
		const selectedElements = document.querySelectorAll(query);
	
		// 선택된 목록의 갯수 세기
		const selectedElementsCnt = selectedElements.length;
	
		// 출력
		$('#chk_sel_num').text(selectedElementsCnt);
	}

	const ClickPopupAdd = (e) => {
		console.log("ClickPopupAdd=>", groupAddrPopTmpList);
		setPopAddPbList(groupAddrPopTmpList);

		ClickHidePopup();

	}

	useEffect(() => {
		if( groupNamed !== ""){
			document.getElementById('group_label').innerText = ""
		}
		else{
			document.getElementById('group_label').innerText = "그룹명을 입력해주세요."
		}
	},[groupNamed])

	const groupTextOnChange = (e) => {
		let text = e.target.value
		//console.log("ㅁㅁtext",text)
		setGroupNamed(text)
	}

	const textClearOnClick = (e) => {
		setGroupNamed("");
	}

	const ClickReset = (e) => {
		$('#popSechNm').val('');
		setPopSearchWords('');
	}

    return (
    <>
        <div className="content store">
            <div className="page_title_wrap3 group">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={() =>goBackPage()}>뒤로가기</button>
                    <h2>그룹 추가</h2>
                    <button type="button" className="btn_complete" onClick={(e) =>groupAdd(e)}>완료</button>
                </div>
            </div>
            <div className="contact_group_edit">
                <div className="contact_info">
                    <div className="inner">
                        <div className="name_wrap">
                            <div className="input_box style3">
                                <input type="text" id="contact_name" value={groupNamed} onChange={(e) => groupTextOnChange(e)} />
                                <label htmlFor="contact_name" id="group_label" className="label_placeholder">그룹명을 입력해주세요.</label>
                                <span className="ip_gradient"></span>
                                <span className="ip_clear hidden" onClick={()=>textClearOnClick()}>초기화</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_gray">
                
						{/* 0617 추가 추가 된 html 파일에도 주석 처리 되어 있음
                        <div className="sec_save">
							<span className="title">저장 위치 선택</span>
							<button type="button" className="sel_box usetap">인터넷 전화<i className="btn_dark_down"></i></button>
						</div> 
                        */}
						
                    <ul className="contact_detail_list">
                        {/*210621 저장위치 추가 , 210915 저장위치 제거
                        <li className="save_locate">
                            <span className="title">저장위치</span>
                            <span className="locate_name">인터넷 전화 주소록</span>
                        </li>
						  */}
                        <li>
                            <span className="title">연락처</span>
                            <button type="button" className="btn_round purple usetap" onClick={(e)=>ClickOpenPopup(e)}>추가</button>
                        </li>
                    </ul>
                    <ul className="client_list">
                        { 
							groupAddrList !== null && groupAddrList.length > 0 ?
							groupAddrList.map((addressItem, idx) => (
								<>
									<li key={idx}>
										<span className="client_name">{addressItem.name}</span>
										<button type="button" className="btn_small_x usetap blind" onClick={(e)=> ClickDelPb(e, addressItem)}>삭제</button>
									</li>
								</>
							)) :
							<></>
						}
                    </ul>
                </div>
            </div>
        </div>

        <div className="modal fadeInUp" id="popup_groupcontact_inpage"> 
			<div className="dim"></div>
			<div className="modal_inner modal_up max_h">
				<div className="modal_group_add contact">
					<span className="modal_title">그룹추가</span>
					<div className="chk_wrap">
						{/*
						<input type="checkbox" id="contactGroupAll" name="contactGroupAll" onClick={(e) => ClickCheckAll(e)} />
						<label for="contactGroupAll" className="btn_all_sel">모두선택</label>
						*/}
					</div>
					<span className="info_chk_sel"></span>
					<div className="input_box style1">
						<input type="text" id="popSechNm" placeholder="이름, 전화번호를 검색해보세요." onChange={(e)=> ChangeSearchWords(e)}/>
						<span className="ip_clear hidden usetap" onClick={(e)=>ClickReset(e)}>초기화</span>
					<span className="ico_srh"></span>
					</div>
					<div className="contact_cate row">
						{/*
						<div className="btn_cate_wrap2" id="memo_cate">
							<button type="button" className="tab_item active" onClick={(e)=> GetAddressList(e, CALLID_POPUP, CONSTS.ADDRESS_TYPE_CENTRIX)}>인터넷 전화 주소록</button>
							<button type="button" className="tab_item" onClick={(e)=> GetAddressList(e, CALLID_POPUP, CONSTS.ADDRESS_TYPE_DEVICE)}>휴대폰 주소록</button>
						</div>
						*/}
						{/* <button type="button" className="btn_refresh_txt usetap" onClick={(e)=> ClickRefresh(e)}><i className="ico_refresh"></i>새로고침</button> */}
					</div>
					<div className="top_info">
					<span className="total_num"><b><span id="chk_sel_num">{groupAddrPopTmpList.length}</span> 개 선택</b> / 총 {popSrchDrawData.length}개</span>
					</div>
					<div className="contact_list_wrap">
						{ 
							popDrawGroupData !== null && popDrawGroupData.length > 0 ?
							popDrawGroupData.map((groupItem, gidx) => (
								<>
									<span className="devide_bar">{groupItem.title}</span>
									{
										(groupItem.items.length !== null || groupItem.items !== undefined ) ?
										groupItem.items.map((addressItem, index) => (
											<>
												<div id={gidx * 1000 +index} className="checkbox_green">
													<input type="checkbox" id={"contactGroupAdd_" + (gidx * 1000 + index)} name="contactGroupAdd" value={"NO_" + addressItem.no}
															{...{booktype: addressItem.bookType
																,drawgroup: addressItem.drawgroup
																,etc: addressItem.etc
																,groupcode: addressItem.groupCode
																,groupname: addressItem.groupName
																,addressname: addressItem.name
																,no: addressItem.no
																,phone: addressItem.phone
																,phone2: addressItem.phone2
															}}
														onClick={(e)=>ClickItemSelect(e, addressItem)} />

													<label for={"contactGroupAdd_" + (gidx * 1000 + index)} className="usetap">
														<span className="i_name">{addressItem.name}</span>
														<span className="i_num">{addressItem.phone}</span>
													</label>
												</div>
											</>
										)) : <></>
					
									}
								</>
							)) :
							
							<>
							<div className="data_none">
								<div className="none_wrap">
									<span className="none_img"></span>
									<p>검색 결과가 없습니다.</p>
								</div>
							</div> 
							{/*
							<div className="data_none" style={{display: "table", width: "100%"}}>
                                <div className="none_wrap" style={{display:"inline-block",verticalAlign: "middle"}}>
                                    <span className="none_img"></span>
				                    <p>검색된 결과가 없습니다.</p>
				                </div>
				            </div>
							*/}
							</>
						}
					</div>
				</div>
			</div>
			<div className="btn_box fixed">
				<div className="inner">
					<button type="button" className="btn_half btn_line2 usetap" onClick={()=>ClickHidePopup()}>취소</button>
					<button type="button" id="btnPopupAdd" className="btn_half btn_point usetap" onClick={(e)=>ClickPopupAdd(e)}>추가</button>
				</div>
			</div>
		</div>
    </>
  );
});
export default GroupAdd;