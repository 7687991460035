import * as Crypt from '../utils/Crypt';

const getObjectLocalStoreData = (keyName) => {
    const data = window.localStorage.getItem(keyName);
    if(data !== null && data !== '') {
        let retData = null;
        try {
            retData = JSON.parse(data);
        } catch(e) {
            retData = null;
        }
        return retData;
    } else {
        return null;
    }
}
const setObjectLocalStoreData = (keyName, value = '') => {
    if(value !== null && value !== '') {
        window.localStorage.setItem(keyName, JSON.stringify(value));
    } else {
        window.localStorage.setItem(keyName, '');
    }
}

export const setDevicefInfo = (deviceInfo) => {
    setObjectLocalStoreData("deviceInfo", deviceInfo);
}
export const getDeviceInfo = () => {
    const deviceInfo =  getObjectLocalStoreData("deviceInfo");
    return deviceInfo;
    // if(deviceInfo) {
    //     return deviceInfo;
    // } else {
    //     return {
    //         "devModel": "NO_DEVICE",
    //         "push_key" : "ASDFASD34563",
    //         "os_type": "unknown",
    //         "os":"0.0",
    //         "ctn":"01012345678",
    //         "network":"WIFI",
    //         "ip_addr":"211.128.0.1",
    //         "mac_info":"FF:FF:FF:FF:FF:FF",
    //         "tel_info": "LGUPLUS",
    //         "iccid":"AAAC",
    //         "sim_operator":"",
    //         "serial_no":"1234",
    //         "imei":"123456789",
    //         "carrier_type":"L", 
    //         "version":"0.0"                    
    //     };                                                                
    // }
}

export const setUserId = (userid) => {
    if(userid) {
        window.localStorage.setItem("userid", Crypt.aesEncrypt(userid));
    } else {
        window.localStorage.setItem("userid", '');
    }
}
export const getUserId = () => {
    const userId = window.localStorage.getItem("userid");
    if(userId) {
        return Crypt.aesDecrypt(userId);
    } else {
        return null;
    }
}
export const setSohoToken = (sohoToken) => {
    if(sohoToken) {
        window.localStorage.setItem("sohotoken", Crypt.aesEncrypt(sohoToken));
    } else {
        window.localStorage.setItem("sohotoken", '');
    }
}
export const getSohoToken = () => {
    const sohoToken = window.localStorage.getItem("sohotoken");
    if(sohoToken) {
        return Crypt.aesDecrypt(sohoToken);
    } else {
        return null;
    }
}
export const setCctvToken = (cctvToken) => {
    if(cctvToken) {
        window.localStorage.setItem("cctvtoken", Crypt.aesEncrypt(cctvToken));
    } else {
        window.localStorage.setItem("cctvtoken", '');
    }
}
export const getCctvToken = () => {
    const cctvToken = window.localStorage.getItem("cctvtoken");
    if(cctvToken) {
        return Crypt.aesDecrypt(cctvToken);
    } else {
        return null;
    }
}
export const setCctvTempUserId = (userId) => {
    if(userId) {
        window.localStorage.setItem("cctvTempUserId", Crypt.aesEncrypt(userId));
    } else {
        window.localStorage.setItem("cctvTempUserId", '');
    }
}
export const getCctvTempUserId = () => {
    const cctvTempUserId = window.localStorage.getItem("cctvTempUserId");

    if( cctvTempUserId ) {
        return Crypt.aesDecrypt(cctvTempUserId);
    } else {
        return null;
    }
}
export const setCctvTempUserPwd = (userPwd) => {
    if(userPwd) {
        window.localStorage.setItem("cctvTempUserCredentials", Crypt.aesEncrypt(userPwd));
    } else {
        window.localStorage.setItem("cctvTempUserCredentials", '');
    }
}
export const getCctvTempUserPwd = () => {
    const cctvTempUserCredentials = window.localStorage.getItem("cctvTempUserCredentials");

    if( cctvTempUserCredentials ) {
        return Crypt.aesDecrypt(cctvTempUserCredentials);
    } else {
        return null;
    }
}
export const setCctvTempUserData = (userData) => {
    console.log("setCctvTempUserData=>", userData);
    if( userData ) {
        window.localStorage.setItem("cctvTempUserData", JSON.stringify(userData));
    } else {
        window.localStorage.setItem("cctvTempUserData", '');
    }
}
export const getCctvTempUserData = () => {
    const cctvTempUserData = window.localStorage.getItem("cctvTempUserData");

    if( cctvTempUserData ) {
        return JSON.parse(cctvTempUserData);
    } else {
        return null;
    }
}

/* setCentrexKey => 
keyObj = {
    SESSID: '',
    SSOHASH: ''
}
*/
export const setCentrexKey = (keyObj) => {
    setObjectLocalStoreData("centrexkey", keyObj);
}
export const getCentrexKey = () => {
    return getObjectLocalStoreData("centrexkey");
}
export const removeCentrexKey = () => {
    window.localStorage.removeItem("centrexkey");
}

export const setPinCode = (pincode) => {
    if(pincode) {
        window.localStorage.setItem("pincode", Crypt.aesEncrypt(pincode));
    } else {
        window.localStorage.setItem("pincode", '');
    }
}
export const removePinCode = () => {
    if(window.localStorage.removeItem("pincode") !== null) {
        window.localStorage.removeItem("pincode");
    }
}
export const getPinCode = () => {
    const pincode = window.localStorage.getItem("pincode");
    if(pincode) {
        return Crypt.aesDecrypt(pincode);
    } else {
        return null;
    }    
}
export const getTermsAgree = () => {
    const agreeData = window.localStorage.getItem("termsagree");
    if(agreeData) {
        return JSON.parse(agreeData);
    } else {
        return null;
    }
}
export const setTermsAgree = (agreeData) => {
    if(agreeData) {
        window.localStorage.setItem("termsagree", JSON.stringify(agreeData));
    } else {
        window.localStorage.setItem("termsagree", '');
    }
}

export const removeTermsAgree = () => {
    if(window.localStorage.removeItem("termsagree") !== null) {
        window.localStorage.removeItem("termsagree");
    }
}


export const setBioLoginType = (bioType=0) => {
    window.localStorage.setItem("biotype", bioType);
}
export const getBioLoginType = () => {
    return window.localStorage.getItem("biotype");
}
export const removeBioLoginType = () => {
    window.localStorage.removeItem("biotype");
}

export const getAllHomeCard = () => {
    if(window.localStorage.getItem("homecard") !== null) {
        return JSON.parse(window.localStorage.getItem("homecard"));
    } else {
        return null;
    }
}
export const getHomeCard = (storeId) => {
    let homecardlist = getAllHomeCard();
    let ret = []
    if(window.localStorage.getItem("homecard") !== null) {
        for(let i=0; i < homecardlist.length; i++) {
            if(storeId === homecardlist[i].storeId) {
                ret = homecardlist[i].homecardList
                break;
            } 
        }
        return ret
    } else {
        return null;
    }
}
export const setAllHomeCard = (homeCard) => {
    window.localStorage.setItem("homecard", JSON.stringify(homeCard));
}
export const setHomeCard = (storeId, homeCard, bUpdate=false) => {
    let homecardlist = getAllHomeCard();
    // let storeIdList = getStoreList().map((item) => item.storeId)
    
    if(homecardlist === null) {
        window.localStorage.setItem("homecard", JSON.stringify([{ storeId: storeId, homecardList: homeCard }]));
    } else {
        let isOk = false;
        let newList = [];
        for(let i=0; i < homecardlist.length; i++ ) {
            if(homecardlist[i].storeId === storeId) {
                isOk = true;
                if(bUpdate === true) {
                    newList.push({ storeId: homecardlist[i].storeId, homecardList: homeCard });
                } else {
                    newList.push({ storeId: homecardlist[i].storeId, homecardList: homecardlist[i].homecardList });
                }
                // break;
            } else {
                newList.push({ storeId: homecardlist[i].storeId, homecardList: homecardlist[i].homecardList });
            }

        }
        if(isOk === false) {
            homecardlist.push({ storeId: storeId, homecardList: homeCard });
            setAllHomeCard(homecardlist);
        } else {
            setAllHomeCard(newList);
        }
    }
}
export const deleteHomecard = () => {
    window.localStorage.removeItem("homecard")
}

export const deleteStoreId = () => {
    window.localStorage.removeItem("storeid")
}

export const getDeviceSnId = () => {
    if(window.localStorage.getItem("getdevicesn") !== null) {
        return JSON.parse(window.localStorage.getItem("getdevicesn"));
    } else {
        return null;
    }
    
}
export const setDeviceSnId = (getdevicesn) => {
    window.localStorage.setItem("getdevicesn", JSON.stringify(getdevicesn));
}

export const getCurrStoreInfo = () => {
    if(window.localStorage.getItem("currstoreinfo") !== null) {
        return JSON.parse(window.localStorage.getItem("currstoreinfo"));
    } else {
        return null;
    }
}
export const setCurrStoreInfo = (setcurrstoreinfo) => {
    window.localStorage.setItem("currstoreinfo", JSON.stringify(setcurrstoreinfo));
}

export const setOwnerId = (ownerid) => {
    if(ownerid) {
        window.localStorage.setItem("ownerid", Crypt.aesEncrypt(ownerid));
    } else {
        window.localStorage.setItem("ownerid", '');
    }
}
export const getOwnerId = () => {
    const ownerid = window.localStorage.getItem("ownerid");
    if(ownerid) {
        return Crypt.aesDecrypt(ownerid);
    } else {
        return null;
    }
}
export const setOwnerNm = (ownerNm) => {
    if(ownerNm) {
        window.localStorage.setItem("ownernm", ownerNm);
    } else {
        window.localStorage.setItem("ownernm", '');
    }
}
export const getOwnerNm = () => {
    return window.localStorage.getItem("ownernm");
}
export const setFamilyYn = (yn) => {
    if(yn) {
        window.localStorage.setItem("familyyn", yn);
    } else {
        window.localStorage.setItem("familyyn", '');
    }
}
export const getFamilyYn = () => {
    return window.localStorage.getItem("familyyn");
}

export const setStoreId = (storeId) => {
    if(storeId) {
        window.localStorage.setItem("storeid", Crypt.aesEncrypt(storeId));
    } else {
        window.localStorage.setItem("storeid", '');
    }
}
export const getStoreId = () => {
    const storeId = window.localStorage.getItem("storeid");
    if(storeId) {
        return Crypt.aesDecrypt(storeId);
    } else {
        return null;
    }
}




export const getStoreList = () => {
    const list = getObjectLocalStoreData("storelist");
    if(Array.isArray(list)) {
        return list;
    } else {
        return [];
    }
}
export const setStoreList = (storeList) => {
    setObjectLocalStoreData("storelist", storeList);
}

export const setExtenInetNo = (exten) => {
    if(exten) {
        window.localStorage.setItem("exteninetno", exten);
    } else {
        window.localStorage.setItem("exteninetno", '');
    }
}

export const getExtenInetNo = () => {
    return window.localStorage.getItem("exteninetno");
}

export const removeExtenInetNo = () => {
    window.localStorage.removeItem("exteninetno");
}

// type : temp | normal
export const setIsTempUser = (bTemp=true) => {
    window.localStorage.setItem("istempuser", bTemp);
}
export const getIsTempUser = () => {
    return getObjectLocalStoreData("istempuser");
}


export const setStoreInetNo = (inetNo) => {
    if(inetNo) {
        window.localStorage.setItem("storeinetno", inetNo);
    } else {
        window.localStorage.setItem("storeinetno", '');
    }
}
export const getStoreInetNo = () => {
    return window.localStorage.getItem("storeinetno");
}
export const removeStoreInetNo = () => {
    window.localStorage.removeItem("storeinetno");
}

//임시사용자 전화 연동하기 070번호
export const setTempInetNo =(inetNo) => {
    if(inetNo) {
        window.localStorage.setItem("tempinetno" , inetNo);
    } else {
        window.localStorage.setItem("tempinetno", '');
    }
}
export const getTempInetNo = () => {
    return window.localStorage.getItem("tempinetno");
}
//임시사용자 전화 연동하기 pass
export const setTempInetPass = (inetPass) => {
    if(inetPass) {
        window.localStorage.setItem("tempinetpass" , inetPass);
    } else {
        window.localStorage.setItem("tempinetpass", '');
    }
}

export const getTempInetPass = () => {
    return window.localStorage.getItem("tempinetpass");
}

//지능형 cctv authkey

export const setStoreCamIdList = (camIdList) => {
    setObjectLocalStoreData("storecamidlist", camIdList);
}
export const getStoreCamIdList = () => {
    const list = getObjectLocalStoreData("storecamidlist");
    if(Array.isArray(list)) {
        return list;
    } else {
        return [];
    }
}

export const deleteCctvAuthKey = () => {
    window.localStorage.removeItem("cctvtoken")
}


//푸쉬알람
export const setPushAlram = (data) => {
    if(data) {
        window.localStorage.setItem("pushAlram", data);
    } else {
        window.localStorage.setItem("pushAlram", '');
    }
    //setPushAlramData("pushAlram", data);
}
export const getPushAlram = () => {
    return window.localStorage.getItem("pushAlram");
}

// home 공지 팝업
export const setHomePopupDate = (data) => {
    setObjectLocalStoreData("homepopupdate", data);
}
export const getHomePopupDate = () => {
    return getObjectLocalStoreData("homepopupdate");
}

//통화메모설정

export const setCallMemo = (data) => {
    if(data) {
        window.localStorage.setItem("callMemoSet", data);
    } else {
        window.localStorage.setItem("callMemoSet", '');
    }
    //setCallMemoData("callMemoSet", data);
}
export const getCallMemo = () => {
    return window.localStorage.getItem("callMemoSet");
}

/*  deviceInfo 만 남기고 모두 제거 */
export const clear = () => {
    const len = window.localStorage.length;
    for (let i = len-1; i >= 0; i--){
        const currKey = window.localStorage.key(i);
       if(currKey !== 'deviceInfo') {
        window.localStorage.removeItem(currKey);
       }
    }
//    window.localStorage.clear();
}