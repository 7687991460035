import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "../../../../store";
import $, { data } from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { toast } from "../../../../libs/common";
import * as LocalStore from "../../../../store/LocalStore"
import { getAddressInfo,getAddressInfoForBlack } from "../../../../common/common";
//수신 차단 설정 페이지

const ReceiveBlockSet = observer(({history}) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();
  console.log(userList);

  const [getBlackListInfo, setGetBlackListInfo] = useState(null);
  const [blackListModeData, setBlackListModeData] = useState(null);
  const [currentValue, setCurrentValue] = useState("");
  const [checkedInputs, setCheckedInputs] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [addressNumData, setAddressNumData] = useState([]);
  const [addressNameData, setAddressNameData] = useState([]);
  const [blockInputValue, setBlockInputValue] = useState("");
  const [phonebooklist, setPhoneBookList] = useState([]);

  useEffect(() => {
    // const subscription = NativeBridge.eventObservable.subscribe(event => {   
		// 	console.log("----------------- from Native Event : PhoneBook.js -----------------");
		// 	console.log(event);
		// 	switch (event.type) {                    
	
		// 		case 'GET_ADDRESS_LIST':
		// 			const result = event.param;
		// 			console.log(" result: " , result);
		// 			const _phonebookList = result.address;

		// 				setPhoneBookList(_phonebookList);
					
		// 		break;
	
		// 	default:
				
		// 		break;
		// 	}
	
		// });
    let blackListInfoControl = true;
    console.log("ReceiveBlockSet default useEffect..");

    blackListInfo(blackListInfoControl); // 처음 화면 진입할 때 블랙리스트 불러옴
    addressList();//주소록 조회
    //NativeBridge.getAddressList("device", "");
    if($('#wrap').hasClass('gnb_bottom')) {
      $('#wrap').removeClass('gnb_bottom')
      $('#wrap').addClass('fixed_bottom');
    }     
    return () => {
      if($('#wrap').hasClass('fixed_bottom')) {
        $('#wrap').removeClass('fixed_bottom');
        $('#wrap').addClass('gnb_bottom');
      }
    }
  }, []);

  useEffect(() => {
    blackNumAddressNumCompare(getBlackListInfo, addressData);
    //lackNumAddressNumCompare2(getBlackListInfo, addressData)
  }, [getBlackListInfo, addressData])

  const centrexIdCheck = () => {
    const centrexId = LocalStore.getStoreInetNo();
    const tempCentrexPass = LocalStore.getTempInetPass();
    return [centrexId ,tempCentrexPass]
  }
  
  const [resultNum, resultPass] = centrexIdCheck();

//수신 차단 목록 API - 을 조회해서 현재 수신차단 유형 표시
const blackListInfo = async (checked) => {
  const getBlackListData = await CentrexApi.getBlackListInfo({})
  const blackListData = getBlackListData.data
  setGetBlackListInfo(blackListData);

  if(getBlackListData.data.SVC_MSG === "OK" &&  checked === true || getBlackListData.data.DATAS === null){
    if(getBlackListData.data.MODEINFO.MODE === "2"){
      $("input:checkbox[name='check_ctr2']").prop("checked", true)
    }
    if(getBlackListData.data.MODEINFO.MODE === "6"){
      $("input:checkbox[name='check_ctr6']").prop("checked", true)
    }
    if(getBlackListData.data.MODEINFO.MODE === "3"){
      $("input:checkbox[name='check_ctr3']").prop("checked", true)
    }
  }
}

//수신 차단 MODE 설정 , 미설정
const blackListMode = async (mode) => {
  let modeValue = mode;
  const setBlackListMode = await CentrexApi.getBlackListMode({
    mode: modeValue // 현재 모드 
  })
  setBlackListModeData(setBlackListMode);
  
  if(setBlackListMode.data.SVC_MSG === "OK"){
    blackListInfo();
  }
}

//수신 차단 번호 삭제 API
const delBlackList = async (delNumber) => {
  const getDelBlackList = await CentrexApi.getDelBlackListInfo({
   // id:centrexIdCheck(),
   // pass:"67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf",
    blacknumber : delNumber //getblacklistinfo의 NUMBER 값
  })
  
  if(getDelBlackList.data.SVC_RT === "0000"){
    NativeBridge.getLodingEnd();
    blackListInfo();
    
  }
  console.log("수신 차단 번호 삭제 ===>", getDelBlackList);
}

//주소록 조회 API

const addressList = async () => {
  const getAddressList = await CentrexApi.getAddressListInfo({
    //id: centrexIdCheck(), 
    //pass : "67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf",
    num_per_page : "10", // 옵션
    page : "1",
    sort : 1 // Type -> number 
  })
  const address = getAddressList.data;
  setAddressData(address);
  console.log("주소록 가져오기 ===>", address)
}




//수신 차단 번호 삭제 
const removeClickHandler = async (e) => {
  let phoneNumber = e.target.value;
  let blackName = e.target.name;
  const addressInfo = getAddressInfo(phoneNumber);
  const name = addressInfo.phoneType === 'centrix' ? addressInfo.name : '미저장';
  const getDelBlackList = await CentrexApi.getDelBlackListInfo({
     blacknumber : phoneNumber //getblacklistinfo의 NUMBER 값
  })
   
  if(getDelBlackList.data.SVC_RT === "0000"){
      await blackListInfo();
      if(blackName){  
        toast(`${blackName}님을 수신 차단 해제합니다.`);
      }
      else{
        toast("미저장님을 수신 차단 해제합니다.");
      }
      setBlockInputValue("");
      blockNumAndNamefilter();
      // e.target.parentNode.style.display = "none";
  }
  else{
      toast(`${blackName}님을 수신 차단 해제가 실패하였습니다.`);
  }
}

//여러개의 체크박스 중 하나만 관리
const inputChangeHandler = (e, id) => {
  let curModeValue = e?.currentTarget.value;
  setCurrentValue(curModeValue); //현재 모드 값
  // 2,6,3
  setCheckedInputs([id]);
  if(curModeValue === "2" ){
    if($("input:checkbox[name='check_ctr2']").is(":checked") !==  true){
      curModeValue = "0";
      blackListMode(curModeValue);
      toast("수신 차단을 해제합니다.");
      userData.setReceiveBlock(curModeValue)
      $(`input:checkbox[name='check_ctr2']`).prop("checked", false);
      NativeBridge.sendUserActionLog('detail', 'D047', "", "009");
    }
    else{
      userData.setReceiveBlock(curModeValue)
      blackListMode(curModeValue);
      toast("차단 설정한 번호로 걸려온 전화를 차단합니다.");
      NativeBridge.sendUserActionLog('detail', 'D047', "", "001");
    $(`input:checkbox[name='check_ctr2']`).prop("checked", true)
    $(`input:checkbox[name='check_ctr6']`).prop("checked", false)
    $(`input:checkbox[name='check_ctr3']`).prop("checked", false)
    }
  }
  if(curModeValue=== "6"){
    if($("input:checkbox[name='check_ctr6']").is(":checked") !==  true){
      curModeValue = "0";
      blackListMode(curModeValue);
      toast("수신 차단을 해제합니다.");
      userData.setReceiveBlock(curModeValue)
      $(`input:checkbox[name='check_ctr6']`).prop("checked", false)
      NativeBridge.sendUserActionLog('detail', 'D047', "", "009");
    }
    else{
      userData.setReceiveBlock(curModeValue)
      console.log("6번--")
      blackListMode(curModeValue);
      toast("차단 설정한 번호, 저장 안된 번호로 걸려오는 전화를 차단합니다.");
      NativeBridge.sendUserActionLog('detail', 'D047', "", "002");
    $(`input:checkbox[name='check_ctr2']`).prop("checked", false)
    $(`input:checkbox[name='check_ctr6']`).prop("checked", true)
    $(`input:checkbox[name='check_ctr3']`).prop("checked", false)
    }
  }
  if(curModeValue === "3"){
    if($("input:checkbox[name='check_ctr3']").is(":checked") !==  true){
      curModeValue = "0";
      blackListMode(curModeValue);
      toast("수신 차단을 해제합니다.");
      userData.setReceiveBlock(curModeValue)
      $(`input:checkbox[name='check_ctr3']`).prop("checked", false)
      NativeBridge.sendUserActionLog('detail', 'D047', "", "009");
    }
    else{
      userData.setReceiveBlock(curModeValue)
      blackListMode(curModeValue);
      toast("모든 전화를 수신 차단합니다.");
      NativeBridge.sendUserActionLog('detail', 'D047', "", "003");
    $(`input:checkbox[name='check_ctr2']`).prop("checked", false)
    $(`input:checkbox[name='check_ctr6']`).prop("checked", false)
    $(`input:checkbox[name='check_ctr3']`).prop("checked", true)
    }
  }
};

const blackNumAddressNumCompare = (receiveBlock, address) => {

  let addressNum = [];
  let addressName = [];
  let receiveBlockList = [];

  for(let i = 0; i < receiveBlock?.DATAS?.length; i++){
        receiveBlockList.push(getAddressInfoForBlack(receiveBlock.DATAS[i].NUMBER));
  }
  for(let i = 0; i < receiveBlockList.length; i++){
        if(receiveBlockList[i].bookType === "nodata"){
          addressName[i] = "미저장"
          addressNum[i] = receiveBlockList[i].name;
        }
        else{
          addressName[i] = receiveBlockList[i].name;
          addressNum[i] = receiveBlockList[i].phone;
        }
  }

//   for(let i = 0; i < receiveBlock?.DATAS?.length; i++){
    
//     //수시차단번호를 넣는다.
//     receiveBlockNum.push(receiveBlock.DATAS[i].NUMBER) // 수신차단번호 

//     //console.log("address 푸쉬 배열1111111111111", receiveBlockNum)
//      for(let j = 0; j < address?.DATAS.length; j++){
      
       
//        //수신차단번호 i번 돌면서 주소록에 포함되어 있는지 확인
//        //console.log("address 수신차단번호 ==>", receiveBlockNum[i])
       
//           // if(receiveBlockNum[i]?.includes(address.DATAS[j].PHONE1) === true )
//           if(receiveBlockNum[i] === address.DATAS[j].PHONE1)
//           {
            
//             console.log("address 배열 ===>",address.DATAS[j].PHONE1);
              
//               addressNum[i] = address.DATAS[j].PHONE1
//               addressName[i] = address.DATAS[j].NAME
//               //console.log("addressNum 첫번째 번호에 있을 때 번호 넣기",addressNum[i], j, address.DATAS[j].PHONE1)
//               //console.log("addressName 첫번째 번호 매칭된 이름 넣기",addressName[i], j, address.DATAS[j].NAME)
            
//           }
//            //미저장자 출력
//            else{
//             addressNum[i] = receiveBlockNum[i];
//             //console.log("addressNum phon2 true",addressNum)
//               //console.log("addressName phon2 true",addressName)
//           }
//           if(receiveBlockNum[i] === address.DATAS[j].PHONE2)
//           {
            
//             //console.log("address 현재 수신 차단 번호 배열",receiveBlockNum);
//             //console.log("address 배열2 ===>",address.DATAS[j].PHONE2);
//             addressNum[i] = address.DATAS[j].PHONE2
//             addressName[i] = address.DATAS[j].NAME
//             //console.log("addressNum 두번째 번호에 있을  때 번호 넣기",addressNum)
//             //console.log("addressName 두번째 번호에 맻이된 이름 넣기.",addressName)
            
//           }
//            //미저장자 출력
//            else{
//             addressNum[i] = receiveBlockNum[i];
//             //console.log("addressNum phon2 true",addressNum)
//               //console.log("addressName phon2 true",addressName)
//           }
//         }
         

          
//         // if(receiveBlockNum[i]?.includes(address.DATAS[j].PHONE2) === true)
       
//       }
    
// //0번째는 미지정자 else타고 addressNum[0]째에 수신차단 번호들감
// //
    
//     console.log("addressNum phon3 true",addressNum)
//           console.log("addressName3 phon true",addressName)

//     //중복된 이름 찾아서 넣기
//   overLapName = addressName.filter((item, index) => {
//     return addressName.indexOf(item) !== index;
//   });

//  for(let i = 0; i < overLapName.length; i++){
//     console.log("타는지 확인11111111111")
//    indexOfSearch = addressName.indexOf(overLapName[i]);
//    console.log("indexOfSearch =====>", indices);

//    while(indexOfSearch !== -1){
//     indices.push(indexOfSearch);
//     indexOfSearch = addressName.indexOf(overLapName[i], indexOfSearch + 1)
//     console.log("indexOfSearch2 =====>", indices);

//   }
// }

// //addressName 첫번째 원소 시작, 삭제되는 것 0, addressName의 4번째 원소 추가하기
// for(let i = 0; i < indices.length ; i = i + 2){

//     addressName.splice(indices[i]+1, 0 , addressName[indices[i+1]]);
//     addressName.splice(indices[i+1]+1, 1 )
//     addressNum.splice(indices[i]+1, 0 , addressNum[indices[i+1]])
//     addressNum.splice(indices[i+1]+1, 1)

// }



// //let test = getAddressInfo()

//   //console.log("이름splice", addressName.splice(indices[0]+1, 0 , addressName[indices[1]]));
//   // onsole.log("번호splice", addressNum.splice(indices[0]+1, 0 , addressNum[indices[1]]))
//   //console.log("삭제확인 --->", addressName.splice([indices[1]], 1 ))
 
 setAddressNumData(addressNum);
 setAddressNameData(addressName);
 
}

const searchBlock = (e) => {
  let value = e.target.value;
  setBlockInputValue(value);
}

const inputClearHandler = () => {
   document.getElementById("search").value = ""
   blockNumAndNamefilter();
   setBlockInputValue("");
  }

//수신거부 번호 목록 검색 기능
const blockNumAndNamefilter = () => {
  let search = document.getElementById("search").value.toLowerCase();
  let listInner = document.getElementsByClassName("listInner");
  
  for(let i = 0; i < listInner.length; i++) {
    let name = listInner[i].getElementsByClassName("block_name");
    let num = listInner[i].getElementsByClassName("block_num");
    if (name[0].innerHTML.toLowerCase().includes(search) || 
        num[0].innerHTML.toLowerCase().replace(/\-/g,'').includes(search)){
      listInner[i].style.display = "flex"
    } else {
      listInner[i].style.display = "none"
    }
  }
  
}

const goBackPage = () => {
  history.goBack();
}

console.log("테스트", addressNumData)

  return (
    <>
      <div className="content store">
      <div className="page_title_wrap nofixed">
        <div className="inner">
          <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
          <h2>수신 차단</h2>
        </div>
      </div>
        <div className="sec_wrap setting_block">
          <div className="inner">
              <span className="title">수신 차단 설정</span>
              <ul className="chk_setting">
                  <li>
                      <span className="chk_title">차단 설정한 번호만 차단</span>
                      <div className="check_wrap">
                          <input id={1} className="checkbox" name="check_ctr2" value="2" type="checkbox" checked={userData.dataset.receiveBlockData === "2" ? true : false} onChange={(e)=>{inputChangeHandler(e, 1)} } />
                          <div className="check_bg">
                              <div className="check_bg_theme"></div>
                          </div>
                          <div className="check_dot"></div>
                      </div>
                  </li>
                  <li>
                      <span className="chk_title">차단 설정한 번호, 저장 안된 번호 차단</span>
                      <div className="check_wrap">
                          <input id={2} className="checkbox" name="check_ctr6" value="6" type="checkbox" checked={userData.dataset.receiveBlockData === "6" ? true : false} onChange={(e)=>{inputChangeHandler(e, 2)} } />
                          <div className="check_bg">
                              <div className="check_bg_theme"></div>
                          </div>
                          <div className="check_dot"></div>
                      </div>
                  </li>
                  <li>
                      <span className="chk_title">모든 전화 차단</span>
                      <div className="check_wrap">
                          <input id={3} className="checkbox" name="check_ctr3" value="3" type="checkbox" checked={userData.dataset.receiveBlockData === "3" ? true : false}  onChange={(e)=>{inputChangeHandler(e, 3)} } />
                          <div className="check_bg">
                              <div className="check_bg_theme"></div>
                          </div>
                          <div className="check_dot"></div>
                      </div>
                    </li>
              </ul>
          </div>
        </div>
        <div className="sec_wrap sec_block_wrap">
            <div className="inner">
                <span className="title">수신 차단 전화번호</span>
                <div className="input_box style1">
                    <input type="text" id="search" onKeyUp={()=>blockNumAndNamefilter()} value={blockInputValue} onChange={(e)=>searchBlock(e)}  placeholder="이름, 전화번호를 검색해보세요." />
                    <span className="ip_clear hidden usetap" onClick={()=>inputClearHandler()}>초기화</span>
                    <span className="ico_srh"></span>
                </div>
                <ul className="block_list">
                  {
                    getBlackListInfo?.DATAS !== null &&           
                    getBlackListInfo?.DATAS?.map((item, idx) => (
                    
                      <li key={item.NUMBER} className="listInner" >
                          <span className="block_name">{addressNameData[idx] ? addressNameData[idx] : "미저장" }</span>
                          <span className="block_num" value={addressNumData[idx]}>{addressNumData[idx] ? addressNumData[idx]?.toString().replace(/(\d{3})(\d{4})(\d{4})/g,'$1-$2-$3') : item.NUMBER.toString().replace(/(\d{3})(\d{4})(\d{4})/g,'$1-$2-$3') }</span>
                          <button  type="button" name={addressNumData[idx] ? addressNameData[idx] : "미저장" } value={addressNumData[idx]} className="btn_small_x blind usetap" onClick={(e)=>removeClickHandler(e)}>삭제</button>
                      </li>
                    ))
                  }
                </ul>
            </div>
			    </div>
			</div>
    </>
  );
});
export default ReceiveBlockSet;