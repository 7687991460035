import React from 'react'
import { observer } from "mobx-react";
import store from '../../../store';
import * as NativeBridge from "../../../utils/NativeBridge";


const ShopManageUnSub = observer(() => {

    const { deviceInfo } = store;


	const externalCallOnClick = () => {
        console.log("안드로이드", deviceInfo.dataset.os_type)
        if(deviceInfo.dataset.os_type === "android"){
        NativeBridge.externalCall("com.lge.promota","1")
        }
        else{
            NativeBridge.externalCall("com.lgsvl.showit","1")
        }
    }
	
    return (
		<div className="fix_container">
			<div className="unsubs_wrap unsubs_promota">
				<div className="intro_sec">
					<span className="title">머스타드</span>
					<p className="desc1">1분 완성 맛깔나는<br /> 우리가게 메뉴판을 만들어 보세요.</p>
					<p className="desc2">우리가게 매출을 올려주는 내 맘대로 메뉴판</p>
					<span className="intro_ico"></span>
				</div>
				<div className="major_service">
					<span className="title">주요 서비스</span>
					<ul>
						<li className="service1">
							<span className="box_title">업종별 다양한 무료 디자인</span>
							<p>전문 디자이너가 작업한<br />500여개 이상 무료 디자인 제공</p>
							<span className="service_ico"></span>
						</li>
						<li className="service2">
							<span className="box_title">모바일로 간단하게 편집</span>
							<p>APP에서 편집과 송출을<br />한 번에  업데이트 가능</p>
							<span className="service_ico"></span>
						</li>
						<li className="service3">
							<span className="box_title">SNS 공유 기능</span>
							<p>내가 만든 디자인을 온라인 홍보물로 <br />활용하여 가게 홍보 가능</p>
							<span className="service_ico"></span>
						</li>
					</ul>
				</div>
				<div className="user_review">
					<span className="title">먼저 사용 중인 사장님들 리뷰</span>
					<div className="review_box">
						<div className="review_wrap right">
							<div className="msg bg1">
								<span className="i_user">
									<span className="user_img"></span>
									<span className="user_name">호랑이</span>
								</span>
								<div className="star_wrap">
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star"></span>
								</div>
								<p>우리가게 컨셉과 잘 맞는 디자인을 <br />쉽게 찾을 수 있어요!</p>
							</div>
						</div>
						<div className="review_wrap left">
							<div className="msg bg2">
								<span className="i_user">
									<span className="user_img"></span>
									<span className="user_name">김사장</span>
								</span>
								<div className="star_wrap">
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star"></span>
									<span className="ico_star"></span>
								</div>
								<p>문구만 수정했을 뿐인데 <br />전문가가 디자인 해준 것 같아요~</p>
							</div>
						</div>
						<div className="review_wrap right">
							<div className="msg bg3">
								<span className="i_user">
									<span className="user_img"></span>
									<span className="user_name">다람쥐</span>
								</span>
								<div className="star_wrap">
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
									<span className="ico_star on"></span>
								</div>
								<p>시즌별 메뉴판 디자인 비용을 절감하게 도와줬어요.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="info_payment">
					<div className="free_box">
						<span className="free_img blind">FREE</span>
						<span className="title">무료</span>
						<p>지금 바로 무료로 이용해보세요!</p>
					</div>
				</div>
				<div className="btn_box fixed is_gnb">
					<div className="inner">
						<button type="button" onClick={() => externalCallOnClick()} className="btn_lg btn_point usetap">가입하기</button>
					</div>
				</div>
			</div>
		</div>  
    )
});

export default ShopManageUnSub