import React, {useEffect, useState} from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import CctvApi from '../../api/CctvApi';
import { layerPopup, toast} from '../../libs/common';
import { sortObjectList, checkStoreName, closeBack, checkIMEEnabledType } from '../../common/common';
import { setUserDataStoreList, clearLocalData } from './DefaultSet'
import { setCenterexUserIdPass, setCentrexSsoKey } from '../../api/CentrexApi';
import { setCctvToken, setTempCctvToken } from '../../api/CctvApi';


let addLength = 0;
let count = 1;
const StoreReg = observer(({ history, location }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    const [storeListData, setStoreListData] = useState([]);
    const [pageState, setPageState] = useState(0);
    const [nextBtnOk, setNextBtnOk] = useState(false);
    const [showFirstBtn, setShowfirstBtn] = useState(false);

    useEffect(() => {
        NativeBridge.sendUserActionLog('second', 'SS');
        if(!$("#wrap").hasClass("fixed_bottom")) {
            $("#wrap").attr("class", "fixed_bottom");
        }

        // -- 임시사용자인경우 바로 홈으로 이동 (임시사용자 storeDetailList 에서 데이터 줄때까지만 임시처리 20211009)
        if(LocalStore.getIsTempUser() === true) {
            const storeList = [
                {
                    storeId : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_ID,
                    storeNm : process.env.REACT_APP_SOHO_API_TMP_USER_STORE_NM,
                    lpzEntrNo:"",
                    lpzNo:"",
                    lzpEntrNo:"",
                    camId: "",
                    cam_device_sn_id:[],
                    cams:[],
                    storeAddr: "",
                    rntSpc: "",
                    storeAddrDetl: "",                    
                    storeRnt: "",
                    storeInetNo: ""                    
                }
            ];
            userData.setStoreList(storeList);
            userData.setStoreId(process.env.REACT_APP_SOHO_API_TMP_USER_STORE_ID);
            history.replace("/");

            return;
        }

        
        getStoreListData();

        /* 키 입력시 바로 체크는 안하는것으로 수정(20210928, TC_BUG.282)
        $(function(){
            $("#shopName").on("keyup", (event) =>  {
                if (event.target.value !== '' && !checkIMEEnabledType(event.target.value)) {
                    layerPopup.showCommon(
                        '#popup_onetitlemsgbutton', 
                        null, 
                        '특수 문자 입력 제한', 
                        '특수 문자는 입력할 수 없습니다.<br />영문, 숫자, 한글 입력만 가능합니다.'
                    );

                    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
                    event.target.value = event.target.value.replace(regExp, "");;
                    
                    if (event.target.value === '') {
                        $("#shopName").val("가게 0" + (addLength + 1)); //2
                    }
                }
                return true;
            });
        });
        */

        // 예외처리 (5초내 데이터 셋 완성 못하면 버튼 생성)
        setTimeout(()=>{
            setNextBtnOk(true);
        }, 5000);        

        //매핑 페이지에서 현재페이지로(뒤로) 이동했다면 
        if(SessionStore.getStoreMappingJob() === true) {
            setPageState(1);
        }
      
        return () => {
            // $("#btn_add_shop").off('click');
            // // $(document).off("click", "#btn_del_shop");
            if($("#wrap").hasClass("fixed_bottom")) {
                $("#wrap").removeClass("fixed_bottom");
            }
            addLength = 0;
            count = 1;
        }
    }, []);

    const addShopName = () => {
        let shopNameForm = $('#save_shop li');
        shopNameForm++;     
        const currLen = $("#save_shop li").length;
        console.log("currLen : " , currLen);
        if (currLen < 15){

            let shopName = $("#shopName").val().trim();
            if (shopName === '') {
                $("#shopName").val("가게 0" + (addLength + 1)); //2
                return;
            }

            const checkName = checkStoreName(shopName);
            if(checkName !== true) {
                layerPopup.showCommon(
                    '#popup_onetitlemsgbutton', 
                    null, 
                    '특수 문자 입력 제한', 
                    '특수 문자는 입력할 수 없습니다.<br />영문, 숫자, 한글만 입력해주세요.'
                );
                return;
            }

            regStore(shopName).then(async (ret) => {
                if(ret.retCode === "0000") {    
                    
                    // let saveShopHtml = '<li>';
                    // saveShopHtml += '<span className="i_name" id="'+ret.data.storeId+'">'+shopName+'</span>'
                    // saveShopHtml += '<button type="button" id="btn_del_shop" className="btn_del blind usetap">삭제</button>'
                    // saveShopHtml += '</li>'

                    const liObj = document.createElement("li");
                    liObj.id = ret.data.storeId;
                    
                    const spanObj = document.createElement("span");
                    spanObj.className = "i_name";
                    spanObj.id = ret.data.storeId;
                    spanObj.innerText = shopName;

                    const btnDelete = document.createElement("button");
                    btnDelete.type = "button";
                    btnDelete.className = "btn_del blind usetap";
                    btnDelete.id = ret.data.storeId;
                    btnDelete.onclick = () => {
                        deleteStoreConfirm(ret.data.storeId, shopName);
                    }

                    liObj.appendChild(spanObj);
                    liObj.appendChild(btnDelete);

                    $('#save_shop').append(liObj);

                    const currLen = $("#save_shop li").length;
                    // $("#shopName").val("가게 0" + (currLen+1)); //2
                    
                    const storeList = await SohoApi.storeDetailList({ownerUserId : LocalStore.getOwnerId()});
                    await setUserDataStoreList(storeList);
                    await setAddLength();
                    $("#shopName").val("가게 0" + (addLength + 1));
                    // addLength++;
                    count++;
                } else {
                    layerPopup.showCommon(
                        '#popup_onetitlemsgbutton', 
                        null, 
                        '오류', 
                        '오류가 발생했습니다.<br />다시한번 시도해 주세요.'
                    );
                    return;
                }
            });
            // console.log("설정한 스토어 count: " , count);

        } else {
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '최대 등록 개수 초과', 
                '가게는 최대 15개까지 등록 할 수 있습니다.', 
                []
            );
            //$("#shopName").val(""); //2
        }

    }


    useEffect(()=> {
        if(location.pathname === '/account/store/modify') {
            goStoreSetting();
        }
    }, [location.pathname]);

    useEffect(()=> {
        if(pageState === 1) {
            setTimeout(()=>{
                $("#shopName").val("가게 0" + (addLength + 1));
                setNextBtnOk(true);
            }, 300);
        }
    }, [pageState]);

    // useEffect(()=> {
    //     console.log("storeListData:", storeListData);
    // }, [storeListData]);

    const getStoreListData = async () => {

        const storeList = await SohoApi.storeDetailList({ownerUserId : LocalStore.getOwnerId()})
        .catch(e=>{

            console.log("storeDetailList Error...");
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '오류', 
                '서비스 사용 중 오류가 발생하였습니다.<br />잠시 후 재시도해 주세요.',
                [
                    ()=>{
                        clearLocalData();
                        history.replace("/");                        
                    }
                ]
            );

        });
        if(typeof(storeList)==="undefined") {
            return;
        }

        // userData.setStoreList(storeList.data.list);
        if(!Array.isArray(storeList.data.storeList)) {
            storeList.data.storeList = [];
        }
        setStoreListData(storeList.data.storeList);

        //--- 최초 로딩 시 스토어 개수 업데이트
        const currLen = storeList.data.storeList.length;
        count = currLen;
        if (currLen > 0) {
            const storeListData = [];
            storeList.data.storeList.map((store) => {
                if (store.storeNm.indexOf('가게 ') > -1) {
                    const seq = parseInt(store.storeNm.replace('가게 ', ''));
                    
                    if (!isNaN(seq) && seq > addLength) {
                        console.log('seq', seq)
                        addLength = seq;
                    }
                }
                storeListData.push({
                    storeId: store.storeId,
                    storeNm: store.storeNm
                });
            });

            // 매장이름순으로 정렬
            const sortedStoreList = sortObjectList(storeListData,'storeNm', 'ASC');
            
            // LocalStore.setStoreList(sortedStoreList);
            // userData.setStoreList(sortedStoreList);
            // const localStoreList = LocalStore.getStoreList();


            if (location.pathname === '/account/store/reg' && sortedStoreList.length > 0 && SessionStore.getStoreMappingJob() === null ) {
                await setUserDataStoreList(storeList);
                history.replace("/home");
            }

        //-- 매장이 없는경우만 최초 버튼 보인다.
        } else {
            setShowfirstBtn(true);
        }
        
        // $("#shopName").val("가게 0" + (addLength + 1));
        // setNextBtnOk(true);

       

    }

    const goStoreSetting = () => {
        // getStoreListData();
        setPageState(1);
    }

    const setAddLength = () => {
        $('#save_shop li').each((index, item) => {
            const storeNm = $(item).children('span').text();
            if (storeNm.indexOf('가게 ') > -1) {
                const seq = parseInt(storeNm.replace('가게 ', ''));
                if (!isNaN(seq) && seq > addLength) {
                    addLength = seq;
                }
            }
        })
    }

    const regStore = async (storeName) => {
        const param = {
            ownerUserId: LocalStore.getOwnerId(),
            storeNm:storeName
        };
        return SohoApi.storeRegi(param);
    }

    const deleteStoreConfirm = (currStoreId, currStoreNm) => {
        const storeId = LocalStore.getStoreId();

        if (currStoreId === storeId) {
            toast("현재 사용중인 매장은 삭제할 수 없습니다.");
            return;
        }

        layerPopup.showCommon(
            '#image_remove_popup', 
            null, 
            '안내', 
            currStoreNm + '을 삭제하시겠어요?', 
            [
                function() {
                    layerPopup.hide('#image_remove_popup');
                },
                function() {
                    deleteStore(currStoreId);
                }
            ]
        );
    }
    
    const deleteStore = async (currStoreId) => {

        const ret = await SohoApi.storeDelete({storeId: currStoreId});
        if(ret.retCode === "0000") {
            $(`#save_shop li#${currStoreId}`).remove();
            
            // addLength--;
            count--;
            const storeList = await SohoApi.storeDetailList({ownerUserId : LocalStore.getOwnerId()});
            await setUserDataStoreList(storeList);
            await setAddLength()
            $("#shopName").val("가게 0" + (addLength + 1));
            layerPopup.hide('#image_remove_popup');
        } else {
            layerPopup.hide('#image_remove_popup',()=>{
                layerPopup.showCommon(
                    '#popup_onetitlemsgbutton', 
                    null, 
                    '안내', 
                    '오류가 발생했습니다.<br />다시한번 시도해 주세요.', 
                    []
                );
            });

        }
    }

    const goStoreMapping = () => {

        const storeLen = $("#save_shop li").length;
        if(storeLen > 0) {

            const storeList = [];
            $("#save_shop li span").each((index, item) => {
                storeList.push({
                    storeId: item.id,
                    storeNm: item.innerText
                });
            });

            // 매장이름순으로 정렬
            const sortedStoreList = sortObjectList(storeList,'storeNm', 'ASC');
            
            // LocalStore.setStoreList(sortedStoreList);
            // userData.setStoreList(sortedStoreList);
            
            history.push("/account/store/mapping");

        } else {

            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '안내', 
                '가게를 추가 해 주세요.'
            );
        }
    }

    const ip_clear = (event) => {
        $(event.target).siblings('input').val('');
    }
    
    return (
        <>

            {pageState === 0 && location.pathname === '/account/store/reg' &&
            <>
                <div className="content home">
                    <div className="set_shop_intro">
                        <div className="middle_box">
                            <h1>보유하신 가게 정보를 <br />설정해주세요.</h1>
                            <p>
                                보유하신 CCTV와 전화를 각 가게에 설정하여 <br />각기 관리할 수 있습니다!
                            </p>
                            <img src="/res/images/set_shop@3x.png" alt="" />
                        </div>
                    </div>
                    {showFirstBtn === true && 
                        <div className="btn_box fixed">
                            <div className="inner">
                                <button type="button" className="btn_lg btn_point usetap" onClick={goStoreSetting}>다음</button>
                            </div>
                        </div>
                    }
                </div>
            </>
            }

            {pageState === 1 &&
            <>
                <div className="shop_setting">
                    <div className="page_title_wrap2">
                        <div className="inner">
                            {location.pathname === '/account/store/modify' ? 
                                <button type="button" className="btn_back usetap blind" onClick={() => {history.goBack()}}>뒤로가기</button>
                            :
                                <button type="button" className="btn_back usetap blind" onClick={() => {closeBack()}}>뒤로가기</button>
                            }
                            <h2>U+우리가게패키지</h2>
                        </div>
                    </div>
                    <div className="inner">
                        <ul className="info_step">
                            <li className="on">1</li>
                            <li>2</li>
                            <li>3</li>
                        </ul>
                        <h1>가게 설정</h1>
                        <p className="desc">가게 이름을 입력해주세요.</p>
                        <div className="input_wrap">
                            <div className="input_box style2">
                                <input type="text" id="shopName" maxLength="13" />
                                <span className="ip_clear hidden usetap" onClick={ip_clear} >초기화</span>
                            </div>
                            <button type="button" id="btn_add_shop" className="home_add usetap" onClick={addShopName}>확인</button>
                        </div>
                        <ul id="save_shop">
                            {storeListData.map((item)=>(
                                <li key={item.storeId} id={item.storeId}>
                                    <span className="i_name" id={item.storeId}>{item.storeNm}</span>
                                    <button type="button" id="btn_del_shop" className="btn_del blind usetap" onClick={()=>deleteStoreConfirm(item.storeId, item.storeNm)}>삭제</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="btn_box fixed">
                    <div className="inner">
                        {nextBtnOk === true ? 
                            <button type="button" className="btn_lg btn_point usetap" onClick={goStoreMapping}>다음</button>
                        : 
                            <button type="button" className="btn_lg btn_point usetap" disabled>다음</button>
                        }
                    </div>
                </div>
            </>
            }
        </>
    )
});
export default StoreReg;