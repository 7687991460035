import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import { layerPopup, toast } from '../../libs/common';
import { isNullData, closeBack } from '../../common/common';
import { checkFamilyInviteInfo } from './DefaultSet'
import { setUserDataStoreList } from './DefaultSet'

const TermAgreeReg = observer(({history}) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    const [termsAgreeList, setTermsAgreeList] = useState(null);
    const [detailInfo, setDetailInfo] = useState(null);

    useEffect(() => {       
        console.log("TermAgreeReg default useEffect..");

        getTermsAgreeList();

        $("html, body").removeClass("noscroll");


        const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : TermAgreeReg.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'CALL_BACKPRESS':
                    closeBack();

            default:
                break;
            }

        });
       
        // layerPopup.show('#popup_account_select');

        return () => {
            subscription.unsubscribe();
        };
        
    }, []);

    const getTermsAgreeList = async () => {
        const result = await SohoApi.getTermsAgreeList();
        console.log("--------getTermsAgreeList----------");
        console.log('getTermsAgreeList', result);   
        // 약관 테스트용 데이터  
        // result.data.list.push({
        //     'estlYn': "Y",
        //     'prvsAgreeYn': "N",
        //     'prvsId': "210729CMPRIN000017",
        //     'prvsNm': "주소록"
        // });
        // result.data.list.push({
        //     'estlYn': "N",
        //     'prvsAgreeYn': "N",
        //     'prvsId': "210729CMPRIN000017",
        //     'prvsNm': "주소록"
        // })

        SessionStore.setAgreeCheck();

        const termsAgreeList = result.data.list.filter((agree) => {
            if (agree.prvsAgreeYn !== 'Y') {
                return agree;
            }
        })

        if(termsAgreeList.length > 0 ) {
            NativeBridge.sendUserActionLog('second', 'A');
            setTermsAgreeList(termsAgreeList);
            getCheck(termsAgreeList);
        } else {
            LocalStore.removeTermsAgree();

            if(SessionStore.getInviceFamilyCheck() === null) {

                if(await checkFamilyInviteInfo() === true) {
                    history.replace("/account/family/reg");
                    return;
                }

            }                        

            // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
            const ownerId = LocalStore.getOwnerId();
            if(isNullData(ownerId)) {
                history.replace("/account/owner/select");
                return;
            }

            // 스토어 체크 추가
            if(LocalStore.getStoreId()===null || LocalStore.getStoreList().length === 0) {
                history.replace("/account/store/reg");
                return;
            }

            try {
                await setUserDataStoreList();
            } catch(e) {
                console.log(e);
            }

            history.replace("/");
                
        }

        
    }

    const regAgree = async () => {

        let notOk = 0;
        const userAgreeList = {prvsInfoList: []};
        $(".terms_list input:checkbox").each((index, item)=> {
            if($(item).attr("estlyn") === "Y" && !$(item).is(":checked")) {
                notOk++;
            }
            userAgreeList.prvsInfoList.push({
                prvsId: $(item).attr("prvsid"),
                prvsAgreeYn: $(item).is(":checked") ? "Y" : "N",
            });
        })  
        console.log(notOk);

        if(notOk > 0) {
            toast("필수항목을 체크해 주세요");
            return;
        }

        console.log("ok gogo");
        console.log(userAgreeList);

        const ret = await SohoApi.regTermsAgreeInfo(userAgreeList);
        console.log(ret);
        if(ret.retCode === "0000") {
            // LocalStore.setTermsAgree(userAgreeList.prvsInfoList);
            LocalStore.removeTermsAgree();
            
            // -- 패밀리 초대 목록을 체크한다.
            if(SessionStore.getInviceFamilyCheck() === null) {
                const familyInviteList = await SohoApi.getFamilyInviteList();

                console.log(familyInviteList);
                const inviteList = familyInviteList.data.list;

                SessionStore.setInviceFamilyCheck();

                //-- check7. 패밀리 초대 내역이 있으면 해당 페이지로 이동
                if(inviteList.length) {

                    let vaildInviteList = inviteList.reduce((prev, cur) => {                            
                        if(cur.reqStus === "0" || cur.reqStus === "3") {
                            prev.push(cur);
                        }
                        return prev;
                    }, []);
                    if(vaildInviteList.length > 0) {
                        history.replace("/account/family/reg");
                    } else {

                        // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
                        history.replace("/account/owner/select");

                    }
                    return;
                } 
            }

            // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
            const ownerId = LocalStore.getOwnerId();
            if(isNullData(ownerId)) {
                history.replace("/account/owner/select");
                return;
            } 
            
            if(LocalStore.getStoreId()===null || LocalStore.getStoreList().length === 0) {
                history.replace("/account/store/reg");
                return;
            }
            
            try {
                await setUserDataStoreList();
            } catch(e) {
                console.log(e);
            }

            history.replace("/");

        } else {
            console.log("terms agree reg error");
        }

    }

    const showDetail = (detailId) => {
        setCheck();
        history.push(`/agree/detail/${detailId}`);
    }

    const getCheckedCnt = () => {
        // 선택된 목록 가져오기
        const query = 'input[name="termsChk"]:checked';
        const selectedElements = document.querySelectorAll(query);
        
        // 선택된 목록의 갯수 세기
        const selectedElementsCnt = selectedElements.length;

        const checkboxes = document.querySelectorAll('input[name="termsChk"]');
        
        let agreeDisable = true;
        checkboxes.forEach((checkbox) => {
            const estlyn = checkbox.getAttribute('estlyn');
            if (estlyn === 'Y' && !checkbox.checked) {
                agreeDisable = false;
            }
        })

        if (!agreeDisable) {
            $('.btn_point').attr('disabled', true);
        } else {
            $('.btn_point').attr('disabled', false);
        }
        
        // 출력
        //document.getElementById('chk_sel_num').innerText = selectedElementsCnt;
    }

    // 상세페이지 이동전 로컬 스토리지에 약관동의 정보 저장
    const setCheck = () => {
        const userAgreeList = {prvsInfoList: []};
        let checkCnt = 0;
        $(".terms_list input:checkbox").each((index, item)=> {
            userAgreeList.prvsInfoList.push({
                prvsId: $(item).attr("prvsid"),
                prvsAgreeYn: $(item).is(":checked") ? "Y" : "N",
            });
        });
        
        LocalStore.setTermsAgree(userAgreeList.prvsInfoList);
    }

    const getCheck = (termsAgreeList) => {
        if (LocalStore.getTermsAgree() !== null) {
            termsAgreeList = LocalStore.getTermsAgree();
        }
        if (termsAgreeList !== null) {
            const checkboxes = document.getElementsByName('termsChk');
            let agreeDisable = true;
            let checkCnt = 0;
            checkboxes.forEach((checkbox, index) => {
                const checked = termsAgreeList[index].prvsAgreeYn === 'Y' ? true : false;
                checkbox.checked = checked

                const estlyn = checkbox.getAttribute('estlyn');
                if (estlyn === 'Y' && !checked) {
                    agreeDisable = false;
                }
                if (checked) {
                    checkCnt++;
                }
            });

            if ($(".terms_list input:checkbox").length === checkCnt) {
                $('input:checkbox[name="termsChkAll"]').prop("checked", true);
            }

            if (!agreeDisable) {
                $('.btn_point').attr('disabled', true);
            } else {
                $('.btn_point').attr('disabled', false);
            }
        }
        
    }
      
    const checkSelectAll = () => {
    
        const checkboxes = document.querySelectorAll('input[name="termsChk"]');
        const checked = document.querySelectorAll('input[name="termsChk"]:checked');
        const selectAll = document.querySelector('input[name="termsChkAll"]');
        
        if(checkboxes.length === checked.length)  {
            selectAll.checked = true;
        } else {
            selectAll.checked = false;
        }
    }
      
    const selectAll = e =>  {
        const checkboxes = document.getElementsByName('termsChk');
        console.log(e.target);
        checkboxes.forEach((checkbox) => {
            checkbox.checked = e.target.checked
        })
    }

    const notAgree = async () => {
        let notOk = 0;
        $(".terms_list input:checkbox").each((index, item)=> {
            if($(item).attr("estlyn") === "Y") {
                notOk++;
            }
        })  

        if(notOk > 0) {
            toast("서비스 이용을 위해서 필수 약관에 동의해 주세요.");
            return;
        }
        
        LocalStore.removeTermsAgree();
        
        // -- 패밀리 초대 목록을 체크한다.
        const familyInviteList = await SohoApi.getFamilyInviteList();

        console.log(familyInviteList);
        const inviteList = familyInviteList.data.list;

        //-- check7. 패밀리 초대 내역이 있으면 해당 페이지로 이동
        if(inviteList.length) {

            let vaildInviteList = inviteList.reduce((prev, cur) => {                            
                if(cur.reqStus === "0" || cur.reqStus === "3") {
                    prev.push(cur);
                }
                return prev;
            }, []);
            if(vaildInviteList.length > 0) {
                history.replace("/account/family/reg");
            } else {

                // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
                history.replace("/account/owner/select");

            }
        } else {            

            // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
            const ownerId = LocalStore.getOwnerId();
            if(isNullData(ownerId)) {
                history.replace("/account/owner/select");
                return;
            }

            if(LocalStore.getStoreId()===null || LocalStore.getStoreList().length === 0) {
                history.replace("/account/store/reg");
                return;
            }

            try {
                await setUserDataStoreList();
            } catch(e) {
                console.log(e);
            }
            
            history.replace("/");            
            
        }

    }

    return (
        <>  
            {termsAgreeList !== null && termsAgreeList.length > 0 &&
                <div className="login_wrap">
                    <div className="page_title_wrap">
                        <div className="inner">
                            <h2>약관 동의</h2>
                        </div>
                    </div>
                    <div className="terms_require">
                        <span className="terms_img"></span>
                        <h3>이용 약관에 동의해주세요.</h3>
                        <p>앱 이용을 위한 약관 동의가 필요합니다.</p>
                        <ol className="terms_list">
                        { termsAgreeList !== null &&
                            termsAgreeList.map((item, i) => (
                            <li key={item.prvsId}>
                                <span className={`i_status ${item.estlYn === "Y" ? "i_require" : ""}`}>{item.estlYn === "Y" ? "필수" : "선택"}</span>
                                <a className="subject_wrap usetap" onClick={() => {showDetail(item.prvsId)}}>
                                    <span className="i_subject">{item.prvsNm}</span><i></i>
                                </a>
                                <div className="checkbox_green">
                                    <input id={`terms0${i+1}`} type="checkbox" name="termsChk" value="Y" {...{estlyn:item.estlYn, prvsid: item.prvsId}} onClick={() => {checkSelectAll();getCheckedCnt();}}/>
                                    <label htmlFor={`terms0${i+1}`}></label>
                                </div>
                            </li>
                            ))
                        }
                        
                        </ol>
                        <div className="terms_all_chk">
                            <div className="checkbox_green">
                                <input id="termsChkAll" type="checkbox" name="termsChkAll" onClick={(e) => {selectAll(e);getCheckedCnt();}}/>
                                <label htmlFor="termsChkAll">전체 동의</label>
                            </div>
                        </div>
                    </div>
                    <div className="btn_box fixed">
                        <div className="inner">
                            <button type="button" className="btn_half btn_lg btn_line2 usetap" onClick={notAgree}>미동의</button>
                            <button type="button" className="btn_half btn_lg btn_point usetap" onClick={()=> regAgree()}>동의 후 다음</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
    
});
export default TermAgreeReg;