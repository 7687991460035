import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { layerPopup } from '../../../libs/common';

const CommonPopup = observer(() => {
   
    return (
        <>
        {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  홈에서 푸쉬 알람 팝업*/}
        <div className="modal fadeIn" id="popup_pushalram_home">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>받지 않기</span></button>
                        <button type="button" className="btn_half usetap"><span>알림 받기</span></button>
                    </div>
                </div>
            </div>
        </div>
        {/* 타이틀 1줄, 내용 1줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="popup_onetitlemsgbutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            texttexttext
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용 2줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="popup_onetitletwomsgbutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            texttexttext
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개 */}
        <div className="modal fadeIn" id="popup_onetitle_twomsgbutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개 취소/로그아웃 버튼*/}
        <div className="modal fadeIn" id="popup_onetitle_twomsgbutton_logout">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>로그아웃</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 이미지, 내용, 하단버튼 2개 오늘 하루 보지 않기, 닫기 버튼*/}
        <div className="modal fadeIn" id="popup_onetitle_twomsgbutton_logout">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <img src="" />
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>오늘 하루 보지 않기</span></button>
                        <button type="button" className="btn_half usetap"><span>닫기</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 2줄, 내용 2줄, 하단버튼 2개 */}
        <div className="modal fadeIn" id="popup_twotitlemsgbutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">2LINE TITLE, 2LINE TITLE, 2LINE TITLE, 2LINE TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 X, 내용 1줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="popup_onemsgbutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_bd">
                        <p>
                            1line text, 1line text, 1line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 X, 내용 2줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="popup_onebutton_twomsg">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용 3줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="popup_onetitlebutton_threemsg">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용 1줄, 하단버튼 1개, 팝업 닫기 버튼 1개 */}
        <div className="modal fadeIn" id="popup_onetitlemsgbutton_close">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>1line text, 1line text, 1line text</p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 X, 내용 1줄, 하단버튼 1개, 팝업 닫기 버튼 1개 */}
        <div className="modal fadeIn" id="popup_onemsgbutton_close">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_bd">
                        <p>
                            texttexttext
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, Input 1개, 하단버튼 2개, */}
        <div className="modal fadeIn" id="popup_onetitleinput_twobutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <div className="input_box style2">
                            <input type="text" placeholder="사용자 메시지 01" />
                            <span className="ip_clear hidden usetap">초기화</span>
                        </div>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용(포인트 컬러) 1줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="popup_onetitle_onemsgbold_twobutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            <span className="pt_color ft_bold">$매장1$</span> 매장 정보를 삭제 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 문장 여러개, 버튼 2개 */}
        <div className="modal fadeIn" id="popup_onetitlesentence_twobutton">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            요일별/간편 통화연결음으로 <br />
                            설정 되어있습니다.<br /><br />
                            기본 통화연결음 설정 시<br />
                            홈에서 간단하게 설정할 수 있습니다.<br /><br />

                            기본 통화연결음을 설정 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 문장 여러개, 버튼 2개(계정삭제) */}
        <div className="modal fadeIn" id="popup_onetitlesentence_twobutton_leaveaccount">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            요일별/간편 통화연결음으로 <br />
                            설정 되어있습니다.<br /><br />
                            기본 통화연결음 설정 시<br />
                            홈에서 간단하게 설정할 수 있습니다.<br /><br />

                            기본 통화연결음을 설정 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>계정삭제</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 문장 여러개, 버튼 2개(계정삭제) */}
        <div className="modal fadeIn" id="popup_onetitlesentence_twobutton_leaveaccount2">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            요일별/간편 통화연결음으로 <br />
                            설정 되어있습니다.<br /><br />
                            기본 통화연결음 설정 시<br />
                            홈에서 간단하게 설정할 수 있습니다.<br /><br />

                            기본 통화연결음을 설정 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>계정삭제</span></button>
                    </div>
                </div>
            </div>
        </div>



          {/* 타이틀 1줄, 내용 1줄, 하단버튼 1개  임시사용 전화 서비스 로그인 살페 오류팝업*/}
          <div className="modal fadeIn" id="popup_temploginerror">
            <div className="dim" onClick={()=>layerPopup.hide('#popup_temploginerror')}></div>
            <div className="modal_inner">
                <div className="modal_content">
                <button type="button" className="pop_close2 blind" onClick={()=>layerPopup.hide('#popup_temploginerror')}>닫기</button>
                    <div className="modal_hd">아이디 또는 비밀번호 오류</div>
                    <div className="modal_bd">
                        <p>
                        texttexttext
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>비밀번호 재설정</span></button>
                    </div>
                </div>
            </div>
        </div>

         {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  임시사용 지능형 로그인 실패 오류 팝업*/}
         <div className="modal fadeIn" id="popup_tempcctvloginerror">
            <div className="dim" onClick={()=>layerPopup.hide('#popup_tempcctvloginerror')}></div>
            <div className="modal_inner">
                <div className="modal_content">
                <button type="button" className="pop_close2 blind" onClick={()=>layerPopup.hide('#popup_tempcctvloginerror')}>닫기</button>
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>아이디 찾기</span></button>
                        <button type="button" className="btn_half usetap"><span>비밀번호 찾기</span></button>
                    </div>
                </div>
            </div>
        </div>
         {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  푸쉬 알람 팝업*/}
         <div className="modal fadeIn" id="popup_pushalram">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>수신 해제</span></button>
                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 문장 여러개, 버튼 1개(패밀리 cctv) */}
        <div className="modal fadeIn" id="popup_onetitlesentence_onebutton_cctv">
            <div className="dim" onClick={()=>layerPopup.hide('#popup_onetitlesentence_onebutton_cctv')}></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            요일별/간편 통화연결음으로 <br />
                            설정 되어있습니다.<br /><br />
                            기본 통화연결음 설정 시<br />
                            홈에서 간단하게 설정할 수 있습니다.<br /><br />

                            기본 통화연결음을 설정 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>
        {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  통화연결음 가입 팝업*/}
        <div className="modal fadeIn" id="popup_callring_reg">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>닫기</span></button>
                        <button type="button" className="btn_half usetap"><span>전화 걸기</span></button>
                    </div>
                </div>
            </div>
        </div>
        {/* 타이틀 1줄, 내용 1줄, 하단버튼 1개 ::: api 오류 */}
        <div className="modal fadeIn" id="popup_onetitlemsgbutton_api_error">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            texttexttext
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>확인</span></button>
                    </div>
                </div>
            </div>
        </div>
          {/* 타이틀 1줄, 내용 1줄, 하단버튼 1개  통화연결음 만들기*/}
          {/* <div className="modal fadeIn" id="popup_callring_make">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">통화연결음 설정 안내</div>
                    <div className="modal_bd">
                        <p>
                        texttexttext
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn usetap"><span>통화연결음 만들기</span></button>
                    </div>
                </div>
            </div>
        </div> */}
        
         {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  홈에서 푸쉬 알람 팝업*/}
         <div className="modal fadeIn" id="interwork_cashnote_popup">
          <div className="dim"></div>
          <div className="modal_inner">
              <div className="modal_content">
                  <div className="modal_hd">TITLE</div>
                  <div className="modal_bd">
                      <p>
                          2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                      </p>
                  </div>
                  <div className="modal_ft">
                      <button type="button" className="btn_half usetap"><span>닫기</span></button>
                      <button type="button" className="btn_half usetap"><span>홈페이지로 이동</span></button>
                  </div>
              </div>
          </div>
      </div>

        {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  홈에서 푸쉬 알람 팝업*/}
        <div className="modal fadeIn" id="interwork_alba_popup">
          <div className="dim"></div>
          <div className="modal_inner">
              <div className="modal_content">
                  <div className="modal_hd">TITLE</div>
                  <div className="modal_bd">
                      <p>
                          2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                      </p>
                  </div>
                  <div className="modal_ft">
                      <button type="button" className="btn_half usetap"><span>닫기</span></button>
                      <button type="button" className="btn_half usetap"><span>홈페이지로 이동</span></button>
                  </div>
              </div>
          </div>
      </div>

        {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  통화연결음 만들기에서 뒤로가기 탭 했을 시*/}
        <div className="modal fadeIn" id="make_call_before">
            <div className="dim" onClick={()=>layerPopup.hide('#make_call_before')}></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">이전 화면으로 이동</div>
                    <div className="modal_bd">
                        <p>
                        2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>이전화면으로 이동</span></button>
                        </div>
                </div>
            </div>
        </div>

        



        {/* 타이틀 1줄, 문장 여러개, 버튼 2개(회원탈퇴) */}
        <div className="modal fadeIn" id="popup_onetitlesentence_twobutton_familyaccount_reject">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            &nbsp;
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>초대 거부</span></button>
                        <button type="button" className="btn_half usetap"><span>취소</span></button>

                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 문장 여러개, 버튼 2개(앱버전 스토어) */}
        <div className="modal fadeIn" id="version_store_popup">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            &nbsp;
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>나중에 하기</span></button>
                        <button type="button" className="btn_half usetap"><span>지금 업데이트</span></button>

                    </div>
                </div>
            </div>
        </div>

        {/* 타이틀 1줄, 내용 3줄, 하단버튼 1개 */}
        <div className="modal fadeIn" id="home_callring_popup">
            <div className="dim" onClick={()=>layerPopup.hide("#home_callring_popup")}></div>
            <div className="modal_inner">
                <div className="modal_content">
                <button type="button" className="pop_close2 blind" onClick={()=>layerPopup.hide('#home_callring_popup')}>닫기</button>
                    <div className="modal_hd">통화연결음 설정 안내</div>
                    <div className="modal_bd">
                        <p>
                            3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text, 3line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <Link to="/shop/call/callset/callring"><button type="button" className="btn usetap"><span>통화연결음 만들기</span></button></Link>
                    </div>
                </div>
            </div>
        </div>

          {/* 타이틀 1줄, 내용 2줄, 하단버튼 2개  통화연결음 만들기에서 뒤로가기 탭 했을 시*/}
          <div className="modal fadeIn" id="image_remove_popup">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd"></div>
                    <div className="modal_bd">
                        <p>
                        2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>삭제</span></button>
                        </div>
                </div>
            </div>
        </div>
        {/* 위생인증 없을 때 팝업 -- 메세지 설정 */}
        <div className="modal fadeIn" id="popup_clean_cctv">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            2line text, 2line text, 2line text, 2line 2line text, text, 2line text
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>가입하기</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fadeIn" id="popup_phonebook_group_delete">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            <span className="pt_color ft_bold">$매장1$</span> 매장 정보를 삭제 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>삭제</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fadeIn" id="popup_ByDayCallRing">
        <div className="dim" onClick={()=> layerPopup.hide("#popup_ByDayCallRing")}></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">알림</div>
                    <div className="modal_bd">
                        <p>
                            고객님은 현재 기본/요일별/시간별 통화<br />
                            연결음을 
                            이용중입니다.<br/>
                            
                            간편통화연결음을 설정하고,<br />
                            홈화면에서 보다 쉽게 관리해 보세요.<br />

                            <br />간편 통화연결음을 설정 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap" onClick={()=> layerPopup.hide("#popup_ByDayCallRing")}><span>취소</span></button>
                        <Link to={`/shop/call/callset/callring`}> <button type="button" className="btn_half usetap" style={{ borderLeft : "1px solid #ebebeb"}} onClick={()=>layerPopup.hide("#popup_ByDayCallRing")}><span  style={{ color:" #6a40c0", borderLeft : "1px solid #ebebeb"}}>확인</span></button></Link>
                    </div>
                </div>
            </div>
	</div>

        

     {/* 타이틀 1줄, 문장 여러개, 버튼 2개(삭제) */}
     <div className="modal fadeIn" id="popup_onetitle_onemsgbold_twobutton2">
            <div className="dim"></div>
            <div className="modal_inner">
                <div className="modal_content">
                    <div className="modal_hd">TITLE</div>
                    <div className="modal_bd">
                        <p>
                            요일별/간편 통화연결음으로 <br />
                            설정 되어있습니다.<br /><br />
                            기본 통화연결음 설정 시<br />
                            홈에서 간단하게 설정할 수 있습니다.<br /><br />

                            기본 통화연결음을 설정 하시겠습니까?
                        </p>
                    </div>
                    <div className="modal_ft">
                        <button type="button" className="btn_half usetap"><span>취소</span></button>
                        <button type="button" className="btn_half usetap"><span>삭제</span></button>
                    </div>
                </div>
            </div>
        </div>

        </>

         

    )
});

export default CommonPopup;
