import React, { useEffect, useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import { observer } from "mobx-react";
import store from "../../../../../store";
import $, { data } from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import SohoApi from "../../../../../api/SohoApi";
import { action, useStrict } from 'mobx'
import { layerPopup, toast } from "../../../../../libs/common";
import * as LocalStore from "../../../../../store/LocalStore";

const CallRing = observer(({history}) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const [mohInfo, setMohInfo] = useState(null);//현재설정된 통화연결음
    const [mohListInfo, setMohListInfo] = useState(null);//통화연결음 목록 조회
    const [delMohListInfo, setDelMohListInfo] = useState(null);
    const [sameClass1, setSameClass] = useState("");
    const [getRingTonelData, setGetRingTonelData] = useState([]); //소호 기본통화연결음 조회
    const [myTtsInfo, setMyTtsInfo] = useState(null); //본인의 tts 청약권 조회
    const [basicCallBoxCheck, setBasicCallBoxCheck] = useState([]); // 기본통화연결음 배열
    const [currentDefaultClass , setCurrentDefaultClass] = useState("");
    const [myServiceDetailList, setMyServiceDetailList] = useState(null);
    const [preValueArray, setPreValueArray] = useState([]);
    const [removeDelete, setRemoveDelete] = useState("0");


    const [ttsOneTime, setTtsOneTime] = useState(false);        //1회권
    const [ttsRegular, setTtsRegular] = useState(false);        //정액형

    useEffect(() => {
      
        if(userInfo.dataset.isTempUser === false){
            myServiceDetail();
            
           //  getSohoBasicRington();
        }
         //ringToneRegi();
        const subscription = NativeBridge.eventObservable.subscribe(event => {
            switch (event.type) {                    
                case 'CALL_BACKPRESS':
                    if(document.getElementById("popup_callring").style.display === "block"){
                        popupClose();
                        return layerPopup.hide('#popup_prelisten');
                    }

                    if(document.getElementById("popup_selectcall").style.display === "block"){
                      return layerPopup.hide('#popup_selectcall');
                    }
                    else{
                        goBackPage();
                    }
                    break;

                default:
                    break;
            }
        });
        
      
        if(userInfo.dataset.popupCheck === "1"){
            document.getElementById('popup_callring').className = 'modal fadeInUp visible';
            document.getElementById("popup_callring").style.display = "block";
            userInfo.setPopupCheck("0");
        }
      
        mohList();//통화 연결음 목록 조회
      
        if( $('#wrap').hasClass('gnb_bottom') ) {
            $('#wrap').removeClass('gnb_bottom');
            $('#wrap').addClass('fixed_bottom');
        }      
      
        return () => {
            subscription.unsubscribe();
          
            if($('#wrap').hasClass('fixed_bottom')) {
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').addClass('gnb_bottom');
            }
        };
      
    },[]);

    const getSohoBasicRington = async () => {
        const resultRingTone = await SohoApi.getRingToneInfo({
            userId: LocalStore.getUserId(),
            lpzEntrNo : resultNum, //매장 인터넷 가입 번호	
        })
        
        if ( resultRingTone.retCode === '0000'){
            const valueArray = Object?.values(resultRingTone.data);
            setPreValueArray(valueArray);
            console.log("valueArray", valueArray)
        }
    }


    //간편통화연결음은 없는데 통화연결음 목록에 나왔을 시
    const nonDefalutRingRemove = (ring, ringList) => {

        //ring : 통화연결음 목록에서 간편통화연결음 모두 가져온 배열
        //ringList : 소호 통화연결음 조회
        let nonData = [];
        

        //중복값 제거 배열
        let nonData2 =[];
    
        if(ring === undefined || ring === null || ring.length === 0){
            userInfo.setDefalutCheck("1")
            return;
        }
    
        if( ringList.length === 0 || ring === undefined ){
            userInfo.setDefalutCheck("1")
            return;
        } else{
            for(let i =0; i < ringList.length; i++ ){
                for(let j = 0; j < ring.length; j++){
                    if(ringList[i] !== ring[j].CLASS){
                        nonData.push(ring[j].CLASS);
                    }
                }
            }
        }
    
        nonData2 = nonData.filter((item, idx) => (
            nonData.indexOf(item) === idx
        ));
    
        for(let i = 0; i < ringList.length; i++){
            if(ringList[i] !== ""){
                for(let j = 0; j < nonData2.length; j++){
                    if(ringList[i] === nonData2[j]){
                        nonData2.splice(j, 1);
                    }
                }
            }
        }
    
        if(nonData2.length > 0){
            nonData2.map((item) => {
                delMohList2(item)
            });
        }
    };//nonDefalutRingRemove
    //통화연결음 목록에는 없는데 소호 통화연결음에는 있을 시
    //  const nonDefalutRingRemove = () => {
    //    let centrexCall = []; //
    //    let sohoCall = [];

    //     for(let i = 0; i < sohoCall.length; i++){
    //       for(let j = 0; j< centrexCall.length; j++){
    //         if(sohoCall[i] === centrexCall[j]){}
    //       }
    //     }

    //  }

    useEffect(() => {

        if ( userInfo.dataset.callRingPopupCheck.rngTnTyp1 === "" && userInfo.dataset.callRingPopupCheck.rngTnTyp2 === "" &&
            userInfo.dataset.callRingPopupCheck.rngTnTyp3 === "" && userInfo.dataset.callRingPopupCheck.rngTnTyp4 === "" &&
            userInfo.dataset.callRingPopupCheck.rngTnTyp5 === "" && removeDelete === "1"){
                
                $("#ringbackBasic1").prop("checked", true);
        }
        if ( userInfo.dataset.callRingPopupCheck.rngTnTyp1 === undefined && userInfo.dataset.callRingPopupCheck.rngTnTyp2 === undefined &&
        userInfo.dataset.callRingPopupCheck.rngTnTyp3 === undefined && userInfo.dataset.callRingPopupCheck.rngTnTyp4 === undefined &&
        userInfo.dataset.callRingPopupCheck.rngTnTyp5 === undefined && removeDelete === "1"){
            
            $("#ringbackBasic1").prop("checked", true);
    }
        
        setRemoveDelete("0");

    },[removeDelete]);

    useEffect(() => {

        if(userInfo.dataset.callRingPopupCheck.rngTnTyp1 !== "") $("#ringbackBasic1").prop("checked", true);
        if(userInfo.dataset.callRingPopupCheck.rngTnTyp2 !== "") $("#ringbackBasic2").prop("checked", true);
        if(userInfo.dataset.callRingPopupCheck.rngTnTyp4 !== "") $("#ringbackBasic3").prop("checked", true);
        if(userInfo.dataset.callRingPopupCheck.rngTnTyp3 !== "") $("#ringbackBasic4").prop("checked", true);
        if(userInfo.dataset.callRingPopupCheck.rngTnTyp5 !== "") $("#ringbackBasic5").prop("checked", true);  
    
    },[userInfo.dataset.callRingPopupCheck]);

    const centrexIdCheck = () => {
        const centrexId = LocalStore.getStoreInetNo();
        const tempCentrexPass = LocalStore.getTempInetPass();
        return [centrexId ,tempCentrexPass]
    }
      
    const [resultNum, resultPass] = centrexIdCheck();

     //현재설정된 통화연결음이 기본통화연결음에 있을 경우 ----
    const sameClassHandler = (sameClassList, mohInfoList, getMohListData) => {
        let sameClass ="";
        let sameClassNum ="";
        let noData = "0";
    
        //현재 설정된 통화연결음 클래스가 소호 기본통화연결음 조회에 있는지 확인
        for(let i = 0; i < 5; i++){
            if(sameClassList[i]?.includes(mohInfoList?.DATAS.MOHCLASS) && mohInfoList?.DATAS.MOHCLASS !== "" ){
                $("input:radio[id='ringback1']").prop("checked",true);
                $(`input:radio[value=${mohInfoList.DATAS.MOHCLASS}]`).prop("checked",true);
          
                sameClass = sameClassList[i];
                sameClassNum = i;
          
                //있다면 화면 들어왔을 때 체크
                noData = "1";
          
                //임시사용자 간편통화연결음 설정 되어 있을 경우 미설정으로 변경
                if(userInfo.dataset.isTempUser === true){
                    callRingChange("");
                }
          
                getTtsInfo();
            }
        }

        //없을 때 진입
        if(noData !== "1" ) {
            sameClassHandler2(getMohListData, mohInfoList);
        }
    }

    //현재설정된 통화연결음이 간편 통화연결음에 없을 경우
    const sameClassHandler2 = (getMohListData,currentRing) => {
    
        $("#ringbackBasic1").prop("checked", false);
        $("#ringbackBasic2").prop("checked", false);
        $("#ringbackBasic3").prop("checked", false);
        $("#ringbackBasic4").prop("checked", false);
        $("#ringbackBasic5").prop("checked", false);
      
        let sameClass ="";
        setRemoveDelete("1")

        for(let i = 0; i< getMohListData?.DATAS?.length; i++){
            if(getMohListData?.DATAS[i].CLASS.includes(currentRing?.DATAS.MOHCLASS) && currentRing?.DATAS.MOHCLASS !== "" ) {
                sameClass = getMohListData?.DATAS[i].CLASS;
                /*
                if(
                    getMohListData?.DATAS[i].TYPE === "요일별" || 
                    getMohListData?.DATAS[i].TYPE === "시간별" || 
                    (getMohListData?.DATAS[i].TYPE === "전체" && getMohListData?.DATAS[i].TITLE !== "[TTS]기본 인사말") || 
                    (getMohListData?.DATAS[i].TYPE === "전체" && getMohListData?.DATAS[i].TITLE !== "[TTS]영업 중") ||
                    (getMohListData?.DATAS[i].TYPE === "전체" && getMohListData?.DATAS[i].TITLE !== "[TTS]영업 종료") ||
                    (getMohListData?.DATAS[i].TYPE === "전체" && getMohListData?.DATAS[i].TITLE !== "[TTS]휴식 시간") ||
                    (getMohListData?.DATAS[i].TYPE === "전체" && getMohListData?.DATAS[i].TITLE !== "[TTS]휴무일")  ){
                }
                */
            }
            // if($(`#ringback${i+2}`).val() === sameClass && currentRing?.DATAS.MOHCLASS !== ""){
            //     $(`#ringback${i+2}`).attr('checked', true);
            // }
        }
    
        getTtsInfo();
    }
    //빈객체 판별
    function isEmptyObj(obj)  {
      if(obj.constructor === Object
         && Object.keys(obj).length === 0)  {
           console.log("빈객체");
           setRemoveDelete("1");
        return true;
      }
    }
    //간펴농화연결음 조회 (소호 통화연결음 조회)
    const ringToneInfo = async (setClass, getMohListData) => {

        const getRingTone = await SohoApi.getRingToneInfo({
            ownerUserId : LocalStore.getOwnerId(),
            lpzEntrNo : resultNum, //매장 인터넷 가입 번호	
        });
        //console.log("zxc2",getMohListData)
        const getRingToneData = getRingTone.data;

        //빈객체 체크
        
        let valueArray = [];
        let classArray2 = []

        if(getRingToneData === undefined){
            valueArray = [];
        }else{
            valueArray = Object?.values(getRingToneData);
            setPreValueArray(valueArray);
            console.log("valueArray", valueArray)
            userInfo.setCallRingPopupCheck({
                rngTnTyp1:valueArray[0], //기본인사말
                rngTnTyp2:valueArray[2], // 영업중
                rngTnTyp3:valueArray[1], // 쉬는 시간
                rngTnTyp4:valueArray[4], // 영업종료
                rngTnTyp5:valueArray[3], // 휴무일
            });
            // console.log("basicCallArray", basicCallArray);
            
            //통화연결음 선택 팝업에서 간편통화연결음 메뉴가 나오도록 처리하는 로직
          
        }
         classArray2 =  getMohListData?.DATAS?.map((item) => item.CLASS );
         console.log("배열1", classArray2)
        //  console.log("zxczxc22",classArray2.find((items) => items === valueArray))
         if(isEmptyObj(getRingToneData) !== true){
            if(classArray2?.find((items) => items === valueArray[0]) === undefined){
              if(valueArray[0] !== ""){
                console.log("test1")
                await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : "", rngTnTyp2 : valueArray[2], rngTnTyp3 :valueArray[1], rngTnTyp4 : valueArray[4], rngTnTyp5 : valueArray[3]}) 
                valueArray[0] = ""
                userInfo.setCallRingPopupCheck({
                  rngTnTyp1: "", //기본인사말
                  rngTnTyp2:valueArray[2], // 영업중
                  rngTnTyp3:valueArray[1], // 쉬는 시간
                  rngTnTyp4:valueArray[4], // 영업종료
                  rngTnTyp5:valueArray[3], // 휴무일
              });
              }
            }
            if(classArray2?.find((items) => items === valueArray[2]) === undefined){
              if( valueArray[2] !== ""){
                console.log("test1")
                await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : "", rngTnTyp3 :valueArray[1], rngTnTyp4 : valueArray[4], rngTnTyp5 : valueArray[3]}) 
                valueArray[2] = ""
                userInfo.setCallRingPopupCheck({
                  rngTnTyp1:valueArray[0], //기본인사말
                  rngTnTyp2:"", // 영업중
                  rngTnTyp3:valueArray[1], // 쉬는 시간
                  rngTnTyp4:valueArray[4], // 영업종료
                  rngTnTyp5:valueArray[3], // 휴무일
              });
              }
            }
            if(classArray2?.find((items) => items === valueArray[1]) === undefined){
              if( valueArray[1] !== ""){
                console.log("test1")
                await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : valueArray[2], rngTnTyp3 : "", rngTnTyp4 : valueArray[4], rngTnTyp5 : valueArray[3]}) 
                valueArray[1] = ""
                userInfo.setCallRingPopupCheck({
                    rngTnTyp1:valueArray[0], //기본인사말
                    rngTnTyp2:valueArray[2], // 영업중
                    rngTnTyp3:"", // 쉬는 시간
                    rngTnTyp4:valueArray[4], // 영업종료
                    rngTnTyp5:valueArray[3], // 휴무일
                });
              }
            }
            if(classArray2?.find((items) => items === valueArray[4]) === undefined){
              if( valueArray[4] !== ""){
                console.log("test1")
                await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : valueArray[2], rngTnTyp3 : valueArray[1], rngTnTyp4 : "", rngTnTyp5 : valueArray[3]}) 
                valueArray[4] = ""
                userInfo.setCallRingPopupCheck({
                    rngTnTyp1:valueArray[0], //기본인사말
                    rngTnTyp2:valueArray[2], // 영업중
                    rngTnTyp3:valueArray[1], // 쉬는 시간
                    rngTnTyp4:"", // 영업종료
                    rngTnTyp5:valueArray[3], // 휴무일
                });
              }
            }
            if(classArray2?.find((items) => items === valueArray[3]) === undefined){
              if( valueArray[3] !== ""){
                console.log("test1")
                await SohoApi.getRingToneRegi({ownerUserId : LocalStore.getOwnerId(), lpzEntrNo : resultNum, rngTnTyp1 : valueArray[0], rngTnTyp2 : valueArray[2], rngTnTyp3 : valueArray[1], rngTnTyp4 : valueArray[4], rngTnTyp5 : ""}) 
                valueArray[3] = ""
                userInfo.setCallRingPopupCheck({
                    rngTnTyp1:valueArray[0], //기본인사말
                    rngTnTyp2:valueArray[2], // 영업중
                    rngTnTyp3:valueArray[1], // 쉬는 시간
                    rngTnTyp4:valueArray[4], // 영업종료
                    rngTnTyp5:"", // 휴무일
                });
              }
            }
        }
        console.log("valueArray2", valueArray)

        if(((valueArray[0] === "" && valueArray[1] === "" &&valueArray[2] === "" &&valueArray[3] === "" &&valueArray[4] === "") || 
        (valueArray[0] === null && valueArray[1] === null &&valueArray[2] === null &&valueArray[3] === null &&valueArray[4] === null) ||
        isEmptyObj(getRingToneData) === true)){
          
            userInfo.setDefalutCheck("1")
        }else {
            userInfo.setDefalutCheck("0")
        }

        let basicCallArray = getMohListData?.DATAS?.filter((item) => {
          if ( checkIsBasicRingTone(item.CLASS, valueArray) ) return item;
        });
        console.log("test",)
          setBasicCallBoxCheck(basicCallArray); //간편 통화연결음 담기
        //console.log("zc", basicCallArray);
        if(userInfo.dataset.isTempUser === false){
            //nonDefalutRingRemove(basicCallArray, valueArray);
        }

        setGetRingTonelData(getRingToneData);
        setSameClass(prev => prev = valueArray);
        let classValue = ""

        for(let i = 0; i< valueArray.length; i++){
            if(valueArray[i] !== ""){
                //console.log("현재기본클래스 저장")
                classValue = valueArray[i];
                setCurrentDefaultClass(prev => prev = classValue);
            }
        }
        sameClassHandler(valueArray,setClass, getMohListData);
    }
    
   
//소호는 있는데 센트릭스에는 없다...
    const checkIsBasicRingTone = (target, valueArray) => { 
        if ( valueArray !== null && valueArray?.length > 0 ){
            return valueArray.filter((item) => {
                if ( item === target ) return item;
            }).length > 0;
        }else{
            return false;
        }
    }
    const checkIsBasicRingTone2 = (target) => {
      if ( preValueArray !== null && preValueArray?.length > 0 ){
          return preValueArray.filter((item) => {
              if ( item === target ) return item;
          }).length > 0;
      }else{
          return false;
      }
  }

    //소호 통화연결음 저장 
    const ringToneRegi = async (data) => {
        const ringToneRegi = await SohoApi.getRingToneRegi({
            ownerUserId : LocalStore.getOwnerId(),
            lpzEntrNo : resultNum, //인터넷 가입번호
            rngTnTyp1 : "",
            rngTnTyp2 : "",
            rngTnTyp3 : "",
            rngTnTyp4 : "",
            rngTnTyp5 : "",
        })
        userInfo.setDefalutCheck("1")
    }

    //통화연결음 설정여부 조회 API
    const getMoh = async (getMohListData) => {
        //getMohListData ==> 통화연결음 목록
        //basicCallArray ==> 간편통화연결음 목록

        const getMohData = await CentrexApi.getMohInfo({
            peer_exten: LocalStore.getExtenInetNo()
        }).catch((e) => {
            // layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요", [
            //     function() {
            //         // SohoApi.getCentrexAuth({storeInetNo: LocalStore.getStoreInetNo()}).then(centrexAuthInfo => {                 
            //         layerPopup.hide("#popup_onetitlemsgbutton_api_error");
            //         // });
            //     }
            // ]);      
        });

        const mohData = getMohData.data;
        setMohInfo(mohData);
    
        //현재 설정된 통화연결음이 없을 때 (미설정)
        if(mohData?.DATAS.MOHCLASS === ''){
            $("input:radio[id='ringbackNot']").prop("checked",true);
        }
  
        if(mohData.DATAS.MOHCLASS !== ""){
            $(`input:radio[value=${mohData.DATAS.MOHCLASS}]`).prop("checked",true);
        }else{
            $("input:radio[id='ringbackNot']").prop("checked",true);
        }
    
        ringToneInfo(mohData,getMohListData);
    }

    const getTtsInfo = async () => {
        const getTtsInfoData = await CentrexApi.getTtsInfoList({})
        setMyTtsInfo(getTtsInfoData);
        if(userInfo.dataset.popupCheck === "1"){
            layerPopup.show('#popup_callring')
            userInfo.setPopupCheck("0");
        }
    }

    //번호의 통화 연결음 변경설정 API
    const callRingChange = async (callClass) => {
        const setMohInfoData = await CentrexApi.getSetMohInfo({
            class: callClass, // getmohlist에서 조회된 CLASS명 미설정시 : "" OR N 
            peer_exten: LocalStore.getExtenInetNo() // 뒷자리 4개 3자리일 경우 0XXX형식
        });
        
        if(setMohInfoData.data.SVC_MSG === "OK"){
            if(callClass === ""){
                NativeBridge.sendUserActionLog('detail', 'D085');
            }
            toast("통화연결음을 변경했습니다.")
            getMoh(mohListInfo,basicCallBoxCheck, true);
        }
    }

    //통화 연결음 목록 조회 API
    const mohList = async () => {

        const mohListData = await CentrexApi.getMohListInfo({})

        if(mohListData.data.SVC_RT === '0000' || mohListData.data.SVC_RT === '4004'){
            const getMohList = mohListData.data
            setMohListInfo(getMohList);

            //console.log("ㅋㅋㅋ", getMohList);
            //통화연결음 리스트 없을 때 
            if(getMohList?.DATAS === null){
                $("input:radio[id='ringbackNot']").prop("checked",true);
            }

            //통화연결음 목록에서 간편통화연결음만 basicCallArray에 담는다. 
            /*
            let basicCallArray = mohListData?.data?.DATAS?.filter((item) =>
                                            (item.TITLE.includes("[TTS]기본 인사말") ||
                                            item.TITLE.includes("[TTS]영업 중") ||
                                            item.TITLE.includes("[TTS]영업 종료") ||
                                            item.TITLE.includes("[TTS]쉬는 시간") ||
                                            item.TITLE.includes("[TTS]휴무일")));
            */
        
        
            //console.log("간편통화연결음 배열 ===>", basicCallArray);
            getMoh(getMohList);

        }else if(mohListData.data.SVC_RT === '1103'){
            //TODO 목록 미노출처리
            const getMohList = mohListData.data
            mohListData.data.DATAS = [];
            setMohListInfo([getMohList]);

            toast('조회불가능 계정입니다.');
            $("input:radio[id='ringbackNot']").prop("checked",true);
        }else {
            //TODO 목록 미노출처리
            toast('조회시 오류가 발생하였습니다.');
            $("input:radio[id='ringbackNot']").prop("checked",true);
        }
    }

    //통화연결음 삭제 API
    const delMohList = async (callClass) => {
        const delMohListData = await CentrexApi.getDelMohList({
            class: callClass
        });

        const getDelMohListData = delMohListData.data;
        setDelMohListInfo(getDelMohListData);
        if(getDelMohListData.SVC_RT === "0000"){
            toast("음원이 삭제되었습니다.")
            return mohList();
        }else if(getDelMohListData.SVC_RT === "4002"){
            toast("삭제 불가능한 음원입니다.")
        }else {
            toast("음원삭제에 실패하였습니다.")
        }
    };

    //기본 통화연결음 삭제 API
    const delMohList2 = async (callClass) => {
        const delMohListData = await CentrexApi.getDelMohList({
            class: callClass
        });

        if(delMohListData.data.SVC_RT === "0000"){
            toast("음원이 삭제되었습니다.")
            return mohList();
        }else if(delMohListData.data.SVC_RT === "4002"){
            //toast("삭제 불가능한 음원입니다.")
        }else {
            //toast("음원삭제에 실패하였습니다.")
        }
    }

    const promiseAllRemoveCall = () => {

        layerPopup.showCommon(
            '#image_remove_popup', 
            null, 
            '통화연결음 삭제', 
            '선택한 통화연결음을 삭제하시겠어요?',
            [
                function() {
                    layerPopup.hide('#image_remove_popup');//닫기
                },
                function() { // 확인 버튼
                    layerPopup.hide('#image_remove_popup');//닫기
                    
                    $("#ringbackBasic1").prop("checked", true);
                    
                    preValueArray.map((item) => {
                      delMohList2(item)
                    });
            
                    ringToneRegi();
                    userInfo.setDefalutCheck("1");
                }
            ]
        ) 

    }

    //통화연결음 가입 여부 조회
    const myServiceDetail = async () => {
        const getMyServiceDetail = await SohoApi.getMyServiceDetailList({
            userId: LocalStore.getUserId(),
            ownerUserId : LocalStore.getOwnerId(), //사장님 고유키(계정 선택시 전달 받은 값)
            storId : LocalStore.getStoreId(), //매장 아이디
        });

        if ( getMyServiceDetail.retCode === '0000'){
            const datas = getMyServiceDetail.data.lpzList;

            if ( datas !== null && datas.length > 0){
                setTtsOneTime(
                    datas.filter((item) => {
                        if ( item.svcCd === '070_ADD_SVC_2' && item.svcNm.indexOf('1회') > 0 && item.useYn === 'Y') return item;
                    }).length > 0
                );

                setTtsRegular(
                    datas.filter((item) => {
                        if ( item.svcCd === '070_ADD_SVC_2' && item.svcNm.indexOf('정액') > 0  && item.useYn === 'Y') return item;
                    }).length > 0
                );
            };
            
        }

        const myServiceDetailData = getMyServiceDetail.data;
        setMyServiceDetailList(myServiceDetailData);
    }

    //통화연결음 삭제
    const callRingRemove = (e)=> {
        let removeClass = e.target.value;
 
        layerPopup.showCommon(
           '#image_remove_popup', 
           null, 
           '통화연결음 삭제', 
           '선택한 통화연결음을 삭제하시겠어요?',
           [
               function() {
                   layerPopup.hide('#image_remove_popup');//닫기
               },
               function() { // 확인 버튼
                    if(removeClass === mohInfo?.DATAS.MOHCLASS ){
                        layerPopup.hide("#image_remove_popup", () => {
                            layerPopup.showCommon(
                                '#popup_onetitlemsgbutton', 
                                null, 
                                '통화연결음 삭제 실패', 
                                '지금 사용하고 있는 통화연결음입니다. <br /> 다른 통화연결음으로 설정한 후 <br /> 다시 삭제해 주세요.',
                                [
                                    function() {
                                        layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                                    },
                                ]
                            )
                        });
                        
                        //document.getElementById("image_remove_popup").style.display = "none";
                    }
                    else{
                        delMohList(removeClass);
                        layerPopup.hide('#image_remove_popup');//닫기
                    }
                }
            ]
         ) 
    }

    //통화연결음 변경 
    const callRingChangeHandler = (e) => {
        const changeClass = e.target.value
        callRingChange(changeClass);
    }
  
    //간편 통화연결음 클릭 방지
    const defaultCallRing = (e) => {
        if(e.target.value !== "" && e.target.name !== "ringback"){
            return callRingChange(e.target.value);
        } else {
            return e.preventDefault();
        }
    }
  
    //추가하기 click
    const addCallClick = () => {
        if(myTtsInfo?.data?.DATAS.TTS_1 === 'N' &&  myTtsInfo?.data.DATAS.TTS_Z ==='N'){
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '알림', 
                'TTS 음원제작을 모두 이용하였습니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                    }
                ]
            );
            return;
        }
        
        if(mohListInfo?.DATAS?.length >= 99){
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '통화연결음 등록 실패', 
                '통화연결음은 최대 99개까지 <br />만들 수 있습니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                    }
                ]
            );
        } else {
            layerPopup.show('#popup_selectcall')
            NativeBridge.sendUserActionLog('detail', 'D086');
         }
    }
  
    const goBackPage = () => {
        history.goBack();
    }
  
    //간편통화연결음 팝업
    const defaultCallRingPopup = () => {
        if(myTtsInfo?.data?.DATAS.TTS_1 === 'N' &&  myTtsInfo?.data.DATAS.TTS_Z ==='N'){
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '알림', 
                'TTS 음원제작을 모두 이용하였습니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                    }
                ]
            );
     
            return getMoh(mohListInfo,basicCallBoxCheck,true);
        }else{
            layerPopup.show('#popup_callring');
        }
    }
  
    const popupClose = () => {
      mohList();
        // getMoh(mohListInfo);
        // mohListInfo?.DATAS?.filter((item) => {
        //   if ( checkIsBasicRingTone2(item.CLASS, ) ) return item;
        // });
        //checkIsBasicRingTone2(mohListInfo);
        layerPopup.hide('#popup_callring')
    }
  
    const ttsCountZero = (e, title) => {
        NativeBridge.sendUserActionLog('detail', 'D091', "", title );
        if(myTtsInfo?.data?.DATAS.TTS_1 === 'N' &&  myTtsInfo?.data.DATAS.TTS_Z ==='N'){
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '알림', 
                'TTS 음원제작을 모두 이용하였습니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                    },
                ]
            );
    
            return;
        }


        if(mohListInfo?.DATAS?.length === 99 ){
            layerPopup.showCommon(
                '#popup_onetitlebutton_threemsg', 
                null, 
                '통화연결음 등록 실패', 
                '통화연결음은 최대 99개까지 <br />만들 수 있습니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                    },
                ]
            );
            return;
        }


        if(e.target.value === "1")  layerPopup.hide('#popup_callring', () => {history.push("/shop/call/callset/callring/basiccall")}) ;
        if(e.target.value === "2")  layerPopup.hide('#popup_callring', () => {history.push("/shop/call/callset/callring/opentimecallring")});
        if(e.target.value === "3")  layerPopup.hide('#popup_callring', () => {history.push("/shop/call/callset/callring/closetimecallring")});
        if(e.target.value === "4")  layerPopup.hide('#popup_callring', () => {history.push("/shop/call/callset/callring/breaktimecallring")});
        if(e.target.value === "5")  layerPopup.hide('#popup_callring', () => {history.push("/shop/call/callset/callring/holidaycallring")});
    }

    const ringBack1Click = () => {
        layerPopup.show('#popup_callring')
        NativeBridge.sendUserActionLog('detail', 'D084');
    }

    return (
    <>
        <div id = "container">
			<div className="content store">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>통화연결음 설정</h2>
					</div>
				</div>
				<div className="ringback_wrap">
					<div className="inner">
						<div className="chk_setting">
							<div className="checkbox style3">
								<span className="chk_title" >통화연결음
                                    {!userInfo.dataset.isTempUser && ttsOneTime && <span className="i_tag">1회용</span>}
                                    {!userInfo.dataset.isTempUser && ttsRegular && <span className="i_tag">정액형</span>}
                                </span>
							</div>
						</div>
						<ul className="ringback_list">
                            {
                                userInfo.dataset.isTempUser === false 
                                ?
                                    basicCallBoxCheck?.length < 1 || basicCallBoxCheck === undefined
                                    ? 
                                    <></>
                                    :
                                    <li>
                                        <input type="radio" id="ringback1"  name="ringback"  onClick={() => ringBack1Click()} />
                                        <label htmlFor="ringback1">
                                            <div className="title_box">
                                                <span className="title">간편 통화연결음</span>
                                            </div>
                                            <button type="button" className="btn_dark_edit usetap blind" onClick={()=>layerPopup.show('#popup_callring')}>수정</button>
                                            <button type="button"  className="btn_dark_del usetap blind" value={currentDefaultClass}  onClick={(e)=>promiseAllRemoveCall(e)}>삭제</button>
                                        </label>
                                    </li> 
                                :<></>
                            }
                            <li>
                                <input type="radio" id="ringbackNot" readOnly={true} name="ringback"  value=""  onChange={(e)=>callRingChangeHandler(e)}/>
                                <label htmlFor="ringbackNot" >
                                    <div className="title_box">
                                        <span className="title" >선택 안함</span>
                                    </div>
                                </label>
                            </li>
                            {
                                userInfo.dataset.isTempUser === false ?
                                    mohListInfo?.DATAS?.map((item, index)=> (
                                        checkIsBasicRingTone2(item.CLASS) ) !== true &&
                                        <li key={index}>
                                            <input type="radio" id={`ringback${index+2}`} name="ringback"  value={item.CLASS} onChange={(e)=>callRingChangeHandler(e)}/>
                                            <label htmlFor={`ringback${index+2}`} >
                                                <div className="title_box">
                                                    <span className="title">{item.TITLE}</span>
                                                    <span className="i_cate" value={item.TYPE}>{item.TYPE === "전체" ? "기본" : item.TYPE} 통화연결음</span>
                                                </div>
                                                <button type="button" value={item.CLASS} className="btn_dark_del usetap blind" onClick={(e)=>callRingRemove(e)}>삭제</button>
                                            </label>
                                        </li> 
                                    )     
                                       
                                    :
                                    mohListInfo?.DATAS?.map((item, index)=> (
                                        <li key={index}>
                                            <input type="radio" id={`ringback${index+2}`} name="ringback"  value={item.CLASS} onChange={(e)=>callRingChangeHandler(e)}/>
                                            <label htmlFor={`ringback${index+2}`} >
                                                <div className="title_box">
                                                    <span className="title">{item.TITLE}</span>
                                                    <span className="i_cate" value={item.TYPE}>{item.TYPE === "전체" ? "기본" : item.TYPE} 통화연결음</span>
                                                </div>
                                                <button type="button" value={item.CLASS} name={item.TITLE}className="btn_dark_del usetap blind" onClick={(e)=>callRingRemove(e)}>삭제</button>
                                            </label>
                                        </li>             
                                    )
                                    )} 
						</ul>
						<div className="btn_wrap">
                        { 
                            mohListInfo?.SVC_RT === "0000" || mohListInfo?.SVC_RT === "4004"  
                            ?
                            <button type="button" className="btn_round purple usetap" onClick={()=>addCallClick()}>만들기</button> 	
                            :
                            <></>
                        }	   
						</div>
					</div>
				</div>
			</div>
      
            <div className="modal fadeInUp" id="popup_callring"> 
                <div className="dim" onClick={()=>popupClose()}></div>
                <div className="modal_inner modal_up max_h">
                    <div className="modal_ringback_basic">
                        <span className="modal_title">간편 통화연결음</span>
                        <p>상황에 맞는 인사말을 등록해보세요. <br/>홈화면에서 간편하게 변경할 수 있습니다.</p>
                        <ul>
                            <li>     
                                <input type="radio" id="ringbackBasic1" name="ringbackBasic" onClick={(e)=>defaultCallRing(e)} value={userInfo.dataset.callRingPopupCheck.rngTnTyp1}/> 
                                <label htmlFor="ringbackBasic1">
                                    <span className="title">기본 인사말</span>
                                    {
                                        userInfo.dataset.callRingPopupCheck.rngTnTyp1 !== "" &&  userInfo.dataset.callRingPopupCheck.rngTnTyp1 !== null && userInfo.dataset.callRingPopupCheck.rngTnTyp1 !== undefined 
                                        ? <button type="button" className="btn_round green refresh usetap" value="1" onClick={(e)=>ttsCountZero(e, "기본인사말")}>다시 만들기</button>
                                        : <button type="button" className="btn_round purple usetap"value="1" onClick={(e)=>ttsCountZero(e, "기본인사말")} >만들기</button>
                                    }
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="ringbackBasic2" name="ringbackBasic" onClick={(e)=>defaultCallRing(e)} value={userInfo.dataset.callRingPopupCheck.rngTnTyp2}/> 
                                <label htmlFor="ringbackBasic2">
                                    <span className="title">영업 중</span>
                                    {
                                        userInfo.dataset.callRingPopupCheck.rngTnTyp2 !== "" &&  userInfo.dataset.callRingPopupCheck.rngTnTyp2 !== null && userInfo.dataset.callRingPopupCheck.rngTnTyp2 !== undefined
                                        ?<button type="button" name={userInfo.dataset.callRingPopupCheck.rngTnTyp2} value="2" className="btn_round green refresh usetap" onClick={(e)=>ttsCountZero(e, "영업중")}>다시 만들기</button>
                                        : <button type="button" className="btn_round purple usetap" value="2" onClick={(e)=>ttsCountZero(e, "영업중")}>만들기</button>
                                    }
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="ringbackBasic3" name="ringbackBasic" onClick={(e)=>defaultCallRing(e)} value={userInfo.dataset.callRingPopupCheck.rngTnTyp4}/>
                                <label htmlFor="ringbackBasic3">
                                    <span className="title">영업 종료</span>
                                    {
                                        userInfo.dataset.callRingPopupCheck.rngTnTyp4 !== ""  &&  userInfo.dataset.callRingPopupCheck.rngTnTyp4 !== null && userInfo.dataset.callRingPopupCheck.rngTnTyp4 !== undefined
                                        ? <button type="button" name={userInfo.dataset.callRingPopupCheck.rngTnTyp4} value="3" className="btn_round green refresh usetap" onClick={(e)=>ttsCountZero(e, "영업종료")} >다시 만들기</button>
                                        : <button type="button" className="btn_round purple usetap" value="3" onClick={(e)=>ttsCountZero(e, "영업종료")} >만들기</button>
                                    }
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="ringbackBasic4" name="ringbackBasic" onClick={(e)=>defaultCallRing(e)} value={userInfo.dataset.callRingPopupCheck.rngTnTyp3}/>
                                <label htmlFor="ringbackBasic4">
                                    <span className="title">쉬는 시간</span>
                                    {
                                        userInfo.dataset.callRingPopupCheck.rngTnTyp3 !== "" &&  userInfo.dataset.callRingPopupCheck.rngTnTyp3 !== null && userInfo.dataset.callRingPopupCheck.rngTnTyp3 !== undefined
                                        ? <button type="button" name={userInfo.dataset.callRingPopupCheck.rngTnTyp3} value="4" className="btn_round green refresh usetap" onClick={(e)=>ttsCountZero(e, "쉬는시간")} >다시 만들기</button>
                                        : <button type="button" className="btn_round purple usetap" value="4" onClick={(e)=>ttsCountZero(e, "쉬는시간")}>만들기</button>
                                    }
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="ringbackBasic5" name="ringbackBasic" onClick={(e)=>defaultCallRing(e)} value={userInfo.dataset.callRingPopupCheck.rngTnTyp5}/> 
                                <label htmlFor="ringbackBasic5">
                                    <span className="title">휴무일</span>
                                    {
                                        userInfo.dataset.callRingPopupCheck.rngTnTyp5 !== ""  &&  userInfo.dataset.callRingPopupCheck.rngTnTyp5 !== null && userInfo.dataset.callRingPopupCheck.rngTnTyp5 !== undefined
                                        ? <button type="button" name={userInfo.dataset.callRingPopupCheck.rngTnTyp5} value="5" className="btn_round green refresh usetap" onClick={(e)=>ttsCountZero(e, "휴무일")} >다시 만들기</button>
                                        : <button type="button" className="btn_round purple usetap" value="5" onClick={(e)=>ttsCountZero(e, "휴무일")} >만들기</button>
                                    }
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
    );

});
export default CallRing;