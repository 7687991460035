import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as NativeBridge from '../../../utils/NativeBridge';
import { getCurrentDateTimeString } from '../../../common/common';

const HeadTop = observer(() => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //console.log(userList);

    const sampleSendLog = async () => {
        NativeBridge.sendUserActionLog(
            "actionType"           //actionType
            ,"actionDetail1"        //actionDetail1
        );

        let test = {
            groups: "0",
            name: "김기환",
            phone: "01094278644,01094270000",
            etc: "서버담당\r" + getCurrentDateTimeString(),
            peer_no: "5707733"
        };
        NativeBridge.modifyAddressInfo(test);
    }

    return (
        <>
            <div className="hd_top">
                <div className="hd_top_inner">
                    <h1 className="logo_wrap">
                        <span className="logo blind">U+</span>
                        <span className="title" onClick={(e)=> sampleSendLog()}>우리가게패키지</span>
                    </h1>
                    { userInfo.dataset.familyYn === 'Y' || userInfo.dataset.isTempUser === true ?
                        <Link to={'/my/mysetting/cs/familynotice'} className="ico_alarm usetap blind" onClick={() => NativeBridge.sendUserActionLog('menu', 'H001')}>공지사항로 이동</Link>
                    :
                        <Link to={'/my/mysetting/cs/eventnotice'} className="ico_alarm usetap blind" onClick={() => NativeBridge.sendUserActionLog('menu', 'H001')}>알림페이지로 이동</Link>
                    }
                </div>
            </div>
        </>
    )
});

export default HeadTop;