import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import $ from 'jquery';
import CctvApi from '../../../api/CctvApi';
import 'moment/locale/ko';
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/react';
import * as LocalStore from '../../../store/LocalStore'
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";
import * as NativeBridge from "../../../utils/NativeBridge";

SwiperCore.use([ Pagination, Scrollbar ]);

const WorkerManage = observer(({ svcCardNm }) => {

    const { userData } = store;

	const RandomNum = ['1', '2', '3'];

    let cctvKey = LocalStore.getCctvToken();
	var dayArray = ['월', '화', '수', '목', '금', '토', '일']
    var currDay = dayArray.indexOf(moment().format('ddd').toLowerCase()) + 1
	var WorkerData = []
	var calcWorkerData = []
	let SplitWorkerData = []
	var imgArray = []
	var imgArrayArray = []

	const [workerList, setWorkerList] = useState([])
	const [workerCnt, setWorkerCnt] = useState(0)
	const [status6, setStatus6] = useState(0) // 결근
	const [status2, setStatus2] = useState(0) // 지각
	const [status3, setStatus3] = useState(0) // 조퇴
	const [retCode, setRetCode] = useState(0)
	const [imgIdx, setImgIdx] = useState([])

	useEffect(()=>{
		if(userData.dataset.procChgStoreComplete) {
			WorkerFilter()
		}
	}, [userData.dataset.procChgStoreComplete]);

	useEffect(()=>{
		if (userData.dataset.homeWorkerFlag){
			WorkerFilter()
			userData.setHomeWorkerChFlag(false);
		}
	}, [userData.dataset.homeWorkerFlag]);

	useEffect(() => {
		if (workerList.length <= 1) {
			$('.sec_staff .staff_rolling .i_pagination').addClass('i_disabled');	
		} else {
			$('.sec_staff .staff_rolling .i_pagination').removeClass('i_disabled');
		}
	}, [workerList])

	const WorkerFilter = async() => {
		await WorkerList()

		if(WorkerData.length > 0) {
			WorkerData.map((item) => (item.schedule_info.schedule_weekly?.indexOf(`${currDay}`) !== -1 && item.schedule_info.schedule_type === 0) && calcWorkerData.push(item))
			for(var i=0; i< calcWorkerData.length; i+=3) SplitWorkerData.push(calcWorkerData.slice(i, i+3))
			setWorkerList(SplitWorkerData)
			setWorkerCnt(calcWorkerData.length)
			setStatus2(calcWorkerData?.filter((item) => item.workStatus === 2).length)
			setStatus3(calcWorkerData?.filter((item) => item.workStatus === 3).length)
			setStatus6(calcWorkerData?.filter((item) => item.workStatus === 6 || item.workStatus == null).length)

			for(var j=0; j<calcWorkerData.length; j++) imgArray.push(...RandomNum[Math.floor(Math.random() * RandomNum.length)])
			for(var z=0; z< calcWorkerData.length; z+=3) imgArrayArray.push(imgArray.slice(z, z+3))
			console.log('wd', imgArrayArray)
			setImgIdx(imgArrayArray)
		}
		// console.log('직원1', calcWorkerData?.filter((item) => item.workStatus === 2).length)
		// console.log('직원2', calcWorkerData?.filter((item) => item.workStatus === 3).length)
		// console.log('직원3', calcWorkerData?.filter((item) => item.workStatus === 6 || item.workStatus == null).length)
		// console.log('직원4', calcWorkerData?.filter((item) => item.workStatus == null).length)
	}

	const ErrorRefresh = () => {
        WorkerFilter();
    }

	const funcTooltip = (e,msg) => {
		e.stopPropagation()
		e.currentTarget.parentNode.querySelector(msg).classList.toggle("on");
	}

	const ShopWorkerManage = () => {
		userData.setShopMainTabList('workermanage')
		NativeBridge.sendUserActionLog('menu', 'H008');
	}

	const WorkerList = async() => {
		if((cctvKey !== null || cctvKey !== "") && (userData.dataset.currstoreInfo.cam_device_sn_id !== undefined && 
			userData.dataset.currstoreInfo.cam_device_sn_id !== null && userData.dataset.currstoreInfo.cam_device_sn_id.length > 0)) {
			try {
				if(userData.dataset.currstoreInfo.cam_device_sn_id.length === 1) {
					const WorkerListData = await CctvApi.getWorkerList(
						userData.dataset.currstoreInfo?.cam_device_sn_id[0][0],
						{
							'fromDateTime' : `${moment().format('YYYYMMDD000000')}`,
							'toDateTime' : `${moment().format('YYYYMMDDHHmmss')}`
						})
						setRetCode(WorkerListData.data.resCode)
					if ( Array.isArray(WorkerListData.data.list) && WorkerListData.data.list.length > 0 ) {
						WorkerData.push(...WorkerListData.data.list)
					}
				} else {
					let serverWorkerList = []
					for(let i = 0; i < userData.dataset.currstoreInfo?.cam_device_sn_id.length; i++) {
						const WorkerListData = await CctvApi.getWorkerList(
							userData.dataset.currstoreInfo?.cam_device_sn_id[i][0],
							{
								'fromDateTime' : `${moment().format('YYYYMMDD000000')}`,
								'toDateTime' : `${moment().format('YYYYMMDDHHmmss')}`
							})
							setRetCode(WorkerListData.data.resCode)
						if ( Array.isArray(WorkerListData.data.list) && WorkerListData.data.list.length > 0 ) {
							serverWorkerList.push(...WorkerListData.data.list)
						}
					}
					WorkerData.push(...serverWorkerList);
				}
			} catch {
				setRetCode(null)
			}
		}
    }

    return (
		<>
			{ ((cctvKey !== null || cctvKey !== "") && 
			  userData.dataset.currstoreInfo.cam_device_sn_id !== undefined && userData.dataset.currstoreInfo.cam_device_sn_id !== null &&
              userData.dataset.currstoreInfo.cam_device_sn_id.length > 0 && (retCode !== null && retCode === 0) ) ?
				<div className="content_box">
					<Link to="/shop/workermanage" className="sec_link usetap" onClick={()=> ShopWorkerManage()}>
						<i className="ico_dark_more"></i>더보기
					</Link>
					<span className="cont_title">{svcCardNm}</span>
					{ (Array.isArray(workerList) && workerList.length > 0) ?
						<div className="sec_wrap sec_staff staff_rolling_wrap">
							<span className="info_msg">오늘 {userData.dataset.storeNm === '' ? 
								userData.dataset.currstoreInfo?.storeNm
							: 
							userData.dataset.storeNm}
							&nbsp;총 {workerCnt}명 직원들 중</span>
							{ 
							(status6 > 0 && status2 === 0 && status3 === 0) ?
								<strong className="st_msg">결근<span className="pt_color"> {status6}</span>명이 있습니다.</strong>
							
							: (status2 > 0 && status3 === 0 && status6 === 0) ?
								<strong className="st_msg">지각<span className="pt_color"> {status2}</span>명이 있습니다.</strong>
							
							: (status3 > 0 && status2 === 0 && status6 === 0) ?
								<strong className="st_msg">조퇴<span className="pt_color"> {status3}</span>명이 있습니다.</strong>
							
							: (status2 === 0 && status3 === 0 && status6 === 0) ?
								<strong className="st_msg">모두 출근 하였습니다.</strong>

							: (status6 > 0 || status2 > 0 || status3 > 0) ?
								<strong className="st_msg">결근 
									<span className="pt_color"> {status6}</span>명, 
									지각 
									<span className="pt_color"> {status2}</span>명,
									조퇴
									<span className="pt_color"> {status3}</span>명이 있습니다.
								</strong>
							:
								<></>
							}
							{ (workerList !== undefined && workerList !== null && workerList.length > 0) &&	
									<Swiper
										className="staff_rolling"
										style={{ overflow: 'visible' }}
										pagination={{ 
											clickable: true, 
											el: '.i_pagination'
										}}
										slidesPerView="auto"
										observeParents
										observer
										loop={false}>
										{ workerList?.map((workeritem, arrayindex) => (
											<SwiperSlide key={`workerArray_${arrayindex}`}>
												<ul className="commute_list">
													{ (Array.isArray(workeritem) && imgIdx[arrayindex])  &&
														workeritem.map((item, index) => (
															<li key={`worker_${index}`}>
																<span className={'staff_thumb' + (` hero${imgIdx[arrayindex][index]}`)}></span>
																<span className="staff_name">{item.device_user_name.length > 10 ? 
																								`${item.device_user_name.substr(0, 10) + '...'}` : 
																								`${item.device_user_name}`}</span>
																<div className="work_plan">
																	<span className="plan_title">근무계획</span>
																	<span className="commute_time">{
																		currDay === 1 ? 
																			item.schedule_info.schedule_mon_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		currDay === 2 ?
																			item.schedule_info.schedule_tue_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		currDay === 3 ?
																			item.schedule_info.schedule_wed_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		currDay === 4 ?
																			item.schedule_info.schedule_thu_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		currDay === 5 ?
																			item.schedule_info.schedule_fri_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		currDay === 6 ?
																			item.schedule_info.schedule_sat_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		currDay === 7 ?
																			item.schedule_info.schedule_sun_start?.replace(/(\d{2})(\d{2})/, '$1:$2 ') :
																		<></>
																	}
																	-
																	{   currDay === 1 ?
																			item.schedule_info.schedule_mon_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		currDay === 2 ?
																			item.schedule_info.schedule_tue_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		currDay === 3 ?
																			item.schedule_info.schedule_wed_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		currDay === 4 ?
																			item.schedule_info.schedule_thu_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		currDay === 5 ?
																			item.schedule_info.schedule_fri_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		currDay === 6 ?
																			item.schedule_info.schedule_sat_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		currDay === 7 ?
																			item.schedule_info.schedule_sun_end?.replace(/(\d{2})(\d{2})/, ' $1:$2') :
																		<></>
																	}
																	</span>
																</div>
																{ item.workStatus === null ?
																	<span className="staff_status blind status6"></span>
																:
																	<span className={`staff_status blind status${item.workStatus}`}></span>
																}
																{ item.workStatus === 5 &&
																	<>
																		<button type="button" className="ico_ques ico_ques1 usetap blind" onClick={(e)=>funcTooltip(e,'.tooltip_box')}>도움말</button>
																		<div className="tooltip_box">
																			<span className="top_arrow"></span>
																			<p className="tip_content">
																				출근 확인이 제대로 되지 않았습니다.<br/>한 번 더 태그해주세요.
																			</p>
																		</div>
																	</>
																}
															</li>
													))
													}
												</ul>
											</SwiperSlide>
										))
										}
									<div className="i_pagination"></div>
									</Swiper>
								}
							{/* 
								status1 : 정상 근무
								status2 : 지각
								status3 : 조퇴
								status4 : 출근
								status5 : 출퇴근 등록 미흡
								status6 : 결근
								status7 : 퇴근(등록미흡)
							*/}
						</div>
					:	
						<div className="sec_wrap sec_staff">
							<span className="info_msg">
								등록된 직원이 없습니다.
								<div className="tooltip_wrap">
									<button type="button" className="ico_ques ico_ques1 usetap blind" onClick={(e)=>funcTooltip(e,'.tooltip_box')}>도움말</button>
									<div className="tooltip_box">
										<span className="top_arrow"></span>
										<p className="tip_content">
											등록방법: PC &gt; 영상모니터링 프로그램 실행 &gt; <br />
											출입관리 &gt; 근무스케줄 관리 &gt; 근무규칙 추가<br /><br />
											(영상모니터링 프로그램 다운로드: cloudcctv.uplus.co.kr)
										</p>
									</div>
								</div>
							</span>
							<strong className="st_msg">직원 근무 규칙을 등록하여 관리해보세요.</strong>
						</div>
					}
				</div>
			:
				<>
					<div className="content_box">
						<Link to="/shop/workermanage" className="sec_link usetap" onClick={()=> ShopWorkerManage()}><i className="ico_dark_more"></i>더보기</Link>
						<span className="cont_title">{svcCardNm}</span>
						<div className="sec_wrap">
							<strong className="st_msg">일시 오류, 새로고침으로 <br />정보를 불러주세요!</strong>
							<div className="ex_img row">
								<img src="/res/images/img-home-staff-card-error@3x.png" />
								<button type="button" className="btn_refresh usetap blind" onClick={()=> ErrorRefresh()}>새로고침</button>
							</div>
						</div>
					</div>
				</>
			}
		</>
    )
});

export default WorkerManage;