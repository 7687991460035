import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { textChgEdit, textChgBlock, textareaUi, layerPopup, toast } from "../../../../../libs/common";
import * as Common from "../../../../../common/common";



const PhoneBookDetailReg = observer(({ history, location }) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  return (
    <>
    
							{/* <div className="call_srh_box">
								<div className="input_box style1">
									<input type="text" placeholder="이름, 전화번호를 검색해보세요."/>
									<span className="ip_clear hidden usetap">초기화</span>
									<span className="ico_srh"></span>
								</div>
							</div> */}
							{/* <!-- 210616 추가 --> */}
							{/* <div className="contact_cate"> */}
								{/* <!-- 210621 디자인 업데이트 클래스 수정 --> */}
								{/* <div className="btn_cate_wrap2" id="memo_cate">
									<button type="button" className="tab_item active">인터넷 전화 주소록</button>
									<button type="button" className="tab_item">휴대폰 주소록</button>
								</div> */}
								{/* <!-- 210913 삭제
								<button type="button" className="btn_refresh_txt usetap"><i className="ico_refresh"></i>새로고침</button>
								 --> */}
								{/* <!-- 210913 추가 --> */}
								{/* <div className="btn_wrap">
									<button type="button" className="btn_refresh_round blind usetap">새로고침</button>
									<button type="button" className="btn_add blind usetap">연락처 추가</button>
								</div>
							</div> */}
							
							{/* <!-- 210913 등록된 연락처가 없습니다. --> */}
				            <div className="data_none">
                                <div className="none_wrap">
                                    <span className="none_img"></span>
				                    <p>검색결과가 없습니다.</p>
				                </div>
				            </div>
				            {/* <!-- // 210913 등록된 연락처가 없습니다. --> */}
                
        
    </>
  );
});
export default PhoneBookDetailReg;