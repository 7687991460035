import React, {useEffect, useState} from 'react';
import { Route, Redirect, Link, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as LocalStore from '../../../store/LocalStore'
import 'moment/locale/ko';
import moment from 'moment';
import $ from 'jquery';
import * as NativeBridge from '../../../utils/NativeBridge';
import { layerPopup, toast } from "../../../libs/common";

const Sales = observer(({ svcCardNm }) => {
    const { userInfo, userData } = store;
    const [iframeKeyValue, setIframeKey] = useState(0);

    useEffect(() => {

        //EvntIfmOnLoad();
        
        const subscription = NativeBridge.eventObservable.subscribe(event => {
            console.log("----------------- receive from Native Event : Sales.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'CALL_REFRESH':		
                    //TODO : 테스트 후 제거 ( iframe reload)
                    // var result = event.param;
                    // console.log("param:", result);
                    // layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "CALL_REFRESH 호출됨.=>" + result.reqID, [
                    //     function() {
                    //         layerPopup.hide("#popup_onetitlemsgbutton");
                    //     }
                    // ]);

                    setIframeKey(iframeKeyValue => iframeKeyValue+1);

                    break;
                default:
                    break;
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const goPageOnClick = (e) => {
        NativeBridge.sendUserActionLog('menu', 'H004');
        NativeBridge.goPage("https://lg-uplus-soho.cashnote.kr/?registration_number=6111303767", "HOME.SALESCARD")
    }

    const EvntIfmOnLoad = () => {
        //onLoad={(e)=>EvntIfmOnLoad(e)}
        console.log('EvntIfmOnLoad=>');
        /*
        var theHeight = e.target.contentWindow.document.body.scrollHeight; 
        console.log("theHeight=>", theHeight);
        e.target.style.height = theHeight;
        e.target.style.overflow = "hidden"; 
        */
        var the_height = document.getElementById('card_unsubs_iframe').contentWindow.document.body.scrollHeight; 
        document.getElementById('card_unsubs_iframe').height = 345; 
        document.getElementById('card_unsubs_iframe').style.overflow = "hidden"; 
    }

    return (
        <>
            <div className="content_box">
                <span className="cont_title" onClick={() => goPageOnClick()}>{svcCardNm}</span>
                <div className="sec_wrap sec_card_sales">
                    <iframe key={iframeKeyValue} id="card_unsubs_iframe" /*style={{ height: "41rem" }}*/ src={`https://lg-uplus-soho.cashnote.kr/preview?registration_number=6111303767&_refreshkey=${iframeKeyValue}`}  frameBorder="0"></iframe>
                </div>
            </div>
        </>
    )
    
});
export default Sales;