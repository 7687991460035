import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { floatUI } from '../../../../libs/floating'
import { tabUi } from '../../../../libs/tab'
import { textChgEdit, textChgBlock, textareaUi, layerPopup } from "../../../../libs/common"



//전화 설정 페이지
const CallLogDetailReg = observer(({ history }) => {
  // const [forwardType, setFowardType] = useEffect([]);
  // const [mohInfo, setMohInfo] = useEffect([]);
     const [callFowarding, SetCallFowarding] = useState(false);
     const [callLink, SetCallLink] = useState(false);
     const [callFowardingToggle, SetCallFowardingToggle] = useState(true);
     const [callLinkToggle, SetCallLinkToggle] = useState(true);

	useEffect(() => {
    store.userData.setShopMainTabList('call');
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('bg_gray');
          }          
          return () => {
            if($('#wrap').hasClass('bg_gray')) {
              $('#wrap').removeClass('bg_gray');
              $('#wrap').addClass('gnb_bottom');
            }
          };
	}, []);

 


  const { userInfo, userData } = store;
  const userList = userInfo.getList();
  console.log(userList);

  const callFowardSet = userInfo.dataset.callFoward;
  const callLinkSet = userInfo.dataset.callLink;

  const blackList = userInfo.dataset.blackList;
  const blackAndAnonymous = userInfo.dataset.blackAndAnonymous;
  const allBlock = userInfo.dataset.allBlock;

  useEffect(() => {
    console.log("ShopMapping default useEffect..");
  }, []);

  const goBackPage = () => {
      history.goBack();
  }

  const TextChangeClick = (e) => {
      textChgBlock(e);
      e.classList.toggle('on');
  }

  return (
    <>
        <div className="content store">
            <div className="page_title_wrap3">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                    <h2>연락처 등록</h2>
                    <button type="button" className="btn_complete">완료</button>
                </div>
            </div>
            <div className="contact_info edit">
              <div className="name_wrap">
                <div className="input_box style3">
                  <input type="text" id="contact_name" />
                  <label htmlFor="contact_name" className="label_placeholder">이름을 입력해주세요.</label>
                  <span className="ip_gradient"></span>
                  <span className="ip_clear hidden">초기화</span>
                </div>
              </div>
              <div className="tel_wrap">
                <span className="title">전화번호</span>
                <div className="input_box style3">
                  <input type="tel" id="contact_tel" value="010-1234-5678" />
                  <label htmlFor="contact_tel" className="label_placeholder">번호를 입력해주세요.</label>
                  <span className="ip_gradient"></span>
                  <span className="ip_clear hidden">초기화</span>
                </div>
                <div className="input_box style3">
                  <input type="tel" id="contact_tel2" />
                  <label htmlFor="contact_tel2" className="label_placeholder">번호를 입력해주세요.</label>
                  <span className="ip_gradient"></span>
                  <span className="ip_clear hidden">초기화</span>
                </div>
              </div>
            </div>
        </div>
    </>
  );
});
export default CallLogDetailReg;