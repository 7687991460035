import React from 'react'
import { observer } from "mobx-react";
import store from '../../../store';
import * as NativeBridge from "../../../utils/NativeBridge";

const ShopManage = observer(() => {

    const { userInfo, userData,deviceInfo } = store;
	const userList = userInfo.getList();

    const externalCallOnClick = () => {
        console.log("안드로이드", deviceInfo.dataset.os_type)
        if(deviceInfo.dataset.os_type === "android"){
            NativeBridge.externalCall("com.lge.promota","1")
        }
        else {
            // NativeBridge.externalCall("com.lgsvl.showit","1")
            NativeBridge.externalCall("promota://","3")
        }
    }

    return (
        <div className="fix_container">
            <div className="fix_container">
                <div className="store_manage">
                    <div className="sec_wrap bn_wrap">
                        <img src="/res/images/store-manage-banner@3x.png" alt="" />
                    </div>
                    <div className="made_signboard">
                        <span className="title">디지털 간판 만들기</span>
                        <div className="link_signboard" onClick={() => externalCallOnClick()}>디지털 간판 만들기</div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ShopManage
