import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store';
import $ from 'jquery';
import * as LocalStore from '../../../../store/LocalStore'
import * as NativeBridge from '../../../../utils/NativeBridge';
import { layerPopup, toast } from '../../../../libs/common';


const BioSetting = observer(({ history, location }) => {

    const { userInfo, userData } = store;

    const [check, setCheck] = useState((LocalStore.getBioLoginType() === "0" || LocalStore.getBioLoginType() === "1") ? true : false)
    
    useEffect(() => {       
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').addClass('bg_gray');
        }

        if(LocalStore.getBioLoginType() === '0' || LocalStore.getBioLoginType() === '1') {
            $("input:checkbox[id='BioLoginCheck']").prop("checked", true);
        } else {
            $("input:checkbox[id='BioLoginCheck']").prop("checked", false);
        }

        NativeBridge.sendUserActionLog('first', 'FS');   
        console.log("Bio default useEffect..");

       //native event set
        const subscription = NativeBridge.eventObservable.subscribe(event => {
            console.log("------- From Native -------");
            console.log(event);
                
            switch (event.type) {                    

                case 'CALL_BIOMETRICS':

                    const result = event.param;                
                    console.log("bio reg result: ", result);

                    console.log(result.type === "0" ? "지문" : "안면인식");
            
                    // 성공
                    if(result.status === "0") {
                        console.log("등록 성공");
                        userInfo.setBioLoginType(result.type);

                    // 실패
                    } else if(result.status === "1") {
                        toast("등록에 실패했습니다. 다시 한번 시도해 주세요.");
                        LocalStore.removeBioLoginType();
                        $("input:checkbox[id='BioLoginCheck']").prop("checked", false);
                        layerPopup.show('#popup_my_bio');
                    // 미등록 
                    } else if(result.status === "2") {
                        toast("기기에서 지문 등록 후 다시 시도해주세요.");
                        $("input:checkbox[id='BioLoginCheck']").prop("checked", false);

                        LocalStore.removeBioLoginType();
                    // 미지원단말 
                    } else if(result.status === "9") {
                        toast("생체인증 미지원단말입니다.");
                        LocalStore.removeBioLoginType();
                        $("input:checkbox[id='BioLoginCheck']").prop("checked", false);

                    } else {
                        toast("등록에 실패했습니다.");
                        LocalStore.removeBioLoginType();
                        $("input:checkbox[id='BioLoginCheck']").prop("checked", false);

                    }

                break;

                case 'CALL_BACKPRESS':
                    console.log("Go Back!");
                    $("input:checkbox[id='BioLoginCheck']").prop("checked", false);
                    $('#wrap').addClass('bg_gray');
                    layerPopup.hide('#popup_my_bio');
                break;

                default:
                    break;
            }
        });

        return () => {
            if($('#wrap').hasClass('bg_gray')) {
                $('#wrap').removeClass('bg_gray');
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').addClass('gnb_bottom');
            }
            subscription.unsubscribe();
        };
    }, []);


    useEffect(()=>{
        if(userInfo.dataset.bioLoginType === null) {
            setCheck(false);
        }
    }, [userInfo.dataset.bioLoginType]);


   // 다음에 다시하기
   const notNow = (event) => {
        event.preventDefault();
        $("input:checkbox[id='BioLoginCheck']").prop("checked", false);
        LocalStore.removeBioLoginType();
        $('#wrap').addClass('bg_gray');
        layerPopup.hide('#popup_my_bio');
    }

    // 등록하기
    const regBio = (event) => {
        event.preventDefault();       
        layerPopup.hide('#popup_my_bio', () => {
            NativeBridge.regBiometrics();
        });
    }

    const BioChange = (e) => {
        let checked = e.target.checked
        if(LocalStore.getBioLoginType() === '0' || LocalStore.getBioLoginType() === '1') {
            $("input:checkbox[id='BioLoginCheck']").prop("checked", false);
            // LocalStore.removeBioLoginType();
            setCheck(checked)
            userInfo.setBioLoginType(null);
        } else {
            $("input:checkbox[id='BioLoginCheck']").prop("checked", true);
            setCheck(checked)
            $('#wrap').removeClass('bg_gray');
            layerPopup.show("#popup_my_bio")
        }
    }

    return (
        <>
			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> history.goBack()}>뒤로가기</button>
						<h2>지문 또는 얼굴인식 로그인 설정</h2>
					</div>
				</div>
	            <div className="my_bio_set">
	                <span className="title">Face ID or 생체인증 로그인</span>
	                <p>빠른 로그인을 위해 사용할 수 있습니다.</p>
	                <div className="check_wrap">
                        <input id="BioLoginCheck" className="checkbox" type="checkbox" checked={check} onChange={(e)=> BioChange(e)} />
                        <div className="check_bg">
                            <div className="check_bg_theme"></div>
                        </div>
                        <div className="check_dot"></div>
                    </div>
	            </div>
			</div>

            <div className="modal fadeInUp" id="popup_my_bio">
                <div className="dim"></div>
                <div className="modal_inner modal_up">
                    <span className="modal_title">간편 인증을 설정해보세요.</span>
                    <a href="" className="bio_regi_box usetap" onClick={(e)=> regBio(e)}> 
                        <span className="title">지문 또는 얼굴 등록하기</span>
                        <p className="desc">비밀번호 대신 <br/>지문 또는 얼굴로 로그인할 수 있어요.</p>
                    </a>
                    <div className="link_theme_wrap">
                        <a href="" className="link_theme" onClick={(e)=> notNow(e)}>다음에 등록하기</a>
                    </div>
                </div>
            </div>
        </>
    )
});

export default BioSetting;