import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import * as NativeBridge from '../../../../utils/NativeBridge'
import store from '../../../../store';
import $ from 'jquery';
import SohoApi from '../../../../api/SohoApi';

const MyPolicyDetail = observer(({ history, match }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const [termsagree, setTermsagree] = useState(null)
    // const [termsagreedetail, setTermsagreedetail] = useState(null)
    const [termsIdx, setTermsIdx] = useState("")
    // const [nextPage, setNextPage] = useState(2)
    // const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        termsAgreeInfo();
        // $(window).on('scroll', function() {
        //     const scrollTop = $(window).scrollTop()
        //     if(scrollTop <= 0) {
        //         setHasMore(false)
        //     } else {
        //         setHasMore(true);
        //     }
        // })
        // return () => {
        //     $(window).off('scroll');
        // }
    }, [])
   
    useEffect(() => {       
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom')
            $('#wrap').addClass('bg_gray');
          }          
          return () => {
            if($('#wrap').hasClass('bg_gray')) {
              $('#wrap').removeClass('bg_gray');
              $('#wrap').addClass('gnb_bottom');
            }
          };
    }, []);

    // useEffect(() => {
    //     if(match.params.termsIdx) {
    //         if(match.params.termsIdx.length === 1) {
    //             setTermsIdx(`P00${match.params.termsIdx}`);
    //             setTimeout(() => {
    //                 $(`#terms-P00${match.params.termsIdx}`).addClass('current');
    //                 $(`#list_a-P00${match.params.termsIdx}`).addClass('on');
    //                 const offsetTop = $(`#terms-P00${match.params.termsIdx}`).offset().top
    //                 $('html, body').animate({ scrollTop: offsetTop }, 1000) 
    //             }, 1000);
    //         } else if(match.params.termsIdx.length === 2) {
    //             setTermsIdx(`P0${match.params.termsIdx}`);
    //             setTimeout(() => {
    //                 const offsetTop = $(`#terms-P0${match.params.termsIdx}`).offset().top
    //                 $('html, body').animate({ scrollTop: offsetTop }, 1000) 
    //                 $(`#terms-P0${match.params.termsIdx}`).addClass('current');
    //                 $(`#list_a-P0${match.params.termsIdx}`).addClass('on');
    //             }, 1000);
    //         } else if(match.params.termsIdx.length === 3) {
    //             setTermsIdx(`P${match.params.termsIdx}`);
    //             setTimeout(() => {
    //                 const offsetTop = $(`#terms-P${match.params.termsIdx}`).offset().top
    //                 $('html, body').animate({ scrollTop: offsetTop }, 1000) 
    //                 $(`#terms-P${match.params.termsIdx}`).addClass('current');
    //                 $(`#list_a-P${match.params.termsIdx}`).addClass('on');
    //             }, 1000);
    //         } else {
    //             const offsetTop = $(`.content.my`).offset().top
    //             $('html, body').animate({ scrollTop: offsetTop }, 1000)
    //         }
    //     }
    // }, [match.params.termsIdx])

    // useEffect(() => {
    //     termsAgreeDetailInfo();
    // }, [termsIdx])

    const termsAgreeInfo = async() => {
        const termsAgreeData = await SohoApi.getTermsAgreeList({"bookmark": '1'})
        setTermsagree(termsAgreeData.data.list);
        console.log("setTermsagree", termsAgreeData.data.list);
    }
    // const termsAgreeNextInfo = async() => {
    //     const termsAgreeNextData = await SohoApi.getTermsAgreeList({"bookmark": `${nextPage}`})
    //     setTermsagree(termsagree => Array.from(termsagree).concat(termsAgreeNextData.data.list));
    //     setNextPage(nextPage + 1);
    //     console.log("setNextTermsagree", termsAgreeNextData.data.list);

    // }
    // const termsAgreeDetailInfo = async() => {
    //     const termsAgreeDetailData = await SohoApi.getTermsAgreeDetailList({ "prvsId": `${termsIdx}` });
    //     setTermsagreedetail(termsAgreeDetailData.data);
    //     console.log('setTermsagreedetail', termsAgreeDetailData.data);
    // }

    const termsdetailClick = (termsId) => {
        NativeBridge.sendUserActionLog('detail', 'D066', '', `${termsId}`);
        setTermsIdx(termsId);
        console.log(termsId)
        userData.setPolicyDetailList(termsId)
        history.push('/my/mysetting/policyaccess/termslist/detail')
    }

    const goBackPage = () => {
        history.goBack();
    }


    return (
        <>
			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>약관 보기</h2>
					</div>
				</div>
	            <div className="my_setting">
                    <div className="sec_wrap">
                        {/* { termsagree !== null &&
                            <InfiniteScroll
                                dataLength={termsagree.length}
                                next={termsAgreeNextInfo}
                                hasMore={hasMore}
                                scrollThreshold={'2px'}>
                                { termsagree.map((termsagreeItem, index) => (
                                    <div id={`terms-${termsagreeItem.prvsId}`} className="set_wrap usetap" key={`${termsagreeItem.prvsId}`} onClick={()=> termsdetailClick(termsagreeItem.prvsId)}>
                                            <span className="set_title">{termsagreeItem.prvsNm}</span>
                                            <span className="i_more"></span>
                                    </div>
                                ))
                                }
                            </InfiniteScroll>
                        } */}
                        { termsagree !== null ?
                            termsagree.map((termsagreeItem, index) => (
                                <div id={`terms-${termsagreeItem.prvsId}`} className="set_wrap usetap" key={`${termsagreeItem.prvsId}`} onClick={()=> termsdetailClick(termsagreeItem.prvsId)}>
                                        <span className="set_title">{termsagreeItem.prvsNm}</span>
                                        <span className="i_more"></span>
                                </div>
                            ))
                        :
                            <></>             
                        }
                    </div>

                </div>
			</div>
        </>
    )
    
});
export default MyPolicyDetail;