import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import { layerPopup, toast } from '../../libs/common';
import { isNullData, getMaskName, addHyphenPhoneNo, addHyphenMaskPhoneNo } from '../../common/common';
import { setUserDataStoreList } from './DefaultSet'

let inviteCnt = 0;
const FamilyAccountReg = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        NativeBridge.sendUserActionLog('second', 'I');
        getFamilyInviteList();
        $("html, body").removeClass("noscroll");

        // document.addEventListener('touchstart',function(e) {
		// 	var target = e.target;
		// 	if(target != document.querySelector('.i_tip')) {
		// 		$('.tooltip_box').removeClass('on');
		// 	}
		// })
    }, []);


    const getFamilyInviteList = async () => {
        const familyInviteList = await SohoApi.getFamilyInviteList();
        console.log(familyInviteList);
        const inviteList = familyInviteList.data.list;

        SessionStore.setInviceFamilyCheck();

        // 초대계정이 없으면 바로 이동 
        // if(inviteList.length === 0) {
        //     history.replace("/");
        //     return;
        // }  
        // inviteCnt = inviteList.length;

        let vaildInviteList = inviteList.reduce((prev, cur) => {
            // reqStus -> 0 : 초대 요청, 1 : 초대 수락, 2 : 초대 거부, 3 : 유효기간 만료 
            if(cur.reqStus === "0" || cur.reqStus === "3") {
                prev.push(cur);
            }
            if(cur.reqStus === "0") inviteCnt++;
            return prev;
        }, []);
        setDataList(vaildInviteList);
        layerPopup.show("#popup_family_account");

    }

    // const confirmInvite = async (event, bConfirm, ownerId, inviteType="M") => {

    //     console.log(bConfirm, ownerId, inviteType);

    //     const obj = event.currentTarget;

    //     let ret = null;
    //     if(bConfirm === "Y") {
    //         ret = await SohoApi.familyAccountConfirm({"ownerUserId": ownerId});
    //         toast("Family 계정 초대를 수락하였습니다.");
    //     } else {
    //         ret = await SohoApi.familyAccountReject({"ownerUserId": ownerId, "inviteType" : inviteType});
    //         toast("Family 계정 초대를 거절하였습니다.");
    //     }

    //     if(inviteType === "M") {
    //         obj.parentNode.parentNode.removeChild(obj.parentNode);
    //         inviteCnt--;

    //     // -- 전체 한번에 거부인경우 모두 제거
    //     } else {
    //         $(".modal_family_invite.fixed_bottom ul li").remove();
    //         inviteCnt = 0;
    //     }
        
    //     console.log(ret);
    // }


    const confirmInvite = async (event, bConfirm, ownerId, inviteType="M") => {

        console.log(bConfirm, ownerId, inviteType);
        const obj = event.currentTarget;

        // 개밸 처리
        if(inviteType === "M") {

            let ret = null;
            if(bConfirm === "Y") {
                ret = await SohoApi.familyAccountConfirm({"ownerUserId": ownerId});
                toast("초대계정으로 등록되었어요.");
            } else {
                ret = await SohoApi.familyAccountReject({"ownerUserId": ownerId, "inviteType" : inviteType});
                toast("계정 초대를 거절하였습니다.");
            }
    
            obj.parentNode.parentNode.removeChild(obj.parentNode);
            inviteCnt--;
            console.log(ret);
    
        // 일괄 거부
        } else {

            if(inviteCnt === 0) {
                toast("초대 리스트가 없습니다.");
                return;
            }

            layerPopup.showCommon("#popup_onetitlesentence_twobutton_familyaccount_reject", null, "초대 계정 거부", "초대 계정을 거부 하시겠습니까?<br />거부 이후 초대를 원하시면<br />초대 발신자에게<br />다시 한번 초대를 요청해주세요.", [
                function () {
                    try {
                        SohoApi.familyAccountReject({"ownerUserId": ownerId, "inviteType" : inviteType}).then((ret)=>{
                            $(".modal_family_invite.fixed_bottom ul li").remove();
                            inviteCnt = 0;
                            console.log(ret);
        
                            layerPopup.hide("#popup_onetitlesentence_twobutton_familyaccount_reject", () => {
                                closePage();
                            });
                        }).catch((e) => {
                            toast('초대 계정 거부 처리에 실패했습니다.');
                        })
                    } catch {
                        toast('초대 계정 거부 처리에 실패했습니다.');
                    }
                },
                function() {
                    layerPopup.hide("#popup_onetitlesentence_twobutton_familyaccount_reject");
                }
            ]);            

        }

    }


    const funcTooltip = (e, msg) => {
        e.stopPropagation()
        e.currentTarget.parentNode.querySelector(msg).classList.toggle("on");
    }

    const closePage = () => {
        console.log("close this page");

        // UI규격 1.3.0 : 체크 안하고 바로 넘김
        // if(inviteCnt > 0) {
        //     layerPopup.showCommon("#popup_onetitlebutton_threemsg", null, "알림", "Family 계정 초대에<br />수락 또는 거절 하지 않은 목록이<br />있습니다.", [
        //         function() {
        //             layerPopup.hide("#popup_onetitlebutton_threemsg");
        //         }
        //     ]);
        //     return;
        // }
        SohoApi.getFamilyInviteList({updateExpire : 'Y'});

        layerPopup.hide("#popup_family_account", async()=>{
            
            // 로그인할 사장님아이디가 설정되어 있는지 확인한다.
            const ownerId = LocalStore.getOwnerId();
            if(isNullData(ownerId)) {
                history.replace("/account/owner/select");
                return;
            }

            if(LocalStore.getStoreId()===null || LocalStore.getStoreList().length === 0) {
                history.replace("/account/store/reg");
                return;
            }

            try {
                await setUserDataStoreList();
            } catch(e) {
                console.log(e);
            }

            history.replace("/home");

        });        

    }

    return (
        <>
            <div className="modal fadeInUp" id="popup_family_account">
                <div className="dim"></div>
                <div className="modal_inner modal_up max_h">
                    <span className="modal_title">초대 계정</span>
                    <div className="modal_family_invite fixed_bottom">
                        <p className="info1">초대 계정으로 초대되었습니다.</p>
                        <p className="info2">초대 리스트를 수락해주세요.</p>
                        <ul>
                        {
                            dataList.length ?
                            dataList.map((item, index) => (
                            <>
                                <li key={`${item.ownerUserId}_${item.reqStus}`}>
                                    <span className="i_name">{getMaskName(item.ownerNm)}</span>
                                    <span className="i_num">{addHyphenMaskPhoneNo(item.ownerCtn)}</span>
                                    {item.reqStus === "0" &&
                                    <>
                                        <button type="button" className="btn_round2 red usetap" onClick={(e)=>confirmInvite(e, "N",item.ownerUserId)}>거절</button>
                                        <button type="button" className="btn_round2 green usetap" onClick={(e)=>confirmInvite(e, "Y",item.ownerUserId)}>수락</button>
                                    </>
                                    }
                                    { 
                                        item.reqStus === "3" &&
                                        <>  
                                            <button type="button" className="ico_ques usetap blind" onClick={(e)=> funcTooltip(e, '.tooltip_box')}>도움말</button>
                                            <div className="tooltip_box">
                                                <span className="top_arrow"></span>
                                                <p className="tip_content">초대를 원하시면 초대 발신자에게 다시 한번 초대를 요청해주세요.</p>
                                            </div>
                                            <button type="button" className="btn_round2 gray">초대만료</button>
                                        </>
                                    }

                                </li>

                            </>
                            ))
                            :
                            <div className="list_none_wrap">
                                <div className="middle_box">
                                    <p className="list_none">초대 리스트가 없습니다.</p>
                                </div>
                            </div>
                        }
                        </ul>
                    </div>
                </div>
                <div className="btn_box fixed">
                    <div className="inner">
                        <button type="button" className="btn_half btn_line2 usetap" onClick={(e)=> confirmInvite(e, "N",'', "A")}>초대 계정 거부</button>
                        <button type="button" className="btn_half btn_point usetap" onClick={()=> closePage()}>다음</button>
                    </div>
                </div>
            </div>
        </>
    )
    
});
export default FamilyAccountReg;