import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as NativeBridge from '../../../utils/NativeBridge';

const WeatherUnSub = observer(() => {

    const { userData } = store;

    const UnSubImg = userData.dataset.homecard?.filter(item => (item.svcCd === '000005'))

    const ExternalPage = (urls) => {
        NativeBridge.sendUserActionLog('menu', 'H006');
        NativeBridge.externalCall(`${urls}`);
    }

    return (
        <> 
            <div className="content_box">
                <span className="cont_title">{UnSubImg[0]?.svcCardNm}</span>
                <div className="sec_wrap unsubs_img">
                    <img src={`${UnSubImg[0]?.hposImge}`} />
                    <button type="button" onClick={()=>ExternalPage(UnSubImg[0]?.urlConn)}><img src={`${UnSubImg[0]?.urlConnImge}`} /></button>
                </div>
            </div>
        </>
    )
});

export default WeatherUnSub;