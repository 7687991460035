import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import { layerPopup } from "../../libs/common";
import { msgUI } from "../../libs/msg";
import {setCctvToken, setTempCctvToken} from '../../api/CctvApi';
import {setCenterexUserIdPass, setCentrexSsoKey} from '../../api/CentrexApi';

const SohoAuthErrorPopup = observer(({ history }) => {

    const { userInfo, userData } = store;
    const goLogin = () => {
        SessionStore.clear();
        LocalStore.clear();
        userInfo.initialize();
        userData.initialize();

        setCentrexSsoKey('','');
        setCenterexUserIdPass('','');
        setCctvToken('');
        setTempCctvToken('');

        layerPopup.hide("#popup_soho_auth_error_logout");
        history.replace("/");
    }

    return (
        <>
            <div className="modal fadeIn" id="popup_soho_auth_error_logout">
                <div className="dim"></div>
                <div className="modal_inner">
                    <div className="modal_content">
                        <div className="modal_hd">알림</div>
                        <div className="modal_bd">
                            <p>
                                인증오류 (9007)
                            </p>
                        </div>
                        <div className="modal_ft">
                            <button type="button" className="btn usetap" onClick={goLogin}><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    );
});
export default SohoAuthErrorPopup;