import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { floatUI } from '../../../../../libs/floating'
import { tabUi } from '../../../../../libs/tab'
import { layerPopup, toast } from "../../../../../libs/common"
import { transformAddressDrawData, getGroup } from "../../../../../common/common"
import * as CONSTS from "../../../../../const"
import { set } from "mobx";
import BizApi from "../../../../../api/BizApi";

//전화 설정 페이지
const GroupDetail = observer(({ history, match, location }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //const [groupId, setGroupId] = useState(""); 
    const [groupInfo, setGroupInfo] = useState({}); 

    const [phonebooklist, setPhoneBookList] = useState([]); 
    const [drawPhonebookList, setDrawData] = useState([]);

    //그룹코드
    const groupId = match.params.detailid;
    
    useEffect(() => {
		console.log('match.params', match.params); 
        console.log('location', location);

        //그룹상세정보
        setGroupInfo(location.state.item);
        getAddressListByGroup(groupId + "");

	}, []);

    useEffect(() => {
		console.log('change phonebook', phonebooklist); 
        setDrawData(transformAddressDrawData(phonebooklist));
	}, [phonebooklist]);
    
    const goBackPage = () => {
        history.goBack();
    }

    const groupEditClick = (e,subMenu='edit') => {
        NativeBridge.sendUserActionLog('detail', 'D027');
        console.log("detailId : " + groupId);
        history.push(
            {
                pathname: `/shop/call/phonebook/group/${groupId}/${subMenu}`
                ,state : {
                    item : groupInfo 
                }
            }
        );
    }

    const groupDelClick = (e) => {
        NativeBridge.sendUserActionLog('detail', 'D028');
        layerPopup.showCommon(
            '#popup_phonebook_group_delete', 
            null, 
            '그룹 삭제', 
            location.state.item.GROUP_NAME + '|그룹을 삭제하시겠어요?',
            [
                function() {
                    layerPopup.hide('#popup_phonebook_group_delete');
                },
                function() {
                    console.log('selected Item removeRecordFile =>');
                    layerPopup.hide('#popup_phonebook_group_delete');
					processRemoveGroup();
					
                }
            ]
        );
    }

    const processRemoveGroup = async () => {

        const result = await CentrexApi.removeAddressGroup({
            group_code : groupId
        });

        layerPopup.hide('#popup_phonebook_group_delete');
        if ( result.data.SVC_RT === '0000' ){
            toast('그룹이 삭제되었습니다.');
            history.replace('/shop/call/phonebook/group');
        }else if ( result.data.SVC_RT === '1111' ){
            toast('기본그룹은 삭제할 수 없습니다.');
        }else{
            // toast('그룹삭제가 실패하였습니다.(CODE:' + result.data.SVC_RT + ')');
            toast('그룹삭제가 실패하였습니다.');
        }
        
    }

    const getAddressListByGroup = async (groupId="") => {
        //get address from native
        /* NativeBridge.getAddressList(CONSTS.ADDRESS_TYPE_CENTRIX, groupId);	//그룹별 */
        //get address from centrix group
        //const addressData = await BizApi.GetCentrixPhonebookListByGroup(groupId, true);
        const addressData = await BizApi.GetCentrixPhonebookListByGroup(groupId, true);
        setPhoneBookList(addressData);
	}

    return (
    <>
        <div className="content store">
            <div className="page_title_wrap3 group">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                    <h2>그룹 상세</h2>
                    { 
                        groupId !== "0" ?
                        <>
                        <button type="button" className="btn_light_edit usetap blind" onClick={(e)=> groupEditClick(e,'edit')}>편집</button>
                        <button type="button" className="btn_light_del usetap blind" onClick={(e)=> groupDelClick(e)}>삭제</button>
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className="contact_group_detail">
                <div className="contact_info">
                    <div className="inner">
                        <div className="name_wrap">
                            <span className="group_name">{groupInfo.GROUP_NAME}</span>
                            <span className="group_num">{groupInfo.ADDRESS_CNT} 명</span>
                        </div>
                    </div>
                </div>
                <div className="contact_list_wrap">
                { 
                    drawPhonebookList.map((groupItem, gidx) => (
                        <>
                            <span className="devide_bar">{groupItem.title}</span>
                            {
                                (groupItem.items.length !== null || groupItem.items !== undefined ) ?
                                groupItem.items.map((addressItem, index) => (
                                    <>
                                        <button type="button" className="contact_list">
                                            <span className="i_name">{addressItem.name}</span>
                                        </button>
                                    </>
                                )) : 
                                <></>
                            }
                        </>
                    )) 
                }
                </div>	
            </div>
        </div>
    </>
  );
});
export default GroupDetail;