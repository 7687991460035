import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup, toast } from "../../libs/common";
import { getByteLength, defaultString, Base64 } from "../../common/common";
import CctvApi from "../../api/CctvApi";
import { CENTRIX_SVC_RT_SUCCESS } from "../../const";
import CentrexApi from "../../api/CentrexApi";
//import {decode} from 'base-64'

const MessageSetPopup = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    //console.log(userList);

    const [state, setState] = useState([]);
    const [previewImgData, setPreviewImgData] = useState("default"); // 파일 base64 (미리보기용)
    const [imgFile, setImgFile] = useState(null);	// 파일 base64
    const [selCamId, setSelCamId] = useState(null);    // 위생인증 카메라 object
    const [selCamOpenUrl, setSelCamOpenUrl] = useState(null);    // 위생인증 카메라 object
    const [isCamOpenUrl , setIsCamOpenUrl] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [lenCheck, setLenCheck] = useState("");
    const [serviceCheck, setServiceCheck] = useState("");

    useEffect(() => {
        /*
        if($('#exptCont').length > 0){
            text_length_check("exptCont_byte", "exptCont", 800 , "최대 입력 가능한 글자수를 초과하였습니다." );
        }
        if($('#exptCont2').length > 0){
            text_length_check("exptCont_byte2", "exptCont2", 800 , "최대 입력 가능한 글자수를 초과하였습니다." );
        }

        // TXT COUNT
        function text_length_check( input_len_id, contents_id, limit_byte, alt_txt ) {
            var $count_len 	= $("#" + input_len_id);
            var $contents 	= $("#" + contents_id);
            var max_byte 	= limit_byte;
            var alt_msg 	= alt_txt||"허용된 글자수가 초과되었습니다.";
            
            var update = function (){

                var cont_len = $contents.val().length;
                var len = 0;
                // getTextLength($contents.val())
                for (var i = 0; i <cont_len ; i++) {
                    if (escape($contents.val().charAt(i)).length == 6) {
                        len++;
                    }
                    len++;
                }
                //  console.log("444", len);
                setLenCheck(len);

                if (Number(len) > Number(max_byte)) {
                    //alert(alt_msg);
                    
                        // layerPopup.showCommon(
                        //     '#popup_onetitlemsgbutton', 
                            
                        //     null, 
                        //     '글자 수 초과', 
                        //     '400자 이내로 입력해주세요',
                            
                        //     [
                        //         function() {
                        //             layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                        //             //document.getElementsById("popup_onetitlemsgbutton")[0].style.display = "none";
                        //         },
                        //         // function() { // 확인 버튼
                                    
                        //         //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                                
                      
                        //         // }
                        //     ]
                        // )
                        //layerPopup.hide('#popup_onetitlemsgbutton');
                    var str2 = $contents.val().substr(0, max_byte);
                    $contents.val(str2);
                }
                //console.log("글자 확인",unicode.length);
                cont_len = len;
                var cont_len_txt = len.toString();
                $count_len.text(cont_len_txt.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")); //콤마
            };
        
            // input, keyup, paste
            $($contents).on('input keyup keydown paste change', function () {
                setTimeout(update, 0)
            });
            
            update();
          
        }
        */
    }, [])
    

    /**
     * effect : 해당 팝업은 앱 실행 최초에 import가 되므로 매장이 선택되면 화면구성을 위한 정보 설정값을 설정한다
     * 카메라정보 목록과 위생인증여부 체크
     **/
    useEffect(() => {
        console.log("nn")
        //위생인증 카메라 보유 유무
        setIsCamOpenUrl(isCamOpenUrl => 
            ( userData.dataset.currstoreInfo !== null && userData.dataset.currstoreInfo.cams !== null && userData.dataset.currstoreInfo.cams?.length > 0) ? 
                userData.dataset.currstoreInfo.cams.filter( (targetItem) => {
                    //console.log("zxczxc",targetItem)
                    if ( targetItem.service01 === 'Y') return targetItem;
                }).length > 0 
                :false
        );

        //카메라정보 설정
        setCameras(cameras => userData.dataset.currstoreInfo !== null && userData.dataset.currstoreInfo.cams !== null ? userData.dataset.currstoreInfo.cams : null);

        if ( userData.dataset.IsMessageSetPopup === 'E') {
            //수정의 경우 데이터 조회
            console.log("nnn");
            getStoreSetMessage();
            userData.setIsMessageSetPopup("Z");
        }else if ( userData.dataset.IsMessageSetPopup === 'C') {
            
            //수정의 경우 데이터 조회
            getStoreSetMessage();
            console.log("nnn222");
            $('#subject').val("");
            $('#exptCont').val("");
            $('#weburl').val("");
            //setPreviewImgData("default");
            setTitle("")
            setText("")
            setPreviewImgData("default");
            setImgFile(null);
            
            userData.setIsMessageSetPopup("Z");
        }

    }, [userData.dataset.IsMessageSetPopup])

    useEffect(() => {
        console.log("asdasd",title)
        if(defaultString(title,"") === "" || defaultString(text, "") === ""){
            $('#save').prop('disabled', true);
        }
        else{
            $('#save').prop('disabled', false);
        }

        const lenStr = getByteLength(text) + '';
        console.log(">>>>>change=>", lenStr);
        $('#exptCont_byte').text(lenStr.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

    },[title,text])

    const getStoreSetMessage = async () => {
        if(LocalStore.getIsTempUser() === false){
            const result = await CentrexApi.getMMSInfo();
            let img = null;
            console.log("22233")
            console.log(result);

            const reader = new FileReader();
            
            if ( result.data.SVC_RT === "0000"){
            
                // $('#subject').val(result.data.DATAS.SUBJECT);
                setTitle(result.data.DATAS.SUBJECT);
                // $('#exptCont').val(result.data.DATAS.MESSAGE);
                setText(result.data.DATAS.MESSAGE);
                if ( result.data.DATAS.IMGDATA ){
                    setPreviewImgData(result.data.DATAS.IMGDATA);
                    setImgFile(dataURLtoFile(result.data.DATAS.IMGDATA, "soho.jpg"));
                    // setImgFile(result.data.DATAS.IMGDATA);
                    //setImgFile(Buffer.from(result.data.DATAS.IMGDATA, "base64").toString('utf8'));
                }
                if ( result.data.DATAS.WEBURL ){
                    $('#weburl').val(result.data.DATAS.WEBURL); 
                    $("#popup_msgpreview_in .preview_url").text(result.data.DATAS.WEBURL);
                }
            }else{
                console.log("3344")
                $('#subject').val("");
                $('#exptCont').val("");
                $('#weburl').val("");
                $('#msgImg').val("");
                setTitle("")
                setText("")
                // setPreviewImgData("default");
            
            }
        }
    }

    const dataURLtoFile = (dataurl, fileName) => {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = Base64.atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});
    }
    const ChanageMsgImg = (e) => {
        //TODO : JPEG확장자 JPG로 변경해서 업로드 처리 필요
        console.log("ChanageMsgImg=>",e.target.files[0]);
        let fileDataReader = new FileReader();
        setImgFile(e.target.files[0]);

        fileDataReader.onload = function(evt) {
            console.log("fileDataReader=>" , fileDataReader.result);
            //previewImgData = fileDataReader.result;
            setPreviewImgData(fileDataReader.result);
            
        };
        fileDataReader.readAsDataURL(e.target.files[0]);
    };

    const ChangeMsgImgActionLog = () => {
        NativeBridge.sendUserActionLog('detail', 'D082');
    }

    const ClickDelFile = (e) => {

        layerPopup.showCommon(
            '#image_remove_popup', 
            null, 
            '첨부 이미지 삭제', 
            '첨부한 이미지를 삭제하시겠어요?',
            [
                function() {
                    layerPopup.hide('#image_remove_popup');//닫기
                },
                function() { // 확인 버튼
                    $('#msgImg').val("");
                    setPreviewImgData("default");
                    setImgFile(null);
                    layerPopup.hide('#image_remove_popup');//닫기
                }
            ]
        )
    }
    const selectCam2 = (e) => {
        let isService = e.target.value;
        setServiceCheck(isService);
        setSelCamOpenUrl(e.target.props.data.camopenurl);
    }

    const SelectCam = (e) => {
        
        const checked = document.querySelectorAll('input[name="msg_cctv"]:checked');

        if(Array.from(checked).length < 1){
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '알림', 
                '선택한 위생인증 카메라가 없습니다.'
            );
            return false;
        }

        var _camInfo = {}; 
        Array.from(checked).map((_roofItem, _idx) => {
			console.log('_roofItem=>', _roofItem);
			_camInfo = {
				camName: $(_roofItem).attr('camname')
				,camOpenUrl: $(_roofItem).attr('camopenurl')
				
			};
		});

        if ( text === null || text === ''){
            setText("\n\n[위생인증 CCTV]\n" + _camInfo.camOpenUrl);
        }else{
            setText(text=>text + "\n\n[위생인증 CCTV]\n" + _camInfo.camOpenUrl);
        }

        setSelCamId(_camInfo);
        layerPopup.hide("#popup_cams");
    }


const NativeCall = (msg) => {
    //   if(e.target.value === "(부가)통화연결음"){
    //     userData.setMyPageCallPopupData("(부가)통화연결음")
    //   }
    // console.log("zxczxc",e.target.value);
    userData.setMyPageCallPopupData("(부가)위생인증 CCTV")
      let menuCode = '';
    
      switch (msg) {
        case '1800-8000':
          layerPopup.show('#popup_ringregi');
          menuCode = 'P014'
          break;
        default:
          break;
      }
    }

    const PopupCamOpenUrl = async (e) => {
        console.log("GetCamOpenUrl",isCamOpenUrl);

        setSelCamId({});
        NativeBridge.sendUserActionLog('detail', 'D082');
        if ( isCamOpenUrl === true ) {
            $('input[name="msg_cctv"]').prop("checked", false);
            layerPopup.show("#popup_cams")
        }else{
            layerPopup.showCommon(
                '#popup_clean_cctv', 
                
                null, 
                '위생 인증 CCTV 선택 불가', 
                'U+ 오픈키친 서비스 가입 고객만 이용할 <br /> 수 있습니다.',
                
                [
                    function() {
                        layerPopup.hide('#popup_clean_cctv');//닫기
                    },
                    function() { // 확인 버튼
                        //goPageOnClick();
                        layerPopup.hide('#popup_clean_cctv')
                        setTimeout(() => {
                            NativeCall('1800-8000');;
                        }, 500)
                     
                     
                    }
                ]
            )
        }
       
    }

    const ClickSaveAction = async(e) => {

        var _title = defaultString($('#subject').val(), "");
        var _message = defaultString($('#exptCont').val(), "");
        var _weburl = defaultString($('#weburl').val(), "");

        if ( _title === null || _title === undefined || _title === ''){
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '알림', 
                '제목을 입력하세요.'
            );
                return;
            
        }

        if(_title.length > 20){
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                
                null, 
                '글자 수 초과', 
                '20자 이내로 입력해주세요',
                
                [
                    function() {
                        layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                        //document.getElementsById("popup_onetitlemsgbutton")[0].style.display = "none";
                    },
                    // function() { // 확인 버튼
                        
                    //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                    
          
                    // }
                ]
            )
            return;
        }

        if(_message === ''){
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '알림', 
                '내용을 입력하세요.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                    }
                ]
            )
            return;
        }


        if(getByteLength(_message) > 800){
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                
                null, 
                '글자 수 초과', 
                '400자 이내로 입력해주세요',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                        //document.getElementsById("popup_onetitlemsgbutton")[0].style.display = "none";
                    },
                    // function() { // 확인 버튼
                    //   layerPopup.hide('#popup_onetitle_twomsgbutton');//닫기
                    // }
                ]
            )
            return;
        }
        console.log("ㅋㅌㅊ", imgFile);

        var _param = {
            'subject' : _title
            ,'imgfile' : imgFile
            ,'weburl' : _weburl
            ,'message' : _message
        }

        const delRet = await CentrexApi.removeMMSInfo();
        if ( delRet.data.SVC_RT === '0000') {
            
            const ret = await CentrexApi.setMMSInfo(_param);
            if ( ret.data.SVC_RT === "0000"){
                let outString = _message.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                let outString2 = outString.replace(/\n/gi,'');
                NativeBridge.sendUserActionLog('detail', 'D083', '', outString2);
                const chgMmsInfo =CentrexApi.setChgMMSInfo({
                    mode : "apply"
                });
                console.log("101", chgMmsInfo)
                console.log('datas=>', ret.data.DATAS);
    
                userData.setIsPopupCloseForMsgSet(true);
                var _title = $('#subject').val();
                var _message = $('#exptCont').val();
                var _weburl = $('#weburl').val();
                setImgFile(null); // 이미지는 초기화
                setPreviewImgData("default");
                layerPopup.hide('#popup_msgset');
                toast("우리매장알림메시지가 설정되었습니다.");
                //getStoreSetMessage();
            }else{
                if ( ret.data.SVC_RT === "3001" && ret.data.DATAS.STATUS.indexOf("102400byte") > 0 ) {
                    layerPopup.showCommon(
                        '#popup_onetitlemsgbutton', 
                        null, 
                        '알림', 
                        '파일 용량(100Kbyte)을 초과하여 업로드 할 수 없습니다. 파일 용량 및 확장자(JPG) 확인 후 등록해주세요.'
                    );
                    //STATUS: "[ ERRMSG=첨부이미지는 102400byte(100K) 이하 jpg 파일만 등록 가능합니다.]"
                    return;
                }else{
                    layerPopup.showCommon(
                        '#popup_onetitlemsgbutton', 
                        null, 
                        '알림', 
                        '우리매장알림메시지 설정에 실패하였습니다.<br/>입력하신 내용을 다시 확인해 주세요.'
                    );

                }

                //TODO : 이전데이터 가지고 있다가 에러났을 때 다시 설정을 던진다
                    
            }
        }

        // const ret = await CentrexApi.setMMSInfo(_param);

        // if ( ret.data.SVC_RT === "0000"){
        //     const chgMmsInfo =CentrexApi.setChgMMSInfo({
        //         mode : "apply"
        //     });
        //     console.log("101", chgMmsInfo)
        //     console.log('datas=>', ret.data.DATAS);

        //     userData.setIsPopupCloseForMsgSet(true);
        //     var _title = $('#subject').val();
        //     var _message = $('#exptCont').val();
        //     var _weburl = $('#weburl').val();

        //     layerPopup.hide('#popup_msgset');
        //     getStoreSetMessage();
        // }else{
        //     if ( ret.data.SVC_RT === "3001" && lenCheck < 800 && _title.length < 50) {
        //         layerPopup.showCommon(
        //             '#popup_onetitlemsgbutton', 
        //             null, 
        //             '알림', 
        //             '파일 용량(100kByte)을 초과하여 업로드 할 수 없습니다. 파일 용량 확인 후 등록해주세요.'
        //         );
    
        //         return;
        //     }
        // }
    }
    const ClickClose = () => {
        layerPopup.hide("#popup_msgset")
        userData.setIsPopupCloseForMsgSet(true);
    }

    const titleInputValue = (e) => {
        console.log("aa",title);
        setTitle(e.target.value)
        //console.log("ㅇㅇㅇ")
    }
    const textInputValue = (e) => {
        //console.log("asdasd",e.target.value)
        setText(e.target.value);
        //getByteLength(e.target.value);
    }
    console.log("asd",cameras)

    const clear = () => {
        setTitle("")
    }
    const urlClear = () => {
        $('#weburl').val("");
    }
    const stateClear = () => {
        setTitle("")
        setImgFile(null);
        $('#weburl').val("");

    }

  return (
    <>
        <div className="modal fadeInUp" id="popup_msgset">
            <div className="dim" ></div>
            <div className="modal_inner modal_up full_h">
                <button type="button" className="pop_close usetap blind active" onClick={() => ClickClose()}>닫기</button>
                <span className="modal_title">메시지 설정</span>
                <div className="modal_msg_alarm">
                    <div className="sec_alarm">
                        <span className="title">제목</span>
                        <div className="input_box style2">
                            <input type="text" placeholder="제목을 입력해주세요." id="subject" maxLength={25} value={title} onChange={(e)=>titleInputValue(e)}/>
                            <span className="ip_clear hidden usetap" onClick={() => {clear()}}>초기화</span>
                        </div>
                    </div>
                    <div className="sec_alarm sec_alarm_img">
                        <span className="title">이미지 첨부<span className="i_opt">(선택)</span></span>
                        <div className="img_file_wrap" style={{display: (previewImgData !== 'default')?'none':'block'}}>
                            <input type="file" id="msgImg" accept="image/*" onClick={() => ChangeMsgImgActionLog()} onChange={(e)=> ChanageMsgImg(e) } />
                            <label htmlFor="msgImg" className="btn_round purple usetap">추가</label>
                            {/* <!-- <button type="button" className="btn_round purple del usetap">삭제</button> --> */}
                        </div>
                        <div className="img_file_wrap" style={{display: (previewImgData !== 'default')?'block':'none'}}>
                            <button type="button" className="btn_round purple del usetap" onClick={(e)=> ClickDelFile(e)}>삭제</button>
                        </div>
                        {
                            previewImgData !== 'default'  ? 
                            <>
                                <div className="img_wrap">
                                    <span className="preview_msg_img" style={{ backgroundImage: `url("${previewImgData}")` }}></span>
                                </div>
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div className="sec_alarm">
                        <span className="title">내용<span className="i_require">(필수)</span></span>
                        <span className="txt_count"><em id="exptCont_byte">0</em>/800 Byte</span>
                        <div className="textarea_wrap style1">
                            <textarea id="exptCont"  placeholder="가게에 전화를 건 고객에게 메시지로 &#13;&#10;가게를 소개해보세요!" value={text} onChange={(e)=>textInputValue(e)}></textarea>
                        </div>
                    </div>
                    <div className="sec_alarm sec_alarm_cctv">
                        <span className="title">위생인증 CCTV<span className="i_opt">(선택)</span></span>
                        <button type="button" className="btn_round purple usetap" onClick={(e)=> PopupCamOpenUrl(e)}>추가</button>
                        {
                            /*
                            selCamId === null ?
                            <>
                                <button type="button" className="btn_round purple usetap" onClick={(e)=> PopupCamOpenUrl(e)}>추가</button>
                            </>
                            :
                            <>
                                <button type="button" className="btn_round purple usetap" onClick={(e)=> PopupCamOpenUrl(e)}>재선택</button>
                            </>
                            */
                        }
                    </div>
                    <div className="sec_alarm">
                        <span className="title">홈페이지 입력<span className="i_opt">(선택)</span></span>
                                <div className="input_box style2">
                                    <input type="text" placeholder="우리가게 홈페이지 주소를 입력해 보세요!" id="weburl"/>
                                    <span className="ip_clear hidden usetap" onClick={urlClear}>초기화</span>
                                </div>
                            </div>
                            <div className="btn_box bottom_box">
                                <button type="button" className="btn_lg btn_point usetap" id="save" onClick={(e)=> ClickSaveAction(e)}>변경사항 저장</button>
                            </div>
                        </div>
                    </div>
            </div>

        <div className="modal fadeInUp" id="popup_cams" >
            <div className="dim" onClick={(e) => layerPopup.hide("#popup_cams")}></div>
            <div className="modal_inner modal_up">
                <button type="button" className="pop_close usetap blind" onClick={(e) => layerPopup.hide("#popup_cams")}>닫기</button>
                <span className="modal_title">위생인증 CCTV 선택</span>
                <p className="modal_desc">고객들에게 공개할 주방 CCTV를 선택해주세요.</p>
                <ul className="modal_radio_list2">
                    { 
                        cameras !== null ?
                        cameras?.filter((targetItem) => { 
                            if ( targetItem.camOpenUrl !== null && targetItem.camOpenUrl !== '' ) return targetItem; }).map( (item, idx) => (
                            <>
                                <li key={"cams_" + idx} {...{service01:item.service01 , camname:item.camName, camopenurl : item.camOpenUrl}}>
                                    <div className="radio_box">
                                        <input type="radio" id={"msg_cctv_" + idx} name="msg_cctv" value={item.camOpenUrl} {...{service01:item.service01 , camname:item.camName, camopenurl : item.camOpenUrl}}/>
                                        <label htmlFor={"msg_cctv_" + idx}>{item.camName}</label>
                                    </div>
                                </li>
                            </>
                        )) 
                         :
                         <></>
                    }
                </ul>
                <div className="btn_box bottom_box">
                    <button type="button" className="btn_lg btn_point usetap" onClick={(e) => SelectCam(e)}>완료</button>
                </div>
            </div>
        </div>
    </>
  );
});
export default MessageSetPopup;