import React from "react";
import { observer } from "mobx-react";
import store from "../../../store";

//전화 에러페이지
const CallLogError = observer(({ history }) => {

  const { userData } = store;
  
  // const goPageOnClick = () => {
  //   NativeBridge.externalCall("https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000060")
  // }

  const reSetClick = () => {
    userData.setCallPageReset(true);
    userData.setShopMainTabCallError(false);
  }

  return (
    <>  
	    <div className="fix_container">
          <div className="temporay_error">
            <p>일시 오류, 새로고침으로<br/>정보를 불러주세요!</p>
            <div className="ex_img">
                <img src="/res/images/img-mystore-call-error@3x.png" alt="통화기록 화면" />
                <button type="button" className="btn_refresh usetap blind" onClick={() => reSetClick()}>새로고침</button>
            </div>
          </div>
			</div>
    </>
  );
});

export default CallLogError;
