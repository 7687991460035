import { observer } from 'mobx-react'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import SohoApi from '../../../../api/SohoApi'
import { getSimpleDate, isDataCheck, isNullData, numberWithCommas } from '../../../../common/common'
import { layerPopup, toast } from '../../../../libs/common'
import { tabUi } from '../../../../libs/tab'
import * as CONSTS from '../../../../const/index'
import store from '../../../../store'

const OrderComplete = observer(({ history }) => {

    const { userData } = store;

    const [orderList, setOrderList] = useState([]);
    const [sortText, setSortText] = useState("최신순");
    const [todayText,setTodayText] = useState("오늘");
    const [date, setDate] = useState(moment().format('YYYY-MM-DD')); 
    const [num, setNum] = useState(0);
    const [num2, setNum2] = useState(0);
    const [sortFlag, setSortFlag] = useState(false);

    useEffect(() => {
        async function fetchOrderList() {
            getOrderListApi(date.replaceAll("-",""));
        }
        fetchOrderList();
    },[date])

    useEffect(() => {
        // getOrderListApi();
        lastedCheck();
    },[])

    useEffect(() => {
        if(num !== 0){
            prevData();
        }
    },[num])

    useEffect(() => {
        if(num2 !== 0){
            nextData();
        }
    },[num2])

    useEffect(() => {
        console.log("todayText",todayText);
    },[todayText])

    useEffect(() => {
        console.log("date",date)
    },[date])


    useEffect(() => {
        if(sortFlag){
            let sortOrderList = []
            if(sortText === "가격 높은순"){
                sortOrderList = orderList?.slice(0).sort((a, b) => {
                    return Number(b.totalPrice) - Number(a.totalPrice);
                })
                setOrderList(sortOrderList);
            } 
            if(sortText === "가격 낮은순"){
                sortOrderList = orderList?.slice(0).sort((a, b) => {
                    return Number(a.totalPrice) - Number(b.totalPrice);
                })
                setOrderList(sortOrderList);
            }
            
            if(sortText === "최신순"){
                let regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\ '\"\\(\=]/gi;
                sortOrderList = orderList?.slice(0).sort((a, b)=> {
                    return b.sysOrdDttm.replace(regExp, "").trim() - a.sysOrdDttm.replace(regExp, "").trim();
                })
                setOrderList(sortOrderList);
            }
            setSortFlag(false);
        }
    },[sortText,sortFlag])

    //날짜 빼기
    const prevData = () => {
        const today = moment();
        const prevDays = today.subtract(num, 'days');
        setTodayText(prevDays.format('YYYY-MM-DD')); //화면용도 
        setDate(prevDays.format('YYYY-MM-DD')); //데이터용도
    }

    const nextData = () => {
        if(date === moment().format('YYYY-MM-DD')) return;
        const today = moment(date);
        const nextDays = today.add(1, 'days');
        setTodayText(nextDays.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "오늘" : nextDays.format('YYYY-MM-DD'));
        setDate(nextDays.format('YYYY-MM-DD'));
    }

    const minus = () => {
        setNum(prev => prev + 1);
        setNum2(0);
    }

    const plus = () => {
        setNum2(prev => prev + 1);
        setNum(0)
    }

    //현재 체크된 것 확인
    const sortTextClick = () => {
        const name = document.getElementsByName("shopSelect");
        Array.from(name).forEach((items) => {
            if(items.checked){
                setSortText(items.value);
            }
        })
    }

    //default 최신순 체크
    const lastedCheck = () => {
        const name = document.getElementsByName("shopSelect");
        Array.from(name).forEach((items) => {
            if(items.value === "최신순"){
                items.checked = true;
            }
        })
    }

    //주문 list
    const getOrderListApi = async (param = "") => {
        try {
            await SohoApi.getOrderList({
                "storeId": userData.dataset.storeId,
                "ordDate": param === "" ? getSimpleDate() : param,
                "stus" : "Y"
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setOrderList(rst.data.orderList);
                    setSortFlag(true);
                }
            }).catch((e) => {
                toast('주문 목록을 가져오는데 실패했습니다.');
            })
        } catch (e) {
            toast('주문 목록을 가져오는데 실패했습니다.');
        }
    }


    const SortingClick = () => {
        layerPopup.show('#popup_sorting_select');
    }

    const SortingSelect = () => {
        sortTextClick();
        setSortFlag(true);
        layerPopup.hide('#popup_sorting_select');
    }

    
    

    return (
        <>
            {/* <!-- 처리완료 시 --> */}
            <div className="order_date">
                <button type="button" onClick={minus} className="date_prev usetap"><span className="blind2">이전</span></button>
                <span className="i_date">{todayText}</span>
                <button type="button" onClick={plus}className="date_next usetap"><span className="blind2">다음</span></button>
            </div>
            <div className="top_info">
                <span className="total_num">총 {orderList?.length}개</span>
                <button type="button" className="btn_range usetap" onClick={()=> SortingClick()}><i className="ico_range"></i>{sortText}</button>
            </div>
            <div className="order_list">
            { orderList?.length > 0 && isDataCheck(orderList) ?
                orderList?.map((items, idx) => (
                    items.stus === 'Y' &&
                    <div className="order_box" key={items.ordId}>
                        <span className="order_time">주문시간 {items.sysOrdDttm}</span>
                        <span className="tbl_num"><span className="i_num"># {items.tblNum}번</span> 테이블</span>
                        {items?.orderProductList?.map((item, idx) => (
                            <>
                                <span className="food_name">{item.prdtNm} {item.prdtCnt}개</span>
                                {item?.options?.options?.map((option) => (
                                    <span className="food_opt">{option?.optionDesc}</span>
                                ))}
                                {/* <span className="food_opt">사이즈 중 (+6,000원) / 공기밥 1개 (+1,000원) / 순한맛(추가요금 없음)</span> */}
                                {/* <span className="food_name">제육덮밥 1개</span>
                                <span className="food_opt">사이즈 중 (+6,000원) / 순한맛(추가요금 없음)</span> */}
                             </>
                        ))}
                        <div className="order_sum">
                            <span className="price_sum">{numberWithCommas(items.totalPrice)}원</span>
                        </div>
                    </div>
                ))
                    :
                    <div className="data_none">
                        <div className="none_wrap">
                            <span className="none_img"></span>
                            <p>씨식가에 아직 주문이 <br/>들어오지 않았어요.</p>
                        </div>
                    </div>
            }

                {/* <div className="order_box">
                    <span className="order_time">주문시간 13:10</span>
                    <span className="tbl_num"><span className="i_num"># 1번</span> 테이블</span>
                    <span className="food_name">얼큰 김치찌개 1개</span>
                    <span className="food_opt">사이즈 중 (+6,000원) / 공기밥 1개 (+1,000원) / 순한맛(추가요금 없음)</span>
                    <span className="food_name">제육덮밥 1개</span>
                    <span className="food_opt">사이즈 중 (+6,000원) / 순한맛(추가요금 없음)</span>
                    <div className="order_sum">
                        <span className="price_sum">52,000원</span>
                    </div>
                </div>
                <div className="order_box">
                    <span className="order_time">주문시간 13:10</span>
                    <span className="tbl_num"><span className="i_num"># 2번</span> 테이블</span>
                    <span className="food_name">얼큰 김치찌개 1개</span>
                    <span className="food_opt">사이즈 중 (+6,000원) / 공기밥 1개 (+1,000원) / 순한맛(추가요금 없음)</span>
                    <span className="food_name">제육덮밥 1개</span>
                    <span className="food_opt">사이즈 중 (+6,000원) / 순한맛(추가요금 없음)</span>
                    <div className="order_sum">
                        <span className="price_sum">52,000원</span>
                    </div>
                </div>
                <div className="order_box">
                    <span className="order_time">주문시간 13:10</span>
                    <span className="tbl_num"><span className="i_num"># 3번</span> 테이블</span>
                    <span className="food_name">얼큰 김치찌개 1개</span>
                    <span className="food_name">제육덮밥 1개</span>
                    <div className="order_sum">
                        <span className="price_sum">52,000원</span>
                    </div>
                </div> */}
            </div>
            {/* 매장 선택 팝업 */}
            <div className="modal fadeInUp" id="popup_sorting_select">
                <div className="dim" onClick={()=>layerPopup.hide('#popup_sorting_select')}></div>
                <div className="modal_inner modal_up">
                    <span className="modal_title">정렬 선택</span>
                    <ul className="modal_radio_list">
                        <li>
                            <div className="radio_box green">
                                <input type="radio" id='sort1' name="shopSelect" value="최신순" />
                                <label htmlFor='sort1' className="usetap"  >최신순</label>
                            </div>
                        </li>
                        <li>
                            <div className="radio_box green">
                                <input type="radio" id='sort2' name="shopSelect" value="가격 낮은순" />
                                <label htmlFor='sort2'  className="usetap">가격 낮은순</label>
                            </div>
                        </li>
                        <li>
                            <div className="radio_box green">
                                <input type="radio" id='sort3' name="shopSelect" value="가격 높은순"/>
                                <label htmlFor='sort3' className="usetap">가격 높은순</label>
                            </div>
                        </li>
                    </ul>
                    <div className="btn_box bottom_box">
                        <button type="button" className="btn_lg btn_point usetap" onClick={()=> SortingSelect()}>완료</button>
                    </div>
                </div>
            </div>
        </>
  )
})

export default OrderComplete;