import { Subject, throwError } from 'rxjs';
import { isIOS, isPC, getCurrentDateTimeString } from '../common/common';
import * as SessionStore from '../store/SessionStore';
import * as LocalStore from '../store/LocalStore';

const kmcUrl                = process.env.REACT_APP_KMC_URL;
const kmcCallbackUrl        = process.env.REACT_APP_KMC_CALLBACK_URL;
const zipSearchUrl          = process.env.REACT_APP_ZIPCODE_URL;
const zipSearchCallbackUrl  = process.env.REACT_APP_ZIPCODE_CALLBACK_URL;
const zipSearchAuthKey      = process.env.REACT_APP_ZIPCODE_AUTHKEY;
const zipSearchReturnType   = process.env.REACT_APP_ZIPCODE_RETURN_TYPE;

/* from native */
export const eventObservable = new Subject();
export function NativeInvoker(eventData) {
  // if(typeof(eventData) === "string") return;
  // const event = JSON.parse(eventData);
  const event = eventData;
  // if(typeof(eventData) === "string") {
  //   event = JSON.parse(eventData);
  // }
  // const event = JSON.parse(eventData);
  console.debug('onEvent(NativeInvoker)', event);
  eventObservable.next(event);
};
window.NativeInvoker = NativeInvoker;

// -- native Event TEST
/*
window.NativeInvoker(
  {
    "type" : "CALL_DEVICE_EVENT", 
    "param" : 
    {
      "action": "callbackmc",
      "detail" : {
        "userNm" : "홍길동",
        "userCi" : "asdf8sd7f897d9f8", 
        "userCtn" : "01012345678", 
        "userBday" : "19801201", 
        "pushRegi" : "1", 
        "sex" : "M"      
      }
    }
  }
);
*/





/* to native */
const iosProxy = (event) => {
  let rtn = "{}";
  try {
    // console.log(event);
    // alert(event)
    rtn = window.webkit.messageHandlers.sendEvent.postMessage(event);
  } catch(e) {
    rtn = "{\"type\":\"NO I/F\"}";
  }
  return rtn;  
}

class IosApis {
  constructor() {
    this.sendEvent = (event) => { return iosProxy(event); };
  }
}
let iOSInterface;
if (!isPC() && isIOS()) {
  iOSInterface = new IosApis();
}

class DummyInterface {
  constructor() {
    this.sendEvent = (event) => { return "{\"type\":\"NO I/F\"}"; };
  }
}
let noInterface = new DummyInterface();

const getNativeInterface = () => {
  if(!isPC()) {
    if (window.SohoInterface) {
      return window.SohoInterface;
    } else if (iOSInterface) {
      return iOSInterface;
    } else {
      return noInterface;
    }
  } else {
    return pcInterface;
  }
  return null;
}

export const sendEvent = (event) => {
  try {
    const nativeInterface = getNativeInterface();
    if (nativeInterface) {
      nativeInterface.sendEvent(JSON.stringify(event));
    }
    return true;
  } catch (ex) {
    return false;
  } finally {
    setNativeEventLog(event);
  }
}

export const initialSplashFinished = () => {
  const evt = { 
    "type" : "INITIAL_SPLASH_FINISHED"
  };
  sendEvent(evt);
  // try {
  //   const nativeInterface = getNativeInterface();
  //   let ret = null;
  //   if (nativeInterface) {
  //     ret = JSON.parse(nativeInterface.sendEvent(JSON.stringify(evt)));
  //   }
  //   return ret;
  // } catch (ex) {
  //   return false;
  // } finally {
  //   setNativeEventLog(evt);
  // }    
}

export const callPhone = (phoneNo) => {
  const evt = { 
    "type": "CALL_PHONE",
    "param": {
      "ctn": phoneNo,
     }
  };
  sendEvent(evt);
  // try {
  //   const nativeInterface = getNativeInterface();
  //   if (nativeInterface) {
  //     nativeInterface.sendEvent(JSON.stringify(evt));
  //   }
  //   return true;
  // } catch (ex) {
  //   return false;
  // } finally {
  //   setNativeEventLog(evt);
  // }
}



/* test

GET_DEVICE_INFO
const deviceInfo = window.SohoInterface.sendEvent("{\"type\":\"GET_DEVICE_INFO\",\"param\":{\"info_type\":\"devModel, os, ctn, network, ip_addr, mac, tel_info, iccid, sim_operation, serial_no, imei, carrier_type, version\"}}");
console.log(deviceInfo);

CALL_PHONE
window.SohoInterface.sendEvent("{\"type\":\"CALL_PHONE\",\"param\":{\"ctn\":\"15440001\"}}");

CALLBACK_KMC
window.SohoInterface.sendEvent("{\"type\":\"CALLBACK_KMC\",\"param\":{\"rec_cert\":\"aaa\"}}");

GO_PAGE
window.SohoInterface.sendEvent("{\"type\":\"GO_PAGE\",\"param\":{\"type\":\"I\", \"url\":\"https://www.google.com\"}}");
window.SohoInterface.sendEvent("{\"type\":\"GO_PAGE\",\"param\":{\"type\":\"O\", \"url\":\"https://www.google.com\"}}");

EXTERNAL_CALL
window.SohoInterface.sendEvent("{\"type\":\"EXTERNAL_CALL\",\"param\":{\"type\":\"2\", \"url\":\"https://www.google.com\"}}");

LOADING_START
window.SohoInterface.sendEvent("{\"type\":\"LOADING_START\",\"param\":{\"type\":\"T\", \"message\":\"메시지문구\n내용확인\"}}");

LOADING_END
window.SohoInterface.sendEvent("{\"type\":\"LOADING_END\"}");

GET_ZIPCODE
window.SohoInterface.sendEvent("{\"type\":\"CALLBACK_KMC\",\"param\":{\"roadAddrPart1\":\"서울특별시 강서구\", \"addrDetail\":\"공항대로 191\", \"roadAddrPart2\":\"\"}}");

// -- pc 테스트용

-- 주소입력
window.NativeInvoker({
  type:"GET_ZIPCODE",
  param:{
    roadAddrPart1:"서울특별시 강서구",
    addrDetail: "공항대로 191",
    roadAddrPart2: ""
  }
});

-- 다른앱위에 표시 설정
window.NativeInvoker({
  type:"GET_CALL_MEMO_POPUP",
  param:{
    status : "0"
  }
});
window.NativeInvoker({
  type:"GET_CALL_MEMO_POPUP",
  param:{
    status : "1"
  }
});


- 푸시
1. type : N
window.NativeInvoker({
  type:"PUSH_LAND",
  param:{
    type: "N",
    data: {
      "LINK_ID":"211003AWNOTI000019",
      "LINK_TYPE":"N",
      "URL_IMG":"http://sohostgadm.uplus.co.kr/img/noti/201203103010301.jpg",
      "URL":"http://www.naver.com"
    },
    isRunning: "Y"
  }
});

1. type : B
window.NativeInvoker({
  type:"PUSH_LAND",
  param:{
    type: "B",
    data: {
      "LINK_ID":"211003AWBNIN000024",
      "LINK_TYPE":"B",
      "LINK_CATG":"210916AWCAIN000006",
      "URL_IMG":"http://sohostgadm.uplus.co.kr/img/noti/201203103010301.jpg",
      "URL":"http://www.naver.com"
    },
    isRunning: "Y"
  }
});


bio 인증 변경 테스트
window.NativeInvoker({
  type:"SET_BIOMETRICS",
  param:{
    status: "3"
  }
});

*/

export const getDeviceInfo = () => {
  const evt = { 
    "type" : "GET_DEVICE_INFO", 
    "param": {
        "info_type":"dev_model, os, ctn, network, ip_addr, mac, tel_info, iccid, sim_operation, serial_no, imei, carrier_type, version"
    }
  };
  return sendEvent(evt);
  // try {
  //   const nativeInterface = getNativeInterface();
  //   let ret = null;
  //   if (nativeInterface) {
  //     ret = JSON.parse(nativeInterface.sendEvent(JSON.stringify(evt)));
  //   }
  //   return ret;
  // } catch (ex) {
  //   return false;
  // } finally {
  //   setNativeEventLog(evt);
  // }    
}

export const regBiometrics = () => {
  const evt = { 
    "type" : "CALL_BIOMETRICS", 
    "param": {
        "req_type":"reg",
        "type":null
    } 
  };
  return sendEvent(evt);

  // const nativeInterface = getNativeInterface();
  // let ret = null;
  // if (nativeInterface) {
  //   ret = JSON.parse(nativeInterface.sendEvent(JSON.stringify(evt)));
  // }
  // return ret;  
}
export const checkBiometrics = (bio_type) => {
  const evt = { 
    "type" : "CALL_BIOMETRICS", 
    "param": {
        "req_type":"req",
        "type":bio_type
    } 
  };
  return sendEvent(evt);
  // const nativeInterface = getNativeInterface();
  // let ret = null;
  // if (nativeInterface) {
  //   ret = JSON.parse(nativeInterface.sendEvent(JSON.stringify(evt)));
  // }
  // return ret;  
}



/**
 * KMC연동
 * //tr_close_use : 닫기버튼노출예외처리 , tr_close_link : 닫기버튼클릭시 연결URL
    
 * @param tr_cert 
 * @returns 
 */
export const callKmc = (tr_cert=null) => {
  if(tr_cert === null) return;
  const evt = { 
    "type": "CALL_KMC",
    "param": {
        "url": kmcUrl,
        "post_data": `tr_cert=${tr_cert}&tr_url=${kmcCallbackUrl}&tr_add=N&tr_close_use=N`
    }
  };

  if(isPC()) {
    try {

      const reqKMCISForm = document.createElement("form");
      reqKMCISForm.setAttribute("charset", "UTF-8");
      reqKMCISForm.setAttribute("method", "post"); 

      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "tr_cert");
      hiddenField.setAttribute("value", tr_cert);
      reqKMCISForm.appendChild(hiddenField);

      const hiddenField2 = document.createElement("input");
      hiddenField2.setAttribute("type", "hidden");
      hiddenField2.setAttribute("name", "tr_url");
      hiddenField2.setAttribute("value", kmcCallbackUrl);
      reqKMCISForm.appendChild(hiddenField2);

      const hiddenField3 = document.createElement("input");
      hiddenField3.setAttribute("type", "hidden");
      hiddenField3.setAttribute("name", "tr_add");
      hiddenField3.setAttribute("value", "N");
      reqKMCISForm.appendChild(hiddenField3);


      document.body.appendChild(reqKMCISForm);


      console.log("kmc form method : post");
      console.log("kmc form tr_cert : ", tr_cert);
      console.log("kmc form tr_url : ", kmcCallbackUrl);
      console.log("kmc form action_url : ", kmcUrl);

      const KMCIS_window = window.open("", "KMCISWindow", "width=425, height=550, resizable=0, Scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250");
      reqKMCISForm.target = "KMCISWindow";
      reqKMCISForm.action = kmcUrl;
      reqKMCISForm.submit();

      setTimeout(()=> {
        document.body.removeChild(reqKMCISForm);
      }, 3000);


      //test용 콜백 처리 (네이티브->메인웹뷰 로 데이터 전달 이벤트 :: 3초후에 자동 호출) -------------- 테스트용 - 시작
      // setTimeout(() => {
      //   const userData = {
      //     type:"GET_KMC",
      //     param:{
      //       rec_cert:"ASDF1234ASDF12341234OIUJOIKJ",
      //     }
      //   };
      //   window.NativeInvoker(userData);
      // }, 3000);
      //test용 콜백 처리 (네이티브->메인웹뷰 로 데이터 전달 이벤트 :: 3초후에 자동 호출) -------------- 테스트용 - 끝




      return true;
    } catch (ex) {
      return false;
    } finally {
      setNativeEventLog(evt);
    }
  } else {
    return sendEvent(evt);
      // const nativeInterface = getNativeInterface();
      // if (nativeInterface) {
      //   nativeInterface.sendEvent(JSON.stringify(evt));
      // }
  }


}



export const callbackKmc = (rec_cert=null) => {
  if(rec_cert === null) return;
  const evt = { 
    "type": "CALLBACK_KMC",
    "param": {
      "rec_cert": rec_cert
    }
  };
  return sendEvent(evt);
  // const nativeInterface = getNativeInterface();
  // let ret = null;
  // if (nativeInterface) {
  //   ret = JSON.parse(nativeInterface.sendEvent(JSON.stringify(evt)));
  // }
  // return ret;  

}

/*
type : 'I' or 'O'
*/
export const goPage = (url=null, reqId="" , type="O") => {
  if(url === null) return;
  const evt = { 
    "type" : "GO_PAGE", 
    "param": {
        "type": type,
        "url": url,
        "reqId":reqId
    }
  };
  return sendEvent(evt);
}

export const externalCall = (url=null, type="2") => {
  if(url === null) return;
  const evt = { 
    "type" : "EXTERNAL_CALL", 
    "param": {
        "type":type,
        "value": url
    }
  };
  return sendEvent(evt);
  // const nativeInterface = getNativeInterface();
  // let ret = null;
  // if (nativeInterface) {
  //   ret = JSON.parse(nativeInterface.sendEvent(JSON.stringify(evt)));
  // }
  // return ret;  

}

/*
DATAS = {
  "SESSID" : "",
  "SSOHASH" : "",
  "REMOTE_ADDR" : "",
  "CHKTOKEN" : ""
}
*/
let oldSessId = "";
let oldSsoHash = "";
let oldInetNo = "";
let oldInetCredit = "";
export const setCentrexSsoKeyToNative = (SESSID='', SSOHASH='', INETNO='', INETCREDIT='') => {
  if(!INETNO) return;

  // 이전에 세팅한값과 동일한 값이 오면 무시한다.
  if(oldSessId === SESSID && oldSsoHash === SSOHASH && oldInetNo === INETNO && oldInetCredit === INETCREDIT) return;
  
  oldSessId = SESSID;
  oldSsoHash = SSOHASH;
  oldInetNo = INETNO;
  oldInetCredit = INETCREDIT;

  const evt = { 
    "type" : "SET_SSO_KEY", 
    "param": {
      "sessid" : SESSID, 
      "ssoHash" : SSOHASH,  
      "storeInetNo" : INETNO, 
      "storeCreditional" : INETCREDIT 
    }
  };
  return sendEvent(evt);
}

export const callZipCode = () => {
  const evt = { 
    "type": "CALL_ZIPCODE",
    "param": {
        "url": zipSearchUrl,
        "post_data": `confmKey=${zipSearchAuthKey}&returnUrl=${zipSearchCallbackUrl}&resultType=${zipSearchReturnType}`
    }
  };

  if(isPC()) {
    try {

      const reqZipForm = document.createElement("form");
      reqZipForm.setAttribute("charset", "UTF-8");
      reqZipForm.setAttribute("method", "post"); 

      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "confmKey");
      hiddenField.setAttribute("value", zipSearchAuthKey);
      reqZipForm.appendChild(hiddenField);

      const hiddenField2 = document.createElement("input");
      hiddenField2.setAttribute("type", "hidden");
      hiddenField2.setAttribute("name", "returnUrl");
      hiddenField2.setAttribute("value", zipSearchCallbackUrl);
      reqZipForm.appendChild(hiddenField2);

      const hiddenField3 = document.createElement("input");
      hiddenField3.setAttribute("type", "hidden");
      hiddenField3.setAttribute("name", "resultType");
      hiddenField3.setAttribute("value", zipSearchReturnType);
      reqZipForm.appendChild(hiddenField3);

      document.body.appendChild(reqZipForm);

      console.log("kmc form method : post");
      console.log("kmc form confmKey : ", zipSearchAuthKey);
      console.log("kmc form returnUrl : ", zipSearchCallbackUrl);
      console.log("kmc form resultType : ", zipSearchReturnType);

      const KMCIS_window = window.open("", "ZIPSEARCHWindow", "width=425, height=550, resizable=0, Scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250");
      reqZipForm.target = "ZIPSEARCHWindow";
      reqZipForm.action = zipSearchUrl;
      reqZipForm.submit();

      setTimeout(()=> {
        document.body.removeChild(reqZipForm);
      }, 3000);


      //test용 콜백 처리 (네이티브->메인웹뷰 로 데이터 전달 이벤트 :: 3초후에 자동 호출) -------------- 테스트용 - 시작
      // setTimeout(() => {
      //   const userData = {
      //     type:"GET_KMC",
      //     param:{
      //       rec_cert:"ASDF1234ASDF12341234OIUJOIKJ",
      //     }
      //   };
      //   window.NativeInvoker(userData);
      // }, 3000);
      //test용 콜백 처리 (네이티브->메인웹뷰 로 데이터 전달 이벤트 :: 3초후에 자동 호출) -------------- 테스트용 - 끝




      return true;
    } catch (ex) {
      return false;
    } finally {
      setNativeEventLog(evt);
    }
  } else {
    return sendEvent(evt);
      // const nativeInterface = getNativeInterface();
      // if (nativeInterface) {
      //   nativeInterface.sendEvent(JSON.stringify(evt));
      // }
  }


}




// export const setNative = (IF_NAME, param = '') => {
//     const evt = { 
//         func: 'setNative',
//         interface: IF_NAME,
//         param : param 
//     };
//     console.debug(evt);
//     try {
//       const nativeInterface = getNativeInterface();
//       if (nativeInterface) {
//         nativeInterface.setNative(IF_NAME, param);
//       }
//       return true;
//     } catch (ex) {
//       return false;
//     } finally {
//       setNativeEventLog(evt);
//     }
// };

// export const getNative = (IF_NAME, param = '') => {
//   const evt = { 
//       func: 'getNative',
//       interface: IF_NAME,
//       param : param 
//   };
//   console.debug(evt);
//   try {
//     const nativeInterface = getNativeInterface();
//     if (nativeInterface) {
//       return nativeInterface.getNative(IF_NAME, param);
//     } else {
//       return '';
//     }
//   } catch (ex) {
//     return '';
//   } finally {
//     setNativeEventLog(evt);
//   }
// };

// export const hideNative = (IF_NAME) => {
//     const evt = {
//         func: 'hide',
//         interface: IF_NAME
//     };
//     console.debug(evt);
//     try {
//       const nativeInterface = getNativeInterface();
//       if (nativeInterface) {
//         nativeInterface.hide(IF_NAME);
//       }
//       return true;
//     } catch (ex) {
//       return false;
//     } finally {
//       setNativeEventLog(evt);
//     }
// };

// export const showNative = (IF_NAME, param1 = '', param2 = '') => {
//     const evt = { 
//         func: 'showNative',
//         interface: IF_NAME,
//         param1 : param1,
//         param2 : param2,
//     };
//     console.debug(evt);
//     try {
//       const nativeInterface = getNativeInterface();
//       if (nativeInterface) {
//         nativeInterface.showNative(IF_NAME, param1, param2);
//       }
//       return true;
//     } catch (ex) {
//       return false;
//     } finally {
//       setNativeEventLog(evt);
//     }
// }
// export const stopNative = (IF_NAME, param = '') => {
//   const evt = { 
//       func: 'stopNative',
//       interface: IF_NAME,
//       param : param 
//   };
//   console.debug(evt);
//   try {
//     const nativeInterface = getNativeInterface();
//     if (nativeInterface) {
//       nativeInterface.stopNative(IF_NAME, param);
//     }
//     return true;
//   } catch (ex) {
//     return false;
//   } finally {
//     setNativeEventLog(evt);
//   }
// }
// export const goBackPage = () => {
//     const evt = {
//         func: 'goBackPage'
//     };
//     console.debug(evt);
//     try {
//       const nativeInterface = getNativeInterface();
//       if (nativeInterface) {
//         nativeInterface.goBackPage();
//       }
//       return true;
//     } catch (ex) {
//       return false;
//     } finally {
//         setNativeEventLog(evt);
//     }
// };



//==================================================================
// 전화번호 관련
//------------------------------------------------------------------
/*
export const getGroupList = () => {
  try {
    const nativeInterface = getNativeInterface();
    if (nativeInterface) {
      nativeInterface.shutdownApp();
    }
  } catch (ex) {
  } finally {
    setNativeEventLog("shutdownApp");
  }
};
*/

export const shutdownApp = () => {
  const evt = { 
    "type" : "CALL_SHUTDOWN_APP"
  };
  return sendEvent(evt);
};

/**
 * 전화번호 조회
 * @param {*} type ( centrix / device )
 * @param {*} groupCode 
 * @returns 
 */
export const getAddressList = (type="centrix", groupCode="") => {
  const evt = { 
    "type" : "GET_ADDRESS_LIST", 
    "param": {
        "group_code": groupCode,
        "type": type
    }
  };
  return sendEvent(evt);
}

/**
 * 전화번호 등록
 * @param {*} params 
 * @returns 
 */
export const setAddressInfo = (params = {}) => {
  const evt = { 
    "type" : "CALL_ADDRESS", 
    "param": params
  };
  return sendEvent(evt);
}

/**
 * 전화번호 수정
 * @param {*} addressInfos 
 * @returns 
 */
export const modifyAddressInfo = (addressInfos = []) => {
  const evt = { 
    "type" : "CALL_ADDRESS_EDIT", 
    "param": addressInfos
  };
  return sendEvent(evt);
}

/**
 * 전화번호 삭제
 * @param {*} addressInfos 
 * @returns 
 */
export const removeAddressInfo = (addressInfo) => {
  const evt = { 
    "type" : "CALL_ADDRESS_DEL", 
    "param": addressInfo
  };
  return sendEvent(evt);
}


//------------------------------------------------------------------


//==================================================================
// 녹취파일 관련
//------------------------------------------------------------------

export const downloadRecordFile = (files = "", url = "") => {
  const evt = { 
    "type" : "CALL_RECODE_DN", 
    "param": {
      "files": files
      ,"url": url
    }
  };

  return sendEvent(evt);
}

export const getRecordfileDnList = () => {
  const evt = { 
    "type" : "GET_RECODE_DN_LIST"
  };

  return sendEvent(evt);
}

export const playRecordFile = (file = "") => {
  const evt = { 
    "type" : "CALL_PLAY_RECODE_FILE"
    ,param : {
      "file" : file
    }
  };

  return sendEvent(evt);
}



//------------------------------------------------------------------

//==================================================================
// 통화연결음 관련
//------------------------------------------------------------------
export const getRingMusicPlay = (speaker, bgfile, text) => {
  
  if(text === []){
    text = ""
  }
  
  
  const evt = {
    "type" : "RING_MUSIC_PLAY",
    "param": {
      "speaker" : speaker !== "" || speaker !== [] ? speaker : "0",
      "bgfile" : bgfile,
      "text" : text !== ""  ? text : "안녕하십니까 U+ 소호서비스 가게입니다. " 
    }
  };
  return sendEvent(evt);
}


//------------------------------------------------------------------


//==================================================================
// 알림 설정 관련
//------------------------------------------------------------------

//푸쉬 알람 설정 조회
export const getAlarm = () => {
  const evt = {
    "type" : "GET_ALARM"
  };
  return sendEvent(evt);
}
//푸쉬 알람 설정 요청
export const setAlarm = (data) => {
  const evt = {
    "type" : "SET_ALARM",
    "param" : {
      "type" : data
    }
  }
  return sendEvent(evt)
}

//------------------------------------------------------------------

//==================================================================
// 통화 메모 설정 관련
//------------------------------------------------------------------

//발신자 메모 확인 설정 요청
export const setCallMemoPopup = (data) => {
  const evt = {
    "type" : "SET_CALL_MEMO_POPUP", 
    "param": {
        "type": data
}

  };
  return sendEvent(evt);
}

//발신자 메모 확인 설정 조회
export const getCallMemoPopup = (data) => {
  const evt = {
    "type" : "GET_CALL_MEMO_POPUP", 
    "param": {
        "type": data
}

  };
  return sendEvent(evt);
}
//------------------------------------------------------------------

//==================================================================
// 녹취파일관련
//------------------------------------------------------------------

//녹취파일 다운로드 내역
export const getDownloadedFileForRecord = (_files) => {
  const evt = {
    "type" : "GET_RECODE_DN_LIST",
    "param" : {
      "files" : _files
    }
  };
  return sendEvent(evt);
}

//녹취파일 다운로드 요청
export const requestDownloadRecord = (_files, _url) => {
  const evt = {
    "type" : "CALL_RECODE_DN",
    "param" : {
      "files" : _files
      ,"url" : _url
    }
  }
  return sendEvent(evt)
}

//------------------------------------------------------------------

//녹취파일 다운로드 요청
export const getCallMemoList = () => {
  const evt = {
    "type" : "GET_ADDRESS_MEMO"
  }
  return sendEvent(evt)
}


//==================================================================
// 로딩관련
//------------------------------------------------------------------

//로딩 스타트
export const getLoadingStart = () => {
  const evt = {
    "type" : "LOADING_START",
    "param" : {
      "type" : "I",
      "message" : ""
    }

  };
  return sendEvent(evt);
}

export const getLoadingStartMsg = (message="") => {
  const evt = {
    "type" : "LOADING_START",
    "param" : {
      "type" : "T",
      "message" : message
    }

  };
  return sendEvent(evt);
}

//로딩 완료
export const getLodingEnd = () => {
  const evt = {
    "type" : "LOADING_END"
  };
  
  return sendEvent(evt);
};


export const sendUserActionLog = (actionType="", actionDetail1="", msg = "", actionDetail2 = "") => {
  const loadingTime = getCurrentDateTimeString();
  let bdpLoadDttm = '';
  if (actionType === 'gnb' && msg === 'end') {
    bdpLoadDttm = getCurrentDateTimeString();
  } 
//   else if (actionType === 'message') {
//     bdpLoadDttm = msg;
//   }
  const evt = {
    "type" : "USER_ACTION_LOG",
    "param" : {
      "USER_TYPE" : LocalStore.getIsTempUser() === true ? "J" : LocalStore.getIsTempUser() === false ? 'T' : 'U' ,
      "USER_ID" : LocalStore.getUserId() ,
      "STORE_ID" : LocalStore.getStoreId(), 
      "FAMILY_YN" : LocalStore.getFamilyYn(), 
      "ACTION_TYPE" : actionType,
      "ACTION_DETAIL1" : actionDetail1,
      "ACTION_DETAIL2" : actionDetail2,
      "BDP_LOAD_DTTM" : bdpLoadDttm,
      "TOKEN" : SessionStore.getSohoToken()
      ,"RSP_TIME" : ""
      ,"VIEW_CURR" : ""
      ,"VIEW_CURR_DTL" : ""
      ,"ACTION_DETAIL3" : ""
      ,"ACTION_DETAIL4" : ""
      ,"ACTION_DETAIL5" : ""
      ,"ACTION_DETAIL6" : ""
      ,"ACTION_DETAIL7" : ""
      ,"R1" : ""
      ,"R2" : ""
      ,"R3" : ""
      ,"R4" : ""
      ,"R5" : ""
    }
  };
  return sendEvent(evt);
}

//------------------------------------------------------------------

const setNativeEventLog = (evt) => {
  console.log('------------------------ setNativeEventLog ----------------------');
  console.log("setNativeEventLog: " , evt);
};

// export const writeActionLog2 = (actionStart, actionTarget, actionTargetDtl, viewCurr, viewCurrDtl, actionDtl1, actionDtl2, actionDtl3, actionDtl4, actionDtl5, R1, R2, R3, R4, R5) => {
//   const evt = { 
//       func: 'writeActionLog2',
//       interface: '',
//       actionStart,
//       actionTarget,
//       actionTargetDtl,
//       viewCurr,
//       viewCurrDtl,
//       actionDtl1,
//       actionDtl2,
//       actionDtl3,
//       actionDtl4,
//       actionDtl5,
//       R1,
//       R2,
//       R3,
//       R4,
//       R5
//   };
//   console.debug(evt);
//   try {
//     const nativeInterface = getNativeInterface();
//     if (nativeInterface) {
//       nativeInterface.writeActionLog(actionStart, actionTarget, actionTargetDtl, viewCurr, viewCurrDtl, actionDtl1, actionDtl2, actionDtl3, actionDtl4, actionDtl5, R1, R2, R3, R4, R5);
//     }
//   } catch (ex) {
//   } finally {
//     setNativeEventLog(evt);
//   }
// }



// export const vibrate = (msec = 50, repeat=0) => {

//   try {
//     const nativeInterface = getNativeInterface();
//     if (nativeInterface) {
//       nativeInterface.vibrate(msec);
//       if(repeat > 0) {
//         let repeatTimer = setInterval(function() {
//           nativeInterface.vibrate(msec);
//           repeat--;
//           if(repeat < 1) clearInterval(repeatTimer);
//         }, msec + 100);
//       }
//     }
//   } catch (ex) {
//   } finally {
//     setNativeEventLog("vibrate msec : " + msec + ", repeat: "+ repeat);
//   }

// };

// export const showToast = (msg = 'toast message', sec = 1) => {
//   try {
//     const nativeInterface = getNativeInterface();
//     if (nativeInterface) {
//       nativeInterface.showToast(msg, sec);
//     }
//   } catch (ex) {
//   } finally {
//     setNativeEventLog("showToast msg : " + msg + ", sec: "+ sec);
//   }

// };


// export const shutdownApp = () => {
//   try {
//     const nativeInterface = getNativeInterface();
//     if (nativeInterface) {
//       nativeInterface.shutdownApp();
//     }
//   } catch (ex) {
//   } finally {
//     setNativeEventLog("shutdownApp");
//   }
// };








//------------------------------------------------------------------- pc test 용
const pcInterface = {
  sendEvent: function(param) {
    const Data = JSON.parse(param);
  
    console.log("========== Native Interface Data :: pc Emul Interface ==============", Data);
    console.log(Data);



    if(Data.type === "SET_SSO_KEY") {
      return;
    }


    if(Data.type === "CALL_PHONE") {
      return;
    }

    if(Data.type === "GET_DEVICE_INFO") {
      const ret = {
        "type" : Data.type, 
        "param": {
            app_name: "U+SOHO"
            ,carrier_type: "S"
            ,ctn: "01022332233"
            ,ctn_info: "01022332233"
            ,dev_model: "PC"
            ,iccid: ""
            ,imei: ""
            ,ip_addr: "0.0.0.0"
            ,mac_info: ""
            ,network: "WIFI"
            ,os: "11"
            ,os_type: "android"
            ,push_key: "dDkjAJduRseRAnNTh6Mlig:APA91bHs-Jy3FfX6xxv1VFFiK21FJA4CAmho2AI2Um9Wjf_CO_8ztI88pC3U7FkGWgAhrzgNdF5PJHGW2384cUSZYLBM_bU4DSnJ4SIk3YSVdbp6mxsot6GjI4OdBXn_JyQc3kJTy_Gi"
            ,serial_no: "unknown"
            ,sim_operator: "45005"
            ,version: "0.5.0"
        }        
      }
      console.log("res : " , ret);
      window.NativeInvoker(ret);

      return JSON.stringify(ret);
    }



    /* 
     * type : 0 : 지문, 1: 안면인식
     * status : 0 : 등록 완료, 1 : 등록 실패, 2 : 인증 완료, 3 : 인증 실패
    */
    if(Data.type === "CALL_BIOMETRICS") {

      let ret = "";

      // 인증 확인
      if(Data.param.type !== null) {

        // ret = {
        //   "type" : Data.type, 
        //   "param": {
        //     "status":"2",
        //     "type": Data.param.type
        //   }
        // }

        const bioLayer = document.createElement("div");
        bioLayer.style.width = "auto";
        bioLayer.style.height = "auto";
        bioLayer.style.position = "fixed";
        bioLayer.style.margin = "auto";
        bioLayer.style.width = "100px";
        bioLayer.style.height = "100px";
        bioLayer.style.border = "1px solid darkgray";
        bioLayer.style.borderRadius = "20px";
        bioLayer.style.left = "50%";
        Data.param.type === "0" ? bioLayer.style.bottom = "100px" : bioLayer.style.top = "100px";
        bioLayer.style.backgroundColor = "white";
        bioLayer.style.transform = "translate(-50%, 0)";
        if(Data.param.type === "1") {
          bioLayer.style.backgroundImage = "url(https://e7.pngegg.com/pngimages/876/162/png-clipart-facial-recognition-system-computer-icons-reconnaissance-text-emoticon.png)";
        } else {
          bioLayer.style.backgroundImage = "url(http://www.pngall.com/wp-content/uploads/2016/06/Fingerprint-Free-Download-PNG.png)";
        }
        bioLayer.style.backgroundSize = "contain";
        bioLayer.style.backgroundPosition = "center";
        bioLayer.style.backgroundRepeat = "no-repeat";
        bioLayer.innerHTML = "&nbsp;";
        bioLayer.onclick = function(e) {
          window.sessionStorage.setItem("userid", window.localStorage.getItem("userid"));
          this.parentNode.removeChild(this);
          // event fire

          const userData = {
            type:"CALL_BIOMETRICS",
            param:{
              status:"0",
              type: Data.param.type
            }
          };
          window.NativeInvoker(userData);
        }
        document.body.appendChild(bioLayer);

      // 인증 등록 
      } else {
        // ret = {
        //   "type" : Data.type, 
        //   "param": {
        //     "status":"0",
        //     "type": Data.param.type !== null ? Data.param.type  : "1"
        //   }
        // }

        const userData = {
            type:"CALL_BIOMETRICS",
            param:{
            status:"0",
            type: "1"                }
        };
        window.NativeInvoker(userData);

      }
      
      console.log("res : " , ret);
      return JSON.stringify(ret);

    }

    /*
     * 네이비브 새로운 웹뷰(kmc창) 에서 kmc 인증 결과값을 네이티브에 전달
    */
    if(Data.type === "CALLBACK_KMC") {
      const ret = {
        "type": Data.type,
        "param": {
            "rec_cert": Data.param.rec_cert
        }
      }
      console.log("res : " , ret);
      return JSON.stringify(ret);

    }


    if(Data.type === "LOADING_START" || Data.type === "LOADING_END") {
      const ret = {
        "type": Data.type         
      }
      console.log("res : " , ret);
      return JSON.stringify(ret);
    }

    /*
     * 외부 브라우저로 url 호출
    */
        if(Data.type === "EXTERNAL_CALL" || Data.type === "GO_PAGE") {
          console.log("req : " , Data.type, ":", Data.param);
          const ret = {
            "type": Data.type,
            "param": {
                "url": Data.param.value
            }
          }
          
          const ext_window = window.open(Data.param.value, "EXTWindow", "width=425, height=550, resizable=0, Scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250");

          console.log("res : " , ret);
          return JSON.stringify(ret);
    
        }



      /*
      */
      if(Data.type === "INITIAL_SPLASH_FINISHED") {
        const ret = {
          "type": Data.type         
        }
        console.log("res : " , ret);
        return JSON.stringify(ret);
  
      }

      if(Data.type === "RING_MUSIC_PLAY") {
        const ret = {
          "type" : Data.type,
          "param": {
            "speaker" : Data.param.speaker,
            "bgfile" : Data.param.bgfile,
            "text" : Data.param.text
        }
      }
      console.log("res : " , ret);
      return JSON.stringify(ret);
    }

    if(Data.type === "GET_ALARM") {
      const ret = {
        "type": Data.type         
      }
      console.log("res : " , ret);

      
    

      return JSON.stringify(ret);

    }

    if(Data.type === "SET_CALL_MEMO_POPUP"){
      const ret = {
        "type" : Data.type,
        "param": {
          "status" : Data.param
        }
      }
      console.log("res : " , ret);

      const userData = {
        "type" : "SET_CALL_MEMO_POPUP",
        "param": {
          "status" : "0"

        }
      }

      window.NativeInvoker(userData);

      return JSON.stringify(ret);


    }

    if(Data.type === "GET_ADDRESS_MEMO") {
      const ret = {
        "type": Data.type         
      }
      console.log("res : " , ret);

      const userData = {
        "type" : "GET_ADDRESS_LIST", 
        "param": {
            "status":"0"
            ,"addresses" : [
              { category : 'ㅅ'
                , list : [
                {
                  "seq":"1"
                  , "name":"소호상인1"
                  , "phone": "010-1234-1234,010-1233-1111"
                  , "peer_no" : ""
                  , "groups" : 
                    [ {
                        "group_code":"G001"
                        ,"group_name":"사장님들"
                    }, {
                        "group_code":"G002"
                        ,"group_name":"기타"
                    }]
              },
              {
                  "seq":"2"
                  , "name":"소호상인2"
                  , "phone":"010-1111-2222"
                  , "peer_no" : "9828"
                  , "groups" : 
                    [ {
                        "group_code":"G002"
                        ,"group_name":"기타"
                    }]
              }
            ]
          }
        ]
    }
      };
      window.NativeInvoker(userData);
    

      return JSON.stringify(ret);

    }
  }
}

