import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import SohoApi from '../../../api/SohoApi';
import * as LocalStore from '../../../store/LocalStore'
import 'moment/locale/ko';
import moment from 'moment';
import * as CONSTS from '../../../const/index'

const Weather = observer(({ svcCardNm }) => {

    const { userData } = store;

	const [retCode, setRetCode] = useState('0000');
    const [weatherInfo, setWeatherInfo] = useState([]);

    useEffect(() => {
        async function fetchWeatherInfo() {
            await WeatherInfo();
        }
        if(userData.dataset.procChgStoreComplete) {
            fetchWeatherInfo();
        }
    }, [userData.dataset.procChgStoreComplete])

    useEffect(() => {
        async function fetchWeatherInfo() {
            await WeatherInfo();
            userData.setHomeWeatherChFlag(false);
        }
        if(userData.dataset.homeWeatherFlag) {
            fetchWeatherInfo();
        }
    }, [userData.dataset.homeWeatherFlag])
 
    const Dates = () => {       
        const nowTime = moment().format("HHmm");
        const nextDateday = moment().add(1,"days").format('ddd');
        const doubleDateday = moment().add(2,"days").format('ddd');

        return { nowTime: nowTime, nextday: nextDateday, doubleday: doubleDateday };
    }

    const WeatherInfo = async() => {
        try {
            await SohoApi.getWeatherInfo({
                'storeId': LocalStore.getStoreId()
            }).then((rst)=> {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setWeatherInfo(rst.data);
                }
                setRetCode(rst.retCode)
            }).catch((e) => {
                console.log(e);
                setRetCode(null);
            })
        } catch {
            setRetCode(null);
        }
    }

    return (
        <> 
            <div className="content_box">
                { ((typeof(weatherInfo) !== 'undefined' && weatherInfo !== null) &&
                    (typeof(weatherInfo?.toList) !== 'undefined' && typeof(weatherInfo?.tmrList) !== 'undefined' && typeof(weatherInfo?.aftTmrList) !== 'undefined') &&
                    (weatherInfo?.toList !== null && weatherInfo?.tmrList !== null && weatherInfo?.aftTmrList !== null) && (retCode !== null && retCode === '0000')) ?
                    <>
                        <span className="cont_title">{svcCardNm}</span>
                        <div className="sec_wrap sec_weather">
                            { weatherInfo.cntn?.indexOf('\n') !== -1 ?
                                <>
                                    <strong className="st_msg" 
                                            dangerouslySetInnerHTML={ {__html: weatherInfo.toList.cntn?.replace('\n', '<br />')} } />
                                </>
                            : weatherInfo.cntn?.indexOf('\r\n') !== -1 ?
                                <>
                                    <strong className="st_msg" 
                                            dangerouslySetInnerHTML={ {__html: weatherInfo.toList.cntn?.replace('\r\n', '<br />')} } />
                                </>
                            :                                 
                                <>
                                    <strong className="st_msg">{weatherInfo.toList.cntn}</strong>
                                </>
                            }
                            <div className="today_area">
                                { weatherInfo.toList.addr === "" ?
                                    <span className="info_locate">
                                        <i className="ico_locate blind"></i>
                                        위치 미설정
                                        <span className="verti_bar" />
                                        이촌로
                                    </span>
                                :
                                    <span className="info_locate">
                                        <i className="ico_locate blind"></i>
                                        가게 위치
                                        <span className="verti_bar" />
                                        {weatherInfo.toList.addr}
                                    </span>
                                }
                                <div className="now_temp">
                                    <span className="info_now">{weatherInfo.toList.tmp}</span>
                                </div>
                                <span className={"ico_precip" + (` rain` + `${parseInt(weatherInfo.toList.pop) < 10 ? '0' : weatherInfo.toList.pop.replace(/^(.)\d/,"$10")}`)}>{weatherInfo.toList.pop + '%'}</span>
                                <ul className="info_temp">
                                    <li>최저 <span className="num_temp" style={{ textAlign: 'right' }}>{weatherInfo.toList.tmn.replace('.0', '')}</span></li>
                                    <li>최고 <span className="num_temp" style={{ textAlign: 'right' }}>{weatherInfo.toList.tmx.replace('.0', '')}</span></li>
                                </ul>
                                    {/* 클래스 정의
                                    wt1 : 맑음
                                    wt2 : 흐림
                                    wt3 : 구름
                                    wt4 : 비
                                    wt5 : 눈
                                    wt6 : 천둥
                                    wt7 : 안개
                                    wt8 : 소나기
                                    wt9 : 황사 */}
                                <span className={"ico_weather" 
                                                    + (weatherInfo.toList.sky === '1' ? ' wt1'
                                                    : weatherInfo.tmrList.sky === '1' && weatherInfo.tmrList.pty === '4' ? ' wt8'
                                                    : weatherInfo.toList.sky === '3' ? ' wt3'
                                                    : weatherInfo.toList.sky === '4' ? ' wt2'
                                                    : weatherInfo.toList.pty === '1' ? ' wt4' 
                                                    : weatherInfo.toList.pty === '3' ? ' wt5' 
                                                    : <></>)}>       
                                </span>
                            </div>
                            <ol className="weekly_area">
                                <li>
                                    <div className="weekly_title">
                                        <span className="title1">{Dates().nextday}</span>
                                        <span className="title2">내일</span>
                                    </div>
                                    <span className={"ico_precip" + (` rain` +  `${parseInt(weatherInfo.tmrList.pop) < 10 ? '0' : weatherInfo.tmrList.pop.replace(/^(.)\d/,"$10")}`)}>{weatherInfo.tmrList.pop + '%'}</span>
                                    <span className={"info_condition" 
                                                     + (weatherInfo.toList.sky === '1' ? ' wt1'
                                                     : weatherInfo.tmrList.sky === '1' && weatherInfo.tmrList.pty === '4' ? ' wt8'
                                                     : weatherInfo.toList.sky === '3' ? ' wt3'
                                                     : weatherInfo.toList.sky === '4' ? ' wt2'
                                                     : weatherInfo.toList.pty === '1' ? ' wt4' 
                                                     : weatherInfo.toList.pty === '3' ? ' wt5' 
                                                     : <></>)}>       
                                    </span>
                                    <ul className="info_temp">
                                        <li><span className="num_temp">{weatherInfo.tmrList.tmn.replace('.0', '')}</span></li>
                                        <li><span className="num_temp">{weatherInfo.tmrList.tmx.replace('.0', '')}</span></li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="weekly_title">
                                        <span className="title1">{Dates().doubleday}</span>
                                        <span className="title2">모레</span>
                                    </div>
                                    <span className={"ico_precip" + (` rain` +  `${parseInt(weatherInfo.aftTmrList.pop) < 10 ? '0' : weatherInfo.aftTmrList.pop.replace(/^(.)\d/,"$10")   }`)}>{weatherInfo.aftTmrList.pop + '%'}</span>
                                    <span className={"info_condition" 
                                                    + (weatherInfo.toList.sky === '1' ? ' wt1'
                                                    : weatherInfo.tmrList.sky === '1' && weatherInfo.tmrList.pty === '4' ? ' wt8'
                                                    : weatherInfo.toList.sky === '3' ? ' wt3'
                                                    : weatherInfo.toList.sky === '4' ? ' wt2'
                                                    : weatherInfo.toList.pty === '1' ? ' wt4' 
                                                    : weatherInfo.toList.pty === '3' ? ' wt5' 
                                                    : <></>)}>       
                                    </span>
                                    <ul className="info_temp">
                                        <li><span className="num_temp">{weatherInfo.aftTmrList.tmn.replace('.0', '')}</span></li>
                                        <li><span className="num_temp">{weatherInfo.aftTmrList.tmx.replace('.0', '')}</span></li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </>
                :
                    <>
                        <span className="cont_title">{svcCardNm}</span>
                        <div className="sec_wrap">
                            <strong className="st_msg">일시 오류, 새로고침으로 <br />정보를 불러주세요!</strong>
                            <div className="ex_img row">
                                <img src={`/res/images/img-home-weather-card-error@3x.png`} alt="날씨별 전략 예시" />
                                <button type="button" className="btn_refresh usetap blind" onClick={()=> WeatherInfo()}>새로고침</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
});

export default Weather;