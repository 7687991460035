import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as NativeBridge from "../../../utils/NativeBridge";


const ShopAnalyze = observer(() => {
    const { userInfo, userData } = store;

    const ShopAnalyze = () => {
        NativeBridge.sendUserActionLog('menu', 'H007');
        userData.setShopMainTabList('shopanalyze');
    }

    return (
        <>
            <div className="content_box">
                    <div className="sec_wrap bn_wrap">
                        <Link to="/shop/shopanalyze" onClick={()=> ShopAnalyze()}>
                            <img src="/res/images/img-banner2@3x.png" alt="" />
                        </Link>
                    </div>

            </div>
        </>
    )
    
});
export default ShopAnalyze;