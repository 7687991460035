import { webUI } from './common'

export const btnStUi = (function() {
	let lastScrollTop = 0;
	let bfocusvent; 
    return {
		"msgScroll" : function() {
			if (bfocusvent) {
				return;				
			}
			let container = document.getElementById('btn_floating_msg');
			webUI.debounce(function() {
				let st = window.scrollY;
				if (st > lastScrollTop && Math.abs(st - lastScrollTop) > 5){
					container.classList.remove('down');
				} else if (st < lastScrollTop && Math.abs(st - lastScrollTop) > 5){
					container.classList.add('down');
				}
				lastScrollTop = st;
			}, 100);
		}
    }
})();

export const btnStUi2 = (function() {
	let lastScrollTop = 0;
	let bfocusvent; 
    return {
		"csScroll" : function() {
			if (bfocusvent) {
				return;				
			}
			let container = document.getElementById('floating_cs');
			webUI.debounce(function() {
				let st = window.scrollY;
				if (st > lastScrollTop && Math.abs(st - lastScrollTop) > 5){
					container.classList.remove('down');
				} else if (st < lastScrollTop && Math.abs(st - lastScrollTop) > 5){
					container.classList.add('down');
				}
				lastScrollTop = st;
			}, 100);
		}
    }
})();