import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { floatUI } from '../../../../../libs/floating'
import { tabUi } from '../../../../../libs/tab'
import { sortHanFirstObjectList, sortHanWordsList } from "../../../../../common/common";

//전화 설정 페이지
const Group = observer(({ history }) => {

	const { userInfo, userData } = store;
	const userList = userInfo.getList();
	console.log(userList);

	const [addressGroupList, setAddressGroupList] = useState([]); 

	useEffect(() => {
		tabUi.init3DepthTab(document.getElementById('tab_style4'));
		tabUi.init3DepthTab(document.getElementById('floating_tab2'));
		tabUi.init3DepthTab(document.getElementById('memo_cate'));

		getAddressGroupList();
		
	}, []);

	const getAddressGroupList = async () => {
        const result = await CentrexApi.getAddressGroupList(); 

		console.log("그룹목록조회 ===>", result);
        if(result.data.SVC_RT === "0000"){
			if(Array.isArray(result.data.DATAS) && result.data.DATAS.length > 0) {
				setAddressGroupList(sortGroupList(result.data.DATAS));
			}
		}
	};
	
	const sortGroupList = (list) => {
		let rst = sortHanWordsList(list, 'GROUP_NAME', 'ASC');
		let noGroup = null;
		for (let i = rst.length; i--;) { 
			if (rst[i].GROUP_CODE === 0) { 
				noGroup = rst.splice(i, 1); 
				break;
			} 
		}
		if(noGroup) {
			rst.unshift(noGroup[0]);
		}
		return rst;
	}

  	const GroupAddClick = (subMenu='add') => {
		NativeBridge.sendUserActionLog('detail', 'D026');
		history.push(`/shop/call/phonebook/group/${subMenu}`)
	}

	const GroupDetailClick = (subMenu='detail', groupItem) => {
		history.push(
			{
				pathname:`/shop/call/phonebook/group/${groupItem.GROUP_CODE}/detail`
				,state : {
					item : groupItem 
				}
			}
		);
	}

  	return (
    <>
		{
				/*
		<div className="contact_cate">
			
				<div className="btn_cate_wrap2" id="memo_cate">
				<button type="button" className="tab_item active">인터넷 전화 주소록</button>
				<button type="button" className="tab_item">휴대폰 주소록</button>
				</div>
			
			
			<button type="button" className="btn_refresh_txt usetap" onClick={()=>getAddressGroupList()}><i className="ico_refresh"></i>새로고침</button>
				
			}
		</div>
		*/
		}
		<div className="contact_group floating_bottom">
			<ul className="group_list">
				{ (addressGroupList.length !== null || addressGroupList !== undefined ) ?
					addressGroupList.map((groupItem, index) => (
						<>
							<li key={groupItem.GROUP_CODE}>
								<button type="button" onClick={()=>GroupDetailClick('detail', groupItem)}>
									<span className="group_name">{groupItem.GROUP_NAME}</span>
									<span className="group_num">{groupItem.ADDRESS_CNT}명</span>
								</button>
							</li>
						</>
					))
					:
					<></>
				}
			</ul>
			<div className="btn_wrap">
				<button type="button" className="btn_round purple usetap" onClick={()=>GroupAddClick('add')}>그룹추가</button>
			</div>
		</div>
    </>
  );
});
export default Group;