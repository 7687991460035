import { observable } from 'mobx';
import * as LocalStore from './LocalStore';
//import * as SessionStore from './SessionStore';

const initialDataSet = {
    sA: "",
    bA: false,
    aA: [],
    nA: 0,
    homecard: [],
    cctvauthFlag: false,
    tempcctvauthFlag: false,
    // 우리 가게 전화 탭 파라미터
    currCallPage: "calllog",
    shopMainTab: "cctv",
    homeShopMainTab: "",
    phoneBookTap: "phonebook",
    shopMsgLogTab: 'msgset',
    msgPhoneBookGroupTap: 'phonebook',
    phonebookCnt: '',
    phonebooklist : [],                         //전화번호 주소록(centrix, device)
    pblistForCentrix : [],                      //전화번호 centrix
    pblistForDevice : [],                       //전화번호 device
    callmemoCnt: '',
    groupCnt: '',
    recordFileCnt: '',
    workerCnt: '0',
    workerList: [],
    detectionCnt: '',
    noticeTab: 'eventnotice',
    memberShipNum: '',
    bannerListData: [],
    currstoreInfo: null, //현재(마지막) 선택된 스토어정보
    storeList: [],
    storeId: "",      
    storeNm: "",                                //가게 이름
    storeInetNo: "",                            //가게 전화번호
    interworkCheck : "",                        //전화 & 메세지 연동 후 화면 렌더링에 사용
    interworkCheck2:"",                         //지능형 cctv 연동 후 화면 렌더링에 사용
    storeCamIdList: [],
    policyAccessDetail: "",
    thumbnail: [],
    splitThumbnail: [],
    camRuleEventList: [],
    camEventList: [],
    camEventTotalCnt: null,
    blacklist : [],                             //수신차단 목록
    StoreNmIndex : 0,
    fowardingData : "",                         //착신전환 on/off 여부 N : off, F : on
    receiveBlockData : "",                      //수신차단 현재설정 모드
    alramSetData : "",                          //알람 설정 on/off 여부  1 : off, 0 : on
    callMemoData : "",                          //통화메모 설정 on/off 여부 1 : off, 0 : on
    byDayTimeCall : "",                         //요일별, 시간대별 통화연결음 생성 시 타임아웃 변경
    messagePopupCellNum : {},                   //메시지 작성시 보내기 위한 전화번호(전화상세에서 메시지 선택시)
    messagePopupConts : "",
    popSelectedSendMsgGroup : [],
    popSelectedSendMsgPhone : [],
    popSelectAddressAndPhone : false,           //팝업닫기
    popFlag : '',
    homepopupData : null,
    homeFloatBannerDown : false,
    accountList : null,
    IsMessageSetPopup : 'E',
    addressSyncFlag : '',                       //주소록 싱크 완료 플래그
    IsPopupCloseForMsgSet : false,               //우리가게 알림서비스 팝업 닫기 ( 리플래시 플래그 )
    messageWritePopupClose : false,              //메시지발송 팝업 닫기 플래그(전화 > 메시지탭 리플래쉬 용도)
    myPageCall : "",                              //마이페이지에서 가입하기 누를 시 팝업창 타이틀 종류마다 변경 용도
    msgCheck : "",                                 //메세지 발신 시 화면 리플래쉬 용도
    sendMsgAddrPopupToggle : false,             //메시지 작성시 연락처/그룹 선택 플래그
    sendMsgGroupPopupToggle : false,            //메시지 작성시 연락처/그룹 선택 플래그
    bgCheck : false,                             //배경음 초기화 용도
    bnfCateId: "",
    bnfId : "",
    callPageReset : false,                               //리셋버튼 용도
    passFindPopupFlag :  false,                     //연동관리 인터넷전화 비밀번호 찾기 초기화 용도
    callLoginFlag : false,                          //연동관리 인터넷 전화 로그린 팝업 초기화 용도
    phoneBookEditFlag : false,                      //연락처 수정 -> 상세로 이동할 때 수정한 값 업데이트 
    procChgStoreComplete: false,
    flagGetAddress : '',                         //연락처 호출
    phoneMainTab : 'centrix',                       //폰주소록 탭 유지용
    shopMainTabCallError : false,
    shopMainTabMsgError : false,
    callMemoFlag : false,                           //연락처 상세에서 통화메모 수정 후 -> 통화메모 리스트 값 업데이트 
    centrexTempAuthFlag : false,                       //임시사용자 인터넷 전화 연동 후 사용자 정보 인증 실패 시 최초 한번만 요청
    centrexTempAuthFlag2 : false,                       //임시사용자 에러코드 19xx 일 경우 띄우는 서버오류 팝업 최초 한번만 
    centrexTempAuthFlag3 : false,                          //임시사용자 경우 인터넷전화 auth 에러일 경우 홈 -> 인터넷 전화 에러화면 노출
    homeChFlag : false,
    homeWorkerFlag : false,
    homeWeatherFlag : false,
    sisicMainTab : 'order',
    orderTap : 'neworder',
    sissicgaRegiYn : '',
    newOrderLength : '0'
}

const userData = observable({
    dataset : initialDataSet,
    initialize() { 
        this.dataset = initialDataSet;
    },
    setList(data) {
        this.dataset = data;
    },
    getList() {
        return this.dataset;
    },
    setStringA(data) {
        this.setList({
            ...this.dataset,
            sA : data
        })
    },
    setHomePopupData(data) {
        this.setList({
            ...this.dataset,
            homepopupData : data
        })
    },
    setFloatPopupDown(data) {
        this.setList({
            ...this.dataset,
            homeFloatBannerDown : data
        })
    },
    setCctvAuthFlag(data) {
        this.setList({
            ...this.dataset,
            cctvauthFlag : data
        })
    },
    setTempCctvAuthFlag(data) {
        this.setList({
            ...this.dataset,
            tempcctvauthFlag : data
        })
    },
    setHomeList(data, bUpdate=false) {
        this.setList({
            ...this.dataset,
            homecard : data
        });
        LocalStore.setHomeCard(LocalStore.getStoreId(), data, bUpdate);
    },
    getHomeList() {
        return this.dataset.homecard;
    },
    setCallTabList(data) {
        this.setList({
            ...this.dataset,
            currCallPage : data
        })
    },
    setShopMainTabList(data) {
        this.setList({
            ...this.dataset,
            shopMainTab : data
        })
    },
    setHomeShopMainTabList(data) {
        this.setList({
            ...this.dataset,
            homeShopMainTab : data
        })
    },
    setPhoneBookTabList(data) {
        this.setList({
            ...this.dataset,
            phoneBookTap : data
        })
    },
    setBannerList(data) {
        this.setList({
            ...this.dataset,
            bannerListData : data
        })
    },
    setMsgLogTabList(data) {
        this.setList({
            ...this.dataset,
            shopMsgLogTab : data
        })
    },
    setMsgPhonebookGroupList(data) {
        this.setList({
            ...this.dataset,
            msgPhoneBookGroupTap : data,
        })
    },
    setPhonebookCnt(data) {
        this.setList({
            ...this.dataset,
            phonebookCnt: data,
        })
    },
    setCallMemoCnt(data) {
        this.setList({
            ...this.dataset,
            callmemoCnt: data,
        })
    },
    setGroupCnt(data) {
        this.setList({
            ...this.dataset,
            groupCnt: data
        })
    },
    setRecordFileCnt(data) {
        this.setList({
            ...this.dataset,
            recordFileCnt: data
        })
    },
    setWorkerCnt(data) {
        this.setList({
            ...this.dataset,
            workerCnt: data
        })
    },
    setDetectionCnt(data) {
        this.setList({
            ...this.dataset,
            detectionCnt: data
        })
    },
    setNoticeTabList(data) {
        this.setList({
            ...this.dataset,
            noticeTab : data
        })
    },
    setMemberShipNumList(data) {
        this.setList({
            ...this.dataset,
            memberShipNum : data
        })
    },
    setStoreList(data) {
        this.setList({
            ...this.dataset,
            storeList : data
        });
        LocalStore.setStoreList(data);
    },
    setStoreId(data) {
        this.setList({
            ...this.dataset,
            storeId : data
        });
        LocalStore.setStoreId(data);
    },
    setStoreNm(data) {
        this.setList({
            ...this.dataset,
            storeNm : data
        })
    },
    setStoreInetNo(data) {
        this.setList({
            ...this.dataset,
            storeInetNo : data
        });
        LocalStore.setStoreInetNo(data);
    },
    setCurrStoreInfo(data) {
        this.setList({
            ...this.dataset,
            currstoreInfo : data
        })
        LocalStore.setCurrStoreInfo(data)
    },
    setInterworkCheck(data){
        this.setList({
            ...this.dataset,
            interworkCheck : data
        })
    },
    setInterworkCheck2(data){
        this.setList({
            ...this.dataset,
            interworkCheck2 : data
        })
    },
    setStoreCamIdList(data) {
        this.setList({
            ...this.dataset,
            storeCamIdList : data
        })
    },
    setPolicyDetailList(data) {
        this.setList({
            ...this.dataset,
            policyAccessDetail : data
        })
    },
    setWorkerListData(data) {
        this.setList({
            ...this.dataset,
            workerList : data
        })
    },
    setAccountList(data) {
        this.setList({
            ...this.dataset,
            accountList : data
        })
    },
    setThumbnail(data) {
        this.setList({
            ...this.dataset,
            thumbnail : data
        })
    },
    setSplitThumbnail(data) {
        this.setList({
            ...this.dataset,
            splitThumbnail : data
        })
    },
    setPhoneBookList(data) {
        this.setList({
            ...this.dataset,
            phonebooklist : data
        })
    },
    setCentrixPhoneBookList(data) {
        this.setList({
            ...this.dataset,
            pblistForCentrix : data
        })
    }
    ,
    setDevicePhoneBookList(data) {
        this.setList({
            ...this.dataset,
            pblistForDevice : data
        })
    },
    setBlackList(data) {
        this.setList({
            ...this.dataset,
            blacklist : data
        })
    },
    setCamEventRuleList(data) {
        this.setList({
            ...this.dataset,
            camRuleEventList : data
        })
    },
    setCamEventList(data) {
        this.setList({
            ...this.dataset,
            camEventList : data
        })
    },
    setCamEventTotalCnt(data) {
        this.setList({
            ...this.dataset,
            camEventTotalCnt : data
        })
    },
    setStoreNmIndex(data) {
        this.setList({
            ...this.dataset,
            StoreNmIndex : data
        })
    },
    setCallFowarding(data) {
        this.setList({
            ...this.dataset,
            fowardingData : data
        })
    },
    setReceiveBlock(data) {
        this.setList({
            ...this.dataset,
            receiveBlockData : data
        })
    },
    setAlram(data) {
        this.setList({
            ...this.dataset,
            alramSetData : data
        })
    },
    setCallMemo(data) {
        this.setList({
            ...this.dataset,
            callMemoData : data
        })
    },
    setByDayTimeCheck(data) {
        this.setList({
            ...this.dataset,
            byDayTimeCall : data
        })
    },
    setMessagePopupCellNum(data) {
        this.setList({
            ...this.dataset,
            messagePopupCellNum : data
        })
    },
    setMessagePopupConts(data) {
        this.setList({
            ...this.dataset,
            messagePopupConts : data
        })
    },
    setSendMsgAddrPopupToggle(data) {
        this.setList({
            ...this.dataset,
            sendMsgAddrPopupToggle : data
        })
    },
    setSendMsgGroupPopupToogle(data) {
        this.setList({
            ...this.dataset,
            sendMsgGroupPopupToggle : data
        })
    }
    ,
    setPopSelectedSendMsgGroup(data) {
        this.setList({
            ...this.dataset,
            popSelectedSendMsgGroup : data
        })
    }
    ,
    setPopSelectedSendMsgPhone(data) {
        this.setList({
            ...this.dataset,
            popSelectedSendMsgPhone : data
        })
    }
    ,
    setPopSelectAddressAndPhone(data) {
        this.setList({
            ...this.dataset,
            popSelectAddressAndPhone : data
        })
    }
    ,
    setPopFlag(data) {
        this.setList({
            ...this.dataset,
            popFlag : data
        })
    }
    ,
    setIsMessageSetPopup(data) {
        this.setList({
            ...this.dataset,
            IsMessageSetPopup : data
        })
    }
    ,
    setAddressSyncFlag(data) {
        this.setList({
            ...this.dataset,
            addressSyncFlag : data
        })
    }
    ,
    setIsPopupCloseForMsgSet(data) {
        this.setList({
            ...this.dataset,
            IsPopupCloseForMsgSet : data
        })
    }
    ,
    setMessageWritePopupClose(data) {
        this.setList({
            ...this.dataset,
            messageWritePopupClose : data
        })
    }
    ,
    setMyPageCallPopupData(data) {
        this.setList({
            ...this.dataset,
            myPageCall : data
        })
    },
    setMsgCheck(data) {
        this.setList({
            ...this.dataset,
            msgCheck : data
        })
    },
    setSendMsgAddrPopupToogle(data) {
        this.setList({
            ...this.dataset,
            sendMsgAddrPopupTogle : data
        })
    },
    setBgCheck(data) {
        this.setList({
            ...this.dataset,
            bgCheck : data
        })
    },
    setBnfCateId(cateId) {
        this.setList({
            ...this.dataset,
            bnfCateId : cateId
        })
    },
    setBnfId(bnfId) {
        this.setList({
            ...this.dataset,
            bnfId : bnfId
        })
    },
    setCallPageReset(data){
        this.setList({
            ...this.dataset,
            callPageReset : data
        })
    },
    setPassFindPopupFlag(data) {
        this.setList({
            ...this.dataset,
            passFindPopupFlag : data
        })
    },
    setCallLoginPopupFlag(data) {
        this.setList({
            ...this.dataset,
            callLoginFlag : data
        })
    },
    setPhoneBookEditFlag(data) {
        this.setList({
            ...this.dataset,
            phoneBookEditFlag : data
        })
    },
    setProcChgStoreComplete(flag) {
        this.setList({
            ...this.dataset,
            procChgStoreComplete : flag
        })
    },
    setFlagGetAddress(data){
        this.setList({
            ...this.dataset,
            flagGetAddress : data
        })
    },
    setPhoneMainTab(data){
        this.setList({
            ...this.dataset,
            phoneMainTab : data
        })
    },
    setShopMainTabCallError(data){
        this.setList({
            ...this.dataset,
            shopMainTabCallError : data
        })
    },
    setShopMainTabMsgError(data) {
        this.setList({
            ...this.dataset,
            shopMainTabMsgError : data
        })
    },
    setCallMemoFlag(data){
        this.setList({
            ...this.dataset,
            callMemoFlag : data
        })
    },
    setCentrexTempAuthFlag(data){
        this.setList({
            ...this.dataset,
            centrexTempAuthFlag :data
        })
    },
    setCentrexTempAuthFlag2(data){
        this.setList({
            ...this.dataset,
            centrexTempAuthFlag2 :data
        })
    },
    setCentrexTempAuthFlag3(data){
        this.setList({
            ...this.dataset,
            centrexTempAuthFlag3 :data
        })
    },
    setHomeChFlag(data){
        this.setList({
            ...this.dataset,
            homeChFlag :data
        })
    },
    setHomeWorkerChFlag(data){
        this.setList({
            ...this.dataset,
            homeWorkerFlag :data
        })
    },
    setHomeWeatherChFlag(data){
        this.setList({
            ...this.dataset,
            homeWeatherFlag :data
        })
    },
    setSisicMainTabList(data){
        this.setList({
            ...this.dataset,
            sisicMainTab :data
        })
    },
    setOrderTabList(data){
        this.setList({
            ...this.dataset,
            orderTap :data
        })
    },
    setSissicgaRegiYn(data){
        this.setList({
            ...this.dataset,
            sissicgaRegiYn :data
        })
    },
    setNewOrderLength(data){
        this.setList({
            ...this.dataset,
            newOrderLength : data
        })
    }

});

export default userData;