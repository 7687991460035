import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import store from '../../../store';
import CctvApi from '../../../api/CctvApi';
import * as LocalStore from '../../../store/LocalStore';
import * as SessionStore from '../../../store/SessionStore';
import * as NativeBridge from '../../../utils/NativeBridge'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";
import moment from "moment";
import { layerPopup, tabContents } from "../../../libs/common"
import { observer } from 'mobx-react';

SwiperCore.use([ Pagination, Scrollbar ]);

const CCTV = observer(() => {

    const { userInfo, userData } = store;

    const [SplitCctvList, setSplitCctvList] = useState([])
    const [camIdList, setCamIdList] = useState([])
    const [camThumbList, setCamThumbList] = useState([])
    const [camSplitThumbList, setSplitCamThumbList] = useState([])
    const [camEventList, setCamEventList] = useState([])
    const [camEventCnt, setCamEventCnt] = useState(null)
    const [refreshtime, setRefreshtime] = useState(moment().format('HH:mm:ss'))
    const [retCode, setRetCode] = useState(0);

    var FullCctvList = userData.dataset.currstoreInfo.cams
    var SplitCctvListData = []
    var ThumbNailLists = []
    var SplitThumbNailLists = []
    let cctvKey = LocalStore.getCctvToken();

    // dots click Event
    useEffect(() => {
        CamEventList();
        setCamIdList([])
        setSplitCctvList([])

        if(Array.isArray(userData.dataset.currstoreInfo.cams)) {
            setCamIdList(userData.dataset.currstoreInfo.cams?.map((item) => item.camId))
        }

        if(userData.dataset.currstoreInfo.cams !== null && userData.dataset.currstoreInfo.cams !== 'undefined') {
            for(var i=0; i< FullCctvList.length; i+=4) SplitCctvListData.push(FullCctvList.slice(i, i+4))
            for(var i =0; i < SplitCctvListData.length; i++) {
                if(SplitCctvListData[i].length < 4){
                    var leng = 4 - SplitCctvListData[i].length
                    for(var j = 0; j < leng; j++) {
                        SplitCctvListData[i].push(false)
                    }
                }
            }
            setSplitCctvList(SplitCctvListData)
        }

        setTimeout(() => { 
            if(userData.dataset.currstoreInfo.cams?.length <= 1) {
                $('.cctv_manage #cctvFull .i_pagination').addClass('i_disabled');	
            } else {    
                $('.cctv_manage #cctvFull .i_pagination').removeClass('i_disabled');
            }
        }, 150);    
    }, [userData.dataset.currstoreInfo])

    useEffect(() => {
        if(SplitCctvList?.length <= 1) {
            $('.cctv_manage #cctvSplit .i_pagination').addClass('i_disabled');	
        } else {
            $('.cctv_manage #cctvSplit .i_pagination').removeClass('i_disabled');
        }
    }, [SplitCctvList])

    useEffect(() => {
        console.log('매장 변경 아이디', camIdList);
        ThumbNailLists = Array.from({length: camIdList.length}, (v, i) => false);
        setCamThumbList(ThumbNailLists);
        for(let i = 0; i < camIdList.length; i++) {
            console.log(i,camIdList.length,camIdList[i])
            try {
                CctvApi.getCctvThumbNail(camIdList[i]).then((cctv_thumb) => {
                    ThumbNailPush(i, cctv_thumb.data.imageData);
                }).catch((e)=>{
                    ThumbNailPush(i, "");
                });
            } catch(e) {
                ThumbNailPush(i, "");
            }
        }
    }, [camIdList])
    
    useEffect(() => {
        for(var i=0; i < camThumbList.length; i+=4) SplitThumbNailLists.push(camThumbList.slice(i, i+4))
        console.log("스플릿 썸네일", SplitThumbNailLists)
        setSplitCamThumbList(SplitThumbNailLists)
    }, [camThumbList])
    
    const ThumbNailPush = (i, ThumbNail) => {
      setCamThumbList(camThumbList => camThumbList.map((item,idx)=>{
          if(idx === i) {
              return ThumbNail;
          } else {
              return item;
          }
       }));
    }

    const CamEventList = async() => {
        if((cctvKey !== null || cctvKey !== "") && 
        (userData.dataset.currstoreInfo.cams !== undefined && userData.dataset.currstoreInfo.cams !== null && 
        userData.dataset.currstoreInfo.cams.length > 0)) {
            try {
                const event_list = await CctvApi.getCctvEventList(userData.dataset.currstoreInfo.camId, 
                    moment().format('yyyyMMDD000000'), moment().format('yyyyMMDDHHmmss'));

                setCamEventCnt(event_list.data.totalCount);
                setRetCode(event_list.data.resCode);
                if (event_list.data.list !== undefined && event_list.data.list !== null && event_list.data.list.length > 0){
                    setCamEventList(event_list.data.list);
                }
            } catch(e){
                console.log(e);
                setRetCode(null);
                setCamEventCnt(0);
                setCamEventList([])
            }

            // const event_list = await CctvApi.getCctvEventList(userData.dataset.currstoreInfo.camId, 
            //                                                     moment().format('yyyyMMDD000000'), 
            //                                                     moment().format('yyyyMMDDHHmmss'));
            // console.log('감지 내역', event_list.data.list);
            // setCamEventCnt(event_list.data.totalCount)
            // // if(typeof(event_list.data.retCode) === 'undefined') {
            // //     setRetCode(null)
            // // } else {
            // //     setRetCode(event_list.data.resCode)
            // // }
            // setRetCode(event_list.data.resCode);

            // if ( event_list.data.list !== undefined && event_list.data.list !== null && event_list.data.list.length > 0 ){
            //     setCamEventList(event_list.data.list);
            // }
        }
    }

    const CamRefresh = () => {
        setCamIdList([])
        setCamThumbList([])
        setSplitCamThumbList([])
        setCamEventList([])
        setCamEventCnt(null)

        // cctv 감지내역 조회
        CamEventList()
        //썸네일
        setCamIdList(userData.dataset.currstoreInfo?.cams.map((item) => item.camId))
        // 시간 변경
        setRefreshtime(moment().format('HH:mm:ss'))
    }


    const thumbnailClick = (e, currCamId) => {
        let authKey = SessionStore.getCctvToken();

        if(LocalStore.getFamilyYn() === 'Y' && LocalStore.getIsTempUser() === false) {
            layerPopup.showCommon(
                '#popup_onetitlesentence_onebutton_cctv',
                null,
                '안내',
                '초대계정은 보안 정책으로 <br /> U+지능형 CCTV앱 이동이 불가능합니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlesentence_onebutton_cctv')
                    }
                ]
            )
        } else {
            if(currCamId !== null) {
                NativeBridge.sendUserActionLog('detail', 'D008');
                NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=1&camId=${currCamId}`, "3")
            } else {
                NativeBridge.sendUserActionLog('detail', 'D009');
                NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=1`, "3")
            }
        }
    }

    const detectionHistory = (e) => {
        NativeBridge.sendUserActionLog('detail', 'D006');
        let authKey = SessionStore.getCctvToken();
        
        if(LocalStore.getFamilyYn() === 'Y' && LocalStore.getIsTempUser() === false) {
            layerPopup.showCommon(
                '#popup_onetitlesentence_onebutton_cctv',
                null,
                '안내',
                "초대계정은 보안 정책으로 <br /> U+지능형 CCTV앱 이동이 불가능합니다.",
                [
                    function() {
                        layerPopup.hide('#popup_onetitlesentence_onebutton_cctv')
                    }
                ]
            )
        } else {
            NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=2`,"3")
        }
    }

    const prevSearch = (e) => {
        let authKey = SessionStore.getCctvToken();

        if(LocalStore.getFamilyYn() === 'Y' && LocalStore.getIsTempUser() === false) {
            layerPopup.showCommon(
                '#popup_onetitlesentence_onebutton_cctv',
                null,
                '안내',
                "초대계정은 보안 정책으로 <br /> U+지능형 CCTV앱 이동이 불가능합니다.",
                [
                    function() {
                        layerPopup.hide('#popup_onetitlesentence_onebutton_cctv')
                    }
                ]
            )
        } else {
            NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=4`, "3")
        }
    }

    return (
        <>
            { ((cctvKey !== null || cctvKey !== "") && 
              (typeof(userData.dataset.currstoreInfo.cams) !== 'undefined' && userData.dataset.currstoreInfo.cams !== null &&
              userData.dataset.currstoreInfo.cams.length > 0) && (retCode !== null && retCode === 0)) ?
                <div className="fix_container cctv_manage">
                    <div className="sec_wrap sec_cctv_live">
                        <div className="inner">
                            <span className="title">실시간 CCTV</span>
                            <div className="cctv_live">
                                <div className="btn_cctv_tap">
                                    <button className="btn_tap btn_cctvfull usetap on" onClick={(e)=>tabContents(e, 'cctvFull')}>풀화면</button>
                                    <button className="btn_tap btn_cctvsplit usetap" onClick={(e)=>tabContents(e, 'cctvSplit')}>분할화면</button>
                                    <div className="cctv_opt">
                                        <span className="cctv_time">{refreshtime}</span>
                                        <button type="button" className="btn_refresh usetap" onClick={()=>CamRefresh()}><i className="ico_refresh"></i>새로고침</button>
                                    </div>
                                </div>
                                <div className="tab_content_wrap">
                                    <div id="cctvFull" className="tab_content" style={{display: "block"}}>
                                    { (typeof(userData.dataset.currstoreInfo.cams) !== "undefined" && userData.dataset.currstoreInfo.cams.length !== 0) ?
                                        <Swiper
                                            className="cctv_rolling cctv_full_rolling"
                                            observeParents
                                            observer
                                            pagination={{ 
                                                clickable: true, 
                                                el: '.i_pagination' }
                                            }
                                            slidesPerView="auto"
                                            loop={false}>
                                            { Array.isArray(FullCctvList) &&
                                                FullCctvList?.map((item, index) => (
                                                index < 24 &&
                                                <SwiperSlide key={`shopCctvFull_${index}`}>
                                                    { item.service01 === 'Y' && camThumbList[index] !== "" &&
                                                        <span className="cctv_mark">위생인증 CCTV</span>
                                                    }
                                                    { camThumbList[index] !== "" &&
                                                        <span className="cctv_name">{item.camName}</span>
                                                    }
                                                    { typeof(camThumbList[index]) === "undefined" || camThumbList[index] === false ?
                                                        <div className={'empty_cctv ' + `${item.camId}`} /*key={item.camId} onClick={(e)=>thumbnailClick(e, item.camId)}*/></div>
                                                    
                                                    : camThumbList[index] !== "" ?
                                                        <div className={'cctv_thumb ' + `${item.camId}`} 
                                                            style={{backgroundImage: `url('data:image/png;base64,${camThumbList[index]}')`}} 
                                                            onClick={(e)=>thumbnailClick(e, item.camId)}></div>
                                                    : camThumbList[index] === "" ?
                                                        <div className={'cctv_err_wrap ' + `${item.camId}`} /*key={item.camId}*/ onClick={(e)=>thumbnailClick(e, item.camId)}>
                                                            <div className="cctv_err_box">
                                                                <div className="err_inner">
                                                                    <span className="ic_card_error"></span>
                                                                    <p className="info1">일시 오류</p>
                                                                    <p className="info2">새로고침으로 정보를 불러주세요!</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <></>
                                                    }
                                                </SwiperSlide>
                                            ))
                                            }
                                            { userData.dataset.currstoreInfo.cams.length >= 24 &&
                                                <>
                                                    <SwiperSlide>
                                                        <div className="cctv_excess_wrap">
                                                            <div className="vertical_cen">
                                                                <div className="excess_desc">
                                                                    <span className="ic_cctv"></span>
                                                                    <p className="info1">가게에 설치된 CCTV가 24대 이상입니다</p>
                                                                    <p className="info2">전체 CCTV 영상을 시청하려면</p>
                                                                    <p className="link_cctvapp usetap" onClick={(e)=>thumbnailClick(e, null)}>U+ 지능형 CCTV 앱을 이용해 주세요</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </>
                                            }
                                                <div className="i_pagination"></div>
                                        </Swiper>
                                    :
                                        <div className="cctv_err_wrap" >
                                            <div className="cctv_err_box">
                                                <div className="err_inner">
                                                    <span className="ic_card_error"></span>
                                                    <p className="info1">일시 오류</p>
                                                    <p className="info2">새로고침으로 정보를 불러주세요!</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    <div id="cctvSplit" className="tab_content" style={{display: "none"}}>
                                        { (typeof(userData.dataset.currstoreInfo.cams) !== "undefined" && userData.dataset.currstoreInfo.cams.length !== 0) ?
                                            <Swiper
                                                className="cctv_rolling cctv_split_rolling"
                                                // spaceBetween={2}
                                                pagination={{ 
                                                    clickable: true, 
                                                    el: '.i_pagination'
                                                }}
                                                observeParents
                                                observer
                                                slidesPerView="auto"
                                                loop={false}>
                                                    { (typeof(SplitCctvList) !== 'undefined' && SplitCctvList !== null) &&
                                                        SplitCctvList.map((splititems, splitindex) => (
                                                            splitindex < 6 &&
                                                            <>
                                                                <SwiperSlide key={`shopCctvSplit_${splitindex}`}>
                                                                    { splititems.map((splititem, index) => (
                                                                        <>                                                                    
                                                                            <div className="thumb_wrap">
                                                                                { splititem === false ?
                                                                                    <div className="empty_cctv"></div>
                                                                                :
                                                                                    (camSplitThumbList[splitindex] && camSplitThumbList[splitindex][index] === false) ?
                                                                                        <div className={'empty_cctv ' + `${splititem.camId}`}></div>
                                                                                    : (camSplitThumbList[splitindex] && camSplitThumbList[splitindex][index] !== "") ?
                                                                                        <div className={'cctv_thumb ' + `${splititem.camId}`} key={splititem.camId}  
                                                                                            onClick={(e)=>thumbnailClick(e, splititem.camId)} 
                                                                                            style={{backgroundImage:`url('data:image/png;base64,${camSplitThumbList[splitindex][index]}')`}}></div>
                                                                                    : (camSplitThumbList[splitindex] && camSplitThumbList[splitindex][index] === "") ?
                                                                                        <div className={'cctv_split_error ' + `${splititem.camId}`} key={splititem.camId} onClick={(e)=>thumbnailClick(e, splititem.camId)}>
                                                                                            <div className="err_wrap">
                                                                                                <span className="ic_card_error"></span>
                                                                                                <span className="err_title">일시오류</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    :
                                                                                        <></>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                    }
                                                                </SwiperSlide>
                                                            </>
                                                    ))
                                                    }
                                                    { userData.dataset.currstoreInfo.cams.length >= 24 &&
                                                        <>
                                                            <SwiperSlide>
                                                                <div className="cctv_excess_wrap" >
                                                                    <div className="vertical_cen">
                                                                        <div className="excess_desc">
                                                                        <span className="ic_cctv"></span>
                                                                            <p className="info1">가게에 설치된 CCTV가 24대 이상입니다</p>
                                                                            <p className="info2">전체 CCTV 영상을 시청하려면</p>
                                                                            <p className="link_cctvapp usetap" onClick={(e)=>thumbnailClick(e, null)}>U+ 지능형 CCTV 앱을 이용해 주세요</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        </>
                                                    }
                                                    <div className="i_pagination"></div>
                                            </Swiper>
                                        :
                                            <div className="cctv_err_wrap">
                                                <div className="cctv_err_box">
                                                    <div className="err_inner">
                                                        <span className="ic_card_error"></span>
                                                        <p className="info1">일시 오류</p>
                                                        <p className="info2">새로고침으로 정보를 불러주세요!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sec_wrap sec_cctv">
                    { (camEventCnt > 0) &&
                        <div className="inner">
                            <div className="title_wrap">
                                <span className="title">오늘 발생한 감지</span>
                                <span className="total_num">{camEventCnt !== null ? `${camEventCnt?.toLocaleString()}` : '0'}건</span>
                            </div>
                            <div className="cctv_sense" onClick={()=>detectionHistory()}>
                                <ul>
                                    <li className={"sense1" + (camEventList?.filter((item) => item.eventType === 1).length > 0 ? ' on' : '')}>
                                        <span className="title_sense">침입감지</span>
                                        <span className="num_sense">
                                            {camEventList?.filter((item) => item.eventType === 1).length}건
                                        </span>
                                    </li>
                                    <li className={"sense2" + (camEventList?.filter((item) => item.eventType === 13).length > 0 ? ' on' : '')}>
                                        <span className="title_sense">배회</span>
                                        <span className="num_sense">
                                            {camEventList?.filter((item) => item.eventType === 13).length}건
                                        </span>
                                    </li>
                                    <li className={"sense3" + (camEventList?.filter((item) => item.eventType === 7).length > 0 ? ' on' : '')}>
                                        <span className="title_sense">카메라훼손</span>
                                        <span className="num_sense">
                                            {camEventList?.filter((item) => item.eventType === 7).length}건
                                        </span>
                                    </li>
                                    <li className={"sense4" + (camEventList?.filter((item) => item.eventType === 5).length > 0 ? ' on' : '')}>
                                        <span className="title_sense">유기</span>
                                        <span className="num_sense">
                                            {camEventList?.filter((item) => item.eventType === 5).length}건
                                        </span>
                                    </li>
                                    <li className={"sense5" + (camEventList?.filter((item) => item.eventType === 8).length > 0 ? ' on' : '')}>
                                        <span className="title_sense">불꽃연기</span>
                                        <span className="num_sense">
                                            {camEventList?.filter((item) => item.eventType === 8).length}건
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                    { (camEventCnt === 0 || camEventCnt === null ) &&
                        <div className="inner">
                            <div className="title_wrap">
                                <span className="title">오늘 발생한 감지</span>
                                <span className="total_num">0건</span>
                            </div>
                            <div className="cctv_sense" onClick={()=>detectionHistory()}>
                                <ul>
                                    <li className={"sense1"}>
                                        <span className="title_sense">침입감지</span>
                                        <span className="num_sense">0건</span>
                                    </li>
                                    <li className={"sense2"}>
                                        <span className="title_sense">배회</span>
                                        <span className="num_sense">0건</span>
                                    </li>
                                    <li className={"sense3"}>
                                        <span className="title_sense">카메라훼손</span>
                                        <span className="num_sense">0건</span>
                                    </li>
                                    <li className={"sense4"}>
                                        <span className="title_sense">유기</span>
                                        <span className="num_sense">0건</span>
                                    </li>
                                    <li className={"sense5"}>
                                        <span className="title_sense">불꽃연기</span>
                                        <span className="num_sense">0건</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                    </div>
                    <div className="sec_wrap sec_cctv_history">
                        <div className="inner">
                            <span className="title">스마트 영상검색</span>
                            <button type="button" className="btn_round purple usetap" onClick={(e)=>prevSearch(e)}>U+ 지능형 CCTV 앱에서 보기</button>
                        </div>
                    </div>
                </div>
            :
                <div className="fix_container">
                    <div className="fix_container cctv_manage">
                        <div className="temporay_error">
                            <p>일시 오류, 새로고침으로<br />정보를 불러주세요!</p>
                            <div className="ex_img">
                                <img src="/res/images/img-mystore-cctv-error@3x.png" alt="CCTV 화면" />
                                <button type="button" className="btn_refresh usetap blind" onClick={()=> CamRefresh()}>새로고침</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
});

export default CCTV
