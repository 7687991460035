import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import $ from 'jquery';
import * as CONSTS from '../../const';
import * as LocalStore from '../../store/LocalStore';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import {parsePathname, isPC} from '../../common/common';
import { layerPopup, toast } from '../../libs/common';
import { setCenterexUserIdPass, setCentrexSsoKey } from '../../api/CentrexApi';
import { setCctvToken, setTempCctvToken } from '../../api/CctvApi';
import * as SessionStore from '../../store/SessionStore';


const userList_dev = [
    /* 개발기 */
    /*
    {name :'test01', userCi : 'hve1IzRiHkDhXF/o9ZQ57/GpLYzZdP3Jo9FqubSYQP1lVAgYc5wisNuPJ9hV1dV/il6eE1C5oxv4gy+tWtMqxw==', userNm:'테스트01-케01', userCtn:'01020937018', userBday: '19801011', sex: 'M'}
    ,{name :'test02', userCi : 'vv+rzZk1r/FeD96sXWNf9c/trEE8of5AlesKRhZSI0i06WFfhdXQa6iwg4FpvHzF4gy5BGWOCIImKpPRXb8y6g==', userNm:'테스트02-케01', userCtn:'01022371312', userBday: '19801021', sex: 'F'}
    ,{name :'test03', userCi : 'uQo2ZHCOnXhwVIgmBr1FfxjaNUgfeu2f91KslyiYNmwFPvNEzQrB+f3cwZUwjd5xzkZlSJj5jYMrqYimlgA6Vw==', userNm:'테스트03-케01', userCtn:'01054743189', userBday: '19801031', sex: 'M'}
    ,{name :'test04', userCi : 'o/p6frgtGzoT+aHzNQZ+OXXVL+LPfuC5QJtQ7RopUkllCed+I59BDl/gmGZyKZTSbcg/vvrIlScHNWKtM2MXuw==', userNm:'테스트04-케02', userCtn:'01026110816', userBday: '19801041', sex: 'F'}
    ,{name :'test05', userCi : 'qSSecfwneBhgxG4ShZYFSmSXVx29s6JGyDAS9xaYRbCEo8wycHyAmUFPVga4o28atV859zx7tDC0sw6uUUhlXg==', userNm:'테스트05-케02', userCtn:'01080804530', userBday: '19801051', sex: 'M'}
    ,{name :'test06', userCi : 'fCEmMBnzokGWJHPrMN7q49GCnxjgkfwQsWH5GYr507wUb9cqST32u+fUX5TA4OnBloG1jpjPsXd3Ahnkex7jvQ==', userNm:'테스트06-케03', userCtn:'01035979572', userBday: '19801061', sex: 'F'}
    ,{name :'test07', userCi : 'pmROqIbvGV1tSH3+wzcJpy8zSRg82SM3nQ7T4N5QOVZzTWhVwHZtoxOaZ5hSx6SdCb7CrJRoUajzrY2VNTiOlw==', userNm:'테스트07-케05', userCtn:'01071339751', userBday: '19801071', sex: 'M'}
    ,{name :'test08', userCi : 'Xn36NumCptf3sUOBMoZ1Iq5k3fTk5XhEK9u2HD9lRU1HXSOzJP3sMCkxnvvwZsSP5R0sjq/AWKVwfKXmLP7M8w==', userNm:'테스트08-케05', userCtn:'01011112222', userBday: '19801081', sex: 'F'}
    ,{name :'test09', userCi : '4FNN5diqFiipWgHYHuLvwccR+1ln23CvimRbUwLpQH/0wGujkktKRFHhMruTEMY7AEC3IBhOsIP9OC8+WdEFxQ==', userNm:'테스트09-케06', userCtn:'01022223333', userBday: '19801091', sex: 'M'}
    ,{name :'test10', userCi : 'sLwrX9HvZhejR1r1j1DDm7KvtfRfytnVejxF4tdhTm2C4mppt5G7FDq3N5zyJlACkxpCBijqYOk59rTi2NV3xw==', userNm:'테스트10-케06', userCtn:'01057377034', userBday: '19801101', sex: 'F'}
    ,{name :'test11', userCi : '+VHywyK67cPH+F3XR6ecEt0rpfj3Y1pXPQT0YPQ//gekrZ1nZRdQun+PJZEElHgRLxJX4MLjVAIoibP8cbIdTA==', userNm:'테스트11-케07', userCtn:'01080804531', userBday: '19801111', sex: 'M'}
    ,{name :'test12', userCi : 's4RTGhtRaDwKQc12dQDcR35+Aa4oiB4kTx3zn+2+Ld8eCOfOuJmurmyLHo8IqooDmF8WLs/Ty8D1OocwshIisQ==', userNm:'테스트12-케08', userCtn:'01092128828', userBday: '19801121', sex: 'F'}
    ,{name :'test13', userCi : 'hO7adzC31WoFB7q5DPR1cZzpLtRX+O47k4R6GWBDEEFQXbtFJQJfJr8R/OkXuNBdynQdWVA7BCCq7FejoeDGmQ==', userNm:'테스트13-케08', userCtn:'01051683912', userBday: '19801131', sex: 'M'}
    ,{name :'test14', userCi : 'lGgsRYc3Th6AG+RqT0GpOiPS84hdDd3ewci2M4Dr2C7omkAciznsW3uVYNaeoz7qj4o+lKN6/cH3IQH/uA4aQQ==', userNm:'테스트14-케08', userCtn:'01080804532', userBday: '19801141', sex: 'F'}
    ,{name :'test15', userCi : 'lPD9rUkNaWQSWPBN8PPKCVKQrbPuNQAHgepNoh3Y9NulwUlfse9fbuUwT0v9cLu1pixpdPkwzDSgQnlslVN1AQ==', userNm:'테스트15-케09', userCtn:'01044445555', userBday: '19801151', sex: 'M'}
    ,{name :'test16', userCi : '1AwcrB+xgbFGEtAWtlgNkyobqjdenN6lOOu63t8qm1JvJHHsdy7ZpnjtZ8iI3F24CQvVL9jM9lW0gGfWmxEa1Q==', userNm:'테스트16-케09', userCtn:'01055556666', userBday: '19801161', sex: 'F'}
    ,{name :'test17', userCi : 'SiU4q6OD5I3GFgteAMw740FIEMRzij+miM6bco8d/Un0iFFyC4ymmrqnGeiE99/PoOIsxrz1AZu2lK3xb26AUQ==', userNm:'테스트17-케09', userCtn:'01066667777', userBday: '19801171', sex: 'M'}
    ,{name :'test18', userCi : 'Li1wXSa0IKhlUs8+5qpEbbZdMD6f1cJpNpL/cdPpdIzov+a3AOCmWx0f+UvAve4JeRT3q7FT6fsG8Rij46BAZA==', userNm:'테스트18-케10', userCtn:'01077778888', userBday: '19801181', sex: 'F'} 
    ,{name :'본인확인', userCi : '', userNm: '', userCtn:'', userBday: '', sex: ''}
    */
    {name :'SSG', userCi : 'ssg-Kamindong', userNm:'SSG-카미동', userCtn:'01000700070', userBday: '19881113', sex: 'M'},
    {name :'DVT01', userCi : 'smAT1Mcmd6TIv17t7e0mxQ7c37BEdBZ/InZa/xmJYoZ4bPkcbx687DASe9ki7gol5IJ3bAihHTtR/9Bfxryfyg==', userNm:'DVT01_전체1-M9', userCtn:'01080802736', userBday: '19881113', sex: 'M'},
    {name :'DVT02', userCi : 'uQIf4F4ZrnWpC6A0wq8N4Z0yYchPC9pvdULIPA7bfmH87zXmZF2qT1Ci/PRc+3q6WSeNyxeCT8ROT509om+ZTg==', userNm:'DVT02_전체2', userCtn:'01080804535', userBday: '19920222', sex: 'F'},
    //{name :'DVT03', userCi : 'abcd', userNm:'DVT03_전체3-M9', userCtn:'01022371312', userBday: '20000101', sex: 'M'},
    {name :'DVT04', userCi : 'hve1IzRiHkDhXF/o9ZQ57/GpLYzZdP3Jo9FqubSYQP1lVAgYc5wisNuPJ9hV1dV/il6eE1C5oxv4gy+tWtMqxw==', userNm:'DVT04_전화1', userCtn:'01020937018', userBday: '19740121', sex: 'M'},
    {name :'DVT05', userCi : 'lPD9rUkNaWQSWPBN8PPKCVKQrbPuNQAHgepNoh3Y9NulwUlfse9fbuUwT0v9cLu1pixpdPkwzDSgQnlslVN1AQ==', userNm:'DVT05_전화2', userCtn:'01012345679', userBday: '19670905', sex: 'F'},
    //{name :'DVT06', userCi : 'abcdef', userNm:'DVT06_부가미1-M9', userCtn:'01012345678', userBday: '19990101', sex: 'F'},
    //{name :'DVT08', userCi : 'uQo2ZHCOnXhwVIgmBr1FfxjaNUgfeu2f91KslyiYNmwFPvNEzQrB+f3cwZUwjd5xzkZlSJj5jYMrqYimlgA6Vw==', userNm:'DVT08', userCtn:'01054743189', userBday: '19710309', sex: 'F'},
    {name :'DVT07', userCi : 'o/p6frgtGzoT+aHzNQZ+OXXVL+LPfuC5QJtQ7RopUkllCed+I59BDl/gmGZyKZTSbcg/vvrIlScHNWKtM2MXuw==', userNm:'DVT07_부가미2', userCtn:'01026110816', userBday: '19940816', sex: 'F'},
    {name :'본인확인', userCi: '', userNm: '', userCtn:'', userBday: '', sex: ''}
];
   /* 검수기 */
   const userList_stg = [
    {name :'CVT_01', userCi : 'uQo2ZHCOnXhwVIgmBr1FfxjaNUgfeu2f91KslyiYNmwFPvNEzQrB+f3cwZUwjd5xzkZlSJj5jYMrqYimlgA6Vw==', userNm:'CVT_01', userCtn:'01012345678', userBday: '19801021', sex: 'F'}
    ,{name :'CVT-02', userCi : 'lGgsRYc3Th6AG+RqT0GpOiPS84hdDd3ewci2M4Dr2C7omkAciznsW3uVYNaeoz7qj4o+lKN6/cH3IQH/uA4aQQ==', userNm:'CVT-02', userCtn:'01022331328', userBday: '19801051', sex: 'M'}
    ,{name :'검수01_케01', userCi : '+VHywyK67cPH+F3XR6ecEt0rpfj3Y1pXPQT0YPQ//gekrZ1nZRdQun+PJZEElHgRLxJX4MLjVAIoibP8cbIdTA==', userNm:'검수01_케01', userCtn:'01012341234', userBday: '19801011', sex: 'M'}
    //,{name :'검수02-케01', userCi : 'uQo2ZHCOnXhwVIgmBr1FfxjaNUgfeu2f91KslyiYNmwFPvNEzQrB+f3cwZUwjd5xzkZlSJj5jYMrqYimlgA6Vw==', userNm:'검수02-케01', userCtn:'01022331328', userBday: '19801021', sex: 'F'}
    ,{name :'검수03-케01', userCi : 'o/p6frgtGzoT+aHzNQZ+OXXVL+LPfuC5QJtQ7RopUkllCed+I59BDl/gmGZyKZTSbcg/vvrIlScHNWKtM2MXuw==', userNm:'검수03-케01', userCtn:'01026110816', userBday: '19801031', sex: 'M'}
    ,{name :'검수04-케02', userCi : 's4RTGhtRaDwKQc12dQDcR35+Aa4oiB4kTx3zn+2+Ld8eCOfOuJmurmyLHo8IqooDmF8WLs/Ty8D1OocwshIisQ==', userNm:'검수04-케02', userCtn:'01092128828', userBday: '19801041', sex: 'F'}
    // ,{name :'검수05-케02', userCi : 'lGgsRYc3Th6AG+RqT0GpOiPS84hdDd3ewci2M4Dr2C7omkAciznsW3uVYNaeoz7qj4o+lKN6/cH3IQH/uA4aQQ==', userNm:'검수05-케02', userCtn:'01012345678', userBday: '19801051', sex: 'M'}
    ,{name :'검수06-케03', userCi : 'hO7adzC31WoFB7q5DPR1cZzpLtRX+O47k4R6GWBDEEFQXbtFJQJfJr8R/OkXuNBdynQdWVA7BCCq7FejoeDGmQ==', userNm:'검수06-케03', userCtn:'01051683912', userBday: '19801061', sex: 'F'}
    //,{name :'검수07-케05', userCi : 'lPD9rUkNaWQSWPBN8PPKCVKQrbPuNQAHgepNoh3Y9NulwUlfse9fbuUwT0v9cLu1pixpdPkwzDSgQnlslVN1AQ==', userNm:'검수07-케05', userCtn:'01071339751', userBday: '19801071', sex: 'M'}
    ,{name :'검수07-케05', userCi : 'pmROqIbvGV1tSH3+wzcJpy8zSRg82SM3nQ7T4N5QOVZzTWhVwHZtoxOaZ5hSx6SdCb7CrJRoUajzrY2VNTiOlw==', userNm:'검수07-케05', userCtn:'01071339751', userBday: '19801071', sex: 'M'}
    //,{name :'검수08-케05', userCi : 'Li1wXSa0IKhlUs8+5qpEbbZdMD6f1cJpNpL/cdPpdIzov+a3AOCmWx0f+UvAve4JeRT3q7FT6fsG8Rij46BAZA==', userNm:'검수08-케05', userCtn:'01011112222', userBday: '19801081', sex: 'F'}
    ,{name :'본인확인', userCi: '', userNm: '', userCtn:'', userBday: '', sex: ''}
  ];

 /* 검수기 */
 const userList_cvt = [
    {name :'CVT-01', userCi : 'uQo2ZHCOnXhwVIgmBr1FfxjaNUgfeu2f91KslyiYNmwFPvNEzQrB+f3cwZUwjd5xzkZlSJj5jYMrqYimlgA6Vw==', userNm:'CVT-01', userCtn:'01012345678', userBday: '19801021', sex: 'F'}
    //,{name :'CVT-02', userCi : 'lGgsRYc3Th6AG+RqT0GpOiPS84hdDd3ewci2M4Dr2C7omkAciznsW3uVYNaeoz7qj4o+lKN6/cH3IQH/uA4aQQ==', userNm:'CVT-02', userCtn:'01022331328', userBday: '19801051', sex: 'M'}
    ,{name :'CVT-02', userCi : 'lPD9rUkNaWQSWPBN8PPKCVKQrbPuNQAHgepNoh3Y9NulwUlfse9fbuUwT0v9cLu1pixpdPkwzDSgQnlslVN1AQ==', userNm:'CVT-02', userCtn:'01012341234', userBday: '19801071', sex: 'M'}
    ,{name :'본인확인', userCi: '', userNm: '', userCtn:'', userBday: '', sex: ''}
 ];

let userList = userList_dev;

if ( process.env.REACT_APP_MODE === 'stg'){
    userList = userList_stg;
} else if ( process.env.REACT_APP_MODE === 'cvt'){
    userList = userList_cvt;
}

const CallKmc = observer(({ history,location,match }) => {

    const { userInfo, userData } = store;
    const [certNum, setCertNum] = useState('');
    const [retCert, setRetCert] = useState('');

    // const userList = userInfo.getList();
    
    let param = {
        "encData" : "",
        "certNum" : "",
        "userCi" : "",
        "name" : "",
        'userNm':'', 
        'userCtn':'',
        'userBday': '', 
        'sex': 'F'
    }

    useEffect(() => {       
        console.log("CallKmc default useEffect..");

        console.log("match.params : " , match.params);

        //native event set
        const subscription = NativeBridge.eventObservable.subscribe(event => {

            console.log("===================== From Native =====================");
            console.log(event);

            switch (event.type) {
                case 'GET_KMC':
                    const rec_cert = event.param.rec_cert;
                    const certNum = event.param.certNum;
                    console.log("CALL_DEVICE_EVENT :: kmccallback :: event.param.rec_cert : ", event.param.rec_cert);
                    setRetCert(rec_cert);
                    setCertNum(certNum);

                    // KMC 인증 결과 데이터가 없는경우
                    if(!rec_cert) {

                        // 개발모드인경우는 테스트 계정 보임
                        if(process.env.REACT_APP_MODE !== "prd") {
                            //param.encData = "TESTABCDAADFASDFASDFASDFASDFASDFASDFASLLKJJLJLHLUYTIGIGBKMBNKGIUJG8687685787";
                            //param.certNum = "123456789012345678901234567890";
                            $('select').show();

                        // 개발모드가 아닌경우 뒤로 이동
                        } else {

                            if(match.params.from === 'modpass') {
                                history.goBack();
                            } else {
                                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "본인인증을 취소하셨습니다.", [
                                    function() {
                                        layerPopup.hide("#popup_onetitlemsgbutton",()=>{
                                            history.replace("/login");
                                        });                                        
                                    }
                                ]);
                            }
                            return;
                        }
                    }
                    
                    if(process.env.REACT_APP_MODE !== "prd") {
                        // param.encData = rec_cert;
                        // param.certNum = certNum;
                        $('select').show();
                    } else {
                        const param  = {
                            "encData" : rec_cert,
                            "certNum" : certNum
                        }
    
                        regUser(param);
                    }
                break;
            default:
                break;
            }

        });

        callKmc();

        // -- cctv pw enc example 
        /*
        let pwd = "qwerqwer34";
        const cryptPw = Crypt.cctvPwEncrypt(pwd);
        console.log(cryptPw);
        */

        return () => {
            subscription.unsubscribe();
        };
        



    }, []);
   
    async function regUser(param) {
        let ret = "";
        const deviceInfo = LocalStore.getDeviceInfo();
        let testParam = {};

        //-- 개발 모드
        if(process.env.REACT_APP_MODE !== "prd" && param.name !== '본인확인') {

            testParam = 
            {
                "encData": ''//param.encData
                ,"userNm": param.userNm
                // ,"userCi":"hve1IzRiHkDhXF/o9ZQ57/GpLYzZdP3Jo9FqubSYQP1lVAgYc5wisNuPJ9hV1dV/il6eE1C5oxv4gy+tWtMqxw=="		
                ,"userCi" : param.userCi
                ,"userCtn": param.userCtn
                ,"userBday" : param.userBday		
                ,"pushRegi" : deviceInfo.push_key ? deviceInfo.push_key : "NONE"
                ,"sex": param.sex
                ,"certNum": ''//param.certNum
                ,"detailYn": "N"
                ,"osInfo" : deviceInfo.os_type ? deviceInfo.os_type : "unknown_os"
            };
            console.log('testParam: ', testParam);
            ret = await SohoApi.regUser(testParam);

        } else {
            ret = await SohoApi.regUser({
                encData: param.encData,
                certNum: param.certNum,
                pushRegi : deviceInfo.push_key ? deviceInfo.push_key : "NONE",
                detailYn: "N",
                osInfo: deviceInfo.os_type ? deviceInfo.os_type : "android",
                userNm: "",
                userCi : "",
                userCtn: "",
                userBday : "",
                sex : ""
            }).catch((e) => {
                //timeout 처리
                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "로그인 중 오류가 발생하였습니다."
                    , [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton", ()=>{
                            history.replace("/");
                        });
                    }
                ]);
            });
        }

        try {
            if(ret.retCode !== '0000') {
                
                if(process.env.REACT_APP_MODE !== "prd") {
                    $(".msgbox1").html(JSON.stringify(testParam));
                    $(".msgbox2").html(JSON.stringify(ret));
                }

                layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "로그인 중 오류가 발생하였습니다"
                    , [
                    function() {
                        layerPopup.hide("#popup_onetitlemsgbutton", ()=>{
                            history.replace("/");
                        });
                    }
                ]);
            } else {
                let userId = ret.data.userId;

                // 비밀번호 재설정으로 들어온 경우 기존 userid 와 새롭게 받은 userid 가 같은지 체크
                //if(match.params.from === 'modpass') {
                    if(LocalStore.getUserId() && LocalStore.getUserId() !== userId) {
                        toast("비밀번호를 변경할수 없습니다.");
                        
                        
                        // -- mypagge 에서 온경우
                        if(match.params.from === 'modpass') {
                            history.goBack();

                        // -- 그 외
                        } else {
                            SessionStore.clear();
                            LocalStore.clear();
                            userInfo.initialize();
                            userData.initialize();
                            setCentrexSsoKey('','');
                            setCenterexUserIdPass('','');
                            setCctvToken('');
                            setTempCctvToken('');

                            NativeBridge.setAlarm('delete');
                            NativeBridge.setCallMemoPopup('off');
                    
                            history.replace("/");
                        }
                        return;
                    }
                //}

                userInfo.setUserId(userId);
                if(match.params.from === "resetpin") {
                    history.replace("/pincode/resetpin");
                } else {
                    history.replace("/pincode/reg");
                }
            }


        } catch(e) {
            console.log(e);
        }
        
    };

    async function callKmc() {

        // kmc pass
        // const userId = "user123";
        // LocalStore.setUserId(userId);
        // userInfo.setUserId(userId);
        // history.push("/pincode");
        
        // kmc auth
        const result = await SohoApi.getKmcUrlEnc();
        console.log(result);

        if(typeof(result) === "undefined" || result === null || result.retCode !== CONSTS.SOHOAPI_RETCODE_SUCCESS) {
            toast("본인인증 호출에 실패했습니다. 다시 한번 시도해 주세요.");
            history.replace("/login");
            return;
        }

        NativeBridge.callKmc(result.data.kmcUrl);
        setCertNum(result.data.certNum);
        
        if(isPC()) {
            setTimeout(() => {
                const userData = {
                    type:"GET_KMC",
                    param:{
                        rec_cert:"",
                        certNum : ""
                    }
                };
                window.NativeInvoker(userData);
            }, 3000);
        }
    }

    function selectUser(e) {
        // if (e.target.value === '') {
        //     return;
        // }

        const userCi = e.target.value;
        param = userList.find((item) => {
            return item.userCi === userCi;
        });

        param.certNum = certNum;
        param.encData = retCert;


        console.log('param', param);

        regUser(param);
        
    }

    return (
        <>
            <select onChange={(e) => selectUser(e)} style={{display: 'none'}}>
                <option>-- 로그인 테스트 계정 선택 --</option>
                {
                    userList.map((a, index) => {
                        return (
                            <option value={a.userCi} key={index}>{a.name} : {a.userNm}</option>
                        )
                    })
                }
            </select>
            <br /><br />
            <div className="msgbox1" style={{"wordBreak":"break-all"}}></div>
            <div className="msgbox2" style={{"wordBreak":"break-all"}}></div>
        </>
    )
    
});
export default CallKmc;