import { observable } from 'mobx';
import * as LocalStore from './LocalStore';
import * as SessionStore from './SessionStore';

const deviceInfo = observable({
    dataset : {
        devModel: "",
        push_key: "",
        os_type: "",
        os:"",
        ctn:"",
        network:"",
        ip_addr:"",
        mac_info:"",
        tel_info: "",
        iccid:"",
        sim_operator:"",
        serial_no:"",
        imei:"",
        carrier_type:"", 
        version:""
    },
    setData(data) {
        data.os_type = data.os_type ? data.os_type.toLowerCase() : "android";
        this.dataset = data;
        LocalStore.setDevicefInfo(data);
    },
    getData() {
        return this.dataset;
    },
    setCtn(data) {
        this.setList({
            ...this.dataset,
            ctn : data
        })
    }
});

export default deviceInfo;