import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import { layerPopup } from "../../libs/common";
import { msgUI } from "../../libs/msg";
import {setCctvToken, setTempCctvToken} from '../../api/CctvApi';
import {setCenterexUserIdPass, setCentrexSsoKey} from '../../api/CentrexApi';
import SohoApi from "../../api/SohoApi";
import CentrexApi from "../../api/CentrexApi";
import CctvApi from "../../api/CctvApi";
import * as CONSTS from '../../const';
import { setUserDataStoreList } from '../../components/login/DefaultSet';

const SohoCctvAuthErrorPopup = observer(({ history }) => {

    const { userInfo, userData } = store;
    const goNext = () => {
        console.log("SohoCctvAuthErrorPopup CAll goNext");
        layerPopup.hide("#popup_soho_cctv_auth_error");

        if(LocalStore.getIsTempUser()===true) {

            // 로컬스토리지에 id,pw 가 세팅되어 있는지 확인한다.
            const cctvId = LocalStore.getCctvTempUserId();
            const cctvPw = LocalStore.getCctvTempUserPwd();
            if(cctvId && cctvPw) {
                CctvApi.getAuth({
                    userId : cctvId,
                    userPwd : cctvPw
                }).then(cctvAuthInfo=>{
                    console.log('cctvAuthInfo', cctvAuthInfo);
                }).catch((e)=>{
                    console.log(e);
                });                
            }

        } else {
            SohoApi.getCctvAuth().then(rst=>{
                if(rst && rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setUserDataStoreList();
                }
            }).catch(e=>{
                console.log(e);
            });
        }
        
    };

    return (
        <>
            <div className="modal fadeIn" id="popup_soho_cctv_auth_error">
                <div className="dim"></div>
                <div className="modal_inner">
                    <div className="modal_content">
                        <div className="modal_hd">알림</div>
                        <div className="modal_bd">
                            <p>CCTV 로그인에 실패하였습니다.<br/>U+지능형 CCTV에 등록된 휴대폰번호를 업데이트하세요.</p>
                        </div>
                        <div className="modal_ft">
                            <button type="button" className="btn usetap" onClick={goNext}><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    );
});
export default SohoCctvAuthErrorPopup;