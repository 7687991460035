import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import BizApi from "../../../../../api/BizApi";
import { floatUI } from '../../../../../libs/floating'
import { tabUi } from '../../../../../libs/tab'
import { layerPopup, toast } from "../../../../../libs/common";
import { getGroup, transformAddressDrawData, checkIMEEnabledType, defaultString, sortHanWordsList, sortHanFirstObjectList } from "../../../../../common/common";
import * as CONSTS from "../../../../../const";
import phoneSampleData from "../../phonebook/test.json"

//전화 설정 페이지
const GroupDetailEdit = observer(({ history, match, location}) => {

	const { userInfo, userData } = store;
	const userList = userInfo.getList();

	const CALLID_POPUP = "POPUP";
	//화면수정정보
	const [groupName, setGroupName] = useState("");
	const [oriGroupName, setOriGroupName] = useState("");
	const [groupAddrList, setGroupAddrList] = useState([]);
	const [groupAddrOriginList, setGroupAddrOriginList] = useState([]);
	
	const [groupAddrDrawList, setGroupAddrDrawList] = useState([]);
	const [delPbList, setPbDelList] = useState([]);
	const [callID, setCallID] = useState("");		//임시방안처리

	//popup 관련
	const [popSearchWords, setPopSearchWords] = useState(""); 
	const [popPbList, setPopPbList] = useState([]);
	const [popSrchDrawData, setPopSrchDrawData] = useState([]); 
	const [popDrawGroupData, setPopDrawGroupData] = useState([]); 
	const [popPbType, setPopPbType] = useState(CONSTS.ADDRESS_TYPE_CENTRIX);
	const [popAddPbList, setPopAddPbList] = useState([]);

	//temp save용
	const [groupAddrPopTmpList, setGroupAddrPopTmpList] = useState([]);

	//그룹코드
    const groupCode = match.params.detailid;
    //그룹상세정보
    const groupInfo = location.state.item;

	useEffect(() => {
		console.log('match.params', match.params); 
        console.log('location', location);

		//그룹명 설정
		setGroupName(groupInfo.GROUP_NAME);
		setOriGroupName(groupInfo.GROUP_NAME);

		//그룹내 연락처 조회
		getAddressListByGroup(groupCode);

	}, []);

	const getAddressListByGroup = async (groupId="") => {
        //get address from centrix group
        const centrixGroupAddrlist = await BizApi.GetCentrixPhonebookListByGroup(groupId);
		setGroupAddrList(sortHanWordsList(centrixGroupAddrlist, 'name', 'ASC'));
		setGroupAddrOriginList(centrixGroupAddrlist);
	}

	const goBackPage = () => {
		history.goBack();
	}

	const ClickDelPb = (e, _item) => {
		setPbDelList(delPbList => Array.from(delPbList).concat(_item));
	}

	useEffect(() => {
		console.log("change delPbList", delPbList);
		//그룹내 연락처 정보 현행화(UI상)
		let tmplist = [];
		let tmpAddlist = [];

		popAddPbList.map((_item, _idx) => {

			var retCnt = delPbList.filter((targetItem) => {
				if ( targetItem.no === _item.no ){
					return targetItem;
				}	
			}).length;

			if ( retCnt === 0 ){
				tmpAddlist.push(_item);
			}
		});
		setPopAddPbList(tmpAddlist);


		groupAddrList.map((_item, _idx) => {

			var retCnt = delPbList.filter((targetItem) => {
				if ( targetItem.no === _item.no ){
					return targetItem;
				}	
			}).length;

			if ( retCnt === 0 ){
				tmplist.push(_item);
			}
		});
		setGroupAddrList(tmplist);

	}, [delPbList]);

	const groupEdit = (e) => {

		const _groupName = defaultString($("#contact_name").val(), '');
		if ( _groupName === ''){
			//그룹명을 입력하세요.
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "그룹명을 입력하세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
			layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
			return 
		}

		if(_groupName.length < 2 || _groupName.length > 19){
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'그룹 수정 실패', 
				'2자이상 19자 이내로 입력해주세요.'
			);
			return;
		}

		const checkName = checkIMEEnabledType($('#contact_name').val());
		if(checkName !== true) {
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'알림', 
				'입력이 불가능한 특수문자가 포함되어 있습니다.<br />* 영문, 숫자, 한글 입력만 가능합니다.'
			);
			return;
		}

		if ( $('#contact_name').val() === oriGroupName && delPbList.length === 0 && popAddPbList.length === 0 ){
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'알림', 
				'변경된 정보가 없습니다.'
			);
			return;
		}

		layerPopup.showCommon(
            '#popup_onetitle_onemsgbold_twobutton', 
            null, 
            '그룹 수정', 
            '그룹을 수정하시겠어요?', 
            [
                function() {
                    layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
                },
                function() {

                    console.log('selected Item removeRecordFile =>');
					processEditGroup();
					
                }
            ]
        );
	}

	const processEditGroup = async () => {

		
		if ( $('#contact_name').val() !== oriGroupName) {
			//그룹명이 동일한 경우 오류발생함.
			const result = await CentrexApi.modifyAddressGroup({
				group_code : groupInfo.GROUP_CODE
				,parent_code : groupInfo.PARENT_GROUP
				,group_name : $('#contact_name').val()
			});

			if ( result.data.SVC_RT !== '0000'){
				//연락처 정보 변경
				toast('그룹수정 중 오류가 발생하였습니다.');
				layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
				return false;
			}
		}else{
			//등록시에만 처리
			//toast('이름이 같은 그룹이 있습니다. 다시 입력해 주세요.');
			//return false;
		}

		let successYNFlag = false;
		try{
			if ( delPbList !== null && delPbList.length > 0 ){
				const resultDelAddr = await CentrexApi.mvaddresslist({
					new_groupcode: "0"	//삭제시 그룹없음으로 이동
					,peer_no_list : delPbList.map(e => e.no).join(",")
				});
			
				if ( resultDelAddr.data.SVC_RT === '0000'){
					successYNFlag = true;
				}else{
					successYNFlag = false;
				}
			}else{
				successYNFlag = true;
			}
			
		}catch(e){
			successYNFlag = false;
			//layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
			//toast('그룹 연락처 변경 중 오류가 발생하였습니다.');
		}

		try{
			//CORS에러로 인하여 try / catch 처리
			if ( popAddPbList.length > 0 && successYNFlag){
				const resultDelAddr = await CentrexApi.mvaddresslist({
					new_groupcode: groupInfo.GROUP_CODE
					,peer_no_list : popAddPbList.map(e => e.no).join(",")
				});
				
				if ( resultDelAddr.data.SVC_RT === '0000'){
					successYNFlag = true;
				}else{
					successYNFlag = false;
				}
			}else{
				successYNFlag = true;
			}
			
		}catch(e){
			successYNFlag = false;
		}

		layerPopup.hide('#popup_onetitle_onemsgbold_twobutton');
		if (successYNFlag){
			BizApi.makeCentrixPhonebookList();
			toast('그룹 정보가 변경되었습니다.');
			history.replace('/shop/call/phonebook/group');
		}else{
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'그룹 수정 실패', 
				'그룹 수정이 실패했습니다.\n다시 시도해주세요.'
			);
		}
		
	}

	const ChangeInputText = (e) => {
		if ( e.target.id === 'contact_name') setGroupName(e.target.value);
	}

	const checkAddressList = () => {
		setTimeout( () => {
			//팝업 재 오픈시 변화없는 주소록으로 인한 처리가 필요함.
			//init
			$('input[name="contactGroupAdd"]').prop('checked', false);
			$('input[name="contactGroupAll"]').prop('checked', false);
			
			//console.log('************change popPbList popAddPbList=>', popAddPbList);
			groupAddrPopTmpList.map((_roofItem, _idx) => {
				console.log("selItem => ", $('input[name="contactGroupAdd"][value="NO_' + _roofItem.no + '"]'));
				$('input[name="contactGroupAdd"][value="NO_' + _roofItem.no + '"]').prop('checked',true);
			});

		}, 500);
	}

	const ClickOpenPopup = async (e) => {
		layerPopup.show('#popup_groupcontact_inpage');

		//주소정보 화면 그리기 
		setPopPbList(userData.dataset.pblistForCentrix);

		//이미 추가한 목록 넣기
		if ( popAddPbList !== null && popAddPbList.length > 0 ){
			setGroupAddrPopTmpList(popAddPbList);
		}else{
			//이미 추가한 목록이 없거나, 처음인 경우
			setGroupAddrPopTmpList([]);
		}
	}

	//---------------------------[ POPUP Logic]---------------------------
	const ClickHidePopup = (e) => {
		layerPopup.hide('#popup_groupcontact_inpage');
		//setCallID("");

		$('#popSechNm').val('');
		setPopSearchWords('');		//검색어 초기화
		setPopPbList([]);			//주소록 초기화(팝업)

	}

	const ChangeSearchWords = (e) => {
		setPopSearchWords(e.target.value);
	}

	const filterPbData = (_list, _words) => {
		return popPbList.filter((targetItem) => {
			if ( _words === '' || (targetItem.name)?.includes(_words) || (targetItem.phone)?.includes(_words) ){
				return targetItem;
			}	
		});
	}

	useEffect(() => {
		//console.log("change popPbList, popSearchWords", popPbList);
		if ( popPbList !== null && popPbList.length > 0 ){
			setPopSrchDrawData(filterPbData(popPbList, popSearchWords));
		}else{
			setPopSrchDrawData([]);
		}

	}, [popPbList, popSearchWords]);


	useEffect(() => {
		console.log("change popSrchDrawData", popSrchDrawData);
		if ( popSrchDrawData !== null && popSrchDrawData.length > 0 ){
			setPopDrawGroupData(transformAddressDrawData(popSrchDrawData));
		}else{
			setPopDrawGroupData([]);
		}
	}, [popSrchDrawData]);


	useEffect(() => {

		//검색어가 바뀌거나, 주소가 변경되어 다시 설정이 되면 발생하는 이벤트
		if ( popDrawGroupData !== null && popDrawGroupData.length > 0 ){
			//연락처 데이터 화면 표기
			//선택된 정보 체크버튼 표기
			checkAddressList();
		}
		

	}, [popDrawGroupData]);


	useEffect(() => {
		//그룹 연락처 추가 발생시
		console.log("change popAddPbList", popAddPbList);
		changeGroupAddrCurList();
		//setPopDrawGroupData(transformAddressDrawData(popSrchDrawData));
	}, [popAddPbList]);

	/*
	useEffect(() => {

		//선택항목 없을 때
		$('input[name="contactGroupAdd"]:checked').attr('checked', false);
		$('input[name="contactGroupAll"]').attr('checked', false);


		//연락처 추가시 또는 팝업 오픈시
		//console.log('************change groupAddrPopTmpList=>', groupAddrPopTmpList);
		if ( groupAddrPopTmpList !== null && groupAddrPopTmpList.length > 0 ){
			groupAddrPopTmpList.map((_roofItem, _idx) => {
				console.log("selItem => ", $('input[name="contactGroupAdd"][value="' + _roofItem.no + '"]'));
				$('input[name="contactGroupAdd"][value="' + _roofItem.no + '"]').attr('checked',true);
			});
		}

	}, [groupAddrPopTmpList]);
	*/
	

	const ClickItemSelect = (e, _item) => {

		if ( groupAddrPopTmpList.length > 20 ) {
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'알림', 
				'최대 20개까지 선택 가능합니다.'
			);

			$(e.target).prop('checked', false);
			return false;
		}


		if ( $(e.target).prop('checked') == true ){
			if ( groupAddrOriginList.filter((targetItem) => {

					if ( targetItem.no === _item.no){
                        let existDelLstYN = delPbList.filter((targetDelItem) => {
                            if ( targetDelItem.no === targetItem.no ){
                                return targetDelItem;
                            }
                        }).length > 0;

                        if ( !existDelLstYN ) return targetItem;
					}
				}).length > 0 ) {

				toast('이미 추가된 연락처 입니다.');
				$(e.target).prop('checked', false);
				return false;
			}else{
				//항목추가
				let tmpArr = [];
				tmpArr.push(_item);

				if ( groupAddrPopTmpList !== null && groupAddrPopTmpList.length > 0 ){
					setGroupAddrPopTmpList(groupAddrPopTmpList => Array.from(groupAddrPopTmpList).concat(tmpArr));
				}else{
					setGroupAddrPopTmpList(tmpArr);
				}
				
				$(e.target).prop('checked', true);
			}
		} else {
			//체크 해제시 항목삭제
			setGroupAddrPopTmpList( groupAddrPopTmpList => 
																groupAddrPopTmpList.filter( (targetItem) => {
																	if ( targetItem.no !== _item.no) return targetItem
																})
			);
		}
		
	}

	/**
	 * 제거
	 * 
	 * @param {*} e 
	 * @returns 
	 */
	const ClickCheckAll = (e) => {
		const checkboxes = document.getElementsByName('contactGroupAdd');
  
		if ( Array.from(checkboxes).length > 20 ) {
			layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'알림', 
				'최대 20개까지 선택 가능합니다.'
			);

			$(e.target).prop('checked', false);
			return false;
		}
		
		checkboxes.forEach((checkbox) => {
			checkbox.checked = e.target.checked
		})

		//RefreshClickedCount();
	}

	/**
	 * 미사용
	 */
	const RefreshClickedCount = () => {
		const query = 'input[name="contactGroupAdd"]:checked';
		const selectedElements = document.querySelectorAll(query);
	
		// 선택된 목록의 갯수 세기
		const selectedElementsCnt = selectedElements.length;
	
		// 출력
		$('#chk_sel_num').text(selectedElementsCnt);
	}

	const ClickPopupAdd = (e) => {

		console.log("ClickPopupAdd=>", groupAddrPopTmpList);
		setPopAddPbList(groupAddrPopTmpList);

		ClickHidePopup();
	}

	/**
	 * 초기데이터 - 삭제데이터 + 추가데이터
	 */
	const changeGroupAddrCurList = () => {
		let resultAddrList=  [];
		setGroupAddrList([]);		//데이터 변화가 없으면 사라짐.

		groupAddrOriginList.map((_item, _idx) => {

			var retCnt = delPbList.filter((targetItem) => {
				if ( targetItem.no === _item.no ){
					return targetItem;
				}	
			}).length;

			if ( retCnt === 0 ){
				resultAddrList.push(_item);
			}
		});

		if ( resultAddrList.length > 0 ){
			resultAddrList = Array.from(resultAddrList).concat(popAddPbList);
		}else{
			resultAddrList = popAddPbList;
		}

		//선택데이터 변경
		console.log('최초 그룹편집시 존재한 연락처 데이터 =>', groupAddrOriginList);
		console.log('검색추가로 등록된 연락처 데이터 =>', popAddPbList);
		console.log('결과 데이터 =>', resultAddrList);

		setGroupAddrList(resultAddrList);
	}

	const textClearOnClick = (e) => {
		setGroupName("");
	}

	const ClickReset2 = () => {
		console.log('ClickReset');
		$('#popSechNm').val('');
		setPopSearchWords('');
	}

  	return (
    <>
		<div className="content store">
			<div className="page_title_wrap3 group">
				<div className="inner">
					<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
					<h2>그룹 수정</h2>
					<button type="button" className="btn_complete" onClick={(e) => groupEdit(e)}>완료</button>
				</div>
			</div>
			<div className="contact_group_edit">
				<div className="contact_info">
					<div className="inner">
						<div className="name_wrap">
							<div className="input_box style3">
								<input type="text" id="contact_name" value={groupName||""} maxLength="20" onChange={(e)=> ChangeInputText(e)}/>
								<label htmlFor="contact_name" className={groupName !== '' ? "label_placeholder hidden":"label_placeholder"} >그룹명을 입력해주세요.</label>
								<span className="ip_gradient"></span>
								<span className={groupName !== '' ? "ip_clear hidden":"ip_clear"} onClick={()=>textClearOnClick()}>초기화</span>
							</div>
						</div>
					</div>
				</div>
				<div className="content_gray">
					<ul className="contact_detail_list">
						{
							/*
						<li className="save_locate">
							<span className="title">저장위치</span>
							<span className="locate_name" onClick={(e) => ClickSampleGroupData(e)}>인터넷 전화 주소록</span>
						</li>
							*/
						}
						<li>
							<span className="title">연락처</span>
							<button type="button" className="btn_round purple usetap" onClick={(e)=> ClickOpenPopup(e)}>추가</button>
						</li>
					</ul>
					<ul className="client_list">
						{ 
							groupAddrList !== null && groupAddrList.length > 0 ?
							groupAddrList.map((addressItem, idx) => (
								<>
									<li key={idx}>
										<span className="client_name">{addressItem.name}</span>
										<button type="button" className="btn_small_x usetap blind" onClick={(e)=> ClickDelPb(e, addressItem)}>삭제</button>
									</li>
								</>
							)) :
							<></>
						}
					</ul>
				</div>
			</div>
		</div>

		<div className="modal fadeInUp" id="popup_groupcontact_inpage"> 
			<div className="dim"></div>
			<div className="modal_inner modal_up max_h">
				<div className="modal_group_add contact">
					<span className="modal_title">그룹추가</span>
					<div className="chk_wrap">
                        { /*
						<input type="checkbox" id="contactGroupAll" name="contactGroupAll" onClick={(e) => ClickCheckAll(e)} />
						<label for="contactGroupAll" className="btn_all_sel">모두선택</label>
                        */}
					</div>
					<span className="info_chk_sel"></span>
					<div className="input_box style1">
						<input type="text" id="popSechNm" placeholder="이름, 전화번호를 검색해보세요." onChange={(e)=> ChangeSearchWords(e)}/>
						<span className={popSearchWords !== '' ? "ip_clear hidden":"ip_clear"}  onClick={()=>ClickReset2()}>초기화</span>
						<span className="ico_srh"></span>
					</div>
					{/*
					<div className="contact_cate row">
						
						<div className="btn_cate_wrap2" id="memo_cate">
							<button type="button" className="tab_item active" onClick={(e)=> GetAddressList(e, CALLID_POPUP, CONSTS.ADDRESS_TYPE_CENTRIX)}>인터넷 전화 주소록</button>
							<button type="button" className="tab_item" onClick={(e)=> GetAddressList(e, CALLID_POPUP, CONSTS.ADDRESS_TYPE_DEVICE)}>휴대폰 주소록</button>
						</div>
						
						<button type="button" className="btn_refresh_txt usetap" onClick={(e)=> ClickRefresh(e)}><i className="ico_refresh"></i>새로고침</button>
					</div>
					*/}
					<div className="top_info">
						<span className="total_num"><b><span id="chk_sel_num">{groupAddrPopTmpList.length}</span> 개 선택</b> / 총 {popSrchDrawData.length}개</span>
					</div>
					<div className="contact_list_wrap">
						{ 
							popDrawGroupData !== null && popDrawGroupData.length > 0 ?
							popDrawGroupData.map((groupItem, gidx) => (
								<>
									<span className="devide_bar">{groupItem.title}</span>
									{
										(groupItem.items.length !== null || groupItem.items !== undefined ) ?
										groupItem.items.map((addressItem, index) => (
											<>
												<div id={gidx * 1000 +index} className="checkbox_green">
													<input type="checkbox" id={"contactGroupAdd_" + (gidx * 1000 + index)} name="contactGroupAdd" value={"NO_" + addressItem.no}
															{...{booktype: addressItem.bookType
																,drawgroup: addressItem.drawgroup
																,etc: addressItem.etc
																,groupcode: addressItem.groupCode
																,groupname: addressItem.groupName
																,addressname: addressItem.name
																,no: addressItem.no
																,phone: addressItem.phone
																,phone2: addressItem.phone2
															}}
														onClick={(e)=>ClickItemSelect(e, addressItem)} />
													<label for={"contactGroupAdd_" + (gidx * 1000 + index)} className="usetap">
														<span className="i_name">{addressItem.name}</span>
														<span className="i_num">{addressItem.phone}</span>
													</label>
												</div>
											</>
										)) : 
										<>
										
										</>
									}
								</>
							)) :
							<>
								<div className="data_none">
									<div className="none_wrap">
										<span className="none_img"></span>
										<p>검색 결과가 없습니다.</p>
									</div>
								</div> 
							</>
						}
					</div>
				</div>
			</div>
			<div className="btn_box fixed">
				<div className="inner">
					<button type="button" className="btn_half btn_line2 usetap" onClick={()=>ClickHidePopup()}>취소</button>
					<button type="button" className="btn_half btn_point usetap" onClick={(e)=>ClickPopupAdd(e)}>추가</button>
				</div>
			</div>
		</div>
    </>
  );
});
export default GroupDetailEdit;