import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore'
import { layerPopup } from "../../libs/common";

const PreListen = observer(() => {
    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);


   

    const [checkedInputs, setCheckedInputs] = useState([]);
    const [checkedButton, setCheckedButton] = useState(false);
    const [name, setName] = useState("");
    const [bgName, setBgName] = useState("");
    const [check, setCheck] = useState(false);


    useEffect(() => {       
        // console.log("Home default useEffect..");
        // let homeCard = LocalStore.getHomeCard();
        // if(homeCard !== null) {
        //     userData.setHomeList(homeCard);
        // }
        // console.log(homeCard);

        const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : Main.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'CALL_BACKPRESS':
                   
                    //console.log("bBack: ", bBack);
                    setCheckedButton(true);
                break;

               


            default:
                break;
            }

        });

        setCheckedInputs([1]);
        $(`[name="bg_01"]`).attr('class', "btn_list_play usetap on") 


        return (() => {
            subscription.unsubscribe();
        })
    }, []);

    // 배경음 default 값 설정
    useEffect(()=> {
        console.log("preListenPopup useEffect...")
         console.log("현재 체크된 checkedInputs ===>", checkedInputs);
         
         
         
    },[checkedInputs])

    useEffect(() => {
        
             if(checkedInputs[0] === 0){
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap on") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap") 
                setName("배경음 사용 안함");
                setBgName("");
             }
             if(checkedInputs[0] === 1){
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap on")
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")   
                setName("비발디 사계 봄")
                setBgName("bg_01")
                $('div').scrollTop(0)// 팝업 새로 열었을 때 팝업 스크롤 상단 위치
             }
             if(checkedInputs[0] === 2){
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap on") 
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap") 
                setName("비발디 사계 가을");
                setBgName("bg_02");
             }
             if(checkedInputs[0] === 3){
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap on") 
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                setName("비발디 수상곡 1번 중")
                setBgName("bg_03")
             }
             if(checkedInputs[0] === 4){
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap on") 
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                setName("헨델 왕국의 불꽃놀이 음악 중")
                setBgName("bg_04")
             }
             if(checkedInputs[0] === 5){
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap on") 
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                setName("바하 브란덴부르크 협주곡 2번 중")
                setBgName("bg_05")
             }
             if(checkedInputs[0] === 6){
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap on") 
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                setName("비발디 사계 가을 중")
                setBgName("bg_06")
             }
             if(checkedInputs[0] === 7){
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap on")
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                setName("모짜르트 소야곡 중 1")
                setBgName("bg_07")
             }
             if(checkedInputs[0] === 8){
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap on")
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")   
                setName("바하 브란덴부르크 협주곡 3번 중")
                setBgName("bg_08")
             }
             if(checkedInputs[0] === 9){
                $(`[name="bg_09"]`).attr('class', "btn_list_play usetap on")
                $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_07"]`).attr('class', "btn_list_play usetap") 
                $(`[name="bg_08"]`).attr('class', "btn_list_play usetap")   
                setName("모짜르트 소야곡 중 2")
                setBgName("bg_09")
             }
        
       

        setCheckedButton(false);
    },[checkedButton,userData.dataset.bgCheck])

    useEffect(() => {
        console.log("zzzz2",checkedInputs)
    if(userData.dataset.bgCheck === true){
         setCheckedInputs([1]);
         userInfo.setDefalutGreeting({
            ringName : "비발디 사계 봄",
            ringEngName : "bg_01",
           });
    }  
    userData.setBgCheck(false);
    },[userData.dataset.bgCheck,checkedInputs])

    const listenBtnHandleClick = (e, id) => {
        

        console.log("확인", e.name, id);
        if(id || id === 0){
            if($(`[name=${e.name}]`).attr('class') === "btn_list_play usetap on" ){
                console.log("확인", e.name, id);
                $(`[name=${e.name}]`).attr('class', "btn_list_play usetap") 
            }
            else{
            
                if(e.name === "bg_00"){
                    console.log("확인2", e.name);
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap") 
                    if(checkedButton !== true)
                    {
                        setName(e.value);
                        setBgName("");
                    }    
                } 
                else if(e.name === "bg_01"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                    //setCheckedInputs([id])
                    if(checkedButton !== true)
                    {
                        setName(e.value);
                        setBgName(e.name);
                    }
                }
                   else if(e.name === "bg_02"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                    //setCheckedInputs([id])
                    if(checkedButton !== true)
                    {
                        setName(e.value);
                        setBgName(e.name);
                    }
                }
                   else if(e.name === "bg_03"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                    //setCheckedInputs([id])
                    if(checkedButton !== true)
                        {
                            setName(e.value);
                            setBgName(e.name);
                        }
                }
                   else if(e.name === "bg_04"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                    //setCheckedInputs([id])
                    if(checkedButton !== true)
                    {
                        setName(e.value);
                        setBgName(e.name);
                    }
                }
                   else if(e.name === "bg_05"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                    //setCheckedInputs([id])
                    if(checkedButton !== true)
                    {
                        setName(e.value);
                        setBgName(e.name);
                    }
                   }
                   else if(e.name === "bg_06"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                    //setCheckedInputs([id])
                    if(checkedButton !== true)
                    {
                        setName(e.value);
                        setBgName(e.name);
                    }
                }
                   else if(e.name === "bg_07"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on")
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap") 
                   // setCheckedInputs([id])
                   if(checkedButton !== true)
                   {
                       setName(e.value);
                       setBgName(e.name)
                   }
                }
                  else if(e.name === "bg_08"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap")  
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on")
                    $(`[name="bg_09"]`).attr('class', "btn_list_play usetap")  
                   // setCheckedInputs([id])
                   if(checkedButton !== true)
                   {
                       setName(e.value);
                       setBgName(e.name)
                   }
                }
                  else if(e.name === "bg_09"){
                    $(`[name="bg_00"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_01"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_02"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_03"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_04"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_05"]`).attr('class', "btn_list_play usetap")
                    $(`[name="bg_06"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_07"]`).attr('class', "btn_list_play usetap") 
                    $(`[name="bg_08"]`).attr('class', "btn_list_play usetap") 
                    $(`[name=${e.name}]`).attr('class', "btn_list_play usetap on") 
                   // setCheckedInputs([id])
                   if(checkedButton !== true)
                   {
                       setName(e.value);
                       setBgName(e.name)
                   }
                }
                     
            
            }
        } 
}
//     //여러개의 체크박스 중 하나만 관리
//     const inputChangeHandler = (checked, id) => {
//         if(checked) {
//             setCheckedInputs([id]);
      
//         }else{
//         // 체크 해제
//             setCheckedInputs(checkedInputs.filter((el)=> el !== id));
//     }
//   };

const closePopup = () => {
    //$(`[name=${bgName}]`).attr('class', "btn_list_play usetap on")
    // userInfo.setDefalutGreeting({
    //     ringName : "비발디 사계 봄",
    //     ringEngName : "bg_01",
    // });  
    //setCheckedInputs([])


console.log("zzzzz1")
    setCheckedButton(true);
    layerPopup.hide('#popup_prelisten')
}
const closePopup2 = () => {
    if(bgName === ""){
        $(`[name="bg_00"]`).attr('class', "btn_list_play usetap on") 
        setCheckedInputs([0])
    } 
    else{
    $(`[name=${bgName}]`).attr('class', "btn_list_play usetap on") 
        if(bgName === "") setCheckedInputs([0])
        if(bgName === "bg_01") setCheckedInputs([1])
        if(bgName === "bg_02") setCheckedInputs([2])
        if(bgName === "bg_03") setCheckedInputs([3])
        if(bgName === "bg_04") setCheckedInputs([4])
        if(bgName === "bg_05") setCheckedInputs([5])
        if(bgName === "bg_06") setCheckedInputs([6])
        if(bgName === "bg_07") setCheckedInputs([7])
        if(bgName === "bg_08") setCheckedInputs([8])
        if(bgName === "bg_09") setCheckedInputs([9])
    }
    userInfo.setDefalutGreeting({
        ringName : name,
        ringEngName : bgName,
    });  
    setCheckedButton(true);
    setCheck(true);
    layerPopup.hide('#popup_prelisten')
}

    return (
        <>
            <div className="modal fadeInUp" id="popup_prelisten"> 
                <div className="dim" onClick={closePopup}></div>
                <div className="modal_inner modal_up">
                    <span className="modal_title">배경음</span>
                    <ul className="modal_play_list">
                        <li>
                            <button type="button" value="배경음 사용 안함" name="bg_00" className= "btn_list_play usetap" onClick={(e)=>listenBtnHandleClick(e.currentTarget, 0)}>배경음 사용 안함</button>
                        </li>
                        <li>
                            <button type="button" value="비발디 사계 봄" name="bg_01" className= "btn_list_play usetap"  onClick={(e)=>listenBtnHandleClick(e.currentTarget, 1)}>비발디 사계 봄</button>
                        </li>
                        <li>
                            <button type="button" value="비발디 사계 가을" name="bg_02" className= "btn_list_play usetap"   onClick={(e)=>listenBtnHandleClick(e.currentTarget,2)}>비발디 사계 가을</button>
                        </li>
                        <li>
                            <button type="button" value="비발디 수상곡 1번 중" name="bg_03" className= "btn_list_play usetap"  onClick={(e)=>listenBtnHandleClick(e.currentTarget,3)}>비발디 수상곡 1번 중</button>
                        </li>
                        <li>
                            <button type="button" value="헨델 왕국의 불꽃놀이 음악 중" name="bg_04" className= "btn_list_play usetap"   onClick={(e)=>listenBtnHandleClick(e.currentTarget,4)}>헨델 왕국의 불꽃놀이 음악 중</button>
                        </li>
                        <li>
                            <button type="button" value="바하 브란덴부르크 협주곡 2번 중" name="bg_05" className= "btn_list_play usetap"  onClick={(e)=>listenBtnHandleClick(e.currentTarget, 5)}>바하 브란덴부르크 협주곡 2번 중</button>
                        </li>
                        <li>
                            <button type="button" value="비발디 사계 가을 중" name="bg_06" className= "btn_list_play usetap"  onClick={(e)=>listenBtnHandleClick(e.currentTarget, 6)}>비발디 사계 가을 중</button>
                        </li>
                        <li>
                            <button type="button" value="모짜르트 소야곡 중 1" name="bg_07" className= "btn_list_play usetap"  onClick={(e)=>listenBtnHandleClick(e.currentTarget, 7)}>모짜르트 소야곡 중 1</button>
                        </li>
                        <li>
                            <button type="button" value="바하 브란덴부르크 협주곡 3번 중" name="bg_08" className= "btn_list_play usetap" onClick={(e)=>listenBtnHandleClick(e.currentTarget, 8)}>바하 브란덴부르크 협주곡 3번 중</button>
                        </li>
                        <li>
                            <button type="button" value="모짜르트 소야곡 중 2" name="bg_09" className= "btn_list_play usetap" onClick={(e)=>listenBtnHandleClick(e.currentTarget, 9)}>모짜르트 소야곡 중 2</button>
                        </li>
                    </ul>
                </div>
                <div className="btn_box modal_in_fixed">
                    <button type="button" className="btn_lg btn_point usetap"  onClick={closePopup2}>확인</button>
                </div>
            </div>
        </>
    );
});
export default PreListen;
// 샘플 삭제금지
{/* <i className="ico_play"></i> */}