import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import CentrexApi from "../../../../api/CentrexApi";
import { floatUI } from '../../../../libs/floating'
import { tabUi } from '../../../../libs/tab'
import { textChgEdit, textChgBlock, textareaUi, layerPopup } from "../../../../libs/common"



//전화 설정 페이지
const CallLogDetail = observer(({ history }) => {
  // const [forwardType, setFowardType] = useEffect([]);
  // const [mohInfo, setMohInfo] = useEffect([]);
     const [callFowarding, SetCallFowarding] = useState(false);
     const [callLink, SetCallLink] = useState(false);
     const [callFowardingToggle, SetCallFowardingToggle] = useState(true);
     const [callLinkToggle, SetCallLinkToggle] = useState(true);

	useEffect(() => {
  store.userData.setShopMainTabList('call');
  
  if($('#wrap').hasClass('gnb_bottom')) {
      $('#wrap').removeClass('gnb_bottom')
      $('#wrap').addClass('bg_gray');
    }          
    return () => {
      if($('#wrap').hasClass('bg_gray')) {
        $('#wrap').removeClass('bg_gray');
        $('#wrap').addClass('gnb_bottom');
      }
    };
	}, []);

 


  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  const callFowardSet = userInfo.dataset.callFoward;
  const callLinkSet = userInfo.dataset.callLink;

  const blackList = userInfo.dataset.blackList;
  const blackAndAnonymous = userInfo.dataset.blackAndAnonymous;
  const allBlock = userInfo.dataset.allBlock;

  //착신 전화 조회
  // useEffect(() => {
  //   CentrexApi.getForwardTypeInfo({
  //     id: "",
  //     pass: "",
  //   })
  //     .then((result) => {
  //       setFowardType(result.datas);
  //       console.log("getSetFowardType ===>", result.datas);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // });

  //현재 설정된 통화 연결음 조회 API
  // useEffect(() => {
  //   CentrexApi.getMohInfo({
  //     id: "",
  //     pass: "",
  //     peer_exten: "", //조회할 내선번호 -> 고객관리자만 변경가능하며, 자신의 내선번호 // 필수아님 옵션
  //   })
  //     .then((result) => {
  //       setMohInfo(result.datas);
  //       console.log("getMohInfo ===>", result.datas);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // });
  
  // ON/OFF 시 상태관리 함수
  const callFowardingToggleOnHandleClick = () => {
    SetCallFowardingToggle(!callFowardingToggle);
    console.log(callFowardingToggle);
  }
  const callLinkToggleOnHandleClick = () => {
    SetCallLinkToggle(!callLinkToggle);
    console.log(callLinkToggle);
  }

  //버튼식 시 상태관리 함수
const callFowardingHandleClick = useCallback(() => { 
  // e.preventDefault()
  SetCallFowarding(prevState => !prevState);//함수형 업데이트
  console.log(callFowarding)
})
const callLinkHandleClick = useCallback(() => { 
  // e.preventDefault()
  SetCallLink(prevState => !prevState);//함수형 업데이트
  console.log(callLink)
})

const goBackPage = () => {
    history.goBack();
}
const CallLogDetailEditClick = (submenu= null) => {
    history.push(`detail/${submenu}`)
}

const TextChangeClick = (e) => {
    textChgBlock(e);
    console.log("11ㄷㅋ",e)
    e.classList.toggle('on');
}

const CallSent = (msg) => {
  NativeBridge.callPhone(msg);
}
const MsgMove = () => {
  store.userData.setShopMainTabList('msg');
  history.push('/shop/msg');
}



// 여러개의 체크박스 중 하나만 관리
// const inputChangeHandler = (checked, id) => {
// if(checked) {
//   setCheckedInputs([id]);
// }else{
//   // 체크 해제
//   setCheckedInputs(checkedInputs.filter((el)=> el !== id));
// }
// };


  return (
    <>
        <div className="content store">
            <div className="page_title_wrap3">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                    <h2>연락처 상세</h2>
                </div>
            </div>
            <div className="contact_info">
              <div className="unregi_box">
                <span className="i_num">010-1234-5678</span>
                <button type="button" className="btn_regi" onClick={()=> history.push('/shop/call/calllog/detail/reg')}>연락처 추가</button>
                <div className="btn_wrap">
                  <button type="button" className="btn_call usetap blind" onClick={()=> CallSent('070-0000-0000')}>전화 발신</button>
                  <button type="button" className="btn_sms usetap blind" onClick={()=> MsgMove()}>메시지 작성 화면 이동</button>
                </div>
              </div>
            </div>
            <ul className="contact_detail_list">
                <li className="detail_group_list">
                    <span className="title">그룹</span>
                    <button href="#none" className="btn_round purple usetap" onClick={()=>layerPopup.show('#popup_calladdgroup')}>추가</button>
                    <div className="group_box">
                        <span>고객</span>
                        <span>VIP</span>
                        <span>단골</span>
                        <span>동네</span>
                        <span>단골그룹2</span>
                        <span>50대 고객</span>
                    </div>
                </li>
                <li>
                    <span className="title">수신차단</span>
                    <button type="button" className="btn_round red usetap" onClick={(e)=>TextChangeClick(e.target)}>차단</button>
                </li>
            </ul>
        </div>
    </>
  );
});
export default CallLogDetail;