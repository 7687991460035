import React, {useEffect, useState} from 'react';
import { Link, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../store'
import { layerPopup } from '../../libs/common';
import * as LocalStore from '../../store/LocalStore'
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import { tabUi } from '../../libs/tab';
import { webUI } from '../../libs/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";
import SohoApi from '../../api/SohoApi';
import BizApi from '../../api/BizApi';
import MyFamilyAccount from './MyFamilyAccount';
import MyTemporaryAccount from './MyTemporaryAccount';
import { floatUI } from '../../libs/floating';

SwiperCore.use([ Pagination, Scrollbar ]);

const My = observer(({ history }) => {

    const { userInfo, userData } = store;
	const userList = userInfo.getList();
    console.log(userList);
	const [monthBills, setMonthBills] = useState(null)
	const [monthBillsMonth, setMonthBillsMonth] = useState(null)
	const [retCode, setRetCode] = useState('0000')
	const [serviceDetails, setServiceDetails] = useState(null)
	const [myInfo, setMyinfo] = useState(null)
	const [monthcheck, setMonthCheck] = useState(false)

	var StoreNmIndex = 0;

	useEffect(() => {
		initTabMenu();
		floatUI.init();
		$('.content.my .i_pagination').addClass('i_disabled');
		$("html, body").removeClass("noscroll");
		
		if($("#wrap").hasClass('gnb_bottom')) {
			$("#wrap").removeClass('bg_gray');
		}
    }, []);

	const initTabMenu = () => {
		if($('#tab_1depth ul li').length > 0 ) {
			let container = document.getElementById('tab_1depth');
			StoreNmIndex = LocalStore.getStoreList().map((item, index) => item.storeId).indexOf(LocalStore.getStoreId());
			tabUi.initSubTab(container, StoreNmIndex);
			webUI.animatedScrollTo(document.getElementById('tab_1depth'),(container.querySelectorAll('li')[StoreNmIndex].offsetLeft + 
			container.querySelectorAll('li')[StoreNmIndex].clientWidth * 0.5) - (container.clientWidth * 0.5), 300);
		}
	}

    useEffect(() => {
		if (userData.dataset.storeList === null) {
			userData.setStoreList(LocalStore.getStoreList());
		}
		if(LocalStore.getFamilyYn() === 'Y') {
			userInfo.setFamilyYn('Y')
		}
		if(LocalStore.getIsTempUser() === false && LocalStore.getFamilyYn() === 'N') {
			monthBill();
			ServiceJoin();
			MyInfo();
		}
    }, []);

	useEffect(() => {
		if(monthBills !== null) {
			if(monthBills.length > 1) {
				$('.content.my .i_pagination').removeClass('i_disabled');
			}
		}
	}, [monthBills])

	// useEffect(() => {
	// 	if((monthBillsMonth === null || monthBillsMonth === "") && monthcheck) {
	// 		$('.tooltip_box').css('left','3.4rem')
	// 	}
	// }, [monthBillsMonth, monthcheck])

	const monthBill = async() => {
		const monthBillList = await SohoApi.getMonthBillList({})
		setRetCode(monthBillList.retCode);
		if(monthBillList.retCode === "0000") {
			setMonthBills(monthBillList.data.list);
			setMonthBillsMonth(monthBillList.data.billMonth)
			console.log('사용자 요금 조회', monthBillList.data.billMonth);
		} else {
			setMonthCheck(true)
		}
	}
	
	const ServiceJoin = async() => {
		const ServiceJoinList = await SohoApi.getMyServiceDetailList({ 
			'ownerUserId': LocalStore.getOwnerId(), 
			'storId': LocalStore.getStoreId()
		})
		setServiceDetails(ServiceJoinList?.data);
		console.log("서비스 가입여부", ServiceJoinList?.data)
	}

	const MyInfo = async() => {
		const MyInfoList = await SohoApi.getMyInfo({})
		setMyinfo(MyInfoList?.data);
		console.log("사용자 정보 조회", MyInfoList?.data);
	}

	const ShopClick = async(storeId=null, storeNm=null) => {
		NativeBridge.sendUserActionLog('menu', 'P005');
		await BizApi.procChgStore(storeId);
		await ServiceJoin()
	}
	

	const ToggleClick = (index=null) => {
		if(index === null) return;
		if(monthBills[index].billInfo !== null && monthBills[index].billInfo.length > 0) {
			NativeBridge.sendUserActionLog('menu', 'P004');
			$('.charge_rolling').toggleClass('on');
		}
	}
	const funcTooltip = (e,msg) => {
		NativeBridge.sendUserActionLog('detail', 'D067');
		e.stopPropagation();
		document.querySelector(msg).classList.toggle("on");
	}

	const ReportRegisterClick = (msg, e) => {
		let menuCode = '';
		if(e.target.value === "(부가)통화연결음"){
			menuCode = 'P007';
			userData.setMyPageCallPopupData("(부가)통화연결음")
		} else if(e.target.value === "(부가)우리매장알림메시지"){
			menuCode = 'P008';
			userData.setMyPageCallPopupData("(부가)우리매장알림메시지")
		} else if(e.target.value === "(부가)TTS 음원제작(정액형)"){
			menuCode = 'P009';
			userData.setMyPageCallPopupData("(부가)TTS 음원제작(정액형)")
		} else if(e.target.value === "(부가)TTS 음원제작(1회용)"){
			menuCode = 'P009';
			userData.setMyPageCallPopupData("(부가)TTS 음원제작(1회용)")
		} else if(e.target.value === "(부가)출입관리기"){	
			//console.log("zzzz",e.target.value);
			menuCode = 'P011';
			userData.setMyPageCallPopupData("(부가)출입관리기");
		}

		NativeBridge.sendUserActionLog('menu', menuCode);

		switch (msg) {
			case '101':
				menuCode = 'P015'
				NativeBridge.sendUserActionLog('menu', menuCode);
				break;
			case '1800-8000':
				menuCode = 'P014'	
				layerPopup.show('#popup_ringregi');
				break;
			default:
				break;
		}
	}

	const FloatCsClick = (e, msg) => {
		e.preventDefault();
		e.stopPropagation();
		
		let menuCode = '';
		switch (msg) {
			case '1544-8585':
				menuCode = 'P015'
				NativeBridge.sendUserActionLog('menu', menuCode);
				NativeBridge.callPhone(msg)
				break;
			case '1800-8300':
				menuCode = 'P014'
				NativeBridge.sendUserActionLog('menu', menuCode);
				NativeBridge.callPhone(msg)
				break;
			default:
				break;
		}
	}

	const myTopButtonClick = (urls, btnName, menuCode) => {
		console.log('버튼이름', btnName)
		switch (btnName)
		{
		  case "가입관리" :
			NativeBridge.sendUserActionLog('menu', menuCode);
			history.replace(`${urls}`)
			break;
		  case "매장편집" :
			NativeBridge.sendUserActionLog('menu', menuCode);
			history.replace(`${urls}`)
			break;
		  case "앱 설정" : 
			NativeBridge.sendUserActionLog('menu', menuCode);
			history.replace(`${urls}`)
			break;
		}
	}

	const goPageOnClick = (urls=null, svcNm) => {
		NativeBridge.sendUserActionLog('menu', 'P006');
        NativeBridge.externalCall(`${urls}`)
    }

	// console.log('curr스토어', userData.dataset.currstoreInfo);

    return (
        <>
			{ (userInfo.dataset.familyYn === 'N' && userInfo.dataset.isTempUser === false) &&
				<div className="content my">
					<div className="my_info_top">
						<span className="my_name">{myInfo?.name} 사장님</span>
						<span className="my_num">{myInfo?.ctn.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")}</span>
						<div className="tab_style1">
							{/* <button className="tab_link usetap" id="my_header_button" onClick={() => myTopButtonClick("/shop/interwork", "가입관리", 'P001')}>가입관리</button>
							<button className="tab_link usetap" id="my_header_button" onClick={() => myTopButtonClick("/account/store/modify", "매장편집", 'P002')}>매장편집</button>
							<button className="tab_link usetap" id="my_header_button" onClick={() => myTopButtonClick("/my/mysetting", "앱 설정", "P003")}>앱 설정</button> */}
							<Link to="/shop/interwork" className="tab_link usetap" onClick={() => {NativeBridge.sendUserActionLog('menu', 'P001');}}>가입관리</Link>
							<Link to="/account/store/modify" className="tab_link usetap" onClick={() => {NativeBridge.sendUserActionLog('menu', 'P002');}}>매장편집</Link>
							<Link to="/my/mysetting" className="tab_link usetap" onClick={() => {NativeBridge.sendUserActionLog('menu', 'P003');}}>앱 설정</Link>
						</div>
						<div className="charge_wrap">
							<Swiper
								className="charge_rolling"
								// spaceBetween={3}
								pagination={{ 
									clickable: true, 
									el: '.i_pagination' }}
								slidesPerView="auto"
								observeParents
								observer
								loop={false}>
								{ (retCode === '0000' && monthBills !== null && monthBills.length > 0) ?
									monthBills?.map((item, index) => (
										<>
											<SwiperSlide key={`monthBill_${index}`}>
												<div className="charge_box" onClick={()=>ToggleClick(index)}>
													<div className="total_charge">
														<span className="chg_title">
															<span className="min_month">{monthBillsMonth?.substr(4, 2) < 10 ? `${monthBillsMonth?.substr(5, 1)}` : `${monthBillsMonth?.substr(4, 2)}`}</span>
																월 이용요금
															<button type="button" className="i_tip blind" onClick={(e)=> funcTooltip(e,'.tooltip_box')}>
																툴팁
															</button>
														</span>
														<span className="i_charge">
															<span className="ft_roboto">{parseInt(item.totalAmt).toLocaleString()}</span>
															원
															{ item.billInfo !== null && item.billInfo.length > 0 ?
																<span className="ico_light_dropdown"></span>
															:
																<></>
															}
														</span>
													</div>
													<ul className="detail_charge">
														{ item.billInfo !== null && item.billInfo.length > 0 ?
															item.billInfo?.map((detailitem, index) => (
																<li>
																	<span className="detaiil_title">{detailitem.prntBiltemNm}</span>
																	<span className="detaiil_chg"><span className="ft_roboto">{parseInt(detailitem.byitmBillAmt).toLocaleString()}</span>원</span>
																</li>
															))
														:
															<></>
														}
													</ul>
												</div>
											</SwiperSlide>
										</>
									))
								:
									<>
										<SwiperSlide>
											<div className="charge_box" onClick={()=>ToggleClick(null)}>
												<div className="total_charge">
													<>
														<span className="chg_title">
														<span className="min_month">
															{monthBillsMonth !== null && monthBillsMonth !== "" ?
																monthBillsMonth?.substr(4, 2) < 10 ? `${monthBillsMonth?.substr(5, 1)}월` : `${monthBillsMonth?.substr(4, 2)}월`
															:
																<></>
															}
															</span>
																이용요금
															<button type="button" className="i_tip blind" onClick={(e)=> funcTooltip(e,'.tooltip_box')}>
																툴팁
															</button>
														</span>
														<span className="i_calculate">
															금액 정산 중
														</span>
													</>
												</div>

											</div>
										</SwiperSlide>
									</>
								}
								<div className="i_pagination"></div>
							</Swiper>
							<div className="tooltip_box">
								<span className="top_arrow"></span>
								<p className="tip_content">
									{ (retCode === '0000' && monthBillsMonth !== "" && monthBills !== null && monthBills.length > 0) ?
										'고객님의 명의로 가입한 전체 서비스 이용요금의 합산금액입니다. 해지한 서비스가 있는 경우, 할인 반환금이 반영될 수 있습니다.'
									:
										"이번달 청구요금을 계산중입니다. 잠시만 기다려주세요."
									}
								</p>
							</div>
						</div>
					</div>
					<div className="sec_wrap my_service_info">
						<span className="title">상품 이용 현황</span>
						<div className="tab_1depth" id="tab_1depth">
							<ul className="tab_link">
								{ userData.dataset.storeList?.length > 0 &&
									userData.dataset.storeList?.map((item, index) => (
										index < 15 &&
										<li className={"tab_item" + (item.storeId === LocalStore.getStoreId()  ? " active" : "")} key={item.storeId} id={item.storeId} onClick={()=> ShopClick(item.storeId, item.storeNm)}>
											<span>{item.storeNm}</span>
										</li>
									))
								}
							</ul>
							<div className="active_bar"></div>
						</div>
						<div className="service_bystore">
							<span className="service_cate">U+인터넷 전화</span>
							<ul className="service_list">
								{ serviceDetails?.lpzList !== null ?
									serviceDetails?.lpzList.map((centrixitem, index) => (
										(centrixitem.extServiceYn === "N" && centrixitem.showYn === 'Y') &&
											<li> 
												<div className="cont_wrap">
													<div className="use_state">
														{ centrixitem.useYn === 'Y' ?
															<>
																<span className="ico_use">이용중</span>
																<span className="use_num">{centrixitem.cnt}대</span>
															</>
														:
															<>
															</>
														}
													</div>	
													<span className="i_name">{centrixitem.svcNm}</span>
													<button type="button" className={"btn_round usetap" + (centrixitem.useYn === 'Y' ? ' green add' : ' purple')} value={centrixitem.svcNm === "(부가)통화연결음" ? centrixitem.svcNm : "" }
														 onClick={()=>goPageOnClick('https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000060', centrixitem.svcNm)}>
														{/* onClick={(e)=>goPageOnClick2(e)}> */}
															{centrixitem.useYn === 'Y' ? '추가가입' : '가입하기'}
													</button>
												</div>
											</li>		
									))
									:
									<></>
								}
								{
									serviceDetails?.lpzList != null ?
										serviceDetails?.lpzList.map((centrixitem, index) => (
											(centrixitem.extServiceYn === "Y" && centrixitem.showYn === 'Y') &&
												<li> 
													<div className="cont_wrap">
														<span className="i_name">{centrixitem.svcNm}</span>
														<button type="button" value={centrixitem.svcNm} className={"btn_round usetap" + (centrixitem.useYn === 'Y' ? ' green add' : ' purple')}
															 onClick={(e)=> ReportRegisterClick('1800-8000', e)}>
																 {centrixitem.useYn === 'Y' ? '추가가입' : '가입하기'}
														</button>
													</div>
												</li>
										))
									:
									<></>
								}
							</ul>
						</div>
						<div className="service_bystore">
							<span className="service_cate">U+지능형 CCTV</span>
							<ul className="service_list">
								{ serviceDetails?.lzpList !== null ?
									serviceDetails?.lzpList.map((cctvitem, index) => (
										(cctvitem.extServiceYn === "N" && cctvitem.showYn === 'Y') &&
											<li> 
												<div className="cont_wrap">
													<div className="use_state">
														{ cctvitem.useYn === 'Y' ?
															<>
																<span className="ico_use">이용중</span>
																<span className="use_num">{cctvitem.cnt}대</span>
															</>
														:
															<></>
														}
													</div>
													<span className="i_name">{cctvitem.svcNm}</span>
													<button type="button"  className={"btn_round usetap" + (cctvitem.useYn === 'Y' ? ' green add' : ' purple')} 
														onClick={()=>goPageOnClick((cctvitem.svcCd === "CCTV_SVC_1") ? 
																						"https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000055" :
																					(cctvitem.svcCd === "CCTV_SVC_2") ? 
																						"https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000067" :
																					(cctvitem.svcCd === "CCTV_SVC_3") ? 
																						"https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/9000000009" :
																					(cctvitem.svcCd === "CCTV_SVC_4") ? 
																						"https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000055" :
																					(cctvitem.svcCd === "CCTV_SVC_5") ? 
																						"https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000011001" :
																					'')}>
															{cctvitem.useYn === 'Y' ? '추가가입' : '가입하기'}
													</button>
												</div>
											</li>		
									))
									:
									<></>
								}
								{
									serviceDetails?.lzpList != null ?
										serviceDetails?.lzpList.map((cctvitem, index) => (
											(cctvitem.extServiceYn === "Y" && cctvitem.showYn === 'Y') &&
												<li>
													<div className="cont_wrap">
														<span className="i_name">{cctvitem.svcNm}</span>
														<button type="button" value={cctvitem.svcNm} className={"btn_round usetap" + (cctvitem.useYn === 'Y' ? ' green add' : ' purple')}
															onClick={(e)=> ReportRegisterClick('1800-8000', e)}>
																{cctvitem.useYn === 'Y' ? ' 추가가입' : ' 가입하기'}
														</button>
													</div>
												</li>
										))
									:
									<></>
								}
							</ul>
						</div>
					</div>
				</div>
			}
			{ (userInfo.dataset.familyYn === 'Y' && userInfo.dataset.isTempUser === false) &&
				<Route path="/my" component={MyFamilyAccount} />
			}
			{ (userInfo.dataset.familyYn === 'N' && userInfo.dataset.isTempUser === true) &&
				<Route path="/my" component={MyTemporaryAccount} />
			}
			{/* 마이페이지 floating_cs */}
			<div className="modal fadeIn modal_cs" id="modal_cs">
				<div className="dim" onClick={()=> layerPopup.hide('#modal_cs')}></div>
				<div id="floating_cs" className="floating_cs down">
					<button type="button" className="btn_cs_open usetap blind" onClick={()=> layerPopup.show('#modal_cs')}>메뉴 펼침</button>
					<button type="button" className="btn_cs_close usetap blind" onClick={()=> layerPopup.hide('#modal_cs')}>메뉴 닫힘</button>
					<div className="btn_register_wrap" onClick={(e)=>FloatCsClick(e,'1800-8300')}>
						<span className="btn_title">가입/문의</span>
						<a href="#" className="btn_register usetap blind">가입/문의</a>
					</div>
					<div className="btn_report_wrap" onClick={(e)=>FloatCsClick(e,'1544-8585')}>
						<span className="btn_title">장애 신고</span>
						<a href="#" className="btn_report usetap blind">장애 신고</a>
					</div>
				</div>
			</div>
        </>
    )
});

export default My;