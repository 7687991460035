import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../../store';
import 'moment/locale/ko';
import moment from 'moment';
import { tabHomeContents } from "../../../libs/common"
import $ from 'jquery';
import * as NativeBridge from '../../../utils/NativeBridge'
import * as LocalStore from '../../../store/LocalStore'
import * as SessionStore from '../../../store/SessionStore'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Pagination, 
    Scrollbar, 
  } from "swiper";
import { layerPopup } from '../../../libs/common';
import CctvApi from '../../../api/CctvApi'

SwiperCore.use([ Pagination, Scrollbar ]);

const ShopManage = observer(({ svcCardNm }) => {

    const { userInfo, userData } = store;

    const [camIdList, setCamIdList] = useState([])
    const [SplitCctvList, setSplitCctvList] = useState([])
    const [camThumbList, setCamThumbList] = useState([])
    const [camSplitThumbList, setSplitCamThumbList] = useState([])
    const [camEventList, setCamEventList] = useState([])
    const [camEventCnt, setCamEventCnt] = useState(null)
    const [refreshtime, setRefreshtime] = useState(moment().format('HH:mm:ss'))
    const [retCode, setRetCode] = useState(0);

    var FullCctvList = userData.dataset.currstoreInfo.cams
    var SplitCctvListData = []
    var ThumbNailLists = []
    var SplitThumbNailLists = []
    let cctvKey = LocalStore.getCctvToken();

    // camID가 변경 때 마다 새로운 썸네일 가져온다.
    useEffect(() => {
        console.log('매장 변경 아이디', camIdList);
        // 길이가 camIdList 만큼의 기본값이 false인 배열 생성
        ThumbNailLists = Array.from({length: camIdList.length}, (v, i) => false);
        setCamThumbList(ThumbNailLists);
        for(let i = 0; i < camIdList.length; i++) {
            // cctv_thumb 값이 있으면 ThumbNail Push
            try {
                CctvApi.getCctvThumbNail(camIdList[i])
                .then((cctv_thumb) => {
                    ThumbNailPush(i, cctv_thumb.data.imageData);
                })
            // 없으면 빈 문자열 Push
                .catch((e)=>{
                    ThumbNailPush(i, "");
                });
            } catch(e) {
                ThumbNailPush(i, "");
            }
        }
    }, [camIdList])

    // 매장 변경 플래그 감지하여 스플릿 캠과 감지목록 불러오기
    useEffect(()=>{
        if(userData.dataset.procChgStoreComplete) {
            CamEventList();
            setCamIdList([])
            setSplitCctvList([])
    
            if(Array.isArray(userData.dataset.currstoreInfo.cams)) {
                setCamIdList(userData.dataset.currstoreInfo.cams?.map((item) => item.camId))
            }
    
            if(userData.dataset.currstoreInfo.cams !== null && typeof(userData.dataset.currstoreInfo.cams) !== "undefined") {
                for(var i=0; i< FullCctvList.length; i+=4) SplitCctvListData.push(FullCctvList.slice(i, i+4))
                for(var i =0; i < SplitCctvListData.length; i++) {
                    if(SplitCctvListData[i].length < 4){
                        var leng = 4 - SplitCctvListData[i].length
                        for(var j = 0; j < leng; j++) {
                            SplitCctvListData[i].push(false)
                        }
                    }
                }
                setSplitCctvList(SplitCctvListData)
            }
        }

        setTimeout(() => { 
            if(userData.dataset.currstoreInfo.cams.length <= 1) {
                $('#cctvFull .i_pagination').addClass('i_disabled');	
            } else {    
                $('#cctvFull .i_pagination').removeClass('i_disabled');
            }
        }, 150);

    }, [userData.dataset.procChgStoreComplete]);

    // 홈 카드 편집 감지하여 스플릿 캠과 감지 목록 불러오기
    useEffect(() => {
        if (userData.dataset.homeChFlag){
            CamEventList();
            setCamIdList([])
            setSplitCctvList([])
    
            if(Array.isArray(userData.dataset.currstoreInfo.cams)) {
                setCamIdList(userData.dataset.currstoreInfo.cams?.map((item) => item.camId))
            }
    
            if(userData.dataset.currstoreInfo.cams !== null && typeof(userData.dataset.currstoreInfo.cams) !== "undefined") {
                for(var i=0; i< FullCctvList.length; i+=4) SplitCctvListData.push(FullCctvList.slice(i, i+4))
                for(var i =0; i < SplitCctvListData.length; i++) {
                    if(SplitCctvListData[i].length < 4){
                        var leng = 4 - SplitCctvListData[i].length
                        for(var j = 0; j < leng; j++) {
                            SplitCctvListData[i].push(false)
                        }
                    }
                }
                setSplitCctvList(SplitCctvListData)
            }
            
            setTimeout(() => { 
                if(userData.dataset.currstoreInfo.cams.length <= 1) {
                    $('.sec_cctv #cctvFull .i_pagination').addClass('i_disabled');	
                } else {    
                    $('.sec_cctv #cctvFull .i_pagination').removeClass('i_disabled');
                }
            }, 150);
            userData.setHomeChFlag(false);
        }
    }, [userData.dataset.homeChFlag]);

    // 스플릿 캠이 1개일때는 페이지네이션 비활성화
    useEffect(() => {
        if(SplitCctvList.length <= 1) {
            $('.sec_cctv #cctvSplit .i_pagination').addClass('i_disabled');	
        } else {
            $('.sec_cctv #cctvSplit .i_pagination').removeClass('i_disabled');
        }
    }, [SplitCctvList]);
    
    // 카메라 썸네일 목록이 바뀔때 4개씩 쪼개서 스플릿 캠 썸네일 생성
    useEffect(() => {
        for(var i=0; i < camThumbList.length; i+=4) SplitThumbNailLists.push(camThumbList.slice(i, i+4))
        console.log("스플릿 썸네일", SplitThumbNailLists)
        setSplitCamThumbList(SplitThumbNailLists)
    }, [camThumbList]);

    const ThumbNailPush = (i, ThumbNail) => {
        setCamThumbList(camThumbList => camThumbList.map((item, idx)=>{
            if(idx === i) {
                return ThumbNail;
            } else {
                return item;
            }
        }));
    }

    const ShopCCTV = () => {
        NativeBridge.sendUserActionLog('menu', 'H005');
        userData.setShopMainTabList('cctv');
    }

    const CamEventList = async() => {
        if((cctvKey !== null || cctvKey !== "") &&
        (userData.dataset.currstoreInfo.cams !== undefined && userData.dataset.currstoreInfo.cams !== null && 
        userData.dataset.currstoreInfo.cams.length > 0)) {
            try {
                const event_list = await CctvApi.getCctvEventList(userData.dataset.currstoreInfo.camId, 
                    moment().format('yyyyMMDD000000'), moment().format('yyyyMMDDHHmmss'));
                setCamEventCnt(event_list.data.totalCount);
                setRetCode(event_list.data.resCode);
                if (event_list.data.list !== undefined && event_list.data.list !== null && event_list.data.list.length > 0){
                    setCamEventList(event_list.data.list);
                }
            } catch(e){
                console.log(e);
                setRetCode(null);
                setCamEventCnt(0);
                setCamEventList([])
            }

            // await CctvApi.getCctvEventList(userData.dataset.currstoreInfo.camId, 
            //     moment().format('yyyyMMDD000000'), moment().format('yyyyMMDDHHmmss'))
            //     .then((rst)=> {
            //         setCamEventCnt(rst.data.totalCount);
            //         setRetCode(rst.data.resCode);
            //         if (rst.data.list !== undefined && rst.data.list !== null && rst.data.list.length > 0){
            //             setCamEventList(rst.data.list);
            //         }
            //     })
            //     .catch((e) => {
            //         console.log(e);
            //         setRetCode(null);
            //         setCamEventCnt(0);
            //         setCamEventList([])
            //     })            
        }
    }

    const CamRefresh = () => {
        NativeBridge.sendUserActionLog('detail', 'H035');

        setCamIdList([])
        setCamThumbList([])
        setSplitCamThumbList([])
        setCamEventList([])
        setCamEventCnt(null)
        
        // cctv 감지내역 조회
        CamEventList()

        //썸네일
        setCamIdList(userData.dataset.currstoreInfo.cams.map((item) => item.camId))
        // 시간 변경
        setRefreshtime(moment().format('HH:mm:ss'))
    }

    const detectionHistory = (e) => {
        NativeBridge.sendUserActionLog('detail', 'D006');
        let authKey = SessionStore.getCctvToken();
        
        if(LocalStore.getFamilyYn() === 'Y' && LocalStore.getIsTempUser() === false) {
            layerPopup.showCommon(
                '#popup_onetitlesentence_onebutton_cctv',
                null,
                '안내',
                "초대계정은 보안 정책으로 <br /> U+지능형 CCTV앱 이동이 불가능합니다.",
                [
                    function() {
                        layerPopup.hide('#popup_onetitlesentence_onebutton_cctv')
                    }
                ]
            )
        } else {
            NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=2`,"3")
        }
    }

    const thumbnailClick = (e, focusCamId) => {
        let authKey = SessionStore.getCctvToken();

        if(LocalStore.getFamilyYn() === 'Y' && LocalStore.getIsTempUser() === false) {
            layerPopup.showCommon(
                '#popup_onetitlesentence_onebutton_cctv',
                null,
                '안내',
                '초대계정은 보안 정책으로 <br /> U+지능형 CCTV앱 이동이 불가능합니다.',
                [
                    function() {
                        layerPopup.hide('#popup_onetitlesentence_onebutton_cctv')
                    }
                ]
            )
        } else {
            if(focusCamId !== null) {
                NativeBridge.sendUserActionLog('detail', 'D008');
                NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=1&camId=${focusCamId}`, "3")

            } else {
                NativeBridge.sendUserActionLog('detail', 'D009');
                NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${userInfo.dataset.myservice.cctvReq ? '1' : '0'}&iotType=${userInfo.dataset.myservice.staffMng ? '1' : '0'}&type=1`, "3")
            }
        }
    }

    return (
        <>
            { (((cctvKey !== null || cctvKey !== "") &&
              (userData.dataset.currstoreInfo.cams !== undefined  && userData.dataset.currstoreInfo.cams !== null &&
                userData.dataset.currstoreInfo.cams.length > 0)) && (retCode !== null && retCode === 0)) ?
                <div className="content_box">
                    <Link to="/shop/cctv" className="sec_link usetap" onClick={()=> ShopCCTV()}><i className="ico_dark_more"></i>더보기</Link>
                    <span className="cont_title">{svcCardNm}</span>
                    <div className="sec_wrap sec_cctv">
                        { (camEventList !== null && Array.isArray(camEventList) && camEventCnt > 0) &&
                            <React.Fragment>
                                <div className="desc_link" title="지능형 CCTV 앱 > 감지내역 및 기기정보 > 감지 내역" onClick={()=> detectionHistory()}>
                                    <span className="info_msg">서둘러서 확인해 보세요!</span>
                                    <strong className="st_msg"><span className="pt_color">방금 총 {camEventCnt !== null ? `${camEventCnt?.toLocaleString()}` : ''}건 감지</span> 되었어요.</strong>
                                </div>
                                <div className="cctv_sense">
                                    <ul>
                                        <li className={"sense1" + (camEventList?.filter((item) => item.eventType === 1).length > 0 ? ' on' : '')}>
                                            <span className="title_sense">침입감지</span>
                                            <span className="num_sense">
                                                {camEventList?.filter((item) => item.eventType === 1).length}건
                                            </span>
                                        </li>
                                        <li className={"sense2" + (camEventList?.filter((item) => item.eventType === 13).length > 0 ? ' on' : '')}>
                                            <span className="title_sense">배회</span>
                                            <span className="num_sense">
                                                {camEventList?.filter((item) => item.eventType === 13).length}건
                                            </span>
                                        </li>
                                        <li className={"sense3" + (camEventList?.filter((item) => item.eventType === 7).length > 0 ? ' on' : '')}>
                                            <span className="title_sense">카메라훼손</span>
                                            <span className="num_sense">
                                                {camEventList?.filter((item) => item.eventType === 7).length}건
                                            </span>
                                        </li>
                                        <li className={"sense4" + (camEventList?.filter((item) => item.eventType === 5).length > 0 ? ' on' : '')}>
                                            <span className="title_sense">유기</span>
                                            <span className="num_sense">
                                                {camEventList?.filter((item) => item.eventType === 5).length}건
                                            </span>
                                        </li>
                                        <li className={"sense5" + (camEventList?.filter((item) => item.eventType === 8).length > 0 ? ' on' : '')}>
                                            <span className="title_sense">불꽃연기</span>
                                            <span className="num_sense">
                                                {camEventList?.filter((item) => item.eventType === 8).length}건
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </React.Fragment>
                        }
                        { (camEventCnt === 0) &&
                            <div onClick={()=> detectionHistory()}>
                                <span className="info_msg">사장님, 안심하세요!</span>
                                <strong className="st_msg">오늘은 감지된 내용이 없어요.</strong>
                            </div>
                        }
                        <div className="cctv_live">
                            <p>실시간 촬영 중 입니다!</p>
                            <div className="btn_cctv_tap">
                                <button className="btn_tap btn_cctvfull usetap on" onClick={(e)=>tabHomeContents(e, 'cctvFull')}>풀화면</button>
                                <button className="btn_tap btn_cctvsplit usetap" onClick={(e)=>tabHomeContents(e, 'cctvSplit')}>분할화면</button>
                                <div className="cctv_opt">
                                    <span className="cctv_time">{refreshtime}</span>
                                    <button type="button" className="btn_refresh usetap" onClick={()=>CamRefresh()}><i className="ico_refresh"></i>새로고침</button>
                                </div>
                            </div>
                        <div className="tab_content_wrap">
                            <div id="cctvFull" className="tab_content" style={{display: "block"}}>
                                { (typeof(userData.dataset.currstoreInfo.cams) !== "undefined" && userData.dataset.currstoreInfo.cams.length !== 0) ?
                                    <Swiper
                                        className="cctv_rolling cctv_full_rolling"
                                        observeParents
                                        observer
                                        pagination={{ 
                                            clickable: true, 
                                            el: '.i_pagination' 
                                        }}
                                        slidesPerView="auto"
                                        loop={false}>
                                        { Array.isArray(FullCctvList) &&
                                            FullCctvList?.map((item, index) => (
                                            index < 24 &&
                                            <SwiperSlide key={`Full_${index}`}>
                                                { item.service01 === 'Y' && camThumbList[index] !== "" &&
                                                    <span className="cctv_mark">위생인증 CCTV</span>
                                                }
                                                { camThumbList[index] !== "" &&
                                                    <span className="cctv_name">{item.camName}</span>
                                                }
                                                { typeof(camThumbList[index]) === "undefined" || camThumbList[index] === false ?
                                                    <div className={'empty_cctv ' + `${item.camId}`} /*key={"empty_cctv_" + index}*/ onClick={(e)=>thumbnailClick(e, item.camId)}></div>
                                                
                                                : camThumbList[index] !== "" ?
                                                    <div className={'cctv_thumb ' + `${item.camId}`} style={{backgroundImage: `url('data:image/png;base64,${camThumbList[index]}')`}} onClick={(e)=>thumbnailClick(e, item.camId)}></div>
                                                : camThumbList[index] === "" ?
                                                    <div className={'cctv_err_wrap ' + `${item.camId}`} /*key={"cctv_err_wrap_" + index}*/ onClick={(e)=>thumbnailClick(e, item.camId)}>
                                                        <div className="cctv_err_box">
                                                            <div className="err_inner">
                                                                <span className="ic_card_error"></span>
                                                                <p className="info1">일시 오류</p>
                                                                <p className="info2">새로고침으로 정보를 불러주세요!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <></>
                                                }
                                            </SwiperSlide>
                                        ))
                                        }
                                        { userData.dataset.currstoreInfo.cams.length >= 24 &&
                                            <>
                                                <SwiperSlide>
                                                    <div className="cctv_excess_wrap">
                                                        <div className="vertical_cen">
                                                            <div className="excess_desc">
                                                                <span className="ic_cctv"></span>
                                                                <p className="info1">가게에 설치된 CCTV가 24대 이상입니다</p>
                                                                <p className="info2">전체 CCTV 영상을 시청하려면</p>
                                                                <p className="link_cctvapp usetap" onClick={(e)=>thumbnailClick(e, null)}>U+ 지능형 CCTV 앱을 이용해 주세요</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </>

                                        }
                                            <div className="i_pagination"></div>
                                    </Swiper>
                                :
                                    <div className="cctv_err_wrap">
                                        <div className="cctv_err_box">
                                            <div className="err_inner">
                                                <span className="ic_card_error"></span>
                                                <p className="info1">일시 오류</p>
                                                <p className="info2">새로고침으로 정보를 불러주세요!</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                                <div id="cctvSplit" className="tab_content" style={{display: "none"}}>
                                    { (typeof(userData.dataset.currstoreInfo.cams) !== "undefined" && userData.dataset.currstoreInfo.cams.length !== 0) ?
                                        <Swiper
                                            className="cctv_rolling cctv_split_rolling"
                                            // spaceBetween={2}
                                            pagination={{ 
                                                clickable: true, 
                                                el: '.i_pagination'
                                            }}
                                            slidesPerView="auto"
                                            observeParents
                                            observer
                                            loop={false}>
                                                { (SplitCctvList !== null && SplitCctvList !== undefined) &&
                                                    SplitCctvList.map((splititems, splitindex) => (
                                                        splitindex < 6 &&
                                                        <>
                                                            <SwiperSlide key={`split-${splitindex}`}>
                                                                { splititems.map((splititem, index) => (
                                                                    <>    
                                                                        <div className="thumb_wrap">
                                                                            { splititem === false ?
                                                                                <div className="empty_cctv"></div>
                                                                            :
                                                                                (camSplitThumbList[splitindex] && camSplitThumbList[splitindex][index] === false) ?
                                                                                    <div className="empty_cctv"></div>
                                                                                : (camSplitThumbList[splitindex] && camSplitThumbList[splitindex][index] !== "") ?
                                                                                    <div className={'cctv_thumb ' + `${splititem.camId}`} key={"cctv_thumb_" + index}  
                                                                                         onClick={(e)=>thumbnailClick(e, splititem.camId)} 
                                                                                         style={{backgroundImage:`url('data:image/png;base64,${camSplitThumbList[splitindex][index]}')`}}></div>
                                                                                : (camSplitThumbList[splitindex] && camSplitThumbList[splitindex][index] === "") ?
                                                                                    <div className={'cctv_split_error ' + `${splititem.camId}`} key={"cctv_split_error_" + splititem.camId} onClick={(e)=>thumbnailClick(e, splititem.camId)}>
                                                                                        <div className="err_wrap">
                                                                                            <span className="ic_card_error"></span>
                                                                                            <span className="err_title">일시오류</span>
                                                                                        </div>
                                                                                    </div>
                                                                                :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                ))
                                                                }
                                                            </SwiperSlide>
                                                        </>
                                                ))
                                                }
                                                { userData.dataset.currstoreInfo.cams.length >= 24 &&
                                                    <>
                                                        <SwiperSlide>
                                                            <div className="cctv_excess_wrap">
                                                                <div className="vertical_cen">
                                                                    <div className="excess_desc">
                                                                    <span className="ic_cctv"></span>
                                                                    <p className="info1">가게에 설치된 CCTV가 24대 이상입니다</p>
                                                                    <p className="info2">전체 CCTV 영상을 시청하려면</p>
                                                                    <p className="link_cctvapp usetap" onClick={(e)=>thumbnailClick(e, null)}>U+ 지능형 CCTV 앱을 이용해 주세요</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    </>
                                                }
                                                <div className="i_pagination"></div>
                                        </Swiper>
                                    :
                                        <div className="cctv_err_wrap">
                                            <div className="cctv_err_box">
                                                <div className="err_inner">
                                                    <span className="ic_card_error"></span>
                                                    <p className="info1">일시 오류</p>
                                                    <p className="info2">새로고침으로 정보를 불러주세요!</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <>
                    <div className="content_box">     
                        <Link to="/shop/cctv" className="sec_link usetap" onClick={()=> ShopCCTV()}><i className="ico_dark_more"></i>더보기</Link>
                        <span className="cont_title">{svcCardNm}</span>
                        <div className="sec_wrap">
                            <strong className="st_msg">일시 오류, 새로고침으로 <br />정보를 불러주세요!</strong>
                            <div className="ex_img row">
                                <img src="/res/images/img-home-cctv-card-error@3x.png" alt="매장관리 예시" />
                                <button type="button" className="btn_refresh usetap blind" onClick={()=> CamRefresh()}>새로고침</button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
});

export default ShopManage;