import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store';
import { layerPopup, toast } from '../../../../libs/common';
import $ from 'jquery';
import SohoApi from '../../../../api/SohoApi';
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";

SwiperCore.use([ Pagination, Scrollbar ]);



const MyFamilySetPhone = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);

    const [inputValue, setInputValue] = useState([]);
    const [saveInputValue, setSaveInputValue] = useState("");
    const [familyAccountListInfo, setFamilyAccoutListInfo] = useState([]);
   
    useEffect(() => {       
        console.log("MyFamilySetPhone default useEffect..");
        // familyAccountList();
        
        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
          }          
          return () => {
            if($('#wrap').hasClass('fixed_bottom')) {
              $('#wrap').removeClass('fixed_bottom');
              $('#wrap').addClass('gnb_bottom');
            }
          };
    }, []);

    useEffect(() => {


        if (inputValue.length === 11) {
            setSaveInputValue(inputValue);
          //setInputValue(inputValue?.replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**'));
        }
        // if (inputValue.length === 13) {
        //   setSaveInputValue(inputValue);
        //   setInputValue(inputValue?.replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**'));
        // }
      }, [inputValue]);

      //useEffect(() => {
        // if (inputValue.length < 5) {
        //   setInputValue(inputValue.replace(/(\d{3})(\d{1})/g,'$1-$2'));
        //   console.log("일");
        // } //현재 length = 5
        // if (inputValue.length < 7) {
        //   setInputValue(inputValue.replace(/(\d{3})(\d{2})(\d{2})/g,'$1-$2**'));
        //   console.log("이");
        // }
        // if (inputValue.length === 8) {
        //   setInputValue(inputValue.replace(/(\d{3})(\d{2})(\d{2})(\d{1})/g,'$1-$2**'));
        //   console.log("삼");
        // }
        // if (inputValue.length === 9) {
        //   setInputValue(inputValue.replace(/(\d{3})(\d{2})(\d{2})(\d{1})/g,'$1-$2**-'));
        //   console.log("사");
        // }
        // if (inputValue.length === 11) {
        //   setInputValue(inputValue.replace(/(\d{3})(\d{2})(\d{2})(\d{1})/g,'$1-$2**-$4**'));
        //   console.log("오");
        // }
    
    

        // if (inputValue.length === 13) {
        //   setInputValue(inputValue.replace(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/g,'$1-$2**-$4**'));
        // }
      //}, [inputValue]);

  

    console.log(inputValue);
    console.log(inputValue.length);
    console.log("33",saveInputValue);


    const goBackPage = () => {
        history.goBack();
    }

  //   //패밀리 계정조회
	// const familyAccountList = async () => {
	// 	const familyAccountData = await SohoApi.getFamilyAccountList({
			
	// 	});

	// 	const getFamilyAccount = familyAccountData.data;

	// 	setFamilyAccoutListInfo(getFamilyAccount);
	// 	console.log("패밀리 계정 조회 ===>", familyAccountData);
	// }


    //패밀리 계정등록 
	const familyAccountRegi = async (num) => {
		const familyAccountRegiData = await SohoApi.getFamilyAccountRegi({
			mblTlno : num, // 사용자 전화번호
      famctn: num 
		})

		const getFamilyAccountRegi = familyAccountRegiData;
		console.log("패밀리 계정 등록 확인 ===>", getFamilyAccountRegi);
    if(getFamilyAccountRegi.retCode === "0000" ){
      //familyAccountList();
    }

    return familyAccountRegiData;
	}

  console.log("3943948", familyAccountListInfo)

    //패밀리 계정 추가 함수 
    const familyAccountAddClick = async () => {
        const addNum = saveInputValue
        
        if(addNum.length < 11){
          return toast("휴대폰 번호를 확인해주세요.")
        }
        const ret = await familyAccountRegi(addNum);
       
        // const addNum = inputValue.toString().replace(/\*/g,'')
        
        if(addNum.length === 11 && ret.retCode === '0000'){
          layerPopup.showCommon(
            '#popup_onetitlebutton_threemsg', 
          
            null, 
            '초대 문자메시지 발송', 
            '입력한 휴대폰번호로 초대<br/>문자메시지를 발송했습니다.',
            
            [
                function() {
                  setInputValue([]);
                  history.goBack();
                  layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                },
             
            ]
          )
        } else if (ret.retCode === '1706') {
          layerPopup.showCommon(
            '#popup_onetitlebutton_threemsg', 
          
            null, 
            '알림', 
            '이미 초대된 계정입니다.<br/> 사용자 변경 시, 삭제 후 다시 초대해주세요.',
            
            [
                function() {
                  
                  setInputValue([]);
                  history.goBack();
                    layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
                },
             
            ]
          )
        } else if (ret.retCode === '1707') {
          layerPopup.showCommon(
            '#popup_onetitlemsgbutton', 
            
            null, 
            '알림', 
            '본인계정은 초대 불가합니다.',
            
            [
              function() {
                layerPopup.hide('#popup_onetitlemsgbutton');//닫기
              },
            ]
          )
        }
           //else{
          //   layerPopup.showCommon(
          //     '#popup_onetitlebutton_threemsg', 
            
          //     null, 
          //     '초대 문자메시지 발송 오류', 
          //     '초대 발송에 실패 하였습니다.<br />다시 초대를 시도해 주세요',
              
          //     [
          //         function() {
          //           familyAccountRegi(addNum);
          //           setInputValue([]);
          //             layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
          //         },
               
          //     ]
          // )
          // }
				
			  
       }

        
    

    //핸드폰 유효성 검사
    const checkPhoneNum = (e) => {
       
        let regExp4 =  /^[0-9]*$/g;
        // 형식에 맞는 경우 true 리턴
        // console.log('핸드폰 유효성 검사 ::', regExp4.test(e.target.value))

        if(regExp4.test(e.target.value)){
          //setInputValue(e.target.value);
          //$("#familyNum").removeAttr('readOnly');
          setInputValue(e.target.value)
          //console.log("확인",regExp4.test(e.target.value))
        }
        else{
          console.log("전화번호를 확인하세요")
        }
    }

    //console.log( "특문 제거", inputValue?.toString().replace(/\-|\*/g,'',))
    const inputClear = () => {
      setInputValue("")
    }

    return (
        <>
 			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>초대하기</h2>
					</div>
				</div>
	            <div className="sec_wrap sec_family_num">
                    <span className="title">휴대폰 번호</span>
                    <div className="input_box style2">
                        <input type="text" id="familyNum" placeholder="- 없이 입력해주세요." maxLength="11" value={inputValue} onChange={(e)=>checkPhoneNum(e)} />
                        <span className="ip_clear hidden usetap"onClick={inputClear}>초기화</span>
                    </div>
                </div>
                <div className="btn_box fixed is_gnb">
                    <div className="inner">
                        <button type="button" className="btn_lg btn_point usetap" onClick={()=>familyAccountAddClick()}>초대</button>
                    </div>
                </div>
			</div>
        </>
    )
    
});
export default MyFamilySetPhone;