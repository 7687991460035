import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";
import $ from "jquery";
import * as NativeBridge from "../../utils/NativeBridge";
import * as LocalStore from '../../store/LocalStore';
import * as SessionStore from '../../store/SessionStore';
import { layerPopup } from "../../libs/common";
import { msgUI } from "../../libs/msg";
import {setCctvToken, setTempCctvToken} from '../../api/CctvApi';
import {setCenterexUserIdPass, setCentrexSsoKey} from '../../api/CentrexApi';
import SohoApi from "../../api/SohoApi";
import CentrexApi from "../../api/CentrexApi";
import CctvApi from "../../api/CctvApi";
import BizApi from '../../api/BizApi';
import * as CONSTS from "../../const";
import { setUserDataStoreList } from '../../components/login/DefaultSet';

const CctvAuthErrorPopup = observer(({ history }) => {

    const { userInfo, userData } = store;
    const goNext = async () => {
        console.log("CctvAuthErrorPopup CAll goNext");
        layerPopup.hide("#popup_cctv_auth_error", async() => {
            // -- 임시 사용자
            if(LocalStore.getIsTempUser() === true) {
                console.log('여기탄다.')
                // 로컬스토리지에 id,pw 가 세팅되어 있는지 확인한다.
                const cctvId = LocalStore.getCctvTempUserId();
                const cctvPw = LocalStore.getCctvTempUserPwd();
                if(cctvId && cctvPw) {
                    const cctvAuthInfo = await CctvApi.getAuth({
                        userId : cctvId,
                        userPwd : cctvPw
                    }).catch((e)=>{
                        console.log(e);
                    });
                    console.log('여기탄다.2',cctvAuthInfo)
                    

                    // 인증 성공인경우 카메라 업데이트
                    if(cctvAuthInfo && cctvAuthInfo.data.resCode === CONSTS.CCTV_RES_CODE_SUCCESS) {

                        if(cctvAuthInfo.data.authKey){
                            await camerasData();
                        }    
                        BizApi.makeMyService();                    
                    }
                }
            } else {
                await setUserDataStoreList();
            }
        });


      
    };

    const camerasData = async () => {

        let cameras = {};
        try {
            cameras = await CctvApi.getCameras();
        } catch(e) {
            cameras = {
                data: {
                    list : []
                }
            };
        }        

        let cammerasData = cameras.data.list
        let storeMappingList = []
        let camIds = [];
        let cams = [];
        let cam_device_sn_id = [];
        let addLzpList = [];
        
        cammerasData?.map((camera) => {           
            const cam = {};
            camIds.push(camera.camId);
            cam.camId = camera.camId;
            cam.camName = camera.camName;
            cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
            cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
            if (camera.device_sn_id.length > 0) {
                cam_device_sn_id.push(camera.device_sn_id)
            }
            cams.push(cam);
        })
        storeMappingList.push({
            storeId: LocalStore.getStoreList()[0].storeId ,
            storeNm: LocalStore.getStoreList()[0].storeNm ,
            lpzEntrNo: LocalStore.getStoreList()[0].lpzEntrNo,
            camId: camIds.join(","), 
            cams: cams,
            cam_device_sn_id : cam_device_sn_id,
            lpzNo: LocalStore.getStoreList()[0].lpzNo,
            lzpEntrNo: addLzpList.join(",")
        });
        userData.setStoreList(storeMappingList);
        userData.setCurrStoreInfo(storeMappingList[0]);
   }


    return (
        <>
            <div className="modal fadeIn" id="popup_cctv_auth_error">
                <div className="dim"></div>
                <div className="modal_inner">
                    <div className="modal_content">
                        <div className="modal_hd">알림</div>
                        <div className="modal_bd">
                            <p>CCTV 로그인에 실패하였습니다.<br />U+지능형 CCTV에 등록된 휴대폰번호를 업데이트하세요.</p>
                        </div>
                        <div className="modal_ft">
                            <button type="button" className="btn usetap" onClick={goNext}><span>확인</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
export default CctvAuthErrorPopup;