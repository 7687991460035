import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import SohoApi from '../../api/SohoApi';
import {parsePathname} from '../../common/common';

const TermAgreeDetail = observer(({match, history}) => {
    const [detailInfo, setDetailInfo] = useState(null);

    useEffect(() => {    
        console.log('match.param', match.params.detailid);  
        showDetail(match.params.detailid);
    }, []);

    const showDetail = (detailId) => {
        SohoApi.getTermsAgreeDetailList({prvsId : detailId}).then((details)=> {
            setDetailInfo(details.data.cntn);
        });
    }

    const goToTermAgree = () => {
        history.push(`/agree/reg`);
    }

    return (
        <>
            <div className="page_title_wrap">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=>history.goBack({state:{test:'datail'}})}>뒤로가기</button>
                    <h2>이용 약관</h2>
                </div>
            </div>
            <div className="terms_content2" dangerouslySetInnerHTML={ {__html: detailInfo} }>
            </div>
            <div className="btn_box fixed">
                <div className="inner">
                    <button type="button" className="btn_lg btn_point usetap" onClick={()=>goToTermAgree()}>확인</button>
                </div>
            </div>
        </>
    )
    
});
export default TermAgreeDetail;