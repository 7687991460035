import { observable } from 'mobx';
import * as LocalStore from './LocalStore';
//import * as SessionStore from './SessionStore';

const initialDataSet = {
    isLogin: false,
    bioLoginType: null,
    name: "",
    userId: "",
    ownerId: "",
    ownerNm: "",
    centrexId: "",
    cctvId: "",
    email: [],
    phone: "070-1234-5678",
    homeNotice: true,
    defaultGrt : {                //배경음 선택 시 defalut 값 
        ringName : "비발디 사계 봄",
        ringEngName : "bg_01"
    }, 
    defaultCall : "",
    defaultCheck : "",
    callRingPopupCheck : { //기본통화연결음 조회 시 통화연결음이 있을 경우 만들기/다시만들기 체크하기 위함
        rngTnTyp1 : "",
        rngTnTyp2 : "",
        rngTnTyp3 : "",//쉬는시간 
        rngTnTyp4 : "",//영업종료
        rngTnTyp5 : "",
    },
    popupCheck : "0", //소호 간편통화연결음 생성에서 뒤로가기 눌렀을 시 팝업 띄우는 체크용
    sohoToken: "",
    cctvToken: "",
    centrexToken: "",
    isTempUser: true,
    familyYn: "N",
    myservice : {               //우리가게 서비스 종류 및 가입여부
        call : false,           //전화&메시지
        cctv : false,           //지능형CCTV
        promota : false,        //프로모타 ( 가입유무 체크 가능로직 없음 )
        cashnote : false,       //캐시노트 ( 가입유무 없음 - 판단은 캐시노트에서 진행함. iframe으로 제공)
        alba : false,           //알바천국 ( 가입유무 체크 가능로직 없음  )
        callRing : false,       //통화연결음
        callRingEnReg : false,  //통화연결음 음원 생성
        callMsg : false,        //우리매장알림서비스
        staffMng : false,       //근태서비스(매장관리)
        cctvOpenUrl : false,    //위생인증서비스
        cctvReq : false         //출동요청
    },
    cctvTempUserId: "",
    cctvTempUserCredentials : ''
}

const userInfo = observable({
    dataset : initialDataSet,
    initialize() {
        this.dataset = initialDataSet;
    },
    setLogin() {
        this.setList({
            ...this.dataset,
            isLogin : true
        })
    },
    setBioLoginType(data) {
        this.setList({
            ...this.dataset,
            bioLoginType : data
        });
        if(data === null) {
            LocalStore.removeBioLoginType()
        } else {
            LocalStore.setBioLoginType(data);
        }
    },    
    setLogout() {
        this.setList({
            ...this.dataset,
            isLogin : false
        })
    },    
    setList(data) {
        this.dataset = data;
    },
    getList() {
        return this.dataset;
    },
    setUserId(data) {
        this.setList({
            ...this.dataset,
            userId : data
        });
        LocalStore.setUserId(data);
    },    
    setOwnerId(data) {
        this.setList({
            ...this.dataset,
            ownerId : data
        });
        LocalStore.setOwnerId(data);
    },    
    setOwnerNm(data) {
        this.setList({
            ...this.dataset,
            ownerNm : data
        });
        LocalStore.setOwnerNm(data);
    },        
    setCentrexId(data) {
        this.setList({
            ...this.dataset,
            centrexId : data
        })
    },    
    setCctvId(data) {
        this.setList({
            ...this.dataset,
            cctvId : data
        })
    },    
    setSohoToken(data) {
        this.setList({
            ...this.dataset,
            sohoToken : data
        })
    }, 
    setCctvToken(data) {
        this.setList({
            ...this.dataset,
            cctvToken : data
        })
    }, 
    setCentrexToken(data) {
        this.setList({
            ...this.dataset,
            centrexToken : data
        })
    }, 
    setPhone(data) {
        this.setList({
            ...this.dataset,
            phone : data
        })
    },
    setHomeNotice(bShow) {
        this.setList({
            ...this.dataset,
            homeNotice : bShow
        })
    },
    setDefalutGreeting(data) {
        this.setList({
            ...this.dataset,
            defaultGrt : data
        })
    },
    setDefalutCall(data) {
        this.setList({
            ...this.dataset,
            defaultCall : data
        })
    }, 
    setDefalutCheck(data) {
        this.setList({
            ...this.dataset,
            defaultCheck : data
        })
    },
    setPopupCheck(data) {
        this.setList({
            ...this.dataset,
            popupCheck : data
        })
    },
    setCallRingPopupCheck(data){
        this.setList({
            ...this.dataset,
            callRingPopupCheck: data
        })
    },
    setIsTempUser(data=true) {
        this.setList({
            ...this.dataset,
            isTempUser : data
        });
        LocalStore.setIsTempUser(data);
    },
    setFamilyYn(data='N') {
        this.setList({
            ...this.dataset,
            familyYn : data
        });
        LocalStore.setFamilyYn(data);
    },
    setMyService(data) {
        this.setList({
            ...this.dataset,
            myservice : data
        });
    },
    setCctvTempUserId(data) {
        this.setList({
            ...this.dataset,
            cctvTempUserId : data
        });
    }
});

export default userInfo;