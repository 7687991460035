import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import { layerPopup } from '../../libs/common';

const CallAddGroupPopup = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);

    useEffect(() => {       
        console.log("Bio default useEffect..");
    }, []);

    const customerClick = () => {

    } 

    return (
        <>
            <div className="modal fadeInUp" id="popup_calladdgroup">
                <div className="dim" onClick={()=> layerPopup.hide('#popup_calladdgroup')}></div>
                <div className="modal_inner modal_up">
                    <button type="button" className="pop_close_txt" onClick={()=> layerPopup.hide('#popup_calladdgroup')}>취소</button>
                    <span className="modal_title">그룹편집</span>
                    <ul className="modal_radio_list">
                        <li>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroup1" name="contactGroup1" checked onChange={()=> customerClick()} />
                                <label htmlFor="contactGroup1" className="usetap">고객</label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroup2" name="contactGroup2" checked onChange={()=> customerClick()}/>
                                <label htmlFor="contactGroup2" className="usetap">VIP</label>
                            </div>
                        </li>
                        <li>
                            <div className="checkbox_green">
                                <input type="checkbox" id="contactGroup3" name="contactGroup3" />
                                <label htmlFor="contactGroup3" className="usetap">단골</label>
                            </div>
                        </li>
                    </ul>
                    <div className="btn_box bottom_box">
                        <button type="button" className="btn_lg btn_point usetap">완료</button>
                    </div>
                </div>
            </div>
        </>
    )
    
});
export default CallAddGroupPopup;