import React, {useEffect, useState} from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import {setCctvToken} from '../../api/CctvApi';
import { layerPopup, toast} from '../../libs/common';
import { isNullData, closeBack } from '../../common/common';
import CentrexApi from '../../api/CentrexApi';
import { relativeTimeRounding } from 'moment';
import * as CONSTS from '../../const';

const AccountSelect = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    const [accountListData, setAccountListData] = useState([]);

    useEffect(() => {
        // -- 사장아이디가 선택된 이력이 있으면 바로 매장선택으로 이동
        NativeBridge.sendUserActionLog('second', 'AS');
        const ownerId = LocalStore.getOwnerId();
        if(!isNullData(ownerId)) {
            history.replace("/account/store/reg");
            return;
        }
        getUserAccountList();

        const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : AccountSelect.js ------------------");
            console.log(event);
            switch (event.type) {                    
                case 'CALL_BACKPRESS':
                    console.log("Go Back!");
                    closeBack();
                    break;
                default:
                    break;
            }
        });

        return () => {
            subscription.unsubscribe();
        };

    }, []);

    const getUserAccountList = async () => {
       const accountList = await SohoApi.getAccountList();
        console.log(accountList);
        setAccountListData(accountList.data.list);

        if (accountList.data.list.length === 1) {
            let ownerId = "";
            let familyYn = "";
            let ownerNm= "";

            accountList.data.list.map(account => {
                ownerId = account.ownerUserId;
                familyYn = account.familyYn;
                ownerNm = account.ownerNm;
            });
            
            userInfo.setOwnerId(ownerId);
            userInfo.setOwnerNm(ownerNm);
            userInfo.setFamilyYn(familyYn);

            /*
            try{
                const cctvAuthInfo = await SohoApi.getCctvAuth();
                // if(cctvAuthInfo.retCode === "0000") {
                //     const cctvToken = cctvAuthInfo.data.token;
                //     // setCctvToken(cctvToken);
                //     // LocalStore.setCctvToken(cctvToken);
                // }
                // if(cctvAuthInfo.data.resCode !== "0000") {
                //     layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "CCTV 인증실패", [
                //         function() {
                //             layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=>{
                //                 if(userData.dataset.cctvauthFlag === false) {
                //                     await SohoApi.getCctvAuth();
                //                 }
                //                 userData.setCctvAuthFlag(true)
                //             });
                //         }
                //     ]);
                //     return;
                // }
            } catch(e) {
                console.log(e);
            }
            */
            history.replace("/account/store/reg");
        }
    }

    const selectAccount = async () => {

        const checkedObj = $('input[type="radio"]:checked');
        console.log(checkedObj.length);
        if(checkedObj.length === 1) {
            const ownerId = checkedObj.val();
            const familyYn = checkedObj.attr("familyyn");
            const ownerNm= checkedObj.attr("ownernm");
            console.log("selectedId: ", ownerId);
            
            //const sohoToken = sohoAuthInfo.data.token;
            //setSohoToken(sohoToken);
            
            //LocalStore.setSohoToken(sohoToken);
            userInfo.setOwnerId(ownerId);
            userInfo.setOwnerNm(ownerNm);
            userInfo.setFamilyYn(familyYn);

            /*
            try{
                const cctvAuthInfo = await SohoApi.getCctvAuth();
                // if(cctvAuthInfo.retCode === "0000") {
                //     const cctvToken = cctvAuthInfo.data.token;
                //     setCctvToken(cctvToken);
                //     LocalStore.setCctvToken(cctvToken);
                // }
                // if(cctvAuthInfo.data.resCode !== "0000") {
                //     layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "CCTV 인증실패", [
                //         function() {
                //             layerPopup.hide("#popup_onetitlemsgbutton_api_error", async()=>{
                //                 if(userData.dataset.cctvauthFlag === false) {
                //                     await SohoApi.getCctvAuth();
                //                 }
                //                 userData.setCctvAuthFlag(true)
                //             });
                //         }
                //     ]);
                //     return;
                // }
            } catch(e) {
                console.log(e);
            }
            */

            // 센트릭스 인증키 발급
            // try {
            //     const centrexAuthInfo = await SohoApi.getCentrexAuth({"storeInetNo":"07075999903"});
            //     setCentrexSsoKey(centrexAuthInfo.data.DATAS.SESSID, centrexAuthInfo.data.DATAS.SSOHASH);
            // } catch(e) {
            //     console.log(e);
            // }


            // 다음단계(매장설정)로 이동
            history.push("/account/store/reg");

        } else {
            toast("계정을 선택해 주세요");
            return;
        }

    }

    return (
        <>
            {
                accountListData !== null && accountListData.length > 1 &&
                <div id="container">
                    <div className="content">
                        <div className="page_title_wrap nofixed">
                            <div className="inner">
                                {
                                    LocalStore.getDeviceInfo().os_type !== 'ios' ?
                                    <>
                                    <button type="button" className="btn_back usetap blind" onClick={() => {closeBack()}}>뒤로가기</button>
                                    </>
                                    :
                                    <></>
                                }
                                <h2>계정 선택</h2>
                            </div>
                        </div>
                        <ul className="account_sel_list">
                            {accountListData.map((item, index)=>(
                                <li key={`li_${item.ownerUserId}`}>
                                    <input id={`accounts_${item.ownerUserId}`} type="radio" name="sel_accounts" value={item.ownerUserId} {...{familyyn: item.familyYn, ownernm: item.ownerNm}} />
                                    <label htmlFor={`accounts_${item.ownerUserId}`} className="usetap">
                                        <span className={"account_img" + (item.familyYn === 'Y' ? ' family' : ' owner')}></span>
                                        <span className="i_name">{item.ownerNm}</span>
                                        <span className="i_sort">
                                            {item.familyYn !== "Y" ?
                                            <>소유 계정</>
                                            :
                                            <>패밀리 계정</>
                                            }
                                        </span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div className="btn_box fixed">
                            <div className="inner">
                                <button type="button" className="btn_lg btn_point usetap" onClick={()=>selectAccount()}>다음</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
    
});
export default AccountSelect;