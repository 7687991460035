import React, { useEffect, Suspense, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from './store';
import $ from 'jquery';
import * as NativeBridge from './utils/NativeBridge';
import * as common from './common/common';
import * as LocalStore from './store/LocalStore';
import * as SessionStore from './store/SessionStore';
import * as CONSTS from './const/index'
import { bubble, layerPopup } from './libs/common';
import { isNullData } from './common/common';
import {webUI, focusOutIpClear} from './libs/common';
import SohoApi from './api/SohoApi';

import Home from './components/home/Home';
import My from './components/my/My';
import Shop from './components/shop/Shop';
import CeoCommunity from './components/lounge/CeoCommunity';
import Foot from './components/foot/Foot';
import Login from './components/login/Login';
import PinCode from './components/login/PinCode';
import CallKmc from './components/login/CallKmc';
import BioReg from './components/login/BioReg';
import BioCheck from './components/login/BioCheck';
import AccountSelect from './components/login/AccountSelect';
import StoreReg from './components/login/StoreReg';
import StoreMapping from './components/login/StoreMapping';
import StoreDetailReg from './components/login/StoreDetailReg';
import FamilyAccountReg from './components/login/FamilyAccountReg';
import TermAgreeReg from './components/login/TermAgreeReg';
import TermAgreeDetail from './components/login/TermAgreeDetail';

import MySetting from './components/my/mysetting/MySetting';
import MySettingCS from './components/my/mysetting/cs/MyCS';
import CallForwarding from './components/shop/call/callsetting/CallForwarding';
import ReceiveBlockSet from './components/shop/call/callsetting/ReceiveBlockSet';
import CallRing from './components/shop/call/callsetting/callring/CallRing';
import CallLogDetail from './components/shop/call/calllog/CallLogDetail';
import PhoneBookDetail from './components/shop/call/phonebook/phonebook/PhoneBookDetail';
import PhoneBookDetailEdit from './components/shop/call/phonebook/phonebook/PhoneBookDetailEdit';
import PhoneBookDetailReg from './components/shop/call/phonebook/phonebook/PhoneBookDetailReg';
import GroupAdd from './components/shop/call/phonebook/group/GroupAdd';
import GroupDetail from './components/shop/call/phonebook/group/GroupDetail';
import GroupDetailEdit from './components/shop/call/phonebook/group/GroupDetailEdit';
import PreListen from './components/popup/PreListenPopup';
import BasicCallRing from './components/shop/call/callsetting/callring/BasicCallRing';
import AlwaysCallRing from './components/shop/call/callsetting/callring/AlwaysCallRing';
import ByDayCallRing from './components/shop/call/callsetting/callring/ByDayCallRing';
import TimeCallRing from './components/shop/call/callsetting/callring/TimeCallRing';
import SelectCallRing from './components/popup/SelectCallRingPopup';
import MessageContactGroupAddPopup from './components/popup/MessageContactGroupAddPopup';
import MessageWritePopup from './components/popup/MessageWritePopup'
import MessagePreviewPopup from './components/popup/MessagePreviewPopup';
import GroupContactAddPopup from './components/popup/GroupContactAddPopup';
import OpenTimeCallRing from './components/shop/call/callsetting/callring/OpenTimeCallRing';
import CloseTimeCallRing from './components/shop/call/callsetting/callring/CloseTimeCallRing';
import BreakTimeCallRing from './components/shop/call/callsetting/callring/BreakTimeCallRing';
import HoliDayCallRing from './components/shop/call/callsetting/callring/HoliDayCallRing';
import CommonPopup from './components/popup//common/CommonPopup';
import Interwork from './components/shop/interwork/Interwork';
import InterworkLoginPopup from './components/popup/InterworkLoginPopup';
import InterworkPwFindPopup from './components/popup/InterworkPwFindPopup';
import CallAddGroupPopup from './components/popup/CallAddGroupPopup';
import BioSetting from './components/my/mysetting/biosetting/BioSetting';
import MyFamilySet from './components/my/mysetting/familysetting/MyFamilySet';
import MyFamilySetPhone from './components/my/mysetting/familysetting/MyFamilySetPhone';
import MyPolicySet from './components/my/mysetting/policysetting/MyPolicySet';
import MyPolicyDetail from './components/my/mysetting/policysetting/MyPolicyDetail';
import MyAccess from './components/my/mysetting/policysetting/MyAccess';
import EventNotice from './components/my/mysetting/cs/Notice/EventNotice';
import Notice from './components/my/mysetting/cs/Notice/Notice';
import MsgLogDetail from './components/shop/msg/msglog/MsgLogDetail';
import InterworkLoginTempPopup from './components/popup/InterworkLoginTempPopup';
import CallLogDetailReg from './components/shop/call/calllog/CallLogDetailReg';
import InviteAccountNotice from './components/my/mysetting/cs/Notice/InviteAccountNotice';
import MyPolicyContent from './components/my/mysetting/policysetting/MyPolicyContent';
import MakeCallRingPopup from './components/popup/MakeCallRingPopup';
import RingRegiPopup from './components/popup/RingRegiPopup';
import SohoAuthErrorPopup from './components/popup/SohoAuthErrorPopup';
import SohoCentrexAuthErrorPopup from './components/popup/SohoCentrexAuthErrorPopup';
import CentrexAuthErrorPopup from './components/popup/CentrexAuthErrorPopup';
import SohoCctvAuthErrorPopup from './components/popup/SohoCctvAuthErrorPopup';
import CctvAuthErrorPopup from './components/popup/CctvAuthErrorPopup';
import {checkAgreeRegInfo, checkFamilyInviteInfo} from './components/login/DefaultSet';

import TagManager from 'react-gtm-module'
import OrderMain from './components/ssisicga/OrderMain';

// const Home = React.lazy(() => import('./components/home/Home'));
// const My = React.lazy(() => import('./components/my/My'));
// const Shop = React.lazy(() => import('./components/shop/Shop'));
// const CeoCommunity = React.lazy(() => import('./components/lounge/CeoCommunity'));
// const Foot = React.lazy(() => import('./components/foot/Foot'));
// const Login = React.lazy(() => import('./components/login/Login'));
// const PinCode = React.lazy(() => import('./components/login/PinCode'));
// const CallKmc = React.lazy(() => import('./components/login/CallKmc'));
// const BioReg = React.lazy(() => import('./components/login/BioReg'));
// const BioCheck = React.lazy(() => import('./components/login/BioCheck'));
// const AccountSelect = React.lazy(() => import('./components/login/AccountSelect'));
// const StoreReg = React.lazy(() => import('./components/login/StoreReg'));
// const StoreMapping = React.lazy(() => import('./components/login/StoreMapping'));
// const StoreDetailReg = React.lazy(() => import('./components/login/StoreDetailReg'));
// const FamilyAccountReg = React.lazy(() => import('./components/login/FamilyAccountReg'));
// const TermAgreeReg = React.lazy(() => import('./components/login/TermAgreeReg'));

const tagManagerArgs = {
    dataLayer: {}
};

let currPathName = "/";
let IntervalId;

const Main = observer(({ match, location, history }) => {

    // const [camIdList, setCamIdList] = useState([])
    // const [camThumbList, setCamThumbList] = useState([])

    const { userInfo, userData, deviceInfo } = store;
    const [ pushCallData, setPushCallData] = useState(null); // 푸시알림
    const [serviceDetails, setServiceDetails] = useState(null);

    //add GA TAG - 21.12.20
    //ReactGA.initialize(process.env.REACT_APP_SOHO_GA_ID);

    // const userList = userInfo.getList();
    // console.log(userList);
    // let isAppfinish = false;
    // let InitStoreInfo = userData.dataset.storeList
    // var ThumbNailLists = []

    useEffect(() => {
        console.log(location);
        $('html, body').scrollTop(0);

        // tagManagerArgs.dataLayer.push({
        //     event: "path",
        //     url: location.pathname
        //     // userId: LocalStore.getUserId()
        // });

        tagManagerArgs.dataLayer.url = location.pathname;
        TagManager.dataLayer(tagManagerArgs);

        //add GA TAG - 21.12.20
        //ReactGA.send({ hitType: "pageview", page: location.pathname});

    }, [location]);
    
    useEffect(() => {

        //--- kmc callback skip
        // if(location.pathname === "/callbackkmc") return;

        $('#wrap').removeClass('bg_gray');

       //native event set
        const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : Main.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'GET_DEVICE_INFO':
                    const deviceData = event.param;
                    console.log(" deviceData: " , deviceData);
                    deviceInfo.setData(deviceData);
                    // splash 닫기
                    NativeBridge.initialSplashFinished();
                break;

                case 'CALL_BACKPRESS':
                    console.log("Go Back!");
                    const bBack = checkHistoryBack();
                    console.log("bBack: ", bBack);
                    if(bBack) {
                        history.goBack();
                    }
                break;
                case 'PUSH_LAND':
                    const pushData = event.param;
                    console.log(" pushData: " , pushData);
                    setPushCallData(pushData);
                break;
                case 'CALL_DEVICE_EVENT':
                    if(event.param.action === "resume") {

                    }
                    if(event.param.action === "pause") {
                        
                    }
                break;

                case 'SET_BIOMETRICS':
                    const bioData = event.param;
                    if(LocalStore.getBioLoginType()===null) return;

                    // 다음 값인경우 인증 내역 제거 (1: 인증미등록, 3: 인증변경) 
                    if(bioData.status === "1" || bioData.status === "3") {
                        //LocalStore.removeBioLoginType();
                        userInfo.setBioLoginType(null);
                    }
                break;


            default:
                break;
            }

        });

        //------------------------------------ PC에서 backkey test 용 (ESC)
        if(common.isPC()) {            
            $(document).on("keydown", (event) => {
                if(event.key === "Escape") {
                    window.NativeInvoker({type:"CALL_BACKPRESS"});
                    event.preventDefault();
                }
            });
        }
        //------------------------------------ PC에서 backkey test 용 (ESC)


        // document.addEventListener("DOMContentLoaded", function() {       //현재 useEffect 상태에서는 이미 "DOMContentLoaded" 가 완료 된 상태이므로 이부분 주석 후 바로 실행
        $(function(){
            var hasTouchEvent = "ontouchstart" in document.documentElement,
                START_EV = hasTouchEvent ? "touchstart" : "mousedown",
                END_EV = hasTouchEvent ? "touchend" : "mouseup";
            var dragPoint = false;
            webUI.addDelegate(document.body, START_EV, ".usetap", function(e) {
                dragPoint = true;
                this.classList.add("active");
            });
            webUI.addDelegate(document.body, END_EV, ".usetap", function(e) {
                dragPoint = false;
                this.classList.remove("active");
            });
            webUI.addDelegate(document.body, "touchcancel", ".usetap", function(e) {
                dragPoint = false;
                this.classList.remove("active");
            });
            webUI.addDelegate(document.body, "mousemove", ".usetap", function(e) {
                if (dragPoint == true) {
                    e.target.onmouseout = function() {
                        this.classList.remove("active");
                    }
                }
            });
        // });
        });

        $(function(){
            // Input focusin .ip_clear show
            if($('.input_box').length > 0){
                
                $('div').on('focusin', '.input_box input', function () {
                    if ($(this).val().length > 0) { // check if value changed        
                        $(this).siblings('.ip_clear').show();
                        $(this).siblings('.ip_recipient').hide();
                        $(this).addClass('ip_sp');
                    } else {
                        $(this).removeClass('ip_sp');
                    }
                });

                // Input focusout .ip_clear hide
                $('div').on('focusout', '.input_box input', function () {
                    $(this).removeClass('ip_sp');
                    var thisVal = this;
                    focusOutIpClear(thisVal); // setTimeout hide
                });

                // Input keyup .ip_clear show
                $('div').on('keyup', '.input_box input', function () {        
                    if ($(this).val().length > 0) {       
                        $(this).siblings('.ip_clear').show();
                        $(this).siblings('.ip_recipient').hide();
                        $(this).addClass('ip_sp');
                    } else  {
                        $(this).siblings('.ip_clear').hide();
                        $(this).siblings('.ip_recipient').show();
                        $(this).removeClass('ip_sp');
                    }
                });

                // Input reset
                $('.ip_clear').on("click", function(){
                    $(this).siblings('input').val('')
                    .trigger('propertychange').focus();
                });
                
            };
            


            // Input_box.style3
            if($('.input_box.style3').length > 0){	
                
                $('.label_placeholder').on("click", function(){ // input gradient
                    $(this).siblings('input').focus();
                });
                
                $(".input_box.style3 input").each(function(){
                    if( $(this).val().length === 0 ){
                        $(this).siblings('.label_placeholder').show(); // label - placeholder
                    }else{
                        $(this).siblings('.label_placeholder').hide();
                    }
                })

                $('div').on('focusin', '.input_box.style3 input', function () {
                    $(this).siblings('.label_placeholder').hide();
                    $(this).siblings('.ip_gradient').hide();
                });
                
                $('div').on('keyup', '.input_box.style3 input', function () {
                    $(this).siblings('.label_placeholder').hide();
                });
                
                $('div').on('focusout', '.input_box.style3 input', function () {
                    if ($(this).val().length > 0) {       
                        $(this).siblings('.label_placeholder').hide();
                        $(this).siblings('.ip_gradient').show();
                    } else {
                        $(this).siblings('.label_placeholder').show();
                    }
                });
                
                $('.ip_gradient').on("click", function(){ // input gradient
                    $(this).siblings('input').focus();
                });
            }
            
            // Input_box.style3
            if($('.textarea_greet').length > 0){

                $('.textarea_label').on("click", function(){ // input gradient
                    $(this).siblings('textarea').focus(); 
                });
                
                $(".textarea_greet textarea").each(function(){
                    if( $(this).val().length === 0 ){
                        $(this).siblings('.textarea_label').show(); // label - placeholder
                    }else{
                        $(this).siblings('.textarea_label').hide();
                    }
                })

                $('div').on('focusin', '.textarea_greet textarea', function () {
                    $(this).siblings('.textarea_label').hide();
                });
                
                $('div').on('focusout', '.textarea_greet textarea', function () {
                    if ($(this).val().length > 0) {       
                        $(this).siblings('.textarea_label').hide();
                    } else {
                        $(this).siblings('.textarea_label').show();
                    }
                });
            }
        });        

        const deviceData = NativeBridge.getDeviceInfo();

        
        // setCctvToken("bZlV65GCFyUbwctsgtLcWHYctjJUreh2XSC6ggkZlOGcCzdXsJI6eIw+rFD0ghnbuOEzJr+2/RVZPfz2kwg6YyuJCYu0om0YE4PQiLoavtY=");
        // CctvApi.getCameras().then((rst)=> {
        //     console.log('sdfaf',rst);
        //     LocalStore.setDeviceSnId(rst.data.list?.device_sn_id)    
        // });

        // CctvApi.getAuth().then((rst)=> {
        //     console.log(rst);
        // });

        // const param = {
        //     "userId": "playertest",
        //     "userPwd": "zMCmR0bA5pakza0ixD8lSNWuhpeplzYGalWkzekrEPYpUqkGMjFuJHKgmt9m/qh5gRlomx9HaR6Wc3hTc94E+A=="
        // };
        // CctvApi.getAuth(param).then((rst)=> {
        //     console.log(rst);
        // });

        // const p = {
        //     id : "07075999903",
        //     pass : "67c543b817a71b79435c0172a9128a850946edc325bedd63165aea94e31d783e7d9ea5b483a37f6f6cc7608ff867b32d3b42ce2ba1c9ba154c4b5fed7ddc8fcf"
        // }
        // CentrexApi.getCallHistoryInfo(p).then((rst)=> {
        //     console.log(rst);
        // });

        // var param = {
        //     urlCode: "001002"
        // }
        // $.ajax({
        //     url:'http://106.103.228.193/sohosvc/api/kmcUrlEnc',
        //     type:'post',
        //     data: param,
        //     success:function(data){
        //         console.log(data);
        //     }
        // });


        // SohoApi.getCentrexAuth({"storeInetNo":"07075999903"}).then((rst)=> {
        //     console.log(rst);
        // });


        // const enPW = Crypt.centrexPwEncrypt('lguplus1!');
        // console.log("==================== enPW : " , enPW);

         return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if(userData.dataset.sissicgaRegiYn === 'Y') {
            IntervalId = startTimeInterval(10, 'Y', IntervalId);
            // console.log('123인터벌시작', IntervalId);
        } else {
            startTimeInterval(1, 'N', IntervalId);
        }
    }, [userData.dataset.sissicgaRegiYn])

    const startTimeInterval = (seconds, RegiYn, IntervalId="") => {
        if(IntervalId !== "") {
            // console.log('123클리어인터벌', IntervalId);
            clearInterval(IntervalId);
        }

        if(RegiYn === 'Y') {
            // console.log('123인터벌스타트', IntervalId);
            getOrderListApi();
            return setInterval(() => {
                getOrderListApi()
            }, seconds * 1000);
        }
    };
    
    // 로그인 상태가 바뀔때마다 전체 데이터 로직 체크 (홈 상태일경우만)
    useEffect(() => {
        currPathName = location.pathname;

        // : /login, /callkmc, /pincode, /bio  경로가 아니면 항상 로그인 체크하여 로그인으로 이동시킨다.
        if(location.pathname !== '/' && location.pathname !== '/home' && location.pathname.match(/^\/login/) === null && location.pathname.match(/^\/callkmc/) === null && location.pathname.match(/^\/pincode/) === null && location.pathname.match(/^\/bio/) === null) {
            console.log("=== check login process ===");

            const userId = LocalStore.getUserId();
            userInfo.setUserId(userId);
    
            //--로그인 한 기록이 없으면 로그인 페이지로 이동
            if(common.isNullData(userId)) {
                history.replace("/login");
                return;
            }

            const loginSession = SessionStore.getLoginSession();
            if(common.isNullData(loginSession) || userInfo.dataset.isLogin !== true) {
                const bioType = LocalStore.getBioLoginType();
                //pin 번호 체크
                if(common.isNullData(bioType)) {
                    history.replace("/pincode/check");                
                //bio 체크
                } else {
                    //NativeBridge.checkBiometrics(bioType);
                    userInfo.setBioLoginType(bioType);
                    history.replace("/bio/check");
                }
                return;
            }

        }

        if(location.pathname === '/home' || location.pathname === '/my' || location.pathname === '/shop' || location.pathname === '/lounge') {
            $("#wrap").removeClass("fixed_bottom")
            if(!$("#wrap").hasClass("gnb_bottom")) {
                $("#wrap").addClass("gnb_bottom")
            }
        }
        
        if(location.pathname !== '/' && location.pathname !== '/home') return;
        
        checkDefaultUserAuth();

    }, [location.pathname, userInfo.dataset.isLogin])




    useEffect(()=>{

        // -- 1. 푸시 데이터 버리는 케이스
        // KMC 로그인 이력이 없으면 버린다.
        if(LocalStore.getUserId()===null) {
            setPushCallData(null);
        }

        // -- 1. 푸시 데이터 사용 케이스 (아래 페이지에서는 바로 이동하지 않고, 아래 페이지에서 벗어났들때 이동한다. )
        if(userInfo.dataset.isLogin 
            && pushCallData !== null 
            && location.pathname.indexOf("/pincode/") < 0 
            && location.pathname.indexOf("/bio/") < 0 
            && location.pathname.indexOf("/agree/") < 0
            && location.pathname.indexOf("/agree/") < 0
            && location.pathname.indexOf("/account/family/reg") < 0
        ) 
        {
            setPushCallData(null);
            executePushCall(pushCallData);
        }

    }, [pushCallData, userInfo.dataset.isLogin, location.pathname]);


    const executePushCall = (dataObj) => {

        // return;
        const callType = dataObj.type;
        const callData = dataObj.data;

        // 공지사항
        if(callType === "N") {
            if(LocalStore.getIsTempUser() === true || LocalStore.getFamilyYn() === 'Y') {
                history.push("/my/mysetting/cs/familynotice/"+callData.LINK_ID);
            } else {
                history.push("/my/mysetting/cs/notice/"+callData.LINK_ID);
            }
        } else if(callType=== "B") {
            
            userData.setBnfCateId(callData.LINK_CATG);
            userData.setBnfId(callData.LINK_ID);
            history.push("/ceocommunity");
        }

    };



    /**
     * 
     * 팝업이 떠 있는지 검사하고 있으면 팝업을 닫은 후(닫지 않는 예외도 있음) false 를 리턴한다. 
     * 팝업이 없으면 true 를 리턴한다.
     */
    const checkHistoryBack = () => {
        let rst = true;

        //-- 아래 팝업이 있으면 아무것도 안함 (팝업닫기, 뒤로가기 모두 안함)
        const protectPopupIdList = [
            "popup_bio",
            "popup_my_bio",
            "popup_accountselect",
            "popup_onetitlemsgbutton_api_error",
            "popup_family_account",
            "popup_store_detail_info",
            "popup_msgwrite",
            "popup_msgset",
            "popup_msgpreview_in",
            "popup_soho_auth_error_logout",
            "popup_pushalram_home",
            "popup_onetitle_twomsgbutton_homepopup",
            "popup_interwork_pw_find",
            "popup_interworklogin",
            
        ];


        //-- 아래 팝업있으면 닫지않고 뒤로가기 함
        const passPopupIdList = [
            "modal_cs"
        ];        

        //-- 아래 url 에서는 백키로 아무것도 안함 (팝업닫기, 뒤로가기 모두 안함)
        const protectRoutePath = [
            "/pincode/reg",
            "/pincode/check",
            "/pincode/checkreg",
            "/agree/reg",
            "/shop/call/callset/callring",
            "/shop/call/callset/callring/alwayscall",
            "/shop/call/callset/callring/bydaycall",
            "/shop/call/callset/callring/basiccall",
            "/shop/call/callset/callring/opentimecallring",
            "/shop/call/callset/callring/closetimecallring",
            "/shop/call/callset/callring/breaktimecallring",
            "/shop/call/callset/callring/holidaycallring",
            "/shop/call/callset/callring/timecall",
            "/shop/call/phonebook/detail",
            "/shop/interwork",
            "account/owner/select"
        ];

   

        // url 검사
        if(protectRoutePath.indexOf(currPathName) > -1) {
            return false;
        }

       
        
        
        // -- 팝업 오픈되어 있는지 검사
        let isPopupOpen = false;
        $(".modal").each((index, item) => {
            if(passPopupIdList.indexOf($(item).attr("id")) > -1) {
                return true;    // continue
            }
            const currDisplay =  $(item).css("display");
            if(currDisplay === "block") {
                isPopupOpen = true;
                const currPopupId = $(item).attr("id");
                if(protectPopupIdList.indexOf(currPopupId) < 0) {
                    layerPopup.hide("#"+currPopupId);
                    return false;   //break
                }
                return false;       //break
            }
        });

        // -- 위 오픈된 팝업이 없으면 홈화면 배너팝업도 검사
        if(!isPopupOpen) {
            $(".modal_banner").each((index, item) => {
                const currDisplay =  $(item).css("display");
                if(currDisplay === "block") {
                    isPopupOpen = true;
                    const currPopupId = $(item).attr("id");
                    layerPopup.hide("#"+currPopupId);
                    return false;
                }
            });
        }

        rst = !isPopupOpen;
        return rst;
    }



    const getOrderListApi = () => {
        try {
            SohoApi.getOrderList({
                "storeId": userData.dataset.storeId,
                "ordDate": common.getSimpleDate()
                // "ordDate": "20220330"
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS && rst.data.orderList.length > 0) {
                    bubble('새로운 주문이 들어왔어요! 주문 확인 해주세요.');
                }
            }).catch((e) => {
                console.log(e);
            })
        } catch(e) {
            console.log(e);
        }
    }


    const checkDefaultUserAuth = async () => {

        //device info setting to mobx store
        // const deviceData = NativeBridge.getDeviceInfo();
        // console.log(" deviceData: " , deviceData);

        // deviceInfo.setData(deviceData.param);
        // LocalStore.setDevicefInfo(deviceData.param);

        let userId = LocalStore.getUserId();
        const istempuser = LocalStore.getIsTempUser();
        const userPinCode = LocalStore.getPinCode();
        userInfo.setUserId(userId);

        if (istempuser && common.isNullData(userPinCode)) {
            LocalStore.clear();
            userId = LocalStore.getUserId();
            history.replace("/login");
            return;
        }

        //-- check1.  로그인 한 기록이 없으면 로그인 페이지로 이동
        if(common.isNullData(userId)) {
            history.replace("/login");
            return;
        }

        //-- check2. 핀번호가 설정되어 있지 않으면, 핀번호 설정 페이지로 이동
        if(common.isNullData(userPinCode)) {
            history.replace("/pincode/reg");
            return;
        }

        //-- check3. 로그인 세션이 없으면 로그인 페이지로 이동시킨다.
        const loginSession = SessionStore.getLoginSession();
        if(common.isNullData(loginSession) || userInfo.dataset.isLogin !== true) {

            // 로그인 전에 이전 정보제공동의 데이터 흔적이 있으면 제거
            if (LocalStore.getTermsAgree() !== null) {
                LocalStore.removeTermsAgree();
            }

            const bioType = LocalStore.getBioLoginType();
            //pin 번호 체크
            if(common.isNullData(bioType)) {
                history.replace("/pincode/check");                
            //bio 체크
            } else {
                //NativeBridge.checkBiometrics(bioType);
                userInfo.setBioLoginType(bioType);
                history.replace("/bio/check");
            }
            return;
        } 



        // 임시 사용자 여부 체크
        const isTempUser = LocalStore.getIsTempUser();
        userInfo.setIsTempUser(isTempUser);

        // 선택된 사장 아이디 가져오기
        const ownerId = LocalStore.getOwnerId();
        userInfo.setOwnerId(ownerId);

        const ownerNm = LocalStore.getOwnerNm();
        userInfo.setOwnerNm(ownerNm);


        //--- 선택된 사장 아이디가 없으면 계정선택(사장계정선택) 화면으로 이동
        if(isNullData(ownerId)) {
            history.replace("/account/owner/select");
            return;
        }

        if(isTempUser === true) return;
        /* ------------------------------------------------------------------------- 여기까지 임시 사용자 체크 부분 ----------------------------------------------- */

        //-- check.11  동의 필수값 체크
        if(SessionStore.getAgreeCheck() === null) {
            if(await checkAgreeRegInfo()===true) {
                history.replace("/agree/reg");
                return; 
            }
        }

        // -- 패밀리 초대 목록을 체크한다.
        if(SessionStore.getInviceFamilyCheck() === null) {
            if(await checkFamilyInviteInfo() === true) {
                history.replace("/account/family/reg");
                return;
            }
        }

        // await BizApi.makeMyService();
        /*
        tagManagerArgs.dataLayer.event = "login";
        tagManagerArgs.dataLayer.userId = LocalStore.getUserId();
        
        let mySvc = { ...userInfo.dataset.myservice }
        tagManagerArgs.dataLayer.myservice = {}
        tagManagerArgs.dataLayer.myservice.call = mySvc.call;
        tagManagerArgs.dataLayer.myservice.cctv = mySvc.cctv;
        TagManager.dataLayer(tagManagerArgs);
        */
    }

    const goSissicga = () => {
        userData.setSisicMainTabList('order');
        userData.setOrderTabList('neworder');
        history.push("/ssisicga/order/neworder");
    }

    return (
        <> 
            <Suspense fallback={<div></div>}>
                <div id="wrap" className="gnb_bottom">
                    <div className="container">
                    <Switch> 
                        <Route path="/home" component={Home} />
                        <Route path="/shop/call/calllog/detail/reg" component={CallLogDetailReg} />
                        <Route path="/shop/call/calllog/detail" component={CallLogDetail} />
                        <Route path="/shop/call/phonebook/detail/edit" component={PhoneBookDetailEdit} />
                        <Route path="/shop/call/phonebook/detail" component={PhoneBookDetail} />
                        <Route path="/shop/call/phonebook/reg" component={PhoneBookDetailReg} />
                        <Route path="/shop/call/phonebook/group/add" component={GroupAdd} />
                        <Route path="/shop/call/phonebook/group/:detailid/edit" component={GroupDetailEdit} />
                        <Route path="/shop/call/phonebook/group/:detailid/detail" component={GroupDetail} />
                        <Route path="/shop/call/callset/callring/timecall" component={TimeCallRing} />
                        <Route path="/shop/call/callset/callring/bydaycall" component={ByDayCallRing} />
                        <Route path="/shop/call/callset/callring/alwayscall" component={AlwaysCallRing} />
                        <Route path="/shop/call/callset/callring/holidaycallring" component={HoliDayCallRing} />
                        <Route path="/shop/call/callset/callring/breaktimecallring" component={BreakTimeCallRing} />
                        <Route path="/shop/call/callset/callring/closetimecallring" component={CloseTimeCallRing} />
                        <Route path="/shop/call/callset/callring/opentimecallring" component={OpenTimeCallRing} />
                        <Route path="/shop/call/callset/callring/basiccall" component={BasicCallRing} />
                        <Route path="/shop/call/callset/receiveBlockSet" component={ReceiveBlockSet} />
                        <Route path="/shop/call/callset/callring" component={CallRing} />
                        <Route path="/shop/call/callset/callfowarding" component={CallForwarding} />
                        <Route path="/shop/msg/msglog/detail" component={MsgLogDetail} />

                        <Route path="/shop/interwork" exact component={Interwork} />
                        <Route path="/shop/:mainmenu" component={Shop} />
                        <Route path="/shop" component={Shop} />

                        <Route path="/ssisicga/:mainmenu" component={OrderMain} />
                        <Route path="/ssisicga" component={OrderMain} />

                        <Route path="/ceocommunity" component={CeoCommunity} />
                        <Route path="/my/mysetting/biosetting" component={BioSetting} />
                        <Route path="/my/mysetting/familysetting/add" component={MyFamilySetPhone} />
                        <Route path="/my/mysetting/familysetting" component={MyFamilySet} />
                        <Route path="/my/mysetting/policyaccess/termslist/detail" component={MyPolicyContent} />
                        <Route path="/my/mysetting/policyaccess/termslist/:termsIdx" component={MyPolicyDetail} />
                        <Route path="/my/mysetting/policyaccess/termslist" component={MyPolicyDetail} />
                        <Route path="/my/mysetting/policyaccess/accesslist" component={MyAccess} />
                        <Route path="/my/mysetting/policyaccess" component={MyPolicySet} />

                        <Route path="/my/mysetting/cs/eventnotice/:eventnoticeIdx" component={EventNotice} />
                        <Route path="/my/mysetting/cs/eventnotice" component={EventNotice} />
                        <Route path="/my/mysetting/cs/notice/:noticeIdx" component={Notice} />
                        <Route path="/my/mysetting/cs/notice" component={Notice} />
                        <Route path="/my/mysetting/cs/familynotice/:noticeIdx" component={InviteAccountNotice} />
                        <Route path="/my/mysetting/cs/familynotice" component={InviteAccountNotice} />
                        <Route path="/my/mysetting/cs" component={MySettingCS} />
                        <Route path="/my/mysetting" component={MySetting} />
                        <Route path="/my" component={My} />

                        <Route path="/login" component={Login} />
                        <Route path="/pincode/:type" component={PinCode} />
                        <Route path="/bio/reg" component={BioReg} />
                        <Route path="/bio/myreg" component={BioReg} />
                        <Route path="/bio/check" component={BioCheck} />
                        <Route path="/callkmc/:from" component={CallKmc} />
                        <Route path="/callkmc" component={CallKmc} />
                        <Route path="/agree/reg" component={TermAgreeReg} />
                        <Route path="/agree/detail/:detailid" component={TermAgreeDetail} />
                        <Route path="/account/family/reg" component={FamilyAccountReg} />
                        <Route path="/account/owner/select" component={AccountSelect} />
                        <Route path="/account/store/reg" component={StoreReg} />
                        <Route path="/account/store/modify" component={StoreReg} />
                        <Route path="/account/store/mapping" component={StoreMapping} />
                        <Route path="/account/store/detail" component={StoreDetailReg} />
                        
                        <Redirect to='/home' />
                    </Switch>
                    </div>
                    {
                        location.pathname !== "/notice"
                        && location.pathname !== "/eventnotice" 
                        && location.pathname !== "/login" 
                        && location.pathname !== "/notice/detail/:noticeId" 
                        && location.pathname.substr(0,8) !== "/pincode" 
                        && location.pathname !== "/bio/reg" 
                        && location.pathname !== "/bio/check" 
                        && location.pathname !== "/bio/myreg" 
                        && location.pathname !== "/agree/reg" 
                        && location.pathname.substr(0,13) !== "/agree/detail" 
                        && location.pathname !== "/shop/call/callset/callfowarding" 
                        && location.pathname !== "/shop/call/callset/callring" 
                        && location.pathname !== "/shop/call/callset/receiveBlockSet"
                        && location.pathname !== "/shop/call/calllog/reception/detail" 
                        && location.pathname !== "/shop/call/calllog/reception/newdetail"
                        && location.pathname !== "/shop/call/calllog/reception/newdetail/reg"
                        && location.pathname !== "/shop/call/calllog/sent/detail"
                        && location.pathname !== "/shop/call/calllog/sent/newdetail"
                        && location.pathname !== "/shop/call/calllog/sent/newdetail/reg"
                        && location.pathname !== "/shop/call/calllog/reception/detail/edit" 
                        && location.pathname !== "/shop/call/calllog/sent/detail/edit" 
                        && location.pathname !== "/shop/call/calllog/detail/edit" 
                        && location.pathname !== "/shop/call/phonebook/phonebook/detail/edit" 
                        && location.pathname !== "/shop/call/phonebook/phonebook/detail" 
                        && location.pathname !== "/shop/call/phonebook/reg"
                        && location.pathname.indexOf("/shop/call/phonebook/detail") === -1
                        && location.pathname.indexOf("/shop/call/phonebook/group/") === -1
                        && location.pathname !== "/shop/call/callset/callring/basiccall" 
                        && location.pathname !== "/shop/call/callset/callring/opentimecallring" 
                        && location.pathname !== "/shop/call/callset/callring/closetimecallring" 
                        && location.pathname !== "/shop/call/callset/callring/breaktimecallring" 
                        && location.pathname !== "/shop/call/callset/callring/holidaycallring" 
                        && location.pathname !== "/shop/call/callset/callring/alwayscall"
                        && location.pathname !== "/shop/call/callset/callring/timecall" 
                        && location.pathname !== "/shop/call/callset/callring/bydaycall" 
                        && location.pathname !== "/shop/call/callset/receiveblockset"
                        && location.pathname !== "/shop/msg/msglog/detail"
                        && location.pathname !== "/shop/interwork"
                        && location.pathname !== "/callkmc" 
                        && location.pathname !== "/callkmc/modpass" 
                        && location.pathname !== "/account/family/reg"                         
                        && location.pathname !== "/account/owner/select" 
                        && location.pathname !== "/account/store/reg"
                        && location.pathname !== "/account/store/modify"
                        && location.pathname !== "/account/store/mapping"
                        && location.pathname !== "/account/store/detail"
                        && location.pathname.indexOf("/my/mysetting") === -1
                        && <Route component={Foot} />
                    }
                </div>
                
                {/* popup */}
                <Route component={MakeCallRingPopup} />
                <Route component={PreListen} />
                <Route component={SelectCallRing} />
                <Route component={InterworkLoginPopup} />
                <Route component={InterworkLoginTempPopup} />
                <Route component={InterworkPwFindPopup} />
                <Route component={CallAddGroupPopup} />
                <Route component={MessageWritePopup} />
                <Route component={RingRegiPopup } />
                { location.pathname === "/shop/call/phonebook/group/add" || location.pathname === "/shop/call/phonebook/group/detail/edit" ?
                    <Route component={GroupContactAddPopup} />
                :
                    <Route component={MessageContactGroupAddPopup} />
                }
                <Route component={MessagePreviewPopup} />
                <Route component={CommonPopup} />
                {/* Auth Error Popup */}
                <Route component={CctvAuthErrorPopup} />
                <Route component={CentrexAuthErrorPopup} />
                <Route component={SohoCentrexAuthErrorPopup} />
                <Route component={SohoCctvAuthErrorPopup} />
                <Route component={SohoAuthErrorPopup} />
                <div id="toast" className="type_gnb"></div>
                <div id="bubble" className="bubble_box" onClick={()=> goSissicga()}></div>
            </Suspense>
        </>
    )
});

export default Main;