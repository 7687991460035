import React, { useEffect, useState, useCallback } from "react";
import { Link, Route } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import * as LocalStore from '../../../../store/LocalStore';
import CentrexApi from "../../../../api/CentrexApi";
import { getAMPM, getDate, getTimes, getAddressInfo, getCurrentDate } from "../../../../common/common";
import { floatUI } from '../../../../libs/floating'
import { tabUi } from '../../../../libs/tab'
import * as CONSTS from '../../../../const';
import SohoApi from "../../../../api/SohoApi";
import { layerPopup } from "../../../../libs/common";

import 'moment/locale/ko';
import moment from "moment";
import ContactDataNone from "../ContactDataNone";
import CallLogError from "../../../shop/call/CallLogError" ;

//전화 설정 페이지
const CallLogMain = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();

    const [callLogList, setCallLogList] = useState([]);
    const [callLogDrawList, setCallLogDrawList] = useState([]);
    const [currentPage, setPage] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [listCnt, setListCnt] = useState(0);
    const [errorFlag, setErrorFlag] = useState(false);

    const CALLTYPE_OUTBOUND = "outbound";
    const CALLTYPE_INBOUND = "inbound";

    let callType = CALLTYPE_OUTBOUND;

    useEffect(() => {
        tabUi.init3DepthTab(document.getElementById('tab_style4'));

        $("#contact_scroll_area").on('scroll', function() {
            const scrollTop = $("#contact_scroll_area").scrollTop()
            if(scrollTop <= 0) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        })
    }, []);

    //TODO : 왜 백으로 왔는데 두번호출이 되는 것일까. 확인 필요함.
    useEffect(() => {
        if ( !userData.dataset.shopMainTabCallError ){
            getCallLogList();
        }
    }, [userData.dataset.currstoreInfo, userData.dataset.shopMainTabCallError]);

    useEffect(() => {

		var drawData = callLogList.reduce((src, dest ) => {
			(src[dest.GROUPDIV] = src[dest.GROUPDIV] || []).push(dest);
			return src;
		}, {});

		var drawlstData = [];
		Object.keys(drawData).map((_key) => {
			if ( drawlstData[_key] === null ){
				drawlstData[_key] = [];
			}
			drawlstData.push({
				title : _key
				,items : drawData[_key]
			});
		});

        drawlstData.sort( (_src, _dest) => {
            if ( _src.title > _dest.title) return -1;
            if ( _src.title === _dest.title) return 0;
            if ( _src.title < _dest.title) return 1;
        });

        if(Array.isArray(drawlstData)) {
            let listcnt = 0;
            drawlstData.map(list=>{
                listcnt++;
                list.items.map(item=>{
                    listcnt++;
                })
            });
            console.log("listcnt:"+listcnt);
            setListCnt(listcnt);
        }

		setCallLogDrawList(drawlstData);
        //console.log("sdfsdf",drawlstData)
        
    }, [callLogList]);

      //새로고침 클릭 시 api 재호출
  const reset = () => {
    getCallLogList();
  }

  useEffect(() => {
    //화면 진입 시 호출 방지 (값 받아 올 때만 호출)
    if(userData.dataset.callPageReset === true){
      reset();
      userData.setCallPageReset(false);
    }
    
  },[userData.dataset.callPageReset])

    const getCallLogList = async (_page=1) => {

        let _errorFlag = false;
        let _callLogList = [];
        let _totalCnt = 0;
        setPage(_page);

        //아웃바운드
        const result = await CentrexApi.getCallHistoryInfo({
            page: _page
            ,calltype: CALLTYPE_OUTBOUND
            ,startday : moment().add(-5,"days").format('YYYY-MM-DD')
            ,endday : moment().format('YYYY-MM-DD')
        }).catch((e) => {

            /*
            layerPopup.showCommon("#popup_onetitlemsgbutton_api_error", null, "알림", "서버오류가 발생했습니다. 재시도 해주세요", [
              function() {
                  SohoApi.getCentrexAuth({storeInetNo: LocalStore.getStoreInetNo()}).then(centrexAuthInfo => {
                    // if(centrexAuthInfo.retCode === "0000") {
                    //   NativeBridge.setCentrexSsoKeyToNative(centrexAuthInfo.data.sessId, centrexAuthInfo.data.ssoHash, LocalStore.getStoreInetNo());
                    // }                  
                    layerPopup.hide("#popup_onetitlemsgbutton_api_error");
                  });
              }
            ]);
            */
            userData.setShopMainTabCallError(true);
            return;
        });

        if( (result?.data.SVC_RT === "0000" || result?.data.SVC_RT === "4004" ) ){
            const callLogListPage = result.data.DATAS;
            _totalCnt = result.data.LISTINFO.total;
            _callLogList = makeData(callLogListPage, CALLTYPE_OUTBOUND);
            console.log("통화목록조회 결과 : 아웃바운드", _callLogList);
            userData.setShopMainTabCallError(false);
        }else{
            //TODO 에러보내기
            userData.setShopMainTabCallError(true);
            return;
        }

        //----------------------------
        //인바운드
        //----------------------------
        const resultInBound = await CentrexApi.getCallHistoryInfo({
            page: _page
            ,calltype: CALLTYPE_INBOUND
            ,startday : moment().add(-5,"days").format('YYYY-MM-DD')
            ,endday : moment().format('YYYY-MM-DD')
        }).catch((e) => {

            /*
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(인터넷 전화)", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
            */
            userData.setShopMainTabCallError(true);
            return;
        })

        //console.log("통화내역조회 인바운드 ===>", resultInBound);

        if(resultInBound.data.SVC_MSG === "OK" || resultInBound.data.SVC_MSG === "NO_DATA"){
            const callLogListInbound = resultInBound.data.DATAS;
            _totalCnt += resultInBound.data.LISTINFO.total;
            //console.log("통화목록조회 결과 : 아웃바운드", callLogListInbound);

            _callLogList = Array.from(_callLogList).concat(makeData(callLogListInbound, CALLTYPE_INBOUND));

            userData.setShopMainTabCallError(false);
        }else{
            userData.setShopMainTabCallError(true);
            return;
        }
        console.log("통화내역결과 ==>", _callLogList);

        setTotalCnt(_totalCnt);
        if ( _page === 1) {
            setCallLogList(_callLogList);
        }else{
            setCallLogList(callLog => Array.from(callLog).concat(_callLogList));
        }
        
    } 

    const getCallLogListForNext = async () => {
        getCallLogList(currentPage + 1, callType);
    }

    const makeData = (listobj, _inOutBoundType) => {
        let _callLogList = [];

        if ( listobj !== null && listobj.length > 0 ){
            listobj.map((callLogItem, index) => {
                var _addressInfo = ( _inOutBoundType == CALLTYPE_INBOUND ) ? getAddressInfo(callLogItem.SRC) : getAddressInfo(callLogItem.DST);
                var momentObj = moment(callLogItem.TIME, 'YYYY-MM-DD HH:mm:ss');
                
                _callLogList.push({
                    DST: callLogItem.DST
                    ,DURATION: callLogItem.DURATION
                    ,KIND: callLogItem.KIND
                    ,NO: callLogItem.NO
                    ,SRC: callLogItem.SRC
                    ,STATUS: callLogItem.STATUS
                    ,DATE: callLogItem.TIME
                    ,TIME: momentObj.format('hh:mm')
                    ,ICO_CALL_CLASS : getIcoCallClass(callLogItem.STATUS, _inOutBoundType)
                    ,STATUS_NM : getIcoCallClassNm(callLogItem.STATUS, _inOutBoundType)
                    ,TIME_DIVISION : momentObj.format('A')
                    ,GROUPDIV : momentObj.format('YYYY-MM-DD')
                    ,INOUTBOUND : _inOutBoundType
                    ,NAME : _addressInfo.name //( _inOutBoundType == CALLTYPE_INBOUND ) ? getAddressInfo(callLogItem.SRC).name : getAddressInfo(callLogItem.DST).name
                    ,CENTIX_NO : _addressInfo.no//_addressInfo.bookType === CONSTS.ADDRESS_TYPE_NODATA ? '' : _addressInfo.no
                });
            });
        }

        return _callLogList;
    }

    const getIcoCallClass = (_status, _callType) => {
        if ( _status === "FAIL") {
            return "fail";      //통화실패
        }else if ( _status === "NO-ANS") {
            return "miss";      //무응답
        }else if ( _status === "OK" && _callType === CALLTYPE_INBOUND) {
            return "in";        //수신(성공)
        }else if ( _status === "OK" && _callType === CALLTYPE_OUTBOUND) {
            return "out";       //발신(성공)
        }else if ( _status === "CANCEL" ) {
            return "fail";      //응답전 발신자 종료
        }else if ( _status === "BUSY" ) {
            return "fail";      //통화중
        }else{
            return "fail";      //그외(실패)
        }
    }

    const STATUS_NM_IN = "수신";
    const STATUS_NM_OUT = "발신";
    const STATUS_NM_FAIL = "통화실패";
    const STATUS_NM_MISS = "무응답";

    const getIcoCallClassNm = (_status, _callType) => {
        if ( _status === "FAIL") {
            return STATUS_NM_FAIL;      //통화실패
        }else if ( _status === "NO-ANS") {
            return STATUS_NM_MISS;      //무응답
        }else if ( _status === "OK" && _callType === CALLTYPE_INBOUND) {
            return STATUS_NM_IN;        //수신(성공)
        }else if ( _status === "OK" && _callType === CALLTYPE_OUTBOUND) {
            return STATUS_NM_OUT;       //발신(성공)
        }else if ( _status === "CANCEL" ) {
            return STATUS_NM_FAIL;      //응답전 발신자 종료
        }else if ( _status === "BUSY" ) {
            return STATUS_NM_FAIL;      //통화중
        }else{
            return STATUS_NM_FAIL;      //그외(실패)
        }
    }


    const CallLogDetailClick = (e, _callLog) => {

        setHasMore(false);

        history.push(
            {
                pathname : `/shop/call/phonebook/detail`
                ,state : {
                    destnumber : _callLog.INOUTBOUND === CALLTYPE_INBOUND ? _callLog.SRC : _callLog.DST
                    ,no : _callLog.CENTIX_NO
                    ,item : _callLog 
                }
            }
        );
	}

    return (
        <>  
            <div id="call_content" className="call_content">
                <div  className="call_log_wrap">
                    {
                        Array.isArray(callLogDrawList) && callLogList !== [] && callLogDrawList.length > 0 ?
                        <InfiniteScroll
                            dataLength={listCnt}
                            next={getCallLogListForNext}
                            hasMore={hasMore}
                            scrollThreshold={'2px'}
                            scrollableTarget="contact_scroll_area">
                            { callLogDrawList.map((callGroupLog, index) => (
                                <>
                                <span className="devide_bar">{callGroupLog.title}</span>
                                {
                                    ( callGroupLog.items !== null && callGroupLog.items.length > 0 ) ? 
                                    callGroupLog.items.sort( (_src, _dest) => {
                                        var srcTime = moment(_src.DATE, 'YYYY-MM-DD HH:mm:ss');
                                        var destTime = moment(_dest.DATE, 'YYYY-MM-DD HH:mm:ss');
                                        
                                        if ( srcTime.isAfter(destTime) ) return -1;
                                        if ( srcTime.isSame(destTime)) return 0;
                                        if ( srcTime.isBefore(destTime)) return 1;

                                    }).map ( (callLog, seq) => (
                                    <>
                                        <div className="call_log">
                                            <div className="log_cell">
                                                <span className="log_name_wrap">
                                                    <span className={"ico_call " + callLog.ICO_CALL_CLASS + " blind"}>{callLog.STATUS_NM}</span>
                                                    <span className="log_name">{callLog.NAME}</span>
                                                </span>
                                                <span className="log_status">{callLog.STATUS_NM}</span>
                                                <span className="log_time">
                                                    <span className="time_devision">{callLog.TIME_DIVISION}</span>
                                                    <span className="i_time">{callLog.TIME}</span>
                                                </span>
                                                <button type="button" className="btn_info usetap blind" onClick={(e)=> CallLogDetailClick(e, callLog)}>상세정보</button>
                                            </div>
                                        </div>
                                    </>
                                    ))
                                    :
                                    <></>
                                }
                                </>
                            ))}
                        </InfiniteScroll>
                        :
                        <ContactDataNone />
                    }
                </div> 
            </div>
        </>
    );
});

export default CallLogMain;