import React, { useEffect, useState, useCallback } from "react";
import { Link, Route } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../utils/NativeBridge";
import SohoApi from '../../../../api/SohoApi';
import { tabUi } from '../../../../libs/tab'
import { layerPopup, toast } from "../../../../libs/common";
import * as CONSTS from "../../../../const";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { 
    Pagination, 
    Scrollbar, 
  } from "swiper";
import CentrexApi from "../../../../api/CentrexApi";
import * as LocalStore from '../../../../store/LocalStore';


SwiperCore.use([ Pagination, Scrollbar ]);

//전화 설정 페이지
const MsgSet = observer(({ history, match }) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();
  const [contsMsgList, setContsMsgList] = useState([]);

  let myUsageNum = 0;
  let myTotalNum = 0;
  const myCalcNum = (myUsageNum / myTotalNum);

  const [isChecked, setIsChecked] = useState(true);
  const [isServiceYN, setIsServiceYN] = useState(false);

  

  useEffect(() => {
    
    console.log("ShopMapping default useEffect..");
    console.log("storeId:" + userData.dataset.currstoreInfo.storeId);

    

    /*
    const ret = SohoApi.getFreeSmsDetail(userData.dataset.currstoreInfo.storeId);
    if ( ret.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS){
      myUsageNum = ret.data.useCnt;
      myTotalNum = ret.data.totalCnt;
    }
    
    // 사용 건수 증가하는 애니메이션
    $({ val : 0 }).animate({ val : myUsageNum }, {
      duration: 1000,
      step: function() {
        var num = Math.floor(this.val);
        $(".my_num").text(num);
      },
      complete: function() {
        var num = Math.floor(this.val);
        $('.my_num').text(num);
      }
    })
    */

    // Progress Bar 애니메이션
    // const smsUsage = document.getElementById('smsUsage');
    // var Gradient = '<defs><linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse"><stop offset="0%" stop-color="#75a5f6"/><stop offset="25%" stop-color="#8991f4"/><stop offset="75%" stop-color="#a397f4"/><stop offset="100%" stop-color="#c1b5f8"/></linearGradient></defs>';

    // var usageBar = new ProgressBar.SemiCircle(smsUsage, {
    //   strokeWidth: 9,
    //   color: 'url(#gradient)',
    //   trailWidth: 9,
    //   trailColor: '#e6e6e6',
    //   easing: 'easeInOut',
    //   duration: 1000
    // })

    // usageBar.svg.insertAdjacentHTML('afterbegin', Gradient);
    
    // if(myCalcNum > 1) {
    //   usageBar.animate(1);
    // } else {
    //   usageBar.animate(myCalcNum)
    // }

    if ($('.msg_card_rolling .swiper-wrapper .swiper-slide').length <= 1) {
			$('.i_pagination').addClass('i_disabled');	
		} else {
			$('.i_pagination').removeClass('i_disabled');
		}

    //매장별 알림메시지 정보 조회
    getConstsMsg();

    //매장별 서비스 가입 정보
    getMyService();

    //우리가게 알림메시지 정보 조회
    getStoreSetMessage();

  }, []);

  useEffect(() => {
    
   
    if ( !userData.dataset.shopMainTabMsgError ){

      //매장별 알림메시지 정보 조회
      getConstsMsg();

      //매장별 서비스 가입 정보
      getMyService();

      //우리가게 알림메시지 정보 조회
      getStoreSetMessage();
    }

  }, [userData.dataset.shopMainTabMsgError]);

  
  useEffect(() => {
    //화면갱신(팝업 닫힌경우)

    console.log('change popFlag =>', userData.dataset.popFlag);
    if ( userData.dataset.popFlag ) {
      
      //매장별 알림메시지 정보 조회
      getConstsMsg();

      //매장별 서비스 가입 정보
      getMyService();

      //우리가게 알림메시지 정보 조회
      getStoreSetMessage();

      userData.setPopFlag(false);

    }

  }, [userData.dataset.popFlag, userData.dataset.currstoreInfo]);

  useEffect(() => {

    //우리가게 알림메시지 정보 조회
    if ( userData.dataset.IsPopupCloseForMsgSet ){
      getStoreSetMessage();
      userData.setIsPopupCloseForMsgSet(false);
    }

  }, [userData.dataset.IsPopupCloseForMsgSet]);


  const reset = () => {
    getStoreSetMessage();
    getConstsMsg();
    getMyService();
  }

  useEffect(() => {
    //화면 진입 시 호출 방지 (값 받아 올 때만 호출)
    if(userData.dataset.callPageReset === true){
      reset();
      userData.setCallPageReset(false);
    }
    
  },[userData.dataset.callPageReset])


  const MsgonChange = async (e) => {
    if($('.checkbox').is(':checked') === true) {
      //setIsChecked(true)
      //userData.setIsMessageSetPopup('C');
      chgMmsInfo('apply')
      //layerPopup.show('#popup_msgset');
      NativeBridge.sendUserActionLog('detail', 'D076', '', "ON");
    } else if ($('.checkbox').is(':checked') === false) {
      //const delRet = await CentrexApi.removeMMSInfo();
      // if ( delRet.data.SVC_RT === '0000') {
      //   //toast('설정된 메시지가 삭제되었습니다.');
      //   //userData.setIsMessageSetPopup('C');
      //   //setIsChecked(false)
        
      //   //getStoreSetMessage();
      // }
      chgMmsInfo('pause')
      NativeBridge.sendUserActionLog('detail', 'D076', '', "OFF");
    }

    return false;
  }

  const getStoreSetMessage = async () => {

    if ( userInfo.dataset.myservice.callMsg ) {
		//서비스 가입자의 경우
		const result = await CentrexApi.getMMSInfo()
    				.catch((e) => {
      					userData.setShopMainTabMsgError(true);
      					return;
    				});

		if ( result?.data.SVC_RT === "0000"){
			setIsChecked(true);
			if(result.data.DATAS.SENDSTAT === "SEND"){
				$('.checkbox').prop('checked', true);
			}else{
				$('.checkbox').prop('checked', false);
			}
		
			//preview 화면 셋팅
			$("#popup_msgpreview_in .preview_ip").text(result.data.DATAS.SUBJECT);
			$("#popup_msgpreview_in .preview_textarea").html(result.data.DATAS.MESSAGE);
			if ( result.data.DATAS.IMGDATA ){
				$("#popup_msgpreview_in .preview_img").css('background-image', 'url(' + result.data.DATAS.IMGDATA + ')');
				$("#popup_msgpreview_in .preview_img").css('display', 'block');
			}else{
				$("#popup_msgpreview_in .preview_img").css('display', 'none');
			}

			if(result.data.DATAS.WEBURL) {
				$("#popup_msgpreview_in .preview_url").text(result.data.DATAS.WEBURL);
				$("#popup_msgpreview_in .preview_url").css('display', 'block');
			}else{
				$("#popup_msgpreview_in .preview_url").css('display', 'none');
			}
		
			userData.setShopMainTabMsgError(false);
		}else{

			if ( result?.data.SVC_RT !== '4001') {
				userData.setShopMainTabMsgError(true);
			}

			setIsChecked(false);
			$('.checkbox').prop('checked', false);
			//console.log("111확인..")
		}
	}
	//미서비스인경우
	
  }

  const getConstsMsg = async () => {
    const contsMsgResult = await SohoApi.getContMsg({
      storeId : userData.dataset.currstoreInfo.storeId
    });

    if ( contsMsgResult.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS){
      if ( contsMsgResult.data.list !== null && contsMsgResult.data.list.length > 0 ){
        setContsMsgList(contsMsgResult.data.list);
      }
      else{
        setContsMsgList([]);
      }
    }
  }

  const getMyService = async () => {
    const myserviceRet = await SohoApi.getMyServiceDetailList({
      ownerUserId : LocalStore.getOwnerId()
      ,storId : LocalStore.getStoreId()
    });

    if ( myserviceRet.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS){
      console.log("getMyServiceDetailList=>", myserviceRet.data);
      if ( myserviceRet.data !== null && myserviceRet.data.lpzList !== null && myserviceRet.data.lpzList.length > 0 ){
        let isServiceYN = myserviceRet.data.lpzList.filter( (targetItem) => {
          if ( targetItem.svcCd === '070_ADD_SVC_3' && targetItem.useYn === 'Y' ){
            return targetItem;
          }
        });
        console.log("확인...",isServiceYN)

        if ( isServiceYN.length > 0){
          setIsServiceYN(true);
          $('.checkbox').attr('checked', true);
        }else{
          setIsServiceYN(false);
          $('.checkbox').attr('checked', false);
        }
      }
      //
    }
  }

  const ClickDelCard = (e, _item) => {
    e.preventDefault();
    console.log("ClickDelCard=>", _item);

    layerPopup.showCommon(
        '#popup_onetitle_onemsgbold_twobutton2', 
        null, 
        '문자메시지 삭제', 
        '이 문자메시지를 정말 삭제하시겠어요?', 
        [
            function() {
                layerPopup.hide('#popup_onetitle_onemsgbold_twobutton2');
            },
            function() {
                layerPopup.hide('#popup_onetitle_onemsgbold_twobutton2');
                console.log('selected Item removeRecordFile =>');
                processDelCard(_item);
            }
        ]
    );
  }

  const processDelCard = async (_item) => {
    const contsMsgResult = await SohoApi.delContMsg({
      msgId : _item.msgId
    });

    if ( contsMsgResult.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS){
      toast('메시지가 삭제되었습니다.');
      getConstsMsg();
    }else{
      toast('메시지 삭제가 실패되었습니다.');
    }

    
  }

  /**
   * 미리보기 기능
   * 
   * @param {*} e 
   */
  const showPopupPreviewMessage = (e) => {

    getStoreSetMessage();
    NativeBridge.sendUserActionLog('detail', 'D073');
    layerPopup.show('#popup_msgpreview_in');
  }

  const showPopupSetMessage = (e, isMode) => {
    if (isMode === 'E') {
        NativeBridge.sendUserActionLog('detail', 'D074');
    }
    userData.setIsMessageSetPopup(isMode);
    layerPopup.show('#popup_msgset');
  }

  const ClickMoveToSendMsg = (e, conts = "", title) => {
    e.preventDefault();
    NativeBridge.sendUserActionLog('detail', 'D072', '', title);
    console.log("ClickMoveToSendMsg=>", conts);
    userData.setMessagePopupConts(conts);
    userData.setMessagePopupCellNum({});
    layerPopup.show('#popup_msgwrite');
  }
  const ClickMoveToSendMsg2 = (e, conts = "") => {
    e.preventDefault();
    NativeBridge.sendUserActionLog('detail', 'D075');
    console.log("ClickMoveToSendMsg=>", conts);
    //userData.setMessagePopupConts(conts);
    //userData.setMessagePopupCellNum({});
    layerPopup.show('#popup_msgwrite');
  }

  const ClickJoin = (e) => {
    NativeBridge.callPhone('1800-8000');
  }

  const chgMmsInfo = (data) => {

    const chgMmsInfo = CentrexApi.setChgMMSInfo({
      mode : data
  });

    console.log("메세지!!!",chgMmsInfo )
  }

  const NativeCall = (e,msg) => {
    if(e.target.value === "(부가)우리매장알림메시지"){
      userData.setMyPageCallPopupData("(부가)우리매장알림메시지")
    }
    let menuCode = '';
  
    switch (msg) {
      case '1800-8000':
        layerPopup.show('#popup_ringregi');
        menuCode = 'P014'
        break;
      default:
        break;
    }
  }
  console.log("@@@@확인.",isChecked); 
  console.log("@@@@확인2.",isServiceYN);

  return (
    <>
      <div className="fix_container">
          <div className="msg_main">
             
              <div className="sec_msg sec_msg_card msg">
                  <span className="title">자주 쓰는 문자 메시지</span>
                  <div className="msg_card_wrap">
                    <Swiper 
                      className="msg_card_rolling"
                      // spaceBetween={3}
                      slidesPerView="auto"
                      observeParents
                      observer
                      pagination={{ 
                          clickable: true, 
                          el: '.i_pagination' }}
                      loop={false}>
                        <SwiperSlide key="10001" className="msg_card templ1" onClick={(e)=> ClickMoveToSendMsg(e, CONSTS.MESSAGE_TEMPLATE_01, "이벤트 행사 안내")}>
                          <span className="ico_templ"></span>
                          <span className="card_title">이벤트 행사 안내</span>
                          <p className="card_cont">안녕하세요. 가게 명 입니다. <br/> 특별 이벤트로 행사 명 행사가 진행되오니, <br/> 많은 관심 부탁드립니다.</p>
                        </SwiperSlide>

                        <SwiperSlide key="10002"  className="msg_card templ2" onClick={(e)=> ClickMoveToSendMsg(e, CONSTS.MESSAGE_TEMPLATE_02, "예약 완료 안내")}>
                          <span className="ico_templ"></span>
                          <span className="card_title">예약 완료 안내</span>
                          <p className="card_cont">안녕하세요. <br/> 가게 명에 예약이 확정되었습니다.</p>
                        </SwiperSlide>

                        {
                          contsMsgList !== null && contsMsgList.length > 0 ?
                          contsMsgList.map((item, idx) => (
                            <>
                              <SwiperSlide key={`msgset_${idx}`} className="msg_card user_card">
                                <span className="ico_templ" onClick={(e)=> ClickMoveToSendMsg(e, item.msgCntn, item.msgTit)}></span> 
                                <span className="card_title" onClick={(e)=> ClickMoveToSendMsg(e, item.msgCntn, item.msgTit)}>{item.msgTit}</span>
                                <p className="card_cont">{item.msgCntn}</p>
                                <button type="button" className="btn_dark_close usetap blind" onClick={(e) => ClickDelCard(e, item)}>카드 삭제</button>
                              </SwiperSlide>
                            </>
                          ))
                          :
                          <></>
                        }
                    </Swiper>
                  </div>
              </div>
              <div className="sec_msg sec_msg_alarm">
                  <span className="title">우리매장알림메시지</span>
                  {
                        isChecked && userInfo.dataset.myservice.callMsg !== false
                        ?
                        <div className="check_wrap">     
                              <input className="checkbox" type="checkbox" name="ShopMsgSet" onChange={(e)=> MsgonChange(e.currentTarget)} />
                            <div className="check_bg">
                                <div className="check_bg_theme"></div>
                            </div>
                            <div className="check_dot"></div>
                        </div>
                        :
                        <></>
                  }

                  {
                    ( !isServiceYN || !isChecked ) ?
                    <>
                      <p>
                        전화를 건 고객에게 우리 매장 정보나 혜택을 자동으로 알릴 수 있어요. 앱에서 간편하게 설정해보세요!
                      </p>
                    </>
                    :
                    <>
                      <p>
                        통화가 끝나면 미리 설정한 메시지를 <br />고객에게 자동 전송할 수 있습니다.
                      </p>
                    </>
                  }
              </div>
              { 
                (isServiceYN && isChecked ) ?
                <div className="btn_box">
                  <div className="inner">
                      <button type="button" className="btn_half7 btn_point usetap" onClick={(e)=> showPopupPreviewMessage(e)}>메시지 미리보기</button>
                      {
                        isServiceYN === true
                        ? <button type="button" className="btn_half3 btn_line2 usetap" onClick={(e)=> showPopupSetMessage(e, 'E')}>수정</button>
                        : <></>
                      }
                      
                  </div>
                </div>
                :
                (isServiceYN && !isChecked ) ?
                <div className="btn_box">
                  <div className="inner">
                      <button type="button" className="btn_half7 btn_point usetap" disabled>메시지 미설정</button>
                      {
                        isServiceYN === true
                        ? <button type="button" className="btn_half3 btn_line2 usetap" onClick={(e)=> showPopupSetMessage(e, 'C')}>설정</button>
                        : <></>
                      }
                  </div>
                </div>
                :
                  <div className="btn_box">
                      <div className="inner">
                          <button type="button" className="btn_lg btn_point usetap" value={"(부가)우리매장알림메시지"} onClick={(e)=>NativeCall(e,'1800-8000')} >연동하기 (가입)</button>
                      </div>
                  </div>
              }
              <button type="button" id="btn_floating_msg" className="btn_floating_msg blind usetap down" onClick={(e)=> ClickMoveToSendMsg2(e)}>메시지 작성</button>
          </div>
      </div>

      <div className="modal fadeInUp" id="popup_msgpreview_in">
        <div className="dim"></div>
        <div className="modal_inner modal_up max_h full_h">
        <button type="button" className="pop_close usetap blind active" onClick={() => layerPopup.hide("#popup_msgpreview_in")}>닫기</button>
            <span className="modal_title">메시지 미리보기</span>
            <div className="modal_msg_preview">
              <p className="preview_ip">영업시간 안내</p>
              <span className="preview_img" style={{backgroundImage:"url('res/images/img_dummy.jpg')"}}></span>
              <p className="preview_textarea pre_line"></p>
              <p className="preview_ip preview_url"></p>
              <div className="btn_box bottom_box">
                  <button type="button" className="btn_lg btn_point usetap" onClick={(e) => layerPopup.hide("#popup_msgpreview_in")}>확인</button>
              </div>
            </div>
          </div>
      </div>

      

    </>
  );
});
export default MsgSet;