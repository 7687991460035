import React from 'react'
import store from '../../../store';

function MessageError() {

    const { userInfo, userData } = store;

    const reSetClick = () => {
        //userData.setCallPageReset(true);
        userData.setShopMainTabMsgError(false);
    }

    return (
        <div className="fix_container">
            <div className="temporay_error">
                <p>일시 오류, 새로고침으로<br />정보를 불러주세요!</p>
                <div className="ex_img">
                    <img src="/res/images/img-mystore-message-error@3x.png" alt="메시지 관리 화면" />
                    <button type="button" className="btn_refresh usetap blind" onClick={() =>reSetClick() }>새로고침</button>
                </div>
            </div>
        </div>
    )
}

export default MessageError;
