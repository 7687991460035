import React from 'react'


function ShopAnalyzeUnSub() {
    return (
        <div className="fix_container">
            <div className="unsubs_wrap unsubs_analysis">
                <div className="intro_sec">
                    <span className="title">상권분석</span>
                    <p className="desc1">빅데이터 기반의 상권분석 <br/>곧 출시합니다!</p>
                    <p className="desc2">내 가게 상권 뿐만 아니라 타 지역 상권 분석까지</p>
                    <span className="intro_ico"></span>
                </div>
                <div className="major_service">
                    <span className="title">주요 서비스</span>
                    <ul>
                        <li className="service1">
                            <span className="box_title">내 가게 분석</span>
                            <p>내 가게 상권의 <br/>매출, 배달, 주변 동종 업계 등 제공</p>
                            <span className="service_ico"></span>
                        </li>
                        <li className="service2">
                            <span className="box_title">다른 상권도 탐색 </span>
                            <p>내 가게 상권 뿐만 아니라, <br/>다른 지역 상권까지 분석</p>
                            <span className="service_ico"></span>
                        </li>
                        <li className="service3">
                            <span className="box_title">매출에서 배달까지</span>
                            <p>매출 분석 정보에서 배달 분석 정보까지 <br/>다양한 분석 정보 제공</p>
                            <span className="service_ico"></span>
                        </li>
                    </ul>
                </div>
                <div className="comming_cont">
                    <span className="comming_img blind">사장님을 위한 상권분석</span>
                    <p className="ment1">내년에 만나요!</p>
                    <p className="ment2">사장님들을 위해 열심히 준비 중입니다!</p>
                </div>
                <div className="info_payment">
                    <div className="free_box">
                        <span className="free_img blind">FREE</span>
                        <span className="title">무료</span>
                        <p>U+ 고객이라면 누구나<br/>무료로 이용하실 수 있습니다!</p> 
                    </div>
                </div>
            
                {
                    /*
                    <div className="btn_box fixed is_gnb">
                        <div className="inner">
                            <button type="button" className="btn_lg btn_point usetap">가입하기</button>
                        </div>
                    </div>
                    */
                }
                
            </div>
        </div>
    );
};

export default ShopAnalyzeUnSub
