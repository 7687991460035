import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import BizApi from "../../../../../api/BizApi";
import { textChgEdit, textChgBlock, textareaUi, layerPopup, toast } from "../../../../../libs/common";
import * as Common from "../../../../../common/common";



const PhoneBookDetailReg = observer(({ history, location }) => {

  const { userInfo, userData } = store;
  const userList = userInfo.getList();

  const destnumber = location.state.destnumber ? location.state.destnumber  : '';

  const [name, setAddressName] = useState("");        //이름
  const [phone, setAddressPhone] = useState("");      //PHONE
  const [phone2, setAddressPhone2] = useState("");    //PHONE2
  const [etc, setAddressEtc] = useState("");          //etc

	useEffect(() => {
    
    //마지막 선택한 메뉴 설정
    store.userData.setShopMainTabList('call');

    console.log("target=>", destnumber);
    if ( destnumber !== ''){
      console.log("set default target=>", destnumber);
      // document.getElementById('phone').innerText = ""
      setAddressPhone(destnumber);
    }

    if($('#wrap').hasClass('gnb_bottom')) 
    {
        $('#wrap').removeClass('gnb_bottom')
        $('#wrap').addClass('bg_gray');
    }   

    return () => {
      if($('#wrap').hasClass('bg_gray')) {
        $('#wrap').removeClass('bg_gray');
        $('#wrap').addClass('gnb_bottom');
      }
    };
    
	}, []);


  const goBackPage = () => {
      history.goBack();
  }

  const ClickSave = async (e) => {
    
    let _name = Common.defaultString($('#name').val(), "");
    let _phone = Common.defaultString($('#phone').val(),"");
    let _phone2 = Common.defaultString($('#phone2').val(),"");
    //let _etc = $('#etc').val();

    if ( Common.defaultString(_name, '') === '' || Common.defaultString(_phone) === '' ){
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "이름, 전화번호를 입력했는지</br> 확인해주세요.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);

      return false;
    }

    if ( !Common.checkIMEEnabledType(Common.defaultString(_name, '')) ){
      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "특수 문자 입력 제한", "특수문자는 입력할 수 없습니다.<br/>영문, 숫자, 한글만 입력해 주세요.", [
        function() {
            layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);

      return false;
    }

    if(_name.length > 10){
      layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'글자 수 초과', 
				'10자 이내로 입력해주세요.'
			);
			return;
    }

    if(_phone.length < 5 || _phone.length > 14){
      layerPopup.showCommon(
				'#popup_onetitlemsgbutton', 
				null, 
				'연락처 등록 실패', 
				'연락처는 5~14자리로 입력해주세요.'
			);
			return;
    }

    if(_phone2.length > 0 ) {
      if ( _phone2.length < 5 || _phone2.length > 14 ){
        layerPopup.showCommon(
          '#popup_onetitlemsgbutton', 
          null, 
          '연락처 등록 실패', 
          '연락처는 5~14자리로 입력해주세요.'
        );
        return;
      } 
    }

    var reqParam = {
        group_code : ''
        ,name : _name
        ,phone1 : _phone
        ,phone2 : _phone2
        ,etc : '' //_etc
    };

    console.log("reqParam=>", reqParam);

    const ret = await CentrexApi.AddPhonebookInfo(reqParam);

    if ( ret.data.SVC_RT === '0000'){
      //TODO : 전화번호 처리되고 나서 현행화 필요 ( 추가된 번호 미존재 )
      //Native 연동규격으로 데이터 현행화 요청(peer)
      BizApi.makeCentrixPhonebookList();

      console.log('등록등록결과=>', ret.data);
      NativeBridge.setAddressInfo({
        name : reqParam.name
        ,phone : reqParam.phone + ( (reqParam.phone2 !== '') ? ',' + reqParam.phone2 : '' )
        ,etc : reqParam.etc
        ,peer_no : ""
        ,groups : reqParam.group_code
      });
      toast('연락처를 추가했습니다.');
      
      //리턴으로 perr_no가 안와서 URL 상세로 변경하지 않고 목록으로 나감.
      if ( destnumber === ''){
        history.goBack();
      }else{
        history.go(-2);
      }
      
      /*
      history.push(
          {
              pathname : `/shop/call/phonebook/detail`
              ,state : {
                  destnumber : reqParam.phone
                  ,item : null
                  ,no : ""
              }
          }
      );
      */

    }else{

      //저장 가능한 최대 연락처 개수(500개)를 초과하였습니다. 새로 등록하시려면 불필요한 연락처를 정리해주세요.
      let _popupMsg = "";
      if ( ret.data.SVC_RT === '3001' &&  ret.data.SVC_MSG.indexOf('ERR MAX Count') > -1 ){
        _popupMsg = "저장 가능한 최대 연락처 개수(500개)를 초과하였습니다.\n새로 등록하시려면 불필요한 연락처를 정리해주세요.";
      }else{
        _popupMsg = "연락처 등록이 실패했습니다.\n다시 시도해주세요."; //"연락처 등록 실패하였습니다.\n연락처는 4~15자리로 입력해주세요.";
      }

      layerPopup.showCommon("#popup_onetitlemsgbutton", null, "연락처 등록 실패", _popupMsg, [
        function() {
          layerPopup.hide("#popup_onetitlemsgbutton");
        }
      ]);
    }
  }

  const ChangeInputText = (e) => {
    if ( e.target.id === 'name') setAddressName(e.target.value);
    else if ( e.target.id === 'phone') setAddressPhone(e.target.value);
    else if ( e.target.id === 'phone2') setAddressPhone2(e.target.value);
    else if ( e.target.id === 'etc') setAddressEtc(e.target.value);
  }

  useEffect(() => {
		//console.log("ㅁㅁ",groupNamed)
		if(name !== ""){
     // console.log("GGG")
		document.getElementById('input_name').innerText = ""
		}
		else{
      console.log("zxczxc..")
			document.getElementById('input_name').innerText = "이름을 입력해주세요."
      $("#input_name").show();
		}
	},[name])
  useEffect(() => {
		//console.log("ㅁㅁ",groupNamed)
		if(phone !== ""){
     // console.log("ㅁㅁ")
		document.getElementById('input_phone').innerText = ""
		}
		else{
      //console.log("ㅁㅁ2")
			document.getElementById('input_phone').innerText = "번호를 입력해주세요."
      $("#input_phone").show();
      
		}
	},[phone])

  useEffect(() => {
		//console.log("ㅁㅁ",groupNamed)
		if(phone2 !== ""){
		document.getElementById('input_phone2').innerText = ""
    //console.log("확인")
		}
		else{
			document.getElementById('input_phone2').innerText = "번호를 입력해주세요."
      //console.log("확인2")
      $("#input_phone2").show();
		}
	},[phone2])


  const textClearOnClick = (e) => {
  
    setAddressName("")
	}
  const textClearOnClick2 = (e) => {
  
    setAddressPhone("")
	}
  
  const textClearOnClick3 = (e) => {
    
    setAddressPhone2("")
	}

  const focusCheck = (e) => {
    let name = e.target.id;

    //setLabelHide("1") 

    if(name === "input_name") 
    {
      $("#input_name").hide();
      $("name").show();
    }
    if(name === "input_phone") 
    {
      $("#input_phone").hide();
      $("phone").show();
    }
    if(name === "input_phone2") 
    {
      $("#input_phone2").hide();
      $("phone2").show();
    }
   
    //$("#input_name").hide();
    //console.log("aa")
  }

  return (
    <>
        <div className="content store">
            <div className="page_title_wrap3">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={(e)=> goBackPage(e)}>뒤로가기</button>
                    <h2>연락처 등록</h2>
                    <button type="button" className="btn_complete" onClick={(e)=> ClickSave(e)}>완료</button>
                </div>
            </div>
            <div className="contact_info edit">
              <div className="name_wrap">
              <span className="title">이름</span>
                <div className="input_box style3">
                  <input type="text" id="name" value={name||''} onChange={(e) => ChangeInputText(e)}/>
                  <label htmlFor="name" id="input_name" onClick={(e)=> focusCheck(e)} className="label_placeholder">이름을 입력해주세요.</label>
                  <span className="ip_gradient"></span>
                  <span className="ip_clear hidden" name="name" onClick={()=>{textClearOnClick()}}>초기화</span>
                </div>
              </div>
              <div className="tel_wrap">
                <span className="title">전화번호</span>
                <div className="input_box style3">
                  <input type="tel" id="phone" value={phone||''} onChange={(e) => ChangeInputText(e)}/>
                  <label htmlFor="phone" id="input_phone" onClick={(e)=> focusCheck(e)} className="label_placeholder">번호를 입력해주세요.</label>
                  <span className="ip_gradient"></span>
                  <span className="ip_clear hidden" name="phone" onClick={()=>{textClearOnClick2()}}>초기화</span>
                </div>
                <div className="input_box style3">
                  <input type="tel" id="phone2" value={phone2||''} onChange={(e) => ChangeInputText(e)}/>
                  <label htmlFor="phone2" id="input_phone2" onClick={(e)=> focusCheck(e)} className="label_placeholder">번호를 입력해주세요.</label>
                  <span className="ip_gradient"></span>
                  <span className="ip_clear hidden" name ="phone2" onClick={()=>{textClearOnClick3()}}>초기화</span>
                </div>
              </div>
            </div>
        </div>
    </>
  );
});
export default PhoneBookDetailReg;