import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { floatUI } from '../../../../../libs/floating';
import { tabUi } from '../../../../../libs/tab';
import sampleData from './callmemoSample.json';
import { sortHanFirstObjectList, sortHanWordsList } from '../../../../../common/common';
//전화 설정 페이지
const CallMemo = observer(({ history, location }) => {

const { userInfo, userData } = store;
const userList = userInfo.getList();
//console.log(userList);

const [phoneMemoList, setPhoneMemoList] = useState([]);
const [sortType, setSortType] = useState("2");


useEffect(() => {

  tabUi.init3DepthTab(document.getElementById('tab_style4'));
  tabUi.init3DepthTab(document.getElementById('floating_tab2'));
  tabUi.init3DepthTab(document.getElementById('memo_cate'));

  //setSortType("2");
  GetCallMemo();

}, []);

useEffect(() => {
  console.log("change phoneMemoList=>", phoneMemoList);
}, [phoneMemoList]);


// useEffect(() => {
//   console.log("test0",  sortHanFirstObjectList(userData.dataset.pblistForCentrix.filter( (targetItem) => {
//     if ( targetItem.etc !== null && targetItem.etc !== undefined && targetItem.etc !== ''  && targetItem.etc !== "\r"  ){
//       return targetItem;
//     }
//   } ), 'etc', 'ASC'));
//   console.log("test00",  sortHanFirstObjectList(userData.dataset.pblistForCentrix.filter( (targetItem) => {
//     if ( targetItem.etc !== null && targetItem.etc !== undefined && targetItem.etc !== ''  && targetItem.etc !== "\r"  ){
//       return targetItem;
//     }
//   } ), 'etc', 'DESC'));
// },[userData.dataset.pblistForCentrix])

// useEffect(async () => {
//   console.log("change sortType=>", sortType);
//   console.log("change phoneMemoList=>", phoneMemoList);
//   if ( sortType === "1"){
//     await setPhoneMemoList(phoneMemoList=> sortHanFirstObjectList(phoneMemoList, 'etc', 'DESC'));
//       //  console.log("test1", sortHanFirstObjectList(phoneMemoList, 'etc', 'DESC'))
//       // console.log("test11", sortHanFirstObjectList(phoneMemoList, 'etc', 'ASC'))
//   } else {
//     await setPhoneMemoList(phoneMemoList=> sortHanFirstObjectList(phoneMemoList, 'etc', 'ASC'));
//       //  console.log("test2", sortHanFirstObjectList(phoneMemoList, 'etc', 'ASC'))
//       // console.log("test22", sortHanFirstObjectList(phoneMemoList, 'etc', 'DESC'))
//   }

// }, [sortType,phoneMemoList,userData.dataset.pblistForCentrix]);

useEffect(() => {

  GetCallMemo(sortType);

},[sortType])

//연락처 상세에서 통화메모 수정 후 리스트 재호출
useEffect(() => {

    GetCallMemo(sortType);

},[userData.dataset.addressSyncFlag])


const GetCallMemo = async (sortType) => {

      if(sortType === "1"){
      await setPhoneMemoList( phoneMemoList => 
        sortHanWordsList(userData.dataset.pblistForCentrix.filter( (targetItem) => {
          if ( targetItem.etc !== null && targetItem.etc !== undefined && targetItem.etc !== ''  && targetItem.etc !== "\r"  ){
            return targetItem;
          }
        } ), 'etc', 'DESC'));
      }else{
      await setPhoneMemoList( phoneMemoList => 
        sortHanWordsList(userData.dataset.pblistForCentrix.filter( (targetItem) => {
          if ( targetItem.etc !== null && targetItem.etc !== undefined && targetItem.etc !== ''  && targetItem.etc !== "\r"  ){
            return targetItem;
          }
        } ), 'etc', 'ASC'));
      }

}

const ContactDetailClick = (e, _item) => {
  history.push(
    {
        pathname : `/shop/call/phonebook/detail`
        ,state : {
            destnumber : _item.phone
            ,no : _item.no
            ,item : null
        }
    }
  );
}

const ClickSortType = (e, _sortType) => {
  setSortType(_sortType);
}

const hangulFirstCompare = (_a, _b) => {

  const a = addOrderPrefix(_a);
  const b = addOrderPrefix(_b);

  //ascending ( a< b ==> -1) | descending ( b < a ==> -1)
  if (a < b) return -1;
  else if (b < a ) return 1;
  else return 0;
}

const hangulFirstCompare2 = (_a, _b) => {

  let changeFlag = false;
  let firstvalue = -2;
  let len = 0;
  let tmpCompareValue = 0;
  let tmpA = '';
  let tmpB = '';
  do {

    tmpA = addOrderPrefix2(_a, len);
    tmpB = addOrderPrefix2(_b, len);
    
    //길이체크
    if ( _a.length < len && _a.length < _b.length ){
      tmpCompareValue = 1;
    }else if ( _b.length < len && _a.length < _b.length ){
      tmpCompareValue = -1;
    } else if ( _a.length === _b.length && _a.length === len ){
      tmpCompareValue = 0;
    }

    if ( len === 0 ) firstvalue = tmpCompareValue;

    if ( firstvalue !== tmpCompareValue ){
      firstvalue = tmpCompareValue;
      changeFlag = true;
      break;
    }

    //compare
    if ( tmpA > tmpB ){
      tmpCompareValue = 1;
    }else if ( tmpB > tmpA ) {
      tmpCompareValue = -1;
    }else { 
      tmpCompareValue = 0;
    }

    if ( firstvalue !== tmpCompareValue ){
      firstvalue = tmpCompareValue;
      changeFlag = true;
    }

    len++;

    if ( _a.length < len || _b.length < len ) {
      break;
    }

  } while ( !changeFlag );

  return tmpCompareValue;
}

/**
 * ㄱㄴㄷ : 한글
    #: 한글자음 > 영어 > 숫자 >기타(특수문자) 입니다.
  * @param {*} s 
  * @returns 
  */
const addOrderPrefix = (s) => {
  var code = s.toLowerCase().charCodeAt(0);
  var prefix;

  // 한글 AC00—D7AF
  if (0xac00 <= code && code <= 0xd7af) prefix = 1000000;
  // 한글 자모 3130—318F
  else if (0x3130 <= code && code <= 0x318f) prefix = 2000000;
  // 영어 소문자 0061-007A
  else if (0x61 <= code && code <= 0x7a) prefix = 3000000;
  // 숫자
  else if (48 <= code && code <= 57) prefix = 4000000;
  // 그외
  else prefix = 9000000;

  return prefix + code;

}


/**
 * ㄱㄴㄷ : 한글
    #: 한글자음 > 영어 > 숫자 >기타(특수문자) 입니다.
  * @param {*} s 
  * @returns 
  */
    const addOrderPrefix2 = (s, n) => {
      //var code = s.toLowerCase().charCodeAt(n);
      var code = s.charCodeAt(n);
      var prefix;
    
      // 한글 AC00—D7AF
      if (0xac00 <= code && code <= 0xd7af) prefix = 1000000;
      // 한글 자모 3130—318F
      else if (0x3130 <= code && code <= 0x318f) prefix = 2000000;
      // 영어 소문자 0061-007A
      else if (0x61 <= code && code <= 0x7a) prefix = 3000000;
      // 숫자
      else if (48 <= code && code <= 57) prefix = 4000000;
      // 그외
      else prefix = 9000000;
    
      return prefix + code;
    
    }

return (
    <>
        <div className="call_memo_wrap floating_bottom">
            <span className="total_num">총 {phoneMemoList.length}개</span>
            <div className="btn_cate_wrap" id="memo_cate">
                <button type="button" className="tab_item active" onClick={(e) => ClickSortType(e, "2")}>가나다순</button>
                <button type="button" className="tab_item" onClick={(e) => ClickSortType(e, "1")}>역순</button>
            </div>
            { phoneMemoList !== null && phoneMemoList.length > 0 ?
              <>
                <ul className="memo_list">
                { phoneMemoList.map((memoItem, idx) => (
                  <>
                    <li key={idx} onClick={(e)=> ContactDetailClick(e, memoItem)}>
                        <button type="button">
                            <span className="summary_memo">{memoItem.etc}</span>
                            <span className="memo_name">{memoItem.name}</span>
                            <span className="memo_num">{memoItem.phone}</span>
                        </button>
                    </li>
                  </>
                  ))
                }
                </ul>
              </>
                :
              <>
              <div className="data_none">
                <div className="none_wrap">
                  <span className="none_img"></span>
                    <p>통화메모가 없습니다.</p>
                </div>
              </div>
              </>
            }
            
        </div>
    </>
  );
});
export default CallMemo;