import React from 'react'
import * as NativeBridge from '../../../utils/NativeBridge'

function MessageUnSub() {
	const joinUser = () => {
        NativeBridge.sendUserActionLog('first', 'N');
		NativeBridge.sendUserActionLog('detail', 'D015');
        console.log("join... .open..");
        const ret = NativeBridge.externalCall(process.env.REACT_APP_EXT_USER_REG_URL);
        // window.SohoInterface.sendEvent("{\"type\":\"GO_PAGE\",\"param\":{\"type\":\"O\", \"url\":\"https://www.google.com\"}}");
        // const ret = NativeBridge.goPage(process.env.REACT_APP_EXT_USER_REG_URL, "O");
    }
	
    // const goPageOnClick = () => {
    //     NativeBridge.externalCall("https://sohoshop.uplus.co.kr/soho/v1/applyPp/shop/1000000060")
    // }

    return (
        <>
			<div className="fix_container">
				<div className="unsubs_wrap unsubs_msg">
					<div className="intro_sec">
						<span className="title">메시지</span>
						<p className="desc1">매장 번호로<br/>문자를 주고 받으세요!</p>
						<p className="desc2">인터넷 전화 고객이라면 누구나 사용 가능</p>
						<span className="intro_ico"></span>
					</div>
					<div className="major_service">
						<span className="title">주요 서비스</span>
						<ul>
							<li className="service1">
								<span className="box_title">간편한 홍보문구 발송</span>
								<p>매장 단골들에게 진행중인 이벤트나 <br/>신메뉴 출시 소식 안내</p>
								<span className="service_ico"></span>
							</li>
							<li className="service2">
								<span className="box_title">정확한 정보 안내</span>
								<p>고객에게 업무 시간, 위치 등 <br/>매장 주요 정보를 쉽고 정확하게 전달</p>
								<span className="service_ico"></span>
							</li>
							<li className="service3">
								<span className="box_title">매장 번호 활용</span>
								<p>개인번호 노출 없이 매장 번호로 <br/>앱에서 간편하게 수발신</p>
								<span className="service_ico"></span>
							</li>
						</ul>
					</div>
					<div className="user_review">
						<span className="title">먼저 사용 중인 사장님들 리뷰</span>
						<div className="review_box">
							<div className="review_wrap right">
								<div className="msg bg1">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">호랑이</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
									</div>
									<p>예약 관련 문자를 보낼 때, 템플릿을 저장해두고 바로 보낼 수 있어서 좋아요.</p>
								</div>
							</div>
							<div className="review_wrap left">
								<div className="msg bg2">
									<span className="i_user">
										<span className="user_img"></span>
										<span className="user_name">김사장</span>
									</span>
									<div className="star_wrap">
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star on"></span>
										<span className="ico_star"></span>
										<span className="ico_star"></span>
									</div>
									<p>매장 번호로 앱에서 고객과 문자를 쉽게 주고 받을 수 있어서 편리해요!</p>
								</div>
							</div>
						</div>
					</div>
					<div className="info_payment">
						<span className="title">요금 안내</span>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">유선전화</span>
								<p>유선전화 이용시 요금</p>
							</div>
							<div className="charge_box">
								{/* <!--<span className="i_discount">25%</span>--> */}
								<span className="i_charge"><span className="ft_roboto">11</span>원</span>
								<span className="i_unit">건당</span>
							</div>
						</div>
						<div className="payment_box">
							<div className="title_box">
								<span className="pay_title">휴대폰</span>
								<p>휴대폰 이용시 요금</p>
							</div>
							<div className="charge_box">
								{/* <!--<span className="i_discount">25%</span>--> */}
								<span className="i_charge"><span className="ft_roboto">16.5</span>원</span>
								<span className="i_unit">건당</span>
							</div>
						</div>
					</div>
					<div className="btn_box fixed is_gnb">
						<div className="inner">
							<button type="button" className="btn_lg btn_point usetap" onClick={joinUser}>가입하기</button>
						</div>
					</div>
				</div>
			</div>          
    	</>
    )
}

export default MessageUnSub