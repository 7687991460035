import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../../store';
import $ from 'jquery';
import * as NativeBridge from '../../../../../utils/NativeBridge';
import * as SessionStore from '../../../../../store/SessionStore';
import SohoApi from "../../../../../api/SohoApi"
import * as LocalStore from '../../../../../store/LocalStore'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { tabUi } from '../../../../../libs/tab';
import CctvApi from '../../../../../api/CctvApi';

const Notice = observer(({ history }) => {

    var cctvKey = LocalStore.getCctvToken();
    const { userInfo, userData } = store;

    const [camRuleEvent, setCamRuleEvent] = useState([])
    const [servicechange, setServicechange] = useState(null)
    const [servicedate, setServicedate] = useState([])
    const [dispatchService, setDispatchService] = useState(null)
    const [iotType, setIotType] = useState(null)

    useEffect(() => {
		tabUi.init3DepthTab(document.getElementById('tab_style4'));
        serviceChg()
        CamEventRuleList()

        setDispatchService(userInfo.dataset.myservice.cctvReq);
        setIotType(userInfo.dataset.myservice.staffMng);

        if($('#wrap').hasClass('gnb_bottom') || !($('#wrap').hasClass('gnb_bottom'))) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').removeClass('bg_gray')
            $('#wrap').addClass('fixed_bottom');
        }
                  
        return () => {
            if($('#wrap').hasClass('fixed_bottom')) {
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').addClass('bg_gray')
                $('#wrap').addClass('gnb_bottom');
            }
        };
    }, []);

    const serviceChg = async() => {
        const serviceChgList = await SohoApi.setServiceChgListInfo()
        setServicechange(serviceChgList.data.list)
        setServicedate(serviceChgList.data.list.map((item) => item.date))
        console.log('서비스 변동 알림', serviceChgList.data.list)
    }

    const CamEventRuleList = async() => {
        if((cctvKey !== null && cctvKey !== "")) {
            const event_rule_list = await CctvApi.getCctvEventRuleList(moment().format('yyyyMMDD'), moment().format('yyyyMMDD'));
            console.log('감지 내역', event_rule_list.data.list);
            if(event_rule_list.data.list !== undefined && event_rule_list.data.list !== null && event_rule_list.data.list.length > 0 ) {
                setCamRuleEvent(event_rule_list.data.list);
            }
        }
    }

    const Dates = () => {
        const serviceDate = new Set(servicedate);
        const serviceDateList = [...serviceDate].sort(function(a, b) {
            if(a < b) return 1;
            if(a > b) return -1;
            if(a === b) return 0;
        })
        console.log(serviceDateList)
        const nowDate = moment().format("YYYYMMDD");
        const nownoYearDate = moment().format('MM월 DD일 오늘')

        return { serviceDateList: serviceDateList ,nowDate: nowDate, nownoYearDate: nownoYearDate }
    }

    const goBackPage = () => {
        history.goBack();
    }

    const ShopClick = () => {
        let authKey = SessionStore.getCctvToken();
        NativeBridge.externalCall(`Soho://main.launch?authKey=${authKey}&dispatchService=${dispatchService ? '1' : '0'}&iotType=${iotType ? '1' : '0'}&type=2`, "3")
    }

    const AlarmNoticeClick = (e, subMenu=null) => {
        let detailCode = 'D042';
        if (subMenu === 'notice') {
            detailCode = 'D041';
        }
        NativeBridge.sendUserActionLog('detail', detailCode);

        userData.setNoticeTabList(subMenu)
        history.replace(`/my/mysetting/cs/${userData.dataset.noticeTab}`);
    }

    return (
        <>
            <div className="content home">
                <div className="page_title_wrap nofixed">
                    <div className="inner">
                        <button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
                        <h2>알림/공지사항</h2>
                    </div>
                </div>
                <div className="change_history">
                    <div className="tab_btn_wrap">
                        <div id="tab_style4" className="tab_style4 tab2">
                            <button className="tab_item active" onClick={(e)=> AlarmNoticeClick(e.target,'eventnotice')}>알림</button>
                            <button className="tab_item" onClick={(e)=> AlarmNoticeClick(e.target,'notice')}>공지사항</button>
                        </div>
                    </div>
                    <div className="inner">
                        <span className="chg_date_bar">{Dates().nownoYearDate}</span>
                        {/* 오늘 온 알림 */}
                        { Array.isArray(camRuleEvent) && camRuleEvent.length > 0 ?
                            <>
                                <div className='his_wrap'>
                                    <div className="his_box">
                                        <div className="his_cont">
                                                <span className="title">U+지능형 CCTV 감지 알림</span>
                                                <p>
                                                    CCTV에 새로운 감지내역이 발생했습니다.<br />
                                                    침입, 카메라 훼손, 화재 등이 의심되오니<br />
                                                    즉시, 확인해보세요.
                                                </p>
                                        </div>
                                        <button type="button" className="btn_line1 usetap" onClick={ShopClick}>영상 확인하기</button>
                                    </div>
                                </div>
                            </>
                        : Array.isArray(servicechange) ?
                            servicechange.map((nowdateitem, index) => (
                                ( nowdateitem.date === Dates().nowDate &&
                                    <div className='his_wrap'>
                                        <div className="his_box">
                                            <span className="i_time">{nowdateitem.time}</span>
                                            <div className="his_cont">
                                                <span className="title">{nowdateitem.svcStus === "01" ? "신규가입" : "가입 해지"}</span>
                                                <p>{`'${nowdateitem.svcNm}'`}{nowdateitem.svcStus === "01" ? "서비스에 가입하셨습니다. 가게에 등록 후 이용해 보세요." 
                                                                                                    : "서비스를 해지하여 U+우리가게 서비스 앱에서 이용할 수 없습니다."}</p>
                                            </div>
                                            { nowdateitem.svcStus === "01" &&
                                                <Link to="/account/store/modify">
                                                    <button type="button" className="btn_line1 usetap">등록하기</button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                )
                            ))
                        :
                            <></>
                        }

                        { Dates().serviceDateList.map((prevdateitem, index) => (
                            prevdateitem !== Dates().nowDate &&
                            <>
						        <span className="chg_date_bar">{moment(`${prevdateitem}`).format('MM월 DD일')}</span>
                                { Array.isArray(servicechange) && 
                                    servicechange.map((serviceitem, index) => (
                                        ( prevdateitem === serviceitem.date &&
                                            <div className='his_wrap'>
                                                <div className="his_box">
                                                    <span className="i_time">{serviceitem.time}</span>
                                                    <div className="his_cont">
                                                        <span className="title">{serviceitem.svcStus === "01" ? "신규 가입" : "가입 해지"}</span>
                                                        <p>{`'${serviceitem.svcNm}'`}{serviceitem.svcStus === "01" ? "서비스에 가입하셨습니다. 가게에 등록 후 이용해 보세요." 
                                                                                                            : "서비스를 해지하여 U+우리가게 서비스 앱에서 이용할 수 없습니다."}</p>
                                                    </div>
                                                    { serviceitem.svcStus === "01" &&
                                                        <Link to="/account/store/modify">
                                                            <button type="button" className="btn_line1 usetap">등록하기</button>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    ))
                                }
                            </>
                          ))
                        }
					</div>
				</div>
            </div>
        </>
    );
});

export default Notice;