import $ from 'jquery';
import { throwError } from 'rxjs';
import * as NativeBridge from '../utils/NativeBridge';

export const webUI = (function() {
    var timeout;
    return {
        "getChildIndex": function(child) {
            var parent = child.parentNode;
            var children = parent.children;
            var i = children.length - 1;
            for (; i >= 0; i--) {
                if (child == children[i]) {
                    break;
                }
            }
            return i;
        },
        "debounce": function(func, wait, immediate) {
            var context = this,
                args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            }, wait);
            if (immediate && !timeout) func.apply(context, args);
        },
		"easeInOutQuad" : function (t, b, c, d) {
			t /= d/2;
			if (t < 1) return c/2*t*t + b;
			t--;
			return -c/2 * (t*(t-2) - 1) + b;
		},
		"animatedScrollTo": function(element, to, duration) {
			var start = element.scrollLeft;
			var change = to - start;
			var currentTime = 0;
			var increment = 20;
			var animateScroll = function(callback) {
				currentTime += increment;
				var val = Math.floor(webUI.easeInOutQuad(currentTime, start, change, duration));
				element.scrollLeft = val;
				if (currentTime < duration) {
					window.requestAnimationFrame(animateScroll);
				} else {
					if (callback && typeof(callback) === 'function') {
						callback();
					}
				}
			}
			animateScroll();
		},
        "addListener": function(node, event, listener, useCapture) {
            if (!node || !event || !listener) return;

            if (node instanceof Node) {
                node.addEventListener(event, listener, (typeof useCapture === "undefined") ? false : useCapture);
            } else if (node instanceof NodeList) {
                if (node.length > 0) {
                    for (var i = 0, l = node.length; i < l; i++) {
                        node[i].addEventListener(event, listener, (typeof useCapture === "undefined") ? false : useCapture);
                    }
                }
            }
        },
        "addDelegate": function(node, event, selector, listener, useCapture) {
            if (!node || !event || !listener) return;

            webUI.addListener(node, event, function(e) {
                var target = e.target;
                if (typeof selector === "string") {
                    while (target.matches(selector) == false && target !== this) {
                        target = target.parentElement;
                    }
                    if (target.matches(selector)) {
                        listener.call(target, e);
                    }
                } else {
                    selector.call(this, e);
                }
            }, useCapture);
        }
    }
})();
/* 팝업 노출시 스크롤 고정 스크립트 */
export const layerPopup = (function() {
	var html = undefined;
//	var layer;
	var sctop;
    return {
		"show" : function(elem, container) {
			const currLayer = document.querySelector(elem);
            if(currLayer === null) return;
			if (container == null){
				html = (document.body.scrollTop == '0') ? document.documentElement : document.body;
			} else {
				html = container;
			}
			sctop = html.scrollTop;
			html.style.top = (0 - sctop) + "px";
			html.classList.add('noscroll');
			currLayer.style.display = 'block';
			setTimeout(function() {
                try {
                    currLayer.classList.add('visible');
                } catch(e) {
                    console.log(e);
                }				
			}, 50);
		},
		"hide" : function(elem, callback=null) {
			const html = (document.body.scrollTop == '0') ? document.documentElement : document.body;
            let currLayer = undefined;
			if (elem !== undefined){
                currLayer = document.querySelector(elem);
			}
			html.classList.remove("noscroll");
			html.scrollTop = sctop;
			html.style.top = "";

            try {
                if(currLayer !== undefined) {
                    if(currLayer) {
                        currLayer.classList.remove('visible');
                    }
                }

                setTimeout(function() {
                    try {
                        if(currLayer !== undefined) {
                            if(currLayer) {
                                currLayer.style.display = 'none';
                            }
                        }
                        if(typeof(callback) === "function") {
                            callback();
                        }
                    } catch(e) {
                        console.log(e);
                    }
                }, 500);
            } catch(e) {
                console.log(e);
            }
		},
        "showCommon" : function(elem, container=null, title='', content='', btnFuncList=[]) {
			const currLayer = document.querySelector(elem);
            if(currLayer === null) return;

            try {
                if(title !== '') currLayer.getElementsByClassName("modal_hd")[0].innerText = title;

                //-- 내용이 색상 분리가 되어 있는 팝업 (내용에 | 가 포함)
                if(currLayer.getElementsByClassName("modal_bd")[0].firstChild.firstChild.nodeName.toLowerCase() === "span") {
                    
                    // layer.id == "popup_onetitle_onemsgbold_twobutton") {
                    if(content.indexOf("|") >= 0) {
                        const contentList = content.split("|");

                        const textSpanNode = document.createElement("span");
                        textSpanNode.className = "pt_color ft_bold";
                        textSpanNode.innerText = contentList[0];

                        currLayer.getElementsByClassName("modal_bd")[0].firstChild.innerHTML = "";
                        currLayer.getElementsByClassName("modal_bd")[0].firstChild.appendChild(textSpanNode);
                        currLayer.getElementsByClassName("modal_bd")[0].firstChild.innerHTML += "&nbsp;" + contentList[1];

                    }else{
                        if(content !== '') currLayer.getElementsByClassName("modal_bd")[0].firstChild.innerHTML = content;
                    }

                //-- 기본 내용 팝업
                } else {
                    if(content !== '') currLayer.getElementsByClassName("modal_bd")[0].firstChild.innerHTML = content;
                }

                if(currLayer.getElementsByClassName("modal_ft").length) {
                    const btnObjs = currLayer.getElementsByClassName("modal_ft")[0].childNodes;
                    for(let i = 0; i < btnObjs.length; i++) {
                        (function() {
                            const btnIdx = i;
                            const currBtnObj = btnObjs[btnIdx];
                            let inputObj = null;
                            if(currLayer.getElementsByClassName("input_box").length) {
                                inputObj = currLayer.getElementsByClassName("input_box")[0].firstChild;
                                if(inputObj.getAttribute("type") !== "text") {
                                    inputObj = null;
                                }
                            }
                            if(currBtnObj) {
                                currBtnObj.onclick = function() {
                                    if(btnFuncList.length >= (i+1)) {
                                        if(inputObj === null) {
                                            if(typeof(btnFuncList[btnIdx])==="function") {
                                                btnFuncList[btnIdx]();
                                            }
                                        } else {
                                            if(typeof(btnFuncList[btnIdx])==="function") {
                                                btnFuncList[btnIdx](inputObj.value);
                                            }
                                        }
                                    } else {
                                        layerPopup.hide(elem);
                                    }
                                }
                            }
                        })();
                    }
                }


            } catch(e) {
                console.log(e);
            }

            const html = (document.body.scrollTop == '0') ? document.documentElement : document.body;
			sctop = html.scrollTop;
			html.style.top = (0 - sctop) + "px";
			html.classList.add('noscroll');
			currLayer.style.display = 'block';
			setTimeout(function() {
                try {
                    currLayer.classList.add('visible');
                } catch(e) {
                    console.log(e);
                }				
			}, 50);
		},
        "showSohoAuthError" : function(title='', content='') {
			const currLayer = document.querySelector("#popup_soho_auth_error_logout");
            if(currLayer === null) return;
            try {
                if(title !== '') currLayer.getElementsByClassName("modal_hd")[0].innerText = title;
                if(content !== '') currLayer.getElementsByClassName("modal_bd")[0].firstChild.innerHTML = content;
            } catch(e) {
                console.log(e);
            }
    		const html = (document.body.scrollTop == '0') ? document.documentElement : document.body;
			sctop = html.scrollTop;
			html.style.top = (0 - sctop) + "px";
			html.classList.add('noscroll');
			currLayer.style.display = 'block';
			setTimeout(function() {
                try {
                    currLayer.classList.add('visible');
                } catch(e) {
                    console.log(e);
                }				
			}, 50);
		},        
    };
}());

export const bnPopup = (function() {
	var html = undefined;
	var layer;
    return {
		"show" : function(elem, container) {
			layer = document.querySelector(elem);
			layer.style.display = 'block';
			setTimeout(function() {
				layer.classList.add('visible');
			}, 50);
		},
		"hide" : function(elem) {
			if (elem !== undefined){
				layer = document.querySelector(elem);
			}
			layer.classList.remove('visible');
			setTimeout(function() {
				layer.style.display = 'none';
			}, 500);
		}
    };
}());

/* Toast Popup */
let removeToast;
const toastSec = 3;
export function toast(string) {
    const toast = document.getElementById("toast");

    if(toast.classList.contains("reveal")) {
        clearTimeout(removeToast);
        removeToast = setTimeout(function () {
            document.getElementById("toast").classList.remove("reveal")
        }, toastSec*1000);
    } else {
        removeToast = setTimeout(function () {
            document.getElementById("toast").classList.remove("reveal")
        }, toastSec*1000);
    }
    toast.classList.add("reveal");
    toast.innerText = string
}

/* Bubble Popup */
let removeBubble;
export function bubble(string) {
    const bubble = document.getElementById("bubble");    
    
     if(bubble.classList.contains("reveal")){
            clearTimeout(removeBubble)
            removeBubble = setTimeout(() => {
                document.getElementById("bubble").classList.remove("reveal");
            }, 3000);
            // (clearTimeout(removeBubble), removeBubble = setTimeout(function () {
            //      document.getElementById("bubble").classList.remove("reveal");
            // }, 3000))
    }else{
            removeBubble = setTimeout(() => {
                 document.getElementById("bubble").classList.remove("reveal");
            }, 3000)
    }
    console.log("bubble.children",bubble.children);
    bubble.classList.add("reveal")
    bubble.innerHTML =`<p>${string}</p>`;
}

export function textareaUi () {
    return {
		"textareaAuto": function(obj) {
			let dummy = document.getElementById('dummy2');
			let timer;
			obj.addEventListener('focus', function(){			
				timer = setInterval(function(){
					dummy.value = obj.value;
					obj.style.height = (dummy.scrollHeight) + 'px';
				}, 100);
			});
			obj.addEventListener('blur', function(){
				clearInterval(timer);
			});
        }
    }
};

document.addEventListener("DOMContentLoaded", function() {
    var hasTouchEvent = "ontouchstart" in document.documentElement,
        START_EV = hasTouchEvent ? "touchstart" : "mousedown",
        END_EV = hasTouchEvent ? "touchend" : "mouseup";
    var dragPoint = false;
    webUI.addDelegate(document.body, START_EV, ".usetap", function(e) {
        dragPoint = true;
        this.classList.add("active");
    });
    webUI.addDelegate(document.body, END_EV, ".usetap", function(e) {
        dragPoint = false;
        this.classList.remove("active");
    });
    webUI.addDelegate(document.body, "touchcancel", ".usetap", function(e) {
        dragPoint = false;
        this.classList.remove("active");
    });
    webUI.addDelegate(document.body, "mousemove", ".usetap", function(e) {
        if (dragPoint == true) {
            e.target.onmouseout = function() {
                this.classList.remove("active");
            }
        }
    });
});

export function fn_onlyNum(event, frm, value) {
    if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        (event.keyCode >= 37 && event.keyCode <= 40) ||
        event.keyCode == 9 ||
        event.keyCode == 8 ||
        event.keyCode == 46
        ) {
            event.returnValue = true;
        }
    else {
        event.returnValue = false;
    }
}

export function fn_NextFocus(event, obj_id, targetobj_id, obj_maxlength) {
    if (event.keyCode != 8 && event.keyCode != 9 && event.keyCode != 13 && event.keyCode != 46) {
        var txtStart = document.getElementById(obj_id);
        var txtEnd = document.getElementById(targetobj_id);
        if (txtStart.value.length >= obj_maxlength) {
            $(txtEnd).next('span').hide();
            txtEnd.value = '';
            txtEnd.focus();
        }
    }
}

export function fn_PreviousFouse(event, obj_id, targetobj_id) {
    var obj = document.getElementById(obj_id);
    var target_obj = document.getElementById(targetobj_id);
    if (event.keyCode == 8 && obj.value.length == 0) {
        $(target_obj).next('span').hide();
        target_obj.focus();
        target_obj.value = target_obj.value;
    }
}

export function tabHomeContents(evt, tabName) {

    if ( tabName === 'cctvFull') {
        //추가 - 12.08
        NativeBridge.sendUserActionLog('menu', 'H033');
    }else{
        //추가 - 12.08
        NativeBridge.sendUserActionLog('menu', 'H034');
    }

    tabContents(evt, tabName);
}

export function tabContents(evt, tabName) {

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tab_content");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("btn_tap");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" on", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " on";
}



export function textChgEdit(target) {
      if (target.innerText === "편집") {
      target.innerText = "삭제";
    } else {
      target.innerText = "편집";
    }
};

export function textChgBlock(target) {
      if (target.innerText === "차단") {
        target.innerText = "해제";
    } else {
        target.innerText = "차단";
    }
};



// Input focusout .ip_clear hide(default)
export function focusOutIpClear(thisVal) {
    setTimeout(function() {
        $(thisVal).siblings('.ip_clear').hide();
        $(thisVal).siblings('.ip_recipient').show();
    }, 0);
}

/*
********************  10/09 퍼블
*/
 // 통화연결음 textarea, input 입력 시 fixed 버튼 감추기
//  $('.ringback_greeting textarea, input').on("focusin", function(){
//     $('.btn_box.fixed').hide();
//     $('#wrap').removeClass('fixed_bottom');
// });
// $('.ringback_greeting textarea, input').on("focusout", function(){
//     $('.btn_box.fixed').show();
//     $('#wrap').addClass('fixed_bottom');
// });
// $('.ringback_greeting textarea, input').on("blur", function(){
//     $('.btn_box.fixed').show();
//     $('#wrap').addClass('fixed_bottom');
// });

// 연락처 검색 시 탭(연락처,그룹,통화메모) 없애기
// $('.content.store .store_call .call_srh_box input').on("focusin", function(){
//     $('.floating_tab_wrap').hide();
// });
// $('.content.store .store_call .call_srh_box input').on("focusout", function(){
//     $('.floating_tab_wrap').show();
// });
// $('.content.store .store_call .call_srh_box input').on("blur", function(){
//     $('.floating_tab_wrap').show();


/* */


// ---- Main.js 에 useEffect 에 반영함
// $(function(){

//     // Input focusin .ip_clear show
//     if($('.input_box').length > 0){
	
//         $('div').on('focusin', '.input_box input', function () {
//             if ($(this).val().length > 0) { // check if value changed        
//                 $(this).siblings('.ip_clear').show();
//                 $(this).siblings('.ip_recipient').hide();
//                 $(this).addClass('ip_sp');
//             } else {
//                 $(this).removeClass('ip_sp');
//             }
//         });

//         // Input focusout .ip_clear hide
//         $('div').on('focusout', '.input_box input', function () {
//             $(this).removeClass('ip_sp');
//             var thisVal = this;
//             focusOutIpClear(thisVal); // setTimeout hide
//         });

//         // Input keyup .ip_clear show
//         $('div').on('keyup', '.input_box input', function () {        
//             if ($(this).val().length > 0) {       
//                 $(this).siblings('.ip_clear').show();
//                 $(this).siblings('.ip_recipient').hide();
//                 $(this).addClass('ip_sp');
//             } else  {
//                 $(this).siblings('.ip_clear').hide();
//                 $(this).siblings('.ip_recipient').show();
//                 $(this).removeClass('ip_sp');
//             }
//         });

//         // Input reset
//         $('.ip_clear').on("click", function(){
//             $(this).siblings('input').val('')
//             .trigger('propertychange').focus();
//         });
        
//     };
	
// 	// Input_box.style3
// 	if($('.input_box.style3').length > 0){	
		
//         $('.label_placeholder').on("click", function(){ // input gradient
//             $(this).siblings('input').focus();
//         });
        
// 		$(".input_box.style3 input").each(function(){
// 			if( $(this).val().length === 0 ){
// 				$(this).siblings('.label_placeholder').show(); // label - placeholder
// 			}else{
// 				$(this).siblings('.label_placeholder').hide();
// 			}
// 		})

// 		$('div').on('focusin', '.input_box.style3 input', function () {
//             $(this).siblings('.label_placeholder').hide();
//             $(this).siblings('.ip_gradient').hide();
//         });
		
// 		$('div').on('keyup', '.input_box.style3 input', function () {
//             $(this).siblings('.label_placeholder').hide();
//         });
		
// 		$('div').on('focusout', '.input_box.style3 input', function () {
//             if ($(this).val().length > 0) {       
//                 $(this).siblings('.label_placeholder').hide();
//                 $(this).siblings('.ip_gradient').show();
//             } else {
// 				$(this).siblings('.label_placeholder').show();
//             }
//         });
        
//          $('.ip_gradient').on("click", function(){ // input gradient
//             $(this).siblings('input').focus();
//         });
// 	}
	
//     // Input_box.style3
// 	if($('.textarea_greet').length > 0){
        
//         $('.textarea_label').on("click", function(){ // input gradient
//             $(this).siblings('textarea').focus();
//         });
        
// 		$(".textarea_greet textarea").each(function(){
// 			if( $(this).val().length === 0 ){
// 				$(this).siblings('.textarea_label').show(); // label - placeholder
// 			}else{
// 				$(this).siblings('.textarea_label').hide();
// 			}
// 		})

// 		$('div').on('focusin', '.textarea_greet textarea', function () {
//             $(this).siblings('.textarea_label').hide();
//         });
		
// 		$('div').on('focusout', '.textarea_greet textarea', function () {
//             if ($(this).val().length > 0) {       
//                 $(this).siblings('.textarea_label').hide();
//             } else {
// 				$(this).siblings('.textarea_label').show();
//             }
//         });
// 	}
    
// });