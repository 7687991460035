import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../../../store';
import $ from 'jquery';
import * as NativeBridge from '../../../../utils/NativeBridge';
import * as LocalStore from '../../../../store/LocalStore'
import { fn_onlyNum, fn_NextFocus, fn_PreviousFouse, layerPopup, toast} from '../../../../libs/common';
import SohoApi from '../../../../api/SohoApi';
import { clearLocalData } from '../../../../components/login/DefaultSet';

const MyCS = observer(({ history }) => {

    const { userInfo, userData } = store;
    const userList = userInfo.getList();
    console.log(userList);

    const [userPass, setUserPass] = useState("");
    const [incorrectCnt, setIncorrectCnt] = useState(0);
    // const [check, setCheck] = useState("");


    useEffect(() => {
        setIncorrectCnt(0);
        clearInputPasswd()

        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom')
            $('#wrap').addClass('bg_gray');
        }
        $('#wrap').addClass('fixed_bottom')
        

        clearInputPasswd();
        let pinFinish = $('.pin_finish');

        pinFinish.hide();
    
        $('.pinCode').on("keyup blur", function() {  
            let rgEx = /^[0-9]*$/g;
            let chkEx = rgEx.exec($(this).val());
            if(!chkEx){
                $(this).val( $(this).val().replace( /[^0-9]/gi, '' ));
            } 
            if ($(this).val()!="") {
                $(this).next(pinFinish).show();
            } else {             
                $(this).next(pinFinish).hide();
            }
            checkComplete();
        });
        
        $('.pin_finish, .pin_box').on("click", function(event) {
            $('.pin_finish').hide();
            $('.pin_box input').val('');
            $('#pinCode1').trigger("focus");
        });


          return () => {
            // subscription.unsubscribe();
            $('.pinCode').off("keyup blur");

            if($('#wrap').hasClass('fixed_bottom')) {
              $('#wrap').removeClass('bg_gray');
              $('#wrap').removeClass('fixed_bottom')
              $('#wrap').addClass('gnb_bottom');
            }
          };
    }, []);
        
    useEffect(() => {       
        //--- 핀번호 5번이상 틀리면 초기화
        if(incorrectCnt > 4) {
            LocalStore.removePinCode();
            let pinFinish = $('.pin_finish');
            // pinFinish.hide();
            // if(LocalStore.getIsTempUser() === false || LocalStore.getFamilyYn() === 'Y') {
            //     execLogout();
            // } else {
            //     execTempLogout()
            // }
        }
    }, [incorrectCnt]);

    const clearInputPasswd = () => {
        $('.pin_finish').hide();
        $('.pinCode').val("");
        // $('#pinCode1').trigger("focus");
    }

    const goBackPage = () => {
        history.goBack();
    }

    const AlarmNoticeClick = (noticeName=null) => {
        history.push(`/my/mysetting/cs/${noticeName}`);
    }

    const PinBoxClick = () => {
        $(function(){
            let pinFinish = $('.pin_finish')

            pinFinish.hide();
        
            $('.pinCode').on("keyup blur", function() {  
                let rgEx = /^[0-9]*$/g;
                let chkEx = rgEx.exec($(this).val());
                if(!chkEx){
                    $(this).val( $(this).val().replace( /[^0-9]/gi, '' ));
                } 
                if ($(this).val()!="") {
                    $(this).next(pinFinish).show();
                } else {             
                    $(this).next(pinFinish).hide();
                }
            });
            
            $('.pin_finish, .pin_box').on("click", function() {
                pinFinish.hide();
                $('.pin_box input').val('');
                $('#pinCode1').trigger("focus");
            });
        });
    }

    const callMemoSetApi = async (data) => {
        const memo = await NativeBridge.setCallMemoPopup(data)
        console.log("메모설정확인",memo);
    }


    const pushAlramCheckApi = async (checked) => {
        const pushData = await NativeBridge.setAlarm(checked)
        console.log("네이티브 알람체크",pushData);
    }

    const execLogout = async() => {
        const UserOutData = await SohoApi.getUserQuit({})
        console.log('배너 조회', UserOutData.retCode)
        if(UserOutData.retCode === '0000') {
            clearLocalData();
            toast('계정삭제 성공 하였습니다.');
            history.replace("/");
        } else {
            toast('계정삭제 실패 하였습니다.');
        }
    }

    const execTempLogout = async() => {
        clearLocalData();
        history.replace("/");
        toast('계정삭제 성공 하였습니다.');
    }

    const CloseMemberClick = () => {
        layerPopup.showCommon("#popup_onetitlesentence_twobutton_leaveaccount", null, "계정삭제", "계정삭제 시 본인 계정은 물론 초대 계정도 서비스를 이용할 수 없게됩니다. 서비스 이용을 위해 등록한 가게 정보를 삭제하고, 계정삭제하시겠어요?", [
            function() {
                layerPopup.hide("#popup_onetitlesentence_twobutton_leaveaccount");   
            },
            function() {
                layerPopup.hide("#popup_onetitlesentence_twobutton_leaveaccount", ()=>{
                    layerPopup.show('#mypasswordpopup');
                    clearInputPasswd();
                    $('#wrap').removeClass('bg_gray');

                    setTimeout(() => {
                        $('#pinCode1').trigger("focus");
                    }, 500);
                });
            }
        ]);
    }

    const NumKeyEvent = (e) => {
        const obj = e.target;
        const objNum = parseInt(obj.id.replace(/^pinCode/i,''), 10);

        fn_onlyNum(e, obj, obj.value);

        console.log(obj.value);

        if(objNum !== 6) {
            const nextId = "pinCode" + ( objNum + 1 );
            fn_NextFocus(e, obj.id, nextId, 1);
        }

        if(objNum !== 1) {
            const prevId = "pinCode" + ( objNum - 1 );
            fn_PreviousFouse(e, obj.id, prevId);
        }

        if(objNum === 6) {
            $('.btn_box .inner button').trigger("focus");
        }
    };

    const checkAngGoNext = () => {
        if(userPass.length !== 6) {
            return;
        }

        let passString = "";
        $('.pinCode').each((idx, item) => {
            passString += $(item).val();
        });
        setUserPass(passString)

        if(passString.length === 6) {
            if(passString === LocalStore.getPinCode()) {
                layerPopup.hide("#mypasswordpopup", ()=>{
                    if(LocalStore.getIsTempUser() === true) {
                        execTempLogout()
                    } else {
                        execLogout();
                    }
                })
            } else {
                setIncorrectCnt(incorrectCnt => incorrectCnt + 1);
                clearInputPasswd();
                $('#pinCode1').trigger("focus");
            }
        }
    }

    const checkComplete = () => {
        let passString = "";
        $('.pinCode').each((idx, item) => {
            passString += $(item).val();
        });
        setUserPass(passString)
    }
    
    const clearInputPasswd2 = () => {
        // setCheck("1")
        $('#wrap').addClass('bg_gray')
        setIncorrectCnt(0)
        setUserPass("")
        $('.pin_finish').hide();
        $('.pinCode').val("");
        $('#pinCode1').trigger("focus");
        layerPopup.hide('#mypasswordpopup')
    }

    const resetPinCode = (event) => {

        event.preventDefault();

        // 임시 사용자 여부 체크
        const isTempUser = LocalStore.getIsTempUser();
        userInfo.setIsTempUser(isTempUser);

        let pinFinish = $('.pin_finish');
        pinFinish.hide();
        
        setUserPass("")
        setIncorrectCnt(0);

        if(isTempUser === true) {
            history.replace("/pincode/reg");
            $('#pinCode1').trigger("focus");
            return;
        }
        history.push("/callkmc/modpass");
    }

    return (
        <>  
			<div className="content my">
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>{(userInfo.dataset.familyYn === 'N' && userInfo.dataset.isTempUser === false) ? 
                                "알림 및 공지보기/계정삭제" : 
                            userInfo.dataset.familyYn === 'Y' && userInfo.dataset.isTempUser === false ?
                                '공지보기' :
                            userInfo.dataset.familyYn === 'N' && userInfo.dataset.isTempUser === true ?
                                '공지보기/계정삭제' :
                            <></>
                            }</h2>
					</div>
				</div>
				<div className="my_setting">
                    <div className="sec_wrap">
                        <div className="set_wrap usetap" 
                             onClick={()=> AlarmNoticeClick((userInfo.dataset.familyYn === 'N' && userInfo.dataset.isTempUser === false) ? 
                                                                'eventnotice'
                                                            : (userInfo.dataset.familyYn === 'Y' && userInfo.dataset.isTempUser === false) ||
                                                            (userInfo.dataset.familyYn === 'N' && userInfo.dataset.isTempUser === true) ? 
                                                                'familynotice'
                                                            : '')}>
                            <span className="set_title">{(userInfo.dataset.familyYn === 'Y' || userInfo.dataset.isTempUser === true) ? "공지보기" : "알림/공지사항"}</span>
                            <span className="i_more"></span>
                        </div>

                        {userInfo.dataset.familyYn === 'Y' ? 
                            <></>
                        :
                            <div className="set_wrap usetap" onClick={()=> CloseMemberClick()}>
                                <span className="set_title">계정삭제</span>
                                <span className="i_more"></span>
                            </div>
                        }
                    </div>
				</div>
			</div>

            <div className="modal fadeInUp" id="mypasswordpopup"> 
                <div className="dim" onClick={clearInputPasswd2}></div>
                <div className="modal_inner modal_up max_h">
                    {/* <button type="button" className="pop_close usetap blind" onClick={()=>layerPopup.hide('#mypasswordpopup')}>닫기</button> */}
                    <div className="pin_wrap modal_password">
                        <p className="title">비밀번호 입력</p>
                        <ul className="pin_box" onClick={PinBoxClick}>
                            <li>
                                <input type="tel"  id="pinCode1" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="첫 번째 비밀번호 입력"/>
                                <span className="pin_finish"  style={{ display:"none"}}></span>
                            </li>
                            <li>
                                <input type="tel" id="pinCode2" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent}   title="두 번째 비밀번호 입력"/>
                                <span className="pin_finish"  style={{display: "none"}}></span>
                            </li>
                            <li>
                                <input type="tel" id="pinCode3" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="세 번째 비밀번호 입력"/>
                                <span className="pin_finish"  style={{display :"none"}}></span>
                            </li>
                            <li>
                                <input type="tel" id="pinCode4" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="네 번째 비밀번호 입력"/>
                                <span className="pin_finish"  style={{ display :"none"}}></span>
                            </li>
                            <li>

                                <input type="tel" id="pinCode5" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="다섯 번째 비밀번호 입력"/>
                                <span className="pin_finish"  style={{display :"none"}}></span>
                            </li>
                            <li>
                                <input type="tel" id="pinCode6" className="pinCode" maxLength="1" onKeyDown={NumKeyEvent} title="여섯 번째 비밀번호 입력" />
                                <span className="pin_finish" style={{display :"none"}}></span>
                            </li>
                        </ul>
                        <p className="pin_desc">
                            다른 사람의 얼굴이 등록되어 있는지,<br /> 화면 주시 필요 기능이 켜져있는지 꼭 확인해주세요.
                        </p>
                        {
                            incorrectCnt > 0 && 
                        <>
                            <span className="err_count">{incorrectCnt} / 5</span>
                            <p className="pin_desc">
                                {
                                incorrectCnt < 5 ? 
                                    <>
                                        {incorrectCnt}회 잘못 입력했습니다.<br />
                                        5회 잘못 입력하면 비밀번호를 재설정해야 합니다.<br />
                                        비밀번호를 잊으셨다면<br />
                                        지금 비밀번호를 재설정 하세요.
                                    </>
                                : 
                                    <>
                                        5회 이상 입력 오류로 <br />
                                        비밀번호 재설정이 필요합니다.
                                    </>
                                }
                            </p>
                        </>
                        }
                        { userInfo.dataset.isTempUser === false &&
                            <div className="reset_pw">
                                { incorrectCnt < 5 &&
                                    <a href="#none" className="link_theme" onClick={(e)=> resetPinCode(e)}>비밀번호 재설정</a>
                                }
                            </div>
                        }
                    </div>
                    <div className="btn_box fixed">
                        <div className="inner">
                            { incorrectCnt < 5 &&
                                    <button type="button" className="btn_lg btn_point usetap" disabled={(userPass.length === 6 ? false : true)} onClick={()=>checkAngGoNext()}>확인</button>
                            }
                            { incorrectCnt > 4 &&
                                    <button type="button" className="btn_lg btn_point usetap" onClick={(e)=> resetPinCode(e)}>비밀번호 재설정</button>
                            }
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
    
});
export default MyCS;