// import * as URLs from '../const';
import axios from 'axios';
import * as LocalStore from '../store/LocalStore';
import * as SessionStore from '../store/SessionStore';
import * as NativeBridge from '../utils/NativeBridge';
import * as CONSTS from '../const';
import { layerPopup } from '../libs/common';

let cctvAuthRetryCnt = 0;

const instance = axios.create({
    baseURL: `https://${process.env.REACT_APP_CCTV_API_URL}`,
    timeout: 10000,
    headers: {'Accept': '*/*', 'Content-Type': 'application/json' }
});
instance.interceptors.request.use(
    function (config) {
        if ( config.url.indexOf('thumbnail') < 0  ){
            NativeBridge.getLoadingStart();
        }
        return config;
    }, 
    function (error) {
        NativeBridge.getLodingEnd();
        return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    function (response) {
        NativeBridge.getLodingEnd();
        return response;
    },
    async function (error) {

        NativeBridge.getLodingEnd();

        // timeout error
        // if(error.code == 'ECONNABORTED'){
        //     console.log('timeout error')
        //     layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "응답시간이 초과되었습니다.<br />다시 한번 시도해 주세요.(CCTV)", [
        //         function() {
        //             layerPopup.hide("#popup_onetitlemsgbutton");
        //         }
        //     ]);
        // }        
        return Promise.reject(error);
    }
);
//처음에 진입 시 null 값이니 if를 탄다. 그래서 header에 token이 안박힘
export const setCctvToken = (data) => {
    // if(LocalStore.getIsTempUser() === true && LocalStore.getCctvToken() === null){
    //     LocalStore.setCctvToken(token);
    //     SessionStore.setCctvToken(token); 
    // }

    instance.defaults.headers.common['X-LGUplus-VSaaS-Key'] = data;
    LocalStore.setCctvToken(data);
    SessionStore.setCctvToken(data);
}

export const setTempCctvToken = (data) => {
    
    if ( data === '') {
        instance.defaults.headers.common['X-LGUplus-VSaaS-Key'] = data;
        LocalStore.setCctvToken(data);
        SessionStore.setCctvToken(data);

        LocalStore.setCctvTempUserData(data);
    } else {
        const token = data.authKey
    
        instance.defaults.headers.common['X-LGUplus-VSaaS-Key'] = token;
        LocalStore.setCctvToken(token);
        SessionStore.setCctvToken(token);

        LocalStore.setCctvTempUserData(data);
    }
}

export const setTempCctvParam = ( _param ) => {
    LocalStore.setCctvTempUserId(_param.userId);
    LocalStore.setCctvTempUserPwd(_param.userPwd);
}

export const initCctvAuthRetryEnv = () => {
    cctvAuthRetryCnt = 0;
}
const cctvAuthErrorPopupCall = () => {
    layerPopup.show("#popup_cctv_auth_error");
}

export default {
    getMyInfo() {
        return instance({
                url: '/res/json/sample3.json',
                method: 'get'
            })
    },
    // CCTV 카메라 목록 조회
    getCameras() {
        return instance({
            url: '/CtrlSvr/soho/cameras?offset=0&limit=0',
            method: 'get',
            data: {}
        }).then(rst=>{

            if(rst.data.resCode === CONSTS.CCTV_RES_CODE_SUCCESS) {
                initCctvAuthRetryEnv();
            } else if ( rst.data.resCode === CONSTS.CCTV_RES_AUTH_ERROR_CODE){
                if(cctvAuthRetryCnt === 0) {
                    cctvAuthRetryCnt++;
                    cctvAuthErrorPopupCall();
                }
            }
            return rst;

        }).catch(e=>{
            console.log(e);
        });
    },
    async getAuth(params) {
        if(cctvAuthRetryCnt > 1) {
            console.log("cctvAuthRetryCnt Over cnt : ", cctvAuthRetryCnt);
            return Promise.resolve();
        }
        setCctvToken(""); // 카메라 인증오류로 인해 임시로 적용
        const rst = await instance({
            url: '/CtrlSvr/soho/user/auth',
            method: 'POST',
            data: params
        });

        // test key
        // rst.data.authKey = "bZlV65GCFyV1RZ/LcPnNTrgtdzhuvx+PMU+g4wyEuaZMTVrpeQJz5h52/hNed5gv+qiFLGyXy/arOXt6AhhINU6wzd8RxPTYcnCZM8mHYOk=";
        if ( rst.data.resCode === 0 ){
            setTempCctvToken(rst.data);
            setTempCctvParam(params);
            initCctvAuthRetryEnv();
        }
        
        return rst;
    },
    getAuth2(params) {
        return instance({
            url: '/CtrlSvr/soho/user/auth',
            method: 'POST',
            data:params
        })
    },
    // CCTV 썸네일 조회
    getCctvThumbNail(params) {
        return instance({
            url: `/CtrlSvr/soho/cameras/${params}/thumbnail`,
            method: 'get',
        })
    },
    // CCTV 이벤트 목록조회
    getCctvEventList(CamIdList=null, StartDate=null, EndDate=null) {
        return instance({
            url: `/CtrlSvr/soho/event/alarms?camIdList=${CamIdList}&fromDateTime=${StartDate}&toDateTime=${EndDate}&offset=0&limit=0`,
            method: 'get',
        })
    },
    // CCTV 이벤트 통계조회
    getCctvEventRuleList(StartDate=null, EndDate=null) {
        return instance({
            url: `/CtrlSvr/soho/event/stat/rule?startDate=${StartDate}&endDate=${EndDate}`,
            method: 'get'
        })
    },
    // 직원목록 조회
    getWorkerList(device_sn_id, params) {
        return instance({
            url: `/CtrlSvr/soho/device/${device_sn_id}/dayOfWorkStatus`,
            method: 'POST',
            data: params
        })
    },
    //로그아웃
    getDeleteAuth(params) {
        return (instance({
            url: '/CtrlSvr/soho/user/auth',
            method:'DELETE',
            data: params
        }))
    }
}