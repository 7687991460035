import React, {useEffect, useState} from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import CctvApi from '../../api/CctvApi';
import { layerPopup, toast} from '../../libs/common';
import { sortObjectList, checkStoreDetailNumber, uncomma , comma} from '../../common/common';
import { setUserDataStoreList } from './DefaultSet'

let selectItemIndex = "";
const StoreDetailReg = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    const [storeListData, setStoreListData] = useState([]);
    const [storeDetail, setStoreDetail] = useState({
        namd: "",
        id: "",
        addr1: "",
        addr2: "",
        zipcode: "",
        ownerType: "",
        cost: 0,
        area: 0
    });
    const [enableBtn, setEnableBtn] = useState(false);

    console.log(userList);

    useEffect(() => {

        const subscription = NativeBridge.eventObservable.subscribe(event => {

            console.log("===================== From Native =====================");
            console.log(event);

            switch (event.type) {
                case 'GET_ZIPCODE':

                    const addr1 = event.param.roadAddrPart1;
                    const addr2 = event.param.addrDetail + event.param.roadAddrPart2;
                    const roadFullAddr  = event.param.roadFullAddr;

                    $("#storeAddr1").val(addr1);
                    $("#storeAddr2").val(addr2);
                    $("#jdtCc").val(roadFullAddr);

                    checkInputAddr();
                break;
            default:
                break;
            }

        });

        getStoreListData();

        if(!$("#wrap").hasClass("fixed_bottom")) {
            $("#wrap").addClass("fixed_bottom");
        }
        $(function(){
            $("#storeAddr1").on('change', () => checkInputAddr());
            $("#storeAddr2").on('change', () => checkInputAddr());

            // 실시간 체크 제거(20210930)
            $("#storeRentCost").on('keyup', (event) => inputNumber(event));
            $("#storeArea").on('keyup', (event) => inputNumber(event));
            
        });

        $('#popup_store_detail_info input[type=text]').on("focusin", function(){
			if ( deviceInfo.dataset.os_type === 'ios'){
				$('.modal_in_fixed').hide();
			}
		});
		$('#popup_store_detail_info input[type=text]').on("focusout", function(){
			if ( deviceInfo.dataset.os_type === 'ios'){
				$('.modal_in_fixed').show();
			}
			
		});
		$('#popup_store_detail_info input[type=text]').on("blur", function(){
			if ( deviceInfo.dataset.os_type === 'ios'){
				$('.modal_in_fixed').show();
			}
		});



        return () => {
            subscription.unsubscribe();
            if($("#wrap").hasClass("fixed_bottom")) {
                $("#wrap").removeClass("fixed_bottom");
            }
        };

    }, []);

    const checkInputAddr = () => {
        console.log('checkInputAddr');
        const addr1 = $("#storeAddr1").val();
        const addr2 = $("#storeAddr2").val();

        if (addr1 !== '' && addr2 !== '') {
            $('#popup_store_detail_info .bottom_box .btn_point').attr('disabled', false);
        } else {
            $('#popup_store_detail_info .bottom_box .btn_point').attr('disabled', true);
        }

        try {
            if(addr1 !== '') {
                $("#storeAddr2").removeAttr('readOnly');
            } else {
                $("#storeAddr2").attr('readOnly', true);
            }
        } catch(e) {}

    }

    const inputNumber = (event) => {
        /*
        console.log(event.keyCode);
        if((event.keyCode >= 48 && event.keyCode <= 57 ) 
            || event.keyCode == 8 //backspace
            || event.keyCode == 37 || event.keyCode == 39 //방향키 →, ←
            || event.keyCode == 46 //delete키
            || event.keyCode == 39){
                console.log('1', event.keyCode);
            event.target.value = event.target.value.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }else{
            event.target.value = event.target.value.replace(/[^0-9]/gi, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            toast('숫자만 입력할 수 있습니다.');
        }
        */

        event.target.value = event.target.value.replace(/[^0-9]/gi, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');

        const currVal = event.target.value.trim();
        if(event.target.id === "storeRentCost") {
            if (uncomma(currVal).length >= 10) {
                event.preventDefault();
                event.stopPropagation();
            }
        } 
        if(event.target.id === "storeArea") {
            if (uncomma(currVal).length >= 5) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

    }

    const getStoreListData = async () => {

        const ownerId = LocalStore.getOwnerId();
        const storeList = await SohoApi.storeDetailList({ownerUserId: ownerId});
        if(storeList.data && storeList.data.storeList && storeList.data.storeList.length) {
            // const list = LocalStore.getStoreList();
            setStoreListData(storeList.data.storeList);
            setUserDataStoreList(storeList)
            // userData.setStoreList(storeList.data.list);
        }

        setTimeout(()=> {
            checkInput();
        }, 500);

    }

    const inputDetail = (e) => {

        console.log(e.currentTarget);
    }
   
    const showDetailPopup = (e, item) => {

        const clickObj = e.currentTarget;

        selectItemIndex = $(clickObj).parent().index();

        console.log("selectItemIndex", selectItemIndex);
        const storeId = $(clickObj).attr("storeid");
        const storeNm = $(clickObj).attr("storenm");
        const data = {
            name: storeNm,
            id: storeId,
            addr1: item.storeAddr ? item.storeAddr : "",
            addr2: item.storeAddrDetl  ? item.storeAddrDetl  : "",
            zipcode: "",
            ownerType: item.storeOwnKd ? item.storeOwnKd : "",
            cost: item.storeRnt ? item.storeRnt : "",
            area: item.rntSpc ? item.rntSpc : "",
            jdtCc : item.jdtCc ? item.jdtCc : ""
        }
        setStoreDetail(data);
        $("#storeAddr1").val(data.addr1);
        $("#storeAddr2").val(data.addr2);
        $("#jdtCc").val(data.jdtCc);
        checkInputAddr();
        // if(data.ownerType === "01") {
        //     $("#typeRent").attr("checked",true);
        //     $("#typeOwn").attr("checked",false);
        // } else if(data.ownerType === "02") {
        //     $("#typeOwn").attr("checked",true);
        //     $("#typeRent").attr("checked",false);
        // } else {
        //     $("#typeRent").attr("checked",false);
        //     $("#typeOwn").attr("checked",false);
        // }

        if(data.ownerType) {
            $("input:radio[name=ownerType]:radio[value="+data.ownerType+"]").prop('checked',true);
        } else {
            $("input:radio[name='ownerType']").prop('checked',false);
        }


        $("#storeRentCost").val(data.cost.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'));
        $("#storeArea").val(data.area.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'));

        layerPopup.show("#popup_store_detail_info");
    }

    const saveStoreDetail = async () => {

        const storeId = $("#popup_store_detail_info .home_name").attr("id");
        const storeNm = $("#popup_store_detail_info .home_name").text();
        const addr1 = $("#storeAddr1").val().trim();
        const addr2 = $("#storeAddr2").val().trim();
        // const zipCode = "1232345"; //$("#storeZipCode").val();
        const owerType = $("input[name='ownerType']:checked").val();
        //const cost = $("#storeRentCost").val().trim();
        //const area = $("#storeArea").val().trim();
        const jdtCc = $("#jdtCc").val().trim();

        if(addr1 === '' || addr2 === '')  {
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "가게 정보를 입력해주세요.", [
                function() {
                    if (addr1 === '') {
                        $("#storeAddr1").val('');
                    }
                    if (addr2 === '') {
                        $("#storeAddr2").val('');
                    }
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
            return;
        }

        /*
        if(cost !== '' &&  !checkStoreDetailNumber(cost)) {
            toast('가게 임대료는 숫자만 입력할 수 있습니다.');
            return;
        }
        if(area !== '' && !checkStoreDetailNumber(area)) {
            toast('가게 평수는 숫자만 입력할 수 있습니다.');
            return;
        }
        if (uncomma(cost).length > 10) {
            toast('가게 임대료는 11자리 이상 입력 할 수 없습니다.');
            return;
        }
        if (uncomma(area).length > 5) {
            toast('가게 평수는 6자리 이상 입력 할 수 없습니다.');
            return;
        }
        */

        //console.log(storeId, storeNm, addr1, addr2, owerType, cost, area, jdtCc);

        const params = {
            storeId: storeId,
            storeAddr: addr1,
            storeAddrDetl: addr2,
            storeOwnKd: owerType,
            storeRnt: '', //cost.replace(/[^0-9]/gi, ''),
            rntSpc: '', //area.replace(/[^0-9]/gi, ''),
            jdtCc: jdtCc // roadFullAddr
        };
        const rst = await SohoApi.storeDetailRegi(params);

        if(rst.retCode === "0000") {
            layerPopup.hide("#popup_store_detail_info", async() => {
                await getStoreListData();
                checkInput();
            });
        }

    }

    

    const checkInput = () => {
        let uncheckCnt = 0;
        let cnt = 0;
        $("button.home_add").each(( index, item ) => {
            cnt++;
            console.log("button cnt ", cnt);
            if(!$(item).hasClass("on")) {
                uncheckCnt++;
            }
        })
        console.log("uncheckCnt:", uncheckCnt);
        if(uncheckCnt === 0) {
            setEnableBtn(true);
        }
    }

    const goNext = () => {
        NativeBridge.sendUserActionLog('detail', 'D052')
        toast("가게 등록을 완료했어요!")
        history.replace("/home");
    }


    const searchZipcode = () => {
        NativeBridge.callZipCode();
    }

    const cancel = () => {
        const addr1 = $("#storeAddr1").val().trim();
        const addr2 = $("#storeAddr2").val().trim();
        if(addr1 === '' || addr2 === '')  {
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "가게 정보를 입력해 주세요.", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
        } else {
            layerPopup.hide("#popup_store_detail_info");
        }
    }

    const ip_clear = (event) => {
        $(event.target).siblings('input').val('');
    }

    const CheckNumber = (e) => {
        if ( isNaN(uncomma(e.target.value))){
            layerPopup.showCommon("#popup_onetitlemsgbutton", null, "알림", "숫자만 입력해 주세요..", [
                function() {
                    layerPopup.hide("#popup_onetitlemsgbutton");
                }
            ]);
        }
    }

    return (
        <>
           

           <div className="shop_setting">
            <div className="page_title_wrap2">
                <div className="inner">
                    <button type="button" className="btn_back usetap blind" onClick={()=>history.goBack()}>뒤로가기</button>
                    <h2>U+우리가게패키지</h2>
                </div>
            </div>
            <div className="inner">
                <ul className="info_step">
                    <li>1</li>
                    <li>2</li>
                    <li className="on">3</li>
                </ul>
                <h1>가게 상세 정보 입력</h1>
                <p className="desc">가게별 상세정보를 입력해 주세요.</p>
                <div className="shop_setting_step3">

                {
                    storeListData && storeListData.length > 0 && 
                    storeListData.map((item, index) => (
                        <div className="input_wrap" key={`key_${item.storeId}`}>
                            <div className="input_box style2">
                                <input type="text" value={item.storeNm} readOnly disabled />
                            </div>
                            <button type="button" id={item.storeId} className={"home_add usetap" + (item.storeAddr && item.storeAddrDetl ? " on" : "")} {...{storeid: item.storeId, storenm: item.storeNm}} onClick={(e)=>showDetailPopup(e, item)}>
                                {item.storeAddr && item.storeAddrDetl ? 
                                    <>입력완료</>    
                                : 
                                    <>입력하기</>    
                                }
                            </button>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
        <div className="btn_box fixed">
            <div className="inner">
                {enableBtn === true ? 
                    <button type="button" className="btn_lg btn_point usetap" onClick={goNext}>다음</button>
                : 
                    <button type="button" className="btn_lg btn_point usetap" disabled>다음</button>
                }
            </div>
        </div>

        <div className="modal fadeInUp" id="popup_store_detail_info">
            <div className="dim"></div>
            <div className="modal_inner modal_up max_h">
                
                <span className="modal_title">가게 상세 정보</span>
                <div className="modal_home_shop">
                    <span className="home_name" id={storeDetail.id}>{storeDetail.name}</span>
                    <p className="desc">가게 정보를 입력해주세요.</p>
                    <div className="input_sec">
                        <span className="title">주소<span className="i_require">(필수)</span></span>
                        <div className="input_box style2 addr_first">
                            <input type="hidden" id="storeZipCode" />
                            <input type="text" id="storeAddr1" readOnly />
                            <button type="button" className="btn_point2 usetap" onClick={searchZipcode}>우편번호 검색</button>
                        </div>
                        <div className="input_box style2">
                            <input type="text" id="storeAddr2" placeholder="상세 주소를 입력해주세요." maxLength="50"/>
                            <input type="hidden" id="jdtCc" />
                            <span className="ip_clear hidden usetap" onClick={ip_clear} >초기화</span>
                        </div>
                    </div>
                    {
                        /*
                    <div className="input_sec">
                        <span className="title">가게 소유 유형<span className="i_opt">(선택)</span></span>
                        <div className="owner_type">
                            <input type="radio" id="typeRent" name="ownerType" value={"01"} />
                            <label htmlFor="typeRent">임차</label>
                        </div>
                        <div className="owner_type">
                            <input type="radio" id="typeOwn" name="ownerType" value={"02"} />
                            <label htmlFor="typeOwn">자가보유</label>
                        </div>
                    </div>
                   
                    
                    <div className="input_sec unit_sec">
                        <span className="title">가게 임대료<span className="i_opt">(선택)</span></span>
                        <div className="input_wrap">
                            <div className="input_box style2">
                                <input type="number" id="storeRentCost" maxLength={10} onClick={CheckNumber}/>
                                <span className="ip_clear hidden usetap" onClick={ip_clear}>초기화</span>
                            </div>
                            <span className="i_unit">원</span>
                        </div>
                    </div>
                    <div className="input_sec unit_sec">
                        <span className="title">가게 평수<span className="i_opt">(선택)</span></span>
                        <div className="input_wrap">
                            <div className="input_box style2">
                                <input type="number" id="storeArea" maxLength={5} onClick={CheckNumber}/>
                                <span className="ip_clear hidden usetap" onClick={ip_clear}>초기화</span>
                            </div>
                            <span className="i_unit">㎡</span>
                        </div>
                    </div>
                        */
                    }
                </div>
            </div>
            <div className="btn_box modal_in_fixed">
                <button type="button" className="btn_half btn_line2 usetap" onClick={cancel}>취소</button>
                <button type="button" className="btn_half btn_point usetap" onClick={saveStoreDetail}>저장</button>
            </div>

        </div>

        </>
    )
    
});
export default StoreDetailReg;