import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import store from "../../../../../store";
import $ from "jquery";
import * as NativeBridge from "../../../../../utils/NativeBridge";
import CentrexApi from "../../../../../api/CentrexApi";
import { action, useStrict } from 'mobx'
import { layerPopup } from "../../../../../libs/common";
import { toast } from "../../../../../libs/common";
import * as LocalStore from "../../../../../store/LocalStore";


//저장 버튼 눌렀을 때 영업요일 설정 초기화 --완료
//타이틀, comtext 입력 안했는데 저장 눌림 -- 완료
//연결음 미리듣기 자동종료? bgfile설정


//className="completed"

const ByDayCallRing = observer(({history, location}) => {
  
	const { userInfo, userData,deviceInfo } = store;
    const userList = userInfo.getList();
    console.log(userList);
	
	const [byDayRing, setByDayRing] = useState(null);
	const [titleValue, setTitleValue] = useState("");
	const [comTextValue, setComTextValue] = useState({
		byDayCall1 : "",
		byDayCall2 : "",
		byDayCall3 : "",
		byDayCall4 : "",
		byDayCall5 : "",
		byDayCall6 : "",
		byDayCall0 : "",
	});
	const [eachCheckInput, setEachCheckInput] = useState("");
	const [checked, setChecked] = useState(true);
	const [dayCheck, setDayCheck] = useState([]);
	const [dayMangage,setDayManage]=useState(""); //음원멘트를 나누기 위한 value 상태 관리
	const [ttsPrePlayInfo, setTtsPrePlayInfo] = useState(null);
	const [comTextValueCheck, setComTextValueCheck] = useState("") 
	const [comTextValueCheck2, setComTextValueCheck2] = useState("") 
	const [render, setRender] = useState("0")

	const centrexIdCheck = () => {
		const centrexId = LocalStore.getStoreInetNo();
		const tempCentrexPass = LocalStore.getTempInetPass();
		return [centrexId ,tempCentrexPass]
	}
	  
	const [resultNum, resultPass] = centrexIdCheck();

	// 화면이 렌더링 되었을 때 speker(여성) default 값 체크
	useEffect(() => {
		setEachCheckInput("0")
		const subscription = NativeBridge.eventObservable.subscribe(event => {
            
			switch (event.type) {                    
		
				case 'CALL_BACKPRESS':
						if(document.getElementById("popup_prelisten").style.display === "block"){
							return layerPopup.hide('#popup_prelisten')
						}
						if(document.getElementById("make_call_before").style.display === "block"){
							return layerPopup.hide('#make_call_before')
						}
						if(((location.pathname === "/shop/call/callset/callring/bydaycall" && document.getElementById("popup_prelisten").style.display === "none") ||
						(location.pathname === "/shop/call/callset/callring/bydaycall" && document.getElementById("popup_prelisten").style.display === ""))){
							goBackPage();
						}
					
				break;
		
			default:
				break;
			}
		
		});

		setDayManage("1")
		document.getElementById('chkMon').className = 'selected'
				if($('#wrap').hasClass('gnb_bottom')) {
					$('#wrap').removeClass('gnb_bottom')
					$('#wrap').addClass('fixed_bottom');
				}     
		 		return () => {
				subscription.unsubscribe();
					if($('#wrap').hasClass('fixed_bottom')) {
					$('#wrap').removeClass('fixed_bottom');
					$('#wrap').addClass('gnb_bottom');
				}
		 	 }
}, [])

	useEffect(() => {
		console.log("1번")
		setComTextValueCheck("1")
	},[comTextValue.byDayCall1])

	useEffect(() => {
		console.log("2번")
		setComTextValueCheck("2")
	},[comTextValue.byDayCall2])

	useEffect(() => {
		console.log("3번")
		setComTextValueCheck("3")
	},[comTextValue.byDayCall3])

	useEffect(() => {
		console.log("4번")
		setComTextValueCheck("4")
	},[comTextValue.byDayCall4])

	useEffect(() => {
		console.log("5번")
		setComTextValueCheck("5")
	},[comTextValue.byDayCall5])

	useEffect(() => {
		console.log("6번")
		setComTextValueCheck("6")
	},[comTextValue.byDayCall6])

	useEffect(() => {
		console.log("0번")
		setComTextValueCheck("0")
	},[comTextValue.byDayCall0])

	useEffect(() => {
		console.log("체크2",comTextValueCheck2)
	},[comTextValueCheck2])

	useEffect(()=> {
		if(comTextValue.byDayCall1 === "" || comTextValue.byDayCall2 === "" ||
		comTextValue.byDayCall3 === ""|| comTextValue.byDayCall4 === "" ||
		comTextValue.byDayCall5 === ""|| comTextValue.byDayCall6 === "" ||
		comTextValue.byDayCall0 === "" || titleValue === "" ){
			$('#bydayCallRing').attr('disabled', true);
		}
		else{
			$('#bydayCallRing').attr('disabled', false);
		}
	
	  },[comTextValue, titleValue])

	  //요일별 통화연결음 생성
const mekeTtsMoh = async (title, com, bgRing) => {
		NativeBridge.getLoadingStart();
		const makeTtsMohData = await CentrexApi.getMakettsMoh({
		newservice : "week",  //전체:com, 시간:work, 요일별:week
		titlename : title.trim(), //음원 명 2자 이상 20자 이하
		speaker : eachCheckInput, // 0 : 남성, 1 : 여성, 2 : 아이
		bgfile: bgRing, // "(빈값)" : 배경사용안함
		//comtext : com, // 전체음원멘트 최대 200자(한글기준, 공백포함)
		weektext0 : com.byDayCall0.trim(),  // 일
		weektext1 : com.byDayCall1.trim(),  // 월
		weektext2 : com.byDayCall2.trim(),  // 화
		weektext3 : com.byDayCall3.trim(),  // 수
		weektext4 : com.byDayCall4.trim(),  // 목
		weektext5 : com.byDayCall5.trim(),  // 금
		weektext6 : com.byDayCall6.trim(),  // 토
	}).catch((e)=> {
		console.log(e);
		userInfo.setDefalutGreeting({
					ringName : "비발디 사계 봄",
					ringEngName : "bg_01",
				  })
		NativeBridge.getLodingEnd();
		history.goBack();
		toast("통화연결음을 등록했습니다.")
	})
	if(makeTtsMohData.data.SVC_MSG === "OK"){
        NativeBridge.sendUserActionLog('detail', 'D094');
		userData.setBgCheck(true);
		userInfo.setDefalutGreeting({
			ringName : "비발디 사계 봄",
			ringEngName : "bg_01",
		  })
		NativeBridge.getLodingEnd();
		history.goBack();
		toast("통화연결음을 등록했습니다.")
	}else{
		NativeBridge.getLodingEnd();
		layerPopup.showCommon(
			'#popup_onetitlemsgbutton', 
			null, 
            '통화연결음 등록 실패', 
            '통화연결음 등록에 실패하였습니다. 다시 시도해주세요.',
			[
				function() {
					layerPopup.hide('#popup_onetitlemsgbutton');//닫기
				}
			]
		);
	}
}

	const comTextClick = (e) => {
		console.log("네임",e.target.name)
		console.log("value", comTextValue);
		if(e.target.name){
			if(e.target.name === "chkMon"){
				if(comTextValue.byDayCall1 === ""){
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall1 === ""){
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
			  	}
			}
			if(e.target.name === "chkTue"){
				if(comTextValue.byDayCall2 === ""){
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall2 === ""){
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
			 	}
			}
			if(e.target.name === "chkWed"){
				if(comTextValue.byDayCall3 === ""){
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall3 === ""){
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
				}
			}
			if(e.target.name === "chkThu"){
				if(comTextValue.byDayCall4 === ""){
					console.log("공백")
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall4 === ""){
						console.log("비움")
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						console.log("채움")
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
				
			  }
			}
			if(e.target.name === "chkFri"){
				if(comTextValue.byDayCall5 === ""){
					console.log("공백")
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall5 === ""){
						console.log("비움")
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						console.log("채움")
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
				
			  }
			}
			if(e.target.name === "chkSat"){
				if(comTextValue.byDayCall6 === ""){
					console.log("공백")
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall6 === ""){
						console.log("비움")
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						console.log("채움")
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
				
			  }
			}if(e.target.name === "chkSun"){
				if(comTextValue.byDayCall0 === ""){
					console.log("공백")
					document.getElementById(`${e.target.name}`).className = ''
				}
				else{
					if(comTextValue.byDayCall0 === ""){
						console.log("비움")
						document.getElementById(`${e.target.name}`).className = ''
					}
					else{
						console.log("채움")
						document.getElementById(`${e.target.name}`).className = 'completed'
					}
				
			  }
			}


		}
	}

// comText input관리
const comTextOnChange = (e) => {
	if(e.target.id)
	{
		setComTextValue({
			...comTextValue,
			[e.target.id] : e.target.value
		})
		document.getElementById(`${e.target.name}`).className = 'selected'
		if(e.target.value === ""){
			document.getElementById(`${e.target.name}`).className = ''
		}
		else{
			//   document.getElementById(`${e.target.name}`).className = 'completed'
		}
		
	}
	else{
		console.log("진입")
		document.getElementById(`${e.target.name}`).className = 'completed'
	}
}



//현재 체크된 요일을 배열에 담는다.
const selectComText = (e) => {
	let dayTrueCheck = e.target.value;
	let byDayId = e.target.id;
	setComTextValueCheck2(byDayId);
	
	if(dayTrueCheck){
		setDayManage(dayTrueCheck);
		if(dayTrueCheck === "1"){
			if(comTextValue.byDayCall1 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkMon').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkMon').className = 'selected'
			}
			if(comTextValue.byDayCall2 === "" )document.getElementById('chkTue').className = ''
			if(comTextValue.byDayCall3 === "" )document.getElementById('chkWed').className = ''
			if(comTextValue.byDayCall4 === "" )document.getElementById('chkThu').className = ''
			if(comTextValue.byDayCall5 === "" )document.getElementById('chkFri').className = ''
			if(comTextValue.byDayCall6 === "" )document.getElementById('chkSat').className = ''
			if(comTextValue.byDayCall0 === "" )document.getElementById('chkSun').className = ''
		}
		if(dayTrueCheck === "2"){
			if(comTextValue.byDayCall2 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkTue').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkTue').className = 'selected'
			}
			if(comTextValue.byDayCall1 === "" )document.getElementById('chkMon').className = ''
			if(comTextValue.byDayCall3 === "" )document.getElementById('chkWed').className = ''
			if(comTextValue.byDayCall4 === "" )document.getElementById('chkThu').className = ''
			if(comTextValue.byDayCall5 === "" )document.getElementById('chkFri').className = ''
			if(comTextValue.byDayCall6 === "" )document.getElementById('chkSat').className = ''
			if(comTextValue.byDayCall0 === "" )document.getElementById('chkSun').className = ''
		}
		if(dayTrueCheck === "3"){
			if(comTextValue.byDayCall3 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkWed').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkWed').className = 'selected'
			}
			if(comTextValue.byDayCall1 === "" )document.getElementById('chkMon').className = ''
			if(comTextValue.byDayCall2 === "" )document.getElementById('chkTue').className = ''
			if(comTextValue.byDayCall4 === "" )document.getElementById('chkThu').className = ''
			if(comTextValue.byDayCall5 === "" )document.getElementById('chkFri').className = ''
			if(comTextValue.byDayCall6 === "" )document.getElementById('chkSat').className = ''
			if(comTextValue.byDayCall0 === "" )document.getElementById('chkSun').className = ''
		}
		if(dayTrueCheck === "4"){
			if(comTextValue.byDayCall4 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkThu').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkThu').className = 'selected'
			}
			if(comTextValue.byDayCall1 === "" )document.getElementById('chkMon').className = ''
			if(comTextValue.byDayCall2 === "" )document.getElementById('chkTue').className = ''
			if(comTextValue.byDayCall3 === "" )document.getElementById('chkWed').className = ''
			if(comTextValue.byDayCall5 === "" )document.getElementById('chkFri').className = ''
			if(comTextValue.byDayCall6 === "" )document.getElementById('chkSat').className = ''
			if(comTextValue.byDayCall0 === "" )document.getElementById('chkSun').className = ''
		}
		if(dayTrueCheck === "5"){
			if(comTextValue.byDayCall5 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkFri').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkFri').className = 'selected'
			}
			if(comTextValue.byDayCall1 === "" )document.getElementById('chkMon').className = ''
			if(comTextValue.byDayCall2 === "" )document.getElementById('chkTue').className = ''
			if(comTextValue.byDayCall3 === "" )document.getElementById('chkWed').className = ''
			if(comTextValue.byDayCall4 === "" )document.getElementById('chkThu').className = ''
			if(comTextValue.byDayCall6 === "" )document.getElementById('chkSat').className = ''
			if(comTextValue.byDayCall0 === "" )document.getElementById('chkSun').className = ''
		}
		if(dayTrueCheck === "6"){
			if(comTextValue.byDayCall6 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkSat').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkSat').className = 'selected'
			}
			if(comTextValue.byDayCall1 === "" )document.getElementById('chkMon').className = ''
			if(comTextValue.byDayCall2 === "" )document.getElementById('chkTue').className = ''
			if(comTextValue.byDayCall3 === "" )document.getElementById('chkWed').className = ''
			if(comTextValue.byDayCall4 === "" )document.getElementById('chkThu').className = ''
			if(comTextValue.byDayCall5 === "" )document.getElementById('chkFri').className = ''
			if(comTextValue.byDayCall0 === "" )document.getElementById('chkSun').className = ''
		}
		if(dayTrueCheck === "0"){
			if(comTextValue.byDayCall0 !==""){
				document.getElementsByName("callLabel4")[0].style.display = "none";
				document.getElementById('chkSun').className = 'completed'
			}
			else{
				document.getElementsByName("callLabel4")[0].style.display = "block";
				document.getElementById('chkSun').className = 'selected'
			}
			if(comTextValue.byDayCall1 === "" )document.getElementById('chkMon').className = ''
			if(comTextValue.byDayCall2 === "" )document.getElementById('chkTue').className = ''
			if(comTextValue.byDayCall3 === "" )document.getElementById('chkWed').className = ''
			if(comTextValue.byDayCall4 === "" )document.getElementById('chkThu').className = ''
			if(comTextValue.byDayCall5 === "" )document.getElementById('chkFri').className = ''
			if(comTextValue.byDayCall6 === "" )document.getElementById('chkSat').className = ''
		}
	}
	//체크 해제된 요일 제거
	else if(dayTrueCheck === dayTrueCheck) {
	}	
}



	   //title input관리
	const tittleOnChange = (e) => {
		setTitleValue(e.target.value)
	}
	
	const byteCheck = (text) => {
		if(text.length > 1){
		console.log("@@@@@@@@@@@@@@@@2",text, text.length);
		let len = 0;
		for (var i = 0; i < text?.length ; i++) {
			if (escape(text.charAt(i)).length === 6) {
				len++;
			}
			len++;
		}
		console.log("zzzzzdd",len);
		return len;
	}else{
		console.log("@@@@@3",text);
	}
}

	// 저장
const saveOnHandleClick = () => {
	console.log("@@@@@@@@@@@@@@@@",comTextValue);
	if(
		comTextValue.byDayCall1 === "" &&
		comTextValue.byDayCall2 === "" &&
		comTextValue.byDayCall3 === "" &&
		comTextValue.byDayCall4 === "" &&
		comTextValue.byDayCall5 === "" &&
		comTextValue.byDayCall6 === "" &&
		comTextValue.byDayCall0 === "" &&
		titleValue === ""
	){
		layerPopup.showCommon(
			'#popup_onetitlebutton_threemsg', 
			
			null, 
			'입력 정보 확인', 
			'설정하지 않은 정보가 있습니다. <br /> 인사말, 영업 요일 등 빠진 항목이 <br /> 없는지 확인해 주세요.',
			
			[
				function() {
					layerPopup.hide('#popup_onetitlebutton_threemsg');//닫기
				},
				
			]
		)
		return;
	}
	if(titleValue.trim().length < 2 || titleValue.trim().length > 20){
        layerPopup.showCommon(
            '#popup_onetitlemsgbutton',  
            null, 
            '제목 글자 수 알림', 
            '2자 이상 20자 이하로 입력해주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                },
			]
        )
		return
    }
     if(
	((comTextValue?.byDayCall0.trim().length) < 5 || (comTextValue?.byDayCall0.trim().length)> 200) ||
	((comTextValue?.byDayCall1.trim().length) < 5 || (comTextValue?.byDayCall1.trim().length)> 200)||
	((comTextValue?.byDayCall2.trim().length) < 5 || (comTextValue?.byDayCall2.trim().length)> 200) ||
	((comTextValue?.byDayCall3.trim().length) < 5 || (comTextValue?.byDayCall3.trim().length)> 200) ||
	((comTextValue?.byDayCall4.trim().length) < 5 || (comTextValue?.byDayCall4.trim().length)> 200) ||
	((comTextValue?.byDayCall5.trim().length) < 5 || (comTextValue?.byDayCall5.trim().length)> 200) ||
	((comTextValue?.byDayCall6.trim().length) < 5 || (comTextValue?.byDayCall6.trim().length)> 200)) 
		{
        layerPopup.showCommon(
            '#popup_onetitlemsgbutton', 
            null, 
            '내용 글자 수 알림', 
            '5자 이상 200자 이하로 입력해주세요.',
            [
                function() {
                    layerPopup.hide('#popup_onetitlemsgbutton');//닫기
                },
            ]
        )
		return;
    }
		userData.setByDayTimeCheck("ok")
			mekeTtsMoh(titleValue, comTextValue, userInfo.dataset.defaultGrt.ringEngName);
}

		//여성, 남성, 아이 구분 각각 0,1,2
const speakerHandelClick = (e) => {
	//defalut값(여성 = 1) 유지하면서 다른 input 체크
	if(e.target.value === "1" || e.target.value ==="2"){
		setChecked(false)
	}
	else{
		setChecked(true)
	}
	setEachCheckInput(e.target.value);
}

//연결음 미리듣기 API
const ttsPrePlay = async (speaker, text, bgRing) => {
	if( text === ""){
		text = "안녕하세요,고객님. 주말은 전화 통화량이 많습니다. 잠시만 기다려주세요."
	}
	const ttsPrePlayData = await NativeBridge.getRingMusicPlay(speaker, bgRing, text)
    NativeBridge.sendUserActionLog('detail', 'D093', '', '요일별');
    setTtsPrePlayInfo(ttsPrePlayData);
}

//연결음 미리듣기 click
const ttsPrePlayOnClick = () => {

	console.log("comTextValueCheck2", comTextValueCheck2)

	if(comTextValueCheck === "1" || comTextValueCheck2 === "chkMon"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall1,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
	if(comTextValueCheck === "2" || comTextValueCheck2 === "chkTue"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall2,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
	if(comTextValueCheck === "3" || comTextValueCheck2 === "chkWed"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall3,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
	if(comTextValueCheck === "4" || comTextValueCheck2 === "chkThu"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall4,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
	if(comTextValueCheck === "5" || comTextValueCheck2 === "chkFri"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall5,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
	if(comTextValueCheck === "6" || comTextValueCheck2 === "chkSat"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall6,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
	if(comTextValueCheck === "0" || comTextValueCheck2 === "chkSun"){
		return ttsPrePlay(eachCheckInput, 
			comTextValue.byDayCall0,
			userInfo.dataset.defaultGrt.ringEngName
		   );
	}
}


  const goBackPage = () => {
    NativeBridge.sendUserActionLog('detail', 'D092', "", "요일별");
	layerPopup.showCommon(
	  '#make_call_before', 
	  null, 
	  '이전 화면으로 이동', 
      '통화연결음을 만들지 않고 이전화면으로 <br /> 이동하시겠어요?',
	  [
		  function() {
			  layerPopup.hide('#make_call_before');//닫기
		  },
		  function() { // 확인 버튼
			userData.setBgCheck(true);
			layerPopup.hide('#make_call_before',() => {
                history.goBack();
            });
		  }
	  ]
 	)	
  }
  
  const textAreaLabelControl = (e) => {
	if(e.target.id === "abc" && document.getElementById(e.target.htmlFor) !== null ){
	document.getElementsByName("callLabel4")[0].style.display = "none"
	document.getElementById(e.target.htmlFor).focus();
	}
  }
 
  const inputClear = () => {
	setTitleValue("")
}
 
const saveButtonNone = () => {
    if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "none";
  }
  const saveButtonBlock = () => {
    if(deviceInfo.dataset.os_type === "ios") document.getElementsByName("save_button")[0].style.display = "block";
  }
  const popupScrollControll = () => {
	if(userInfo.dataset.defaultGrt.ringEngName === "bg_01"){
		userData.setBgCheck(true);
	}
	layerPopup.show('#popup_prelisten');
  }
  return (
    <>
			<div className="content store" onBlur={saveButtonBlock}>
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<button type="button" className="btn_back usetap blind" onClick={()=> goBackPage()}>뒤로가기</button>
						<h2>요일별 통화연결음</h2>
					</div>
				</div>
				<div className="ringback_greeting">
				    <div className="greet_sec">
				        <span className="title">통화연결음 이름</span>
				        <div className="input_box style2">
                            <input type="text" onFocus={saveButtonNone} placeholder="사용자 연결음" value={titleValue} onChange={(e)=>tittleOnChange(e) }/>
                            <span className="ip_clear hidden usetap" onClick={() =>{inputClear()}}>초기화</span>
                        </div>
				    </div>
				    <div className="greet_sec">
				        <span className="title">배경음</span>
				    	<button type="button" className="sel_sound" value={userInfo.dataset.defaultGrt.ringEngName} onClick={()=>popupScrollControll()}>{userInfo.dataset.defaultGrt.ringName}<i className="btn_dark_down"></i></button>
					</div>
				    <div className="greet_sec">
					<span className="title">요일 설정</span>
                        <p className="desc">모든 요일별 음원 멘트를 작성해 주셔야 합니다.</p>
                        {/* <!-- 210725 요일 설정 추가 --> */}
                        <ol className="greet_day_set">
								<li><button type="button" id="chkMon" name="chkDays" value="1" onClick ={(e) => selectComText(e)}>월</button></li> 
								<li><button type="button" id="chkTue" name="chkDays" value="2" onClick ={(e) => selectComText(e)}>화</button></li> 
								<li><button type="button" id="chkWed" name="chkDays" value="3" onClick ={(e) => selectComText(e)}>수</button></li> 
								<li><button type="button" id="chkThu" name="chkDays" value="4" onClick ={(e) => selectComText(e)}>목</button></li>
								<li><button type="button" id="chkFri" name="chkDays" value="5" onClick ={(e) => selectComText(e)}>금</button></li>
								<li><button type="button" id="chkSat" name="chkDays" value="6" onClick ={(e) => selectComText(e)}>토</button></li>
								<li><button type="button" id="chkSun" name="chkDays" value="0" onClick ={(e) => selectComText(e)}>일</button></li>
                        </ol>
                        <div className="tab_style4_radio tab3">
                            <input type="radio" id="sourceWoman" name="soundSource" value="0" checked={checked} onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceWoman">여성</label>
                            <input type="radio" id="sourceMan" name="soundSource" value="1" onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceMan">남성</label>
                            <input type="radio" id="sourceChild" name="soundSource" value="2" onChange={(e) => speakerHandelClick(e)} />
                            <label htmlFor="sourceChild">아이</label>
                        </div>
						 <div className="textarea_wrap textarea_greet" >
							<label htmlFor={`byDayCall${dayMangage}`} className="textarea_label" id="abc" name="callLabel4" style={{display : ""}} onClick={(e)=>textAreaLabelControl(e)} >
							예) 안녕하세요,고객님. 주말은 전화 통화량이 많습니다. 잠시만 기다려주세요.
								<span className="txt_limit">200자 이내</span>
							</label>
							{
								dayMangage === "1" &&
								<textarea  onFocus={saveButtonNone} id={`byDayCall${dayMangage}`} name="chkMon" value={comTextValue.byDayCall1 ||""} onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>
							}
							{
								dayMangage === "2" &&
								<textarea  onFocus={saveButtonNone} id={`byDayCall${dayMangage}`} name="chkTue" value={comTextValue.byDayCall2 ||""}onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>

							}
							{
								dayMangage === "3" &&
								<textarea  onFocus={saveButtonNone} id={`byDayCall${dayMangage}`} name="chkWed" value={comTextValue.byDayCall3 ||""}onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>
							}
							{
								dayMangage === "4" &&
								<textarea  onFocus={saveButtonNone} id={`byDayCall${dayMangage}`} name="chkThu" value={comTextValue.byDayCall4 ||""}onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>
							}
							{
								dayMangage === "5" &&
								<textarea onFocus={saveButtonNone}  id={`byDayCall${dayMangage}`} name="chkFri"  value={comTextValue.byDayCall5 ||""}onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>
							}
							{
								dayMangage === "6" &&
								<textarea  onFocus={saveButtonNone} id={`byDayCall${dayMangage}`} name="chkSat" value={comTextValue.byDayCall6 ||""}onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>
							}
							{
								dayMangage === "0" &&
								<textarea onFocus={saveButtonNone}  id={`byDayCall${dayMangage}`} name="chkSun" value={comTextValue.byDayCall0 ||""} onBlur={(e)=>{comTextClick(e)}} onChange={(e)=>comTextOnChange(e)} ></textarea>
							}
				   		</div>
					</div>
				</div>
				<div className="btn_box fixed" name="save_button">
                    <div className="inner">
                        <button type="button" className="btn_half7 btn_point usetap" onClick={()=>ttsPrePlayOnClick()}>미리듣기</button>
                        <button type="button" id="bydayCallRing" className="btn_half3 btn_line2 usetap" disabled="" onClick={()=>saveOnHandleClick()}>저장</button>
                    </div>
                </div>
			</div>
    </>
  );
});
export default ByDayCallRing;
