import React from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import * as NativeBridge from '../../utils/NativeBridge';

const Foot = observer(({ location, history }) => {

    const { userData } = store;

    const goMainPage = (page) => {
        let gnbCode = 'M01';
        switch (page) {
            case 'home':
                gnbCode = 'M01'
                break;
            case 'shop/cctv':
                gnbCode = 'M02'
                break;
            case 'ceocommunity':
                gnbCode = 'M03'
                break;

            case 'my':
                gnbCode = 'M04'
                break;
            default:
                break;
        }
        userData.setSisicMainTabList('order');
        userData.setOrderTabList('neworder');
        NativeBridge.sendUserActionLog('gnb', gnbCode, 'start');
        history.push(`/${page}`);
    }

    return (
        <>
        <div id="footer" className="tab_wrap">
            <button type="button" className={"gnb_tab tab_home usetap" + 
            (
                location.pathname === "/home"
                ? " on" : "")} onClick={()=>goMainPage('home')}>홈
            </button>
            {/* 우리가게 탭 모든 페이지 class on 활성화 */}
			<button type="button" className={"gnb_tab tab_store usetap" + 
                (   
                    location.pathname === `/shop/interwork`
                    // || location.pathname ===`/shop/call/callset/receiveblockset`
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}` 
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}/${userData.dataset.currCallPage}` 
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}/${userData.dataset.currCallPage}/${userData.dataset.phoneBookTap}` 
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}/${userData.dataset.currCallPage}/${userData.dataset.phoneBookTap}/detail` 
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}/${userData.dataset.currCallPage}/${userData.dataset.phoneBookTap}/detail/edit`
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}/${userData.dataset.shopMsgLogTab}`
                    || location.pathname === `/shop/${userData.dataset.shopMainTab}/${userData.dataset.shopMsgLogTab}/detail`
                    ? " on" : "")} onClick={()=>goMainPage('shop/cctv')}>우리가게
            </button>
            <button type="button" className={"gnb_tab tab_food usetap" +
                (
                    location.pathname === `/ssisicga/${userData.dataset.sisicMainTab}`
                    || location.pathname === `/ssisicga/${userData.dataset.sisicMainTab}/${userData.dataset.orderTap}`
                    ? " on" : "")} onClick={()=>goMainPage('ssisicga/order/neworder')}>씨식가</button>
			<button type="button" className={"gnb_tab tab_square usetap" + 
                (
                   location.pathname === "/ceocommunity" 
                   ? " on" : "")} onClick={()=>goMainPage('ceocommunity')}>사장님 광장
            </button>
			<button type="button" className={"gnb_tab tab_my usetap" + 
                (
                    location.pathname.substr(0, 3) === "/my" 
                    ? " on" : "")} onClick={()=>goMainPage('my')}>내 정보
            </button>
        </div>
        </>
    )
});

export default Foot;