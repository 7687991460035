import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import store from '../../../store';
import moment from 'moment';
import SohoApi from '../../../api/SohoApi';
import * as CONSTS from '../../../const/index'
import { isDataCheck } from '../../../common/common';
import { observer } from 'mobx-react';

const OrderList = observer(() => {

    const { userData } = store

    const [onlineBoardInfo, setOnlineBoardInfo] = useState([]);
    const [storeBoardInfo, setStoreBoardInfo] = useState([]);
    const [onlineCnt, setOnlineCnt] = useState(0);
    const [likeCnt, setLikeCnt] = useState(0);
    const [shareCnt, setShareCnt] = useState(0);

    useEffect(() => {
      async function fetchBoardInfo() {
          await getBoardInfo()
      }
      if(userData.dataset.sissicgaRegiYn === 'Y') {
          fetchBoardInfo();
      }
    }, [])

    useEffect(() => {
        if(isDataCheck(onlineBoardInfo)) {
            setOnlineCnt(onlineBoardInfo.weekOnlineCnt);
            setLikeCnt(onlineBoardInfo.weekLikeCnt);
            setShareCnt(onlineBoardInfo.weekShareCnt);
        }
    }, [onlineBoardInfo])
    

    const getBoardInfo = async() => {
        try {
            await SohoApi.getBoardInfo({
                storeId: userData.dataset.storeId
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setOnlineBoardInfo(rst.data.onlineBoard)
                    setStoreBoardInfo(rst.data.storeBoard)
                }
            }).catch((e) => {
                console.log(e);
            })
        } catch (e) {
            console.log(e);
        }
    }

    const goSsisicgaAnalyze = () => {
        userData.setSisicMainTabList('analyze');
    }
    
    const goSsisicga = () => {
        userData.setSisicMainTabList('order');
        userData.setOrderTabList('neworder');
    }

    const Refresh = async() => {
        setOnlineBoardInfo([]);
        setStoreBoardInfo([]);
        setOnlineCnt(0);
        setLikeCnt(0);
        setShareCnt(0);

        await getBoardInfo();
    }

    return (
        <div className="content_box">
            <Link to="/ssisicga/analyze" className="sec_link usetap" onClick={()=> goSsisicgaAnalyze()}><i className="ico_dark_more"></i>더보기</Link> 
            <span className="cont_title">씨식가</span>
            <div className="sec_wrap sec_food">
                <Link to="/ssisicga/order/neworder" className="desc_link" onClick={()=> goSsisicga()}>
                    <span className="info_msg">7일 간 씨식가를 통해</span>
                    <strong className="st_msg">총 <span className="pt_color">{isDataCheck(storeBoardInfo.weekCnt) ? storeBoardInfo.weekCnt : '0'}건</span>의 <br/>주문이 들어왔어요.</strong>
                </Link>
                <div className="cctv_sense">
                    <Link to="/ssisicga/order/neworder" onClick={()=> goSsisicga()}>
                        <ul>
                            <li className={'sense1' + (storeBoardInfo.todayCnt > 0 ? ' on' : '' )}>
                                <span className="title_sense">오늘 주문 수</span>
                                <span className="num_sense">{isDataCheck(storeBoardInfo.todayCnt) ? storeBoardInfo.todayCnt : '0'}건</span>
                            </li>
                            <li className={'sense2' + (onlineCnt > 0 ? ' on' : '' )}>
                                <span className="title_sense">온라인 방문 수</span>
                                <span className="num_sense">{onlineCnt}건</span>
                            </li>
                            <li className={'sense3' + (likeCnt > 0 ? ' on' : '' )}>
                                <span className="title_sense">좋아요 수</span>
                                <span className="num_sense">{likeCnt}건</span>
                            </li>
                            <li className={'sense4' + (shareCnt > 0 ? ' on' : '' )}>
                                <span className="title_sense">공유 수</span>
                                <span className="num_sense">{shareCnt}건</span>
                            </li>
                        </ul>
                    </Link>
                </div>
                <div className="num_info">
                    <span className="i_date"><i className="ico_info"></i>{moment().format('YYYY.MM.DD')} 기준, 7일 간 현황</span>
                    <div className="refresh_wrap">
                        <span className="i_time">{moment().format('HH:mm:ss')}</span>
                        <button type="button" className="btn_refresh usetap" onClick={()=> Refresh()}><i className="ico_refresh"></i>새로고침</button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default OrderList;