import React from 'react';
import { observer } from 'mobx-react';
import store from '../../../store';
import * as NativeBridge from '../../../utils/NativeBridge';

const AlbaBannerUnSub = observer(() => {
    const { userInfo, userData } = store;

    const UnSubImg = userData.dataset.homecard?.filter(item => (item.svcCd === '000006'))

    const ExternalPage = (urls) => {
        NativeBridge.sendUserActionLog('menu', 'H006');
        NativeBridge.externalCall(`${urls}`)
    }

    return (
        <> 
            { 
                <div className="content_box">
                    <div className="sec_wrap unsubs_img" onClick={()=>ExternalPage(UnSubImg[0]?.urlConn)}>
                        <img src={`${UnSubImg[0]?.hposImge}`} />
                        <button type="button"><img src={`${UnSubImg[0]?.urlConnImge}`} /></button>
                    </div>
                </div>
            }
        </>
    )
});

export default AlbaBannerUnSub;