import React, {useEffect, useState} from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as Crypt from '../../utils/Crypt';
import SohoApi from '../../api/SohoApi';
import CctvApi, {setCctvToken} from '../../api/CctvApi';
import * as SessionStore from '../../store/SessionStore';
// import {setCentrexSsoKey} from  '../../api/CentrexApi';
import { layerPopup, toast} from '../../libs/common';
import { sortObjectList, addHyphenPhoneNo, closeBack } from '../../common/common';
import * as CONSTS from '../../const';
// let phoneNoObj = null;
// let shopObj = null;
let phoneListNo = 0;
let shopListNo = 0;
let shopCctvListNo = 0;
let shopNoTarget = "phone"; // phone, cctv

// let mappdingData = 
// [
//     {
//         storeId: "",
//         lpzEntrNo: "",
//         lzpEntrNo: []
//     }
    
// ];

const StoreMapping = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;
    const userList = userInfo.getList();
    const [storeListData, setStoreListData] = useState([]);
    const [storePhoneMappingCnt, setStorePhoneMappingCnt] = useState(0);
    const [storeDetailListData, setStoreDetailListData] = useState([]);
    const [storePhoneList, setStorePhoeList] = useState([]);
    const [storeCctvList, setStoreCctvList] = useState([]);
    const [allStoreIdList, setAllStoreIdList] = useState([]);
    const [cameras, setCameras] = useState();
    const [nextBtnOk, setNextBtnOk] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // getStoreListData();
        getStoreDetailListData();
        getServiceContractList();

        SessionStore.setStoreMappingJob();

        // 예외처리 (5초내 데이터 셋 완성 못하면 버튼 생성)
        setTimeout(()=>{
            setNextBtnOk(true);
        }, 5000);

        if(!$("#wrap").hasClass("fixed_bottom")) {
            $("#wrap").attr("class", "fixed_bottom");
        }

        console.log('history.location.state', history.location.state)

        return () => {
            if($("#wrap").hasClass("fixed_bottom")) {
                $("#wrap").removeClass("fixed_bottom");
            }
        }
    }, []);

    const getStoreListData = async () => {
        const storeList = await SohoApi.getStoreList({ownerUserId : LocalStore.getOwnerId()});
        // userData.setStoreList(storeList.data.list);
        setStoreListData(storeList.data.list);
    }

    const getStoreDetailListData = async () => {
        const storeDetailList = await SohoApi.storeDetailList({ownerUserId : LocalStore.getOwnerId()});
        setStoreListData(storeDetailList.data.storeList);


        // --- 전화번호 가 매핑된 매장 수 계산
        let calCount = 0;
        if(Array.isArray(storeDetailList.data.storeList) && storeDetailList.data.storeList.length > 0) {
            storeDetailList.data.storeList.map((item, index) => {
                if(item.storeInetNo) {
                    calCount++;   
                }
            });
        }
        setStorePhoneMappingCnt(calCount);
        console.log("calCount : " , calCount);
        
        //const cameras = await CctvApi.getCameras();

        //서비스 가입유무를 통한 CCTV 가입유무 확인 ( CCTV는 가입하였으나, 매장설정하지 않은 경우)
        //const ownerServiceList = await SohoApi.serviceContractList();

        //매장정보에서 CCTV가입유무 확인
        const isJoinCCTV = storeDetailList.data.storeList.filter( (targetItem) => {
            if ( targetItem.lzpList.length > 0) return targetItem;
        }).length > 0 ? true : false;

        let rtn_cameras = {
            data: {
                list : []
            }
        };

        if ( isJoinCCTV ){

            try {
                //카메라 인증키 조회 후 카메라 목록 생성
                const cctvAuthRet = await SohoApi.getCctvAuth();
                if(cctvAuthRet && cctvAuthRet.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    //무조건 카메라 정보를 조회하면 안됨.
                    const cameraRet = await CctvApi.getCameras();
                    if ( cameraRet.resCode === CONSTS.CCTV_RES_CODE_SUCCESS) {
                        rtn_cameras = cameraRet;
                        console.log('>>>>set cam', rtn_cameras);
                    } 
                }   
            } catch(e) {
                rtn_cameras = {
                    data: {
                        list : []
                    }
                };
            }   
        }   
        
        console.log("set camera info : ", rtn_cameras);
        setCameras(rtn_cameras);
        
        //--- cctv 가입번호가 매핑된 데이터만 중복 제거하여 생성한다.
        const newList = [];
        const allStoreIdList = [];
        
        if(storeDetailList.data.storeList.length > 0) {
            storeDetailList.data.storeList.map((item, index) => {
                allStoreIdList.push(item.storeId);
                if(item.lzpList.length > 0) {
                    const camIds = [];
                    const cams = [];
                    const cam_device_sn_id = [];
                    item.lzpList.map((lzp) => {
                        const lzpEntrNo = lzp.lzpEntrNo;
                        if(item.lzpList.length > 0 && rtn_cameras.data.list !== undefined) {
                            rtn_cameras.data.list.map(camera => {
                                const cam = {};
                                if (lzpEntrNo.indexOf(camera.info[0].subsNo) > -1) {
                                    camIds.push(camera.camId);
                                    cam.camId = camera.camId;
                                    cam.camName = camera.camName;
                                    cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
                                    cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
                                    if (camera.device_sn_id.length > 0) {
                                        cam_device_sn_id.push(camera.device_sn_id)
                                    }
                                    cams.push(cam);
                                }
                            });
                        }
                    })
                    item.camId = camIds.join(',');
                    item.cam_device_sn_id = cam_device_sn_id;
                    item.cams = cams;
                } else {
                    item.camId = '';
                    item.cam_device_sn_id = [];
                    item.cams = [];
                }
                newList.push(item);
            });
        }
        setStoreDetailListData(newList);
        setAllStoreIdList(allStoreIdList);

        setNextBtnOk(true);

    }

    const getServiceContractList = async () => {
        
        const serviceList = await SohoApi.serviceContractList();



        const phoneList = serviceList.data.lpzList;
        const sortedPhoneList = sortObjectList(phoneList,'lpzNo', 'ASC');
        // const sortedPhoneList = phoneList.sort(function (a, b) {
        //     let x = a.lpzno;
        //     let y = b.lpzno;
        //     if (x < y) {
        //         return -1;
        //     }
        //     if (x > y) {
        //         return 1;
        //     }
        //     return 0;
        // });

        setStorePhoeList(sortedPhoneList);
        setStoreCctvList(serviceList.data.lzpList);
        setLoading(true);
    }


    const showPhoneNoListPopup = (e) => {
        const phoneNoObj = e.currentTarget;
        phoneListNo = $(phoneNoObj).parent().index();
        const text = $(e.currentTarget).text();

        $("#popup_phone_number_select ul li label").each((i, item) => {
            if (text === $(item).text()) {
                $(item).siblings('input').prop("checked", true);
            } else {
                $(item).siblings('input').prop("checked", false);
            }
        })
        layerPopup.show('#popup_phone_number_select');
    }

    const showStoreListPopup = (e) => {
        shopNoTarget = "phone";
        const shopObj = e.currentTarget;
        shopListNo = $(shopObj).parent().index();
        const text = $(e.currentTarget).text();

        $("#popup_store_id_select ul li label").each((i, item) => {
            if (text === $(item).text()) {
                $(item).siblings('input').prop("checked", true);
            } else {
                $(item).siblings('input').prop("checked", false);
            }
        })
        console.log("shopListNo: " , shopListNo);
        layerPopup.show('#popup_store_id_select');
    }


    const selectPhoneNo = () => {

        const lpzno = $("#popup_phone_number_select ul li input[name='representNum']:checked").attr("lpzno");
        const lpzentrno = $("#popup_phone_number_select ul li input[name='representNum']:checked").attr("lpzentrno");
        console.log(lpzno, lpzentrno);
        if(!lpzno || !lpzentrno) {
            return;
        }

        const targetObj1 = $(".represent_number ul li").eq(phoneListNo).children().eq(0);       // 전화번호 버튼
        const targetObj2 = $(".represent_number ul li").eq(phoneListNo).children().eq(1);       // 매장선택버튼
        targetObj2.attr("lpzno",lpzno);
        targetObj2.attr("lpzentrno",lpzentrno);
        targetObj1.html(addHyphenPhoneNo(lpzno) + "<i></i>");
        layerPopup.hide('#popup_phone_number_select');
    }
    const selectStore = () => {
        const storenm = $("#popup_store_id_select ul li input[name='shopSelect']:checked").attr("storenm");
        const storeid = $("#popup_store_id_select ul li input[name='shopSelect']:checked").attr("storeid");
        //-- 전화번호에 대한 매장 설정인경우
        let targetObj = null;
        if(shopNoTarget === "phone") {
            targetObj = $(".represent_number ul li").eq(shopListNo).children().eq(1);
        //-- cctv 대한 매장 설정인경우
        } else {
            targetObj = $("ul.cctv_sel_list li").eq(shopCctvListNo).children("button");
        }

        console.log(targetObj); 
        targetObj.attr("storeid",storeid);
        targetObj.attr("storenm",storenm);
        targetObj.addClass("on");
        targetObj.html(storenm);

        // 현재 설정한 storeId가 중복적용 되었는지 확인
        if(shopNoTarget === "phone") {
            let isDuplCnt = 0;
            $(".represent_number ul li button").each((index, item)=> {
                if($(item).hasClass("btn_sel_shop")) {
                    const currStoreId = $(item).attr("storeid");
                    if(currStoreId === storeid) {
                        isDuplCnt++;
                    }
                }
            });
            console.log("isDuplCnt: " , isDuplCnt);

            if(isDuplCnt > 1) {
                toast("가게가 중복됩니다.");
                targetObj.attr("storeid","");
                targetObj.attr("storenm","");
                targetObj.removeClass("on");
                targetObj.html("가게선택");    
                layerPopup.hide('#popup_store_id_select');
                return;
            }
        }
        $("input:radio[name='shopSelect']").prop("checked", false);
        layerPopup.hide('#popup_store_id_select');
    }

    const addPhoneNoBox = () => {

        const liElem = document.createElement("li");

        const btn1 = document.createElement("button");
        btn1.type = "button";
        btn1.className = "btn_sel_tel usetap";
        btn1.innerHTML = "070-0000-0000<i></i>";
        btn1.onclick = (e) => {
            showPhoneNoListPopup(e);
        };
        const btn2 = document.createElement("button");
        btn2.type = "button";
        btn2.className = "btn_sel_shop usetap";
        btn2.innerHTML = "가게선택";
        btn2.onclick = (e) => {
            showStoreListPopup(e);
        };
        liElem.appendChild(btn1);
        liElem.appendChild(btn2);

        $(".represent_number ul").append(liElem);

    }

    const showStoreListCctvPopup = (e) => {
        shopNoTarget = "cctv";
        const shopObj = e.currentTarget;
        shopCctvListNo = $(shopObj).parent().index();
        const text = $(e.currentTarget).text();

        $("#popup_store_id_select ul li label").each((i, item) => {
            if (text === $(item).text()) {
                $(item).siblings('input').prop("checked", true);
            } else {
                $(item).siblings('input').prop("checked", false);
            }
        })
        console.log("shopCctvListNo: " , shopCctvListNo);
        layerPopup.show('#popup_store_id_select');
    }

    const goNext = async () => {
        const lpzList = [];
        const storeIdList = [];
        const localStoreList = [];
        let breakYn = false;
        $(".represent_number ul li").each((index, item) => {

            const btnObj    = $(item).children().eq(1);
            const lpzno     = btnObj.attr("lpzno");
            const lpzentrno = btnObj.attr("lpzentrno");
            const storeid   = btnObj.attr("storeid");
            const storenm   = btnObj.attr("storenm");

            console.log('lpzno', lpzno);

            if (lpzno === undefined && storeid !== undefined ) {
                breakYn = true;
            } 

            if(storeid) {
                lpzList.push({
                    storeId: storeid,
                    storeNm: storenm,
                    lpzEntrNo: lpzentrno,
                    lpzNo: lpzno,
                    lzpEntrNo: ""
                });
                storeIdList.push(storeid);
                localStoreList.push({
                    storeId: storeid,
                    storeNm: storenm,
                    storeDetailReg: false
                });
            }
        });
        
        if (breakYn) {
            toast('인터넷전화 번호를 먼저 선택해주세요.');
            return;
        }

        const lzpList = [];
        $("ul.cctv_sel_list li button").each((index, item) => {
            const lzpentrno = $(item).attr("lzpentrno");
            const storeid   = $(item).attr("storeid");
            const storenm   = $(item).attr("storenm");
            if(storeid) {
                lzpList.push({
                    storeId: storeid,
                    storeNm: storenm,
                    lpzEntrNo: "",
                    lpzNo: "",
                    lzpEntrNo: lzpentrno,
                    // camId: camId
                });            
                storeIdList.push(storeid);
                localStoreList.push({
                    storeId: storeid,
                    storeNm: storenm,
                    storeDetailReg: false
                });
            }
        });
        //종복제거
        var uniqStoreIdList = storeIdList.reduce(function(a,b){
            if (a.indexOf(b) < 0 ) a.push(b);
            return a;
        },[]);

        const otherStoreId = allStoreIdList.filter(storeId => !uniqStoreIdList.includes(storeId));

        let storeMappingList = [];
        let userDataStoreList = [];
        let currStoreNm = '';
        let currLpzNo = '';

        const storeServiceRegiData = {
            storeList: []
        };

        uniqStoreIdList.map((currStoreId, index) => {
            currLpzNo = '';
            let addLpzList = [];
            lpzList.map((item, index) => {
                if(item.storeId === currStoreId) {
                    addLpzList.push(item.lpzEntrNo);
                    currStoreNm = item.storeNm;
                    currLpzNo = item.lpzNo;
                }
            })

            let camIds = [];
            let cams = [];
            let cam_device_sn_id = [];
            let addLzpList = [];
            
            lzpList?.map((item, index) => {
                if(item.storeId === currStoreId) {
                    addLzpList.push(item.lzpEntrNo);
                    cameras.data.list?.map((camera) => {
                        const cam = {};
                        if (item.lzpEntrNo.indexOf(camera.info[0].subsNo) > -1) {
                            camIds.push(camera.camId);
                            cam.camId = camera.camId;
                            cam.camName = camera.camName;
                            cam.camOpenUrl = camera.camOpenUrl === undefined ? '' : camera.camOpenUrl ;
                            cam.service01 = camera.camOpenUrl !== null && camera.camOpenUrl !== '' && camera.camOpenUrl !== undefined ? 'Y' : 'N';
                            if (camera.device_sn_id.length > 0) {
                                cam_device_sn_id.push(camera.device_sn_id)
                            }
                            cams.push(cam);
                        }
                    });
                    currStoreNm = item.storeNm;
                }
            })

            storeMappingList.push({
                storeId: currStoreId,
                storeNm: currStoreNm,
                lpzEntrNo: addLpzList.join(","),
                camId: camIds.join(","), 
                cams: cams,
                cam_device_sn_id : cam_device_sn_id,
                lpzNo: currLpzNo,
                lzpEntrNo: addLzpList.join(",")
            });

            storeServiceRegiData.storeList.push({
                "storeId": currStoreId,
                "lpzEntrNo": addLpzList.join(","),
                "camId": camIds.join(","),
                "lzpEntrNo": addLzpList.join(","),
            })
        });

        otherStoreId.map((storeId, index) => {
            let storeNm = '';
            
            storeDetailListData.map((data) => {
                if (data.storeId === storeId) {
                    storeNm = data.storeNm;
                }
            });

            storeMappingList.push({
                storeId: storeId,
                storeNm: storeNm,
                lpzEntrNo: '',
                camId: '', 
                cams: [],
                cam_device_sn_id : [],
                lpzNo: currLpzNo,
                lzpEntrNo: ''
            });
        })
        
        // --- 전송
       
        // storeMappingList.map((item, index)=> {
        //     storeServiceRegiData.storeList.push({
        //         "storeId": item.storeId,
        //         "lpzEntrNo": item.lpzEntrNo,
        //         "camId": item.camId,
        //         "lzpEntrNo": item.lzpEntrNo,
        //     })
        // })

        // 매핑된 매장이 없으면 임시사용자로 취급하여 바로 메인으로 이동 : 취소(210910)
        // 매핑된 매장이 없으면 바로 상세 정보 입력 페이지로 이동 (210910)
        if(!storeServiceRegiData.storeList.length) {
            // layerPopup.showCommon(
            //     '#popup_onetitlemsgbutton', 
            //     null, 
            //     '안내', 
            //     '가게별 대표번호 및 CCTV를 설정해 주세요.'
            // );
            // 임시사용자로 취급하여 바로 메인으로 이동
            // history.replace("/");
            history.push("/account/store/detail"); 
            return;
        }


        const rst = await SohoApi.storeServiceRegi(storeServiceRegiData);
        console.log(rst);

        if(rst.retCode !== "0000") {
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '오류', 
                '오류가 발생했습니다.<br />다시한번 시도해 주세요.'
            );
            return;
        }

        /*
        const localStoreList2 = sortObjectList(localStoreList, "storeId");

        console.log(localStoreList2);
        const localStoreList3 = [];
        let currStoreId = "";
        localStoreList2.map((item, index) => {
            if(item.storeId === currStoreId) {
                return true;
            } 
            localStoreList3.push(item);
            currStoreId = item.storeId;
        });

        console.log(localStoreList3);
        LocalStore.setStoreList(localStoreList3);
        userData.setStoreList(localStoreList3);
        */
        try {
            // const ownerId = LocalStore.getOwnerId();
            // const storeList = await SohoApi.getStoreList({ownerUserId: ownerId});
            // if(storeList.data && storeList.data.list && storeList.data.list.length) {

            //     // 센트릭스나 cctv에 가입된 스토어만 (사용)저장한다.
            //     const usageStoreList = [];
            //     storeList.data.list.map((item, index)=> {
                    
            //     })
                

            //     const initStoreId = storeList.data.list[0].storeId;
            //     const initStoreInetNo = storeList.data.list[0].storeInetNo;
            //     LocalStore.setStoreList(storeList.data.list);
            //     LocalStore.setStoreId(initStoreId);
            //     LocalStore.setStoreInetNo(initStoreInetNo);
            //     userData.setStoreList(storeList.data.list);
            //     userData.setStoreId(initStoreId);
            //     userData.setStoreInetNo(initStoreInetNo);
            // }

            if(storeMappingList.length) {
                let initStoreId = LocalStore.getStoreId();
                if(!initStoreId) {
                    initStoreId = storeMappingList[0].storeId;
                }  
                let initStoreInetNo = LocalStore.getStoreInetNo();
                if(!initStoreInetNo) {
                    initStoreInetNo = storeMappingList[0].lpzNo;
                }     
                userData.setStoreList(storeMappingList);
                userData.setStoreId(initStoreId);
                userData.setStoreInetNo(initStoreInetNo);
            }

        } catch(e) {
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '오류', 
                '오류가 발생했습니다.<br />다시한번 시도해 주세요.'
            );
            return;
        }

        // 센트릭스 인증키 발급
        // try {
            if(LocalStore.getStoreInetNo()) {
                await SohoApi.getCentrexAuth({"storeInetNo":LocalStore.getStoreInetNo()});
                // if(centrexAuthInfo.retCode === "0000") {
                //     // setCentrexSsoKey(centrexAuthInfo.data.DATAS.SESSID, centrexAuthInfo.data.DATAS.SSOHASH);
                //     NativeBridge.setCentrexSsoKeyToNative(centrexAuthInfo.data.sessId, centrexAuthInfo.data.ssoHash, LocalStore.getStoreInetNo());
                // }
            }
        /*
        } catch(e) {
            console.log(e);
            layerPopup.showCommon(
                '#popup_onetitlemsgbutton', 
                null, 
                '오류', 
                '오류가 발생했습니다.<br />다시한번 시도해 주세요.'
            );
            return;
        }
        */

        // toast('가게 설정 완료!');
        history.push("/account/store/detail");        

    }

    const getMatchStoreButton = (itemEntrNo, detailList) => {

        
        
        if(detailList.length > 0) {
            let selected = false;
            let selStoreId = "";
            let selStoreNm = "";

            if (detailList.length === 1 && storeCctvList.length === 1) {
                const detail = detailList[0];
                const selStoreId = detail.storeId;
                const selStoreNm = detail.storeNm;
                return (
                    <button type="button" className="btn_sel_shop usetap on" {...{lzpentrno: itemEntrNo, storeid: selStoreId, storenm: selStoreNm}} onClick={(e)=>showStoreListCctvPopup(e)}>{selStoreNm}</button>
                );
            } else {
                detailList.map((detail, idx)=>{
                    detail.lzpList.map(lzp => {
                        const lzpArr = lzp.lzpEntrNo.split(',');
                        const itemArr = itemEntrNo.split(',');
    
                        if (lzpArr.length === itemArr.length) {
                            let difference = lzpArr.filter(x => !itemArr.includes(x));
    
                            if(difference.length === 0) {
                                selected = true;
                                selStoreId = detail.storeId;
                                selStoreNm = detail.storeNm;
                                return true;
                            }
                        }
                    })
                });
                if(selected === true) {
                    return (
                        <button type="button" className="btn_sel_shop usetap on" {...{lzpentrno: itemEntrNo, storeid: selStoreId, storenm: selStoreNm}} onClick={(e)=>showStoreListCctvPopup(e)}>{selStoreNm}</button>
                    );
                } else {
                    return (
                        <button type="button" className="btn_sel_shop usetap" {...{lzpentrno: itemEntrNo}} onClick={(e)=>showStoreListCctvPopup(e)}>가게선택</button>
                    );
                }

            }
        } else {
            return (
                <button type="button" className="btn_sel_shop usetap" {...{lzpentrno: itemEntrNo}} onClick={(e)=>showStoreListCctvPopup(e)}>가게선택</button>
            );
        }
    }

    return (
        <>
            <div className="shop_setting">
                <div className="page_title_wrap2">
                    <div className="inner">
                        <button type="button" className="btn_back usetap blind" onClick={()=>history.goBack()}>뒤로가기</button>
                        <h2>U+우리가게패키지</h2>
                    </div>
                </div>
                <div className="inner">
                    <ul className="info_step">
                        <li>1</li>
                        <li className="on">2</li>
                        <li>3</li>
                    </ul>

                    {storePhoneList?.length > 0 && 
                    <>
                    <h1>가게 별 대표번호 설정</h1>
                    <p className="desc">가장 낮은 숫자 순으로 나열되어 있습니다.</p>
                    <div className="represent_number">
                        <ul>
                            {storeListData?.length > 0 && 
                                storeListData?.map((item, index) => (
                                    item.storeInetNo && 
                                        <li key={item.storeId}>
                                            <button type="button" className="btn_sel_tel usetap" onClick={(e)=>showPhoneNoListPopup(e)}>{addHyphenPhoneNo(item.storeInetNo)}<i></i></button>
                                            <button type="button" className="btn_sel_shop usetap on" onClick={(e)=>showStoreListPopup(e)} {...{lpzentrno:item.lpzEntrNo, lpzno:item.storeInetNo, storeid: item.storeId, storenm: item.storeNm, camid: item.camId}}>{item.storeNm}</button>
                                        </li>
                                ))
                            }
                            {storePhoneMappingCnt === 0 && storePhoneList?.length > 0 && 
                                <li key="0">
                                    <button type="button" className="btn_sel_tel usetap" onClick={(e)=>showPhoneNoListPopup(e)}>070-0000-0000<i></i></button>
                                    <button type="button" className="btn_sel_shop usetap" onClick={(e)=>showStoreListPopup(e)}>가게선택</button>
                                </li>
                            }
                        </ul>
                        <button type="button" className="btn_add_tel btn_line1 usetap" onClick={addPhoneNoBox}>대표번호 추가</button>
                    </div>
                    </>
                    }

                </div>

                {storeCctvList?.length > 0 && 
                <div className={storePhoneList?.length > 0 ? "sec_wrap" : ""}>
                    <div className="inner">
                        <h1>가게 별 지능형CCTV 선택</h1>
                        <ul className="cctv_sel_list">
                            {storeCctvList?.map((item, index)=>(

                                <li key={index}>
                                    <span className="cctv_num">CCTV {item.totCnt}대</span>
                                    <span className="cctv_addr">{item.estbAddr}</span>

                                    {
                                        getMatchStoreButton(item.lzpEntrNo, storeDetailListData)
                                    }

                                    {/* {storeDetailListData.length > 0 ?
                                        storeDetailListData.map((detail, idx)=>(
                                            detail.lzpList.length > 0 && detail.lzpList[0].lzpEntrNo === item.lzpEntrNo && 
                                                <button type="button" className="btn_sel_shop usetap on" {...{lzpentrno: item.lzpEntrNo, storeid: detail.storeId}} onClick={(e)=>showStoreListCctvPopup(e)}>{detail.storeNm}</button>
                                        ))
                                    : 
                                        <button type="button" className="btn_sel_shop usetap" {...{lzpentrno: item.lzpEntrNo}} onClick={(e)=>showStoreListCctvPopup(e)}>가게선택</button>
                                    } */}
                                </li>

                            ))}
                        </ul>
                    </div>
                </div>
                }


                {storePhoneList?.length === 0 && storeCctvList?.length === 0  && loading &&
                    <div className="data_none">
                        <div className="none_wrap">
                            <span className="none_img"></span>
                            <b>가입한 서비스가 없습니다.</b>
                            <p>다음 버튼을 눌러 <br />내 가게를 등록해보세요</p>
                        </div>
                    </div>
                }



            </div>
            <div className="btn_box fixed">
                <div className="inner">
                    {nextBtnOk === true ? 
                        <button type="button" id="idx_btn_go_next" className="btn_lg btn_point usetap" onClick={goNext}>다음</button>
                    : 
                        <button type="button" id="idx_btn_go_next" className="btn_lg btn_point usetap" disabled>다음</button>
                    }
                </div>
            </div>
            <div className="modal fadeInUp" id="popup_phone_number_select">
                <div className="dim" onClick={()=>layerPopup.hide('#popup_phone_number_select')}></div>
                <div className="modal_inner modal_up">
                    <button type="button" className="pop_close usetap blind" onClick={()=>layerPopup.hide('#popup_phone_number_select')}>닫기</button>
                    <span className="modal_title">대표 번호 선택</span>
                    <ul className="modal_radio_list">


                        {storePhoneList?.map((item, index)=>(                                
                            <li key={index}>
                                <div className="radio_box green">
                                    <input type="radio" id={`phoneIdx_${item.lpzEntrNo}`} name="representNum" {...{lpzentrno:item.lpzEntrNo, lpzno:item.lpzNo}} />
                                    <label htmlFor={`phoneIdx_${item.lpzEntrNo}`} className="usetap">{addHyphenPhoneNo(item.lpzNo)}</label>
                                </div>
                            </li>
                        ))}
                      
                    </ul>
                    <div className="btn_box bottom_box">
                        <button type="button" className="btn_lg btn_point usetap" onClick={selectPhoneNo}>완료</button>
                    </div>
                </div>
            </div>

            <div className="modal fadeInUp" id="popup_store_id_select">
                <div className="dim" onClick={()=>layerPopup.hide('#popup_store_id_select')}></div>
                <div className="modal_inner modal_up">
                    <button type="button" className="pop_close usetap blind" onClick={()=>layerPopup.hide('#popup_store_id_select')}>닫기</button>
                    <span className="modal_title">가게 선택</span>
                    <ul className="modal_radio_list">

                        {storeListData?.map((item, index) => (
                            <li key={index}>
                                <div className="radio_box green">
                                    <input type="radio" id={`storeIdx_${item.storeId}`} name="shopSelect" {...{storeid:item.storeId, storenm:item.storeNm}} />
                                    <label htmlFor={`storeIdx_${item.storeId}`}  className="usetap">{item.storeNm}</label>
                                </div>
                            </li>
                        ))}

                    </ul>
                    <div className="btn_box bottom_box">
                        <button type="button" className="btn_lg btn_point usetap" onClick={selectStore}>완료</button>
                    </div>
                </div>
            </div>

                   
          
        </>
    )
    
});
export default StoreMapping;