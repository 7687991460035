import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import { layerPopup, toast } from '../../libs/common';
import SohoApi from '../../api/SohoApi';
import $ from 'jquery';
import * as NativeBridge from '../../utils/NativeBridge';
import * as SessionStore from '../../store/SessionStore';
import * as LocalStore from '../../store/LocalStore'
import { setUserDataStoreListByOwnerId, clearLocalData, clearApiRetryCnt } from '../login/DefaultSet';

const MyFamilyAccount = observer(({ history }) => {

    const { userInfo, userData, deviceInfo } = store;

    const [appversion, setAppversion] = useState("")
    const [accountListData, setAccountListData] = useState([]);
   
    useEffect(() => {       
        getAppVersionList();

        if($('#wrap').hasClass('gnb_bottom')) {
            $('#wrap').removeClass('gnb_bottom')
            $('#wrap').addClass('fixed_bottom');
            $('#wrap').addClass('bg_gray');
        }        

        return () => {
            if($('#wrap').hasClass('bg_gray')) {
                $('#wrap').addClass('gnb_bottom')
                $('#wrap').removeClass('fixed_bottom');
                $('#wrap').removeClass('bg_gray');
            }
        };
    }, []);
    const funcTooltipFamily = (e,msg) => {
        e.stopPropagation();
		document.querySelector(msg).classList.toggle("on")
	}

    const getAppVersionList = async () => {
        const appversionData = await SohoApi.getAppVersionList(`${deviceInfo.dataset.os_type}`)
            setAppversion(appversionData.data.appVersion);
            console.log("getAppVersionList",appversionData.data.appVersion);
    }

    const logout = () => {
        NativeBridge.sendUserActionLog('detail', 'D061')
        layerPopup.showCommon("#popup_onetitle_twomsgbutton_logout", null, "로그아웃", "로그아웃 하시겠어요?", [
            function() {
                layerPopup.hide("#popup_onetitle_twomsgbutton_logout");   
            },
            function() {
                layerPopup.hide("#popup_onetitle_twomsgbutton_logout", () => {
                    execLogout();
                });
            }
        ]);
    }    
    const execLogout = () => {
        clearLocalData();
        history.replace("/");
    }

    const UpdateClick = async(currVersion, updateVersion) => {
        if(currVersion < updateVersion) {
            layerPopup.showCommon(
                '#version_store_popup',
                null,
                '업데이트 알림',
                '새로운 버전이 나왔습니다.<br /> 지금 업데이트하고 더 안정적인<br /> 서비스를 이용하시겠어요?',
                [
                    function() {
                        layerPopup.hide("#version_store_popup")
                    },
                    function() {
                        layerPopup.hide("#version_store_popup", () => {
                            if(deviceInfo.dataset.os_type === 'android') {
                                NativeBridge.externalCall('https://play.google.com/store/apps/details?id=com.lguplus.sohoapp')
                            } else {
                                NativeBridge.externalCall('https://itunes.apple.com/app/id1571096278')
                            }  
                        })
                    },
                ]
            )
        }
    }

    const AccountChange = async() => {
        NativeBridge.sendUserActionLog('detail', 'D053')
        const accountList = await SohoApi.getAccountList();
        console.log("전 계정목록",accountList.data.list);
        setAccountListData(accountList.data.list);
        layerPopup.show('#popup_accountselect')
    }

    const selectAccount = async () => {
        const checkedObj = $('input[type="radio"]:checked');
        const ownerId = checkedObj.val();
        const familyYn = checkedObj.attr("familyyn");
        const ownerNm= checkedObj.attr("ownernm");
        console.log("selectedId: ", ownerId);
        console.log("selectedId: ", familyYn);
        console.log("selectedId: ", ownerNm);
        console.log(checkedObj.length);
        
        if(checkedObj.length === 1 && ownerId !== LocalStore.getOwnerId()) {
            userInfo.setIsTempUser(false);
            userInfo.setOwnerId(ownerId);
            userInfo.setOwnerNm(ownerNm);

            // 다음단계(마이 -> 설정)로 이동
            // toast('계정 변경이 완료 되었습니다.')
            LocalStore.deleteHomecard();
            // setUserDataStoreListByOwnerId(ownerId)
            console.log('storeId', userData.dataset.storeId);
            await setUserDataStoreListByOwnerId(ownerId);

            // 로그인 세션을 제거한다.
            SessionStore.clear();

            clearApiRetryCnt();

            layerPopup.hide("#popup_accountselect", ()=> {
                if(LocalStore.getBioLoginType() === '0' || LocalStore.getBioLoginType() === '1') {
                    history.push('/bio/check');
                } else {
                    history.push('/pincode/check')
                }
                userInfo.setFamilyYn(familyYn);
            });


        } else {
            userInfo.setFamilyYn('Y');
            if(ownerId === LocalStore.getOwnerId()) {
                toast("현재 선택된 계정입니다, 다른 계정을 선택해주세요.");
            } else {
                toast("계정을 선택해 주세요.");
            }
            return;
        }
    }

    const AccountChangeClose = async() => {
		layerPopup.hide('#popup_accountselect');
	}

    const SettingTabPinCodeClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D054')
        history.push(`/callkmc/modpass`)
    }

    const SettingBioClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D055')
        history.push("/my/mysetting/biosetting")
    }

    const SettingNoticeClick = () => {
        NativeBridge.sendUserActionLog('detail', 'D060')
        history.push("/my/mysetting/cs")
    }

    return (
        <>
			{/*---------0713 퍼블---------- */}
			<div className="content my">
				<div className="my_set_top">
				    <h1>
				        초대 계정
                        <button type="button" className="ico_ques usetap blind" onClick={(e) => funcTooltipFamily(e,'.tooltip_box')}>도움말</button>
				        <div className="tooltip_box" style={{ left: '2.1rem' }}>
                            <span className="top_arrow"></span>
                            <p className="tip_content">
                               * 초대계정의 경우 초대자에게 관리 권한이 있습니다.<br /> 초대 삭제를 원하시면 초대자에게 삭제요청을 해주세요. <br />초대자 계정 &gt; 마이 페이지&gt;  초대 계정 관리
                            </p>
                        </div>
				    </h1>
				    <button type="button" className="btn_round white refresh usetap" onClick={()=> AccountChange()}> 다른 계정으로 로그인</button>
				    <p className="account_desc">초대 받은 계정입니다.</p>
				</div>
				<div className="my_setting">
                    <div className="sec_wrap">
					{/* <!-- 터치 위치 --> */}
                            <div className="set_wrap usetap" onClick={()=> SettingTabPinCodeClick()}>
                                <span className="set_title">비밀번호 재설정</span>
                                <span className="i_more"></span>
                            </div>
                            <div className="set_wrap is_desc usetap" onClick={()=> SettingBioClick()}>
                                <span className="set_title">지문 또는 얼굴인식 로그인 설정</span>
                                <span className="desc">비밀번호 대신 지문 또는 얼굴을 인식해 <br /> 로그인할 수 있습니다.</span>
                                <span className="i_more"></span>
                            </div>
                    </div>
                    <div className="sec_wrap">
                            <div className="set_wrap usetap" onClick={()=> SettingNoticeClick()}>
                                <span className="set_title">공지보기</span>
                                <span className="i_more"></span>
                            </div>
                    </div>
                    <div className="sec_wrap">
                        <div className="set_wrap app_vision usetap">
                            <span className="set_title">앱 버전</span>
                            <span className="vision_txt">버전 V.{deviceInfo.dataset.version}</span>
                            <span className={"vision_round" + (deviceInfo.dataset.version < appversion ? " update" : " latest")} onClick={()=>UpdateClick(deviceInfo.dataset.version, appversion)}>
                                {deviceInfo.dataset.version < appversion ? "업데이트" : "최신버전"}
                            </span>
                        </div>
                        <div className="set_wrap" onClick={logout}>
                            <span className="set_title">로그아웃</span>
                            {/* <span className="desc">본인 명의의 매장 조회를 원하시면 재 로그인 하세요.</span> */}
                        </div>
                    </div>
				</div>
			</div>
            {/* 계정 전환 팝업 */}
			<div className="modal fadeInUp" id="popup_accountselect">
				<div className="dim" onClick={()=> AccountChangeClose()}></div>
				<div className="modal_inner modal_up">
					<span className="modal_title">어느 가게를 관리하시겠어요?</span>
					<ul className="account_sel_list modal_style">
						{ accountListData !== null &&
							accountListData.map((item, index) => (
								item.familyYn === 'N' &&
								<li>
									<input id={`accounts${index + 1}`} type="radio" name="sel_accounts" value={item.ownerUserId} {...{familyyn: item.familyYn, ownernm: item.ownerNm}} />
									<label htmlFor={`accounts${index + 1}`} className="usetap">
										<span className="account_img owner"></span>
										<span className="i_name">{item.ownerNm}</span>
										<span className="i_sort">소유 계정</span>
										<span className="i_sort">{userInfo.dataset.ownerId === item.ownerUserId && '현재 선택된 계정입니다.'}</span>
									</label>
								</li>
							))
						}
						{ accountListData !== null &&
							accountListData.map((item, index) => (
								item.familyYn === 'Y' &&
								<li>
									<input id={`accounts${index + 1}`} type="radio" name="sel_accounts" value={item.ownerUserId} {...{familyyn: item.familyYn, ownernm: item.ownerNm}} />
									<label htmlFor={`accounts${index + 1}`} className="usetap">
										<span className="account_img family"></span>
										<span className="i_name">{item.ownerNm}</span>
										<span className="i_sort">패밀리 계정</span>
										<span className="i_sort">{userInfo.dataset.ownerId === item.ownerUserId && '현재 선택된 계정입니다.'}</span>
									</label>
								</li>
							))
						}
					</ul>
					<div className="btn_box bottom_box">
						<button type="button" className="btn_lg btn_point usetap" onClick={()=> selectAccount()}>다음</button>
					</div>
				</div>
			</div>
        </>
    )
});

export default MyFamilyAccount;